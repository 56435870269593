import { Card, FormControlLabel, FormGroup, Radio, RadioGroup, Stack } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"
import {
    useGetFormByTokenQuery,
    useSaveUserAnswerMutation,
} from "~/domains/identity/features/customForms/api/customFormsApi"
import "~/domains/identity/features/customForms/assets/CustomForms.scss"
import {
    QuestionFieldTypeEnum,
    UserSurveyForm,
    UserSurveyQuestion,
    UserSurveySection,
} from "~/domains/identity/features/customForms/types/CustomForms"

import { CustomFormsInput } from "./CustomFormsInput"

const messages = defineMessages({
    radioYes: {
        id: "common.yes",
        defaultMessage: "Yes",
    },
    radioNo: {
        id: "common.no",
        defaultMessage: "No",
    },
    nextSection: {
        id: "smartForms.nextSection",
        defaultMessage: "Next section",
    },
    previousSection: {
        id: "smartForms.previousSection",
        defaultMessage: "Previous section",
    },
    send: {
        id: "smartForms.buttonSend",
        defaultMessage: "Send",
    },
    sectionContainsErrors: {
        id: "smartForms.sectionContainsErrors",
        defaultMessage: "Section contains errors",
    },
    formSent: {
        id: "smartForms.formSent",
        defaultMessage: "Form sent",
    },
})

const CustomFormsRadioInput = ({
    savedValue,
    onValueChanged,
    disabled = false,
    isInvalid = false,
}: {
    savedValue: string | boolean | string[]
    onValueChanged: (value: string | boolean | string[]) => void
    disabled?: boolean
    isInvalid?: boolean
}) => {
    const [value, setValue] = useState<string | boolean | string[]>(savedValue)
    const { formatMessage } = useIntl()
    const handleChange = (event: React.SyntheticEvent) => {
        const newValue = (event.target as HTMLInputElement).value
        onValueChanged(newValue)
        setValue(newValue)
    }

    const errorClassName = isInvalid ? "form-error" : ""

    return (
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            row
            value={value}
            className={errorClassName}
        >
            <FormControlLabel
                onChange={handleChange}
                value="true"
                control={<Radio />}
                label={formatMessage(messages.radioYes)}
                disabled={disabled}
            />
            <FormControlLabel
                onChange={handleChange}
                value="false"
                control={<Radio />}
                label={formatMessage(messages.radioNo)}
                disabled={disabled}
            />
        </RadioGroup>
    )
}

// Returns the form item
const FormItem = ({
    question,
    displayIndex,
    disabled,
    onValueChanged,
}: {
    question: UserSurveyQuestion
    displayIndex: number
    disabled: boolean
    onValueChanged: (value: string | string[] | boolean, question: UserSurveyQuestion) => void
}) => {
    const handleValueChanged = (value: string | string[] | boolean) => {
        onValueChanged(value, question)
    }

    const getQuestionInput = useCallback(() => {
        const savedValue = typeof question.savedValue === "boolean" ? String(question.savedValue) : question.savedValue

        if (QuestionFieldTypeEnum.TextField in question.fieldType) {
            return (
                <CustomFormsInput
                    savedValue={savedValue}
                    onValueChanged={handleValueChanged}
                    description={question.questionContent}
                    required={question.required}
                    disabled={disabled}
                    isInvalid={question.invalid}
                />
            )
        } else if (QuestionFieldTypeEnum.TextArea in question.fieldType) {
            return (
                <CustomFormsInput
                    savedValue={savedValue}
                    onValueChanged={handleValueChanged}
                    description={question.questionContent}
                    isMultiline
                    required={question.required}
                    disabled={disabled}
                    isInvalid={question.invalid}
                />
            )
        } else if (QuestionFieldTypeEnum.NumericField in question.fieldType) {
            return (
                <CustomFormsInput
                    savedValue={savedValue}
                    onValueChanged={handleValueChanged}
                    description={question.questionContent}
                    isNumber
                    required={question.required}
                    disabled={disabled}
                    isInvalid={question.invalid}
                />
            )
        } else if (QuestionFieldTypeEnum.MultiChoice in question.fieldType) {
            return (
                <CustomFormsInput
                    savedValue={savedValue}
                    onValueChanged={handleValueChanged}
                    description={question.questionContent}
                    isSelect
                    isMultichoice
                    options={question.fieldType.MultiChoice.choices}
                    required={question.required}
                    disabled={disabled}
                    isInvalid={question.invalid}
                />
            )
        } else if (QuestionFieldTypeEnum.SingleChoice in question.fieldType) {
            return (
                <CustomFormsInput
                    savedValue={savedValue}
                    onValueChanged={handleValueChanged}
                    description={question.questionContent}
                    isSelect
                    options={question.fieldType.SingleChoice.choices}
                    required={question.required}
                    disabled={disabled}
                    isInvalid={question.invalid}
                />
            )
        } else if (QuestionFieldTypeEnum.YesNo in question.fieldType) {
            return (
                <CustomFormsRadioInput
                    savedValue={savedValue}
                    onValueChanged={handleValueChanged}
                    disabled={disabled}
                    isInvalid={question.invalid}
                />
            )
        } else {
            return <></>
        }
    }, [question, disabled, handleValueChanged])

    return (
        <FormGroup key={question.id} sx={{ padding: "20px" }}>
            <FormControlLabel
                required={question.required}
                labelPlacement="top"
                sx={{ alignItems: "start" }}
                label={
                    <h4 className="mb-12">
                        {displayIndex}: {question.questionContent}
                    </h4>
                }
                control={getQuestionInput()}
            />
        </FormGroup>
    )
}

// Returns the form section
const FormSection = ({
    section,
    disabled,
    onValueChanged,
}: {
    section: UserSurveySection
    disabled: boolean
    onValueChanged: (value: string | string[] | boolean, question: UserSurveyQuestion) => void
}) => (
    <Card key={section.id} sx={{ padding: "20px" }}>
        <h3 className="mb-12">{section.title}</h3>
        {section?.questions?.map((question, indx) => (
            <FormItem
                key={question.id}
                question={question}
                displayIndex={indx + 1}
                disabled={disabled}
                onValueChanged={onValueChanged}
            />
        ))}
    </Card>
)

interface FormViewerProps {
    formToken: string
    viewMode: string | null
}

export const FormViewer = ({ formToken, viewMode }: FormViewerProps) => {
    const [currentSection, setCurrentSection] = useState(0)
    const [formSent, setFormSent] = useState(false)
    const [formData, setFormData] = useState<UserSurveyForm | null>(null)
    const { data, isLoading: formLoading, refetch: getFormByToken } = useGetFormByTokenQuery({ formToken })
    const [saveUserAnswer] = useSaveUserAnswerMutation()
    const [currentSectionInvalid, setCurrentSectionInvalid] = useState(false)

    const formAnswers = data?.answers

    // Enrich the questions with already existing answers
    useEffect(() => {
        if (!formAnswers || !data?.form) return

        const newSections = data.form.sections.map((section) => ({
            ...section,
            questions: section.questions.map((question) => {
                const matchingAnswer = formAnswers.find((answer) => answer.questionId === question.id)
                if (!matchingAnswer) return { ...question, invalid: false }

                const answerKey = Object.keys(matchingAnswer.answer)[0]
                return {
                    ...question,
                    savedValue: (matchingAnswer.answer[answerKey] as { value: string | boolean }).value, // TODO: implement better typing
                    invalid: false,
                }
            }),
        }))

        setFormData((prevFormData) => ({
            ...prevFormData,
            ...data.form,
            sections: newSections,
        }))
    }, [data?.form, formAnswers])

    useEffect(() => {
        // need to fetch the form data when section changes
        getFormByToken()
    }, [currentSection])

    if (!formData) {
        return null
    }

    const updateQuestionInSection = (question: UserSurveyQuestion) => {
        const updatedSections = formData.sections.map((section) => {
            if (section.id === formData.sections[currentSection].id) {
                const updatedQuestions = section.questions.map((q) =>
                    q.id === question.id ? { ...q, invalid: question.invalid } : q
                )
                return { ...section, questions: updatedQuestions }
            }
            return section
        })

        setFormData((prev) => (prev ? { ...prev, sections: updatedSections } : prev))
    }

    const validateSection = () => {
        const checkQuestionInvalid = (question: UserSurveyQuestion) => {
            if (!question.required) {
                return false
            }

            if (QuestionFieldTypeEnum.YesNo in question.fieldType) {
                return question.savedValue === undefined // false is a valid value for radio buttons
            }

            return !question.savedValue
        }
        let isSectionInvalid = false
        formData.sections[currentSection].questions.forEach((question) => {
            const isInvalid = checkQuestionInvalid(question)
            question.invalid = isInvalid
            updateQuestionInSection(question)

            if (isInvalid) {
                isSectionInvalid = true
            }
        })

        return isSectionInvalid
    }

    // validate section before moving to the next one
    const nextSection = () => {
        const isSectionInvalid = validateSection()
        if (!isSectionInvalid) {
            setCurrentSection(currentSection + 1)
            setCurrentSectionInvalid(false)
        } else {
            setCurrentSectionInvalid(true)
        }
        //
    }

    const handleSendForm = () => {
        const isSectionInvalid = validateSection()

        if (!isSectionInvalid) {
            setFormSent(true)
        } else {
            setCurrentSectionInvalid(true)
        }
    }

    // TODO: redo this to better save answers
    // this is the component that has question and input value, so let's save the answer from here
    const handleValueChanged = (value: string | string[] | boolean, question: UserSurveyQuestion) => {
        const fieldTypeKey = Object.keys(question.fieldType)[0]
        const answerKey = `${fieldTypeKey}Answer`

        // Convert YesNo answers to boolean
        const formattedValue = fieldTypeKey === "YesNo" ? value === "true" : value

        // Save the user's answer via API
        saveUserAnswer({
            surveyToken: formToken,
            questionId: question.id,
            answer: { [answerKey]: { value: formattedValue } },
        })

        setFormData((prev) => {
            const prevFormData = prev || formData
            // Update the local state for the question in the current section
            const updatedSections = prevFormData.sections.map((section) => {
                if (section.id === prevFormData.sections[currentSection].id) {
                    const updatedQuestions = section.questions.map((q) => {
                        return q.id === question.id ? { ...q, savedValue: formattedValue } : q
                    })
                    return { ...section, questions: updatedQuestions }
                }
                return section
            })
            return prev ? { ...prev, sections: updatedSections } : prev
        })
    }

    const isLastSection = () => currentSection === (formData.sections || []).length - 1

    return (
        <Stack className="main-box" display="flex" maxWidth={"800px"}>
            <h2 className="mb-12">{formData?.name}</h2>
            {formSent ? (
                <>
                    <Card>
                        <Stack
                            direction="row"
                            gap={2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding={3}
                        >
                            <h3>
                                <SafeFormattedMessage {...messages.formSent} />
                            </h3>
                        </Stack>
                    </Card>
                </>
            ) : (
                <>
                    <form>
                        {formData?.sections ? (
                            <FormSection
                                disabled={viewMode === "true" || formLoading}
                                key={formData.sections[currentSection].id}
                                section={formData.sections[currentSection]}
                                onValueChanged={handleValueChanged}
                            />
                        ) : (
                            <></>
                        )}
                    </form>
                    <Card className="mt-12">
                        <Stack display="flex" justifyContent="space-between" direction="row" margin={1}>
                            {currentSection > 0 ? (
                                <Button type="primary" onClick={() => setCurrentSection(currentSection - 1)}>
                                    <SafeFormattedMessage {...messages.previousSection} />
                                </Button>
                            ) : (
                                <div></div>
                            )}
                            {currentSectionInvalid ? (
                                <Stack display="flex" direction="row" alignItems="center" className="form-error">
                                    <SafeFormattedMessage {...messages.sectionContainsErrors} />
                                </Stack>
                            ) : (
                                <></>
                            )}
                            {!isLastSection() ? (
                                <Button type="primary" onClick={nextSection}>
                                    <SafeFormattedMessage {...messages.nextSection} />
                                </Button>
                            ) : (
                                <div>
                                    <Button className="flex-grow" type="primary" onClick={handleSendForm}>
                                        <SafeFormattedMessage {...messages.send} />
                                    </Button>
                                </div>
                            )}
                        </Stack>
                    </Card>
                </>
            )}
        </Stack>
    )
}
