import * as t from "io-ts"

import { optional } from "~/types/utils"
import { genericParser } from "~/utils"

export enum DocumentJobStatus {
    PENDING = "PENDING",
    SUCCESSFUL = "SUCCESSFUL",
    FAILED = "FAILED",
}

const DocumentJobStatusIO = t.keyof({
    // using "keyof" for better performance instead of "union"
    [DocumentJobStatus.PENDING]: null,
    [DocumentJobStatus.SUCCESSFUL]: null,
    [DocumentJobStatus.FAILED]: null,
})

const DocumentJobIO = t.intersection([
    t.type({
        jobId: t.string,
        createdAt: t.string,
        expectedCompletedAt: t.string,
        originalFileName: t.string,
        status: DocumentJobStatusIO,
    }),
    t.partial({
        error: optional(t.string),
        invoiceId: optional(t.string),
    }),
])

export type DocumentJobI = t.TypeOf<typeof DocumentJobIO>

export const parseDocumentJob = (data: unknown) => {
    return genericParser<DocumentJobI>(data, DocumentJobIO)
}
