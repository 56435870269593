import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PersistConfig, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import { RootState } from "~/store"
import { VatRateI } from "~/types/VatRate"

export enum SupportedLanguages {
    FR = "fr",
    EN = "en",
    AR = "ar",
    CH = "ch",
    DE = "de",
    NO = "no",
}

export enum GlobalFilterType {
    TAGS = "TAGS",
}

export const SUPPORTED_LANGUAGES = [
    SupportedLanguages.FR,
    SupportedLanguages.EN,
    SupportedLanguages.AR,
    SupportedLanguages.CH,
    SupportedLanguages.DE,
    SupportedLanguages.NO,
]

interface StateInterface {
    displayMenu: boolean
    displayModalTerms: boolean
    lang: SupportedLanguages
    keepSidebarOpened: boolean
    vatRates?: VatRateI[]
    fetchingVatRates: boolean
    darkMode: boolean
    filterTypes: GlobalFilterType[]
}

const globalPersistConfig: PersistConfig<StateInterface> = {
    key: "global",
    storage: storage,
    whitelist: ["lang", "keepSidebarOpened"],
}

const initialState: StateInterface = {
    displayMenu: false,
    displayModalTerms: false,
    lang: SupportedLanguages.FR,
    keepSidebarOpened: true,
    fetchingVatRates: false,
    darkMode: false,
    filterTypes: [],
}

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setDisplayMenu(state) {
            state.displayMenu = !state.displayMenu
        },
        setLang(state, action: PayloadAction<SupportedLanguages>) {
            state.lang = action.payload
            if (localStorage) {
                localStorage.setItem("lang", action.payload)
            }
        },
        setDisplayModalTerms(state, action: PayloadAction<boolean>) {
            state.displayModalTerms = action.payload
        },
        toggleKeepSidebarOpened(state) {
            state.keepSidebarOpened = !state.keepSidebarOpened
        },
        fetchVatRates(state) {
            state.fetchingVatRates = true
        },
        fetchVatRatesSuccess(state, action: PayloadAction<VatRateI[]>) {
            state.fetchingVatRates = false
            state.vatRates = action.payload
        },
        fetchVatRatesFailure(state) {
            state.fetchingVatRates = false
        },
        setDarkMode(state, action: PayloadAction<boolean>) {
            state.darkMode = action.payload
            if (localStorage) {
                localStorage.setItem("darkMode", action.payload.toString())
            }
        },
        setFilterTypes(state, action: PayloadAction<GlobalFilterType[]>) {
            state.filterTypes = action.payload
        },
        resetFilterTypes(state) {
            state.filterTypes = []
        },
    },
})

export const globalActions = globalSlice.actions

export const selectDisplayMenu = (state: RootState) => state.global.displayMenu
export const selectDisplayModalTerms = (state: RootState) => state.global.displayModalTerms
export const selectLang = (state: RootState) => state.global.lang
export const selectKeepSidebarOpened = (state: RootState) => state.global.keepSidebarOpened
export const selectVatRates = (state: RootState) => state.global.vatRates
export const selectFetchingVatRates = (state: RootState) => state.global.fetchingVatRates
export const selectDarkMode = (state: RootState) => {
    return state.global.darkMode || (localStorage && localStorage.getItem("darkMode") === "true")
}
export const selectFilterTypes = (state: RootState) => state.global.filterTypes

export const globalReducer = globalSlice.reducer

export default persistReducer(globalPersistConfig, globalReducer)
