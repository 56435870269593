import { Grid, Tooltip } from "@mui/material"
import { FC, useState } from "react"
import { Check, Download, Save, Trash2, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { Button, ConfirmModal } from "~/components"
import {
    useDeleteInvoiceMutation,
    usePatchUpdateInvoiceMutation,
} from "~/domains/transactions/invoices-v1/api/invoiceApiV1"
import { INVOICES_ROUTE } from "~/domains/transactions/invoices/routes"
import { checkFormValidity } from "~/store/invoice/core"
import { InvoiceI, ViewTypeI } from "~/types"
import { InvoiceStatus } from "~/types"

const messages = defineMessages({
    title: {
        id: "supplier.editInvoice.page.title",
        defaultMessage: "Edit Invoice",
    },
    close: {
        id: "supplier.invoice.page.close",
        defaultMessage: "Close",
    },
    addEdit: {
        id: "buyer.invoice.page.add",
        defaultMessage: "Send",
    },
    confirmEdit: {
        id: "supplier.invoice.page.confirm",
        defaultMessage: "Update Invoice",
    },
    downloadButtonTooltip: {
        id: "invoice.actions.button.download.tooltip",
        defaultMessage: "Download this invoice",
    },
    unableToDownloadInvoice: {
        id: "invoice.ocrInvoice.unableToDownloadInvoice",
        defaultMessage: "An error occured: your invoice could not be downloaded.",
    },
    saveAsDraft: {
        id: "invoice.ocrInvoice.saveAsDraft",
        defaultMessage: "Save as draft",
    },
    invoiceSavedInDraft: {
        id: "invoice.ocrInvoice.invoiceSavedInDraft",
        defaultMessage: "Your invoice has been successfully saved in draft.",
    },
    unableToSaveInDraft: {
        id: "invoice.ocrInvoice.unableToSaveInDraft",
        defaultMessage: "An error occured: your invoice could not be saved in draft.",
    },
    moveToTrash: {
        id: "invoice.ocrInvoice.moveToTrash",
        defaultMessage: "Move to trash",
    },
    confirmDeleteInvoiceTitle: {
        id: "invoice.ocrInvoice.confirmDeleteInvoiceTitle",
        defaultMessage: "Are you sure?",
    },
    confirmDeleteInvoiceMessage: {
        id: "invoice.ocrInvoice.confirmDeleteInvoiceMessage",
        defaultMessage: "This action cannot be undone, this invoice will be permanently lost.",
    },
})

interface InvoiceActionsEditProps {
    invoice: InvoiceI
    formRef: React.RefObject<HTMLFormElement>
    invoiceLinesFormRef: React.RefObject<HTMLFormElement>
}

export const InvoiceActionsEdit: FC<InvoiceActionsEditProps> = ({ invoice, formRef, invoiceLinesFormRef }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const [modalConfirmDeleteDisplayed, setModalConfirmDeleteDisplayed] = useState(false)

    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()
    const [deleteInvoice] = useDeleteInvoiceMutation()

    const handleDisplayModalConfirmDelete = () => setModalConfirmDeleteDisplayed(true)
    const handleHideModalConfirmDelete = () => setModalConfirmDeleteDisplayed(false)

    const handleDownloadPDF = () => {
        if (!invoice?.fileLinks?.pdfLink) {
            toast.error(formatMessage(messages.unableToDownloadInvoice))
            return
        }

        window.location.href = invoice.fileLinks.pdfLink
    }

    const handleCancelEdit = () => {
        navigate(generatePath(INVOICES_ROUTE, { view: ViewTypeI.buyer }))
    }

    const handleConfirm = () => {
        if (!invoice || !formRef.current) return

        const formValid = checkFormValidity(invoice, formRef, invoiceLinesFormRef, formatMessage, true)
        if (formValid.valid) {
            formRef.current.requestSubmit()
        }
    }

    const handleSaveDraft = async () => {
        if (!invoice) return

        const formValid = checkFormValidity(invoice, formRef, invoiceLinesFormRef, formatMessage)
        if (formValid.valid) {
            try {
                await patchUpdateInvoice({ status: InvoiceStatus.DRAFT, id: invoice.id })
                toast.success(formatMessage(messages.invoiceSavedInDraft))
            } catch {
                toast.error(formatMessage(messages.unableToSaveInDraft))
            }
        }
    }

    const handleDeleteInvoice = async () => {
        await deleteInvoice(invoice.id)
        navigate(generatePath(INVOICES_ROUTE, { view: ViewTypeI.buyer }))
    }

    return (
        <>
            <Grid container spacing={1}>
                <Tooltip title={formatMessage(messages.close)} arrow>
                    <div>
                        <Button onClick={handleCancelEdit} type="neutral">
                            <X size={18} />
                        </Button>
                    </div>
                </Tooltip>
                {invoice?.status === InvoiceStatus.DRAFT && (
                    <Tooltip title={formatMessage(messages.saveAsDraft)} arrow>
                        <div>
                            <Button onClick={handleSaveDraft} type="neutral">
                                <Save size={18} />
                            </Button>
                        </div>
                    </Tooltip>
                )}
                <Tooltip title={formatMessage(messages.downloadButtonTooltip)} arrow>
                    <div>
                        <Button onClick={handleDownloadPDF} type="tertiary">
                            <Download size={18} />
                        </Button>
                    </div>
                </Tooltip>
                {[InvoiceStatus.INFO_REQUESTED, InvoiceStatus.CONFIRMED, InvoiceStatus.DRAFT].includes(
                    invoice.status
                ) && (
                    <>
                        <Tooltip title={formatMessage(messages.moveToTrash)} placement="bottom" arrow>
                            <div>
                                <Button onClick={handleDisplayModalConfirmDelete} type="error-light">
                                    <Trash2 size={18} />
                                </Button>
                            </div>
                        </Tooltip>
                        {invoice?.status === InvoiceStatus.DRAFT ? (
                            <Tooltip title={formatMessage(messages.addEdit)} placement="bottom" arrow>
                                <div>
                                    <Button onClick={handleConfirm}>
                                        <Check size={18} />
                                    </Button>
                                </div>
                            </Tooltip>
                        ) : (
                            <Tooltip title={formatMessage(messages.confirmEdit)} placement="bottom" arrow>
                                <div>
                                    <Button onClick={handleConfirm}>
                                        <Save size={18} />
                                    </Button>
                                </div>
                            </Tooltip>
                        )}
                    </>
                )}
            </Grid>

            <ConfirmModal
                title={formatMessage(messages.confirmDeleteInvoiceTitle)}
                open={modalConfirmDeleteDisplayed}
                close={handleHideModalConfirmDelete}
                onConfirm={handleDeleteInvoice}
                confirmButtonText={formatMessage(messages.moveToTrash)}
            >
                {formatMessage(messages.confirmDeleteInvoiceMessage)}
            </ConfirmModal>
        </>
    )
}
