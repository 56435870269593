import * as Sentry from "@sentry/browser"
import { useIntl } from "react-intl"
import { defineMessages } from "react-intl"
import { toast } from "react-toastify"

import { copilotApi } from "~/domains/copilot/prompt/api/copilotApi"

const messages = defineMessages({
    errorUpload: {
        id: "common.error.errorUploadingDocument",
        defaultMessage: "An error occurred while uploading the document.",
    },
})

export const useCopilotUploadDocument = () => {
    const { formatMessage } = useIntl()
    const uploadDocument = async (file: File): Promise<string> => {
        try {
            const { content } = await copilotApi.uploadDocument({ file })
            return content
        } catch (error) {
            Sentry.captureException(error)
            toast.error(formatMessage(messages.errorUpload))
            throw error
        }
    }

    return { uploadDocument }
}
