import React from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"

import { Prompt } from "./pages/Prompt"
import { COPILOT_PROMPT_ROUTE } from "./routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="prs_route">
            <Route path={COPILOT_PROMPT_ROUTE} element={<Prompt />} />
        </Route>,
    ],
}
