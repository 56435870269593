import { Stack } from "@mui/material"
import React, { useState } from "react"
import { Play } from "react-feather"
import { useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { HeaderH1, HeaderNavigation, HeaderNavigationItem } from "~/components"
import { Filter } from "~/domains/orchestration/flows/components/Filter"
import { RunsTable } from "~/domains/orchestration/flows/components/RunsTable"
import { messages } from "~/domains/orchestration/flows/messages"
import { FLOWS_LIST } from "~/domains/orchestration/flows/routes"
import { useTitle } from "~/hooks"

export const RunsList: React.FC = () => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const pageName = formatMessage(messages.page.title)
    const [filter, setFilter] = useState("")

    useTitle(pageName)

    const filterChangeCallback = (filter: string) => {
        setFilter(filter)
    }

    const handleViewChange = () => {
        const path = generatePath(FLOWS_LIST)
        navigate(path)
    }

    const navItems: HeaderNavigationItem[] = [
        { value: FLOWS_LIST, label: messages.page.flowsTab, onClick: handleViewChange },
        { value: FLOWS_LIST, label: messages.page.runsTab, onClick: handleViewChange },
    ]

    return (
        <>
            <HeaderH1 title={pageName} icon={<Play />} />
            <Stack className="main-box">
                <HeaderNavigation items={navItems} selectedIndex={0} onChange={handleViewChange} />
                <Filter filterChangeCallback={filterChangeCallback} filter={filter} hasCreatePermission={false} />
                <RunsTable filter={filter} />
            </Stack>
        </>
    )
}
