import axiosClient from "~/api/axiosClient"
import { MessageResult, ThreadResult } from "~/domains/copilot/prompt/types/Copilot"

const BASE_URL = import.meta.env.VITE_API_COPILOT_URL

const DOCUMENT_TEXT_EXTRACT_URL_BASE_URL = import.meta.env.VITE_API_DOCUMENT_TEXT_EXTRACT_URL
const DOCUMENT_INFO_EXTRACT_URL_BASE_URL = import.meta.env.VITE_API_DOCUMENT_INFO_EXTRACT_URL

export const copilotApi = {
    createThread(organizationId: string, userId: string): Promise<ThreadResult> {
        const url = `${BASE_URL}threads`
        return axiosClient.post(url, {
            organizationId,
            userId,
        })
    },
    createMessage(threadId: string, userId: string, message: string): Promise<MessageResult> {
        const url = `${BASE_URL}messages`
        return axiosClient.post(url, {
            threadId,
            userId,
            message,
        })
    },
    uploadDocument(payload: { file: File }): Promise<{ content: string }> {
        const formData = new FormData()
        formData.append("file", payload.file)

        const url = `${DOCUMENT_TEXT_EXTRACT_URL_BASE_URL}process_file`

        return axiosClient.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },

    async uploadPaymentMethod(file: File, organizationId?: string) {
        const formData = new FormData()
        formData.append("files", file)
        formData.append("data", JSON.stringify({ organizationId: organizationId ?? "" }))

        const url = `${DOCUMENT_INFO_EXTRACT_URL_BASE_URL}process_files`

        return axiosClient.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    /*     findAll(organizationId: string): Promise<PurchaseRequestSummaryAPI[]> {
        const url = `${BASE_URL}${organizationId}/purchase-requests`
        return axiosClient.get(url)
    },
    findById(organizationId: string, purchaseRequestId: string): Promise<PurchaseRequestDetails> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}`
        return axiosClient.get(url)
    },
    delete(organizationId: string, purchaseRequestId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}`
        return axiosClient.delete(url)
    },
    update(
        organizationId: string,
        purchaseRequestId: string,
        purchaseRequest: UpdatePurchaseRequestDTO
    ): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}`
        return axiosClient.patch(url, purchaseRequest)
    }, */
}
