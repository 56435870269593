import { Autocomplete, Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { useGetOrganisationCustomFormsQuery } from "~/domains/identity/features/customForms/api/customFormsApi"
import { CustomForm } from "~/domains/identity/features/customForms/types/CustomForms"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks/useOrganizationId"
import { messages } from "~/domains/orchestration/flows/messages"
import { ConfigurationProps, CreateSurveyNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const CreateSurveyConfiguration: FC<ConfigurationProps<CreateSurveyNode>> = ({
    selectedNode,
    advancedFields,
}) => {
    const { formatMessage } = useIntl()

    const [currentNode, setCurrentNode] = useState(selectedNode)
    const organisationId = useOrganizationId()

    const { data: customForms = [], isLoading } = useGetOrganisationCustomFormsQuery({ organisationId })

    const [form, setForm] = useState<CustomForm | null>(null)

    const handleChangeForm = (_, value: CustomForm | null) => {
        if (!value) return

        setForm(value)
        setCurrentNode({
            ...currentNode,
            formId: value.id,
            metadata: {
                ...currentNode.metadata,
                additionalInformation: value.name,
            },
        })
    }

    useEffect(() => {
        const currentForm = customForms.find((form) => form.id === currentNode.formId)

        if (currentForm) {
            setForm(currentForm)
        }
    }, [customForms])

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <Autocomplete
                    options={customForms}
                    value={form}
                    onChange={handleChangeForm}
                    getOptionLabel={(form: CustomForm) => form.name}
                    isOptionEqualToValue={(option: CustomForm, value: CustomForm) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.advancedFields.formId)} />
                    )}
                    loading={isLoading}
                />
            </Stack>
            <AdvancedFields<CreateSurveyNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
