import { Box, IconButton, Stack, Tooltip } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { Check, Info, RotateCcw, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"

import { Button, Card, SafeFormattedMessage, TooltipWhite } from "~/components"
import {
    useApproveObjectMutation,
    useGetObjectChecksQuery,
    useRefuseObjectMutation,
    useRetractAllReviewsMutation,
    useRetractReviewMutation,
} from "~/domains/orchestration/flows-v0/core/approvalApi"
import {
    ApprovalApiResponse,
    ApprovalObjectType,
    ApprovalResult,
} from "~/domains/orchestration/flows-v0/types/Approval"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationTeams } from "~/store/organization/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { useGetAllUsersQuery } from "~/store/users/hooks"

import { ApprovalCheck } from "./ApprovalCheck"

interface Props {
    organisationId: string
    objectId: string
    objectType: ApprovalObjectType
    className?: string
    displayType?: "inline" | "block"
    showRetract?: boolean
    showRetractAll?: boolean
    onReviewed?: (boolean) => void
    onApproved?: () => void
    onRefused?: () => void
    onRetract?: () => void
    onApprovalRequired?: () => void
    onRetractAll?: () => void
}

const HTTP_STATUS_NO_CONTENT = 204

const messages = defineMessages({
    approval: { id: "approval.title.approval", defaultMessage: "Approval" },
    approve: { id: "approval.button.approve", defaultMessage: "Approve" },
    refuse: { id: "approval.button.refuse", defaultMessage: "Refuse" },
    retractReview: { id: "approval.button.retractReview", defaultMessage: "Retract review" },
    retractAllReviews: { id: "approval.button.retractAllReviews", defaultMessage: "Invalidate all reviews" },
    invalidationSuccessful: {
        id: "approval.description.invalidationSuccessful",
        defaultMessage: "All reviews have been invalidated.",
    },
    invoiceReviewPrompt: {
        id: "approval.description.invoiceReviewPrompt",
        defaultMessage: "This invoice needs your review",
    },
    partnerReviewPrompt: {
        id: "approval.description.partnerReviewPrompt",
        defaultMessage: "This partner needs your review",
    },
})

const approvalStatusMessages = defineMessages({
    [ApprovalObjectType.INVOICE]: {
        id: "common.invoice",
        defaultMessage: "invoice",
    },
    [ApprovalObjectType.ORGANIZATION_RELATIONSHIP]: {
        id: "common.partner",
        defaultMessage: "partner",
    },
    [ApprovalObjectType.BUDGET]: {
        id: "common.budget",
        defaultMessage: "budget",
    },
    [ApprovalObjectType.PURCHASE_REQUEST]: {
        id: "common.pr",
        defaultMessage: "PR",
    },
    [ApprovalObjectType.PURCHASE_ORDER]: {
        id: "common.po",
        defaultMessage: "PO",
    },
    [ApprovalObjectType.PAYMENT_METHOD_DETAILS]: {
        id: "common.paymentMethodDetails",
        defaultMessage: "payment method details",
    },
    objectAlreadyReviewed: {
        id: "approval.description.objectAlreadyReviewed",
        defaultMessage: "You have already reviewed this {objectType}",
    },
    objectApproved: {
        id: "approval.description.objectApproved",
        defaultMessage: "This {objectType} has been approved.",
    },
    objectRefused: {
        id: "approval.description.objectRefused",
        defaultMessage: "This {objectType} has been refused.",
    },
    objectRetracted: {
        id: "approval.description.objectRetracted",
        defaultMessage: "Your review for this {objectType} has been retracted.",
    },
})

const isPartnerPaymentDetails = (pathname: string) => {
    return pathname.includes("partners") && pathname.includes("payment-details")
}

export const ApprovalBlock = ({
    organisationId,
    objectId,
    objectType,
    onReviewed,
    onApproved,
    onRefused,
    onRetract,
    onApprovalRequired,
    onRetractAll,
    className,
    displayType = "block",
    showRetract = true,
    showRetractAll = false,
}: Props) => {
    const [loading, setLoading] = useState(true)
    const [isReviewing, setIsReviewing] = useState(false)
    const [reviewed, setReviewed] = useState(false)
    const [userIds, setUsersIds] = useState<string[]>([])
    const [approvalResult, setApprovalResult] = useState(ApprovalResult.PENDING)

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId) || ""

    const location = useLocation()

    const isPartnerPaymentDetailApproval = isPartnerPaymentDetails(location.pathname)

    const reviewersId = isPartnerPaymentDetailApproval ? currentOrganizationId : organisationId

    const { formatMessage } = useIntl()

    const userId = useAppSelector(selectUserId)

    const { users } = useGetAllUsersQuery(userIds)
    const { teams } = useFetchOrganizationTeams(reviewersId, true)

    // FIXME: this is a temporary fix to allow the approval of partner payment details
    const tempObjectType = isPartnerPaymentDetailApproval
        ? ApprovalObjectType.PARTNER_PAYMENT_METHOD_DETAILS
        : objectType

    const checkParams = {
        organisationId: isPartnerPaymentDetailApproval ? currentOrganizationId : organisationId,
        objectId,
        objectType: tempObjectType,
    }

    const { data: progressResponse } = useGetObjectChecksQuery(checkParams, {
        skip: !objectId,
    })
    const [approveObject, { data: approveResult, error: approveError }] = useApproveObjectMutation()
    const [refuseObject, { data: refuseResult, error: refuseError }] = useRefuseObjectMutation()
    const [retractReview, { data: retractResult, error: retractError, isLoading: refuseLoading }] =
        useRetractReviewMutation()
    const [retractAllReviews, { data: retractAllResult, error: retractAllError, isLoading: retractAllLoading }] =
        useRetractAllReviewsMutation()

    // Check if the user has already reviewed
    const alreadyReviewed = useCallback(
        (response: ApprovalApiResponse) => {
            return response.checks.some((check) =>
                [...check.review.approvers, ...check.review.refusers].some((reviewer) => reviewer.userId === userId)
            )
        },
        [userId]
    )

    const isApprovalRequired = useCallback(
        (response: ApprovalApiResponse) => {
            return response.checks.some((check) => check.reviewers.some((reviewer) => reviewer.userId === userId))
        },
        [userId]
    )

    // Handle responses from approve or refuse actions
    const handleResponse = useCallback(
        (result, error) => {
            if (result === HTTP_STATUS_NO_CONTENT || error) {
                setIsReviewing(false)
                setReviewed(true)
                // only call the onReviewed callback if the approval was not retracted
                if (onReviewed && approvalResult !== ApprovalResult.PENDING) {
                    onReviewed(approvalResult === ApprovalResult.APPROVED)
                }
            }
        },
        [onReviewed, approvalResult]
    )

    useEffect(() => {
        if (progressResponse) {
            const ids = progressResponse.checks.flatMap((check) => check.reviewers).map((reviewer) => reviewer.userId)
            setUsersIds(ids.filter((id) => id !== undefined))
            // notify the parent component that the approval for this object is required
            if (onApprovalRequired && isApprovalRequired(progressResponse)) {
                onApprovalRequired()
            }
        }
    }, [progressResponse, isApprovalRequired, onApprovalRequired])

    // Effect for handling review status updates
    useEffect(() => handleResponse(approveResult, approveError), [approveResult, approveError, handleResponse])
    useEffect(() => handleResponse(refuseResult, refuseError), [refuseResult, refuseError, handleResponse])
    useEffect(() => {
        if (
            retractResult === HTTP_STATUS_NO_CONTENT ||
            retractAllResult === HTTP_STATUS_NO_CONTENT ||
            retractError ||
            retractAllError
        ) {
            setReviewed(false)
        }
    }, [retractResult, retractAllResult, retractError, retractAllError])

    // Determine if the user has already reviewed or if approval is required
    useEffect(() => {
        if (userId && progressResponse) {
            setLoading(false)
            setReviewed(alreadyReviewed(progressResponse))
        }
    }, [progressResponse, userId, alreadyReviewed])

    // If still loading or no approval required, return null
    if (loading || !progressResponse || !isApprovalRequired(progressResponse)) return null

    // Action handlers
    const handleApprove = async () => {
        setIsReviewing(true)
        const response = await approveObject({ objectId, objectType: tempObjectType }).unwrap()
        if (response === HTTP_STATUS_NO_CONTENT) {
            setApprovalResult(ApprovalResult.APPROVED)
            onApproved?.()
            toast.success(
                formatMessage(approvalStatusMessages.objectApproved, {
                    objectType: formatMessage(approvalStatusMessages[objectType]),
                })
            )
        }
    }

    const handleRefuse = async () => {
        setIsReviewing(true)
        const response = await refuseObject({ objectId, objectType: tempObjectType }).unwrap()
        if (response === HTTP_STATUS_NO_CONTENT) {
            setApprovalResult(ApprovalResult.REFUSED)
            onRefused?.()
            toast.success(
                formatMessage(approvalStatusMessages.objectRefused, {
                    objectType: formatMessage(approvalStatusMessages[objectType]),
                })
            )
        }
    }

    const handleUpdateApproval = async () => {
        const response = await retractReview({ objectId, objectType }).unwrap()
        if (response === HTTP_STATUS_NO_CONTENT) {
            onRetract?.()
            toast.success(
                formatMessage(approvalStatusMessages.objectRetracted, {
                    objectType: formatMessage(approvalStatusMessages[objectType]),
                })
            )
        }
    }

    const handleRetractAll = async () => {
        const response = await retractAllReviews({ objectId, objectType }).unwrap()
        if (response === HTTP_STATUS_NO_CONTENT) {
            onRetractAll?.()
            toast.success(formatMessage(messages.invalidationSuccessful))
        }
    }

    return displayType === "block" ? (
        <Card className={className} title={formatMessage(messages.approval)} expandable>
            {reviewed ? (
                <Stack
                    direction={{ md: "column", lg: "row" }}
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                >
                    <SafeFormattedMessage
                        {...approvalStatusMessages.objectAlreadyReviewed}
                        values={{ objectType: formatMessage(approvalStatusMessages[objectType]) }}
                    />
                    <Button type="primary-light" size="small" onClick={handleUpdateApproval} disabled={refuseLoading}>
                        <SafeFormattedMessage {...messages.retractReview} />
                    </Button>
                </Stack>
            ) : (
                <Stack direction="row" justifyContent="flex-end" gap={1} marginBottom={1}>
                    <Button
                        type="error-light"
                        onClick={handleRefuse}
                        disabled={isReviewing}
                        disableDelay={0}
                        size="small"
                    >
                        <SafeFormattedMessage {...messages.refuse} />
                        <X size={16} />
                    </Button>
                    <Button
                        type="primary-light"
                        onClick={handleApprove}
                        disabled={isReviewing}
                        disableDelay={0}
                        size="small"
                    >
                        <SafeFormattedMessage {...messages.approve} />
                        <Check size={16} />
                    </Button>
                </Stack>
            )}

            {progressResponse.checks.map((check, index) => (
                <ApprovalCheck check={check} key={`${check.name}-${index}`} users={users} teams={teams} />
            ))}
        </Card>
    ) : (
        <Stack flexDirection="row" alignItems="center" gap={1}>
            <TooltipWhite
                arrow
                title={
                    <Box width={250}>
                        {progressResponse.checks.map((check, index) => (
                            <ApprovalCheck
                                isOpen
                                check={check}
                                key={`${check.name}-${index}`}
                                users={users}
                                teams={teams}
                            />
                        ))}
                    </Box>
                }
            >
                <Info size={16} color="var(--color-grey)" />
            </TooltipWhite>
            {reviewed ? (
                <>
                    {showRetract && (
                        <Tooltip arrow title={formatMessage(messages.retractReview)}>
                            <IconButton color="info" onClick={handleUpdateApproval} disabled={refuseLoading}>
                                <X size={20} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {showRetractAll && (
                        <Tooltip arrow title={formatMessage(messages.retractAllReviews)}>
                            <IconButton color="error" onClick={handleRetractAll} disabled={retractAllLoading}>
                                <RotateCcw size={20} />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            ) : (
                <>
                    <Tooltip arrow title={formatMessage(messages.approve)}>
                        <IconButton color="success" onClick={handleApprove} disabled={isReviewing}>
                            <Check size={20} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow title={formatMessage(messages.refuse)}>
                        <IconButton color="error" onClick={handleRefuse} disabled={isReviewing}>
                            <X size={20} />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </Stack>
    )
}
