import { XYPosition } from "@xyflow/react"

import { TRANSACTION_TYPES } from "~/domains/orchestration/flows/const"
import {
    ApiBranch,
    ApiFlow,
    ApiFlowItem,
    ApiFlowNode,
    ApiFlows,
    ApiNodeType,
    ApiReviewer,
    ApiRun,
    ApiRuns,
    Branch,
    Event,
    Flow,
    FlowId,
    FlowNode,
    Flows,
    Node,
    NodeType,
    ObjectType,
    Reviewer,
    Run,
    RunId,
    RunStatus,
    Runs,
    TeamReviewer,
    UserReviewer,
    UserType,
    isPosition,
} from "~/domains/orchestration/flows/types"

const adaptMetadata = (metadata: Record<string, unknown>) => {
    const defaultPosition: XYPosition = { x: 0, y: 0 }

    return {
        position: isPosition(metadata?.position) ? metadata.position : defaultPosition,
        additionalInformation: metadata?.additionalInformation as string | undefined,
    }
}

const adaptReviewerFromApi = (reviewer: ApiReviewer): Reviewer => {
    if ("userId" in reviewer) {
        return {
            userId: reviewer.userId,
            type: UserType.USER,
        } as UserReviewer
    }
    return {
        teamId: reviewer.teamId,
        type: UserType.TEAM,
    } as TeamReviewer
}

const adaptCommonNodeProperties = (node: ApiFlowNode): Node => {
    return {
        slug: node.slug,
        name: node.name || "",
        metadata: adaptMetadata(node.metadata),
    }
}

const adaptExpressionFromApi = (value: string) => {
    if (value.startsWith("'") && value.endsWith("'")) {
        return value.slice(1, -1).replace(/\\/g, "")
    }
    return value
}

const adaptBranchFromApi = (branch: ApiBranch): Branch => {
    return {
        name: branch.name,
        nextNode: branch.nextNode || null,
        conditions: branch.conditions.filter((condition) => condition.length > 0),
    }
}

const adaptTransactionTypeFromApi = (value: string) => {
    const adaptedValue = adaptExpressionFromApi(value)
    const transactionType = TRANSACTION_TYPES.find((type) => type === adaptedValue)
    return transactionType || null
}

const adaptNodeFromApi = (node: ApiFlowNode): FlowNode | null => {
    switch (node.type) {
        case ApiNodeType.EVENT_TRIGGER_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                event: node.event as unknown as Event,
                type: node.type as unknown as NodeType.EVENT_TRIGGER_NODE,
                filter: node.filter ? node.filter : { conditions: [] },
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.IF_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.IF_NODE,
                condition: node.condition,
                nextIfSuccess: node.nextIfTrue || null,
                nextIfFailure: node.nextIfFalse || null,
            }
        case ApiNodeType.SET_PARTNERSHIP_FIELD_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.SET_PARTNERSHIP_FIELD_NODE,
                partnershipId: node.partnershipId,
                fieldToUpdate: node.fieldToUpdate,
                valueToSet: node.valueToSet,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.CHECK_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.CHECK_NODE,
                objectId: node.objectId,
                objectType: node.objectType as unknown as ObjectType,
                reviewers: (node.reviewers || []).map(adaptReviewerFromApi),
                passThreshold: node.passThreshold,
                refuseThreshold: node.refuseThreshold,
                nextIfSuccess: node.nextIfPassed || null,
                nextIfFailure: node.nextIfRefused || null,
            }
        case ApiNodeType.SEND_EMAIL_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.SEND_EMAIL_NODE,
                subject: node.subject,
                recipientAddresses: node.recipientAddresses,
                body: adaptExpressionFromApi(node.body),
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.ADD_TO_BUDGET_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.ADD_TO_BUDGET_NODE,
                amount: node.amount,
                budgetId: adaptExpressionFromApi(node.budgetId),
                transactionId: node.transactionId,
                transactionType: adaptTransactionTypeFromApi(node.transactionType),
                failIfOverbudget: node.failIfOverbudget,
                currency: node.currency || "",
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE,
                invoiceId: node.invoiceId,
                nextIfSuccess: node.nextIfMatched || null,
                nextIfFailure: node.nextIfNotMatched || null,
                metadata: adaptMetadata(node.metadata),
            }
        case ApiNodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE,
                invoiceId: node.invoiceId,
                statusToSet: node.statusToSet,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.FITS_TO_BUDGET_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.FITS_TO_BUDGET_NODE,
                budgetId: adaptExpressionFromApi(node.budgetId),
                transactionId: node.transactionId,
                transactionType: adaptTransactionTypeFromApi(node.transactionType),
                amount: node.amount,
                currency: node.currency,
                nextIfSuccess: node.nextIfFits || null,
                nextIfFailure: node.nextIfDoesNotFit || null,
            }
        case ApiNodeType.ASSIGN_TAG_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.ASSIGN_TAG_NODE,
                objectId: node.objectId,
                objectType: node.objectType as unknown as ObjectType,
                tagId: node.tagId,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.UPDATE_TRIPLETEX_LEDGER_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.UPDATE_TRIPLETEX_LEDGER_NODE,
                ledgerId: node.ledgerId,
                ledgerDate: node.ledgerDate || "",
                ledgerDescription: adaptExpressionFromApi(node.ledgerDescription || ""),
                accountToCredit: node.accountToCredit,
                accountToDebit: node.accountToDebit,
                amount: node.amount,
                amountGross: node.amountGross,
                currency: node.currency,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.APPROVE_PURCHASE_ORDER_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.APPROVE_PURCHASE_ORDER_NODE,
                purchaseOrderId: node.purchaseOrderId,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.APPROVE_PURCHASE_ORDER_LINE_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE,
                purchaseOrderLineId: node.purchaseOrderLineId,
                purchaseOrderId: node.purchaseOrderId,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.CONVERT_PR_TO_PO_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.CONVERT_PR_TO_PO_NODE,
                purchaseRequestId: node.purchaseRequestId,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.APPROVE_PURCHASE_REQUEST_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.APPROVE_PURCHASE_REQUEST_NODE,
                purchaseRequestId: node.purchaseRequestId,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE,
                purchaseRequestId: node.purchaseRequestId,
                purchaseRequestLineId: node.purchaseRequestLineId,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.BRANCH_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.BRANCH_NODE,
                branches: node.branches.filter((branch) => branch.conditions.length > 0).map(adaptBranchFromApi),
                default: node.default,
            }
        case ApiNodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE,
                paymentMethodDetailsId: node.paymentMethodDetailsId,
                fieldToUpdate: node.fieldToUpdate,
                valueToSet: node.valueToSet,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.CREATE_SURVEY_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.CREATE_SURVEY_NODE,
                formId: adaptExpressionFromApi(node.formId),
                respondentOrganizationId: node.respondentOrganizationId || null,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.RETRACT_REVIEWS_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.RETRACT_REVIEWS_NODE,
                objectId: node.objectId,
                objectType: node.objectType as unknown as ObjectType,
                nextNode: node.nextNode || null,
            }
        case ApiNodeType.FETCH_CUSTOM_FIELDS_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as NodeType.FETCH_CUSTOM_FIELDS_NODE,
                objectId: node.objectId,
                nextNode: node.nextNode || null,
            }
        default:
            return null
    }
}

// Adapt ApiFlow to Flow
export const adaptFlowFromApi = (flow: ApiFlow): Flow => {
    return {
        id: flow.id as FlowId,
        version: flow.version,
        name: flow.name,
        enabled: flow.enabled,
        archived: flow.archived,
        createdAt: flow.createdAt,
        nodes: flow.nodes.map(adaptNodeFromApi).filter(Boolean) as FlowNode[],
        author: flow.author,
    }
}

// Adapt ApiFlows to Flows
export const adaptFlowsFromApi = (flow: ApiFlows): Flows => {
    return {
        flows: flow.flows.map((f: ApiFlowItem) => ({
            id: f.id as FlowId,
            version: f.version,
            name: f.name,
            enabled: f.enabled,
            archived: f.archived,
            createdAt: f.createdAt,
        })),
    }
}

// Adapt ApiRuns to Runs
export const adaptRunsFromApi = (run: ApiRuns): Runs => {
    return {
        runs: run.runs.map((r) => ({
            id: r.id as RunId,
            status: r.status as unknown as RunStatus,
            startedAt: r.startedAt,
            finishedAt: r.finishedAt || null,
        })),
    }
}

// Adapt ApiRun to Run
export const adaptRunFromApi = (run: ApiRun): Run => {
    return {
        id: run.id as RunId,
        flowId: run.flowId as FlowId,
        flowVersion: run.flowVersion,
        status: run.status as unknown as RunStatus,
        state: {
            pathTaken: run.state.pathTaken,
        },
        error: run.error,
        startedAt: run.startedAt,
        finishedAt: run.finishedAt || null,
    }
}
