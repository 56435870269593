import React, { useCallback, useState } from "react"
import { Share2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { ShareObjectModal } from "~/components"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { OrganizationId } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"

import { Button } from "../Button/Button"

const messages = defineMessages({
    share: { id: "share.object.modal.share", defaultMessage: "Share" },
})

interface Props {
    organizationId: OrganizationId
    objectType: SharedObjectType
    objectId: string
    buyerOrganization: OrganizationSummary | undefined
    supplierOrganization: OrganizationSummary | undefined
}

export const ShareButton: React.FC<Props> = ({
    organizationId,
    objectType,
    objectId,
    buyerOrganization,
    supplierOrganization,
}) => {
    const { formatMessage } = useIntl()
    const [shareObjectModalOpen, setShareObjectModalOpen] = useState<boolean>(false)

    const showShareObjectModal = useCallback(() => setShareObjectModalOpen(true), [setShareObjectModalOpen])
    const hideShareObjectModal = useCallback(() => setShareObjectModalOpen(false), [setShareObjectModalOpen])

    return (
        <>
            <Button type="menu-item" onClick={showShareObjectModal}>
                <Share2 size={16} />
                <span>{formatMessage(messages.share)}</span>
            </Button>
            {shareObjectModalOpen ? (
                <ShareObjectModal
                    open={shareObjectModalOpen}
                    close={hideShareObjectModal}
                    organizationId={organizationId}
                    objectType={objectType}
                    objectId={objectId}
                    buyerOrganization={buyerOrganization}
                    supplierOrganization={supplierOrganization}
                    sendShareEmail={true}
                />
            ) : null}
        </>
    )
}
