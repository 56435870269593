import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import {
    catalogFromApiAdapters,
    priceFromApiAdapters,
    productFromApiAdapters,
    productVariantFromApiAdapters,
} from "~/domains/catalog/api/adapters/catalogFromApiAdapters"
import {
    catalogToApiAdapters,
    productToApiAdapters,
    productVariantToApiAdapters,
} from "~/domains/catalog/api/adapters/catalogToApiAdapters"
import {
    Catalog,
    CatalogPayload,
    CatalogProduct,
    CatalogProductPayload,
    CatalogProductPrice,
    CatalogProductVariant,
    CatalogProductVariantPayload,
    CatalogSearchQuery,
} from "~/domains/catalog/types/Catalog"

const BASE_URL = import.meta.env.VITE_API_CATALOG_V1_URL

export const catalogApiV1 = createApi({
    reducerPath: "catalogApiV1",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    tagTypes: ["Search", "Catalog", "CatalogProduct", "CatalogProductVariant", "CatalogProductPrice"],
    endpoints: (builder) => ({
        getCatalogs: builder.query<Catalog[], { organizationId: string }>({
            query: ({ organizationId }) => ({
                url: `organizations/${organizationId}/catalogs`,
            }),
            providesTags: ["Catalog"],
            transformResponse: (response: Catalog[]) => {
                return response.map(catalogFromApiAdapters)
            },
        }),

        getCatalogById: builder.query<Catalog, { organizationId: string; catalogId: string }>({
            query: ({ organizationId, catalogId }) => ({
                url: `organizations/${organizationId}/catalogs/${catalogId}`,
            }),
            transformResponse: (response: Catalog) => catalogFromApiAdapters(response),
            providesTags: ["Catalog"],
        }),

        createCatalog: builder.mutation<Catalog, { organizationId: string; catalog: CatalogPayload }>({
            query: ({ organizationId, catalog }) => ({
                url: `organizations/${organizationId}/catalogs`,
                method: "POST",
                body: catalogToApiAdapters(catalog),
            }),
            transformResponse: (response: Catalog) => catalogFromApiAdapters(response),
            invalidatesTags: ["Catalog"],
        }),

        updateCatalog: builder.mutation<
            Catalog,
            { organizationId: string; catalogId: string; catalog: Partial<CatalogPayload> }
        >({
            query: ({ organizationId, catalogId, catalog }) => ({
                url: `organizations/${organizationId}/catalogs/${catalogId}`,
                method: "PATCH",
                body: catalogToApiAdapters(catalog),
            }),
            transformResponse: (response: Catalog) => catalogFromApiAdapters(response),
            invalidatesTags: ["Catalog"],
        }),

        deleteCatalog: builder.mutation<void, { organizationId: string; catalogId: string }>({
            query: ({ organizationId, catalogId }) => ({
                url: `organizations/${organizationId}/catalogs/${catalogId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Catalog"],
        }),

        getCatalogProducts: builder.query<CatalogProduct[], { organizationId: string; catalogId: string }>({
            query: ({ organizationId, catalogId }) => ({
                url: `organizations/${organizationId}/catalogs/${catalogId}/products`,
            }),
            transformResponse: (response: CatalogProduct[]) => response.map(productFromApiAdapters),
            providesTags: ["CatalogProduct"],
        }),

        getProducts: builder.query<CatalogProduct[], { organizationId: string }>({
            query: ({ organizationId }) => ({
                url: `organizations/${organizationId}/products`,
            }),
            transformResponse: (response: CatalogProduct[]) => response.map(productFromApiAdapters),
            providesTags: ["CatalogProduct"],
        }),

        getProductById: builder.query<CatalogProduct, { organizationId: string; productId: string }>({
            query: ({ organizationId, productId }) => ({
                url: `organizations/${organizationId}/products/${productId}`,
            }),
            transformResponse: (response: CatalogProduct) => productFromApiAdapters(response),
            providesTags: ["CatalogProduct"],
        }),

        createProduct: builder.mutation<CatalogProduct, { organizationId: string; product: CatalogProductPayload }>({
            query: ({ organizationId, product }) => ({
                url: `organizations/${organizationId}/products`,
                method: "POST",
                body: productToApiAdapters(product),
            }),
            transformResponse: productFromApiAdapters,
            invalidatesTags: ["CatalogProduct"],
        }),

        updateProduct: builder.mutation<
            CatalogProduct,
            { organizationId: string; productId: string; product: Partial<CatalogProductPayload> }
        >({
            query: ({ organizationId, productId, product }) => ({
                url: `organizations/${organizationId}/products/${productId}`,
                method: "PATCH",
                body: product,
            }),
            transformResponse: (response: CatalogProduct) => productFromApiAdapters(response),
            invalidatesTags: ["CatalogProduct"],
        }),

        addProductToCatalog: builder.mutation<
            CatalogProduct,
            { organizationId: string; catalogId: string; productId: string }
        >({
            query: ({ organizationId, catalogId, productId }) => ({
                url: `organizations/${organizationId}/catalogs/${catalogId}/products/${productId}`,
                method: "POST",
            }),
            invalidatesTags: ["CatalogProduct"],
        }),

        deleteProduct: builder.mutation<void, { organizationId: string; productId: string }>({
            query: ({ organizationId, productId }) => ({
                url: `organizations/${organizationId}/products/${productId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["CatalogProduct", "Search"],
        }),

        getProductVariants: builder.query<CatalogProductVariant[], { organizationId: string; productId: string }>({
            query: ({ organizationId, productId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants`,
            }),
            transformResponse: (response: CatalogProductVariant[]) => response.map(productVariantFromApiAdapters),
            providesTags: ["CatalogProductVariant"],
        }),

        getProductVariantById: builder.query<
            CatalogProductVariant,
            { organizationId: string; productId: string; variantId: string }
        >({
            query: ({ organizationId, productId, variantId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}`,
            }),
            transformResponse: (response: CatalogProductVariant) => productVariantFromApiAdapters(response),
            providesTags: ["CatalogProductVariant"],
        }),

        createProductVariant: builder.mutation<
            CatalogProductVariant,
            { organizationId: string; productId: string; productVariant: CatalogProductVariantPayload }
        >({
            query: ({ organizationId, productId, productVariant }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants`,
                method: "POST",
                body: productVariantToApiAdapters(productVariant),
            }),
            transformResponse: (response: CatalogProductVariant) => productVariantFromApiAdapters(response),
            invalidatesTags: ["CatalogProductVariant"],
        }),

        deleteProductVariant: builder.mutation<void, { organizationId: string; productId: string; variantId: string }>({
            query: ({ organizationId, productId, variantId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["CatalogProductVariant"],
        }),

        getPrices: builder.query<
            CatalogProductPrice[],
            { organizationId: string; productId: string; variantId: string }
        >({
            query: ({ organizationId, productId, variantId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/prices`,
            }),
            transformResponse: (response: CatalogProductPrice[]) => response.map(priceFromApiAdapters),
            providesTags: ["CatalogProductPrice"],
        }),

        getPriceById: builder.query<
            CatalogProductPrice,
            { organizationId: string; productId: string; variantId: string; priceId: string }
        >({
            query: ({ organizationId, productId, variantId, priceId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/prices/${priceId}`,
            }),
            transformResponse: (response: CatalogProductPrice) => priceFromApiAdapters(response),
            providesTags: ["CatalogProductPrice"],
        }),

        searchProducts: builder.query<CatalogProductVariant[], { organizationId: string; query: CatalogSearchQuery }>({
            query: ({ organizationId, query: { q, minPrice, maxPrice, catalogId } }) => {
                const params = new URLSearchParams()
                if (q !== undefined) params.append("q", q)
                if (minPrice !== undefined) params.append("minPrice", minPrice.toString())
                if (maxPrice !== undefined) params.append("maxPrice", maxPrice.toString())
                catalogId?.forEach((id) => params.append("catalogId", id))

                return {
                    url: `organizations/${organizationId}/variants/search?${params.toString()}`,
                }
            },
            providesTags: ["Search"],
            transformResponse: (response: CatalogProductVariant[]) => response.map(productVariantFromApiAdapters),
        }),
    }),
})

export const {
    useGetCatalogsQuery,
    useGetCatalogByIdQuery,
    useCreateCatalogMutation,
    useUpdateCatalogMutation,
    useDeleteCatalogMutation,
    useGetCatalogProductsQuery,
    useGetProductsQuery,
    useGetProductByIdQuery,
    useCreateProductMutation,
    useCreateProductVariantMutation,
    useUpdateProductMutation,
    useAddProductToCatalogMutation,
    useDeleteProductMutation,
    useGetProductVariantsQuery,
    useGetProductVariantByIdQuery,
    useDeleteProductVariantMutation,
    useSearchProductsQuery,
} = catalogApiV1
