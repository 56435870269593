import { Edit2 } from "react-feather"

import { SetPartnershipConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { SetPartnershipFieldNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    Node,
    NodeType,
    SetPartnershipFieldNode as SetPartnershipFieldNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const setPartnershipFieldNode: Omit<SetPartnershipFieldNodeType, keyof Node> = {
    partnershipId: `{{ trigger_event.id }}`,
    fieldToUpdate: ``,
    valueToSet: ``,
    nextNode: null,
    type: NodeType.SET_PARTNERSHIP_FIELD_NODE,
}

const advancedFields: (keyof typeof setPartnershipFieldNode)[] = [
    AllAdvancedFieldsUnion.FIELD_TO_UPDATE,
    AllAdvancedFieldsUnion.VALUE_TO_SET,
    AllAdvancedFieldsUnion.PARTNERSHIP_ID,
]

const setPartnershipFieldConfig: Configuration<NodeType.SET_PARTNERSHIP_FIELD_NODE> = {
    type: NodeType.SET_PARTNERSHIP_FIELD_NODE,
    baseSlug: "set-partnership-field",
    Icon: Edit2,
    Configuration: SetPartnershipConfiguration,
    advancedFields,
    Node: SetPartnershipFieldNode,
    factory: (props: FactoryProps): SetPartnershipFieldNodeType => ({
        ...setPartnershipFieldNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { setPartnershipFieldConfig, advancedFields as setPartnershipFieldAdvancedFields }
