import { Box, IconButton, Stack, Typography } from "@mui/material"
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Flag, Maximize, Maximize2, Minimize, Minimize2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { TagsSelectorForLineCells } from "~/domains/tags/components/TagsSelector/TagsSelectorForLineCells"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { TagGroupI, TagObjectType } from "~/domains/tags/types"
import { useTagsForLines } from "~/domains/transactions/components/Items/hooks/useTagsForLines"
import { LinesTabs } from "~/domains/transactions/custom-fields/components/LinesTabs"
import { CustomFieldObjectType } from "~/domains/transactions/custom-fields/types/CustomFieldObjectType"
import "~/domains/transactions/invoices/components/InvoiceLines/InvoiceLines.scss"
import { InvoiceLinesTotal } from "~/domains/transactions/invoices/components/InvoiceLines/InvoiceLinesTotal"
import { ModalSuggestVatRateForLines } from "~/domains/transactions/invoices/components/InvoiceLines/ModalSuggestVatRateForLines"
import { VirtualLines } from "~/domains/transactions/invoices/components/InvoiceLines/VirtualLines"
import {
    InvoiceLineErrorType,
    computeInvoiceLinesTotals,
    getOcrInvoiceCurrency,
} from "~/domains/transactions/invoices/core"
import {
    VerifyInvoiceDiscountResult,
    verifyInvoiceDiscount,
} from "~/domains/transactions/invoices/core/invoiceDiscount"
import { tryComputeInvoiceLinesTaxes } from "~/domains/transactions/invoices/core/tryComputeInvoiceLinesTaxes"
import { useVatRates } from "~/domains/transactions/invoices/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { useCurrentOrganization } from "~/store/organization/hooks"
import {
    CountryCode,
    CurrencyCodes,
    DocumentWithLines,
    DocumentWithPrice,
    DocumentWithVersion,
    InvoiceLineI,
    InvoiceStatus,
    InvoiceWithId,
    InvoiceWithStatus,
} from "~/types"
import { VatRateI } from "~/types/VatRate"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"
import { isDefined } from "~/utils/isDefined"

const messages = defineMessages({
    title: {
        id: "invoice.invoiceLines.title",
        defaultMessage: "{countItems, plural, =0 {no items} one {# item} other {# items}}",
    },
    addNewAction: {
        id: "invoice.invoiceLines.addNewItem",
        defaultMessage: "Add new items",
    },
    saveAction: {
        id: "invoice.invoiceLines.SaveAction",
        defaultMessage: "Save",
    },
    cancelAction: {
        id: "invoice.invoiceLines.CancelAction",
        defaultMessage: "Cancel",
    },
    totalPriceAllTaxesIncluded: {
        id: "invoice.invoiceLines.totalPriceAllTaxesIncluded",
        defaultMessage: "Total incl. VAT",
    },
    totalPriceExcludedTaxes: {
        id: "invoice.invoiceLines.totalPriceExcludedTaxes",
        defaultMessage: "Total excl. VAT",
    },
    totalSumDoesNotMatchInvoiceTotal: {
        id: "invoice.invoiceLines.errors.totalSumDoesNotMatchInvoiceTotal",
        defaultMessage: "The total of all items does not match the invoice total.",
    },
    totalTaxExcludedSumDoesNotMatchInvoiceTotal: {
        id: "invoice.invoiceLines.errors.totalTaxExcludedSumDoesNotMatchInvoiceTotal",
        defaultMessage: "The total tax excluded of all items does not match the invoice tax excluded total",
    },
    noItems: {
        id: "invoice.invoiceLines.noItems",
        defaultMessage: "Any items reconize here!",
    },
})

type UpdateOrReadOnlyProps =
    | {
          readonly: true
          updateData?: never
      }
    | {
          readonly?: false
          updateData: (data: Partial<DocumentWithLines>) => void
      }

type Props = {
    dataLoaded: boolean
    invoice: DocumentWithLines & DocumentWithPrice & InvoiceWithId & InvoiceWithStatus & DocumentWithVersion
    countryCode: CountryCode
    setDiscountErrorResult?: (error: VerifyInvoiceDiscountResult | null) => void
    formProps?: React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
    isReadOnly?: boolean
    highlightVATMissingLines?: boolean
} & UpdateOrReadOnlyProps

interface SuggestedTaxRateForLines {
    taxRate: VatRateI
    linePositions: number[]
}

const getSuggestedTaxRateProps = (
    suggestedTaxRate: SuggestedTaxRateForLines | null,
    lines: InvoiceLineI[],
    simplyFill: boolean
): ModalSuggestVatRateForLines => {
    if (!suggestedTaxRate) return { open: false, vatRate: null, lines: null }
    return {
        open: true,
        vatRate: suggestedTaxRate.taxRate,
        lines: lines.filter((line) => suggestedTaxRate.linePositions.indexOf(line.linePosition) >= 0),
        simplyFill,
    }
}

const attributeVatRateToLine = (invoiceLine: InvoiceLineI, rate: VatRateI): InvoiceLineI => {
    const totalTax = (invoiceLine.totalExcludedTaxes * rate.rate) / 100
    return {
        ...invoiceLine,
        taxRateId: rate.id,
        totalTax,
        total: invoiceLine.totalExcludedTaxes + totalTax,
    }
}

const NO_LINES: InvoiceLineI[] = []

export const InvoiceLines = forwardRef<HTMLFormElement, Props>(function InvoiceLines(
    {
        invoice,
        updateData,
        countryCode,
        dataLoaded,
        formProps,
        setDiscountErrorResult,
        readonly = false,
        isReadOnly,
        highlightVATMissingLines,
    },
    ref
) {
    const { formatMessage } = useIntl()
    const [totalIsValid, setTotalIsValid] = useState<boolean>(true)
    const [totalExcludedTaxesIsValid, setTotalExcludedTaxesIsValid] = useState<boolean>(true)
    const vatRates = useVatRates(countryCode, invoice.version)
    const currency = getOcrInvoiceCurrency(invoice)

    const user = useAppSelector(selectUser)
    const organization = useCurrentOrganization(user.organizations)

    const lines = invoice.lines ?? NO_LINES
    const totals = useMemo(() => computeInvoiceLinesTotals(lines), [lines])

    const [opened, setOpened] = useState<boolean>(false)
    const [fullScreen, setFullScreen] = useState<boolean>(false)
    const [simplyFillTaxRate, setSimplyFillTaxRate] = useState<boolean>(false)

    const linesPreviewTimeout = useRef<number>()

    const [suggestTaxRateForLines, setSuggestTaxRateForLines] = useState<SuggestedTaxRateForLines | null>(null)

    const finalIsReadonly = isReadOnly ?? readonly

    const closeSuggestTaxRate = useCallback(() => setSuggestTaxRateForLines(null), [])
    const { tagGroups, refetchTagGroups } = useOrganizationTagGroups(organization?.id)
    const {
        selectedTags: tags,
        setSelectedTags: setTags,
        fetchTags,
    } = useTagsForLines({
        lines: lines.map((line) => ({ ...line, id: `${invoice.id}-${line.linePosition}` })),
        organizationId: organization?.id,
        tagGroups,
        objectType: TagObjectType.INVOICE_LINE,
    })

    useEffect(() => {
        if (organization?.id) {
            refetchTagGroups()
        }
    }, [organization?.id])

    useEffect(() => {
        if (tagGroups && tagGroups.length) {
            fetchTags()
        }
    }, [tagGroups])

    useEffect(() => {
        setOpened(highlightVATMissingLines || false)
    }, [highlightVATMissingLines])

    useEffect(() => {
        if (isDefined(invoice.totalDiscount) && invoice.totalDiscount !== 0 && setDiscountErrorResult) {
            if (dataLoaded && invoice.total && invoice.totalExcludedTaxes && lines.length && vatRates.length) {
                const result = verifyInvoiceDiscount(
                    invoice.totalExcludedTaxes,
                    invoice.total,
                    invoice.totalDiscount,
                    lines,
                    totals,
                    vatRates
                )
                setDiscountErrorResult(result)
            }
        } else {
            const isValidTotal = !lines.length || invoice.total === totals.total
            const isValidTotalExcludedTaxes = !lines.length || invoice.totalExcludedTaxes === totals.totalExcludedTaxes
            setTotalIsValid(isValidTotal)
            setTotalExcludedTaxesIsValid(isValidTotalExcludedTaxes)
            if (setDiscountErrorResult && invoice.totalDiscount === 0) {
                setDiscountErrorResult(null)
            }
        }
    }, [
        dataLoaded,
        invoice.total,
        invoice.totalExcludedTaxes,
        invoice.totalDiscount,
        lines,
        totals,
        vatRates,
        finalIsReadonly,
        setDiscountErrorResult,
    ])

    useEffect(() => {
        if (opened) {
            document.body.classList.add("lines-opened")
        } else {
            document.body.classList.remove("lines-opened")
        }
    }, [opened])

    useEffect(() => {
        if (
            updateData &&
            !totalIsValid &&
            lines.length &&
            vatRates.length &&
            invoice.total &&
            lines.filter((line) => !line.taxRateId).length === lines.length
        ) {
            const matchingRate = tryComputeInvoiceLinesTaxes(lines, vatRates, invoice.total)
            if (matchingRate) {
                updateData({ lines: lines.map((line) => attributeVatRateToLine(line, matchingRate)) })
            }
        }
    }, [updateData, totalIsValid, lines, vatRates, invoice.total])

    const toggleOpen = useCallback(() => {
        window.clearTimeout(linesPreviewTimeout.current)
        linesPreviewTimeout.current = undefined
        setOpened((opened) => !opened)
    }, [opened, setOpened])

    const toggleFullScreen = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation()
            setFullScreen((fullScreen) => !fullScreen)
            if (!opened) {
                toggleOpen()
            }
        },
        [opened, toggleOpen]
    )

    const addLine = useCallback(() => {
        if (!updateData) return
        const maxPosition = lines.reduce((maxPosition, line) => {
            if (line.linePosition > maxPosition) {
                return line.linePosition
            }
            return maxPosition
        }, 0)
        const mostCommonTaxId = (
            Object.entries(
                lines.reduce((taxIds, line) => {
                    if (line.taxRateId) {
                        taxIds[line.taxRateId] = (taxIds[line.taxRateId] ?? 0) + 1
                    }
                    return taxIds
                }, {})
            ) as [string, number][]
        ).reduce((maxTaxId: { taxId: string; count: number } | null, [taxId, count]: [string, number]) => {
            if (!maxTaxId || maxTaxId.count < count) {
                return { taxId, count }
            }
            return maxTaxId
        }, null)
        updateData({
            lines: [
                ...lines,
                {
                    linePosition: maxPosition + 1,
                    quantity: 1,
                    unitPrice: 0,
                    totalExcludedTaxes: 0,
                    total: 0,
                    totalTax: 0,
                    taxRateId: mostCommonTaxId ? mostCommonTaxId.taxId : null,
                } as InvoiceLineI,
            ],
        })
        setOpened(true)
    }, [lines, updateData, finalIsReadonly])

    const deleteLine = useCallback(
        (linePosition: number) => {
            if (!updateData) return Promise.resolve(false)
            updateData({
                lines: lines.filter((line) => line.linePosition !== linePosition),
            })
            return Promise.resolve(true)
        },
        [updateData, lines]
    )

    const bulkDelete = useCallback(
        (deletedLinePositions) => {
            if (!updateData) return false
            updateData({
                lines: lines.filter((line) => !deletedLinePositions.includes(line.linePosition)),
            })
            return true
        },
        [updateData, lines]
    )

    const onLineChange = useCallback(
        (linePosition: number, updatePayload: Partial<InvoiceLineI>) => {
            if (!updateData) return
            if (updatePayload.taxRateId) {
                const taxRate = vatRates.find((vatRate) => vatRate.id === updatePayload.taxRateId)
                if (taxRate) {
                    const otherLinesWithMatchingTaxRate = lines.filter(
                        (line) =>
                            line.linePosition !== linePosition &&
                            !line.taxRateId &&
                            Math.round(line.totalTax * 100) === Math.round(line.totalExcludedTaxes * taxRate.rate)
                    )
                    if (otherLinesWithMatchingTaxRate.length > 0) {
                        setSuggestTaxRateForLines({
                            taxRate,
                            linePositions: otherLinesWithMatchingTaxRate.map((line) => line.linePosition),
                        })
                        setSimplyFillTaxRate(false)
                    } else {
                        const otherLinesWithoutTaxRate = lines.filter(
                            (line) => line.linePosition !== linePosition && !line.taxRateId
                        )
                        if (otherLinesWithoutTaxRate.length > 0) {
                            setSuggestTaxRateForLines({
                                taxRate,
                                linePositions: otherLinesWithoutTaxRate.map((line) => line.linePosition),
                            })
                            setSimplyFillTaxRate(true)
                        } else {
                            setSimplyFillTaxRate(false)
                        }
                    }
                }
            }
            updateData({
                lines: lines.map((line) => {
                    if (line.linePosition !== linePosition) {
                        return line
                    } else {
                        const updatedLine = {
                            ...line,
                            ...updatePayload,
                        }
                        if (
                            isDefined(updatedLine.quantity) &&
                            isDefined(updatedLine.unitPrice) &&
                            !isDefined(updatePayload.totalExcludedTaxes)
                        ) {
                            updatedLine.totalExcludedTaxes = updatedLine.quantity * updatedLine.unitPrice
                        }
                        if (
                            isDefined(updatedLine.totalExcludedTaxes) &&
                            updatedLine.taxRateId &&
                            !updatePayload.total
                        ) {
                            const taxRate = vatRates.find((vatRate) => vatRate.id === updatedLine.taxRateId)
                            if (taxRate) {
                                const taxAmount = (updatedLine.totalExcludedTaxes * taxRate.rate) / 100
                                updatedLine.totalTax = taxAmount
                                updatedLine.total = taxAmount + updatedLine.totalExcludedTaxes
                            }
                        }
                        return updatedLine
                    }
                }),
            })
        },
        [updateData, lines, vatRates]
    )

    useEffect(() => {
        if (updateData && lines && totals.errors) {
            const updates = lines
                .map((line) => {
                    return { line, error: totals.errors.find((error) => error.linePosition === line.linePosition) }
                })
                .map(({ line, error }) => {
                    if (error && error.error === InvoiceLineErrorType.TotalDoesNotMatchComputedTotal) {
                        return { linePosition: line.linePosition, update: { total: error.computedValueError } }
                    }
                    if (
                        error &&
                        error.error === InvoiceLineErrorType.UnitPriceAndQuantityDoesNotMatchTotalExcludedTaxes
                    ) {
                        // If the total difference in less than a cent we fix it automatically
                        if (Math.abs(error.computedValueError - error.referenceValue) < 0.01) {
                            return {
                                linePosition: line.linePosition,
                                update: { totalExcludedTaxes: error.computedValueError },
                            }
                        }
                    }
                })
                .filter(isDefined)
                .reduce((acc, update) => {
                    acc[update.linePosition] = update.update
                    return acc
                }, {})
            if (Object.keys(updates).length > 0) {
                updateData({
                    lines: lines.map((line) => {
                        const updatePayload = updates[line.linePosition]
                        if (!updatePayload) return line
                        return {
                            ...line,
                            ...updatePayload,
                        }
                    }),
                })
            }
        }
    }, [totals.errors, updateData, lines])

    const confirmSuggestedTaxRate = useCallback(() => {
        if (!updateData || !suggestTaxRateForLines) return
        updateData({
            lines: lines.map((line) => {
                if (suggestTaxRateForLines.linePositions.indexOf(line.linePosition) === -1) {
                    return line
                }
                return {
                    ...line,
                    taxRateId: suggestTaxRateForLines.taxRate.id,
                    totalTax: (line.totalExcludedTaxes * suggestTaxRateForLines.taxRate.rate) / 100,
                }
            }),
        })
        closeSuggestTaxRate()
    }, [lines, suggestTaxRateForLines, updateData])

    const renderLineTags = useCallback(
        (invoiceLine: InvoiceLineI, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => {
            if (!user || !organization) return null

            const objectId = `${invoice.id}-${invoiceLine.linePosition}`
            return (
                <TagsSelectorForLineCells
                    objectId={objectId}
                    tags={tags}
                    setTags={setTags}
                    tagGroups={tagGroups ?? []}
                    tagGroupId={tagGroupId}
                    usedTagGroups={usedTagGroups}
                />
            )
        },
        [user, organization, tags, setTags, invoice, readonly]
    )

    const linesForESG = useMemo(
        () => lines.map((line) => ({ ...line, id: `${invoice.id}-${line.linePosition}` })),
        [invoice.lines, invoice.id]
    )

    const hasCustomFields = useMemo(
        () =>
            invoice.status === InvoiceStatus.DRAFT && isFeatureEnabled(Features.InvoiceCustomFields, organization?.id),
        [invoice.status, organization?.id]
    )

    if (finalIsReadonly && lines.length === 0) return null

    return (
        <aside
            className={`invoice-lines-container ${opened || fullScreen ? "is-opened" : "is-closed"} ${
                fullScreen ? " is-fullscreen" : ""
            }`}
        >
            <Box>
                <form {...formProps} ref={ref}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
                        <h4 className="invoice-lines-title">
                            <span onClick={toggleOpen}>
                                {formatMessage(messages.title, { countItems: lines.length })}
                            </span>
                            {!finalIsReadonly && (
                                <button className="invoice-lines-add-btn" onClick={addLine} type="button">
                                    {formatMessage(messages.addNewAction)}
                                </button>
                            )}
                        </h4>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
                            {lines.length > 0 && (
                                <div className="invoice-lines-totals" onClick={toggleOpen}>
                                    <InvoiceLinesTotal
                                        label={formatMessage(messages.totalPriceExcludedTaxes)}
                                        total={totals.totalExcludedTaxes}
                                        currency={currency}
                                        error={
                                            !totalExcludedTaxesIsValid
                                                ? messages.totalTaxExcludedSumDoesNotMatchInvoiceTotal
                                                : null
                                        }
                                    />
                                    <InvoiceLinesTotal
                                        label={formatMessage(messages.totalPriceAllTaxesIncluded)}
                                        total={totals.total}
                                        currency={currency}
                                        error={!totalIsValid ? messages.totalSumDoesNotMatchInvoiceTotal : null}
                                    />
                                </div>
                            )}
                            <IconButton onClick={toggleOpen}>
                                {opened ? (
                                    <Minimize2 size={26} color="var(--color-light-grey)" />
                                ) : (
                                    <Maximize2 size={26} color="var(--color-light-grey)" />
                                )}
                            </IconButton>
                            <IconButton onClick={toggleFullScreen}>
                                {fullScreen ? (
                                    <Minimize size={26} color="var(--color-light-grey)" />
                                ) : (
                                    <Maximize size={26} color="var(--color-light-grey)" />
                                )}
                            </IconButton>
                        </Stack>
                    </Stack>

                    <div className={`invoice-lines-content ${opened ? "is-open" : "is-hidden"}`}>
                        {dataLoaded && lines.length > 0 ? (
                            <LinesTabs
                                organizationId={organization?.id}
                                items={linesForESG}
                                renderLineTags={renderLineTags}
                                idKey="id"
                                contextType={CustomFieldObjectType.INVOICE}
                                contextId={invoice.id}
                                currency={CurrencyCodes.EUR}
                                hasCustomFields={hasCustomFields}
                            >
                                <VirtualLines
                                    organizationId={organization?.id}
                                    invoice={invoice}
                                    user={user}
                                    countryCode={countryCode}
                                    onChange={onLineChange}
                                    onDelete={deleteLine}
                                    fieldClassName="invoice-line-modalfield"
                                    errors={totals.errors}
                                    readonly={finalIsReadonly}
                                    onBulkDelete={bulkDelete}
                                    highlightVATMissingLines={highlightVATMissingLines}
                                />
                            </LinesTabs>
                        ) : (
                            <div className="invoice-lines-nolines">
                                <figure className="invoice-lines-nolines-icon">
                                    <Flag color="var(--color-yellow)" />
                                </figure>
                                <Typography className="invoice-lines-nolines-text">
                                    {formatMessage(messages.noItems)}
                                </Typography>
                            </div>
                        )}
                    </div>
                </form>
            </Box>
            <ModalSuggestVatRateForLines
                {...getSuggestedTaxRateProps(suggestTaxRateForLines, lines, simplyFillTaxRate)}
                onClose={closeSuggestTaxRate}
                onConfirm={confirmSuggestedTaxRate}
            />
        </aside>
    )
})
