import { Stack, Tooltip } from "@mui/material"
import { FC, useMemo } from "react"
import { FormattedNumber, defineMessages, useIntl } from "react-intl"

import { Card, DataTable, DataTableColumn, SafeFormattedMessage, StatusChip } from "~/components"
import { globalStatus } from "~/constants/globalStatus"
import { TagObjectType } from "~/domains/tags/types"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import { formatCustomFields } from "~/domains/transactions/invoices-v1/helpers/formatCustomFields"
import { InvoiceFieldLevel } from "~/domains/transactions/invoices-v1/types/Invoice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceI, InvoiceLineI } from "~/types"

import "./Items.scss"
import { LineTags } from "./LineTags"

const messages = defineMessages({
    items: {
        id: "purchase.requests.request.items",
        defaultMessage: "Items",
    },
    editItem: {
        id: "purchase.requests.request.items.edit",
        defaultMessage: "Edit items",
    },
    addItem: {
        id: "purchase.requests.request.items.addItem",
        defaultMessage: "Add new items",
    },
    description: {
        id: "purchase.requests.request.items.description",
        defaultMessage: "Description",
    },
    quantity: {
        id: "purchase.requests.request.items.quantity",
        defaultMessage: "Qty",
    },
    taxRate: {
        id: "purchase.requests.request.items.taxRate",
        defaultMessage: "Tax",
    },
    priceHT: {
        id: "purchase.requests.request.items.priceHT",
        defaultMessage: "Price (tax excl)",
    },
    priceTTC: {
        id: "purchase.requests.request.items.priceTTC",
        defaultMessage: "Price (tax incl)",
    },
    actions: {
        id: "purchase.requests.request.items.actions",
        defaultMessage: "Actions",
    },
    total: {
        id: "purchase.requests.request.items.total",
        defaultMessage: "Total",
    },
    approval: {
        id: "purchase.requests.request.items.approval",
        defaultMessage: "Approval status",
    },
})

interface ItemsInvoiceProps {
    invoice: InvoiceI
    mode: PurchaseViewType
}

export const ItemsInvoice: FC<ItemsInvoiceProps> = ({ invoice, mode }) => {
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const lines = invoice.lineItems || []
    const totalPriceWithoutTax = invoice.totalPriceWithoutTax
    const totalAmountDue = invoice.totalAmountDue

    const party = invoice.parties?.[organizationId || ""]

    const linesWithCustomFields = lines.map((line) => {
        if (!organizationId) return null

        const lineParty = line.parties?.[organizationId]

        if (!lineParty) return line

        const customFieldsFormatted = formatCustomFields(party, InvoiceFieldLevel.Line, lineParty)

        return {
            ...line,
            customFields: customFieldsFormatted,
        }
    })

    const columns = useMemo(() => {
        const templateLineCustomFields = party?.template?.customFields.filter(
            ({ level }) => level === InvoiceFieldLevel.Line
        )

        const customFieldColumns = (templateLineCustomFields || []).map(({ id, name, description }) => ({
            id: `customField_${id}`,
            key: `customField_${id}`,
            label: name || "-",
            renderCell: () => {
                return (
                    <Tooltip title={description || ""}>
                        <span>{linesWithCustomFields[0]?.customFields?.[id]?.toString() || "-"}</span>
                    </Tooltip>
                )
            },
        }))

        return [
            {
                id: "description",
                key: "description",
                label: formatMessage(messages.description),
                sorter: true,
                renderCell: ({ description }) => (
                    <Tooltip title={description}>
                        <span className="truncate-text item-description">{description}</span>
                    </Tooltip>
                ),
                aggregationFunction: () => formatMessage(messages.total),
            },
            ...customFieldColumns,
            {
                id: "quantity",
                key: "quantity",
                label: formatMessage(messages.quantity),
                sorter: true,
            },
            {
                id: "taxRate",
                key: "tax",
                label: formatMessage(messages.taxRate),
                sorter: true,
                renderCell: ({ tax }) => (+(tax || 1) / 100).toFixed(1) + "%",
            },
            {
                id: "totalAmountExcludingTax",
                key: "totalExcludedTaxes",
                label: formatMessage(messages.priceHT),
                sorter: true,
                renderCell: ({ priceWithoutTax, currency }) => (
                    <FormattedNumber value={+priceWithoutTax} currency={currency} style="currency" />
                ),
                aggregationFunction: () => (
                    <Stack>
                        {Object.entries(totalPriceWithoutTax).map(([currency, amount]) => (
                            <p key={currency}>
                                <FormattedNumber value={+amount} currency={currency} style="currency" />
                            </p>
                        ))}
                    </Stack>
                ),
            },
            {
                id: "totalAmount",
                key: "total",
                label: formatMessage(messages.priceTTC),
                sorter: true,
                renderCell: ({ total, currency }) => (
                    <FormattedNumber value={+total} currency={currency} style="currency" />
                ),
                aggregationFunction: () => (
                    <Stack>
                        {Object.entries(totalAmountDue).map(([currency, amount]) => (
                            <p key={currency}>
                                <FormattedNumber value={+amount} currency={currency} style="currency" />
                            </p>
                        ))}
                    </Stack>
                ),
            },
            {
                id: "approvalStatus",
                key: "approvalStatus",
                label: formatMessage(messages.approval),
                sorter: true,
                renderCell: ({ approvalStatus }) =>
                    !!approvalStatus && (
                        <StatusChip status={approvalStatus}>
                            <SafeFormattedMessage {...(globalStatus[approvalStatus]?.message || "")} />
                        </StatusChip>
                    ),
            },
            {
                id: "tags",
                label: "tags",
                renderCell: (line: InvoiceLineI) => (
                    <LineTags
                        organizationId={organizationId || ""}
                        line={line}
                        objectId={line.id}
                        objectType={TagObjectType.PURCHASE_REQUEST_LINE}
                    />
                ),
            },
        ]
    }, [])

    return (
        <Card className={"items " + (mode === PurchaseViewType.EDIT ? "editable" : "")}>
            {Object.values(linesWithCustomFields).length ? (
                <DataTable
                    classNames="purchase-requests-table"
                    hidePagination={true}
                    data={Object.values(linesWithCustomFields) as unknown as InvoiceLineI[]}
                    withAggregatedFooter
                    columns={columns as DataTableColumn<InvoiceLineI>[]}
                />
            ) : null}
        </Card>
    )
}
