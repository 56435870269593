import { Autocomplete, TextField, TextFieldProps, TextFieldVariants } from "@mui/material"

import { Loader } from "~/components"
import { PaymentMethod } from "~/domains/payment/payment-methods/types/PaymentMethod"

interface PaymentMethodsSelectorProps {
    value: string | null
    label: string
    items: PaymentMethod[]
    variant?: TextFieldVariants
    required?: boolean
    disabled?: boolean
    loading?: boolean
    onChange: (value: PaymentMethod | null) => void
}

export const PaymentMethodsSelector = ({
    value,
    label,
    onChange,
    items,
    required = false,
    disabled = false,
    loading = false,
}: PaymentMethodsSelectorProps) => {
    const inputValue = items?.find((item) => item.id === value) || null

    const renderInput = (params: TextFieldProps) => (
        <TextField
            {...params}
            required={required}
            label={label}
            fullWidth
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <>
                        {loading ? <Loader small /> : null} {params.InputProps?.endAdornment}
                    </>
                ),
            }}
        />
    )

    const handleChange = (_: React.SyntheticEvent<Element, Event>, newValue: PaymentMethod | null): void => {
        onChange(newValue)
    }

    return (
        <Autocomplete
            value={inputValue as PaymentMethod}
            disabled={disabled}
            options={items ?? []}
            getOptionLabel={(option) => option.name}
            onChange={handleChange}
            renderInput={renderInput}
        />
    )
}
