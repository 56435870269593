import { useCallback, useState } from "react"
import { defineMessage, useIntl } from "react-intl"

import { FetchErrorType, useWorkflowsApi } from "~/domains/orchestration/flows-v0/core/workflowsApi"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { getResultErrorValue, isResultSuccess } from "~/types/Result"

import { selectInvoiceWorkflowCheck, workflowActions } from "../workflowSlice"

const errorMessage = defineMessage({
    id: "workflow.purchaseRequest.errorFetchingCheck",
    defaultMessage: "Unable to fetch workflow check status",
})

export const useFetchInvoiceWorkflows = (
    organizationId: OrganizationId | undefined | null,
    invoiceId: string | undefined
) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const workflowsApi = useWorkflowsApi()
    const invoiceWorkflowCheck = useAppSelector(selectInvoiceWorkflowCheck)
    const invoiceChecks = invoiceId ? invoiceWorkflowCheck[invoiceId] : undefined

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const fetchInvoiceChecks = useCallback(
        async (retry: number = 0) => {
            if (invoiceId && organizationId) {
                setLoading(true)
                setError(undefined)
                setTimeout(async () => {
                    const result = await workflowsApi.getInvoiceChecks(organizationId, invoiceId)
                    if (isResultSuccess(result)) {
                        dispatch(
                            workflowActions.setInvoiceWorkflowChecks({
                                invoiceId,
                                check: result.result,
                            })
                        )
                    } else {
                        const error = getResultErrorValue(result)
                        if (error.type === FetchErrorType.HTTP_REQUEST_ERROR && error.code === 404 && retry < 5) {
                            window.setTimeout(() => fetchInvoiceChecks(retry + 1), 200)
                        }
                        setError(formatMessage(errorMessage))
                    }
                    setLoading(false)
                }, 500)
            }
        },
        [dispatch, workflowsApi, organizationId]
    )

    return { invoiceChecks, fetchInvoiceChecks, loading, error }
}
