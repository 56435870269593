import {
    ApiBranch,
    ApiEvent,
    ApiEventTriggerNode,
    ApiFlowNode,
    ApiFlowUpdate,
    ApiNewFlow,
    ApiNode,
    ApiNodeType,
    ApiObjectType,
    ApiReviewer,
    ApiTeamReviewer,
    ApiUserReviewer,
    ApiUserType,
    Branch,
    CreateFlowBody,
    Flow,
    FlowNode,
    NodeType,
    Reviewer,
    TransactionType,
    isApiFlowNode,
} from "~/domains/orchestration/flows/types"

const adaptReviewerToApi = (reviewer: Reviewer): ApiReviewer => {
    if ("userId" in reviewer) {
        return {
            userId: reviewer.userId,
            type: ApiUserType.USER,
        } as ApiUserReviewer
    }
    return {
        teamId: reviewer.teamId,
        type: ApiUserType.TEAM,
    } as ApiTeamReviewer
}

const adaptCommonNodeProperties = (node: FlowNode): ApiNode => {
    return {
        slug: node.slug,
        name: node.name.trim() || null,
        metadata: node.metadata,
    }
}

const adaptTransactionTypeToApi = (value: TransactionType | null) => {
    return value || ""
}

const adaptExpressionToApi = (value: string) => `'${value}'`

const adaptBranchToApi = (branch: Branch): ApiBranch => {
    return {
        name: branch.name,
        conditions: branch.conditions,
        nextNode: branch.nextNode || undefined,
    }
}

const adaptNodeToApi = (node: FlowNode): ApiFlowNode | null => {
    switch (node.type) {
        case NodeType.EVENT_TRIGGER_NODE: {
            const nodeToAdapt: ApiEventTriggerNode = {
                ...adaptCommonNodeProperties(node),
                event: node.event as unknown as ApiEvent,
                type: node.type as unknown as ApiNodeType.EVENT_TRIGGER_NODE,
                nextNode: node.nextNode || undefined,
            }
            if (node.filter.conditions.length > 0 && node.filter.conditions[0].length > 0) {
                nodeToAdapt.filter = node.filter
            }

            return nodeToAdapt
        }

        case NodeType.IF_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.IF_NODE,
                condition: node.condition,
                nextIfTrue: node.nextIfSuccess || undefined,
                nextIfFalse: node.nextIfFailure || undefined,
            }
        case NodeType.SET_PARTNERSHIP_FIELD_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.SET_PARTNERSHIP_FIELD_NODE,
                partnershipId: node.partnershipId,
                fieldToUpdate: node.fieldToUpdate,
                valueToSet: node.valueToSet,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.CHECK_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.CHECK_NODE,
                objectId: node.objectId,
                objectType: node.objectType as unknown as ApiObjectType,
                reviewers: node.reviewers.map(adaptReviewerToApi),
                passThreshold: node.passThreshold,
                refuseThreshold: node.refuseThreshold,
                nextIfPassed: node.nextIfSuccess || undefined,
                nextIfRefused: node.nextIfFailure || undefined,
            }
        case NodeType.SEND_EMAIL_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.SEND_EMAIL_NODE,
                subject: node.subject,
                recipientAddresses: node.recipientAddresses,
                body: adaptExpressionToApi(node.body),
                nextNode: node.nextNode || undefined,
            }
        case NodeType.ADD_TO_BUDGET_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.ADD_TO_BUDGET_NODE,
                amount: node.amount,
                budgetId: adaptExpressionToApi(node.budgetId),
                transactionId: node.transactionId,
                transactionType: adaptTransactionTypeToApi(node.transactionType),
                failIfOverbudget: node.failIfOverbudget,
                currency: node.currency,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE,
                invoiceId: node.invoiceId,
                nextIfMatched: node.nextIfSuccess || undefined,
                nextIfNotMatched: node.nextIfFailure || undefined,
                metadata: node.metadata,
            }
        case NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE,
                invoiceId: node.invoiceId,
                statusToSet: node.statusToSet,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.FITS_TO_BUDGET_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.FITS_TO_BUDGET_NODE,
                budgetId: adaptExpressionToApi(node.budgetId),
                transactionId: node.transactionId,
                transactionType: adaptTransactionTypeToApi(node.transactionType),
                amount: node.amount,
                currency: node.currency,
                nextIfFits: node.nextIfSuccess || undefined,
                nextIfDoesNotFit: node.nextIfFailure || undefined,
            }
        case NodeType.ASSIGN_TAG_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.ASSIGN_TAG_NODE,
                objectId: node.objectId,
                objectType: node.objectType as unknown as ApiObjectType,
                tagId: node.tagId,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.UPDATE_TRIPLETEX_LEDGER_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.UPDATE_TRIPLETEX_LEDGER_NODE,
                ledgerId: node.ledgerId,
                ledgerDate: node.ledgerDate || null,
                ledgerDescription: node.ledgerDescription ? adaptExpressionToApi(node.ledgerDescription) : null,
                accountToCredit: node.accountToCredit,
                accountToDebit: node.accountToDebit,
                amount: node.amount,
                amountGross: node.amountGross,
                currency: node.currency,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.APPROVE_PURCHASE_ORDER_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.APPROVE_PURCHASE_ORDER_NODE,
                purchaseOrderId: node.purchaseOrderId,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.APPROVE_PURCHASE_ORDER_LINE_NODE,
                purchaseOrderLineId: node.purchaseOrderLineId,
                purchaseOrderId: node.purchaseOrderId,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.CONVERT_PR_TO_PO_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.CONVERT_PR_TO_PO_NODE,
                purchaseRequestId: node.purchaseRequestId,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.APPROVE_PURCHASE_REQUEST_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.APPROVE_PURCHASE_REQUEST_NODE,
                purchaseRequestId: node.purchaseRequestId,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE,
                purchaseRequestId: node.purchaseRequestId,
                purchaseRequestLineId: node.purchaseRequestLineId,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.BRANCH_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.BRANCH_NODE,
                branches: node.branches.filter((branch) => branch.conditions.length > 0).map(adaptBranchToApi),
                default: node.default,
            }
        case NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE,
                paymentMethodDetailsId: node.paymentMethodDetailsId,
                fieldToUpdate: node.fieldToUpdate,
                valueToSet: node.valueToSet,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.CREATE_SURVEY_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.CREATE_SURVEY_NODE,
                formId: adaptExpressionToApi(node.formId),
                respondentOrganizationId: node.respondentOrganizationId || undefined,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.RETRACT_REVIEWS_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.RETRACT_REVIEWS_NODE,
                objectId: node.objectId,
                objectType: node.objectType as unknown as ApiObjectType,
                nextNode: node.nextNode || undefined,
            }
        case NodeType.FETCH_CUSTOM_FIELDS_NODE:
            return {
                ...adaptCommonNodeProperties(node),
                type: node.type as unknown as ApiNodeType.FETCH_CUSTOM_FIELDS_NODE,
                objectId: node.objectId,
                nextNode: node.nextNode || undefined,
            }
        default:
            return null
    }
}

export const adaptFlowToApi = (flow: Flow): ApiFlowUpdate => {
    return {
        version: flow.version,
        name: flow.name,
        enabled: flow.enabled,
        archived: flow.archived,
        createdAt: flow.createdAt,
        nodes: flow.nodes.map(adaptNodeToApi).filter(isApiFlowNode),
    }
}

export const adaptNewFlowToApi = (flow: CreateFlowBody): ApiNewFlow => {
    return {
        name: flow.name,
        enabled: flow.enabled,
        nodes: flow.nodes.map(adaptNodeToApi).filter(isApiFlowNode),
    }
}
