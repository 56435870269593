import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { bookOfRelationsActions } from "~/domains/partners/store/bookOfRelationsSlice"
import { PartnersDataI } from "~/domains/partners/types/BookOfRelations"
import { store } from "~/store"
import { OrganizationId } from "~/types"

const BASE_URL = import.meta.env.VITE_API_BOOK_OF_RELATIONS_URL + "v1"

export const partnersApiV1 = createApi({
    reducerPath: "partnersApiV1",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getPartners: builder.query<
            PartnersDataI[],
            { organizationId: OrganizationId; withMetrics?: boolean; withDetails?: boolean }
        >({
            query: ({ organizationId, withMetrics, withDetails }) => ({
                url: `/organizations/${organizationId}/partners${
                    withMetrics ? "?withMetrics=true" : withDetails ? "?withDetails=true" : ""
                }`,
            }),
            transformResponse: (response: PartnersDataI[]) => {
                const organizationNames: Record<OrganizationId, string> = {}

                response.forEach((partner: PartnersDataI) => {
                    organizationNames[partner.organizationId] = partner.brandName || partner.organizationName
                })
                store.dispatch(bookOfRelationsActions.setPartnerBrandNames(organizationNames))
                store.dispatch(bookOfRelationsActions.setPartners(response))

                return response
            },
        }),
    }),
})

export const { useGetPartnersQuery, useLazyGetPartnersQuery } = partnersApiV1
