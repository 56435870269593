import { Button, Grid, Stack } from "@mui/material"
import { Edit3 } from "react-feather"
import { Link, generatePath } from "react-router-dom"

import { Chip } from "~/components/Chip"
import { BUDGET_ROUTE } from "~/features/budget/routes"
import { BudgetDataWithMetricsI } from "~/features/budget/types"

interface Props {
    budgets: BudgetDataWithMetricsI[]
    handleEdit: () => void
}

export function BudgetLink({ budgets, handleEdit }: Props) {
    return (
        <Grid container alignItems="center" gap={1}>
            <Stack direction="row" flexWrap="wrap" gap={2}>
                {budgets.map((budget, index) => (
                    <Chip variant="primary" key={budget.budget.id}>
                        <Link to={generatePath(BUDGET_ROUTE, { budgetId: budget.budget.id })} target="_blank">
                            {budget.budget.name}
                            {index !== budgets.length - 1 ? ", " : ""}
                        </Link>
                    </Chip>
                ))}
            </Stack>
            <Button variant="text" onClick={handleEdit}>
                <Edit3 size={16} />
            </Button>
        </Grid>
    )
}
