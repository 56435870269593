import { Stack } from "@mui/material"
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-premium"
import { skipToken } from "@reduxjs/toolkit/query"
import dayjs from "dayjs"
import React from "react"
import { defineMessages } from "react-intl"

import { Card, SafeFormattedMessage } from "~/components"
import {
    useGetCustomFormQuery,
    useGetCustomFormScoreQuery,
} from "~/domains/identity/features/customForms/api/customFormsApi"
import { CustomFormSurvey } from "~/domains/identity/features/customForms/types/CustomForms"

const messages = defineMessages({
    insights: {
        id: "smartForms.tabHeader.insights",
        defaultMessage: "Insights",
    },
    responses: {
        id: "smartForms.tabHeader.responses",
        defaultMessage: "Responses",
    },
    responsesHeader: {
        id: "smartForms.header.responses",
        defaultMessage: "Partner responses",
    },
    columnHeaderForm: {
        id: "common.form",
        defaultMessage: "Form",
    },
    columnHeaderEmail: {
        id: "common.mail",
        defaultMessage: "Email",
    },
    columnHeaderCreatedDate: {
        id: "common.createdDate",
        defaultMessage: "Created date",
    },
    columnHeaderScore: {
        id: "smartForms.surveyScore",
        defaultMessage: "Scores",
    },
})

// TODO: move this to a separate file
// separate component to statisfy the rule of hooks
const FormNameCell = ({
    formId,
    formName,
    organisationId,
}: {
    formId: string
    formName?: string
    organisationId?: string
}) => {
    const { data: form } = useGetCustomFormQuery(
        organisationId && formId && !formName ? { organisationId, formId } : skipToken
    )
    return <>{formName || form?.name}</>
}

const ScoresCell = ({
    formId,
    organisationId,
    partnerOrganisationId,
}: {
    formId: string
    formName?: string
    organisationId?: string
    partnerOrganisationId
}) => {
    const { data: stats } = useGetCustomFormScoreQuery(
        organisationId && partnerOrganisationId ? { organisationId, formId, partnerOrganisationId } : skipToken
    )

    return <>{stats ? stats[0].alertScore : null}</>
}

export const ResponsesDatagrid = ({
    surveys,
    formName,
    organisationId,
    partnerOrganisationId,
}: {
    surveys: CustomFormSurvey[]
    formName: string
    organisationId?: string
    partnerOrganisationId?: string
}) => {
    const pageSizeOptions = [12, 24, 36, 48, 60]
    const SURVEY_URL = `${import.meta.env.VITE_APP_URL}custom-forms/survey/`

    const columns: GridColDef[] = [
        {
            flex: 1,
            field: "Form",
            renderHeader: () => <SafeFormattedMessage {...messages.columnHeaderForm} />,
            renderCell: (params: GridRenderCellParams<CustomFormSurvey>) =>
                FormNameCell({ formId: params.row.formId, formName, organisationId }),
        },
        {
            flex: 1,
            field: "Scores",
            renderHeader: () => <SafeFormattedMessage {...messages.columnHeaderScore} />,
            renderCell: (params: GridRenderCellParams<CustomFormSurvey>) =>
                ScoresCell({ formId: params.row.formId, formName, organisationId, partnerOrganisationId }),
        },
        {
            flex: 1,
            field: "Contact",
            renderHeader: () => <SafeFormattedMessage {...messages.columnHeaderEmail} />,
            renderCell: (params: GridRenderCellParams<CustomFormSurvey>) => {
                return <>{params.row.respondentContacts[0]?.email}</>
            },
        },
        {
            flex: 1,
            field: "createdAt",
            renderHeader: () => <SafeFormattedMessage {...messages.columnHeaderCreatedDate} />,
            renderCell: (params: GridRenderCellParams<CustomFormSurvey>) => {
                return <>{dayjs(params.row.createdAt).format("YYYY-MM-DD")}</>
            },
        },
    ]

    const handleRowClick = (params: GridRowParams<CustomFormSurvey>) => {
        window.open(`${SURVEY_URL}${params.row.token}?view=true`, "_blank") // open in view mode
    }

    if (!surveys) {
        return null
    }

    return (
        <Stack marginTop={1} spacing={2} direction="column">
            <h3>
                <SafeFormattedMessage {...messages.responsesHeader} />
            </h3>
            <Card>
                <DataGridPremium
                    onRowClick={handleRowClick}
                    pageSizeOptions={pageSizeOptions}
                    columns={columns}
                    rows={surveys}
                    pagination
                    rowCount={surveys.length ?? 0}
                    disableRowSelectionOnClick
                    rowSelection={false}
                    autosizeOnMount
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                Scores: partnerOrganisationId !== undefined,
                            },
                        },
                    }}
                />
            </Card>
        </Stack>
    )
}
