import {
    InvoicePennylaneSynchronizationStatusListI,
    InvoicePennylaneSynchronizationStatusListIO,
} from "~/features/ocr/types/pennylaneSynchronizationStatus"
import { Result, ResultError, ResultSuccess, isResultSuccess } from "~/types/Result"
import { ParsingErrorType, genericParser } from "~/utils"

export const parseInvoicesPennylaneSync = (
    data: unknown
): Result<InvoicePennylaneSynchronizationStatusListI, ParsingErrorType> => {
    const result = genericParser(data, InvoicePennylaneSynchronizationStatusListIO)
    if (isResultSuccess(result)) {
        return ResultSuccess(result.result)
    }
    return ResultError(result.error)
}
