import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { invoiceFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/invoiceFromApiAdapter"
import { invoiceToApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/invoiceToApiAdapter"
import { InvoiceAPI } from "~/domains/transactions/invoices-v1/types/Invoice"
import { InvoiceV1Payload } from "~/domains/transactions/invoices-v1/types/InvoicePayload"
import { InvoiceI, InvoiceLineI } from "~/types"

const BASE_OLD_URL = import.meta.env.VITE_API_INVOICES_URL + "v1"
const BASE_URL = import.meta.env.VITE_API_TRANSACTION_V2_INVOICES_URL + "invoices"

export const invoiceApiV1 = createApi({
    reducerPath: "invoiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getInvoices: builder.query<InvoiceI[], string>({
            query: (organizationId: string) => ({
                url: "",
                params: { organizationId, pageSize: 100 },
            }),
            transformResponse: (response: InvoiceAPI[]) =>
                (response || []).map((invoice) => invoiceFromApiAdapter(invoice)),
        }),
        getInvoice: builder.query<InvoiceI, string>({
            query: (id: string) => ({ url: `/${id}` }),
            transformResponse: (response: InvoiceAPI) => invoiceFromApiAdapter(response),
        }),
        createInvoice: builder.mutation<InvoiceI, Partial<InvoiceV1Payload>>({
            query: (invoice: Partial<InvoiceV1Payload>) => ({
                url: "",
                method: "POST",
                body: invoiceToApiAdapter(invoice),
            }),
            transformResponse: (response: InvoiceAPI) => invoiceFromApiAdapter(response),
            transformErrorResponse: (response: FetchBaseQueryError) => {
                return response.data
            },
        }),
        updateInvoice: builder.mutation({
            query: ({ id, ...invoice }: InvoiceV1Payload) => ({
                url: `/${id}`,
                method: "PUT",
                body: invoiceToApiAdapter(invoice),
            }),
        }),
        deleteInvoice: builder.mutation({
            query: (id: string) => ({ url: `/${id}`, method: "DELETE" }),
        }),
        patchUpdateInvoice: builder.mutation({
            query: ({ id, ...invoice }) => ({
                url: `/${id}`,
                method: "PATCH",
                body: invoiceToApiAdapter(invoice),
            }),
        }),
        downloadInvoices: builder.mutation({
            query: (invoiceIds: string[]) => ({
                url: `${BASE_OLD_URL}/resources/invoice-pdf/batch`,
                method: "POST",
                body: { invoiceIds },
                headers: {
                    Accept: "application/octet-stream",
                },
                responseHandler: async (response) =>
                    window.location.assign(window.URL.createObjectURL(await response.blob())),
            }),
        }),
        createLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, line }: { invoiceId: string; line: InvoiceLineI }) => ({
                url: `/${invoiceId}/lineItems`,
                method: "POST",
                body: line,
            }),
        }),
        putUpdateLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, line }: { invoiceId: string; line: InvoiceLineI }) => ({
                url: `/${invoiceId}/lineItems/${line.id}`,
                method: "PUT",
                body: line,
            }),
        }),
        deleteLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, id }: { invoiceId: string; id: string }) => ({
                url: `/${invoiceId}/lineItems/${id}`,
                method: "DELETE",
            }),
        }),
        patchUpdateLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, line }: { invoiceId: string; line: Partial<InvoiceLineI> }) => ({
                url: `/${invoiceId}/lineItems/${line.id}`,
                method: "PATCH",
                body: line,
            }),
        }),
    }),
})

export const {
    useGetInvoicesQuery,
    useGetInvoiceQuery,
    useCreateInvoiceMutation,
    useUpdateInvoiceMutation,
    useDeleteInvoiceMutation,
    usePatchUpdateInvoiceMutation,
    useDownloadInvoicesMutation,
    useCreateLineItemInInvoiceMutation,
    usePutUpdateLineItemInInvoiceMutation,
    useDeleteLineItemInInvoiceMutation,
    usePatchUpdateLineItemInInvoiceMutation,
} = invoiceApiV1
