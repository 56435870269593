import { useCallback, useState } from "react"
import { useAppDispatch } from "~/store/hooks"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import { CreatePartnerProfileContactI } from "~/domains/partners/types"
import { partnerApi } from "~/api"
import { commonMessages } from "~/common-messages"
import { bookOfRelationsActions } from "../bookOfRelationsSlice"

const messages = defineMessages({
    success: { id: "partners.bookofrelations.profile.contact.create.success", defaultMessage: "Partner contact added" },
})

export const useCreatePartnerProfileContact = () => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const createPartnerProfileContact = useCallback(
        async (initiatorId: string, partnerId: string, contact: CreatePartnerProfileContactI) => {
            try {
                setLoading(true)

                const newContact = await partnerApi.createPartnerProfileContact(initiatorId, partnerId, contact)

                dispatch(bookOfRelationsActions.createPartnerProfileContact({ ...contact, id: newContact.id }))
                setLoading(false)
                toast.success(formatMessage(messages.success))
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(commonMessages.errorContact))
            }
        },
        []
    )

    return {
        createPartnerProfileContact,
        error,
        loading,
    }
}
