import { useCallback } from "react"
import { Descendant } from "slate"

import { useCommunicationApi } from "~/domains/communication/api"
import { communicationActions } from "~/domains/communication/store/CommunicationSlice"
import { MessageId, MessageWithUserI, RoomId } from "~/domains/communication/types"
import { CreateMessagePayload } from "~/domains/communication/types/CreateMessage"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isResultSuccess } from "~/types/Result"

export const useSendOrUpdateMessage = (organizationId: OrganizationId, roomId: RoomId) => {
    const communicationApi = useCommunicationApi()
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()

    const sendNewMessage = useCallback(
        async (content: Descendant[]) => {
            const createMessagePayload: CreateMessagePayload = {
                content,
                senderId: user.id,
                isPinned: false,
            }

            const result = await communicationApi.sendMessage(organizationId, roomId, createMessagePayload)
            if (isResultSuccess(result)) {
                dispatch(communicationActions.addMessageInRoom({ roomId, message: result.result }))
            }
        },
        [organizationId, roomId, user.id, dispatch]
    )

    const updateMessage = useCallback(
        async (messageId: MessageId, content: Descendant[]) => {
            const result = await communicationApi.updateMessage(messageId, { content })
            if (isResultSuccess(result)) {
                dispatch(communicationActions.updateMessage(result.result))
                dispatch(communicationActions.setMessageToUpdate(null))
            }
        },
        [dispatch]
    )

    const sendMessage = useCallback(
        async (content: Descendant[], messageToUpdate: MessageWithUserI | null) => {
            if (messageToUpdate) {
                updateMessage(messageToUpdate.id, content)
            } else {
                sendNewMessage(content)
            }
        },
        [updateMessage, sendNewMessage]
    )

    return sendMessage
}
