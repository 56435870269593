import { PartnerProfileI, PartnersDataI, PartnershipI } from "~/domains/partners/types"
import { TagObjectRecordI } from "~/domains/tags/types/TagObjectRecord"
import { OrganizationId } from "~/types"

export interface PartnersDataState {
    partnersData: PartnersDataI[]
    partnersFilter: string
    brandNames: Record<OrganizationId, string>
    loading: boolean
    error: string | null
    tagsLoading: boolean
    tags: TagObjectRecordI | undefined
}

export interface PartnershipState {
    partnershipData: PartnershipI[]
    loading: boolean
    error: string | null
}

export interface PartnerProfileState {
    data: PartnerProfileI | null
    loading: boolean
    error: string | null
}

export interface BookOfRelationsState {
    partners: PartnersDataState
    partnership: PartnershipState
    partnerProfile: PartnerProfileState
}

export const bookOfRelationsState: BookOfRelationsState = {
    partners: {
        partnersData: [],
        partnersFilter: "",
        brandNames: {},
        loading: false,
        error: null,
        tagsLoading: false,
        tags: undefined,
    },
    partnership: {
        partnershipData: [],
        loading: false,
        error: null,
    },
    partnerProfile: {
        data: null,
        loading: false,
        error: null,
    },
}
