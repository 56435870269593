import { AxiosInstance } from "axios"
import { useContext } from "react"

import { documentsDifferencesFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/parts/documentsDifferencesFromApiAdapter"
import { OrganizationId } from "~/types"
import { Result, ResultError, isResultSuccess } from "~/types/Result"
import { genericParser } from "~/utils"
import { ApiContext } from "~/utils/apiClient"
import { FetchError, FetchErrorType } from "~/utils/apiClient/errors"
import { parseResponseAsArray } from "~/utils/apiClient/parseResponseAsArray"

import { CreateInvoiceRelation, CreatePurchaseOrderRelation } from "./types"
import { CreateRelationResultI, CreateRelationResultIO } from "./types/CreateRelationResult"
import { DocumentRelationId } from "./types/InvoiceRelation"
import { parseInvoiceRelation } from "./types/parseInvoiceRelation"
import { parsePurchaseOrderRelation } from "./types/parsePurchaseOrderRelation"

const BASE_URL = import.meta.env.VITE_API_DOCUMENT_RELATIONS_URL

class DocumentRelationsApi {
    private static instance: DocumentRelationsApi
    private constructor(private axiosClient: AxiosInstance) {}

    static getInstance(axiosClient: AxiosInstance) {
        if (!DocumentRelationsApi.instance) {
            DocumentRelationsApi.instance = new DocumentRelationsApi(axiosClient)
        }
        return DocumentRelationsApi.instance
    }

    private buildUrl(organizationId: OrganizationId, path: string): string {
        return `${BASE_URL}organizations/${organizationId}${path}`
    }
    private buildUrlV2(organizationId: OrganizationId, path: string): string {
        return `${BASE_URL}v2/organizations/${organizationId}${path}`
    }

    private buildInvoiceRelationsUrl(organizationId: OrganizationId, invoiceId: string): string {
        return this.buildUrl(organizationId, `/invoices/${invoiceId}/relations`)
    }

    private buildInvoiceRelationsDifferencesUrl(organizationId: OrganizationId, invoiceId: string): string {
        return this.buildUrlV2(organizationId, `/invoices/${invoiceId}/relations`)
    }

    private buildPurchaseOrderRelationsUrl(organizationId: OrganizationId, purchaseOrderId: string): string {
        return this.buildUrl(organizationId, `/purchase-orders/${purchaseOrderId}/relations`)
    }

    private buildPurchaseOrderRelationsDifferencesUrl(organizationId: OrganizationId, purchaseOrderId: string): string {
        return this.buildUrlV2(organizationId, `/purchase-orders/${purchaseOrderId}/relations`)
    }

    async getInvoiceRelations(organizationId: OrganizationId, invoiceId: string) {
        const url = this.buildInvoiceRelationsUrl(organizationId, invoiceId)
        const response = await this.axiosClient.get(url)
        return parseResponseAsArray(response, parseInvoiceRelation)
    }

    async getInvoiceRelationsDifferences(organizationId: OrganizationId, invoiceId: string) {
        const url = this.buildInvoiceRelationsDifferencesUrl(organizationId, invoiceId)
        const response = await this.axiosClient.get(url)
        return documentsDifferencesFromApiAdapter(response.data)
    }

    async addRelationToInvoice(
        organizationId: OrganizationId,
        invoiceId: string,
        payload: CreateInvoiceRelation
    ): Promise<Result<CreateRelationResultI, FetchError<CreateRelationResultI>>> {
        const url = this.buildInvoiceRelationsUrl(organizationId, invoiceId)
        const response = await this.axiosClient.post(url, payload)
        if (response.status >= 200 && response.status < 300) {
            const result = genericParser<CreateRelationResultI>(response.data, CreateRelationResultIO)
            if (isResultSuccess(result)) {
                return result
            }
            return ResultError({
                type: FetchErrorType.PARSING_ERROR,
                partialResults: [],
                errors: [result.error],
            })
        }
        return ResultError({
            type: FetchErrorType.HTTP_REQUEST_ERROR,
            code: response.status,
            error: `${response.data}`,
        })
    }

    async removeRelation(organizationId: OrganizationId, relationId: DocumentRelationId): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/relations/${relationId}`)
        const response = await this.axiosClient.delete(url)
        return response.status >= 200 && response.status < 300
    }

    async getPurchaseOrderRelations(organizationId: OrganizationId, purchaseOrderId: string) {
        const url = this.buildPurchaseOrderRelationsUrl(organizationId, purchaseOrderId)
        const response = await this.axiosClient.get(url)
        return parseResponseAsArray(response, parsePurchaseOrderRelation)
    }

    async getPurchaseOrderRelationsDifferences(organizationId: OrganizationId, purchaseOrderId: string) {
        const url = this.buildPurchaseOrderRelationsDifferencesUrl(organizationId, purchaseOrderId)
        const response = await this.axiosClient.get(url)
        return documentsDifferencesFromApiAdapter(response.data)
    }

    async addRelationToPurchaseOrder(
        organizationId: OrganizationId,
        purchaseOrderId: string,
        payload: CreatePurchaseOrderRelation
    ): Promise<Result<CreateRelationResultI, FetchError<CreateRelationResultI>>> {
        const url = this.buildPurchaseOrderRelationsUrl(organizationId, purchaseOrderId)
        const response = await this.axiosClient.post(url, payload)
        if (response.status >= 200 && response.status < 300) {
            const result = genericParser<CreateRelationResultI>(response.data, CreateRelationResultIO)
            if (isResultSuccess(result)) {
                return result
            }
            return ResultError({
                type: FetchErrorType.PARSING_ERROR,
                partialResults: [],
                errors: [result.error],
            })
        }
        return ResultError({
            type: FetchErrorType.HTTP_REQUEST_ERROR,
            code: response.status,
            error: `${response.data}`,
        })
    }
}

export const useDocumentRelationsApi = () => {
    const { axiosClient } = useContext(ApiContext)
    return DocumentRelationsApi.getInstance(axiosClient)
}
