import { Alert, styled } from "@mui/material"
import React from "react"

interface Props {
    error: string
}

const StyledAlert = styled(Alert)({
    flexDirection: "row-reverse",
    gap: "var(--spacing-xs)",
    backgroundColor: "transparent",
    padding: 0,
})

export const JobError: React.FC<Props> = ({ error }) => <StyledAlert severity="error">{error}</StyledAlert>
