import { XSquare } from "react-feather"

import { RetractReviewsConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RetractReviewsNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEventTypeToObjectType } from "~/domains/orchestration/flows/core"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    Event,
    Node,
    NodeType,
    RetractReviewsNode as RetractReviewsNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const retractReviewsNode = (eventTriggerEventType: Event | null): Omit<RetractReviewsNodeType, keyof Node> => ({
    objectId: `{{ trigger_event.id }}`,
    objectType: eventTriggerEventType ? adaptEventTypeToObjectType(eventTriggerEventType) : null,
    type: NodeType.RETRACT_REVIEWS_NODE,
    nextNode: null,
})

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.OBJECT_ID, AllAdvancedFieldsUnion.OBJECT_TYPE]

const retractReviewsConfig: Configuration<NodeType.RETRACT_REVIEWS_NODE> = {
    type: NodeType.RETRACT_REVIEWS_NODE,
    baseSlug: "retract-reviews",
    Icon: XSquare,
    Configuration: RetractReviewsConfiguration,
    advancedFields,
    Node: RetractReviewsNode,
    factory: (props: FactoryProps): RetractReviewsNodeType => ({
        ...retractReviewsNode(props.eventTriggerEventType),
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { retractReviewsConfig, advancedFields as retractReviewsAdvancedFields }
