import { CssBaseline, ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material"

/* https://stackoverflow.com/questions/69608945/fix-typescript-error-when-customizing-muidatagrid-in-createtheme */
import type {} from "@mui/x-data-grid-pro/themeAugmentation"
import type {} from "@mui/x-data-grid/themeAugmentation"
import { PropsWithChildren, useEffect, useMemo, useState } from "react"

import { palette } from "~/assets/theme/palette"
import { selectDarkMode } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"

interface ThemeProviderProps extends PropsWithChildren {}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const darkMode = useAppSelector(selectDarkMode)
    const [activeTheme, setActiveTheme] = useState<"light" | "dark">("light")

    useEffect(() => {
        const theme = darkMode ? "dark" : "light"
        setActiveTheme(theme)
        document.documentElement.setAttribute("data-theme", theme)
    }, [darkMode])

    const theme = useMemo(
        () =>
            createTheme({
                palette: palette(activeTheme),
                typography: {
                    fontFamily: ["Noto Sans"].join(","),
                    body1: {
                        fontSize: 14,
                        fontWeight: 500,
                    },
                    h2: {
                        fontWeight: 700,
                        fontSize: 22,
                        lineHeight: "18px",
                        color: "var(--color-secondary)",
                    },
                    button: {
                        fontSize: 16,
                        fontWeight: 700,
                        lineHeight: 1.12,
                    },
                },
                components: {
                    MuiAppBar: {
                        styleOverrides: {
                            root: {
                                minHeight: "var(--header-height)",
                                boxShadow: "none",
                                paddingRight: "0 !important",
                                background: "var(--background-color)",
                            },
                        },
                    },
                    MuiToolbar: {
                        styleOverrides: {
                            root: {
                                minHeight: "32px",
                            },
                        },
                    },
                    MuiMenu: {
                        styleOverrides: {
                            list: {
                                padding: "var(--spacing-xxs)",
                            },
                        },
                    },
                    MuiMenuItem: {
                        styleOverrides: {
                            root: {
                                gap: "var(--spacing-sx)",
                                "& .MuiButtonBase-root": {
                                    padding: "0 var(--spacing-xxs)",
                                },
                            },
                        },
                    },
                    MuiCard: {
                        styleOverrides: {
                            root: {
                                boxShadow: "none",
                            },
                        },
                    },
                    MuiCardHeader: {
                        styleOverrides: {
                            root: {
                                color: "var(--color-grey)",
                            },
                        },
                    },
                    MuiCardContent: {
                        styleOverrides: {
                            root: {
                                padding: 0,
                                "&:last-child": {
                                    paddingBottom: 0,
                                },
                            },
                        },
                    },
                    MuiCardActions: {
                        styleOverrides: {
                            root: {
                                padding: 0,
                            },
                        },
                    },
                    MuiButtonBase: {
                        styleOverrides: {
                            root: {
                                "&:disabled": {
                                    cursor: "not-allowed",
                                    pointerEvents: "auto",
                                },
                            },
                        },
                    },
                    MuiButton: {
                        styleOverrides: {
                            sizeSmall: {
                                height: "34px",
                            },
                        },
                    },
                    MuiBreadcrumbs: {
                        styleOverrides: {
                            root: {
                                width: "100%",
                            },
                            li: {
                                flex: 1,
                                textAlign: "center",
                            },
                        },
                    },
                    MuiTypography: {
                        styleOverrides: {
                            h2: {
                                fontWeight: 700,
                                fontSize: "var(--h2-font-size)",
                                marginBottom: 0,
                                "&.MuiTypography-gutterBottom": {
                                    marginBottom: "16px",
                                },
                            },
                        },
                    },
                    MuiSkeleton: {
                        styleOverrides: {
                            root: {
                                background: "var(--color-silver)",
                            },
                        },
                    },
                    MuiSelect: {
                        styleOverrides: {
                            root: {
                                border: "0",
                                dropShadow: "none",
                                minHeight: "39px",

                                "&.MuiInputBase-sizeSmall": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        top: "-10px !important",
                                        border: "0",
                                    },
                                },
                            },
                            select: {
                                border: "0",
                            },
                        },
                    },
                    MuiAutocomplete: {
                        styleOverrides: {
                            groupLabel: {
                                fontSize: "var(--font-size-xs)",
                                lineHeight: "30px",
                                color: "var(--color-light-grey)",
                            },
                        },
                    },
                    MuiTableHead: {
                        styleOverrides: {
                            root: {
                                backgroundColor:
                                    palette(activeTheme)?.background?.default || "var(--color-lighter-grey)",

                                "& th": {
                                    borderBottom: "1px solid var(--color-light-silver)",
                                },
                            },
                        },
                    },
                    MuiTableRow: {
                        styleOverrides: {
                            root: {
                                "&:nth-of-type(odd)": {
                                    backgroundColor:
                                        palette(activeTheme)?.action?.selected || "var(--color-lighter-grey)",
                                },
                            },
                        },
                    },
                    MuiTableCell: {
                        styleOverrides: {
                            root: {
                                padding: "var(--spacing-sm)",
                                borderBottom: "1px solid var(--color-border-workflow)",
                            },
                            head: {
                                color: "var(--color-light-grey)",
                            },
                        },
                    },
                    MuiTablePagination: {
                        styleOverrides: {
                            root: {
                                overflow: "hidden !important",
                                color: "var(--color-light-grey)",
                            },
                            select: {
                                border: "0",
                            },
                        },
                    },
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    MuiDataGrid: {
                        styleOverrides: {
                            root: {
                                border: "1px solid var(--color-border-workflow)",
                                borderRadius: "var(--border-radius-sm)",
                                minHeight: "350px",
                                maxWidth: "calc(100vw - 2*var(--spacing-md))",
                                cursor: "pointer",

                                "& .MuiCheckbox-root": {
                                    color: "var(--color-light-grey)",
                                },
                            },
                            row: {
                                "&:nth-of-type(odd)": {
                                    backgroundColor:
                                        palette(activeTheme)?.action?.selected || "var(--color-lighter-grey)",
                                },
                            },
                            cell: {
                                "&:focus": {
                                    outline: 0,
                                },

                                "&[data-field='__detail_panel_toggle__'], &[data-field='__check__']": {
                                    width: 40,
                                    minWidth: "40px !important",
                                    maxWidth: "40px !important",
                                    paddingInline: 4,
                                },
                            },
                            columnHeader: {
                                lineHeight: "1",
                                backgroundColor:
                                    palette(activeTheme)?.background?.default || "var(--color-lighter-grey)",

                                "& img": {
                                    maxHeight: 30,
                                },
                                "& label div": {
                                    marginBottom: 0,
                                },
                                "&[data-field='__detail_panel_toggle__'], &[data-field='__check__']": {
                                    width: 40,
                                    minWidth: "40px !important",
                                    maxWidth: "40px !important",
                                    paddingInline: 4,
                                },
                                "& .MuiCheckbox-root": {
                                    padding: 0,
                                },
                            },
                            virtualScroller: {
                                minHeight: 200,
                            },
                            toolbarContainer: {
                                padding: 0,
                                /* justifyContent: "flex-end",

                    "& .MuiBox-root": {
                        display: "none",
                    }, */

                                "& .MuiButton-root": {
                                    padding: "var(--spacing-sm)",
                                    color: "var(--color-light-grey)",
                                },
                            },
                            menu: {
                                outline: "1px solid var(--color-lighter-grey)",
                            },
                            panel: {
                                boxShadow: "none",
                                outline: "1px solid var(--color-lighter-grey)",
                            },
                            panelFooter: {
                                "& .MuiButton-root": {
                                    padding: "var(--spacing-sm)",
                                    color: "var(--color-light-grey)",
                                },
                            },
                            footerContainer: {
                                border: "0",
                            },
                        },
                    },
                    MuiFormGroup: {
                        styleOverrides: {
                            root: {
                                "& .MuiInputBase-colorPrimary": {
                                    backgroundColor: "var(--color-medium-primary)",
                                },
                                "& .MuiInputBase-colorSecondary": {
                                    backgroundColor: "var(--color-slate)",
                                    border: "0",
                                    boxShadow: "0 0 0 1px var(--color-light-silver) inset",
                                },
                            },
                        },
                    },
                    MuiTextField: {
                        styleOverrides: {
                            root: {
                                borderColor: "var(--color-border-workflow)",
                                "&:focus": {
                                    backgroundColor: "var(--sidebar-color)",
                                },
                                "& .MuiInputBase-root": {
                                    backgroundColor: "var(--sidebar-color)",
                                },
                            },
                        },
                    },
                    MuiInputBase: {
                        styleOverrides: {
                            root: {
                                borderColor: "var(--color-border-workflow)",
                                backgroundColor: "transparent",
                                "&:focus": {
                                    backgroundColor: "transparent",
                                },
                            },
                            sizeSmall: {
                                height: "34px",
                                fontSize: "var(--font-size-md)",
                            },
                        },
                    },
                    MuiFilledInput: {
                        styleOverrides: {
                            root: {
                                backgroundColor: "transparent",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                                "&:focus": {
                                    backgroundColor: "transparent",
                                },
                            },
                        },
                    },
                    MuiOutlinedInput: {
                        styleOverrides: {
                            root: {
                                borderColor: "var(--color-light-silver)",
                                "&:hover": {
                                    borderColor: "var(--color-light-silver)",
                                },
                                "&.Mui-disabled": {
                                    "& .MuiOutlinedInput-input": {
                                        WebkitTextFillColor: "var(--color-light-grey)",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "var(--color-light-silver)",
                                    },
                                },
                            },
                            notchedOutline: {
                                borderColor: "var(--color-light-grey)",
                                "&:hover": {
                                    borderColor: "var(--color-light-silver)",
                                },
                            },
                        },
                    },
                    MuiInput: {
                        styleOverrides: {
                            root: {
                                backgroundColor: "transparent",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                                "&:focus": {
                                    backgroundColor: "transparent",
                                },
                            },
                        },
                    },
                    MuiInputLabel: {
                        styleOverrides: {
                            root: {
                                color: "var(--color-placeholder)",
                                "&.Mui-disabled": {
                                    color: "var(--color-light-grey)",
                                },
                            },
                            sizeSmall: {
                                lineHeight: "1.1rem",
                            },
                        },
                    },
                    MuiPaper: {
                        styleOverrides: {
                            root: {
                                boxShadow: "none",
                                borderRadius: "var(--border-radius-sm)",
                            },
                        },
                    },
                    MuiTabs: {
                        styleOverrides: {
                            root: {
                                marginBottom: "0 !important",
                            },
                        },
                    },
                    MuiTab: {
                        styleOverrides: {
                            root: {
                                paddingRight: "12px",
                                paddingLeft: "12px",
                                paddingBottom: "8px",
                                color: "var(--color-light-grey)",
                                background: "transparent",
                                "&:hover, &.Mui-selected": {
                                    color: "var(--primary-color)",
                                },
                                textTransform: "none",
                                fontWeight: 400,
                                fontSize: "var(--font-size-md)",
                            },
                        },
                    },
                    MuiToggleButtonGroup: {
                        styleOverrides: {
                            root: {
                                marginInline: "auto",
                                marginTop: "var(--spacing-sm)",

                                "& .MuiToggleButton-root": {
                                    padding: "var(--spacing-sm)",
                                },
                            },
                        },
                    },
                    MuiToggleButton: {
                        styleOverrides: {
                            root: {
                                textTransform: "none",
                                fontWeight: 400,
                                fontSize: "var(--font-size-md)",
                            },
                        },
                    },
                },
                // These breakpoints are consistent with the ones in /src/assets/css/breakpoints.scss.
                // Both need to be updated together.
                breakpoints: {
                    values: {
                        xs: 480,
                        sm: 576,
                        md: 768,
                        lg: 992,
                        xl: 1200,
                    },
                },
            }),
        [activeTheme]
    )

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    )
}
