import { List } from "@mui/material"
import React from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card, Loader } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import { PurchaseOrderPicker } from "~/domains/transactions/purchase-orders/components/PurchaseOrderPicker"
import { InvoiceUserType, OrganizationId, ViewTypeI } from "~/types"
import { useFetchErrorMessage } from "~/utils/apiClient/errors"

import { useAddRelationToInvoice, useInvoiceRelations } from "../../hooks"
import { InvoiceRelation } from "./InvoiceRelation"

const messages = defineMessages({
    purchaseOrders: {
        id: "buyer.invoice.details.purchaseOrders",
        defaultMessage: "Purchase orders",
    },
})

interface Props {
    organizationId: OrganizationId
    invoiceId: string
    initiator: InvoiceUserType
}

export const InvoiceDocumentRelations: React.FC<Props> = ({ organizationId, invoiceId, initiator }) => {
    const { formatMessage } = useIntl()
    const { loading, error, invoiceRelations } = useInvoiceRelations(organizationId, invoiceId)
    const addRelationToInvoice = useAddRelationToInvoice(organizationId, invoiceId)
    const errorMessage = useFetchErrorMessage(error)

    return (
        <Card title={formatMessage(messages.purchaseOrders)} expandable>
            {loading ? (
                <Loader small />
            ) : (
                <List>
                    {invoiceRelations.map((invoiceRelation) => (
                        <InvoiceRelation
                            key={invoiceRelation.relationId}
                            invoiceRelation={invoiceRelation}
                            organizationId={organizationId}
                            invoiceId={invoiceId}
                        />
                    ))}
                </List>
            )}
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <PurchaseOrderPicker
                organizationId={organizationId}
                value={null}
                onChange={addRelationToInvoice}
                viewType={initiator === InvoiceUserType.BUYER ? ViewTypeI.buyer : ViewTypeI.supplier}
            />
        </Card>
    )
}
