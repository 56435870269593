import { Stack } from "@mui/material"
import classNames from "classnames"
import { AE, CN, DE, FR, FlagComponent, GB, NO } from "country-flag-icons/react/3x2"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from "react"

import { SUPPORTED_LANGUAGES, SupportedLanguages, globalActions, selectLang } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

import "./LangSelector.scss"

const LanguagesFlags: Record<SupportedLanguages, FlagComponent> = {
    [SupportedLanguages.FR]: FR,
    [SupportedLanguages.EN]: GB,
    [SupportedLanguages.AR]: AE,
    [SupportedLanguages.CH]: CN,
    [SupportedLanguages.DE]: DE,
    [SupportedLanguages.NO]: NO,
}
const LanguagesLabels: Record<SupportedLanguages, string> = {
    [SupportedLanguages.FR]: "Français",
    [SupportedLanguages.EN]: "English",
    [SupportedLanguages.AR]: "Arabic",
    [SupportedLanguages.CH]: "Chinese",
    [SupportedLanguages.DE]: "German",
    [SupportedLanguages.NO]: "Norwegian",
}

interface Props {
    displayLang: boolean
    setDisplayLang: Dispatch<SetStateAction<boolean>>
    showSelectedLabel?: boolean
}

export function LangSelector({ displayLang, setDisplayLang, showSelectedLabel = false }: Props) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (displayLang) {
            const onClick = () => setDisplayLang(false)
            window.addEventListener("click", onClick)
            return () => {
                window.removeEventListener("click", onClick)
            }
        }
    }, [displayLang, setDisplayLang])

    const handleSelectLang = useCallback(
        (e: React.MouseEvent<HTMLLIElement | HTMLDivElement>, lang: SupportedLanguages) => {
            if (displayLang) {
                e.stopPropagation()
            }
            setDisplayLang(false)
            dispatch(globalActions.setLang(lang))
        },
        [setDisplayLang, displayLang, dispatch]
    )

    const handleDisplayLang = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation()
            setDisplayLang(true)
        },
        [setDisplayLang]
    )

    const lang = useAppSelector(selectLang)
    const otherLanguages = useMemo(() => SUPPORTED_LANGUAGES.filter((l) => l !== lang), [lang])
    const LangFlag = LanguagesFlags[lang]

    return (
        <div className="lang-selector" onClick={handleDisplayLang}>
            <Stack direction="row" gap={1} alignItems="center" className="lang-selected">
                <LangFlag />
                {showSelectedLabel && <span>{LanguagesLabels[lang]}</span>}
            </Stack>

            <ul className={classNames("lang-list", { open: displayLang })}>
                {otherLanguages.map((language) => {
                    const Flag = LanguagesFlags[language]

                    return (
                        <li key={language} onClick={(e) => handleSelectLang(e, language)}>
                            <Stack direction="row" gap={1} alignItems="center" justifyContent="flex-start">
                                <Flag />
                                <span>{LanguagesLabels[language]}</span>
                            </Stack>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
