import { Stack } from "@mui/material"
import React, { FC } from "react"
import { X } from "react-feather"

import { SafeFormattedMessage } from "~/components"
import { nodeConfig } from "~/domains/orchestration/flows/core/"
import { messages } from "~/domains/orchestration/flows/messages"
import { AdvancedFieldsValues, ConfigurationProps, type FlowNode, NodeType } from "~/domains/orchestration/flows/types"

import { IconButton } from "../IconButton"
import { NodeList } from "./NodeList"
import "./SideBar.scss"

export interface SideBarProps {
    handleDragStart: (nodeType: NodeType) => (event: React.DragEvent<HTMLDivElement>) => void
    selectedNode?: FlowNode
    handleNodeClick: (nodeType: NodeType) => void
    handleClose: () => void
}
const getConfigurationComponent = (selectedNode: FlowNode) => {
    // Map node types to their configuration components

    const ConfigComponent = nodeConfig[selectedNode.type].Configuration as React.FC<ConfigurationProps<FlowNode>>
    if (!ConfigComponent) return null

    const advancedFields = nodeConfig[selectedNode.type].advancedFields

    return (
        <ConfigComponent
            selectedNode={selectedNode}
            advancedFields={advancedFields as AdvancedFieldsValues<FlowNode>}
        />
    )
}

export const SideBar: FC<SideBarProps> = ({ handleDragStart, selectedNode, handleNodeClick, handleClose }) => {
    return (
        <div className="flows-editor-sideBar">
            <Stack
                className="flows-editor-sideBar-header"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <SafeFormattedMessage
                    tagName="h4"
                    {...(selectedNode ? messages.sideBar.configurationTitle : messages.sideBar.listTitle)}
                />
                <IconButton type="close" onClick={handleClose}>
                    <X size={14} />
                </IconButton>
            </Stack>

            {selectedNode ? (
                getConfigurationComponent(selectedNode)
            ) : (
                <NodeList
                    handleDragStart={handleDragStart}
                    handleClick={handleNodeClick}
                    showLabels
                    direction="column"
                />
            )}
        </div>
    )
}
