import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { invoiceApi } from "~/api"
import { useAppSelector } from "~/store/hooks"
import { selectOcrInvolvedPeople } from "~/store/ocr/ocrSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { getOrCreateUserByEmailAndJoinOrganization } from "~/store/users/utils"
import { CountryCode, InvoiceBackendVersion, OrganizationIdentifier, UserId } from "~/types"
import { sleep } from "~/utils"
import { emailValid } from "~/utils/string"

export const messages = defineMessages({
    addInvolvedPeopleSuccess: {
        id: "invoice.addInvolvedPeople.success",
        defaultMessage: "Involved person{s} added",
    },
    errorInvolvedPeople: {
        id: "invoice.addInvolvedPeople.error",
        defaultMessage: "Error adding involved person: {error}",
    },
})

const addInvolvedPeopleAndJoinOrganization = async (
    invoiceId: string,
    email: string,
    countryCode: CountryCode,
    identifier: OrganizationIdentifier,
    invoiceVersion: InvoiceBackendVersion,
    organizationId: string
) => {
    try {
        const user = await getOrCreateUserByEmailAndJoinOrganization({ email, identifier, countryCode })
        await invoiceApi.addInvolvedPerson(invoiceId, user.userId, invoiceVersion, organizationId)
    } catch (error) {
        console.error(`${error}`)
    }
}

export const useAddInvolvedPeopleAndJoinOrganization = (
    invoiceId: string | null | undefined,
    invoiceVersion: InvoiceBackendVersion = InvoiceBackendVersion.V0
) => {
    const { formatMessage } = useIntl()
    const involvedPeople: UserId[] = useAppSelector(selectOcrInvolvedPeople)
    const organizationId = useAppSelector(selectCurrentOrganizationId)

    return useCallback(
        async (countryCode: CountryCode, identifier: OrganizationIdentifier, emailsToAdd?: string[]) => {
            if (invoiceId && organizationId) {
                try {
                    const emails = emailsToAdd
                        ? emailsToAdd.filter((email) => emailValid(email))
                        : involvedPeople.filter((email) => emailValid(email))
                    if (emails.length > 0) {
                        await Promise.all(
                            emails.map((email) =>
                                addInvolvedPeopleAndJoinOrganization(
                                    invoiceId,
                                    email,
                                    countryCode,
                                    identifier,
                                    invoiceVersion,
                                    organizationId
                                )
                            )
                        )
                        toast.success(
                            formatMessage(messages.addInvolvedPeopleSuccess, { s: emails.length > 1 ? "s" : "" })
                        )
                    } else {
                        await sleep(500)
                    }
                } catch (error) {
                    toast.error(formatMessage(messages.errorInvolvedPeople, { error: `${error}` }))
                }
            }
        },
        [invoiceId, involvedPeople]
    )
}
