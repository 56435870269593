import { LinearProgress } from "@mui/material"
import React, { useEffect, useMemo } from "react"

import {
    EXPECTED_DURATION,
    getJobProgress,
} from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/getJobProgress"
import { DocumentJobI } from "~/domains/transactions/invoices/_views/ocr/types"
import { useFakeProgress } from "~/utils/hooks"

interface Props {
    job: DocumentJobI
}

const computeTimeConstant = (job: DocumentJobI): number =>
    new Date(job.expectedCompletedAt).getTime() - new Date(job.createdAt).getTime()

export const PendingJobProgress: React.FC<Props> = ({ job }) => {
    const initialProgress = useMemo(() => getJobProgress(job), [job])
    const { progress, setTimeConstant } = useFakeProgress({
        initialProgress,
        autoStart: true,
        timeConstant: new Date(job.expectedCompletedAt).getTime() - new Date(job.createdAt).getTime(),
    })
    useEffect(() => {
        setTimeConstant(computeTimeConstant(job))
    }, [setTimeConstant, job])
    return <LinearProgress variant="determinate" value={100 * progress} sx={{ width: "100%" }} />
}
