import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorization } from "~/api/prepareHeaders"
import { PaymentTerm, PaymentTermObject } from "~/domains/payment/payment-terms/types"
import { PaginatedResponse } from "~/domains/payment/types"

const BASE_URL = import.meta.env.VITE_API_PAYMENT_URL_V2

const tags = ["PaymentTerm"]

export const paymentTermsApi = createApi({
    reducerPath: "paymentTermsApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorization }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getPaymentTerms: builder.query<PaginatedResponse<PaymentTerm>, unknown>({
            query: () => ({
                url: "/payment_term",
            }),
        }),
        getPaymentTermsByObject: builder.query<PaginatedResponse<PaymentTerm>, string>({
            query: (object_id: string) => ({
                url: `/payment_term/by_object/${object_id}`,
            }),
            providesTags: tags,
        }),
        getPaymentTermObjects: builder.query<PaginatedResponse<PaymentTermObject>, unknown>({
            query: ({ object_id }) => ({
                url: "/payment_term_object",
                params: { object_id },
            }),
            providesTags: tags,
        }),
        createPaymentTermObject: builder.mutation<PaymentTermObject, Partial<PaymentTermObject>>({
            query: (paymentTermObject: PaymentTermObject) => ({
                url: "/payment_term_object",
                method: "POST",
                body: paymentTermObject,
            }),
            invalidatesTags: tags,
        }),
        deletePaymentTermObject: builder.mutation<PaymentTermObject, string>({
            query: (paymentTermObjectId: string) => ({
                url: `/payment_term_object/${paymentTermObjectId}`,
                method: "DELETE",
            }),
            invalidatesTags: tags,
        }),
        updatePaymentTermObject: builder.mutation<PaymentTermObject, Partial<PaymentTermObject>>({
            query: ({ id, ...updateData }) => ({
                url: `/payment_term_object/${id}`,
                method: "PUT",
                body: updateData,
            }),
            invalidatesTags: tags,
        }),
    }),
})

export const {
    useGetPaymentTermsQuery,
    useGetPaymentTermsByObjectQuery,
    useGetPaymentTermObjectsQuery,
    useCreatePaymentTermObjectMutation,
    useUpdatePaymentTermObjectMutation,
    useDeletePaymentTermObjectMutation,
} = paymentTermsApi
