import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"

import { Invoice } from "./pages/[ID]"
import { InvoiceEdit } from "./pages/[ID]/edit"
import { INVOICE_EDIT_ROUTE, INVOICE_ROUTE } from "./routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="invs_route">
            <Route path={INVOICE_EDIT_ROUTE} element={<InvoiceEdit />} />
        </Route>,
        <Route key="invs_unsafe_route">
            <Route path={INVOICE_ROUTE} element={<Invoice />} />
        </Route>,
    ],
}
