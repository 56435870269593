import { AxiosInstance, isAxiosError } from "axios"
import { useContext } from "react"

import {
    MessageFromApiAdapter,
    MessageListFromApiAdapter,
    MessageTemplateFromApiAdapter,
    MessageViewFromApiAdapter,
    populateActionPlansInCommunicationChannels,
} from "~/domains/communication/api/adapters"
import { CommunicationChannelsFromApiAdapter } from "~/domains/communication/api/adapters/CommunicationChannelsFromApiAdapter"
import { CommunicationChannelI } from "~/domains/communication/types/CommunicationChannel"
import { MessageTemplateI } from "~/domains/communication/types/MessageTemplate"
import { OrganizationId } from "~/types"
import { Result, ResultError, ResultSuccess, isResultError } from "~/types/Result"
import { ApiContext, FetchError, FetchErrorType, parseResponse, parseResponseAsArray } from "~/utils/apiClient"

import { MessageId, RoomId, parseRoom } from "../types"
import { CreateMessagePayload } from "../types/CreateMessage"
import { CreateRoomOptions, CreateRoomPayload } from "../types/CreateRoom"

const BASE_URL = import.meta.env.VITE_API_COMMUNICATION_URL

class CommunicationApi {
    private static instance: CommunicationApi
    private constructor(private axiosClient: AxiosInstance) {}

    static getInstance(axiosClient: AxiosInstance) {
        if (!CommunicationApi.instance) {
            CommunicationApi.instance = new CommunicationApi(axiosClient)
        }
        return CommunicationApi.instance
    }

    buildUrl(organizationId: OrganizationId, path: string) {
        return `${BASE_URL}organizations/${organizationId}${path}`
    }

    async findRoomsForObjectIds(organizationId: OrganizationId, objectId: string) {
        const url = this.buildUrl(organizationId, `/rooms/object/${objectId}`)
        const response = await this.axiosClient.get(url)
        return parseResponseAsArray(response, parseRoom)
    }

    async createRoom(organizationId: OrganizationId, createPayload: CreateRoomPayload, options?: CreateRoomOptions) {
        const url = `${this.buildUrl(organizationId, "/rooms")}${
            options?.onlySharedRoom ? `?onlySharedRoom=True` : options?.onlyPrivateRoom ? "?onlyPrivateRoom=True" : ""
        }`
        const response = await this.axiosClient.post(url, createPayload)
        return parseResponseAsArray(response, parseRoom)
    }

    async updateRoom(organizationId: OrganizationId, roomId: RoomId, updatePayload: Partial<CreateRoomPayload>) {
        const url = this.buildUrl(organizationId, `/rooms/${roomId}`)
        const response = await this.axiosClient.put(url, updatePayload)
        return parseResponse(response, parseRoom)
    }

    async getMessages(organizationId: OrganizationId, roomId: RoomId, page = 1, perPage = 20) {
        const url = this.buildUrl(organizationId, `/rooms/${roomId}/messages?page=${page}&per_page=${perPage}`)
        const response = await this.axiosClient.get(url)
        return parseResponse(response, MessageListFromApiAdapter)
    }

    async getMessage(messageId: MessageId) {
        const url = `${BASE_URL}messages/${messageId}`
        const response = await this.axiosClient.get(url)
        return parseResponse(response, MessageFromApiAdapter)
    }

    async sendMessage(organizationId: OrganizationId, roomId: RoomId, createMessagePayload: CreateMessagePayload) {
        const url = this.buildUrl(organizationId, `/rooms/${roomId}/messages`)
        const response = await this.axiosClient.post(url, createMessagePayload)
        return parseResponse(response, MessageFromApiAdapter)
    }

    async updateMessage(messageId: MessageId, updatePayload: Partial<CreateMessagePayload>) {
        const url = `${BASE_URL}messages/${messageId}`
        const response = await this.axiosClient.put(url, updatePayload)
        return parseResponse(response, MessageFromApiAdapter)
    }

    async getMessageView(messageId: MessageId) {
        const url = `${BASE_URL}messages/${messageId}`
        const response = await this.axiosClient.get(url)
        return response.data
    }

    async setMessageAsViewed(messageId: MessageId) {
        const url = `${BASE_URL}messages/${messageId}/viewed`
        const response = await this.axiosClient.post(url)
        return parseResponse(response, MessageViewFromApiAdapter)
    }

    async getActionPlans(organizationId: OrganizationId) {
        const url = `${BASE_URL}messages/organizations/${organizationId}/actions`
        try {
            const response = await this.axiosClient.get(url)
            const result = parseResponseAsArray(response, CommunicationChannelsFromApiAdapter)
            if (isResultError(result)) return result
            return ResultSuccess(result.result.map(populateActionPlansInCommunicationChannels))
        } catch (error) {
            if (isAxiosError(error)) {
                return ResultError<FetchError<CommunicationChannelI>>({
                    type: FetchErrorType.HTTP_REQUEST_ERROR,
                    code: error.response?.status ?? 500,
                    error: error.response?.statusText ?? `${error}`,
                })
            }
            return ResultError<FetchError<CommunicationChannelI>>({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `${error}`,
            })
        }
    }

    async getTemplates(
        organizationId: OrganizationId
    ): Promise<Result<MessageTemplateI[], FetchError<MessageTemplateI>>> {
        const url = this.buildUrl(organizationId, "/templates")
        try {
            const response = await this.axiosClient.get(url)
            return parseResponseAsArray(response, MessageTemplateFromApiAdapter)
        } catch (error) {
            if (isAxiosError(error)) {
                return ResultError<FetchError<MessageTemplateI>>({
                    type: FetchErrorType.HTTP_REQUEST_ERROR,
                    code: error.response?.status ?? 500,
                    error: error.response?.statusText ?? `${error}`,
                })
            }
            return ResultError<FetchError<MessageTemplateI>>({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `${error}`,
            })
        }
    }
}

export const useCommunicationApi = () => {
    const { axiosClient } = useContext(ApiContext)
    return CommunicationApi.getInstance(axiosClient)
}
