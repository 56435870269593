import { Autocomplete, AutocompleteRenderInputParams, CircularProgress, TextField, styled } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Chip } from "~/components/Chip"
import { PartnerIbanCheck } from "~/domains/partners/components/PartnerProfile/components/PartnerIbanCheck"
import { useFetchPartnerPaymentDetails } from "~/domains/partners/store/hooks"
import { PartnerProfilePaymentDetailI, SourceType } from "~/domains/partners/types"
import { IBANValidationWarning } from "~/domains/transactions/invoices/components/Payment/IbanValidationCheck"
import { displayIban, sanitizeIban } from "~/domains/transactions/invoices/hooks"
import { chipStyles } from "~/features/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { ibanStyledPopper } from "~/features/organization/components/assets/config"
import { useAppSelector } from "~/store/hooks"
import { selectCreatingPaymentDetailsIban } from "~/store/invoice/invoiceSlice"
import { OrganizationId } from "~/types"

const OCR_ID = "ocrIbanData"

const messages = defineMessages({
    placeholder: {
        id: "partners.bookofrelations.selectPaymentDetails.placeholder",
        defaultMessage: "IBAN",
    },
    errorPaymentDetails: {
        id: "partners.bookofrelations.selectPaymentDetails.ErrorFetching",
        defaultMessage: "There has been an issue retrieving this partner's payment details.",
    },
    verifiedStatusLabel: {
        id: "partners.bookofrelations.selectPaymentDetails.verifiedStatus",
        defaultMessage: "Verified",
    },
    myBook: {
        id: "partners.bookofrelations.selectors.myBook",
        defaultMessage: "Partner",
    },
    OCRLabel: {
        id: "partners.bookofrelations.selectPaymentDetails.ocr",
        defaultMessage: "OCR",
    },
})

const getBankName = (paymentDetail: PartnerProfilePaymentDetailI) => {
    return paymentDetail.bankName?.trim() || ""
}

const getLabelName = (paymentDetail: PartnerProfilePaymentDetailI) => {
    return paymentDetail.label?.trim() || ""
}

const getIban = (paymentDetail: PartnerProfilePaymentDetailI) => {
    return paymentDetail.iban?.trim() || ""
}

const getPaymentDetailsLabel = (paymentDetail: PartnerProfilePaymentDetailI | string | null) => {
    if (!paymentDetail) {
        return ""
    }
    if (typeof paymentDetail === "string") {
        return paymentDetail
    }
    return paymentDetail.iban ? `${paymentDetail.iban}`.trim() : ""
}

const CheckContainer = styled("div")({
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "4px",
})

interface Props {
    currentOrganizationId: OrganizationId
    partnerId: OrganizationId
    onPaymentDetailChange: (paymentDetail: PartnerProfilePaymentDetailI | null) => void
    label?: string
    isIbanRequired?: boolean
    isIbanDisabled?: boolean
}

export const PartnerPaymentDetailsSelector = ({
    currentOrganizationId,
    partnerId,
    onPaymentDetailChange,
    label,
    isIbanRequired = true,
    isIbanDisabled = false,
}: Props) => {
    const { formatMessage } = useIntl()
    const ibanFromOCR = useAppSelector(selectCreatingPaymentDetailsIban)

    const ocrDataAsPaymentDetails = useMemo(
        () => (ibanFromOCR ? ({ id: OCR_ID, iban: sanitizeIban(ibanFromOCR) } as PartnerProfilePaymentDetailI) : null),
        [ibanFromOCR]
    )

    const [selectedPaymentDetail, setSelectedPaymentDetail] = useState<PartnerProfilePaymentDetailI | null>(
        ocrDataAsPaymentDetails
    )

    const { paymentDetails, loading, error } = useFetchPartnerPaymentDetails(currentOrganizationId, partnerId as string)

    const filteredPaymentDetails = useMemo(() => paymentDetails?.filter((detail) => detail.iban), [paymentDetails])
    const partnerIbans = filteredPaymentDetails?.map((detail) => detail.iban) ?? []
    const allPaymentDetails = useMemo(
        () =>
            ocrDataAsPaymentDetails
                ? [ocrDataAsPaymentDetails, ...(filteredPaymentDetails ?? [])]
                : filteredPaymentDetails,
        [ocrDataAsPaymentDetails, filteredPaymentDetails]
    )

    const handleChange = useCallback(
        (_event: unknown, value: PartnerProfilePaymentDetailI | string | null) => {
            if (value === null) {
                setSelectedPaymentDetail(null)
                onPaymentDetailChange(null)
                return
            }
            const paymentDetail =
                typeof value === "string"
                    ? ({ id: value, label: "", iban: sanitizeIban(value) } as PartnerProfilePaymentDetailI)
                    : (allPaymentDetails?.find((detail) => detail?.id === value?.id) ?? null)
            setSelectedPaymentDetail(paymentDetail)
            onPaymentDetailChange(paymentDetail)
        },
        [onPaymentDetailChange, allPaymentDetails]
    )

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => {
            return (
                <TextField
                    {...params}
                    required={isIbanRequired}
                    label={label ?? formatMessage(messages.placeholder)}
                    error={Boolean(error)}
                    aria-expanded={true}
                    inputProps={{
                        ...params.inputProps,
                        value:
                            typeof params.inputProps.value === "string"
                                ? displayIban(params.inputProps.value)
                                : params.inputProps.value,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && <CircularProgress size={20} />}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )
        },
        [label, error, loading, formatMessage, messages, isIbanRequired]
    )

    const renderChips = useCallback(
        (option: PartnerProfilePaymentDetailI) => {
            if (!option) return null

            return option.id === OCR_ID ? (
                <Chip size="medium" variant="warning" sx={chipStyles}>
                    {formatMessage(messages.OCRLabel)}
                </Chip>
            ) : option.source ? (
                <>
                    {option?.verifiedStatus && (
                        <Chip size="medium" variant="success" sx={chipStyles}>
                            {formatMessage(messages.verifiedStatusLabel)}
                        </Chip>
                    )}
                    <Chip size="medium" variant="primary" sx={chipStyles}>
                        {formatMessage(messages.myBook)}
                    </Chip>
                </>
            ) : null
        },
        [formatMessage]
    )

    const renderOption = useCallback(
        (params: React.HTMLAttributes<HTMLLIElement>, option: PartnerProfilePaymentDetailI) => {
            if (!option) return null
            const bankName = getBankName(option)

            return (
                <li
                    {...params}
                    key={option.source === SourceType.INVOICE ? "OCR" : option.id}
                    className="autocomplete-iban"
                >
                    <div className="autocomplete-iban-label">
                        <div className="autocomplete-iban-name">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: getLabelName(option),
                                }}
                            />
                            {bankName && <span className="autocomplete-iban-bankName">({bankName})</span>}
                        </div>
                        <div className="autocomplete-iban-number">{displayIban(sanitizeIban(getIban(option)))}</div>
                    </div>
                    {renderChips(option)}
                </li>
            )
        },
        [renderChips]
    )

    const isOptionEqualToValue = (
        option: PartnerProfilePaymentDetailI,
        value: PartnerProfilePaymentDetailI | null
    ): boolean => {
        return value ? option.id === value.id : false
    }

    if (!allPaymentDetails) return null

    return (
        <>
            <Autocomplete
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="payment-details-selector"
                options={allPaymentDetails}
                getOptionLabel={getPaymentDetailsLabel}
                value={selectedPaymentDetail}
                inputValue={selectedPaymentDetail?.iban ?? ""}
                onInputChange={handleChange}
                onChange={handleChange}
                renderInput={renderInput}
                renderOption={renderOption}
                isOptionEqualToValue={isOptionEqualToValue}
                PopperComponent={ibanStyledPopper}
                disabled={isIbanDisabled}
            />
            <CheckContainer>
                <IBANValidationWarning iban={selectedPaymentDetail?.iban} />
                <PartnerIbanCheck
                    selectedIban={selectedPaymentDetail?.iban}
                    partnerIbans={partnerIbans}
                    isVerifiedIban={selectedPaymentDetail?.verifiedStatus ?? false}
                />
            </CheckContainer>
        </>
    )
}
