import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { addTagRecursively, removeTagsRecursively, updateTagRecursively } from "~/domains/tags/core/tagGroupsAndTags"
import { initialState } from "~/domains/tags/store/TagsStore"
import { SelectedTagI, SelectedTagWithObjectIdI, TagI } from "~/domains/tags/types"
import { TagGroupI } from "~/domains/tags/types/TagGroup"
import { RootState } from "~/store"
import { OrganizationId } from "~/types"

const tagsSlice = createSlice({
    name: "tags",
    initialState: initialState,
    reducers: {
        fetchOrganizationTagGroups: (
            state,
            { payload }: PayloadAction<{ organizationId: OrganizationId; loading?: boolean }>
        ) => {
            state.tagsForOrganizationId = payload.organizationId
            state.tagGroups = null
            state.errorLoadingTagGroups = undefined
            state.loadingTagGroups = payload.loading !== false
        },
        fetchOrganizationTagGroupsSuccess: (
            state,
            { payload }: PayloadAction<{ organizationId: OrganizationId; tagGroups: TagGroupI[] }>
        ) => {
            if (state.tagsForOrganizationId === payload.organizationId) {
                state.tagGroups = payload.tagGroups
                state.loadingTagGroups = false
            }
        },
        fetchOrganizationTagGroupsFailed: (
            state,
            { payload }: PayloadAction<{ organizationId: OrganizationId; error: string }>
        ) => {
            if (state.tagsForOrganizationId === payload.organizationId) {
                state.errorLoadingTagGroups = payload.error
                state.loadingTagGroups = false
            }
        },
        createTagGroup: (
            state,
            {
                payload: { organizationId, tagGroup },
            }: PayloadAction<{ organizationId: OrganizationId; tagGroup: TagGroupI }>
        ) => {
            if (state.tagsForOrganizationId === organizationId) {
                state.tagGroups = [...(state.tagGroups ?? []), tagGroup]
            }
        },
        updateTagGroup: (state, { payload: { tagGroup } }: PayloadAction<{ tagGroup: TagGroupI }>) => {
            if (state.tagGroups) {
                state.tagGroups = state.tagGroups.map((stateTagGroup) =>
                    stateTagGroup.tagGroupId === tagGroup.tagGroupId ? tagGroup : stateTagGroup
                )
            }
        },
        deleteTagGroup: (state, { payload: { tagGroup } }: PayloadAction<{ tagGroup: TagGroupI }>) => {
            if (state.tagGroups) {
                state.tagGroups = state.tagGroups.filter(
                    (stateTagGroup) => stateTagGroup.tagGroupId !== tagGroup.tagGroupId
                )
            }
        },
        deleteTagGroups: (state, { payload: { tagGroups } }: PayloadAction<{ tagGroups: TagGroupI[] }>) => {
            if (state.tagGroups) {
                const tagGroupIdsToRemove = tagGroups.map((tagGroup) => tagGroup.tagGroupId)
                state.tagGroups = state.tagGroups.filter(
                    (stateTagGroup) => !tagGroupIdsToRemove.includes(stateTagGroup.tagGroupId)
                )
            }
        },
        addTag: (
            state,
            { payload: { organizationId, tag } }: PayloadAction<{ organizationId: OrganizationId; tag: TagI }>
        ) => {
            if (state.tagsForOrganizationId === organizationId && state.tagGroups) {
                state.tagGroups = state.tagGroups.map((stateTagGroup) =>
                    stateTagGroup.tagGroupId === tag.tagGroupId
                        ? {
                              ...stateTagGroup,
                              tags: addTagRecursively(stateTagGroup.tags, tag),
                          }
                        : stateTagGroup
                )
            }
        },
        updateTag: (
            state,
            { payload: { organizationId, tag } }: PayloadAction<{ organizationId: OrganizationId; tag: TagI }>
        ) => {
            if (state.tagsForOrganizationId === organizationId && state.tagGroups) {
                // Find indices of the existing group where the tag resides and the target group
                const existingGroupTagIndex = state.tagGroups.findIndex((stateTagGroup) =>
                    stateTagGroup.tags.find((t) => t.tagId === tag.tagId)
                )
                const targetGroupIndex = state.tagGroups.findIndex(
                    (stateTagGroup) => stateTagGroup.tagGroupId === tag.tagGroupId
                )

                // Move the tag if it's being moved to a different tagGroup
                if (
                    existingGroupTagIndex !== -1 &&
                    targetGroupIndex !== -1 &&
                    existingGroupTagIndex !== targetGroupIndex
                ) {
                    // Remove the tag from the old group using removeTagsRecursively
                    state.tagGroups = state.tagGroups.map((stateTagGroup, index) =>
                        index === existingGroupTagIndex
                            ? {
                                  ...stateTagGroup,
                                  tags: removeTagsRecursively(stateTagGroup.tags, [tag.tagId]),
                              }
                            : stateTagGroup
                    )

                    // Add the tag to the new group using addTagRecursively
                    state.tagGroups = state.tagGroups.map((stateTagGroup, index) =>
                        index === targetGroupIndex
                            ? {
                                  ...stateTagGroup,
                                  tags: addTagRecursively(stateTagGroup.tags, tag),
                              }
                            : stateTagGroup
                    )
                } else {
                    // If the tag remains in the same group, just update it recursively
                    state.tagGroups = state.tagGroups.map((stateTagGroup) =>
                        stateTagGroup.tagGroupId === tag.tagGroupId
                            ? {
                                  ...stateTagGroup,
                                  tags: updateTagRecursively(stateTagGroup.tags, tag),
                              }
                            : stateTagGroup
                    )
                }
            }
        },
        deleteTag: (
            state,
            { payload: { organizationId, tag } }: PayloadAction<{ organizationId: OrganizationId; tag: TagI }>
        ) => {
            if (state.tagsForOrganizationId === organizationId && state.tagGroups) {
                state.tagGroups = state.tagGroups.map((stateTagGroup) =>
                    stateTagGroup.tagGroupId === tag.tagGroupId
                        ? {
                              ...stateTagGroup,
                              tags: removeTagsRecursively(stateTagGroup.tags, [tag.tagId]), // Use recursive function
                          }
                        : stateTagGroup
                )
            }
        },
        deleteTags: (
            state,
            { payload: { organizationId, tags } }: PayloadAction<{ organizationId: OrganizationId; tags: TagI[] }>
        ) => {
            if (state.tagsForOrganizationId === organizationId && state.tagGroups) {
                state.tagGroups = state.tagGroups.map((stateTagGroup) => {
                    // Get the list of tagIds to remove for this tag group
                    const tagIdsToRemove = tags
                        .filter((tag) => tag.tagGroupId === stateTagGroup.tagGroupId)
                        .map((tag) => tag.tagId)

                    // If there are tags to remove, recursively remove them from the tag group
                    return tagIdsToRemove.length
                        ? {
                              ...stateTagGroup,
                              tags: removeTagsRecursively(stateTagGroup.tags, tagIdsToRemove), // Recursively remove tags
                          }
                        : stateTagGroup
                })
            }
        },
        setLinesTags(state, action: PayloadAction<SelectedTagWithObjectIdI[]>) {
            state.linesTags = action.payload
        },
        addNewLinesTags(state, action: PayloadAction<SelectedTagWithObjectIdI[]>) {
            state.linesTags = [...state.linesTags, ...action.payload]
        },
        setPendingTags(state, action: PayloadAction<SelectedTagI[] | ((state: SelectedTagI[]) => SelectedTagI[])>) {
            state.pendingTags = Array.isArray(action.payload) ? action.payload : action.payload(state.pendingTags)
        },
        setPendingLineTags(state, action: PayloadAction<{ lineId: string; tags: SelectedTagI[] }>) {
            state.pendingLineTags[action.payload.lineId] = action.payload.tags
        },
        setPendingLinesTags(state, action: PayloadAction<Record<string, SelectedTagI[]>>) {
            for (const lineId in action.payload) {
                state.pendingLineTags[lineId] = action.payload[lineId]
            }
        },
        resetPendingTags(state) {
            state.pendingTags = []
            state.pendingLineTags = {}
        },
        tagsDuplicationSuccess(state) {
            state.duplicationSuccessful = true
        },
        resetTagsDuplicationSuccess(state) {
            state.duplicationSuccessful = false
        },
        setSelectedTagsForFilter(state, action: PayloadAction<SelectedTagI[]>) {
            state.selectedTagsForFilter = action.payload
        },
        resetSelectedTagsForFilter(state) {
            state.selectedTagsForFilter = []
        },
    },
})

export const tagsActions = tagsSlice.actions
export const tagsReducer = tagsSlice.reducer

export const selectTagGroups = (state: RootState) => state.tags.tagGroups
export const selectLinesTags = (state: RootState) => state.tags.linesTags
export const selectPendingTags = (state: RootState) => state.tags.pendingTags
export const selectPendingLineTags = (state: RootState) => state.tags.pendingLineTags
export const selectDuplicationSuccessful = (state: RootState) => state.tags.duplicationSuccessful
export const selectSelectedTagsForFilter = (state: RootState) => state.tags.selectedTagsForFilter
