import { Typography, styled } from "@mui/material"
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from "@xyflow/react"
import React from "react"

import { useEditor } from "~/domains/orchestration/flows/context/editorContext"
import { isBranchNode } from "~/domains/orchestration/flows/types/Flows"

const EdgeLabel = styled("div")<{ labelX: number; labelY: number }>(({ labelX, labelY }) => ({
    position: "absolute",
    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
    // everything inside EdgeLabelRenderer has no pointer events by default
    // if you have an interactive element, set pointer-events: all
    pointerEvents: "all",
}))

const Label = styled(Typography)({
    backgroundColor: "var(--color-white)",
    padding: "var(--spacing-xs)",
    borderRadius: "var(--border-radius-sm)",
})

export const EdgeWithLabel = ({
    target,
    source,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}: EdgeProps) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const state = useEditor()
    const currentFlow = state.flow

    const node = currentFlow?.nodes.find((node) => node.slug === source)

    if (!node || !isBranchNode(node)) return null

    const edgeLabel = node.branches.find((branch) => branch.nextNode === target)?.name || ""

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <EdgeLabel labelX={labelX} labelY={labelY} className="nodrag nopan">
                    <Label variant="body2">{edgeLabel}</Label>
                </EdgeLabel>
            </EdgeLabelRenderer>
        </>
    )
}
