import { Divider, Grid, Stack, Step, StepIconProps, StepLabel, Stepper, Typography } from "@mui/material"
import * as Sentry from "@sentry/browser"
import dayjs from "dayjs"
import React, { FC, useEffect } from "react"
import { CheckCircle, Square, XCircle } from "react-feather"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import { useGetRunQuery } from "~/domains/orchestration/flows/api/runsApi"
import { useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks/"
import { messages } from "~/domains/orchestration/flows/messages"
import { RunId, RunStatus } from "~/domains/orchestration/flows/types"

interface Props {
    runId: RunId
}

const WARNING_COLOR = "var(--color-yellow)"
const ERROR_COLOR = "var(--color-red)"
const SUCCESS_COLOR = "var(--color-primary)"
const NEUTRAL_COLOR = "var(--color-grey)"

const StepIcon: FC<StepIconProps> = ({ active, completed, error }) => {
    if (completed) {
        return <CheckCircle color={NEUTRAL_COLOR} />
    }
    if (error) {
        return <XCircle color={ERROR_COLOR} />
    }
    if (active) {
        return <Square color={WARNING_COLOR} />
    }
    return <CheckCircle color={SUCCESS_COLOR} />
}

export const RunExplorerItem: FC<Props> = ({ runId }) => {
    const { formatMessage } = useIntl()
    const dispatch = useEditorDispatch()

    const organizationId = useOrganizationId()

    const { data, error, isLoading, isError, refetch } = useGetRunQuery({ runId }, { refetchOnMountOrArgChange: true })

    useEffect(() => {
        if (organizationId) {
            refetch()
        }
    }, [organizationId, refetch])

    useEffect(() => {
        if (data) {
            dispatch({ type: "SET_RUN", payload: data })
        }
    }, [data])

    if (isLoading) {
        return <Loader fullscreen />
    }
    if (isError) {
        toast.error(formatMessage(messages.error.loadingRuns))
        Sentry.captureException(error)
        return null
    }
    if (!data) {
        return null
    }

    const errorStatuses = [RunStatus.FAILED]
    const warningStatuses = [RunStatus.CANCELED, RunStatus.SUSPENDED]

    const hasError = errorStatuses.includes(data.status)
    const hasWarning = warningStatuses.includes(data.status)

    const color = hasError ? ERROR_COLOR : hasWarning ? WARNING_COLOR : SUCCESS_COLOR

    return (
        <Stack gap={2}>
            <Divider />
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2">
                    {formatMessage(messages.runInformation.flowVersion)}: {data.flowVersion}
                </Typography>

                <Typography variant="body2" color={color}>
                    {formatMessage(messages.runInformation.status)}: {data.status}
                </Typography>
            </Stack>

            <Divider />
            <Grid container>
                <Grid item xs={6}>
                    <Stepper activeStep={data.state.pathTaken.length - 1} orientation="vertical">
                        {data.state.pathTaken.map((step, index) => (
                            <Step key={index}>
                                <StepLabel
                                    error={hasError}
                                    StepIconComponent={StepIcon}
                                    StepIconProps={{ active: hasWarning }}
                                >
                                    <Typography
                                        variant="body2"
                                        color={index === data.state.pathTaken.length - 1 ? color : NEUTRAL_COLOR}
                                    >
                                        {step}
                                    </Typography>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Stack>
                        <Typography variant="caption" color={color}>
                            {formatMessage(messages.runInformation.startedAt)}: {dayjs(data.startedAt).format("L LT")}
                        </Typography>
                        {data.finishedAt ? (
                            <Typography variant="caption" color={color}>
                                {formatMessage(messages.runInformation.finishedAt)}:{" "}
                                {dayjs(data.finishedAt).format("L LT")}
                            </Typography>
                        ) : null}
                    </Stack>
                </Grid>
            </Grid>

            {data.error && (
                <Typography variant="caption" color={ERROR_COLOR}>
                    {formatMessage(messages.runInformation.error)}: {data.error}
                </Typography>
            )}

            <Divider />
            <Stack>
                <Typography variant="caption" color="text.secondary">
                    {formatMessage(messages.runInformation.id)}: {data.id}
                </Typography>

                <Typography variant="caption" color="text.secondary">
                    {formatMessage(messages.runInformation.flowId)}: {data.flowId}
                </Typography>
            </Stack>
        </Stack>
    )
}
