import React, { useState } from "react"
import { FileText } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button } from "~/components"
import { CheckFileTypeResult } from "~/components/UploadDocument/UploadDocument"

import { ModalUploadInvoice } from "../ModalUploadInvoice/ModalUploadInvoice"

const messages = defineMessages({
    modalUploadTitle: {
        id: "transactions.purchaseOrder.documentRelations.uploadBox.title",
        defaultMessage: "Upload an invoice",
    },
    uploadTip: {
        id: "transactions.purchaseOrder.documentRelations.uploadBox.tip",
        defaultMessage: "We accept PDF, JPG and PNG files. No more than 2MB.",
    },
    uploadInvoice: {
        id: "transactions.purchaseOrder.documentRelations.uploadBox.uploadInvoice",
        defaultMessage: "Upload an invoice",
    },
})

interface Props {
    handleFile: (file: File) => void
    checkFileType: (file: File) => CheckFileTypeResult
    loading: boolean
    acceptedFileTypes: string
}

export const UploadInvoiceBox: React.FC<Props> = ({ handleFile, checkFileType, loading, acceptedFileTypes }) => {
    const { formatMessage } = useIntl()
    const [modalUploadInvoiceVisible, setModalUploadInvoiceVisible] = useState<boolean>(false)

    const closeModalUploadInvoice = () => setModalUploadInvoiceVisible(false)
    const showModalUploadInvoice = () => setModalUploadInvoiceVisible(true)

    return (
        <>
            <Button type="tertiary" onClick={showModalUploadInvoice}>
                <FileText size={16} />
                {formatMessage(messages.uploadInvoice)}
            </Button>
            <ModalUploadInvoice
                open={modalUploadInvoiceVisible}
                close={closeModalUploadInvoice}
                handleFile={handleFile}
                checkFileType={checkFileType}
                loading={loading}
                uploadTip={messages.uploadTip}
                title={messages.modalUploadTitle}
                acceptedFileTypes={acceptedFileTypes}
            />
        </>
    )
}
