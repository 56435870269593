import React from "react"
import { MessageDescriptor } from "react-intl"

import AlertInfo from "~/assets/images/alert-red.svg"
import { SafeFormattedMessage } from "~/components"
import { InvoiceStatus, ViewTypeI } from "~/types"

export const invoiceStatusLabels: Record<InvoiceStatus, MessageDescriptor> = {
    DRAFT: {
        id: "account.documents.status.buyer.draft",
        defaultMessage: "Draft",
    },
    DUPLICATES: {
        id: "account.documents.status.buyer.duplicates",
        defaultMessage: "Duplicates",
    },
    CONFIRMED: {
        id: "account.documents.status.buyer.created",
        defaultMessage: "To validate",
    },
    INFO_REQUESTED: {
        id: "account.documents.status.buyer.infoRequested",
        defaultMessage: "Info requested",
    },
    PAID: {
        id: "account.documents.status.buyer.paid",
        defaultMessage: "Paid",
    },
    PAYMENT_SCHEDULED: {
        id: "account.documents.status.buyer.paymentScheduled",
        defaultMessage: "Payment Scheduled",
    },
    MARKED_AS_PAID: {
        id: "account.documents.status.buyer.markedAsPaid",
        defaultMessage: "Marked as paid",
    },
    VALIDATED: {
        id: "account.documents.status.buyer.validated",
        defaultMessage: "Validated",
    },
    DRAFT_supplier: {
        id: "account.documents.status.supplier.draft",
        defaultMessage: "Draft",
    },
    CONFIRMED_supplier: {
        id: "account.documents.status.supplier.created",
        defaultMessage: "Sent",
    },
    INFO_REQUESTED_supplier: {
        id: "account.documents.status.supplier.infoRequested",
        defaultMessage: "Info requested",
    },
    PAID_supplier: {
        id: "account.documents.status.supplier.paid",
        defaultMessage: "Paid",
    },
    PAYMENT_SCHEDULED_supplier: {
        id: "account.documents.status.supplier.paymentScheduled",
        defaultMessage: "Payment Scheduled",
    },
    MARKED_AS_PAID_supplier: {
        id: "account.documents.status.supplier.markedAsPaid",
        defaultMessage: "Marked as paid",
    },
    VALIDATED_supplier: {
        id: "account.documents.status.supplier.validated",
        defaultMessage: "Validated",
    },
    REJECTED: {
        id: "account.documents.status.buyer.rejected",
        defaultMessage: "Rejected",
    },
    REJECTED_supplier: {
        id: "account.documents.status.supplier.rejected",
        defaultMessage: "Rejected",
    },
}

interface Props {
    status: InvoiceStatus | string
    view: ViewTypeI
}

export const InvoiceStatusChip: React.FC<Props> = ({ status, view }) => {
    const statusLabel = view === "supplier" ? status + "_supplier" : status
    const message = invoiceStatusLabels[statusLabel]
    return (
        <span className={"invoice-status status-" + status}>
            {status === InvoiceStatus.INFO_REQUESTED ? <img src={AlertInfo} className="red-alert" alt="" /> : null}
            {!message ? statusLabel : <SafeFormattedMessage {...message} values={{ s: "" }} />}
        </span>
    )
}
