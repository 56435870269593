import { SelectedTagI, SelectedTagWithObjectIdI } from "~/domains/tags/types"
import { TagGroupI } from "~/domains/tags/types/TagGroup"
import { OrganizationId } from "~/types"

export interface TagsStore {
    tagsForOrganizationId?: OrganizationId
    tagGroups: TagGroupI[] | null
    loadingTagGroups: boolean
    errorLoadingTagGroups?: string
    linesTags: SelectedTagWithObjectIdI[]
    pendingTags: SelectedTagI[]
    pendingLineTags: Record<string, SelectedTagI[]>
    duplicationSuccessful: boolean
    selectedTagsForFilter: SelectedTagI[]
}

export const initialState: TagsStore = {
    loadingTagGroups: false,
    tagGroups: null,
    linesTags: [],
    pendingTags: [],
    pendingLineTags: {},
    duplicationSuccessful: false,
    selectedTagsForFilter: [],
}
