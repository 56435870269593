import { useEffect } from "react"

import { useLazyGetFlowsQuery } from "~/domains/orchestration/flows/api/flowsApi"
import { FlowId } from "~/domains/orchestration/flows/types"

export const useFlowLatestVersion = (flowId: FlowId): number => {
    const [triggerGetFlows, { data: flows }] = useLazyGetFlowsQuery()
    const latestVersion = flows?.flows.find((flow) => flow.id === flowId)?.version

    useEffect(() => {
        // TODO : false is hardcoded, if true it will fetch archived flows
        // Make it dynamic when feature is implemented
        triggerGetFlows(false)
    }, [])

    return latestVersion || 0
}
