import styled from "@emotion/styled"
import { Stack } from "@mui/material"
import {
    Background,
    BackgroundVariant,
    type Connection,
    Controls,
    type Node,
    NodeMouseHandler,
    Panel,
    ReactFlow,
    XYPosition,
    addEdge,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import debounce from "lodash/debounce"
import React, { FC, memo, useDeferredValue, useEffect, useMemo, useRef, useState } from "react"
import { AlertTriangle, Menu } from "react-feather"
import { useIntl } from "react-intl"

import { Button } from "~/components"
import { EdgeWithLabel } from "~/domains/orchestration/flows/components/EdgeWithLabel"
import { SideBar, SideBarProps } from "~/domains/orchestration/flows/components/editor/SideBar"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import {
    FLOW_NODE_TYPES,
    adaptFlowToEdges,
    adaptFlowToNodes,
    createNode,
    generateReadableId,
} from "~/domains/orchestration/flows/core"
import { useFlowValidator } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/messages"
import {
    type Flow,
    FlowNode,
    NodeType,
    isAddToBudgetNode,
    isApprovePurchaseOrderLineNode,
    isApprovePurchaseOrderNode,
    isApprovePurchaseRequestLineNode,
    isApprovePurchaseRequestNode,
    isAssignTagNode,
    isBranchNode,
    isConvertPrToPoNode,
    isCreateSurveyNode,
    isEventTriggerNode,
    isFetchCustomFieldsNode,
    isRetractReviewsNode,
    isSendEmailNode,
    isSetInvoiceLifecycleStatusNode,
    isSetPartnershipFieldNode,
    isSetPaymentMethodDetailsFieldNode,
    isUpdateTripletexLedgerNode,
} from "~/domains/orchestration/flows/types"

import { ButtonEdge } from "../ButtonEdge"
import { ButtonEdgeWithLabel } from "../ButtonEdgeWithLabel"
import { NodeList } from "./NodeList"

interface Props {
    flow: Flow
    hasWorkflowUpdatePermission: boolean
}

const NODE_WIDTH = 218

const Wrapper = styled.div`
    width: 80vw;
    height: calc(100vh - 100px);
`

const StyledControls = styled(Controls)`
    position: absolute;
    top: 10px;
    box-shadow: none;
`

const StyledPanel = styled(Panel)`
    padding: var(--spacing-md);
    margin-bottom: 40px;
    background-color: var(--sidebar-color);
    border-radius: var(--border-radius-sm);
`

const StyledError = styled(Panel)`
    color: var(--color-yellow);
    padding: var(--spacing-md);
    background-color: var(--sidebar-color);
    border-radius: var(--border-radius-sm);
`

const MenuButton = styled(Button)`
    position: fixed;
    top: 100px;
    right: var(--spacing-xl);
`

const shouldUpdate = (prevProps: SideBarProps, nextProps: SideBarProps) => {
    return prevProps.selectedNode?.slug === nextProps.selectedNode?.slug
}

const MemoizedSidebar = memo(SideBar, shouldUpdate)

export const EditorPanel: FC<Props> = ({ flow, hasWorkflowUpdatePermission }) => {
    const reactFlowWrapper = useRef(null)
    const { formatMessage } = useIntl()
    const state = useEditor()
    const currentFlow = state.flow || flow
    const { error } = state

    const [nodes, setNodes, onNodesChange] = useNodesState(adaptFlowToNodes(currentFlow))
    const [edges, setEdges, onEdgesChange] = useEdgesState(adaptFlowToEdges(currentFlow))
    const [selectedNode, setSelectedNode] = useState<FlowNode>()
    const { screenToFlowPosition } = useReactFlow()
    const dispatch = useEditorDispatch()

    const [currentNodeType, setCurrentNodeType] = useState<NodeType | null>(null)
    const [showBottomPanel, setShowBottomPanel] = useState(false)

    const deferedNodes = useDeferredValue(nodes)
    const deferedEdges = useDeferredValue(edges)

    const edgeTypes = useMemo(
        () => ({
            button: ButtonEdge,
            buttonWithLabel: ButtonEdgeWithLabel,
            withLabel: EdgeWithLabel,
        }),
        []
    )

    const updateFlow = (flow: Flow) => {
        dispatch({
            type: "SET_FLOW",
            payload: flow,
        })

        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: true,
        })
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault()
        event.dataTransfer.dropEffect = "move"
    }

    const handleDragStop = (event: React.MouseEvent, node: Node): void => {
        event.preventDefault()

        const { nodes: flowNodes } = currentFlow
        const currentFlowNode = flowNodes.find((fn) => fn.slug === node.id)
        if (!currentFlowNode) return

        const position = {
            x: Math.floor(node.position.x),
            y: Math.floor(node.position.y),
        }

        // If old node position is different from new position, update the node
        if (currentFlowNode.metadata.position.x !== position.x || currentFlowNode.metadata.position.y !== position.y) {
            dispatch({
                type: "UPDATE_NODE",
                payload: {
                    ...currentFlowNode,
                    metadata: { ...currentFlowNode.metadata, position },
                },
            })
            dispatch({
                type: "SET_FLOW_PUBLISHABLE",
                payload: true,
            })
        }
    }

    const selectCallback = (nodeType: NodeType) => {
        setCurrentNodeType(nodeType)
    }

    const onConnect = (params: Connection) => {
        const nodes = [...currentFlow.nodes]
        const sourceNodeIndex = nodes.findIndex((node) => node.slug === params.source)
        if (sourceNodeIndex === -1) return

        const isDefaultBranchHandle =
            isBranchNode(nodes[sourceNodeIndex]) && params.sourceHandle === `${nodes[sourceNodeIndex].slug}-default`

        if (
            isEventTriggerNode(nodes[sourceNodeIndex]) ||
            isSetPartnershipFieldNode(nodes[sourceNodeIndex]) ||
            isSendEmailNode(nodes[sourceNodeIndex]) ||
            isAddToBudgetNode(nodes[sourceNodeIndex]) ||
            isSetInvoiceLifecycleStatusNode(nodes[sourceNodeIndex]) ||
            isAssignTagNode(nodes[sourceNodeIndex]) ||
            isUpdateTripletexLedgerNode(nodes[sourceNodeIndex]) ||
            isApprovePurchaseOrderNode(nodes[sourceNodeIndex]) ||
            isApprovePurchaseOrderLineNode(nodes[sourceNodeIndex]) ||
            isConvertPrToPoNode(nodes[sourceNodeIndex]) ||
            isApprovePurchaseRequestNode(nodes[sourceNodeIndex]) ||
            isApprovePurchaseRequestLineNode(nodes[sourceNodeIndex]) ||
            isSetPaymentMethodDetailsFieldNode(nodes[sourceNodeIndex]) ||
            isCreateSurveyNode(nodes[sourceNodeIndex]) ||
            isRetractReviewsNode(nodes[sourceNodeIndex]) ||
            isFetchCustomFieldsNode(nodes[sourceNodeIndex])
        ) {
            nodes[sourceNodeIndex] = { ...nodes[sourceNodeIndex], nextNode: params.target }
        } else if (isBranchNode(nodes[sourceNodeIndex])) {
            if (isDefaultBranchHandle) {
                nodes[sourceNodeIndex] = {
                    ...nodes[sourceNodeIndex],
                    default: params.target,
                }
            } else {
                // regex to get the index from the handle index {index}
                const handleIndex = parseInt(params.sourceHandle?.match(/\{(\d+)\}/)?.[1] || "0")

                nodes[sourceNodeIndex] = {
                    ...nodes[sourceNodeIndex],
                    default: isDefaultBranchHandle ? params.target : nodes[sourceNodeIndex].default,
                    branches: nodes[sourceNodeIndex].branches.map((branch, index) => ({
                        ...branch,
                        nextNode: handleIndex === index ? params.target : branch.nextNode,
                    })),
                }
            }
        } else {
            nodes[sourceNodeIndex] = params.sourceHandle?.includes("success")
                ? { ...nodes[sourceNodeIndex], nextIfSuccess: params.target }
                : { ...nodes[sourceNodeIndex], nextIfFailure: params.target }
        }

        updateFlow({ ...currentFlow, nodes })
        setEdges((eds) => addEdge(params, eds))
    }

    const createAndAddNode = (nodeType: NodeType, position: XYPosition) => {
        const id = generateReadableId(nodeType, currentFlow.nodes)

        const eventTriggerEventType = currentFlow.nodes.find(isEventTriggerNode)?.event || null

        const title = messages.nodeTitle[nodeType]

        const name = title ? formatMessage(title) : ""

        const node = createNode({ type: nodeType, slug: id, position, eventTriggerEventType, name })

        // TODO: Handle the case when node is null
        if (!node) return

        updateFlow({ ...currentFlow, nodes: [...currentFlow.nodes, node] })

        const newNode = {
            id,
            type: nodeType,
            position,
            data: node,
        }

        setNodes((nds) => nds.concat(newNode))
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()

        if (!currentNodeType) return

        // TODO: For now node width is hardcoded, it will be dynamic if needed
        const position = screenToFlowPosition({
            x: Math.floor(event.clientX - NODE_WIDTH),
            y: Math.floor(event.clientY),
        })

        createAndAddNode(currentNodeType, position)
    }

    const handleNodeClick = (nodeType: NodeType) => {
        setCurrentNodeType(nodeType)

        const centerX = window.innerWidth / 2
        const centerY = window.innerHeight / 2

        // TODO: For now node width is hardcoded, it will be dynamic if needed
        const position = screenToFlowPosition({
            x: Math.floor(centerX - NODE_WIDTH / 2),
            y: Math.floor(centerY),
        })

        createAndAddNode(nodeType, position)
    }

    const handlSelectNode: NodeMouseHandler<Node> = (_, node) => {
        const selected = currentFlow.nodes.find((n) => n.slug === node.data.slug)

        if (!selected) return
        if (selectedNode && selected.slug === selectedNode.slug) return

        setSelectedNode(selected)
        dispatch({
            type: "SET_FLOW",
            payload: currentFlow,
        })
        setShowBottomPanel(false)
    }

    const handlUnelectNode = () => {
        setSelectedNode(undefined)
    }

    const handleDragStart =
        (nodeType: NodeType) =>
        (event: React.DragEvent<HTMLDivElement>): void => {
            selectCallback(nodeType)
            event.dataTransfer.effectAllowed = "move"
        }

    const handleClose = () => {
        setShowBottomPanel(true)
    }

    const handleOpen = () => {
        setShowBottomPanel(false)
    }

    useEffect(() => {
        const debouncedSetNodes = debounce(() => {
            setNodes(
                adaptFlowToNodes(currentFlow, {
                    currentNode: selectedNode,
                })
            )
            setEdges(adaptFlowToEdges(currentFlow))
        }, 500)

        debouncedSetNodes()

        return () => debouncedSetNodes.cancel()
    }, [currentFlow])

    useEffect(() => {
        if (!selectedNode) return

        const currentNode = currentFlow.nodes.find((node) => node.slug === selectedNode?.slug)

        if (!currentNode) handlUnelectNode()
    }, [currentFlow, selectedNode])

    useFlowValidator(currentFlow)

    useEffect(() => {
        dispatch({ type: "SET_FLOW", payload: flow })
        dispatch({ type: "SET_RUN", payload: null })
    }, [])

    const hasError = error !== null && error.trim().length > 0

    return (
        <>
            <Wrapper ref={reactFlowWrapper}>
                <ReactFlow
                    key={currentFlow.id}
                    nodes={deferedNodes}
                    edges={deferedEdges}
                    nodeTypes={FLOW_NODE_TYPES}
                    edgeTypes={edgeTypes}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onDragOver={handleDragOver}
                    onNodeDragStop={handleDragStop}
                    onDrop={handleDrop}
                    onNodeClick={handlSelectNode}
                    onPaneClick={handlUnelectNode}
                    snapToGrid
                    snapGrid={[50, 50]}
                    fitView
                    proOptions={{
                        hideAttribution: true,
                    }}
                >
                    <StyledControls />
                    {hasError && (
                        <StyledError position="top-center">
                            <Stack alignItems="center" gap={1} direction="row">
                                <AlertTriangle size={18} color="var(--color-yellow)" />
                                {error}
                            </Stack>
                        </StyledError>
                    )}
                    {showBottomPanel && (
                        <StyledPanel position="bottom-center">
                            <NodeList handleDragStart={handleDragStart} handleClick={handleNodeClick} />
                        </StyledPanel>
                    )}
                    {hasWorkflowUpdatePermission && (
                        <Panel position="top-right">
                            {showBottomPanel ? (
                                <MenuButton type="primary" onClick={handleOpen}>
                                    <Menu size={18} />
                                </MenuButton>
                            ) : (
                                <MemoizedSidebar
                                    key={selectedNode?.slug}
                                    handleDragStart={handleDragStart}
                                    selectedNode={selectedNode}
                                    handleNodeClick={handleNodeClick}
                                    handleClose={handleClose}
                                />
                            )}
                        </Panel>
                    )}
                    <Background color="var(--primary-color)" variant={BackgroundVariant.Dots} />
                </ReactFlow>
            </Wrapper>
        </>
    )
}
