import React from "react"
import { defineMessages, useIntl } from "react-intl"

import { AutocompleteWithTags } from "~/components/AutocompleteWithTags/AutocompleteWithTags"
import { getFlattenedTags } from "~/domains/tags/core/tagGroupsAndTags"
import { selectSelectedTagsForFilter, tagsActions } from "~/domains/tags/store/tagsSlice"
import { SelectedTagI, TagGroupI } from "~/domains/tags/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    filterByTags: { id: "common.filterByTags", defaultMessage: "Filter by tags" },
})

export type TagFilterProps = {
    tagGroups: TagGroupI[]
}

export const TagFilter: React.FC<TagFilterProps> = ({ tagGroups }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()

    const selectedTags = useAppSelector(selectSelectedTagsForFilter)

    const options = getFlattenedTags(tagGroups)

    const handleSelectedTags = (selectedTags: SelectedTagI[]) => {
        dispatch(tagsActions.setSelectedTagsForFilter(selectedTags))
    }

    const groupBy = (option: SelectedTagI) => option.tagGroupName || ""

    return (
        <AutocompleteWithTags
            classname="modal-share-object-permisisons-search"
            width="275px"
            options={options}
            selectedEntities={selectedTags}
            setSelectedEntities={handleSelectedTags}
            numberOfTagsToShow={1}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.tagId === value.tagId}
            placeholder={formatMessage(messages.filterByTags)}
            groupBy={groupBy}
            isChips={true}
            maxTagLength={14}
        />
    )
}
