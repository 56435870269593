import { DollarSign } from "react-feather"

import { AddToBudgetConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { AddToBudgetNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEventTypeToTransactionType } from "~/domains/orchestration/flows/core"
import { AddToBudgetNode as AddToBudgetNodeType, Event, Node, NodeType } from "~/domains/orchestration/flows/types/"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"

import { createCommonNodeProperties } from "./commonConfig"

const addToBudgetNode = (eventTriggerEventType: Event | null): Omit<AddToBudgetNodeType, keyof Node> => ({
    amount: `{{ trigger_event.totalAmountDue.EUR }}`,
    budgetId: ` `,
    transactionId: `{{ trigger_event.id }}`,
    transactionType: eventTriggerEventType ? adaptEventTypeToTransactionType(eventTriggerEventType) : null,
    failIfOverbudget: false,
    currency: `'EUR'`,
    nextNode: null,
    type: NodeType.ADD_TO_BUDGET_NODE,
})

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.AMOUNT,
    AllAdvancedFieldsUnion.TRANSACTION_ID,
    AllAdvancedFieldsUnion.TRANSACTION_TYPE,
    AllAdvancedFieldsUnion.CURRENCY,
]

const addToBudgetConfig: Configuration<NodeType.ADD_TO_BUDGET_NODE> = {
    type: NodeType.ADD_TO_BUDGET_NODE,
    baseSlug: "add_to_budget",
    Icon: DollarSign,
    Configuration: AddToBudgetConfiguration,
    Node: AddToBudgetNode,
    advancedFields,
    factory: (props: FactoryProps): AddToBudgetNodeType => ({
        ...addToBudgetNode(props.eventTriggerEventType),
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { addToBudgetConfig, advancedFields as addToBudgetAdvancedFields }
