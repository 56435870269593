import { Grid, Stack, TextField } from "@mui/material"
import React, { useCallback, useEffect, useMemo } from "react"
import { Info } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, Loader, SkeletonInput } from "~/components"
import { usePartnersAsOrganizations } from "~/domains/partners/store/hooks"
import { PartnerProfileContactI, PartnershipType } from "~/domains/partners/types"
import { InvoiceContact } from "~/domains/transactions/invoices/components/InvoiceContact"
import { CompanyResult } from "~/features/organization/components/CompanyAutocomplete"
import { CompanyAutocompleteType } from "~/features/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import {
    CompanyRegistrationFields,
    organizationBlockType,
} from "~/features/organization/components/CompanyRegistrationFields"
import { selectUser } from "~/store/account/accountSlice"
import { authActions, selectIsConnected } from "~/store/auth/authSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectSuggestedSupplierCompagnies } from "~/store/invoice/invoiceSlice"
import { useCurrentOrganization, useFetchOrganization, useOrganizations } from "~/store/organization/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import {
    CompanyI,
    CountryCode,
    ImportInvoiceCompanyInfoI,
    InvoiceUserType,
    OrganizationItemI,
    WhitePagesIdTypes,
} from "~/types/"

const messages = defineMessages({
    title: {
        id: "supplier.supplierInfo.title",
        defaultMessage: "Supplier",
    },
    titleWithOrganization: {
        id: "supplier.supplierInfo.titleWithOrganization",
        defaultMessage: "Supplier: {organizationName}",
    },
    titleAsSupplier: {
        id: "supplier.supplierInfo.titleMe",
        defaultMessage: "Me",
    },
    login: {
        id: "supplier.supplierInfo.login",
        defaultMessage: "Either {login} or just fill in the infos (sign up later)",
    },
    loginWord: {
        id: "supplier.supplierInfo.loginWord",
        defaultMessage: "login",
    },
    contactName: {
        id: "supplier.supplierInfo.contactName",
        defaultMessage: "Your Name",
    },
    email: {
        id: "supplier.supplierInfo.email",
        defaultMessage: "Email",
    },
})

interface Props {
    initiator: InvoiceUserType
    supplier: ImportInvoiceCompanyInfoI
    ocrSupplierData?: ImportInvoiceCompanyInfoI
    updateData: (data: Partial<ImportInvoiceCompanyInfoI>) => void
    dataLoaded: boolean
    readOnly?: boolean
    invoiceV1?: boolean
}

export const useCompanyResultFromCompanyDetails = (
    companyDetails: ImportInvoiceCompanyInfoI,
    suggestedCompanies: CompanyI[] | undefined
): CompanyResult | undefined => {
    const { organization } = useFetchOrganization(
        companyDetails.organizationId ? companyDetails.organizationId : undefined
    )
    return useMemo<CompanyResult | undefined>(() => {
        if (organization) {
            return {
                type: CompanyAutocompleteType.Organization,
                value: {
                    ...organization,
                    name: companyDetails.name,
                    registration: {
                        ...organization.registration,
                        vatNumber: companyDetails.taxId ?? organization.registration.vatNumber ?? "",
                        preferredRegistrationNumber: {
                            registrationType:
                                organization.registration.preferredRegistrationNumber?.registrationType ?? "UNKNOWN",
                            registrationNumber:
                                companyDetails.registrationNumber ??
                                organization.registration.preferredRegistrationNumber?.registrationType ??
                                "",
                        },
                    },
                },
            }
        } else if (suggestedCompanies && suggestedCompanies.length === 1) {
            return {
                type: CompanyAutocompleteType.WhitePagesResult,
                value: suggestedCompanies[0],
            }
        } else if (companyDetails.registrationNumber && companyDetails.countryCode !== CountryCode.UNKNOWN) {
            return {
                type: CompanyAutocompleteType.WhitePagesResult,
                value: {
                    id:
                        companyDetails.countryCode === CountryCode.FR
                            ? companyDetails.registrationNumber
                            : companyDetails.dunsNumber,
                    idType:
                        companyDetails.countryCode === CountryCode.FR
                            ? WhitePagesIdTypes.FRENCH_SIRET
                            : WhitePagesIdTypes.DUNS,
                    name: companyDetails.name,
                    city: "",
                    countryCode: companyDetails.countryCode,
                    isOutOfBusiness: false,
                    registrationNumbers: [
                        companyDetails.registrationNumber
                            ? {
                                  registrationType: "UNKNOWN",
                                  registrationNumber: companyDetails.registrationNumber,
                                  isPreferred: true,
                              }
                            : {
                                  registrationType: "DUNS",
                                  registrationNumber: companyDetails.dunsNumber,
                                  isPreferred: true,
                              },
                    ],
                    taxId: companyDetails.taxId,
                },
            } as CompanyResult
        }
        return undefined
    }, [organization, companyDetails, suggestedCompanies])
}

const NO_ORGANIZATIONS: OrganizationItemI[] = []

export const InvoiceSupplierInfo: React.FC<Props> = ({
    initiator,
    supplier,
    updateData,
    dataLoaded,
    ocrSupplierData,
    readOnly,
}) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()

    const user = useAppSelector(selectUser)
    const userIsConnected = useAppSelector(selectIsConnected)
    const supplierUser = initiator === InvoiceUserType.SUPPLIER ? user : undefined
    const { organizations: supplierOrganizations, loading: loadingOrganizations } = useOrganizations(
        supplierUser?.organizations ?? NO_ORGANIZATIONS
    )
    const suggestedCompanies = useAppSelector(selectSuggestedSupplierCompagnies)
    const currentUserOrganization = useCurrentOrganization(supplierUser?.organizations ?? NO_ORGANIZATIONS)
    const currentUserOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { organizations: currentUserOrganizationPartners } = usePartnersAsOrganizations(
        PartnershipType.SUPPLIER,
        currentUserOrganizationId || ""
    )

    const organizations =
        initiator === InvoiceUserType.SUPPLIER ? supplierOrganizations : currentUserOrganizationPartners

    useEffect(() => {
        if (!userIsConnected || !supplierUser) return

        updateData({
            email: supplierUser.email,
            contactName: supplierUser.fullName,
        })
    }, [userIsConnected])

    const updateInvoiceContact = useCallback(
        (contact: PartnerProfileContactI | null) => {
            if (!contact) {
                updateData({ contactName: "", email: "" })
                return
            }
            const contactName = `${contact.lastName || ""} ${contact.firstName || ""}`.trim()
            updateData({ contactName: contactName, email: contact.email })
        },
        [updateData]
    )

    const updateRegistrationData = useCallback(
        (data: Partial<ImportInvoiceCompanyInfoI>) => updateData(data),
        [updateData]
    )

    const handleLogin = useCallback(() => dispatch(authActions.openPopupSignIn()), [dispatch])

    return (
        <>
            {!userIsConnected && (
                <Grid display="flex" alignItems="center" gap={1}>
                    <Info />
                    <Grid container alignItems="center">
                        {formatMessage(messages.login, {
                            login: (
                                <Button type="title" onClick={handleLogin}>
                                    login
                                </Button>
                            ),
                        })}
                    </Grid>
                </Grid>
            )}

            {!dataLoaded ? (
                <SkeletonInput />
            ) : (
                <Stack>
                    {loadingOrganizations ? (
                        <div>
                            <Loader small />
                        </div>
                    ) : (
                        <div className="mb-12">
                            <CompanyRegistrationFields
                                initiator={initiator}
                                currentOrganization={currentUserOrganization}
                                buyerOrSupplier={supplier}
                                ocrCompanyDetails={ocrSupplierData}
                                suggestedCompanies={suggestedCompanies}
                                updateData={updateRegistrationData}
                                organizations={organizations}
                                organizationType={organizationBlockType.SUPPLIER}
                                isReadOnly={readOnly}
                            />
                        </div>
                    )}
                    {readOnly ? (
                        <>
                            {supplier?.contactName && (
                                <TextField
                                    id="supplier.contactName"
                                    name="supplier.contactName"
                                    label={formatMessage(messages.contactName)}
                                    placeholder={formatMessage(messages.contactName)}
                                    value={supplier?.contactName ?? ""}
                                    fullWidth
                                    inputProps={{ readOnly }}
                                    className={userIsConnected ? "hidden" : "mb-12"}
                                />
                            )}
                            {supplier?.email && (
                                <TextField
                                    id="supplier.email"
                                    name="supplier.email"
                                    type="email"
                                    label={formatMessage(messages.email)}
                                    placeholder={formatMessage(messages.email)}
                                    value={supplier?.email ?? ""}
                                    fullWidth
                                    aria-readonly={userIsConnected}
                                    inputProps={{ readOnly }}
                                    className={userIsConnected ? "hidden" : ""}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <InvoiceContact
                                currentOrganizationId={currentUserOrganizationId}
                                partnerId={supplier.organizationId}
                                updateInvoiceContact={updateInvoiceContact}
                                suggestedOcrContact={ocrSupplierData}
                            />
                        </>
                    )}
                </Stack>
            )}
            {/* <CheckOrganizationCanBeSet
                updateData={updateData}
                user={userIsConnected && supplierUser?.id ? supplierUser : undefined}
                isInitiator={initiator === InvoiceUserType.SUPPLIER}
                organizationIdentifier={getImportCompanyIdentifierOrEmptyString(supplier)}
            /> */}
        </>
    )
}
