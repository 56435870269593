import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { approvalApi, useGetObjectChecksQuery } from "~/domains/orchestration/flows-v0/core/approvalApi"
import { ApprovalObjectType, ApprovalStatus } from "~/domains/orchestration/flows-v0/types/Approval"
import { bookOfRelationsActions } from "~/domains/partners/store/bookOfRelationsSlice"
import { useFetchPartnership } from "~/domains/partners/store/hooks"
import { PartnershipType } from "~/domains/partners/types"

type UseGetPartnerApprovalStatusParams = {
    currentOrganizationId: string
    partnerId: string
}

export const useGetPartnerApprovalStatus = ({
    currentOrganizationId,
    partnerId,
}: UseGetPartnerApprovalStatusParams) => {
    const [approvalStatus, setApprovalStatus] = useState<ApprovalStatus>()
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(approvalApi.util.resetApiState())
            dispatch(bookOfRelationsActions.resetPartnershipData())
            setApprovalStatus(undefined)
        }
    }, [])

    // Fetch partnership data
    const { partnershipData } = useFetchPartnership(currentOrganizationId, partnerId)

    // Find the relevant partnership
    const partnership = useMemo(
        () => partnershipData.find((p) => p.partnershipType === PartnershipType.SUPPLIER),
        [partnershipData]
    )

    // Fetch approval checks
    const { data: approvalChecks } = useGetObjectChecksQuery(
        {
            organisationId: currentOrganizationId,
            objectId: partnership?.id ?? "",
            objectType: ApprovalObjectType.ORGANIZATION_RELATIONSHIP,
        },
        { skip: !partnership?.id }
    )

    useEffect(() => {
        // if there is at least one check that passed, return passed
        if (approvalChecks?.checks.some((check) => check.status === ApprovalStatus.PASSED)) {
            setApprovalStatus(ApprovalStatus.PASSED)
        } else if (approvalChecks?.checks.some((check) => check.status === ApprovalStatus.REFUSED)) {
            setApprovalStatus(ApprovalStatus.REFUSED)
        } else if (approvalChecks) {
            setApprovalStatus(ApprovalStatus.PENDING)
        }
    }, [approvalChecks])

    return { approvalStatus }
}
