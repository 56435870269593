import { useEffect, useMemo } from "react"
import { PartnershipI } from "~/domains/partners/types"
import { partnerApi } from "~/api"
import { useDispatch } from "react-redux"
import { useAppSelector } from "~/store/hooks"
import { bookOfRelationsActions, selectPartnershipData } from "../bookOfRelationsSlice"

type FetchPartnershipResult = {
    partnershipData: PartnershipI[]
    loading: boolean
    error: string | null
}

export const useFetchPartnership = (
    organizationId: string | undefined,
    partnerId: string | undefined
): FetchPartnershipResult => {
    const dispatch = useDispatch()
    const { partnershipData, loading, error } = useAppSelector(selectPartnershipData)

    useEffect(() => {
        if (organizationId && partnerId) {
            dispatch(bookOfRelationsActions.fetchPartnershipData())
            partnerApi
                .fetchPartnership(organizationId, partnerId)
                .then((data) => {
                    dispatch(bookOfRelationsActions.fetchPartnershipDataSuccess(data))
                })
                .catch((error) => {
                    dispatch(bookOfRelationsActions.fetchPartnershipDataFailed(error))
                })
        }
    }, [organizationId, partnerId, dispatch])

    return useMemo(() => {
        return { partnershipData, loading, error }
    }, [organizationId, partnershipData, loading, error])
}
