import { MessageDescriptor, defineMessages } from "react-intl"

import {
    WorkflowFilterListOperation,
    WorkflowFilterNumberOperation,
    WorkflowFilterOperation,
    WorkflowFilterReferenceOperation,
    WorkflowFilterStringOperation,
    WorkflowFilterTagsOperation,
    WorkflowFilterUnknownOperation,
} from "~/domains/orchestration/flows-v0/types"

const stringOperationsLabels: Record<WorkflowFilterStringOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterStringOperation.EQUAL]: {
        id: "workflow.operations.simpleLabel.EQUAL",
        defaultMessage: "is equal to",
    },
    [WorkflowFilterStringOperation.NOT_EQUAL]: {
        id: "workflow.operations.simpleLabel.NOT_EQUAL",
        defaultMessage: "is not equal to",
    },
})

const listOperationsLabels: Record<WorkflowFilterListOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterListOperation.CONTAINS]: {
        id: "workflow.operations.simpleLabel.CONTAINS",
        defaultMessage: "contains",
    },
    [WorkflowFilterListOperation.DOES_NOT_CONTAIN]: {
        id: "workflow.operations.simpleLabel.DOES_NOT_CONTAIN",
        defaultMessage: "does not contain",
    },
})

const referenceOperationsLabels: Record<WorkflowFilterReferenceOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterReferenceOperation.IS]: {
        id: "workflow.operations.simpleLabel.IS",
        defaultMessage: "is",
    },
    [WorkflowFilterReferenceOperation.IS_NOT]: {
        id: "workflow.operations.simpleLabel.IS_NOT",
        defaultMessage: "is not",
    },
})

const tagsOperationsLabels: Record<WorkflowFilterTagsOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterTagsOperation.CONTAINS_NONE_OF]: {
        id: "workflow.operations.simpleLabel.CONTAINS_NONE_OF",
        defaultMessage: "contains none of",
    },
    [WorkflowFilterTagsOperation.CONTAINS_ANY_OF]: {
        id: "workflow.operations.simpleLabel.CONTAINS_ANY_OF",
        defaultMessage: "contains any of",
    },
    [WorkflowFilterTagsOperation.CONTAINS_ALL_OF]: {
        id: "workflow.operations.simpleLabel.CONTAINS_ALL_OF",
        defaultMessage: "contains all of",
    },
})

const numberOperationsLabels: Record<WorkflowFilterNumberOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterNumberOperation.EQUAL]: {
        id: "workflow.operations.simpleLabel.EQUAL",
        defaultMessage: "is equal to",
    },
    [WorkflowFilterNumberOperation.GREATER_THAN_OR_EQUAL_TO]: {
        id: "workflow.operations.simpleLabel.GREATER_OR_EQUAL_THAN",
        defaultMessage: "is greater than or equal to",
    },
    [WorkflowFilterNumberOperation.LESS_THAN_OR_EQUAL_TO]: {
        id: "workflow.operations.simpleLabel.LESS_THAN_OR_EQUAL_TO",
        defaultMessage: "is lower than or equal to",
    },
    [WorkflowFilterNumberOperation.LESS_THAN]: {
        id: "workflow.operations.simpleLabel.LESS_THAN",
        defaultMessage: "is lower than",
    },
    [WorkflowFilterNumberOperation.GREATER_THAN]: {
        id: "workflow.operations.simpleLabel.GREATER_THAN",
        defaultMessage: "is greater than",
    },
    [WorkflowFilterNumberOperation.DOES_NOT_EQUAL]: {
        id: "workflow.operations.simpleLabel.IS_NOT",
        defaultMessage: "is not",
    },
})

const unknownOperationsLabels: Record<WorkflowFilterUnknownOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterUnknownOperation.UNKNOWN]: {
        id: "workflow.operations.simpleLabel.UNKNOWN",
        defaultMessage: "is",
    },
})

const operationsLabels: Record<WorkflowFilterOperation, MessageDescriptor> = {
    ...stringOperationsLabels,
    ...listOperationsLabels,
    ...referenceOperationsLabels,
    ...tagsOperationsLabels,
    ...numberOperationsLabels,
    ...unknownOperationsLabels,
}
export const getOperationSimpleLabel = (operation: WorkflowFilterOperation): MessageDescriptor =>
    operationsLabels[operation]
