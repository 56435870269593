import { CheckSquare } from "react-feather"

import { InvoiceToPurchaseOrderMatchConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { InvoiceToPurchaseOrderMatchNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    InvoiceToPurchaseOrderMatchNode as InvoiceToPurchaseOrderMatchNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const invoiceToPurchaseOrderMatchNode: Omit<InvoiceToPurchaseOrderMatchNodeType, keyof Node> = {
    invoiceId: `{{ trigger_event.id }}`,
    nextIfSuccess: null,
    nextIfFailure: null,
    type: NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE,
}

const advancedFields: (keyof typeof invoiceToPurchaseOrderMatchNode)[] = [AllAdvancedFieldsUnion.INVOICE_ID]

const invoiceToPurchaseOrderMatchConfig: Configuration<NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE> = {
    type: NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE,
    baseSlug: "invoice-to-purchase-order-match",
    Icon: CheckSquare,
    Configuration: InvoiceToPurchaseOrderMatchConfiguration,
    advancedFields,
    Node: InvoiceToPurchaseOrderMatchNode,
    factory: (props: FactoryProps): InvoiceToPurchaseOrderMatchNodeType => ({
        ...invoiceToPurchaseOrderMatchNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { invoiceToPurchaseOrderMatchConfig, advancedFields as invoiceToPurchaseOrderMatchAdvancedFields }
