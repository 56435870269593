import React from "react"

import {
    CompactOrExpandedProps,
    OrganizationPendingJobsQueue,
} from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/OrganizationPendingJobsQueue"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

export const OcrPendingJobsQueue: React.FC<CompactOrExpandedProps> = (props) => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)

    if (!organizationId) return null
    return <OrganizationPendingJobsQueue organizationId={organizationId} {...props} />
}
