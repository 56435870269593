import React from "react"
import { Route } from "react-router-dom"

import { Dashboard } from "./pages/Dashboard"
import { HOME_DASHBOARD } from "./routes"

export default {
    routes: [
        <Route key="dashboard_route">
            <Route path={HOME_DASHBOARD} element={<Dashboard />} />
        </Route>,
    ],
}
