import { XYPosition } from "@xyflow/react"

import { Node } from "~/domains/orchestration/flows/types/"

export const createCommonNodeProperties = (slug: string, position: XYPosition, name: string): Node => ({
    name,
    slug,
    metadata: {
        position,
    },
})
