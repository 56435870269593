import { lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/components"

import { CATALOG_PRODUCT_CREATE_V1_ROUTE, CATALOG_PRODUCT_EDIT_V1_ROUTE, CATALOG_V1_ROUTE } from "./routes"

const CatalogV1 = lazy(() =>
    import("~/domains/catalog/pages/CatalogV1").then(({ CatalogV1 }) => ({
        default: CatalogV1,
    }))
)
const CatalogProductCreateV1 = lazy(() =>
    import("~/domains/catalog/pages/CatalogProductCreateV1").then(({ CatalogProductCreateV1 }) => ({
        default: CatalogProductCreateV1,
    }))
)
const CatalogProductEditV1 = lazy(() =>
    import("~/domains/catalog/pages/CatalogProductEditV1").then(({ CatalogProductEditV1 }) => ({
        default: CatalogProductEditV1,
    }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="profile_route">
            <Route path={CATALOG_V1_ROUTE} element={<CatalogV1 />} />
            <Route path={CATALOG_PRODUCT_CREATE_V1_ROUTE} element={<CatalogProductCreateV1 />} />
            <Route path={CATALOG_PRODUCT_EDIT_V1_ROUTE} element={<CatalogProductEditV1 />} />
        </Route>,
    ],
}
