import { Box, LinearProgress, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material"
import React, { useEffect, useMemo } from "react"

import { PendingJobProgressView } from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/PendingJobProgressView"
import {
    EXPECTED_DURATION,
    getJobProgress,
} from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/getJobProgress"
import { DocumentJobI } from "~/domains/transactions/invoices/_views/ocr/types"
import { useFakeProgress } from "~/utils/hooks"

interface Props {
    jobsQueue: DocumentJobI[]
    component?: React.ElementType
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
})
const QueueContainer = styled(Box)({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    padding: "var(--spacing-xs) 0",
    fontSize: "18px",
    width: "100%",
    backgroundColor: "transparent",
})

const sum = (acc: number, v: number): number => acc + v

const computeProgress = (jobsQueue: DocumentJobI[]) => {
    const progressPendingJobs = jobsQueue.length > 0 ? jobsQueue.map(getJobProgress).reduce(sum) / jobsQueue.length : 1
    return progressPendingJobs
}

const getJobExpectedDuration = (job: DocumentJobI | null) => {
    if (!job) return EXPECTED_DURATION
    const createdAtTime = new Date(job.createdAt).getTime()
    const expectedCompletedAt = new Date(job.expectedCompletedAt).getTime()
    return expectedCompletedAt - createdAtTime
}

const getLatestExpectedTimeJob = (result: DocumentJobI | null, job: DocumentJobI) => {
    if (!result) return job
    if (job.expectedCompletedAt > result.expectedCompletedAt) return job
    return result
}

export const JobQueueProgress: React.FC<Props> = ({ jobsQueue, component }) => {
    // We only want to compute the initial progress once so we don't put jobsQueue and pendingJobs in useMemo dependencies array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialProgress = useMemo(() => computeProgress(jobsQueue), [jobsQueue.length])
    const expectedDuration = useMemo(
        () => getJobExpectedDuration(jobsQueue.reduce(getLatestExpectedTimeJob, null)),
        [jobsQueue.length]
    )
    const { progress, setProgress } = useFakeProgress({
        initialProgress,
        autoStart: true,
        timeConstant: expectedDuration,
    })

    useEffect(() => {
        const newProgress = computeProgress(jobsQueue)
        setProgress(newProgress)
    }, [jobsQueue, setProgress])

    return (
        <StyledTooltip title={<PendingJobProgressView jobsQueue={jobsQueue} noDisclaimer />}>
            <QueueContainer component={component}>
                <LinearProgress variant="determinate" value={100 * progress} sx={{ width: "100%" }} />
            </QueueContainer>
        </StyledTooltip>
    )
}
