import {
    InvoicePennylaneSynchronizationStatusI,
    InvoicePennylaneSynchronizationStatusIO,
} from "~/features/ocr/types/pennylaneSynchronizationStatus"
import { Result, ResultError, ResultSuccess, isResultSuccess } from "~/types/Result"
import { ParsingErrorType, genericParser } from "~/utils"

export const parseInvoicePennylaneSync = (
    data: unknown
): Result<InvoicePennylaneSynchronizationStatusI, ParsingErrorType> => {
    const result = genericParser(data, InvoicePennylaneSynchronizationStatusIO)
    if (isResultSuccess(result)) {
        return ResultSuccess(result.result)
    }
    return ResultError(result.error)
}
