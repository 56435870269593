import { useHasPermissions } from "~/domains/identity/features/roles-permissions/store/hooks"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { AuthorizationName, SpiceDBPermissionsResult } from "~/types"

export const usePartnersPermissions = (organizationId: string): Partial<SpiceDBPermissionsResult> => {
    const { hasPermission: hasReadPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    const { hasPermission: hasCreatePermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    const { hasPermission: hasUpdatePermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.UPDATE,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    const { hasPermission: hasDeletePermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.DELETE,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    return {
        create: hasCreatePermission,
        read: hasReadPermission,
        update: hasUpdatePermission,
        delete: hasDeletePermission,
    }
}
