import { styled } from "@mui/material"
import React from "react"
import { MessageDescriptor, defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { ErrorIcon, SuccessIcon } from "~/components/Icons"

import { CompanyData } from "./types"

type Field = keyof Pick<CompanyData, "name" | "registrationNumber"> | "collaborationStatus"

interface Props {
    isMatching: boolean
    field: Field
}

const matchingMessages: Record<Field, MessageDescriptor> = defineMessages({
    name: {
        id: "transactions.invoices.ocr.OrganizationPicker.nameMatching",
        defaultMessage: "Corporate name match",
    },
    registrationNumber: {
        id: "transactions.invoices.ocr.OrganizationPicker.registrationNumberMatching",
        defaultMessage: "ID Number match",
    },
    collaborationStatus: {
        id: "transactions.invoices.ocr.OrganizationPicker.collaborationStatusMatching",
        defaultMessage: "Collaboration status match",
    },
})

interface ContainerProps {
    isMatching: boolean
}

const Container = styled("span")<ContainerProps>(({ theme, isMatching }) => ({
    display: "inline-flex",
    alignItems: "center",
    gap: "2px",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: isMatching ? theme.palette.success.main : theme.palette.error.main,
    svg: {
        flex: "0 0 16px",
    },
}))

export const MatchingValue: React.FC<Props> = ({ isMatching, field }) => {
    return (
        <Container isMatching={isMatching}>
            <SafeFormattedMessage {...matchingMessages[field]} />
            {isMatching ? <SuccessIcon size={16} /> : <ErrorIcon size={16} />}
        </Container>
    )
}
