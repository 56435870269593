import { styled } from "@mui/system"
import * as Sentry from "@sentry/browser"
import { ReactFlowProvider } from "@xyflow/react"
import React, { FC, useEffect, useRef } from "react"
import { useIntl } from "react-intl"
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import { useLazyGetFlowQuery } from "~/domains/orchestration/flows/api/flowsApi"
import { ActionBar } from "~/domains/orchestration/flows/components/ActionBar"
import { RunExplorerPanel } from "~/domains/orchestration/flows/components/runExplorer/RunExplorerPanel"
import { useEditor } from "~/domains/orchestration/flows/context/editorContext"
import { useFlowLatestVersion, useOrganizationId } from "~/domains/orchestration/flows/hooks/"
import { messages } from "~/domains/orchestration/flows/messages"
import { FLOWS_LIST } from "~/domains/orchestration/flows/routes"
import { FlowId, View } from "~/domains/orchestration/flows/types"
import { useTitle } from "~/hooks"

const StyledLoader = styled("div")({
    height: "calc(100vh - 24px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
})

export const RunExplorer: FC = () => {
    const { formatMessage } = useIntl()
    const location = useLocation()
    const navigate = useNavigate()
    const { flowId = "" as FlowId } = useParams<{ flowId: FlowId }>()
    const { run } = useEditor()

    const organizationId = useOrganizationId()

    const organizationIdRef = useRef(organizationId)

    const { state } = location
    const { view } = state || {
        view: View.RUN_EXPLORER,
    }

    const pageName = formatMessage(messages.editor.title)
    useTitle(pageName)

    const flowVersion = useFlowLatestVersion(flowId)

    const [triggerGetFlow, { data: flow, isLoading, isError, error }] = useLazyGetFlowQuery()

    useEffect(() => {
        const version = run?.flowVersion ?? flowVersion
        if (version) {
            triggerGetFlow({ flowId, params: { version } })
        }
    }, [flowVersion, run?.flowVersion, triggerGetFlow, flowId])

    useEffect(() => {
        if (!organizationIdRef.current) {
            organizationIdRef.current = organizationId
        }

        if (organizationIdRef.current !== organizationId) {
            navigate(generatePath(FLOWS_LIST))
        }
    }, [organizationId])

    if (isLoading) {
        return (
            <StyledLoader>
                <Loader />
            </StyledLoader>
        )
    }

    if (isError) {
        toast.error(formatMessage(messages.error.loadingFlow))
        Sentry.captureException(error)
        return null
    }

    if (!flow) {
        return null
    }

    return (
        <div className="flows-explorer">
            <ActionBar view={view} key={flow.version} flow={flow} allowDelete={false} allowEdit={false} />
            <ReactFlowProvider>
                <RunExplorerPanel flow={flow} />
            </ReactFlowProvider>
        </div>
    )
}
