import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId } from "~/api/prepareHeaders"
import {
    AddRolesToTeamMutation,
    AddRolesToUserMutation,
    ApiRoleTemplate,
    ApiRoleWithEntityIds,
    CreateRoleMutation,
    DeleteResult,
    DeleteRoleMutation,
    RemoveRoleFromTeamMutation,
    RemoveRoleFromUserMutation,
    Role,
    RoleTemplate,
    RoleWithEntityIds,
    TeamRole,
    UpdateRoleMutation,
    UserRole,
} from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { OrganizationId } from "~/types"

import { adaptRoleTemplatesFromApi, adaptRolesFromApi } from "./adapters/rolesAdapters"

const BASE_URL = import.meta.env.VITE_API_ROLES_PERMISSIONS_URL
const tags = ["Roles"]

export const rolesApi = createApi({
    reducerPath: "rolesApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorizationAndOrganizationId }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getRoleTemplates: builder.query<RoleTemplate[], void>({
            query: () => ({
                url: `role-templates`,
            }),
            transformResponse: (response: ApiRoleTemplate[]) => adaptRoleTemplatesFromApi(response),
        }),
        getRolesWithEntityIds: builder.query<RoleWithEntityIds[], OrganizationId>({
            query: (organizationId: OrganizationId) => ({
                url: `organizations/${organizationId}/roles`,
                params: { withUserIds: true, withTeamIds: true },
            }),
            providesTags: tags,
            transformResponse: (response: ApiRoleWithEntityIds[]) => adaptRolesFromApi(response),
        }),
        createRole: builder.mutation<Role, CreateRoleMutation>({
            query: ({ organizationId, role }) => ({
                url: `organizations/${organizationId}/roles`,
                method: "POST",
                body: role,
            }),
            invalidatesTags: tags,
        }),
        updateRole: builder.mutation<Role, UpdateRoleMutation>({
            query: ({ organizationId, roleId, role }) => ({
                url: `organizations/${organizationId}/roles/${roleId}`,
                method: "PATCH",
                body: role,
            }),
            invalidatesTags: tags,
        }),
        deleteRole: builder.mutation<DeleteResult, DeleteRoleMutation>({
            query: ({ organizationId, roleId }) => ({
                url: `organizations/${organizationId}/roles/${roleId}`,
                method: "DELETE",
            }),
            invalidatesTags: tags,
        }),
        addRolesToUser: builder.mutation<UserRole[], AddRolesToUserMutation>({
            query: ({ organizationId, userId, roleIds, roleTemplateIds }) => ({
                url: `organizations/${organizationId}/users/${userId}/roles`,
                method: "POST",
                body: { roleIds, roleTemplateIds },
            }),
            invalidatesTags: tags,
        }),
        addRolesToTeam: builder.mutation<TeamRole[], AddRolesToTeamMutation>({
            query: ({ organizationId, teamId, roleIds, roleTemplateIds }) => ({
                url: `organizations/${organizationId}/teams/${teamId}/roles`,
                method: "POST",
                body: { roleIds, roleTemplateIds },
            }),
            invalidatesTags: tags,
        }),
        removeRoleFromUser: builder.mutation<void, RemoveRoleFromUserMutation>({
            query: ({ organizationId, userId, roleId }) => ({
                url: `organizations/${organizationId}/users/${userId}/roles/${roleId}`,
                method: "DELETE",
            }),
            invalidatesTags: tags,
        }),
        removeRoleFromTeam: builder.mutation<void, RemoveRoleFromTeamMutation>({
            query: ({ organizationId, teamId, roleId }) => ({
                url: `organizations/${organizationId}/teams/${teamId}/roles/${roleId}`,
                method: "DELETE",
            }),
            invalidatesTags: tags,
        }),
    }),
})

export const {
    useGetRoleTemplatesQuery,
    useGetRolesWithEntityIdsQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
    useAddRolesToUserMutation,
    useAddRolesToTeamMutation,
    useRemoveRoleFromUserMutation,
    useRemoveRoleFromTeamMutation,
} = rolesApi
