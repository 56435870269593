import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { FakeProgress, FakeProgressOptions } from "~/utils/fakeProgress"

export const useFakeProgress = (options: FakeProgressOptions) => {
    const fakeProgressOptions = useRef(options)
    const fakeProgress = useMemo(() => new FakeProgress(fakeProgressOptions.current), [])
    const [progress, _setProgress] = useState(fakeProgress.progress)

    useEffect(() => {
        fakeProgress.addListener(_setProgress)
        return () => {
            fakeProgress.removeListener(_setProgress)
        }
    }, [fakeProgress])

    const start = useCallback(() => {
        fakeProgress.start()
    }, [fakeProgress])

    const stop = useCallback(() => {
        fakeProgress.stop()
    }, [fakeProgress])

    const setProgress = useCallback(
        (newProgress: number) => {
            fakeProgress.stop()
            fakeProgress.setProgress(newProgress)
            fakeProgress.start()
        },
        [fakeProgress]
    )

    const setTimeConstant = useCallback(
        (timeConstant: number) => {
            fakeProgress.stop()
            fakeProgress.timeConstant = timeConstant
            fakeProgress.start()
        },
        [fakeProgress]
    )

    return { start, stop, progress, setProgress, setTimeConstant }
}
