import { HandleProps, Position } from "@xyflow/react"
import type { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { DollarSign } from "react-feather"

import { AddToBudgetNode as AddToBudgetNodeType } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

export const AddToBudgetNode: FC<NodeProps<NodeType<AddToBudgetNodeType>>> = ({ data, selected }) => {
    const handles: HandleProps[] = [
        {
            type: "target",
            isConnectableEnd: true,
            position: Position.Top,
        },
        {
            type: "source",
            position: Position.Bottom,
            isConnectableStart: true,
        },
    ]

    const information = data.metadata?.additionalInformation
    const error = !data.budgetId || !data.transactionType

    return (
        <Node
            type={data.type}
            name={data.name}
            information={information}
            icon={DollarSign}
            handles={handles}
            selected={selected}
            slug={data.slug}
            error={error}
        ></Node>
    )
}
