import React, { lazy } from "react"
import { Route } from "react-router-dom"

import { COMMUNICATION_PAGE } from "./routes"

const CommunicationPage = lazy(() =>
    import("~/domains/communication/pages/CommunicationPage").then(({ CommunicationPage }) => ({
        default: CommunicationPage,
    }))
)

export const router = {
    routes: [
        <Route key="communication_route">
            <Route path={COMMUNICATION_PAGE} element={<CommunicationPage />} />
        </Route>,
    ],
}
