import { AxiosInstance } from "axios"
import { useContext } from "react"

import { FetchErrorType } from "~/domains/orchestration/flows-v0/core"
import { OrganizationId } from "~/types"
import { Result, ResultError, ResultSuccess } from "~/types/Result"
import { ApiContext } from "~/utils/apiClient"
import { FetchError } from "~/utils/apiClient/errors"
import { parseResponse } from "~/utils/apiClient/parseResponse"
import { parseResponseAsArray } from "~/utils/apiClient/parseResponseAsArray"

import { CreateFolderPayload, FolderId, UploadDocumentDTO, parseFolder } from "./types"
import {
    DocumentDetailsI,
    DocumentI,
    PaginatedDocumentsResultI,
    parseDocument,
    parseDocumentDetails,
    parseDocumentVersion,
    parseDocumentsResults,
} from "./types/Document"

const DOCUMENT_BASE_URL = import.meta.env.VITE_API_DOCUMENT_URL

class DocumentsApi {
    private static instance: DocumentsApi
    private constructor(private axiosClient: AxiosInstance) {}

    static getInstance(axiosClient: AxiosInstance) {
        if (!DocumentsApi.instance) {
            DocumentsApi.instance = new DocumentsApi(axiosClient)
        }
        return DocumentsApi.instance
    }

    private buildUrl(path: string) {
        return `${DOCUMENT_BASE_URL}${path}`
    }

    private buildUrlForOrganization(organizationId: OrganizationId, path: string) {
        return `${DOCUMENT_BASE_URL}documents/organizations/${organizationId}${path}`
    }

    public getPathForDocumentVersionPageImage(documentId: string, documentVersionId: string, pageNumber: number) {
        return `${DOCUMENT_BASE_URL}documents/${documentId}/versions/${documentVersionId}/image-pages/${pageNumber}`
    }

    async searchDocuments(
        organizationId: OrganizationId,
        query: string,
        page: number,
        perPage: number,
        parentId: FolderId | null
    ): Promise<Result<PaginatedDocumentsResultI, FetchError<PaginatedDocumentsResultI>>> {
        const url = this.buildUrl(
            `search/organizations/${organizationId}?page=${page}&page_size=${perPage}${
                query ? `&query=${encodeURIComponent(query)}` : ""
            }${parentId ? `&folder_id=${parentId}` : ""}`
        )
        const response = await this.axiosClient.get(url)
        return parseResponse(response, parseDocumentsResults)
    }

    async fetchFoldersTree(organizationId: OrganizationId) {
        const url = this.buildUrlForOrganization(organizationId, "/folders/tree")
        const response = await this.axiosClient.get(url)
        return response.data
    }

    async fetchDocuments(organizationId: OrganizationId): Promise<Result<DocumentI[], FetchError<DocumentI>>> {
        const url = this.buildUrlForOrganization(organizationId, "")
        try {
            const response = await this.axiosClient.get(url)
            return parseResponseAsArray(response, parseDocument)
        } catch {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `An unexpected error occurred`,
            })
        }
    }

    async getDocument(documentId: string): Promise<Result<DocumentDetailsI, FetchError<DocumentDetailsI>>> {
        const url = this.buildUrl(`documents/${documentId}`)
        try {
            const response = await this.axiosClient.get(url)
            return parseResponse(response, parseDocumentDetails)
        } catch {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `An unexpected error occurred`,
            })
        }
    }

    async uploadDocument(payload: UploadDocumentDTO): Promise<Result<DocumentI, FetchError<DocumentI>>> {
        const formData = new FormData()
        formData.append("file", payload.file)
        formData.append("organizationIds", JSON.stringify(payload.organizationIds))
        formData.append("name", payload.name)
        formData.append("documentType", payload.documentType)

        if (payload.objectId) {
            formData.append("objectId", payload.objectId)
        }
        if (payload.objectType) {
            formData.append("objectType", payload.objectType)
        }

        const response = await this.axiosClient.post(this.buildUrl(`documents`), formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return parseResponse(response, parseDocument)
    }

    async createFolder(payload: CreateFolderPayload) {
        const response = await this.axiosClient.post(this.buildUrl("folders"), payload)
        return parseResponse(response, parseFolder)
    }

    async updateDocumentVersionData(documentId: string, documentVersionId: string, payload: unknown) {
        const url = this.buildUrl(`documents/${documentId}/versions/${documentVersionId}`)
        const response = await this.axiosClient.patch(url, payload)
        return parseResponse(response, parseDocumentVersion)
    }

    async fetchImage(url: string) {
        const response = await this.axiosClient.get(url)
        if (response.data?.location) return ResultSuccess(response.data.location)
        return ResultError("Unable to fetch page")
    }
}

export const useDocumentsApi = () => {
    const { axiosClient } = useContext(ApiContext)
    return DocumentsApi.getInstance(axiosClient)
}
