import React from "react"
import { AlertTriangle } from "react-feather"

import { EditAmount } from "~/domains/transactions/invoices/_views/supplier/components"
import { FieldContainer, IconContainer } from "~/domains/transactions/invoices/components/InvoiceLines/StyledDiv"
import { InvoiceLineError } from "~/domains/transactions/invoices/core"
import { CurrencyCodes } from "~/types"

interface RenderPriceProps {
    price: number
    currency: CurrencyCodes
    onPriceChange: (price: number | null) => void
    priceLabel: string
    error: InvoiceLineError | null
    readonly?: boolean
    fieldClassName?: string
    hideLabel?: boolean
}

export const RenderPrice: React.FC<RenderPriceProps> = ({
    price,
    currency,
    onPriceChange,
    priceLabel,
    error,
    readonly,
    fieldClassName,
    hideLabel,
}) => (
    <FieldContainer error={error} flex="3 1 3%">
        <EditAmount
            className={`invoice-line-field invoice-line-unitPrice ${fieldClassName}`}
            value={price}
            currency={currency}
            placeholder={priceLabel}
            handleUpdate={onPriceChange}
            required
            disableHelpers
            inputProps={{
                readOnly: readonly,
            }}
            aria-readonly={readonly}
            hideLabel={hideLabel}
        />
        {error ? (
            <IconContainer>
                <AlertTriangle size={16} />
            </IconContainer>
        ) : null}
    </FieldContainer>
)
