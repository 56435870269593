import { InputAdornment, Stack, TextField, Tooltip } from "@mui/material"
import React, { ChangeEventHandler, MouseEventHandler, ReactNode, useCallback } from "react"
import { Search } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { ActionsMenu, Button, Card } from "~/components"
import { useFilterProps } from "~/components/Filters/FilterProvider"
import { TagFilter } from "~/components/Filters/TagsFilter"
import { isTagFilterProps } from "~/components/Filters/utils/filters"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"
import { GlobalFilterType, selectFilterTypes } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"

interface Props {
    onChangeFilter: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    valueFilter: string
    onClickButton?: MouseEventHandler<HTMLButtonElement> | undefined
    labelButton?: string
    iconButton?: React.ReactNode
    actionsMenu?: boolean
    actions?: DatatableFiltersActions[]
    className?: string
    hasPermission?: boolean
}

const messages = defineMessages({
    filterLabel: {
        id: "dataTable.filters.SearchFieldLabel",
        defaultMessage: "Search",
    },
})

export interface DatatableFiltersActions {
    label: ReactNode
    action: () => void
}

export const DatatableFilters: React.FC<Props> = ({
    onChangeFilter,
    valueFilter,
    onClickButton,
    labelButton,
    iconButton,
    actionsMenu,
    actions,
    className,
    hasPermission,
}) => {
    const { formatMessage } = useIntl()
    const filterTypes = useAppSelector(selectFilterTypes)
    const filterProps = useFilterProps()

    const disabled = hasPermission === false
    const actionButton = (
        <Button type="primary" onClick={onClickButton} disabled={disabled}>
            {labelButton}
            {iconButton}
        </Button>
    )

    const renderFilter = useCallback(
        (type: GlobalFilterType): React.ReactNode | null => {
            if (filterTypes.length === 0) return null

            const props = filterProps?.getFilterProps(type)
            if (!props) return null

            switch (type) {
                case GlobalFilterType.TAGS:
                    if (isTagFilterProps(props)) {
                        return <TagFilter key={type} {...props} />
                    }
            }

            return null
        },
        [filterProps, filterTypes]
    )

    return (
        <Card className={className} sx={{ width: "100%", marginBottom: 2 }}>
            <Stack alignItems="center" spacing={2} direction="row" width="100%" justifyContent="space-between">
                <TextField
                    name="datatable-filter"
                    placeholder={formatMessage(messages.filterLabel)}
                    onChange={onChangeFilter}
                    value={valueFilter}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="var(--color-light-grey)" size={14} />
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                />
                <Stack direction="row" alignItems="center" spacing={2}>
                    {filterTypes.map(renderFilter)}
                    {actionsMenu && actions ? (
                        <ActionsMenu
                            actions={actions}
                            labelButton={labelButton}
                            disabled={disabled}
                            buttonType="primary"
                            size="small"
                        />
                    ) : labelButton && onClickButton ? (
                        <>
                            {hasPermission || typeof hasPermission === "undefined" ? (
                                actionButton
                            ) : (
                                <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                                    {actionButton}
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                </Stack>
            </Stack>
        </Card>
    )
}
