import React from "react"
import { DefaultElement, RenderElementProps } from "slate-react"

import {
    ActionPlanElement,
    isElementActionPlan,
    isElementActionPlanItem,
} from "~/domains/communication/components/InputMessage/ActionPlan"
import { ActionPlanItemElement } from "~/domains/communication/components/InputMessage/ActionPlan/ActionPlanItemElement"
import { MentionElement, isElementMention } from "~/domains/communication/components/InputMessage/Mentions"
import { CustomElement } from "~/domains/communication/types/Slate"

export const renderElement = (props: RenderElementProps) => {
    const element: CustomElement = props.element
    if (isElementMention(element)) {
        return <MentionElement attributes={props.attributes} element={element} />
    }
    if (isElementActionPlan(element)) {
        return <ActionPlanElement attributes={props.attributes} element={element} children={props.children} />
    }
    if (isElementActionPlanItem(element)) {
        return <ActionPlanItemElement attributes={props.attributes} element={element} children={props.children} />
    }
    return <DefaultElement {...props} />
}
