import { XYPosition } from "@xyflow/react"

import { Opaque } from "~/utils"

export type FlowId = Opaque<string, { readonly T: unique symbol }>

export type Filter = {
    conditions: Array<Array<string>>
}

export type Branch = Filter & {
    name: string
    nextNode: string | null
}

export type FlowNode =
    | EventTriggerNode
    | IfNode
    | SetPartnershipFieldNode
    | CheckNode
    | SendEmailNode
    | AddToBudgetNode
    | InvoiceToPurchaseOrderMatchNode
    | SetInvoiceLifecycleStatusNode
    | FitsToBudgetNode
    | AssignTagNode
    | UpdateTripletexLedgerNode
    | ApprovePurchaseOrderNode
    | ApprovePurchaseOrderLineNode
    | ConvertPrToPoNode
    | ApprovePurchaseRequestNode
    | ApprovePurchaseRequestLineNode
    | BranchNode
    | SetPaymentMethodDetailsFieldNode
    | CreateSurveyNode
    | RetractReviewsNode
    | FetchCustomFieldsNode

export type Node = {
    name: string
    slug: string
    metadata: {
        position: XYPosition
        additionalInformation?: string
    }
}

export type ConditionalNode = Node & {
    nextIfSuccess: string | null
    nextIfFailure: string | null
}

export type RegularNode = Node & {
    nextNode: string | null
}

export enum UserType {
    USER = "User",
    TEAM = "Team",
}

export type UserReviewer = {
    userId: string
    type: UserType.USER
}

export type TeamReviewer = {
    teamId: string
    type: UserType.TEAM
}

export type Reviewer = UserReviewer | TeamReviewer

export type IfNode = ConditionalNode & {
    condition: string
    type: NodeType.IF_NODE
}

export type CheckNode = ConditionalNode & {
    objectId: string
    objectType: ObjectType | null
    reviewers: Reviewer[]
    passThreshold: number
    refuseThreshold: number
    type: NodeType.CHECK_NODE
}

export type EventTriggerNode = RegularNode & {
    event: Event | null
    filter: Filter
    type: NodeType.EVENT_TRIGGER_NODE
}

export type SetPartnershipFieldNode = RegularNode & {
    partnershipId: string
    fieldToUpdate: string
    valueToSet: string
    type: NodeType.SET_PARTNERSHIP_FIELD_NODE
}

export type SetPaymentMethodDetailsFieldNode = RegularNode & {
    paymentMethodDetailsId: string
    fieldToUpdate: string
    valueToSet: string
    type: NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE
}

export type CreateSurveyNode = RegularNode & {
    formId: string
    respondentOrganizationId: string | null
    type: NodeType.CREATE_SURVEY_NODE
}

export type RetractReviewsNode = RegularNode & {
    objectId: string
    objectType: ObjectType | null
    type: NodeType.RETRACT_REVIEWS_NODE
}

export type SendEmailNode = RegularNode & {
    subject: string
    recipientAddresses: string[]
    body: string
    type: NodeType.SEND_EMAIL_NODE
}

export type AddToBudgetNode = RegularNode & {
    budgetId: string
    transactionId: string
    transactionType: TransactionType | null
    amount: string
    currency: string
    failIfOverbudget: boolean
    type: NodeType.ADD_TO_BUDGET_NODE
}

export type InvoiceToPurchaseOrderMatchNode = ConditionalNode & {
    invoiceId: string
    type: NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE
}

export type SetInvoiceLifecycleStatusNode = RegularNode & {
    invoiceId: string
    statusToSet: string
    type: NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE
}

export type FitsToBudgetNode = ConditionalNode & {
    budgetId: string
    transactionId: string
    transactionType: TransactionType | null
    amount: string
    currency: string
    type: NodeType.FITS_TO_BUDGET_NODE
}

export type AssignTagNode = RegularNode & {
    objectId: string
    objectType: ObjectType | null
    tagId: string
    type: NodeType.ASSIGN_TAG_NODE
}

export type UpdateTripletexLedgerNode = RegularNode & {
    ledgerId: string
    ledgerDate: string
    ledgerDescription: string
    accountToCredit: string
    accountToDebit: string
    amount: string
    amountGross: string
    currency: string
    type: NodeType.UPDATE_TRIPLETEX_LEDGER_NODE
}

export type ApprovePurchaseOrderNode = RegularNode & {
    purchaseOrderId: string
    type: NodeType.APPROVE_PURCHASE_ORDER_NODE
}

export type ApprovePurchaseOrderLineNode = RegularNode & {
    purchaseOrderLineId: string
    purchaseOrderId: string
    type: NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE
}

export type ConvertPrToPoNode = RegularNode & {
    purchaseRequestId: string
    type: NodeType.CONVERT_PR_TO_PO_NODE
}

export type ApprovePurchaseRequestNode = RegularNode & {
    purchaseRequestId: string
    type: NodeType.APPROVE_PURCHASE_REQUEST_NODE
}

export type ApprovePurchaseRequestLineNode = RegularNode & {
    purchaseRequestId: string
    purchaseRequestLineId: string
    type: NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE
}

export type BranchNode = Node & {
    branches: Branch[]
    default: string
    type: NodeType.BRANCH_NODE
}

export type FetchCustomFieldsNode = RegularNode & {
    objectId: string
    type: NodeType.FETCH_CUSTOM_FIELDS_NODE
}

export type Flow = {
    id: FlowId
    version: number
    name: string
    enabled: boolean
    archived: boolean
    createdAt: string
    nodes: FlowNode[]
    author: string
}

export enum NodeType {
    EVENT_TRIGGER_NODE = "EventTriggerNodeV2",
    SET_PARTNERSHIP_FIELD_NODE = "SetPartnershipFieldNode",
    SET_PAYMENT_METHOD_DETAILS_FIELD_NODE = "SetPaymentMethodDetailsFieldNode",
    IF_NODE = "IfNode",
    CHECK_NODE = "CheckNode",
    SEND_EMAIL_NODE = "SendEmailNode",
    ADD_TO_BUDGET_NODE = "AddToBudgetNode",
    INVOICE_TO_PURCHASE_ORDER_MATCH_NODE = "InvoiceToPurchaseOrderMatchNode",
    SET_INVOICE_LIFECYCLE_STATUS_NODE = "SetInvoiceLifecycleStatusNode",
    FITS_TO_BUDGET_NODE = "FitsToBudgetNode",
    ASSIGN_TAG_NODE = "AssignTagNode",
    UPDATE_TRIPLETEX_LEDGER_NODE = "UpdateTripletexLedgerNode",
    APPROVE_PURCHASE_ORDER_NODE = "ApprovePurchaseOrderNode",
    APPROVE_PURCHASE_ORDER_LINE_NODE = "ApprovePurchaseOrderLineNode",
    CONVERT_PR_TO_PO_NODE = "ConvertPrToPoNode",
    APPROVE_PURCHASE_REQUEST_NODE = "ApprovePurchaseRequestNode",
    APPROVE_PURCHASE_REQUEST_LINE_NODE = "ApprovePurchaseRequestLineNode",
    BRANCH_NODE = "BranchNode",
    CREATE_SURVEY_NODE = "CreateSurveyNode",
    RETRACT_REVIEWS_NODE = "RetractReviewsNode",
    FETCH_CUSTOM_FIELDS_NODE = "FetchCustomFieldsNode",
}

export enum Event {
    PARTNERSHIP_CREATED = "PartnershipCreated",
    BUDGET_CREATED = "BudgetCreated",
    BUDGET_UPDATED = "BudgetUpdated",
    INVOICE_CREATED = "InvoiceCreated",
    INVOICE_UPDATED = "InvoiceUpdated",
    PURCHASE_REQUEST_CREATED = "PurchaseRequestCreated",
    PURCHASE_REQUEST_UPDATED = "PurchaseRequestUpdated",
    PURCHASE_REQUEST_LINE_CREATED = "PurchaseRequestLineCreated",
    INVOICE_TO_PURCHASE_ORDER_LINKED = "InvoiceToPurchaseOrderLinked",
    SUPPLIER_PURCHASE_ORDER_CREATED = "SupplierPurchaseOrderCreated",
    SUPPLIER_PURCHASE_ORDER_LINE_CREATED = "SupplierPurchaseOrderLineCreated",
    BUYER_PURCHASE_ORDER_CREATED = "BuyerPurchaseOrderCreated",
    BUYER_PURCHASE_ORDER_LINE_CREATED = "BuyerPurchaseOrderLineCreated",
    PAYMENT_METHOD_DETAILS_CREATED = "PaymentMethodDetailsCreated",
    PARTNER_PAYMENT_METHOD_DETAILS_CREATED = "PartnerPaymentMethodDetailsCreated",
}

export enum FlowTrigger {
    INVOICE = "INVOICE",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
}

export enum ObjectType {
    ORGANIZATION_RELATIONSHIP = "OrganizationRelationship",
    INVOICE = "Invoice",
    BUDGET = "Budget",
    PURCHASE_ORDER = "PurchaseOrder",
    PURCHASE_ORDER_LINE = "PurchaseOrderLine",
    PURCHASE_REQUEST = "PurchaseRequest",
    PURCHASE_REQUEST_LINE = "PurchaseRequestLine",
    PAYMENT_METHOD_DETAILS = "PaymentMethodDetails",
    PARTNER_PAYMENT_METHOD_DETAILS = "PartnerPaymentMethodDetails",
    // TODO: add these once we have the API for them
    // CONTACT = "Contact",
    // PAYMENT = "Payment",
}

export enum TransactionType {
    INVOICE = "Invoice",
    PURCHASE_ORDER = "PurchaseOrder",
    PURCHASE_REQUEST = "PurchaseRequest",
    // TODO: add these once we have the API for them
    // PAYMENT = "Payment",
    // DEPOSIT = "Deposit",
}

export type Flows = {
    flows: FlowItem[]
}

export type FlowItem = {
    id: FlowId
    version: number
    name: string
    enabled: boolean
    archived: boolean
    createdAt: string
}

export type CreateFlowBody = {
    name: string
    enabled: boolean
    nodes: FlowNode[]
}

export type CreateFlowQuery = {
    body: CreateFlowBody
}

type GetFlowParams = {
    version: number
}

export type GetFlowQuery = {
    flowId: FlowId
    params: GetFlowParams
}

export type UpdateFlowMutation = {
    flowId: FlowId
    body: Flow
}

type ValidFlow = {
    isValid: true
}

type InvalidFlow = {
    isNotValid: true
    error: string
}

export type FlowValidation = ValidFlow | InvalidFlow

// type guard to check if node is an EventTriggerNode
export const isEventTriggerNode = (node: FlowNode): node is EventTriggerNode =>
    node.type === NodeType.EVENT_TRIGGER_NODE

// type guard to check if node is an IfNode
export const isIfNode = (node: FlowNode): node is IfNode => node.type === NodeType.IF_NODE

// type guard to check if node is an SetPartnershipFieldNode
export const isSetPartnershipFieldNode = (node: FlowNode): node is SetPartnershipFieldNode =>
    node.type === NodeType.SET_PARTNERSHIP_FIELD_NODE

// type guard to check if string is Event
export const isEvent = (event: string): event is Event => Object.values(Event).includes(event as Event)

// type guard to check if string is ObjectType
export const isObjectType = (type: string): type is ObjectType => Object.values(ObjectType).includes(type as ObjectType)

// type guard to check if string is TransactionType
export const isTransactionType = (type: string): type is TransactionType =>
    Object.values(TransactionType).includes(type as TransactionType)

// type guard to check if node is CheckNode
export const isCheckNode = (node: FlowNode): node is CheckNode => node.type === NodeType.CHECK_NODE

// Add type guard for SendEmailNode
export const isSendEmailNode = (node: FlowNode): node is SendEmailNode => node.type === NodeType.SEND_EMAIL_NODE

// Add type guard for AddToBudgetNode
export const isAddToBudgetNode = (node: FlowNode): node is AddToBudgetNode => node.type === NodeType.ADD_TO_BUDGET_NODE

// type guard to check if node is an InvoiceToPurchaseOrderMatchNode
export const isInvoiceToPurchaseOrderMatchNode = (node: FlowNode): node is InvoiceToPurchaseOrderMatchNode =>
    node.type === NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE

// type guard to check if node is an SetInvoiceLifecycleStatusNode
export const isSetInvoiceLifecycleStatusNode = (node: FlowNode): node is SetInvoiceLifecycleStatusNode =>
    node.type === NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE

// type guard to check if node is an FitsToBudgetNode
export const isFitsToBudgetNode = (node: FlowNode): node is FitsToBudgetNode =>
    node.type === NodeType.FITS_TO_BUDGET_NODE

// type guard to check if node is an AssignTagNode
export const isAssignTagNode = (node: FlowNode): node is AssignTagNode => node.type === NodeType.ASSIGN_TAG_NODE

// type guard to check if node is an UpdateTripletexLedgerNode
export const isUpdateTripletexLedgerNode = (node: FlowNode): node is UpdateTripletexLedgerNode =>
    node.type === NodeType.UPDATE_TRIPLETEX_LEDGER_NODE

// type guard to check if node is an ApprovePurchaseOrderNode
export const isApprovePurchaseOrderNode = (node: FlowNode): node is ApprovePurchaseOrderNode =>
    node.type === NodeType.APPROVE_PURCHASE_ORDER_NODE

// type guard to check if node is an ApprovePurchaseOrderLineNode
export const isApprovePurchaseOrderLineNode = (node: FlowNode): node is ApprovePurchaseOrderLineNode =>
    node.type === NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE

// type guard to check if node is an ConvertPrToPoNode
export const isConvertPrToPoNode = (node: FlowNode): node is ConvertPrToPoNode =>
    node.type === NodeType.CONVERT_PR_TO_PO_NODE

// type guard to check if node is an ApprovePurchaseRequestNode
export const isApprovePurchaseRequestNode = (node: FlowNode): node is ApprovePurchaseRequestNode =>
    node.type === NodeType.APPROVE_PURCHASE_REQUEST_NODE

// type guard to check if node is an ApprovePurchaseRequestLineNode
export const isApprovePurchaseRequestLineNode = (node: FlowNode): node is ApprovePurchaseRequestLineNode =>
    node.type === NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE

// type guard to check if node is an BranchNode
export const isBranchNode = (node: FlowNode): node is BranchNode => node.type === NodeType.BRANCH_NODE

// type guard to check if node is an SetPaymentMethodDetailsFieldNode
export const isSetPaymentMethodDetailsFieldNode = (node: FlowNode): node is SetPaymentMethodDetailsFieldNode =>
    node.type === NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE

// type guard to check if node is an CreateSurveyNode
export const isCreateSurveyNode = (node: FlowNode): node is CreateSurveyNode =>
    node.type === NodeType.CREATE_SURVEY_NODE

// type guard to check if node is an RetractReviewsNode
export const isRetractReviewsNode = (node: FlowNode): node is RetractReviewsNode =>
    node.type === NodeType.RETRACT_REVIEWS_NODE

// type guard to check if node is an FetchCustomFieldsNode
export const isFetchCustomFieldsNode = (node: FlowNode): node is FetchCustomFieldsNode =>
    node.type === NodeType.FETCH_CUSTOM_FIELDS_NODE

// type guard to check if node has nextNode
export const hasNextNode = (node: FlowNode): node is FlowNode & { nextNode: string } =>
    "nextNode" in node && node.nextNode !== null

// type guard to check if node is conditional
export const isConditionalNode = (node: FlowNode): node is FlowNode & ConditionalNode =>
    "nextIfSuccess" in node && "nextIfFailure" in node

// type guard to check if metadata position is position
export const isPosition = (position: unknown): position is XYPosition => {
    if (typeof position !== "object" || position === null) return false
    if (!("x" in position) || !("y" in position)) return false
    if (typeof (position as XYPosition).x !== "number" || typeof (position as XYPosition).y !== "number") return false
    return true
}

// type guard to check if reviewer is a UserReviewer
export const isUserReviewer = (reviewer: Reviewer): reviewer is UserReviewer => reviewer.type === UserType.USER

// type guard to check if reviewer is a TeamReviewer
export const isTeamReviewer = (reviewer: Reviewer): reviewer is TeamReviewer => reviewer.type === UserType.TEAM

// type guard to check if flow is invalid
export const isFlowInvalid = (flowValidation: FlowValidation): flowValidation is InvalidFlow =>
    "error" in flowValidation

// type guard to check if flow is valid
export const isFlowValid = (flowValidation: FlowValidation): flowValidation is ValidFlow => "isValid" in flowValidation


