import * as Sentry from "@sentry/react"
import { useCallback } from "react"
import { defineMessage, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { useAppDispatch } from "~/store/hooks"
import { pipeResult } from "~/types/Result"

import { useCommunicationApi } from "~/domains/communication/api"
import { communicationActions } from "../store/CommunicationSlice"
import { MessageI, RoomId } from "../types"

const errorMessage = defineMessage({
    id: "communication.message.error",
    defaultMessage: "Failed to update message",
})

export const useTogglePinMessage = (message: MessageI, roomId: RoomId) => {
    const communicationApi = useCommunicationApi()
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    return useCallback(async () => {
        const result = await communicationApi.updateMessage(message.id, { isPinned: !message.isPinned })
        pipeResult(
            result,
            (updatedMessage) => {
                dispatch(communicationActions.addMessageInRoom({ roomId, message: updatedMessage }))
                if (updatedMessage.isPinned) {
                    dispatch(communicationActions.pinMessage({ roomId, message: updatedMessage }))
                } else {
                    dispatch(communicationActions.unpinMessage({ roomId, messageId: updatedMessage.id }))
                }
            },
            (error) => {
                Sentry.captureException(`Failed to update message: ${error}`, { extra: { message, error } })
                toast.error(formatMessage(errorMessage))
            }
        )
    }, [message, communicationApi, roomId])
}
