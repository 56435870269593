import { PartnershipI, PartnershipType } from "~/domains/partners/types/index"

const useSupplierAllowed = (partnershipData: PartnershipI[], partnershipDataLoading: boolean) => {
    const checkSupplierAllowed = () => {
        if (!partnershipDataLoading && partnershipData) {
            const supplierIsNotAllowed = partnershipData.some(
                (p) => p.partnershipType === PartnershipType.SUPPLIER && p.allowToSendPO === false
            )
            return !supplierIsNotAllowed
        }

        return true
    }

    return checkSupplierAllowed()
}

export default useSupplierAllowed
