import * as Sentry from "@sentry/browser"
import { useCallback, useEffect } from "react"

import { store } from "~/store"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

import { useCustomFieldsApi } from "../customFieldsApi"
import { customFieldsActions, selectCustomFieldObjects, selectFetchingCustomFieldObjects } from "../store"
import { CustomFieldValueI } from "../types/CustomFieldValue"

export const useFetchCustomFieldsByObjectId = (
    objectId?: string
): { customFields: CustomFieldValueI[] | null; refetch: (objectId: string) => Promise<CustomFieldValueI[]> } => {
    const dispatch = useAppDispatch()
    const customFieldsApi = useCustomFieldsApi()
    const customFieldsObjects = useAppSelector(selectCustomFieldObjects)?.[objectId || ""] || null
    const flattenCustomFieldsObjects: CustomFieldValueI[] = customFieldsObjects
        ? Object.values(customFieldsObjects).flat()
        : []

    const fetch = useCallback(
        async (objectId: string) => {
            const fetching = objectId ? selectFetchingCustomFieldObjects(store.getState())[objectId] === true : false

            if (!fetching && objectId) {
                try {
                    dispatch(customFieldsActions.fetchCustomFieldObjects(objectId))
                    const values = await customFieldsApi.getValues(objectId)
                    const flattenValues = Object.values(values).flat()
                    dispatch(customFieldsActions.fetchCustomFieldObjectsSuccess({ objectId, values: flattenValues }))

                    return flattenValues as CustomFieldValueI[]
                } catch (e) {
                    Sentry.captureException(e, { extra: { objectId } })
                    dispatch(customFieldsActions.fetchCustomFieldObjectsFailure(objectId))
                }
            }
            return [] as CustomFieldValueI[]
        },
        [customFieldsApi, dispatch]
    )

    useEffect(() => {
        if (!objectId) return

        fetch(objectId)
    }, [fetch, objectId])

    return { customFields: flattenCustomFieldsObjects, refetch: fetch }
}
