import React, { FC, useState } from "react"

import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { Condition } from "~/domains/orchestration/flows/components/configuration/BranchConfiguration"
import { ConditionBuilder } from "~/domains/orchestration/flows/components/configuration/ConditionBuilder"
import { ConfigurationProps, IfNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const IfConfiguration: FC<ConfigurationProps<IfNode>> = ({ selectedNode, advancedFields }) => {
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleSave = ({ condition }: Condition): void => {
        setCurrentNode({
            ...currentNode,
            condition: condition,
            metadata: {
                ...currentNode.metadata,
                additionalInformation: condition,
            },
        })
    }

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <ConditionBuilder
                condition={{
                    id: "if condition",
                    condition: currentNode.condition,
                }}
                handleSave={handleSave}
            />

            <AdvancedFields<IfNode> fields={advancedFields} currentNode={currentNode} setCurrentNode={setCurrentNode} />
        </ConfigurationNode>
    )
}
