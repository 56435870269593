import { AxiosInstance } from "axios"
import { useContext } from "react"

import { parseInvoicePennylaneSync } from "~/domains/orchestration/verp/parsers/parseInvoicePennylaneSync"
import {
    InvoicePennylaneSynchronizationStatusI,
    InvoicePennylaneSynchronizationStatusListI,
} from "~/features/ocr/types/pennylaneSynchronizationStatus"
import { isResultSuccess } from "~/types/Result"
import { ApiContext } from "~/utils/apiClient"
import { parseResponse } from "~/utils/apiClient/parseResponse"

import { parseInvoicesPennylaneSync } from "../parsers/parseInvoicesPennylaneSync"

const VERSION = "v1"
const BASE_URL_VERP = import.meta.env.VITE_API_VERP_URL + VERSION

class VerpApi {
    private static instance: VerpApi
    private constructor(private axiosClient: AxiosInstance) {}

    static getInstance(axiosClient: AxiosInstance) {
        if (!VerpApi.instance) {
            VerpApi.instance = new VerpApi(axiosClient)
        }
        return VerpApi.instance
    }

    async getInvoicePennylaneSynchronizationStatus(
        invoiceId: string
    ): Promise<InvoicePennylaneSynchronizationStatusI | null> {
        try {
            const response = await this.axiosClient.get(`${BASE_URL_VERP}/pennylane/invoices/${invoiceId}`)
            if (response.status === 204) {
                return null
            }

            const parsedResult = parseResponse(response, parseInvoicePennylaneSync)
            return isResultSuccess(parsedResult) ? parsedResult.result : null
        } catch (e) {
            console.error(e)
            throw e
        }
    }
    async getInvoicesPennylaneSynchronizationStatus(
        organizationId: string
    ): Promise<InvoicePennylaneSynchronizationStatusListI> {
        try {
            const response = await this.axiosClient.get(
                `${BASE_URL_VERP}/pennylane/organizations/${organizationId}/invoices`
            )
            if (response.status === 204) {
                return []
            }

            const parsedResult = parseResponse(response, parseInvoicesPennylaneSync)
            return isResultSuccess(parsedResult) ? parsedResult.result : []
        } catch (e) {
            console.error(e)
            throw e
        }
    }
    async pushInvoicePennylaneSynchronization(invoiceId: string): Promise<number> {
        try {
            const response = await this.axiosClient.post(`${BASE_URL_VERP}/pennylane/invoices/${invoiceId}/push`)
            return response.status
        } catch (e) {
            console.error(e)
            throw e
        }
    }
}

export const useVerpApi = () => {
    const { axiosClient } = useContext(ApiContext)
    return VerpApi.getInstance(axiosClient)
}
