import { Grid, Stack } from "@mui/material"
import { useRef } from "react"
import { defineMessages, useIntl } from "react-intl"
import { Navigate, generatePath, useNavigate, useParams } from "react-router-dom"

import { Card, HeaderH1, Tabs } from "~/components"
import Messages from "~/components/CommunicationsTabs/Messages"
import { usePartnerContactSave } from "~/domains/partners/store/hooks"
import "~/domains/transactions/assets/css/Purchases.scss"
import { withSocketIOProvider } from "~/domains/transactions/common/subscriptions/components/SocketIOContext"
import {
    InvoiceBudgets,
    InvoiceCommunication,
    InvoiceDocuments,
    InvoiceDuplicateWarning,
    InvoiceLifeCycle,
    InvoiceNavigation,
    InvoiceOrganizationBuyer,
    InvoiceOrganizationVendor,
    InvoicePurchaseOrders,
    InvoiceTags,
    InvoiceTypeSelector,
} from "~/domains/transactions/invoices-v1/components"
import {
    InvoiceActionsEdit,
    InvoiceCommonFieldsEdit,
    InvoiceCustomFieldsEdit,
    InvoiceDescriptionEdit,
    InvoiceItemsEdit,
    InvoicePaymentEdit,
} from "~/domains/transactions/invoices-v1/components/Edit"
import { INVOICE_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import "~/domains/transactions/invoices/_views/supplier/assets/css/Invoice.scss"
import { WithOcrExtractContext } from "~/domains/transactions/invoices/components/OcrExtract"
import { INVOICES_ROUTE } from "~/domains/transactions/invoices/routes"
import { useOcrWebsocket } from "~/features/ocr/hooks/useOcrWebsocket"
import { useSegment } from "~/hooks/useSegment"
import { HOME_ROUTE } from "~/routes/routes"
import { useAppSelector } from "~/store/hooks"
import { checkFormValidity } from "~/store/invoice/core"
import { useFetchInvoice, usePatchInvoice, useSubscribeInvoiceUpdates } from "~/store/invoice/hooks"
import { selectInvoiceEvents } from "~/store/invoice/invoiceSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceUserType, ViewTypeI } from "~/types"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"
import { useInvoiceWebsocket } from "~/utils/invoiceWebsocket"

const PREFIX = "#INV"

const messages = defineMessages({
    title: {
        id: "supplier.editInvoice.page.title",
        defaultMessage: "Edit Invoice",
    },
    tabInfos: {
        id: "supplier.editInvoice.page.tabs.infos",
        defaultMessage: "Infos",
    },
    tabCommunication: {
        id: "supplier.editInvoice.page.tabs.communication",
        defaultMessage: "Communication",
    },
    tabEvents: {
        id: "supplier.editInvoice.page.tabs.events",
        defaultMessage: "Events",
    },
})

export const InvoiceEdit = withSocketIOProvider(() => {
    const { formatMessage } = useIntl()
    const { segmentTrack } = useSegment()
    const navigate = useNavigate()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const events = useAppSelector(selectInvoiceEvents)
    const patchInvoice = usePatchInvoice(InvoiceUserType.BUYER)

    const { invoiceId } = useParams<{ invoiceId: string }>()
    const formRef = useRef<HTMLFormElement>(null)
    const invoiceLinesFormRef = useRef<HTMLFormElement>(null)
    const { invoice, buyerOrganization } = useFetchInvoice(invoiceId, ViewTypeI.buyer, 0)
    const handleContactSave = usePartnerContactSave(invoice?.buyer.organizationId, invoice?.supplier.organizationId)

    useInvoiceWebsocket(invoiceId)
    useOcrWebsocket(invoiceId ?? "")
    useSubscribeInvoiceUpdates(currentOrganizationId, invoiceId)

    const handleSubmit = async (skipVATCheck = false) => {
        if (!invoice) return
        const formValidity = checkFormValidity(invoice, formRef, invoiceLinesFormRef, formatMessage)
        if (!formValidity.valid || !invoice) return

        if (invoice.lineItems?.find((line) => !line.taxId) && !skipVATCheck) {
            // setDisplayVATMissingLinesPrompt(true)
            return
        }

        try {
            await patchInvoice()
            segmentTrack("Invoice Updated", {
                invoice_id: invoice.id,
            })
            handleContactSave({
                email: invoice?.supplier?.email,
            })

            navigate(generatePath(INVOICE_ROUTE, { invoiceId: invoice.id }))
        } catch (error) {
            console.error(error)
        }
    }

    // TEMP: add proper error handling
    if (!invoice) {
        return null
    }

    if (currentOrganizationId && !isFeatureEnabled(Features.InvoiceV1, currentOrganizationId)) {
        return <Navigate to={HOME_ROUTE} />
    }

    return (
        <WithOcrExtractContext>
            <div className="purchase-page edit-invoice-page">
                <div className="purchase-order">
                    <Grid container>
                        <HeaderH1 title={`${PREFIX}${invoice.number}`} backLink={INVOICES_ROUTE}>
                            <InvoiceActionsEdit
                                invoice={invoice}
                                formRef={formRef}
                                invoiceLinesFormRef={invoiceLinesFormRef}
                            />
                        </HeaderH1>
                        <InvoiceNavigation invoice={invoice} />
                        <InvoiceDuplicateWarning invoice={invoice} />
                        <InvoiceLifeCycle />
                    </Grid>
                    <Grid container className="grid">
                        <Grid item xs gap={2}>
                            <Tabs
                                tabs={[
                                    {
                                        label: formatMessage(messages.tabInfos),
                                        component: (
                                            <div className="mt-12 mb-12">
                                                <InvoiceTypeSelector invoice={invoice} />
                                                <InvoiceOrganizationVendor invoice={invoice} />
                                                <InvoiceOrganizationBuyer invoice={invoice} />
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault()
                                                        handleSubmit()
                                                    }}
                                                    ref={formRef}
                                                >
                                                    <Stack gap={2}>
                                                        <InvoiceCommonFieldsEdit
                                                            invoice={invoice}
                                                            dataLoaded={true}
                                                            updateData={() => {}}
                                                        />
                                                        <InvoiceCustomFieldsEdit invoice={invoice} />
                                                        <InvoiceDescriptionEdit invoice={invoice} />
                                                        <InvoicePurchaseOrders invoice={invoice} />
                                                        <InvoiceTags invoice={invoice} />
                                                        <InvoiceBudgets invoice={invoice} />
                                                        <InvoicePaymentEdit invoice={invoice} />
                                                        <InvoiceCommunication invoice={invoice} />
                                                    </Stack>
                                                </form>

                                                <InvoiceDocuments invoiceId={invoice.id} />
                                            </div>
                                        ),
                                    },
                                    {
                                        label: formatMessage(messages.tabCommunication),
                                        component: buyerOrganization && (
                                            <div className="mt-12 mb-12">
                                                <InvoiceCommunication invoice={invoice} />
                                            </div>
                                        ),
                                    },
                                    {
                                        label: formatMessage(messages.tabEvents),
                                        component: (
                                            <Card className="mt-12 mb-12">
                                                <Messages messages={events} type="events" />
                                            </Card>
                                        ),
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <InvoiceItemsEdit invoice={invoice} invoiceLinesFormRef={invoiceLinesFormRef} />
        </WithOcrExtractContext>
    )
})
