import { Stack, Typography } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader, SafeFormattedMessage } from "~/components"
import { CheckFileTypeResult, UploadDocumentBox, boxSize } from "~/components/UploadDocument/UploadDocument"
import { useUploadDocument } from "~/components/UploadDocument/hooks"
import { copilotApi } from "~/domains/copilot/prompt/api"
import { useDocumentsApi } from "~/domains/identity/features/documents/documentsApi"
import { useDocumentById } from "~/domains/identity/features/documents/hooks"
import { DocumentObjectType, DocumentType, UploadDocumentDTO } from "~/domains/identity/features/documents/types"
import {
    paymentMethodDetailsActions,
    selectSelectedItem,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { mapToEdit } from "~/domains/payment/payment-method-details/utils/mapPaymentMethodDetails"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { Result } from "~/types/Result"
import { checkMultiFileTypes } from "~/utils/upload"

const messages = defineMessages({
    uploadTip: {
        id: "common.upload.fileTypesAndSize",
        defaultMessage: "We accept {FileTypes} files. No more than 2MB.",
    },
    description: {
        id: "payment.paymentMethodDetailsManagement.upload.description",
        defaultMessage: "Upload your document containing bank details, we will extract the information for you.",
    },
    loadingMessage: {
        id: "common.upload.loadingMessage",
        defaultMessage: "Upload in progress... This may take a few seconds.",
    },
    errorWrongFileType: {
        id: "common.upload.error.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept {FileTypes} files.",
    },
    errorUpload: {
        id: "common.upload.error.errorUploadingDocument",
        defaultMessage: "An error occurred while uploading the document.",
    },
})

const ACCEPTED_FILE_TYPES = ["application/pdf", "image/*"]
const ACCEPT_FILE_DISPLAY = "PDF, PNG, JPG"

interface PaymentMethodUploadProps {
    organizationId: string
}

export const PaymentMethodUpload: React.FC<PaymentMethodUploadProps> = ({ organizationId }) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState(false)
    const [, setDocumentPreview] = useState<Result<string, string> | null>(null)

    const selectedPaymentMethodDetails = useAppSelector(selectSelectedItem)

    const documentsApi = useDocumentsApi()
    const { uploadDocument } = useUploadDocument(organizationId)

    const { document } = useDocumentById(selectedPaymentMethodDetails?.documentId)

    useEffect(() => {
        const fetchDocumentPreview = async () => {
            if (!document?.latestFileVersion) return

            try {
                const preview = await documentsApi.fetchImage(document?.latestFileVersion)
                setDocumentPreview(preview)
            } catch (error) {
                console.error("Error fetching document preview:", error)
                setDocumentPreview({ success: true, result: document?.latestFileVersion })
            }
        }

        fetchDocumentPreview()
    }, [document?.latestFileVersion])

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult =>
            checkMultiFileTypes(
                file,
                ACCEPTED_FILE_TYPES,
                formatMessage(messages.errorWrongFileType, {
                    FileTypes: ACCEPT_FILE_DISPLAY,
                })
            ),
        [formatMessage]
    )

    const handleFile = useCallback(async (file: File) => {
        setLoading(true)

        try {
            const result: any = await copilotApi.uploadPaymentMethod(file, organizationId)

            if (!result?.paymentMethodDetails?.bankTransfer) {
                toast.error(formatMessage(messages.errorUpload))
                return
            }

            const payload: UploadDocumentDTO = {
                file,
                name: file.name,
                documentType: DocumentType.PAYMENT_METHOD_DETAILS,
                objectId: result?.paymentMethodDetails?.id ?? "",
                objectType: DocumentObjectType.PAYMENT,
                organizationIds: [organizationId],
            }
            const document = await uploadDocument(payload)

            const bankTransfer = mapToEdit(result?.paymentMethodDetails?.bankTransfer)

            dispatch(
                paymentMethodDetailsActions.setSelectedItem({
                    paymentMethodType: PaymentMethodType.BANK_TRANSFER,
                    ...bankTransfer,
                    documentId: document?.id,
                })
            )
        } catch (error) {
            toast.error(formatMessage(messages.errorUpload))
        } finally {
            setLoading(false)
        }
    }, [])

    return (
        <>
            {loading ? (
                <Stack alignItems="center" gap={1}>
                    <Loader small />
                    <Typography color="primary.main">{formatMessage(messages.loadingMessage)}</Typography>
                </Stack>
            ) : (
                <>
                    {
                        /* 
                        Uncomment this when we have an image to display

                        documentPreview ? (
                        isResultSuccess(documentPreview) ? (
                            <DocumentPreview url={documentPreview.result} />
                        ) : (
                            <Alert severity="error">{documentPreview.error}</Alert>
                        )
                    )  : */ <Stack gap={2}>
                            <UploadDocumentBox
                                handleFile={handleFile}
                                conditionMessage={formatMessage(messages.uploadTip, {
                                    FileTypes: ACCEPT_FILE_DISPLAY,
                                })}
                                checkFileType={checkFileType}
                                accept={ACCEPTED_FILE_TYPES.join(",")}
                                size={boxSize.MEDIUM}
                            />
                            <i>
                                <SafeFormattedMessage {...messages.description} />
                            </i>
                        </Stack>
                    }
                </>
            )}
        </>
    )
}
