import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import isEqual from "lodash.isequal"
import React, { ChangeEvent, FC, PropsWithChildren, useEffect, useRef, useState } from "react"

import { SafeFormattedMessage } from "~/components"
import { useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { messages } from "~/domains/orchestration/flows/messages"
import { FlowNode, Node } from "~/domains/orchestration/flows/types"

import "./ConfigurationNode.scss"

export interface ConfigurationNodeProps {
    selectedNode: FlowNode
}

const adaptCommonNodeProperties = (node: FlowNode): Pick<Node, "name" | "slug"> => {
    return {
        name: node.name,
        slug: node.slug,
    }
}

export const ConfigurationNode: FC<PropsWithChildren<ConfigurationNodeProps>> = ({ selectedNode, children }) => {
    const dispatch = useEditorDispatch()
    const [commonNodeProperties, setCommonNodeProperties] = useState(adaptCommonNodeProperties(selectedNode))
    const nodeRef = useRef(selectedNode)

    useEffect(() => {
        // Check if node has changed
        if (
            isEqual(nodeRef.current, selectedNode) &&
            isEqual(commonNodeProperties, {
                name: selectedNode.name,
                slug: selectedNode.slug,
            })
        )
            return

        nodeRef.current = selectedNode

        dispatch({
            type: "UPDATE_NODE",
            payload: { ...selectedNode, ...commonNodeProperties },
        })
        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: true,
        })
    }, [selectedNode, commonNodeProperties])

    const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setCommonNodeProperties({ ...commonNodeProperties, name: e.target.value })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <Stack className="flows-configurationNode" gap={2}>
            <Stack gap={1} className={configurationNodeItemClassName}>
                <TextField
                    label={<SafeFormattedMessage {...messages.configurationNode.nameLabel} />}
                    value={commonNodeProperties.name}
                    onChange={handleChangeName}
                    size="small"
                />
            </Stack>
            {commonNodeProperties.slug && (
                <Stack direction="row" justifyContent="flex-end" gap={1} className="flows-configurationNode-item-slug">
                    <SafeFormattedMessage {...messages.configurationNode.slugLabel} />
                    <span>{commonNodeProperties.slug}</span>
                </Stack>
            )}
            {children}
        </Stack>
    )
}
