import { Autocomplete, TextField } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/messages"
import { BudgetNode } from "~/domains/orchestration/flows/types"
import { BudgetDataI } from "~/features/budget/types"
import { useFetchBudgetsData } from "~/store/budget/hooks"

interface Props {
    currentNode: BudgetNode
    handleChange: (props: BudgetNode) => void
}

export const SharedBudgetConfiguration: FC<Props> = ({ currentNode, handleChange }) => {
    const { formatMessage } = useIntl()

    const organizationId = useOrganizationId()
    const { budgetsData } = useFetchBudgetsData(organizationId, false)

    const [budget, setBudget] = useState<BudgetDataI | null>(null)

    const handleChangeBudget = (_, value: BudgetDataI | null) => {
        if (!value) return

        setBudget(value)
        handleChange({
            ...currentNode,
            budgetId: value.id,
            metadata: {
                ...currentNode.metadata,
                additionalInformation: value.name,
            },
        })
    }

    // TODO: add these once we have the API for them
    // const handleChangeTransactionType = (e: ChangeEvent<HTMLInputElement>): void => {
    //     const transactionType = e.target.value
    //     if (!isTransactionType(transactionType)) return

    //     handleChange({
    //         ...currentNode,
    //         transactionType,
    //     })
    // }

    useEffect(() => {
        const currentBudget = budgetsData.find((budget) => budget.id === currentNode.budgetId)

        if (currentBudget) {
            setBudget(currentBudget)
        }
    }, [budgetsData])

    return (
        <>
            <Autocomplete
                options={budgetsData}
                value={budget}
                onChange={handleChangeBudget}
                getOptionLabel={(budget: BudgetDataI) => budget.name}
                isOptionEqualToValue={(option: BudgetDataI, value: BudgetDataI) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={formatMessage(messages.sharedBudgetConfiguration.selectBudgetsLabel)}
                    />
                )}
            />

            {/* TODO: add these once we have the API for them 

            <TextField
                select
                required
                label={formatMessage(messages.sharedBudgetConfiguration.transactionTypeLabel)}
                value={currentNode.transactionType ?? ""}
                onChange={handleChangeTransactionType}
                fullWidth
            >
                {TRANSACTION_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                        {formatMessage(messages.transactionType[type])}
                    </MenuItem>
                ))}
            </TextField>
            */}
        </>
    )
}
