import React from "react"
import { RenderElementProps } from "slate-react"

import { MessageActionPlan } from "~/domains/communication/types"

type Props = Omit<RenderElementProps, "element"> & { element: MessageActionPlan }

export const ActionPlanElement: React.FC<Props> = ({ attributes, children }) => {
    return <ul {...attributes}>{children}</ul>
}
