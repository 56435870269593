import { Grid, Tooltip } from "@mui/material"
import React, { useCallback, useState } from "react"
import { Check, Edit2, Save, Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useLocation, useNavigate } from "react-router-dom"

import { Button, ModalConfirm, SafeFormattedMessage } from "~/components"
import { ShareObjectPermissionsButton } from "~/domains/identity/features/roles-permissions/components/ShareObjectPermissionsModal/ShareObjectPermissionsButton"
import { useHasPermissions } from "~/domains/identity/features/roles-permissions/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { SpiceDBObjectType } from "~/domains/identity/features/roles-permissions/types/SpiceDB"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import {
    PURCHASE_REQUESTS_ROUTE,
    PURCHASE_REQUEST_NEW_ROUTE,
    PURCHASE_REQUEST_ROUTE,
} from "~/domains/transactions/purchase-requests/routes"
import { useUpdatePurchaseRequest } from "~/domains/transactions/purchase-requests/store/hooks/useUpdatePurchaseRequest"
import { useUpdatePurchaseRequestStatus } from "~/domains/transactions/purchase-requests/store/hooks/useUpdatePurchaseRequestStatus"
import { PurchaseRequestStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { AuthorizationName, OrganizationId, SpiceDBPermissionsResult } from "~/types"

interface PropsPRActions {
    id: string
    status: PurchaseRequestStatus
    organizationId: OrganizationId
    mode: PurchaseViewType
    isConvertedToPO: boolean
    buyerOrganization: OrganizationSummary
    supplierOrganization: OrganizationSummary
    permissions?: SpiceDBPermissionsResult
}

const messages = defineMessages({
    edit: {
        id: "purchase.requests.request.header.actions.edit",
        defaultMessage: "Edit",
    },
    save: {
        id: "purchase.requests.request.header.actions.save",
        defaultMessage: "Save",
    },
    send: {
        id: "purchase.requests.request.header.actions.send",
        defaultMessage: "Send",
    },
    confirm: {
        id: "purchase.requests.request.modalConfirm.title",
        defaultMessage: "Would you send the Purchase request for approval ?",
    },
    saveAsDraft: {
        id: "purchase.requests.request.modalConfirm.saveDraft",
        defaultMessage: "Save it as draft",
    },
    saveAndSend: {
        id: "purchase.requests.request.modalConfirm.send",
        defaultMessage: "Send for approval",
    },
    success: {
        id: "purchase.requests.request.modalConfirm.successSent",
        defaultMessage: "Purchase Request successfully sent for approval !",
    },
    backToList: {
        id: "purchase.requests.request.modalConfirm.backToList",
        defaultMessage: "Go back to purchase requests",
    },
    createNewPR: {
        id: "purchase.requests.request.modalConfirm.createNewPR",
        defaultMessage: "New Purchase request",
    },
})

export function ActionsHeader({
    id,
    status,
    organizationId,
    mode,
    buyerOrganization,
    supplierOrganization,
    isConvertedToPO,
    permissions,
}: PropsPRActions) {
    const navigate = useNavigate()
    const { updatePRStatus } = useUpdatePurchaseRequestStatus(organizationId, id)
    const location = useLocation()
    const { updatePR } = useUpdatePurchaseRequest(organizationId, id)

    const [displayModalSent, setDisplayModalSent] = useState<boolean>(false)
    const { formatMessage } = useIntl()
    const canEdit = (permissions?.edit && typeof permissions?.edit !== "undefined") ?? false
    const canSubmit = (permissions?.submit && typeof permissions?.submit !== "undefined") ?? false
    const permissionLabel = formatMessage(permissionMessages.errorNoAccessAdministrator)

    const handleEdit = () => {
        navigate(location.pathname + "/edit")
    }

    const { hasPermission: hasPREditPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.UPDATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_REQUESTS
    )

    const handleSend = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault()
            await updatePR(false)
            await updatePRStatus(PurchaseRequestStatus.SUBMITTED)
            // wait before redirecing so Workflow & Documents are ready
            window.setTimeout(() => {
                setDisplayModalSent(false)
                navigate(generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId: id }))
            }, 500)
        },
        [id, updatePR, updatePRStatus]
    )

    const handleNavigateToList = () => {
        navigate(PURCHASE_REQUESTS_ROUTE)
    }

    const handleNewPR = () => {
        navigate(PURCHASE_REQUEST_NEW_ROUTE)
    }

    const handleSave = (e: React.MouseEvent) => {
        e.preventDefault()
        updatePR().then(() => {
            const pathname = location.pathname.split("/edit")
            navigate(pathname[0])
        })
    }

    return (
        <Grid item className="actions">
            <ShareObjectPermissionsButton
                objectId={id}
                objectType={SpiceDBObjectType.PURCHASE_REQUEST}
                organizationId={organizationId}
                buyerOrganization={buyerOrganization}
                supplierOrganization={supplierOrganization}
            />

            {mode === PurchaseViewType.EDIT ? (
                <Tooltip arrow title={!canEdit ? permissionLabel : formatMessage(messages.save)}>
                    <span>
                        <Button type="grey" disabled={!canEdit} {...(canEdit ? { onClick: handleSave } : {})}>
                            <Save size={18} />
                        </Button>
                    </span>
                </Tooltip>
            ) : null}

            {mode !== PurchaseViewType.EDIT && !isConvertedToPO && hasPREditPermission ? (
                <Tooltip arrow title={!canEdit ? permissionLabel : formatMessage(messages.edit)}>
                    <span>
                        <Button type="grey" disabled={!canEdit} {...(canEdit ? { onClick: handleEdit } : {})}>
                            <Edit2 size={12} />
                        </Button>
                    </span>
                </Tooltip>
            ) : null}

            {status === PurchaseRequestStatus.DRAFT ? (
                <>
                    <Tooltip arrow title={!canSubmit ? permissionLabel : ""}>
                        <span>
                            <Button type="grey" disabled={!canSubmit} {...(canSubmit ? { onClick: handleSend } : {})}>
                                <>
                                    <SafeFormattedMessage {...messages.send} />
                                    <Check size={18} />
                                </>
                            </Button>
                        </span>
                    </Tooltip>
                    <ModalConfirm
                        message={formatMessage(messages.success)}
                        icon={
                            <div className="icon-container green">
                                <Send size={20} />
                            </div>
                        }
                        button1={
                            <Button
                                type="neutral"
                                onClick={handleNavigateToList}
                                text={formatMessage(messages.backToList)}
                            />
                        }
                        button2={
                            <Button type="primary" onClick={handleNewPR} text={formatMessage(messages.createNewPR)} />
                        }
                        open={displayModalSent}
                    />
                </>
            ) : null}
        </Grid>
    )
}
