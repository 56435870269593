import { Stack } from "@mui/material"
import React, { useCallback } from "react"
import { X as CloseMenu, Menu as HamburgerMenu } from "react-feather"
import { defineMessages } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"
import { authActions } from "~/store/auth/authSlice"
import { globalActions, selectDisplayMenu } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    buttonLogin: {
        id: "header.buttonLogin",
        defaultMessage: "Sign in/up",
    },
})

interface UserActionsProps {
    isConnected: boolean
}

export const UserActions = ({ isConnected }: UserActionsProps) => {
    const dispatch = useAppDispatch()
    const displayMenu = useAppSelector(selectDisplayMenu)

    const handleDisplayHamburgerMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        dispatch(globalActions.setDisplayMenu())
    }
    const handleLogin = useCallback(() => {
        dispatch(authActions.openPopupSignIn("signup"))
    }, [dispatch])

    return (
        <Stack direction="row" alignItems="center" paddingTop={2}>
            {isConnected ? null : (
                <Button type="primary" onClick={handleLogin}>
                    <SafeFormattedMessage {...messages.buttonLogin} />
                </Button>
            )}
            <a
                href="~/components/Header/UserActions#"
                title=""
                className="hamburger-menu"
                onClick={handleDisplayHamburgerMenu}
            >
                {!displayMenu ? <HamburgerMenu size={30} /> : <CloseMenu size={30} />}
            </a>
        </Stack>
    )
}
