import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"

export enum Features {
    ChatBot,
    InvoiceCustomFields,
    Documents,
    VeoliaCustomFields,
    SecheCustomFields,
    SecheCustomRules,
    EiffageCustomFields,
    EiffageFlows,
    Flows,
    InvoiceV1,
    CatalogV1,
    Payment,
    Metrics,
    CustomForms,
    PartnerComplianceRisk,
    CommunicationPage,
}

/* To enable Sidebar Feature Flag, uncomment the related sections in the index.ts file of the Sidebar component */

// These are the feature flags, they are specified per organization per feature:

// ---------------- Section: Organisation grouppings ----------------
// demo orgs, use these orgs to show new features to customers
export const demoOrgs: Record<OrganizationId, boolean> = {
    "672b824fbdc6ff7a9a787ebb": true, // Stellantis staging
    "672b86d9bdc6ff7a9a787ebf": true, // BNP Paribas staging
    "67287572bdc6ff7a9a787e17": true, // FAERHESM
    "6673ee7e4d459a2ad69b16dc": true, // Eiffage staging
    "67474ffd6b832228b77b3aa4": true, // Seche staging
}

// Developer's orgs, use these orgs to test new features your selves
export const developerOrgs: Record<OrganizationId, boolean> = {
    "64f89067e53d5f4b94626027": true, // Estelle staging
    "64eca7e3dd74306a85f11cbd": true, // Thomas staging
    "64ec7758dd74306a85f11cb5": true, // Maxence staging
    "64edd35bdd74306a85f11cd3": true, // Vivien staging
    "64f6e968e53d5f4b94626008": true, // Arthur staging
    "66fd638173291f3d4b04aea9": true, // Pierre staging
    "6703e91850789d0f5f3e5b9f": true, // Nicolas staging
    "67079b086972916694322229": true, // Nicolas production
    "6728c967bdc6ff7a9a787e29": true, // Dmitry staging
    "66b384173400753620786933": true, // Georgian staging
    "67374ed2f2b9364eaf6197b9": true, // Veronika staging
}

// Flowie orgs in staging and production
export const flowieOrgs: Record<OrganizationId, boolean> = {
    "64ec6c29dd74306a85f11c19": true, // Flowie staging
    "64872730349426738cb80712": true, // Flowie production
}

// ---------------- Section:  Customer specifc feature flags (custom fields etc.) ----------------
export const griffinOrgs: Record<OrganizationId, boolean> = {
    "66095bf2118c1f5059bf4cfa": true, // Griffin staging
    "65f9d1662be4eb771ea731ca": true, // Griffin production
}

export const veoliaOrgs: Record<OrganizationId, boolean> = {
    "666b17a3ea11870c7b2d34b1": true, // Veolia staging
}

export const eiffageOrgs: Record<OrganizationId, boolean> = {
    "6673ee7e4d459a2ad69b16dc": true, // Eiffage staging
    "673b1cd159797801c4ea7184": true, // Eiffage production
}

export const prerequisProdFeatureFlag: Record<OrganizationId, boolean> = {
    "65fd90412be4eb771ea732e2": true, // Prerequis production
}

export const flowieNetsuiteFeatureFlag: Record<OrganizationId, boolean> = {
    "673dc9956b832228b77b3a4a": true, // Flowie netsuite staging
}

export const smasOrgs: Record<OrganizationId, boolean> = {
    "67065bd350789d0f5f3e5bab": true, // SMAS staging
}

export const integrationTestingOrgs: Record<OrganizationId, boolean> = {
    "671a584a2c738165af24a902": true, // Integration testing
}

export const mytilimerOrgs: Record<OrganizationId, boolean> = {
    "663411dcdcd8d146b46b8fb0": true, // Mytilimer staging
}

export const secheOrgs: Record<OrganizationId, boolean> = {
    "67474ffd6b832228b77b3aa4": true, // Seche staging
    "667d6850c09bac6e89487f32": true, // Seshe production
}

// ---------------- Section:  Feature flags ----------------

export const flowsFeatureFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
}

export const eiffageFlowsFeatureFlag: Record<OrganizationId, boolean> = {
    ...eiffageOrgs,
}

export const invoiceV1Flag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
    ...eiffageOrgs,
    ...prerequisProdFeatureFlag,
    ...flowieNetsuiteFeatureFlag,
    ...smasOrgs,
    ...integrationTestingOrgs,
    ...mytilimerOrgs,
}

export const catalogV1Flag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
    ...eiffageOrgs,
}

export const paymentFeatureFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
    ...eiffageOrgs,
    ...mytilimerOrgs,
}

export const metricsFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...demoOrgs,
}

export const documentsFeatureFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
}

export const chatbotFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
}

export const customFormsFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
}

export const communicationPageFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...secheOrgs,
}

type FeatureFlagValue = Record<OrganizationId, boolean> | boolean

const featuresFlags: Record<Features, FeatureFlagValue> = {
    [Features.InvoiceCustomFields]: griffinOrgs,
    [Features.VeoliaCustomFields]: veoliaOrgs,
    [Features.EiffageCustomFields]: eiffageOrgs,
    [Features.SecheCustomFields]: secheOrgs,
    [Features.SecheCustomRules]: secheOrgs,
    [Features.EiffageFlows]: eiffageFlowsFeatureFlag,
    [Features.Flows]: flowsFeatureFlag,
    [Features.ChatBot]: chatbotFlag,
    [Features.Documents]: documentsFeatureFlag,
    [Features.InvoiceV1]: invoiceV1Flag,
    [Features.CatalogV1]: catalogV1Flag,
    [Features.Payment]: paymentFeatureFlag,
    [Features.Metrics]: metricsFlag,
    [Features.CustomForms]: customFormsFlag,
    [Features.PartnerComplianceRisk]: secheOrgs,
    [Features.CommunicationPage]: communicationPageFlag,
}

export const isFeatureEnabled = (feature: Features, organizationId?: OrganizationId): boolean => {
    const value = featuresFlags[feature]
    return value === true || (typeof value === "object" && isDefined(organizationId) && value[organizationId] === true)
}
