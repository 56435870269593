import * as Sentry from "@sentry/browser"
import { useEffect, useState } from "react"

import { Card, Steps } from "~/components"
import { useGetPaymentByObjectIdQuery } from "~/domains/payment/payment/api/paymentApi"
import { usePatchUpdateInvoiceMutation } from "~/domains/transactions/invoices-v1/api/invoiceApiV1"
import { InvoiceLifeCycleItem, InvoiceParty } from "~/domains/transactions/invoices-v1/types/Invoice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { invoiceActions, selectInvoice } from "~/store/invoice/invoiceSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

export const InvoiceLifeCycle = () => {
    const dispatch = useAppDispatch()
    const invoice = useAppSelector(selectInvoice)
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()

    const { data: paymentData } = useGetPaymentByObjectIdQuery(invoice?.id || "")
    const payments = paymentData?.items ?? []
    const payment = payments[0] ?? null

    const party = invoice?.parties?.[organizationId || ""]
    const [currentLifecycle, setCurrentLifecycle] = useState(party?.currentLifecycle)

    const lifecycle: InvoiceLifeCycleItem[] = [
        ...(party?.template?.lifecycle?.default ? [party.template.lifecycle.default] : []),
        ...(party?.template?.lifecycle?.other || []),
    ]

    useEffect(() => {
        setCurrentLifecycle(party?.currentLifecycle)
    }, [party?.currentLifecycle])

    const handleUpdateLifecycle = async (status: string) => {
        if (!organizationId || !invoice) return

        try {
            setCurrentLifecycle(status)
            await patchUpdateInvoice({ id: invoice.id, parties: { [organizationId]: { currentLifecycle: status } } })
            const parties = { [organizationId]: { ...party, currentLifecycle: status } } as Record<string, InvoiceParty>
            dispatch(invoiceActions.updatePartialInvoice({ id: invoice.id, parties }))
        } catch (error) {
            setCurrentLifecycle(currentLifecycle)
            Sentry.captureException(error)
        }
    }

    const steps = (lifecycle || []).map(({ status, description }) => ({
        key: status,
        name: status,
        description: description,
        isCurrentLifecycle: status === currentLifecycle,
        onClick: handleUpdateLifecycle,
    }))

    const paymentStep = payment
        ? {
              key: "payment",
              name: "paid",
              description: payment.status,
              isCurrentLifecycle: payment.status === "EXECUTED",
          }
        : null

    const stepsWithPayment = paymentStep ? [...steps, paymentStep] : steps
    return (
        <Card className="w-full">
            <Steps steps={stepsWithPayment} />
        </Card>
    )
}
