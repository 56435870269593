import { nodeConfig } from "~/domains/orchestration/flows/core/"

import { CreateNodeProps, Flow, FlowNode, NodeType } from "../types"

/**
 *
 * @param flow
 * @returns Flow
 * @description bump the version of the flow
 */
const bumpFlow = (flow: Flow): Flow => ({ ...flow, version: flow.version + 1 })

/**
 *
 * @param nodeType
 * @param currentNodes
 * @returns string
 * @description generate a readable id for a node
 */
const generateReadableId = (nodeType: NodeType, currentNodes: FlowNode[]): string => {
    const BaseSlug = nodeConfig[nodeType].baseSlug
    if (!BaseSlug) {
        throw new Error(`Unknown node type: ${nodeType}`)
    }

    const existingNodes = currentNodes.filter((node) => node.type === nodeType)

    const BaseSlugFilter = (id: string) => (node: FlowNode) => node.slug.startsWith(id)
    const isSameBaseSlug = BaseSlugFilter(BaseSlug)

    const getIdCountFromNode = (node: FlowNode) => {
        const match = node.slug.match(new RegExp(`${BaseSlug}_(\\d+)$`))
        return match ? parseInt(match[1], 10) : 0
    }

    const existingIds = existingNodes.filter(isSameBaseSlug).map(getIdCountFromNode)

    const maxNumber = Math.max(0, ...existingIds)
    return `${BaseSlug}_${maxNumber + 1}`
}

const createNode = (props: CreateNodeProps): FlowNode | null => {
    const { type, ...factoryProps } = props
    const configuration = nodeConfig[type]
    if (!configuration) {
        return null
    }
    return configuration.factory(factoryProps)
}

export { bumpFlow, createNode, generateReadableId }
