import styled from "@emotion/styled"
import { Grid } from "@mui/material"
import { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, Card, Loader } from "~/components"
import {
    ActivePayment,
    PayeePaymentMethodDetails,
    PayerPaymentMethodDetails,
} from "~/domains/payment/payment-method-details/components"
import {
    useCreatePaymentMutation,
    useCreatePaymentObjectMutation,
    useGetPaymentByObjectIdQuery,
} from "~/domains/payment/payment/api/paymentApi"
import { DocumentData, Payment, PaymentStatus, SelectedPaymentMethods } from "~/domains/payment/payment/types"
import { ObjectType } from "~/domains/payment/types"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    title: {
        id: "payment.document.paymentDetails.title",
        defaultMessage: "Payment",
    },
    payNow: {
        id: "payment.document.paymentDetails.payNow",
        defaultMessage: "Pay now",
    },
    buyerPaymentMethod: {
        id: "payment.document.paymentDetails.buyerPaymentMethod",
        defaultMessage: "My payment method",
    },
    vendorPaymentMethod: {
        id: "payment.document.paymentDetails.vendorPaymentMethod",
        defaultMessage: "Vendor payment method",
    },
    paymentInitiated: {
        id: "payment.document.paymentDetails.paymentInitiated",
        defaultMessage: "The payment has been successfully initiated",
    },
    paymentFailed: {
        id: "payment.document.paymentDetails.paymentFailed",
        defaultMessage: "The payment has failed",
    },
    noPayeePaymentMethod: {
        id: "payment.document.paymentDetails.noPayeePaymentMethod",
        defaultMessage: "Please select a payment method for the payee",
    },
    noPayerPaymentMethod: {
        id: "payment.document.paymentDetails.noPayerPaymentMethod",
        defaultMessage: "Please select a payment method for the payer",
    },
})

const PartiesPaymentData = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "var(--spacing-md)",
})

const Actions = styled("div")({
    display: "flex",
    justifyContent: "center",
})

interface DocumentPaymentSectionProps {
    objectId?: string
    title?: string
    payerId: string
    payeeId: string
    documentData: DocumentData
    documentId: string
}

const initialState = {
    payerPaymentMethodDetailsId: null,
    payeePaymentMethodDetailsId: null,
    paymentMethodId: null,
}

export const DocumentPaymentSection = ({
    title,
    payerId,
    payeeId,
    documentId,
    documentData,
}: DocumentPaymentSectionProps) => {
    const [paymentData, setPaymentData] = useState<SelectedPaymentMethods>(initialState)
    const currentUserId = useAppSelector(selectUserId)
    const { data, isLoading } = useGetPaymentByObjectIdQuery(documentId ?? "", {
        skip: !documentId,
        refetchOnMountOrArgChange: true,
    })
    const payments = data?.items ?? []
    const payment = payments[0] ?? null

    const [createPayment, { isLoading: isLoadingCreate }] = useCreatePaymentMutation()
    const [createPaymentObject, { isLoading: isLoadingCreateObject }] = useCreatePaymentObjectMutation()

    const { formatMessage } = useIntl()
    const isLoadingState = isLoading || isLoadingCreate || isLoadingCreateObject

    const handlePayNow = useCallback(async () => {
        try {
            const { payeePaymentMethodDetailsId, payerPaymentMethodDetailsId, paymentMethodId } = paymentData

            if (!payeePaymentMethodDetailsId) {
                toast.warning(formatMessage(messages.noPayeePaymentMethod))
                return
            }

            if (!payerPaymentMethodDetailsId && !paymentMethodId) {
                toast.warning(formatMessage(messages.noPayerPaymentMethod))
                return
            }

            const payload: Payment = {
                amount_sent_data: documentData.total,
                payer_id: payerId,
                payee_id: payeeId,
                payment_method_id: paymentMethodId,
                origin_cash_id: payerPaymentMethodDetailsId ?? null,
                destination_cash_id: payeePaymentMethodDetailsId ?? null,
                status: PaymentStatus.PENDING,
                executed_by_id: currentUserId,
                authorized_by_id: currentUserId,
                payment_metadata: {
                    invoice_number: documentData.number,
                },
            }
            const newPayment = await createPayment(payload).unwrap()
            toast.success(formatMessage(messages.paymentInitiated))

            if (newPayment) {
                const paymentObjectPayload = {
                    payment_id: newPayment.id,
                    object_id: documentId,
                    object_type: ObjectType.TRANSACTION,
                    link_created_by_id: currentUserId,
                    link_creation_datetime: new Date().toISOString(),
                }
                await createPaymentObject(paymentObjectPayload).unwrap()
            }
        } catch (e) {
            toast.error(formatMessage(messages.paymentFailed))
        }
    }, [
        paymentData,
        documentData,
        payerId,
        payeeId,
        currentUserId,
        formatMessage,
        createPayment,
        createPaymentObject,
        documentId,
    ])

    const handlePayerPaymentMethodChanged = (payerPaymentMethodDetailsId: string | null) => {
        setPaymentData((prev) => ({
            ...prev,
            payerPaymentMethodDetailsId: payerPaymentMethodDetailsId || null,
        }))
    }

    const handlePayeePaymentMethodChanged = (payeePaymentMethodDetailsId: string | null) => {
        setPaymentData((prev) => ({ ...prev, payeePaymentMethodDetailsId }))
    }

    if (!payerId || !payeeId) {
        return null
    }

    return (
        <Card title={title ?? formatMessage(messages.title)} expandable>
            {isLoadingState ? (
                <Grid container justifyContent="center">
                    <Loader small />
                </Grid>
            ) : (
                <PartiesPaymentData>
                    {payment ? (
                        <ActivePayment payment={payment} />
                    ) : (
                        <>
                            {payerId && (
                                <PayerPaymentMethodDetails
                                    payerId={payerId}
                                    selectedPaymentMethodDetailsId={
                                        paymentData.payerPaymentMethodDetailsId || paymentData.paymentMethodId
                                    }
                                    label={formatMessage(messages.buyerPaymentMethod)}
                                    onPaymentMethodChanged={handlePayerPaymentMethodChanged}
                                />
                            )}
                            {payeeId && (
                                <PayeePaymentMethodDetails
                                    payerId={payerId}
                                    payeeId={payeeId}
                                    label={formatMessage(messages.vendorPaymentMethod)}
                                    selectedPaymentMethodDetailsId={paymentData.payeePaymentMethodDetailsId}
                                    onPaymentMethodChanged={handlePayeePaymentMethodChanged}
                                />
                            )}
                            <Actions>
                                <Button type="primary-light" onClick={handlePayNow}>
                                    {formatMessage(messages.payNow)}
                                </Button>
                            </Actions>
                        </>
                    )}
                </PartiesPaymentData>
            )}
        </Card>
    )
}
