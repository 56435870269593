import { Stack } from "@mui/material"
import { FC } from "react"

import { Card } from "~/components"
import { TotalAmount } from "~/domains/transactions/components/TotalAmount/TotalAmount"
import { InvoiceI } from "~/types"
import { CurrencyCodes } from "~/types"

interface InvoiceTotalAmountProps {
    invoice: InvoiceI
}

export const InvoiceTotalAmount: FC<InvoiceTotalAmountProps> = ({ invoice }) => {
    return (
        <Card>
            <Stack spacing={1}>
                {Object.entries(invoice.totalAmountDue || {}).map(([currency, amount]) => (
                    <TotalAmount key={currency} amount={+amount} currency={currency as CurrencyCodes} />
                ))}
            </Stack>
        </Card>
    )
}
