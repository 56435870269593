import * as Sentry from "@sentry/react"
import { MessageDescriptor } from "react-intl"

import { DocumentI, InvoiceStatus, InvoicesTab, OrganizationId, TagsI } from "~/types"
import { isDefined } from "~/utils/isDefined"

export type InvoicesTabMessage = Record<InvoicesTab, MessageDescriptor>

export const tabBuyerMessages: InvoicesTabMessage = {
    [InvoicesTab.ALL]: {
        id: "dataTable.tabs.buyer.all",
        defaultMessage: "All",
    },
    [InvoicesTab.DUPLICATES]: {
        id: "dataTable.tabs.buyer.duplicates",
        defaultMessage: "Duplicates",
    },
    [InvoicesTab.DRAFT]: {
        id: "dataTable.tabs.buyer.draft",
        defaultMessage: "To confirm",
    },
    [InvoicesTab.PENDING_VALIDATION]: {
        id: "dataTable.tabs.buyer.toValidate",
        defaultMessage: "To approve",
    },
    [InvoicesTab.INFO_REQUESTED]: {
        id: "dataTable.tabs.buyer.infoRequested",
        defaultMessage: "Info requested",
    },
    [InvoicesTab.PENDING_PAYMENT]: {
        id: "dataTable.tabs.buyer.toPay",
        defaultMessage: "To pay",
    },
    [InvoicesTab.PAID]: {
        id: "dataTable.tabs.buyer.paid",
        defaultMessage: "Paid",
    },
    [InvoicesTab.REJECTED]: {
        id: "dataTable.tabs.buyer.rejected",
        defaultMessage: "",
    },
}

export const tabSupplierMessages: InvoicesTabMessage = {
    [InvoicesTab.ALL]: {
        id: "dataTable.tabs.supplier.all",
        defaultMessage: "All",
    },
    [InvoicesTab.DUPLICATES]: {
        id: "dataTable.tabs.supplier.duplicates",
        defaultMessage: "Duplicates",
    },
    [InvoicesTab.DRAFT]: {
        id: "dataTable.tabs.supplier.draft",
        defaultMessage: "To send",
    },
    [InvoicesTab.PENDING_VALIDATION]: {
        id: "dataTable.tabs.supplier.toValidate",
        defaultMessage: "Pending validation",
    },
    [InvoicesTab.INFO_REQUESTED]: {
        id: "dataTable.tabs.supplier.infoRequested",
        defaultMessage: "Info requested",
    },
    [InvoicesTab.PENDING_PAYMENT]: {
        id: "dataTable.tabs.supplier.toPay",
        defaultMessage: "Pending payment",
    },
    [InvoicesTab.PAID]: {
        id: "dataTable.tabs.supplier.paid",
        defaultMessage: "Paid",
    },
    [InvoicesTab.REJECTED]: {
        id: "dataTable.tabs.supplier.rejected",
        defaultMessage: "",
    },
}

export const bulkActionsMessages: Record<string, MessageDescriptor> = {
    selected: {
        id: "account.documents.actions.selected",
        defaultMessage: "selected",
    },
    validate: {
        id: "account.documents.actions.validate",
        defaultMessage: "Validate",
    },
    approve: {
        id: "account.documents.actions.approve",
        defaultMessage: "Approve",
    },
    resolve: {
        id: "account.documents.actions.resolve",
        defaultMessage: "Resolve",
    },
    confirm: {
        id: "account.documents.actions.confirm",
        defaultMessage: "Confirm draft",
    },
    send: {
        id: "account.documents.actions.send",
        defaultMessage: "Send",
    },
    paid: {
        id: "account.documents.actions.paid",
        defaultMessage: "Paid",
    },
    pay: {
        id: "account.documents.actions.pay",
        defaultMessage: "Pay",
    },
    paymentReceived: {
        id: "account.documents.actions.paymentReceived",
        defaultMessage: "Payment{s} received",
    },
    addTags: {
        id: "account.documents.actions.addTags",
        defaultMessage: "Add tags",
    },
    addToBudget: {
        id: "account.documents.actions.addToBudgets",
        defaultMessage: "Add to budgets",
    },
    download: {
        id: "account.documents.actions.download",
        defaultMessage: "PDF",
    },
    downloadCsv: {
        id: "account.documents.actions.downloadCsv",
        defaultMessage: "CSV",
    },
    reject: {
        id: "account.documents.actions.reject",
        defaultMessage: "Reject",
    },
}

const showAll = () => true
const isNotRejected = (document: DocumentI) => document.status !== InvoiceStatus.REJECTED

export const filterResults = (
    invoices: DocumentI[],
    searchTerm: string,
    selectedTags: TagsI[],
    brandNames: Record<OrganizationId, string>,
    showRejected: boolean = false
): DocumentI[] => {
    return invoices
        .filter(showRejected ? showAll : isNotRejected)
        .filter(
            (invoice) =>
                selectedTags.length === 0 ||
                selectedTags.some((selectedTag) => invoice.tags?.some((tag) => tag.tagId === selectedTag.tagId))
        )
        .filter(
            (invoice) =>
                invoice.reference?.toLowerCase().includes(searchTerm) ||
                invoice.description?.toLowerCase().includes(searchTerm) ||
                invoice.supplierName?.toLowerCase().includes(searchTerm) ||
                invoice.buyerName?.toLowerCase().includes(searchTerm) ||
                invoice.purchaseOrderNumber?.toLowerCase().includes(searchTerm) ||
                invoice.tags?.some((tag) => tag.tagName && tag.tagName.toLowerCase().includes(searchTerm)) ||
                (invoice.buyerId && brandNames[invoice.buyerId]?.toLowerCase().includes(searchTerm)) ||
                (invoice.supplierId && brandNames[invoice.supplierId]?.toLowerCase().includes(searchTerm))
        )
}

export const notRejectedDuplicates = (duplicates: string[], documents: DocumentI[]): DocumentI[] => {
    return duplicates
        .map((invoiceId) => documents.find((document) => document.invoiceId === invoiceId))
        .filter(isDefined)
        .filter((document) => document.status !== InvoiceStatus.REJECTED)
}

type StatusCounts = Record<InvoiceStatus, number>
export const statusCounts = (invoices: DocumentI[]) => {
    const countsInitial: StatusCounts = {
        DRAFT: 0,
        DUPLICATES: 0,
        CONFIRMED: 0,
        INFO_REQUESTED: 0,
        VALIDATED: 0,
        PAYMENT_SCHEDULED: 0,
        PAID: 0,
        MARKED_AS_PAID: 0,
        REJECTED: 0,
        DRAFT_supplier: 0,
        CONFIRMED_supplier: 0,
        INFO_REQUESTED_supplier: 0,
        VALIDATED_supplier: 0,
        PAYMENT_SCHEDULED_supplier: 0,
        PAID_supplier: 0,
        MARKED_AS_PAID_supplier: 0,
        REJECTED_supplier: 0,
    }

    return invoices.reduce((count, invoice) => {
        count[invoice.status] = count[invoice.status] ? count[invoice.status] + 1 : 1
        if (
            invoice.possibleDuplicates &&
            invoice.possibleDuplicates.length &&
            invoice.status !== InvoiceStatus.REJECTED &&
            notRejectedDuplicates(invoice.possibleDuplicates, invoices).length
        ) {
            count.DUPLICATES = count.DUPLICATES ? count.DUPLICATES + 1 : 1
        }
        return count
    }, countsInitial)
}

type StatusSelectedI = {
    invoiceIds: string[]
    nb: number
}
export type StatusSelectedCounts = Record<InvoiceStatus, StatusSelectedI>

export const statusSelectedCounts = (invoices: { invoiceId: string; status: string }[], selected: string[]) => {
    const countsInitial: StatusSelectedCounts = {
        DRAFT: { invoiceIds: [], nb: 0 },
        DUPLICATES: { invoiceIds: [], nb: 0 },
        CONFIRMED: { invoiceIds: [], nb: 0 },
        INFO_REQUESTED: { invoiceIds: [], nb: 0 },
        VALIDATED: { invoiceIds: [], nb: 0 },
        PAYMENT_SCHEDULED: { invoiceIds: [], nb: 0 },
        PAID: { invoiceIds: [], nb: 0 },
        MARKED_AS_PAID: { invoiceIds: [], nb: 0 },
        REJECTED: { invoiceIds: [], nb: 0 },
        DRAFT_supplier: { invoiceIds: [], nb: 0 },
        CONFIRMED_supplier: { invoiceIds: [], nb: 0 },
        INFO_REQUESTED_supplier: { invoiceIds: [], nb: 0 },
        VALIDATED_supplier: { invoiceIds: [], nb: 0 },
        PAYMENT_SCHEDULED_supplier: { invoiceIds: [], nb: 0 },
        PAID_supplier: { invoiceIds: [], nb: 0 },
        MARKED_AS_PAID_supplier: { invoiceIds: [], nb: 0 },
        REJECTED_supplier: { invoiceIds: [], nb: 0 },
    }

    return invoices.reduce((count, invoice) => {
        if (selected.indexOf(invoice.invoiceId) !== -1) {
            if (!invoice.status || Object.values(InvoiceStatus).indexOf(invoice.status as InvoiceStatus) === -1) {
                Sentry.captureException(`statusSelectedCounts: Invoice status not found: ${invoice.status}`, {
                    extra: {
                        invoice,
                    },
                })
                return count
            }

            count[invoice.status].nb = count[invoice.status].nb + 1
            count[invoice.status].invoiceIds.push(invoice.invoiceId)
        }

        return count
    }, countsInitial)
}
