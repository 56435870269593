import { MessageDescriptor } from "react-intl"

export enum LanguageCode {
    AA = "AA",
    AB = "AB",
    AF = "AF",
    AK = "AK",
    AM = "AM",
    AR = "AR",
    AN = "AN",
    AS = "AS",
    AV = "AV",
    AE = "AE",
    AY = "AY",
    AZ = "AZ",
    BA = "BA",
    BM = "BM",
    BE = "BE",
    BN = "BN",
    BH = "BH",
    BI = "BI",
    BS = "BS",
    BR = "BR",
    BG = "BG",
    CA = "CA",
    CS = "CS",
    CH = "CH",
    CE = "CE",
    CU = "CU",
    CV = "CV",
    KW = "KW",
    CO = "CO",
    CR = "CR",
    CY = "CY",
    DA = "DA",
    DE = "DE",
    DV = "DV",
    DZ = "DZ",
    EL = "EL",
    EN = "EN",
    EO = "EO",
    ET = "ET",
    EU = "EU",
    EE = "EE",
    FO = "FO",
    FA = "FA",
    FJ = "FJ",
    FI = "FI",
    FR = "FR",
    FY = "FY",
    FF = "FF",
    GD = "GD",
    GA = "GA",
    GL = "GL",
    GV = "GV",
    GN = "GN",
    GU = "GU",
    HT = "HT",
    HA = "HA",
    HE = "HE",
    HZ = "HZ",
    HI = "HI",
    HO = "HO",
    HR = "HR",
    HU = "HU",
    HY = "HY",
    IG = "IG",
    IO = "IO",
    II = "II",
    IU = "IU",
    IE = "IE",
    IA = "IA",
    ID = "ID",
    IK = "IK",
    IS = "IS",
    IT = "IT",
    JV = "JV",
    JA = "JA",
    KL = "KL",
    KN = "KN",
    KS = "KS",
    KA = "KA",
    KR = "KR",
    KK = "KK",
    KM = "KM",
    KI = "KI",
    RW = "RW",
    KY = "KY",
    KV = "KV",
    KG = "KG",
    KO = "KO",
    KU = "KU",
    LO = "LO",
    LA = "LA",
    LV = "LV",
    LI = "LI",
    LN = "LN",
    LT = "LT",
    LB = "LB",
    LU = "LU",
    LG = "LG",
    MK = "MK",
    MH = "MH",
    ML = "ML",
    MI = "MI",
    MR = "MR",
    MS = "MS",
    MG = "MG",
    MT = "MT",
    MO = "MO",
    MN = "MN",
    NA = "NA",
    NV = "NV",
    NR = "NR",
    ND = "ND",
    NG = "NG",
    NE = "NE",
    NL = "NL",
    NN = "NN",
    NB = "NB",
    NO = "NO",
    NY = "NY",
    OC = "OC",
    OJ = "OJ",
    OR = "OR",
    OM = "OM",
    OS = "OS",
    PA = "PA",
    PI = "PI",
    PL = "PL",
    PT = "PT",
    PS = "PS",
    QU = "QU",
    RM = "RM",
    RN = "RN",
    RO = "RO",
    RU = "RU",
    SG = "SG",
    SA = "SA",
    SI = "SI",
    SK = "SK",
    SL = "SL",
    SE = "SE",
    SM = "SM",
    SN = "SN",
    SD = "SD",
    SO = "SO",
    ST = "ST",
    ES = "ES",
    SC = "SC",
    SR = "SR",
    SS = "SS",
    SU = "SU",
    SW = "SW",
    SV = "SV",
    TY = "TY",
    TA = "TA",
    TT = "TT",
    TE = "TE",
    TG = "TG",
    TL = "TL",
    TH = "TH",
    TI = "TI",
    TO = "TO",
    TN = "TN",
    TS = "TS",
    TK = "TK",
    TR = "TR",
    TW = "TW",
    UG = "UG",
    UK = "UK",
    UR = "UR",
    UZ = "UZ",
    VE = "VE",
    VI = "VI",
    VO = "VO",
    WA = "WA",
    WO = "WO",
    XH = "XH",
    YI = "YI",
    YO = "YO",
    ZA = "ZA",
    ZH = "ZH",
    ZU = "ZU",
}
export interface LanguageI {
    code: LanguageCode
    name: string
}

export const Languages: LanguageI[] = [
    { code: LanguageCode.AA, name: "Afar" },
    { code: LanguageCode.AB, name: "Abkhazian" },
    { code: LanguageCode.AF, name: "Afrikaans" },
    { code: LanguageCode.AK, name: "Akan" },
    { code: LanguageCode.AM, name: "Amharic" },
    { code: LanguageCode.AR, name: "Arabic" },
    { code: LanguageCode.AN, name: "Aragonese" },
    { code: LanguageCode.AS, name: "Assamese" },
    { code: LanguageCode.AV, name: "Avaric" },
    { code: LanguageCode.AE, name: "Avestan" },
    { code: LanguageCode.AY, name: "Aymara" },
    { code: LanguageCode.AZ, name: "Azerbaijani" },
    { code: LanguageCode.BA, name: "Bashkir" },
    { code: LanguageCode.BM, name: "Bambara" },
    { code: LanguageCode.BE, name: "Belarusian" },
    { code: LanguageCode.BN, name: "Bengali" },
    { code: LanguageCode.BH, name: "Bihari languages" },
    { code: LanguageCode.BI, name: "Bislama" },
    { code: LanguageCode.BS, name: "Bosnian" },
    { code: LanguageCode.BR, name: "Breton" },
    { code: LanguageCode.BG, name: "Bulgarian" },
    { code: LanguageCode.CA, name: "Catalan; Valencian" },
    { code: LanguageCode.CS, name: "Czech" },
    { code: LanguageCode.CH, name: "Chamorro" },
    { code: LanguageCode.CE, name: "Chechen" },
    { code: LanguageCode.CU, name: "Church Slavic" },
    { code: LanguageCode.CV, name: "Chuvash" },
    { code: LanguageCode.KW, name: "Cornish" },
    { code: LanguageCode.CO, name: "Corsican" },
    { code: LanguageCode.CR, name: "Cree" },
    { code: LanguageCode.CY, name: "Welsh" },
    { code: LanguageCode.DA, name: "Danish" },
    { code: LanguageCode.DE, name: "German" },
    { code: LanguageCode.DV, name: "Divehi" },
    { code: LanguageCode.DZ, name: "Dzongkha" },
    { code: LanguageCode.EL, name: "Greek, Modern" },
    { code: LanguageCode.EN, name: "English" },
    { code: LanguageCode.EO, name: "Esperanto" },
    { code: LanguageCode.ET, name: "Estonian" },
    { code: LanguageCode.EU, name: "Basque" },
    { code: LanguageCode.EE, name: "Ewe" },
    { code: LanguageCode.FO, name: "Faroese" },
    { code: LanguageCode.FA, name: "Persian" },
    { code: LanguageCode.FJ, name: "Fijian" },
    { code: LanguageCode.FI, name: "Finnish" },
    { code: LanguageCode.FR, name: "French" },
    { code: LanguageCode.FY, name: "Western Frisian" },
    { code: LanguageCode.FF, name: "Fulah" },
    { code: LanguageCode.GD, name: "Scottish Gaelic" },
    { code: LanguageCode.GA, name: "Irish" },
    { code: LanguageCode.GL, name: "Galician" },
    { code: LanguageCode.GV, name: "Manx" },
    { code: LanguageCode.GN, name: "Guarani" },
    { code: LanguageCode.GU, name: "Gujarati" },
    { code: LanguageCode.HT, name: "Haitian; Haitian Creole" },
    { code: LanguageCode.HA, name: "Hausa" },
    { code: LanguageCode.HE, name: "Hebrew" },
    { code: LanguageCode.HZ, name: "Herero" },
    { code: LanguageCode.HI, name: "Hindi" },
    { code: LanguageCode.HO, name: "Hiri Motu" },
    { code: LanguageCode.HR, name: "Croatian" },
    { code: LanguageCode.HU, name: "Hungarian" },
    { code: LanguageCode.HY, name: "Armenian" },
    { code: LanguageCode.IG, name: "Igbo" },
    { code: LanguageCode.IO, name: "Ido" },
    { code: LanguageCode.II, name: "Sichuan Yi" },
    { code: LanguageCode.IU, name: "Inuktitut" },
    { code: LanguageCode.IE, name: "Interlingue" },
    { code: LanguageCode.IA, name: "Interlingua" },
    { code: LanguageCode.ID, name: "Indonesian" },
    { code: LanguageCode.IK, name: "Inupiaq" },
    { code: LanguageCode.IS, name: "Icelandic" },
    { code: LanguageCode.IT, name: "Italian" },
    { code: LanguageCode.JV, name: "Javanese" },
    { code: LanguageCode.JA, name: "Japanese" },
    { code: LanguageCode.KL, name: "Kalaallisut" },
    { code: LanguageCode.KN, name: "Kannada" },
    { code: LanguageCode.KS, name: "Kashmiri" },
    { code: LanguageCode.KA, name: "Georgian" },
    { code: LanguageCode.KR, name: "Kanuri" },
    { code: LanguageCode.KK, name: "Kazakh" },
    { code: LanguageCode.KM, name: "Central Khmer" },
    { code: LanguageCode.KI, name: "Kikuyu" },
    { code: LanguageCode.RW, name: "Kinyarwanda" },
    { code: LanguageCode.KY, name: "Kirghiz" },
    { code: LanguageCode.KV, name: "Komi" },
    { code: LanguageCode.KG, name: "Kongo" },
    { code: LanguageCode.KO, name: "Korean" },
    { code: LanguageCode.KU, name: "Kurdish" },
    { code: LanguageCode.LO, name: "Lao" },
    { code: LanguageCode.LA, name: "Latin" },
    { code: LanguageCode.LV, name: "Latvian" },
    { code: LanguageCode.LI, name: "Limburgan" },
    { code: LanguageCode.LN, name: "Lingala" },
    { code: LanguageCode.LT, name: "Lithuanian" },
    { code: LanguageCode.LB, name: "Luxembourgish" },
    { code: LanguageCode.LU, name: "Luba-Katanga" },
    { code: LanguageCode.LG, name: "Ganda" },
    { code: LanguageCode.MK, name: "Macedonian" },
    { code: LanguageCode.MH, name: "Marshallese" },
    { code: LanguageCode.ML, name: "Malayalam" },
    { code: LanguageCode.MI, name: "Maori" },
    { code: LanguageCode.MR, name: "Marathi" },
    { code: LanguageCode.MS, name: "Malay" },
    { code: LanguageCode.MG, name: "Malagasy" },
    { code: LanguageCode.MT, name: "Maltese" },
    { code: LanguageCode.MO, name: "Moldavian" },
    { code: LanguageCode.MN, name: "Mongolian" },
    { code: LanguageCode.NA, name: "Nauru" },
    { code: LanguageCode.NV, name: "Navajo" },
    { code: LanguageCode.NR, name: "Ndebele, South" },
    { code: LanguageCode.ND, name: "Ndebele, North" },
    { code: LanguageCode.NG, name: "Ndonga" },
    { code: LanguageCode.NE, name: "Nepali" },
    { code: LanguageCode.NL, name: "Dutch" },
    { code: LanguageCode.NN, name: "Norwegian Nynorsk" },
    { code: LanguageCode.NB, name: "Norwegian Bokmål" },
    { code: LanguageCode.NO, name: "Norwegian" },
    { code: LanguageCode.NY, name: "Chichewa" },
    { code: LanguageCode.OC, name: "Occitan" },
    { code: LanguageCode.OJ, name: "Ojibwa" },
    { code: LanguageCode.OR, name: "Oriya" },
    { code: LanguageCode.OM, name: "Oromo" },
    { code: LanguageCode.OS, name: "Ossetian" },
    { code: LanguageCode.PA, name: "Punjabi" },
    { code: LanguageCode.PI, name: "Pali" },
    { code: LanguageCode.PL, name: "Polish" },
    { code: LanguageCode.PT, name: "Portuguese" },
    { code: LanguageCode.PS, name: "Pashto" },
    { code: LanguageCode.QU, name: "Quechua" },
    { code: LanguageCode.RM, name: "Romansh" },
    { code: LanguageCode.RN, name: "Rundi" },
    { code: LanguageCode.RO, name: "Romanian" },
    { code: LanguageCode.RU, name: "Russian" },
    { code: LanguageCode.SG, name: "Sango" },
    { code: LanguageCode.SA, name: "Sanskrit" },
    { code: LanguageCode.SI, name: "Sinhala" },
    { code: LanguageCode.SK, name: "Slovak" },
    { code: LanguageCode.SL, name: "Slovenian" },
    { code: LanguageCode.SE, name: "Northern Sami" },
    { code: LanguageCode.SM, name: "Samoan" },
    { code: LanguageCode.SN, name: "Shona" },
    { code: LanguageCode.SD, name: "Sindhi" },
    { code: LanguageCode.SO, name: "Somali" },
    { code: LanguageCode.ST, name: "Sotho, Southern" },
    { code: LanguageCode.ES, name: "Spanish" },
    { code: LanguageCode.SC, name: "Sardinian" },
    { code: LanguageCode.SR, name: "Serbian" },
    { code: LanguageCode.SS, name: "Swati" },
    { code: LanguageCode.SU, name: "Sundanese" },
    { code: LanguageCode.SW, name: "Swahili" },
    { code: LanguageCode.SV, name: "Swedish" },
    { code: LanguageCode.TY, name: "Tahitian" },
    { code: LanguageCode.TA, name: "Tamil" },
    { code: LanguageCode.TT, name: "Tatar" },
    { code: LanguageCode.TE, name: "Telugu" },
    { code: LanguageCode.TG, name: "Tajik" },
    { code: LanguageCode.TL, name: "Tagalog" },
    { code: LanguageCode.TH, name: "Thai" },
    { code: LanguageCode.TI, name: "Tigrinya" },
    { code: LanguageCode.TO, name: "Tonga" },
    { code: LanguageCode.TN, name: "Tswana" },
    { code: LanguageCode.TS, name: "Tsonga" },
    { code: LanguageCode.TK, name: "Turkmen" },
    { code: LanguageCode.TR, name: "Turkish" },
    { code: LanguageCode.TW, name: "Twi" },
    { code: LanguageCode.UG, name: "Uighur" },
    { code: LanguageCode.UK, name: "Ukrainian" },
    { code: LanguageCode.UR, name: "Urdu" },
    { code: LanguageCode.UZ, name: "Uzbek" },
    { code: LanguageCode.VE, name: "Venda" },
    { code: LanguageCode.VI, name: "Vietnamese" },
    { code: LanguageCode.VO, name: "Volapük" },
    { code: LanguageCode.WA, name: "Walloon" },
    { code: LanguageCode.WO, name: "Wolof" },
    { code: LanguageCode.XH, name: "Xhosa" },
    { code: LanguageCode.YI, name: "Yiddish" },
    { code: LanguageCode.YO, name: "Yoruba" },
    { code: LanguageCode.ZA, name: "Zhuang" },
    { code: LanguageCode.ZH, name: "Chinese" },
    { code: LanguageCode.ZU, name: "Zulu" },
]

export const languageCodeMessages: Record<LanguageCode, MessageDescriptor> = Languages.reduce(
    (acc, { code, name }) => ({
        ...acc,
        [code]: { id: `language.${code}`, defaultMessage: `${code} - ${name}` },
    }),
    {} as Record<LanguageCode, MessageDescriptor>
)
