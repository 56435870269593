import { OCRInputTypes } from "~/domains/transactions/invoices/components/OcrExtract/extract"
import { OcrDocumentId } from "~/features/ocr/types"
import { AlreadyPaidPaymentI, CountryCode, FileI, InvoiceUserType, OcrInvoiceI, UserId } from "~/types"

export interface OcrState {
    loading: boolean
    addingInvoice: boolean
    confirmInvoiceSuccess: boolean
    ocrDocumentId: OcrDocumentId | null
    invoiceId: string | null
    imageLinks: string[]
    data: OcrInvoiceI
    dataLoaded: boolean
    invoiceSent: boolean
    error: null | string
    hasSignedUp: boolean
    attachFiles: FileI[]
    involvedPeople: UserId[]
    showShapes: boolean
    currentInput: string
    currentInputType: OCRInputTypes | null
    currentAutocompleteText: string
    details: string
    initiator?: InvoiceUserType
    hasOcr: boolean
    alreadyPaidPayment: AlreadyPaidPaymentI
}

export const ocrState: OcrState = {
    loading: false,
    addingInvoice: false,
    confirmInvoiceSuccess: false,
    ocrDocumentId: null,
    invoiceId: null,
    imageLinks: [],
    dataLoaded: false,
    invoiceSent: false,
    error: null,
    hasSignedUp: false,
    attachFiles: [],
    involvedPeople: [],
    showShapes: false,
    currentInput: "",
    currentInputType: null,
    currentAutocompleteText: "",
    details: "",
    hasOcr: true,
    alreadyPaidPayment: {
        alreadyPaid: false,
        executionDate: null,
        requestIBAN: false,
    },
    data: {
        description: "",
        reference: "",
        purchaseOrderNumber: "",
        dueDate: "",
        total: 0,
        totalExcludedTaxes: 0,
        totalDiscount: 0,
        buyer: {
            userId: "",
            name: "",
            email: "",
            contactName: "",
            registrations: {
                countryCode: CountryCode.UNKNOWN,
                dunsNumber: "",
                registrationNumber: {
                    registrationNumber: "",
                    registrationType: "UNKNOWN",
                },
                vatNumber: "",
            },
        },
        supplier: {
            userId: "",
            name: "",
            email: "",
            contactName: "",
            registrations: {
                countryCode: CountryCode.UNKNOWN,
                dunsNumber: "",
                registrationNumber: {
                    registrationNumber: "",
                    registrationType: "UNKNOWN",
                },
                vatNumber: "",
            },
        },
        paymentDetails: {
            iban: null,
        },
        notification: {
            subject: "",
            body: "",
        },
        buyerTaxes: [],
        supplierTaxes: [],
    },
}
