import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import {
    ApprovalApiResponse,
    ApprovalObjectType,
    ToCheckObjectsResponse,
} from "~/domains/orchestration/flows-v0/types/Approval"

const BASE_URL = import.meta.env.VITE_API_APPROVAL_URL

export enum FetchErrorType {
    HTTP_REQUEST_ERROR = "HTTP_REQUEST_ERROR",
    PARSING_ERROR = "PARSING_ERROR",
}

export const approvalApi = createApi({
    reducerPath: "approvalApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    tagTypes: ["ApprovalChecks"],
    endpoints: (builder) => ({
        getObjectChecks: builder.query<
            ApprovalApiResponse,
            { organisationId: string; objectId: string; objectType: ApprovalObjectType }
        >({
            query: ({ organisationId, objectId, objectType }) => ({
                url: `v2/organizations/${organisationId}/objects/${objectId}/checks-progress/`,
                params: { objectType },
            }),
            providesTags: ["ApprovalChecks"],
        }),
        approveObject: builder.mutation<number, { objectId: string; objectType: ApprovalObjectType }>({
            query: ({ objectId, objectType }) => ({
                url: `v1/objects/${objectId}/approve/`,
                method: "POST",
                params: { objectType },
                responseHandler: async (response) => {
                    return response.status
                },
            }),
            invalidatesTags: ["ApprovalChecks"],
        }),
        refuseObject: builder.mutation<number, { objectId: string; objectType: ApprovalObjectType }>({
            query: ({ objectId, objectType }) => ({
                url: `v1/objects/${objectId}/refuse/`,
                method: "POST",
                params: { objectType },
                responseHandler: async (response) => {
                    return response.status
                },
            }),
            invalidatesTags: ["ApprovalChecks"],
        }),
        retractReview: builder.mutation<number, { objectId: string; objectType: ApprovalObjectType }>({
            query: ({ objectId, objectType }) => ({
                url: `v1/objects/${objectId}/retract/`,
                method: "POST",
                params: { objectType },
                responseHandler: async (response) => {
                    return response.status
                },
            }),
            invalidatesTags: ["ApprovalChecks"],
        }),
        retractAllReviews: builder.mutation<number, { objectId: string; objectType: ApprovalObjectType }>({
            query: ({ objectId, objectType }) => ({
                url: `v1/objects/${objectId}/retract-all/`,
                method: "POST",
                params: { objectType },
                responseHandler: async (response) => {
                    return response.status
                },
            }),
            transformErrorResponse: (response: FetchBaseQueryError) => {
                return response.status
            },
            invalidatesTags: ["ApprovalChecks"],
        }),
        getToCheckObjects: builder.query<
            ToCheckObjectsResponse,
            { organizationId: string; objectType: ApprovalObjectType }
        >({
            query: ({ organizationId, objectType }) => ({
                url: `v1/organizations/${organizationId}/objects/to-check`,
                params: { objectType },
            }),
        }),
    }),
})

export const {
    useGetObjectChecksQuery,
    useLazyGetObjectChecksQuery,
    useApproveObjectMutation,
    useRefuseObjectMutation,
    useGetToCheckObjectsQuery,
    useRetractReviewMutation,
    useRetractAllReviewsMutation,
} = approvalApi
