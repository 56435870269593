import {
    CatalogPayload,
    CatalogProductPayload,
    CatalogProductPrice,
    CatalogProductPricePayload,
    CatalogProductVariant,
    CatalogProductVariantPayload,
} from "~/domains/catalog/types/Catalog"

export const catalogToApiAdapters = (catalog: Partial<CatalogPayload>): Partial<CatalogPayload> => {
    const apiCatalog = {
        supplierId: catalog.supplierId,
        name: catalog.name,
        nameTranslations: catalog.nameTranslations,
        description: catalog.description,
        descriptionTranslations: catalog.descriptionTranslations,
        catalogType: catalog.catalogType,
        isActive: true,
        accessType: catalog.accessType,
        sharedWith: catalog.sharedWith,
    }

    return JSON.parse(JSON.stringify(apiCatalog))
}

export const productToApiAdapters = (product: CatalogProductPayload): CatalogProductPayload => {
    return {
        catalogId: product.catalogId,
        sku: product.sku,
        label: product.label,
        labelTranslations: product.labelTranslations,
        description: product.description,
        descriptionTranslations: product.descriptionTranslations,
        status: "ACTIVE",
        unitQuantity: product.unitQuantity,
        brand: product.brand,
    }
}

export const productVariantToApiAdapters = (
    productVariant: CatalogProductVariantPayload
): CatalogProductVariantPayload => {
    return {
        sku: productVariant.sku,
        label: productVariant.label,
        labelTranslations: productVariant.labelTranslations,
        description: productVariant.description,
        descriptionTranslations: productVariant.descriptionTranslations,
        defaultPriceId: productVariant.defaultPriceId,
        features: productVariant.features,
    }
}

export const priceToApiAdapters = (price: CatalogProductPrice): CatalogProductPricePayload => {
    return {
        amount: price.amount,
        currency: price.currency,
        countryCode: price.countryCode,
        minOrderThreshold: price.minOrderThreshold,
        maxOrderThreshold: price.maxOrderThreshold,
        incotermType: price.incotermType,
        effectiveDate: price.effectiveDate,
        expirationDate: price.expirationDate,
        eligibleOrganizations: price.eligibleOrganizations,
    }
}
