import { Mail } from "react-feather"

import { SendEmailConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { SendEmailNode } from "~/domains/orchestration/flows/components/nodes"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { Node, NodeType, SendEmailNode as SendEmailNodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const sendEmailNode: Omit<SendEmailNodeType, keyof Node> = {
    subject: ``,
    recipientAddresses: [],
    body: ``,
    nextNode: null,
    type: NodeType.SEND_EMAIL_NODE,
}

const advancedFields: (keyof typeof sendEmailNode)[] = []

const sendEmailConfig: Configuration<NodeType.SEND_EMAIL_NODE> = {
    type: NodeType.SEND_EMAIL_NODE,
    baseSlug: "send-email",
    Icon: Mail,
    Configuration: SendEmailConfiguration,
    advancedFields,
    Node: SendEmailNode,
    factory: (props: FactoryProps): SendEmailNodeType => ({
        ...sendEmailNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { sendEmailConfig, advancedFields as sendEmailAdvancedFields }
