import { Stack } from "@mui/material"
import * as Sentry from "@sentry/browser"
import { useMemo, useState } from "react"
import { CheckCircle, Edit, Trash } from "react-feather"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { ActionsMenu, ConfirmModal, Loader, SafeFormattedMessage } from "~/components"
import { ApprovalBlock } from "~/domains/orchestration/flows-v0/components/ApprovalBlock"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"
import {
    useDeletePaymentMethodDetailsMutation,
    useUpdatePaymentMethodDetailsMutation,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { ManualVerificationRequestModal } from "~/domains/payment/payment-method-details/components"
import {
    paymentMethodDetailsActions,
    selectActiveTab,
    selectSelectedOrganizationId,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { PaymentMethodDetails, PaymentMethodDetailsStatus } from "~/domains/payment/payment-method-details/types"
import { mapToEdit } from "~/domains/payment/payment-method-details/utils/mapPaymentMethodDetails"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    edit: {
        id: "payment.paymentMethodDetailsList.edit",
        defaultMessage: "Edit",
    },
    delete: {
        id: "payment.paymentMethodDetailsList.delete",
        defaultMessage: "Delete",
    },
    addVerification: {
        id: "payment.paymentMethodDetailsList.manualVerification.addVerification",
        defaultMessage: "Add verification",
    },
    successMessage: {
        id: "payment.paymentMethodDetailsList.successMessage",
        defaultMessage: "Payment method deleted successfully",
    },
    errorMessage: {
        id: "payment.paymentMethodDetailsList.errorMessage",
        defaultMessage: "An error occurred while deleting the payment method",
    },
    deleteMessage: {
        id: "payment.paymentMethodDetailsList.deleteMessage",
        defaultMessage: "Are you sure you want to delete this payment method?",
    },
})

interface LineActionsProps {
    row: PaymentMethodDetails
}

export const LineActions = ({ row }: LineActionsProps) => {
    const [deletePaymentMethodDetails, { isLoading }] = useDeletePaymentMethodDetailsMutation()
    const [updatePaymentDetails] = useUpdatePaymentMethodDetailsMutation()
    const organizationId = useAppSelector(selectSelectedOrganizationId)
    const activeTab = useAppSelector(selectActiveTab)

    const [open, setOpen] = useState(false)
    const [openManualVerification, setOpenManualVerification] = useState(false)
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    const handleEdit = () => {
        const lineToEdit = mapToEdit(row)
        dispatch(paymentMethodDetailsActions.setSelectedItem(lineToEdit))
    }

    const handleDelete = async () => {
        try {
            await deletePaymentMethodDetails(row.id).unwrap()
            setOpen(false)
            toast.success(formatMessage(messages.successMessage))
            return true
        } catch (error) {
            toast.error(formatMessage(messages.errorMessage))
            Sentry.captureException(error, {
                extra: {
                    paymentMethodId: row.id,
                },
            })
            return false
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpenDeleteConfirmation = () => {
        setOpen(true)
    }

    const handleOpenManualVerification = () => {
        setOpenManualVerification(true)
    }

    const handleCloseManualVerification = () => {
        setOpenManualVerification(false)
    }

    const handleClearRow = () => {
        dispatch(paymentMethodDetailsActions.removeRowById(row.id))
    }

    const handleAddRowToApprove = () => {
        dispatch(paymentMethodDetailsActions.addRowToApprove(row))
    }

    const handleUpdateStatus = async () => {
        try {
            await updatePaymentDetails({ id: row.id, status: PaymentMethodDetailsStatus.PENDING }).unwrap()
        } catch (error) {
            toast.error(formatMessage(messages.errorMessage))
            Sentry.captureException(error, {
                extra: {
                    paymentMethodId: row.id,
                },
            })
        }
    }

    const actions = useMemo(
        () => [
            {
                label: <FormattedMessage {...messages.addVerification} />,
                icon: <CheckCircle size={14} />,
                action: handleOpenManualVerification,
            },
            {
                label: <SafeFormattedMessage {...messages.edit} />,
                icon: <Edit size={14} />,
                action: handleEdit,
            },
            {
                label: <SafeFormattedMessage {...messages.delete} />,
                icon: <Trash size={14} />,
                action: handleOpenDeleteConfirmation,
            },
        ],
        [row]
    )

    if (!organizationId) {
        return null
    }

    return (
        <Stack direction="row" width="100%" alignItems="center" justifyContent="flex-end" gap={1}>
            <ApprovalBlock
                showRetractAll
                showRetract={false}
                organisationId={organizationId}
                objectId={row.id}
                objectType={ApprovalObjectType.PAYMENT_METHOD_DETAILS}
                displayType="inline"
                onApproved={handleClearRow}
                onRefused={handleClearRow}
                onRetract={handleAddRowToApprove}
                onRetractAll={handleUpdateStatus}
            />

            {activeTab !== PaymentMethodType.TO_APPROVE && <ActionsMenu actions={actions} />}

            <ConfirmModal
                open={open}
                title={<SafeFormattedMessage {...messages.deleteMessage} />}
                close={handleClose}
                onConfirm={handleDelete}
                {...(isLoading ? { confirmButtonText: <Loader small /> } : {})}
            />

            <ManualVerificationRequestModal
                open={openManualVerification}
                paymentMethodDetailsId={row.id}
                onClose={handleCloseManualVerification}
            />
        </Stack>
    )
}
