import * as t from "io-ts"

import { MessageViewI } from "~/domains/communication/types"
import { genericParser } from "~/utils"

export const MessageViewIO = t.type({
    id: t.string,
    messageId: t.string,
    userId: t.string,
    viewed: t.boolean,
    viewedAt: t.string,
})

export const MessageViewFromApiAdapter = (data: unknown) => {
    return genericParser<MessageViewI>(data, MessageViewIO)
}
