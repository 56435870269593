import { AxiosResponse } from "axios"
import fileDownload from "js-file-download"

import axiosClient from "~/api/axiosClient"
import {
    convertPOV2toPO,
    convertPOtoPOV2,
    convertPOtoPOV2ForUpdate,
} from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import {
    CreatePurchaseOrderDTO,
    CreatePurchaseOrderDTOV2,
    CreatePurchaseOrderResponse,
    ListPurchaseOrdersResponse,
    PurchaseOrderApprovalStatus,
    PurchaseOrderPDFDTO,
    PurchaseOrderStatus,
    PurchaseOrders,
    PurchaseOrdersResponseV2,
    UpdatePurchaseOrderDTOV2,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { OrganizationId } from "~/types"
import { ImportBatchResponseI } from "~/types/ImportBatch"
import { Result } from "~/types/Result"

const BASE_URL_V2 = import.meta.env.VITE_API_PURCHASE_URL_V2 + "organizations/"
const BASE_URL_V3 = import.meta.env.VITE_API_PURCHASE_URL_V3 + "organizations/"
const IMPORT_BASE_URL = import.meta.env.VITE_API_IMPORT_FROM_FILES_URL
const PO_TO_PDF_URL = import.meta.env.VITE_API_PO_TO_PDF_URL

export const purchaseOrdersApi = {
    create(organizationId: string, purchaseOrder: CreatePurchaseOrderDTO): Promise<CreatePurchaseOrderResponse> {
        const url = `${BASE_URL_V2}${organizationId}/purchase-orders`
        const purchaseOrderV2: CreatePurchaseOrderDTOV2 = convertPOtoPOV2(purchaseOrder)

        return axiosClient.post(url, purchaseOrderV2)
    },
    importBatchPO(organizationId: string, attachedFile: File): Promise<Result<ImportBatchResponseI>> {
        const formData = new FormData()
        formData.append("file", attachedFile)
        formData.append("organizationId", organizationId)

        return axiosClient.post(`${IMPORT_BASE_URL}purchase-order/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    toPDF(payload: PurchaseOrderPDFDTO, fileName: string) {
        const url = `${PO_TO_PDF_URL}pdf`
        return axiosClient
            .post(url, payload, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob",
            })
            .then((response) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fileDownload(response, fileName)
            })
            .catch((error) => {
                console.error(error)
            })
    },
    async findAll(organizationId: string): Promise<ListPurchaseOrdersResponse> {
        const url = `${BASE_URL_V3}${organizationId}/purchase-orders`
        const purchaseOrdersV2: PurchaseOrdersResponseV2[] = await axiosClient.get(url)
        const purchaseOrders: ListPurchaseOrdersResponse = purchaseOrdersV2.map(convertPOV2toPO)

        return purchaseOrders
    },
    async findById(organizationId: string, purchaseOrderId: string): Promise<PurchaseOrders> {
        const url = `${BASE_URL_V3}${organizationId}/purchase-orders/${purchaseOrderId}`
        const purchaseOrderV2: PurchaseOrdersResponseV2 = await axiosClient.get(url)
        const purchaseOrder: PurchaseOrders = convertPOV2toPO(purchaseOrderV2)

        return purchaseOrder
    },
    delete(organizationId: string, purchaseOrderId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL_V3}${organizationId}/purchase-orders/${purchaseOrderId}`
        return axiosClient.delete(url)
    },
    update(organizationId: string, purchaseOrderId: string, purchaseOrder: PurchaseOrders): Promise<AxiosResponse> {
        const url = `${BASE_URL_V3}${organizationId}/purchase-orders/${purchaseOrderId}`
        const purchaseOrderV2: UpdatePurchaseOrderDTOV2 = convertPOtoPOV2ForUpdate(purchaseOrder)

        return axiosClient.post(url, purchaseOrderV2)
    },
    updateApprovalStatus(
        organizationId: OrganizationId,
        purchaseOrderId: string,
        lineIds: string[],
        status: PurchaseOrderApprovalStatus
    ): Promise<AxiosResponse> {
        const url = `${BASE_URL_V3}${organizationId}/purchase-orders/${purchaseOrderId}/lines/approval`
        return axiosClient.post(url, { lineIds, status })
    },
    updateStatus(
        organizationId: OrganizationId,
        purchaseOrderId: string,
        status: PurchaseOrderStatus
    ): Promise<AxiosResponse> {
        const url = `${BASE_URL_V3}${organizationId}/purchase-orders/${purchaseOrderId}/status`
        return axiosClient.post(url, { status })
    },
}
