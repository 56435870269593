import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"

import { PAYMENT_METHODS_ROUTE, PAYMENT_METHOD_ROUTE } from "./routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="invs_route">
            <Route path={PAYMENT_METHODS_ROUTE} element={null} />
            <Route path={PAYMENT_METHOD_ROUTE} element={null} />
        </Route>,
    ],
}
