import * as t from "io-ts"

import { Result, ResultSuccess, isResultSuccess } from "~/types/Result"
import { optional } from "~/types/utils"
import { PaginationInfoIO, ParsingErrorType, RealOpaque, genericParser } from "~/utils"

export type ReceivedEmailId = RealOpaque<string, { readonly T: unique symbol }>
export type AttachementId = RealOpaque<string, { readonly T: unique symbol }>

export enum ReceivedEmailStatusType {
    PROCESSED = "PROCESSED",
    NOTHING_TO_PROCESS = "NOTHING_TO_PROCESS",
    PENDING = "PENDING",
    FAILED = "FAILED",
    UNKNOWN = "UNKNOWN",
}

export enum AttachmentStatusType {
    NOT_PROCESSABLE = "NOT_PROCESSABLE",
    ERROR_STORING = "ERROR_STORING",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
    PENDING = "PENDING",
    UNKNOWN = "UNKNOWN",
}

const AttachementIO = t.intersection([
    t.type({
        id: t.string,
        status: t.string,
    }),
    t.partial({
        fileId: optional(t.string),
        fileName: optional(t.string),
        invoiceId: optional(t.string),
        error: optional(t.string),
    }),
])

const ReceivedEmailIO = t.intersection([
    t.type({
        id: t.string,
        from: t.string,
        to: t.string,
        subject: t.string,
        body: t.string,
        receivedAt: t.string,
        status: t.string,
        read: t.boolean,
        attachments: t.array(AttachementIO),
    }),
    t.partial({
        failedReason: optional(t.string),
    }),
])

const PaginatedReceivedEmailsIO = t.type({
    pagination: PaginationInfoIO,
    results: t.array(ReceivedEmailIO),
    unreadEmails: t.number,
})

export type AttachementI = t.TypeOf<typeof AttachementIO> & {
    id: AttachementId
    status: AttachmentStatusType
}
export type ReceivedEmailI = Omit<t.TypeOf<typeof ReceivedEmailIO>, "attachments"> & {
    id: ReceivedEmailId
    attachments: AttachementI[]
    status: ReceivedEmailStatusType
}
export type PaginatedReceivedEmailsI = t.TypeOf<typeof PaginatedReceivedEmailsIO> & {
    results: ReceivedEmailI[]
}

const attachmentStatusFromString = (stringStatus: string): AttachmentStatusType => {
    if (Object.values(AttachmentStatusType).includes(stringStatus as AttachmentStatusType)) {
        return stringStatus as AttachmentStatusType
    }
    return AttachmentStatusType.UNKNOWN
}

const receivedEmailStatusFromString = (stringStatus: string): ReceivedEmailStatusType => {
    if (Object.values(ReceivedEmailStatusType).includes(stringStatus as ReceivedEmailStatusType)) {
        return stringStatus as ReceivedEmailStatusType
    }
    return ReceivedEmailStatusType.UNKNOWN
}

const parsedDataToAttachment = (data: t.TypeOf<typeof AttachementIO>): AttachementI => ({
    ...data,
    id: data.id as AttachementId,
    status: attachmentStatusFromString(data.status),
})

const parsedDataToReceivedEmail = (data: t.TypeOf<typeof ReceivedEmailIO>): ReceivedEmailI => ({
    ...data,
    id: data.id as ReceivedEmailId,
    attachments: data.attachments.map(parsedDataToAttachment),
    status: receivedEmailStatusFromString(data.status),
})

export const parseAttachment = (data: unknown): Result<AttachementI, ParsingErrorType> => {
    const result = genericParser(data, AttachementIO)
    if (isResultSuccess(result)) {
        return ResultSuccess(parsedDataToAttachment(result.result))
    }
    return result
}

export const parsePaginatedReceivedEmails = (data: unknown): Result<PaginatedReceivedEmailsI, ParsingErrorType> => {
    const result = genericParser(data, PaginatedReceivedEmailsIO)
    if (isResultSuccess(result)) {
        return ResultSuccess({
            ...result.result,
            results: result.result.results.map(parsedDataToReceivedEmail),
        })
    }
    return result
}

export const isAttachmentFailed = (attachment: AttachementI) => attachment.status === AttachmentStatusType.FAILED
