export type CustomForm = {
    id: string
    name: string
    createdAt: string
}

export interface UserSurveyApiResponse {
    id: string
    form: UserSurveyForm
    answers: UserSurveyAnswer[]
}

export interface SendFormResponse {
    id: string
    formId: string
    token: string
    email: string
    userId: string
    dueTimestamp: string
}

export interface CustomFormSurvey {
    id: string
    formId: string
    token: string
    respondentContacts: CustomFormResponder[]
    dueTimestamp: string
    createdAt: string
}

// These are used by form viewer:
export interface UserSurveyForm {
    id: string
    name: string
    createdAt: string
    sections: UserSurveySection[]
}

export interface UserSurveySection {
    id: string
    title: string
    order: number
    questions: UserSurveyQuestion[]
}

export interface UserSurveyQuestion {
    id: string
    order: number
    questionContent: string
    required: boolean
    fieldType: QuestionFieldType
    savedValue: string | string[] | boolean
    invalid?: boolean
}

export type QuestionFieldType =
    | { YesNo: string }
    | { TextField: string }
    | { TextArea: string }
    | { NumericField: { minValue: number; maxValue: number } }
    | { SingleChoice: { choices: string[] } }
    | { MultiChoice: { choices: string[] } }

export enum QuestionFieldTypeEnum {
    YesNo = "YesNo",
    TextField = "TextField",
    TextArea = "TextArea",
    NumericField = "NumericField",
    SingleChoice = "SingleChoice",
    MultiChoice = "MultiChoice",
}

export interface UserSurveyAnswer {
    questionId: string;
    answer: {
      TextFieldAnswer?: {
        value: string;
      };
      MultiChoiceAnswer?: {
        value: string[];
      };
      SingleChoiceAnswer?: {
        value: string;
      };
      YesNoAnswer?: {
        value: boolean;
      };
    };
}

export interface CustomFormResponder {
  userId: string
  email: string
}
