export enum ApprovalObjectType {
    INVOICE = "Invoice",
    ORGANIZATION_RELATIONSHIP = "OrganizationRelationship",
    BUDGET = "Budget",
    CONTACT = "Contact",
    PAYMENT = "Payment",
    PAYMENT_METHOD_DETAILS = "PaymentMethodDetails",
    PARTNER_PAYMENT_METHOD_DETAILS = "PartnerPaymentMethodDetails",
    PURCHASE_ORDER = "PurchaseOrder",
    PURCHASE_REQUEST = "PurchaseRequest",
    PURCHASE_ORDER_LINE = "PurchaseOrderLine",
    PURCHASE_REQUEST_LINE = "PurchaseRequestLine",
}

export enum ApprovalReviewerType {
    USER = "User",
    TEAM = "Team",
}

export type RevewI = {
    reviewedAt: string
    userId: string
}

export type ApprovalReviewI = {
    approvers: RevewI[]
    refusers: RevewI[]
}

export type ApprovalReviewerI = {
    teamId?: string
    userId?: string
    type: ApprovalReviewerType
}

export type ApprovalSourceI = {
    flowId: string
    flowVersion: string
    type: "Flow"
}

export type ApprovalCheckI = {
    name: string
    reviewers: ApprovalReviewerI[]
    passThreshold: number
    refuseThreshold: number
    status: ApprovalStatus
    source: ApprovalSourceI
    review: ApprovalReviewI
}

export type ApprovalApiResponse = {
    review: ApprovalReviewI
    checks: ApprovalCheckI[]
}

export enum ApprovalStatus {
    PASSED = "Passed",
    REFUSED = "Refused",
    PENDING = "Pending",
}

export type ToCheckObjectsResponse = {
    objectsToCheck: string[]
}

export enum ApprovalResult {
    APPROVED,
    REFUSED,
    PENDING,
}
