import {
    BatchUpdatePartnershipPayload,
    CreatePartnerProfileAddressI,
    CreatePartnerProfileContactI,
    CreatePartnerProfileI,
    CreatePartnerProfilePaymentDetailI,
    CreatePartnershipPayload,
    PartnerProfileAddressI,
    PartnerProfileContactI,
    PartnerProfileI,
    PartnerProfilePaymentDetailI,
    PartnershipI,
    PartnershipType,
    UpdatePartnershipPayload,
} from "~/domains/partners/types"
import { ApiElliproComplianceRiskI, ApiLsegComplianceRiskI } from "~/domains/partners/types/OrganizationComplianceRisk"
import { ImportBatchResponseI } from "~/types/ImportBatch"

import axiosClient from "./axiosClient"

const BOR_BASE_URL = import.meta.env.VITE_API_BOOK_OF_RELATIONS_URL
const IMPORT_BASE_URL = import.meta.env.VITE_API_IMPORT_FROM_FILES_URL

export const partnerApi = {
    fetchPartnership(organizationId: string, partnerId: string): Promise<PartnershipI[]> {
        const url = `${BOR_BASE_URL}v1/organizations/${organizationId}/partners/${partnerId}`
        return axiosClient.get(url)
    },
    createPartnership(organizationId: string, payload: CreatePartnershipPayload): Promise<PartnershipI> {
        const url = `${BOR_BASE_URL}v1/organizations/${organizationId}/partners`
        return axiosClient.post(url, payload)
    },
    deletePartner(organizationId: string, partnerId: string, side?: PartnershipType): Promise<void> {
        const deleteBaseURL = `${BOR_BASE_URL}v1/organizations/${organizationId}/partners/${partnerId}`
        const url = side ? deleteBaseURL + `?partnershipType=${side}` : deleteBaseURL
        return axiosClient.delete(url)
    },
    batchDeletePartnershipsByIds(organizationId: string, partnershipIds: string[]): Promise<void> {
        const url = `${BOR_BASE_URL}v1/organizations/${organizationId}/partners`
        return axiosClient.delete(url, { data: partnershipIds })
    },
    updatePartnership(
        organizationId: string,
        partnerId: string,
        payload: UpdatePartnershipPayload
    ): Promise<PartnershipI> {
        const url = `${BOR_BASE_URL}v1/organizations/${organizationId}/partners/${partnerId}`
        return axiosClient.patch(url, payload)
    },
    batchUpdatePartnerships(organizationId: string, payload: BatchUpdatePartnershipPayload[]): Promise<PartnershipI[]> {
        const url = `${BOR_BASE_URL}v1/organizations/${organizationId}/partners`
        return axiosClient.patch(url, payload)
    },
    importBatchBoR(organizationId: string, file: File): Promise<ImportBatchResponseI> {
        const url = `${IMPORT_BASE_URL}organization/${organizationId}/upload-file`
        const formData = new FormData()
        formData.append("file", file)
        return axiosClient.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    fetchPartnerProfile(organizationId: string, partnerId: string): Promise<PartnerProfileI | null> {
        const url = `${BOR_BASE_URL}v1/organizations/${organizationId}/partners/${partnerId}/profile`
        return axiosClient.get(url)
    },
    createPartnerProfile(partnerProfile: CreatePartnerProfileI): Promise<PartnerProfileI> {
        const url = `${BOR_BASE_URL}v1/organizations/${partnerProfile.initiatorId}/partners/${partnerProfile.partnerId}/profile`
        return axiosClient.post(url, partnerProfile)
    },
    updatePartnerProfile(
        initiatorId: string,
        partnerId: string,
        partnerProfile: Partial<PartnerProfileI>
    ): Promise<PartnerProfileI> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile`
        return axiosClient.patch(url, partnerProfile)
    },
    createPartnerProfileAddress(
        initiatorId: string,
        partnerId: string,
        address: CreatePartnerProfileAddressI
    ): Promise<PartnerProfileAddressI> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/addresses/`
        return axiosClient.post(url, address)
    },
    updatePartnerProfileAddress(
        initiatorId: string,
        partnerId: string,
        address: PartnerProfileAddressI
    ): Promise<PartnerProfileAddressI> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/addresses/${address.id}`
        return axiosClient.patch(url, address)
    },
    deletePartnerProfileAddress(
        initiatorId: string,
        partnerId: string,
        addressId: string
    ): Promise<PartnerProfileAddressI> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/addresses/${addressId}`
        return axiosClient.delete(url)
    },
    fetchPartnerProfileContacts(initiatorId: string, partnerId: string): Promise<PartnerProfileContactI[]> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/contacts`
        return axiosClient.get(url)
    },
    createPartnerProfileContact(
        initiatorId: string,
        partnerId: string,
        contact: CreatePartnerProfileContactI
    ): Promise<PartnerProfileContactI> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/contacts/`
        return axiosClient.post(url, contact)
    },
    updatePartnerProfileContact(
        initiatorId: string,
        partnerId: string,
        contact: PartnerProfileContactI
    ): Promise<PartnerProfileContactI> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/contacts/${contact.id}`
        return axiosClient.patch(url, contact)
    },
    deletePartnerProfileContact(
        initiatorId: string,
        partnerId: string,
        contactId: string
    ): Promise<PartnerProfileContactI> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/contacts/${contactId}`
        return axiosClient.delete(url)
    },
    fetchPartnerProfilePaymentDetails(initiatorId: string, partnerId: string): Promise<PartnerProfilePaymentDetailI[]> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/payment-details`
        return axiosClient.get(url)
    },
    createPartnerProfilePaymentDetail(
        initiatorId: string,
        partnerId: string,
        paymentDetail: CreatePartnerProfilePaymentDetailI
    ): Promise<PartnerProfilePaymentDetailI> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/payment-details/`
        return axiosClient.post(url, paymentDetail)
    },
    updatePartnerProfilePaymentDetail(
        initiatorId: string,
        partnerId: string,
        paymentDetail: PartnerProfilePaymentDetailI
    ): Promise<PartnerProfilePaymentDetailI> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/payment-details/${paymentDetail.id}`
        return axiosClient.patch(url, paymentDetail)
    },
    deletePartnerProfilePaymentDetail(
        initiatorId: string,
        partnerId: string,
        paymentDetailId: string
    ): Promise<PartnerProfilePaymentDetailI> {
        const url = `${BOR_BASE_URL}v1/organizations/${initiatorId}/partners/${partnerId}/profile/payment-details/${paymentDetailId}`
        return axiosClient.delete(url)
    },
    fetchPartnerElliproComplianceRisk(
        organizationId: string,
        partnerId: string
    ): Promise<ApiElliproComplianceRiskI | null> {
        const url = `${BOR_BASE_URL}v1/organizations/${organizationId}/partners/${partnerId}/ellipro`
        return axiosClient.get(url)
    },
    fetchPartnerLsegComplianceRisk(organizationId: string, partnerId: string): Promise<ApiLsegComplianceRiskI | null> {
        const url = `${BOR_BASE_URL}v1/organizations/${organizationId}/partners/${partnerId}/lseg`
        return axiosClient.get(url)
    },
}
