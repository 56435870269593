import React, { useEffect, useState } from "react"
import { MessageDescriptor, useIntl } from "react-intl"

import {
    ConditionAcceptedBySupplier,
    ObjectStrictDecision,
    SecheCustomFields,
    conditionAcceptedBySupplierEnumMessages,
    currencyCodeMessages,
    customFieldsListByOrganizationId,
    erpEnumMessages,
    secheCustomFieldsMessages,
    secheSupplierConditionsWarningMessages,
    strictDecisionEnumMessages,
} from "~/domains/transactions/custom-fields/components/CompanyCustomFields/CompanyCustomFieldsConfig"
import { CustomFieldObjectAsTextfield } from "~/domains/transactions/custom-fields/components/CompanyCustomFields/CustomFieldObjectAsTextfield"
import { getOrCreateCustomField } from "~/domains/transactions/custom-fields/components/CompanyCustomFields/CustomFieldObjectHelpers"
import {
    useFetchCustomFieldsByObjectId,
    usePendingCustomFieldsByFieldNames,
} from "~/domains/transactions/custom-fields/hooks"
import { CustomFieldObjectType } from "~/domains/transactions/custom-fields/types/CustomFieldObjectType"
import { OrganizationId } from "~/types"

interface Props {
    partnershipId: string | undefined
    currentOrganizationId: OrganizationId | undefined
    readOnly: boolean
}

export const PartnerProfileSecheCustomFields: React.FC<Props> = ({
    partnershipId,
    currentOrganizationId,
    readOnly,
}) => {
    const { formatMessage } = useIntl()
    const [showExistingEntityIdField, setShowExistingEntityIdField] = useState<boolean>(false)
    const [showFactorIdField, setShowFactorIdField] = useState<boolean>(false)
    const [warningMessage, setWarningMessage] = useState<MessageDescriptor | undefined>(undefined)
    const { customFields } = useFetchCustomFieldsByObjectId(partnershipId)
    const customFieldsList = customFieldsListByOrganizationId[currentOrganizationId ?? ""]
    const pendingCustomFields = usePendingCustomFieldsByFieldNames(customFieldsList)
    const isTemporaryId = !partnershipId

    const isExistingEntityCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.IS_EXISTING_ENTITY,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )
    const isExistingEntityPendingValue = pendingCustomFields.find(
        (field) => field?.name === SecheCustomFields.IS_EXISTING_ENTITY
    )?.value

    useEffect(() => {
        setShowExistingEntityIdField(
            isExistingEntityCustomField?.value === ObjectStrictDecision.YES ||
                isExistingEntityPendingValue === ObjectStrictDecision.YES
        )
    }, [isExistingEntityCustomField?.value, isExistingEntityPendingValue])

    const existingEntityIdCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.EXISTING_ENTITY_ID,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const isFactorCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.IS_FACTOR,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const isFactorPendingValue = pendingCustomFields.find((field) => field?.name === SecheCustomFields.IS_FACTOR)?.value

    useEffect(() => {
        setShowFactorIdField(
            isFactorCustomField?.value === ObjectStrictDecision.YES || isFactorPendingValue === ObjectStrictDecision.YES
        )
    }, [isFactorCustomField?.value, isFactorPendingValue])

    const factorIdCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.FACTOR_ID,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const erpCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.ERP,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const targetsCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.TARGETS,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const estimatedAnnualExpensesCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_AMOUNT,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const estimatedAnnualExpensesCurrencyCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_CURRENCY,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const conditionsAcceptedBySupplierCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.CONDITIONS_ACCEPTED_BY_SUPPLIER,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const conditionsAcceptedBySupplierPendingValue = pendingCustomFields.find(
        (field) => field?.name === SecheCustomFields.CONDITIONS_ACCEPTED_BY_SUPPLIER
    )?.value

    useEffect(() => {
        if (
            conditionsAcceptedBySupplierCustomField?.value === ConditionAcceptedBySupplier.LME ||
            conditionsAcceptedBySupplierCustomField?.value === ConditionAcceptedBySupplier.NO ||
            conditionsAcceptedBySupplierPendingValue === ConditionAcceptedBySupplier.LME ||
            conditionsAcceptedBySupplierPendingValue === ConditionAcceptedBySupplier.NO
        ) {
            setWarningMessage(
                secheSupplierConditionsWarningMessages[
                    (conditionsAcceptedBySupplierCustomField?.value as ConditionAcceptedBySupplier) ||
                        (conditionsAcceptedBySupplierPendingValue as ConditionAcceptedBySupplier)
                ]
            )
        } else {
            setWarningMessage(undefined)
        }
    }, [conditionsAcceptedBySupplierCustomField?.value, conditionsAcceptedBySupplierPendingValue])

    if (!currentOrganizationId) {
        return
    }

    return (
        <>
            <CustomFieldObjectAsTextfield
                customField={isExistingEntityCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.IS_EXISTING_ENTITY])}
                enumMessages={strictDecisionEnumMessages}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
                className="custom-fields"
            />
            {showExistingEntityIdField ? (
                <CustomFieldObjectAsTextfield
                    customField={existingEntityIdCustomField}
                    label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.EXISTING_ENTITY_ID])}
                    isTemporaryId={isTemporaryId}
                    isReadOnly={readOnly}
                    isRequired={showExistingEntityIdField ? true : false}
                />
            ) : null}
            <CustomFieldObjectAsTextfield
                customField={isFactorCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.IS_FACTOR])}
                enumMessages={strictDecisionEnumMessages}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
            />
            {showFactorIdField ? (
                <CustomFieldObjectAsTextfield
                    customField={factorIdCustomField}
                    label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.FACTOR_ID])}
                    isTemporaryId={isTemporaryId}
                    isReadOnly={readOnly}
                    isRequired={showFactorIdField ? true : false}
                />
            ) : null}
            <CustomFieldObjectAsTextfield
                customField={erpCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.ERP])}
                enumMessages={erpEnumMessages}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
            />
            <CustomFieldObjectAsTextfield
                customField={targetsCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.TARGETS])}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
            />
            <CustomFieldObjectAsTextfield
                customField={estimatedAnnualExpensesCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_AMOUNT])}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
            />
            <CustomFieldObjectAsTextfield
                customField={estimatedAnnualExpensesCurrencyCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_CURRENCY])}
                enumMessages={currencyCodeMessages}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
            />
            <CustomFieldObjectAsTextfield
                customField={conditionsAcceptedBySupplierCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.CONDITIONS_ACCEPTED_BY_SUPPLIER])}
                enumMessages={conditionAcceptedBySupplierEnumMessages}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
                errorMessage={warningMessage ? formatMessage(warningMessage) : undefined}
            />
        </>
    )
}
