import { Grid, Stack } from "@mui/material"
import { Navigate, useParams } from "react-router-dom"

import { CenterViewport, HeaderH1, Loader } from "~/components"
import "~/domains/transactions/assets/css/Purchases.scss"
import {
    InvoiceActions,
    InvoiceApproval,
    InvoiceBudgets,
    InvoiceCommonFields,
    InvoiceCopilot,
    InvoiceCustomFields,
    InvoiceDescription,
    InvoiceDocuments,
    InvoiceDuplicateWarning,
    InvoiceItems,
    InvoiceLifeCycle,
    InvoiceOrganizations,
    InvoicePeopleInvolved,
    InvoicePreview,
    InvoicePurchaseOrders,
    InvoiceTags,
} from "~/domains/transactions/invoices-v1/components"
import { InvoiceLifeCycleNextButton } from "~/domains/transactions/invoices-v1/components/InvoiceLifeCycleNextButton"
import { InvoiceNavigation } from "~/domains/transactions/invoices-v1/components/InvoiceNavigation"
import { InvoicePayment } from "~/domains/transactions/invoices-v1/components/InvoicePayment"
import { InvoiceTotalAmount } from "~/domains/transactions/invoices-v1/components/InvoiceTotalAmount"
import { INVOICES_ROUTE } from "~/domains/transactions/invoices/routes"
import { useTitle } from "~/hooks"
import { HOME_ROUTE } from "~/routes/routes"
import { useAppSelector } from "~/store/hooks"
import { useFetchInvoice } from "~/store/invoice/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ViewTypeI } from "~/types"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

const PREFIX = "#INV"

export const Invoice = () => {
    const { invoiceId } = useParams<{ invoiceId: string }>()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { invoice } = useFetchInvoice(invoiceId, ViewTypeI.buyer, 0, false)

    useTitle(`${PREFIX}${invoice?.number}`)

    if (!invoice) {
        return (
            <CenterViewport>
                <Loader />
            </CenterViewport>
        )
    }

    if (currentOrganizationId && !isFeatureEnabled(Features.InvoiceV1, currentOrganizationId)) {
        return <Navigate to={HOME_ROUTE} />
    }

    return (
        <div className="purchase-page">
            <div className="purchase-order">
                <Grid container>
                    <HeaderH1
                        title={`${PREFIX}${invoice.number}`}
                        backLink={INVOICES_ROUTE}
                        mainAction={<InvoiceLifeCycleNextButton />}
                    >
                        <InvoiceActions invoice={invoice} />
                    </HeaderH1>
                    <InvoiceNavigation invoice={invoice} />
                    <InvoiceDuplicateWarning invoice={invoice} />
                </Grid>
                <Grid container className="main-box grid">
                    <InvoiceLifeCycle />
                    <Grid item xs className="column">
                        <InvoiceTotalAmount invoice={invoice} />
                        <InvoiceCommonFields invoice={invoice} />
                        <InvoiceCustomFields invoice={invoice} />
                        <InvoicePurchaseOrders invoice={invoice} />
                        <InvoicePreview invoice={invoice} />
                    </Grid>
                    <Grid item md={6} sm={8} xs={12} gap={2}>
                        <InvoiceOrganizations invoice={invoice} />
                        <InvoiceItems invoice={invoice} />
                        <InvoiceBudgets invoice={invoice} />
                        <InvoicePayment invoice={invoice} />
                    </Grid>
                    <Grid item xs>
                        <Stack gap={2}>
                            <InvoiceApproval invoice={invoice} />
                            <InvoiceCopilot invoice={invoice} />
                            <InvoiceDescription invoice={invoice} />
                            <InvoiceTags invoice={invoice} />
                            <InvoiceDocuments invoiceId={invoice.id} />
                            <InvoicePeopleInvolved invoice={invoice} />
                            {/* <InvoiceCommunication invoice={invoice} /> */}
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
