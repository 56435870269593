import { Checkbox, FormControlLabel, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import dayjs, { Dayjs } from "dayjs"
import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card, SkeletonInput } from "~/components"
import { PartnerPaymentDetailsSelector } from "~/domains/partners/components/PartnerSelectors"
import { PartnerProfilePaymentDetailI } from "~/domains/partners/types"
import { OCRInputTypes } from "~/domains/transactions/invoices/components/OcrExtract/extract"
import { useOcrExtract } from "~/domains/transactions/invoices/components/OcrExtract/useOcrExtract"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { ocrActions } from "~/store/ocr/ocrSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import {
    AlreadyPaidPaymentI,
    ImportingInvoiceI,
    InvoicePaymentDetailsI,
    OrganizationId,
    PaymentDetailsI,
} from "~/types/"

import { displayIban } from "../../hooks"
import { IBANValidationWarning } from "./IbanValidationCheck"
import "./Payment.scss"

const messages = defineMessages({
    title: {
        id: "buyer.import.page.paymentDetails.title",
        defaultMessage: "Payment",
    },
    suppliersIBAN: {
        id: "buyer.import.page.paymentDetails.suppliersIBAN",
        defaultMessage: "Supplier's IBAN",
    },
    requestIBAN: {
        id: "buyer.import.page.paymentDetails.requestIBAN",
        defaultMessage: "I don't have the IBAN, request it",
    },
    invoiceIs: {
        id: "buyer.import.page.paymentDetails.invoiceIs",
        defaultMessage: "This invoice is:",
    },
    paid: {
        id: "buyer.import.page.paymentDetails.paid",
        defaultMessage: "Paid",
    },
    notPaid: {
        id: "buyer.import.page.paymentDetails.notPaid",
        defaultMessage: "Not paid",
    },
    executionDate: {
        id: "buyer.import.page.paymentDetails.executionDate",
        defaultMessage: "Execution date (MM/DD/YYYY)",
    },
})

interface PaymentDetailsProps {
    hidden?: boolean
    paymentDetails: PaymentDetailsI | undefined | null
    updateData: ((data: Partial<ImportingInvoiceI>) => void) | ((data: Partial<InvoicePaymentDetailsI>) => void)
    dataLoaded: boolean
    partnerId?: OrganizationId | null
}

enum PaymentStatus {
    PAID = "paid",
    NOT_PAID = "notPaid",
}

export function Payment({ hidden, paymentDetails, updateData, dataLoaded, partnerId }: PaymentDetailsProps) {
    const [alreadyPaid, setAlreadyPaid] = useState<PaymentStatus>(PaymentStatus.NOT_PAID)
    const [executionDate, setExecutionDate] = useState<string | null>(null)
    const [requestIBAN, setRequestIBAN] = useState<boolean>(false)
    const [pickerOpen, setPickerOpen] = useState<boolean>(false)

    const { formatMessage, locale } = useIntl()

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const { toggleShowShapes } = useOcrExtract()
    const dispatch = useAppDispatch()

    const isPaid = alreadyPaid === PaymentStatus.PAID
    const isIbanDisabled = requestIBAN && !isPaid
    const isIbanRequired = !requestIBAN && !isPaid

    useEffect(() => {
        if (hidden && !paymentDetails?.iban) {
            setRequestIBAN(true)
            updateAlreadyPaidPaymentData({ requestIBAN: true })
        }
        return () => {
            dispatch(ocrActions.resetAlreadyPaidPaymentData())
        }
    }, [])

    const onIbanChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateData({
                iban: event.currentTarget.value,
            })
        },
        [updateData]
    )

    const onPartnerPaymentDetailsChange = useCallback(
        (data: PartnerProfilePaymentDetailI | null) => {
            if (!data) {
                updateData && updateData({ iban: "" })
            }

            updateData && updateData({ iban: data?.iban ?? "" })
        },
        [updateData]
    )

    const updateAlreadyPaidPaymentData = useCallback(
        (data: Partial<AlreadyPaidPaymentI>) => {
            dispatch(ocrActions.updateAlreadyPaidPaymentData(data))
        },
        [dispatch]
    )

    const onIsPaidChange = useCallback(
        (event: MouseEvent<HTMLElement>, alreadyPaidText: PaymentStatus) => {
            if (alreadyPaidText !== null) {
                const alreadyPaid = alreadyPaidText === PaymentStatus.PAID
                updateAlreadyPaidPaymentData({
                    alreadyPaid,
                })
                if (alreadyPaid) {
                    updateAlreadyPaidPaymentData({ requestIBAN: false })
                }
                setAlreadyPaid(alreadyPaidText)
            }
        },
        [updateAlreadyPaidPaymentData]
    )

    const handleUpdateDueDate = useCallback(
        (newDate: Dayjs | null) => {
            if (newDate) {
                const executionDate = newDate.format("YYYY-MM-DD")
                setExecutionDate(executionDate)
                updateAlreadyPaidPaymentData({ executionDate })
            }
        },
        [updateAlreadyPaidPaymentData]
    )

    const handleRequestIBANChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setRequestIBAN(checked)
            updateAlreadyPaidPaymentData({ requestIBAN: checked })
        },
        [updateAlreadyPaidPaymentData]
    )

    const ibanInput = (
        <>
            {currentOrganizationId && partnerId ? (
                <div className="invoice-field mb-12">
                    <div className="invoice-field-container">
                        <PartnerPaymentDetailsSelector
                            currentOrganizationId={currentOrganizationId}
                            partnerId={partnerId}
                            onPaymentDetailChange={onPartnerPaymentDetailsChange}
                            label={formatMessage(messages.suppliersIBAN)}
                            isIbanRequired={isIbanRequired}
                            isIbanDisabled={isIbanDisabled}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="invoice-field">
                        <div className="invoice-field-container">
                            <TextField
                                key={alreadyPaid}
                                id="paymentDetails.iban"
                                name="paymentDetails.iban"
                                label={formatMessage(messages.suppliersIBAN)}
                                placeholder="IBAN"
                                onChange={onIbanChange}
                                value={displayIban(paymentDetails?.iban)}
                                fullWidth
                                disabled={isIbanDisabled}
                                required={isIbanRequired}
                                onFocus={() => toggleShowShapes("iban", OCRInputTypes.PAYMENT)}
                            />
                        </div>
                        {/* <ToggleShowShapes inputName="iban" inputType="payment" /> */}
                    </div>
                    <IBANValidationWarning iban={paymentDetails?.iban} />
                </>
            )}
        </>
    )

    return (
        <Card
            expandable
            title={formatMessage(messages.title)}
            {...(hidden
                ? {
                      sx: {
                          display: "none",
                      },
                  }
                : {})}
        >
            {!dataLoaded && (
                <>
                    <SkeletonInput />
                    <SkeletonInput />
                </>
            )}
            <div className="is-paid-buttons mb-12">
                <span>{formatMessage(messages.invoiceIs)}</span>
                <div className="buttons-container">
                    <ToggleButtonGroup color="primary" value={alreadyPaid} exclusive onChange={onIsPaidChange}>
                        <ToggleButton value={PaymentStatus.PAID}>{formatMessage(messages.paid)}</ToggleButton>
                        <ToggleButton value={PaymentStatus.NOT_PAID}>{formatMessage(messages.notPaid)}</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            {isPaid ? (
                <>
                    <div className="invoice-field mb-12">
                        <div className="invoice-field-container">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                <DatePicker
                                    open={pickerOpen}
                                    onOpen={() => setPickerOpen(true)}
                                    onClose={() => setPickerOpen(false)}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            onClick: () => setPickerOpen(true),
                                        },
                                    }}
                                    label={formatMessage(messages.executionDate)}
                                    value={executionDate ? dayjs(executionDate) : null}
                                    onChange={(newValue) => {
                                        handleUpdateDueDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    {ibanInput}
                </>
            ) : (
                <>
                    {ibanInput}
                    <div className="invoice-field mb-12">
                        <FormControlLabel
                            control={<Checkbox checked={requestIBAN} onChange={handleRequestIBANChange} />}
                            label={formatMessage(messages.requestIBAN)}
                        />
                    </div>
                </>
            )}
        </Card>
    )
}
