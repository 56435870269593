import { CurrencyCodes, DocumentWithPrice, DocumentWithVersion, InvoiceBackendVersion, InvoiceI } from "~/types"

export const getOcrInvoiceCurrency = (
    invoice: DocumentWithPrice & DocumentWithVersion,
    defaultValue: CurrencyCodes = CurrencyCodes.EUR
) => {
    /* Transaction API has different format than OCR API */
    if ((invoice as InvoiceI).version === InvoiceBackendVersion.V1) {
        return (Object.keys((invoice as InvoiceI).totalAmountDue)?.[0] as CurrencyCodes) || defaultValue
    }
    return invoice.totalAmountDue && invoice.totalAmountDue.length === 1
        ? invoice.totalAmountDue[0].currency
        : defaultValue
}
