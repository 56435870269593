import { Grid, Tooltip } from "@mui/material"
import { useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card, DataTable, DataTableColumn, ErrorMessage, Loader } from "~/components"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { selectLinesTags, selectPendingLineTags } from "~/domains/tags/store/tagsSlice"
import { SelectedTagI, SelectedTagWithObjectIdI, TagGroupI } from "~/domains/tags/types"
import { useAppSelector } from "~/store/hooks"
import { CurrencyCodes } from "~/types"
import { isDefined } from "~/utils/isDefined"

import "./AnalyticsTable.scss"

export interface AnalyticalItem {
    id: string | null
    quantity: number
    description?: string | null
    totalExcludedTaxes?: number
    totalAmountExcludingTax?: number
    temporaryId?: string
}

export interface Props<T extends AnalyticalItem> {
    items: T[]
    currency: CurrencyCodes
    renderLineTags: (line: T, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => JSX.Element | null
    organizationId: string
}

const messages = defineMessages({
    description: { id: "purchase.requests.request.items.description", defaultMessage: "Description" },
    quantity: { id: "purchase.requests.request.items.quantity", defaultMessage: "Qty" },
    totalExcludedTaxes: { id: "purchase.requests.request.items.totalExcludedTaxes", defaultMessage: "Total tax excl." },
    tags: { id: "purchase.requests.request.items.tags", defaultMessage: "Tags" },
})

const getTagGroups = (
    tags: SelectedTagWithObjectIdI[],
    pendingTags: Record<string, SelectedTagI[]>,
    tagGroups: TagGroupI[] | null
): TagGroupI[] => {
    if (!tagGroups || !tagGroups.length) {
        return []
    }
    const newTagGroupIds: string[] = []
    tags.forEach((tag) => {
        if (!newTagGroupIds.includes(tag.tagGroupId)) {
            newTagGroupIds.push(tag.tagGroupId)
        }
    })
    for (const lineId in pendingTags) {
        const pTags = pendingTags[lineId]
        pTags.forEach((tag) => {
            if (!newTagGroupIds.includes(tag.tagGroupId)) {
                newTagGroupIds.push(tag.tagGroupId)
            }
        })
    }
    return newTagGroupIds
        .map((tagGroupId: string) => tagGroups.find((tagGroup) => tagGroup.tagGroupId === tagGroupId))
        .filter(isDefined)
}

export function AnalyticsTable<T extends AnalyticalItem>({
    items,
    currency,
    renderLineTags,
    organizationId,
}: Props<T>) {
    const { formatMessage, formatNumber } = useIntl()
    const tags = useAppSelector(selectLinesTags)
    const pendingTags = useAppSelector(selectPendingLineTags)
    const { loading, error, tagGroups } = useOrganizationTagGroups(organizationId)
    const [itemsTagGroups, setItemsTagGroups] = useState<TagGroupI[]>([])

    const totalExcludedTaxesField =
        !items.length || "totalExcludedTaxes" in items[0] ? "totalExcludedTaxes" : "totalAmountExcludingTax"

    useEffect(() => {
        if (tags.length || Object.keys(pendingTags).length) {
            setItemsTagGroups(getTagGroups(tags, pendingTags, tagGroups))
        }
    }, [tags, pendingTags])

    const tagGroupColumns: DataTableColumn<T>[] = [
        ...(!itemsTagGroups.length
            ? []
            : itemsTagGroups
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((tagGroup) => {
                      return {
                          id: tagGroup.tagGroupId,
                          getValue: () => tagGroup.tagGroupId,
                          label: tagGroup.name,
                          renderCell: (line: T) => {
                              return renderLineTags(line, tagGroup.tagGroupId)
                          },
                      }
                  })),
        ...(tagGroups && itemsTagGroups.length < tagGroups.length
            ? [
                  {
                      id: "newGroup",
                      label: "",
                      getValue: () => "",
                      style: { width: 100 },
                      renderCell: (line: T) => {
                          return renderLineTags(line, undefined, itemsTagGroups)
                      },
                  },
              ]
            : []),
    ]

    const columns: DataTableColumn<T>[] = [
        {
            id: "description",
            key: "description",
            label: formatMessage(messages.description),
            sorter: true,
            style: { maxWidth: 350 },
            renderCell: (line) => (
                <Tooltip title={line.description}>
                    <span className="truncate-text item-description">{line.description}</span>
                </Tooltip>
            ),
        },
        ...tagGroupColumns,
        {
            id: "quantity",
            key: "quantity",
            label: formatMessage(messages.quantity),
            sorter: true,
        },
        {
            id: totalExcludedTaxesField,
            key: totalExcludedTaxesField,
            label: formatMessage(messages.totalExcludedTaxes),
            sorter: true,
            renderCell: (line) =>
                formatNumber(line[totalExcludedTaxesField] as number, {
                    style: "currency",
                    currency: currency ?? "EUR",
                }),
        },
    ]

    if (loading) {
        return (
            <Card>
                <Grid container justifyContent="center">
                    <Loader small />
                </Grid>
            </Card>
        )
    }

    if (error) {
        return <ErrorMessage>{error}</ErrorMessage>
    }

    return (
        <Card className="items analytics-table-container">
            <DataTable hidePagination={true} data={items} columns={columns} />
        </Card>
    )
}
