import * as t from "io-ts"

import { MessageViewIO } from "~/domains/communication/api/adapters/MessageViewFromApiAdapter"
import { MessageContentParagraph, MessageContentTypeI, MessageI } from "~/domains/communication/types"
import { Result, ResultSuccess, isResultSuccess } from "~/types/Result"
import { optional } from "~/types/utils"
import { ParsingErrorType, genericParser } from "~/utils"

export const MessageContentTextIO = t.intersection([
    t.type({
        text: t.string,
    }),
    t.partial({
        children: optional(t.any),
    }),
])

export type MessageContentText = t.TypeOf<typeof MessageContentTextIO> & {
    type?: never
}

const MessageContentIO: t.Type<MessageContentTypeI> = t.recursion("MessageContentIO", () =>
    t.type({
        type: t.string,
        children: t.array(t.union([MessageContentIO, MessageContentTextIO])),
    })
)

const ActionPlanCheckListIO = t.type({
    id: t.string,
    checked: t.boolean,
    dueDate: t.union([t.string, t.null]),
})

const MessageActionPlanItemIO = t.type({
    type: t.string,
    data: ActionPlanCheckListIO,
    children: t.array(MessageContentIO),
})

const MessageActionPlanIO = t.type({
    type: t.string,
    children: t.array(MessageActionPlanItemIO),
})

export const MessageIO = t.intersection([
    t.type({
        id: t.string,
        senderId: t.string,
        createdAt: t.string,
        updatedAt: t.string,
        content: t.array(t.union([MessageContentIO, MessageActionPlanIO])),
    }),
    t.partial({
        isPinned: optional(t.boolean),
        views: optional(t.array(MessageViewIO)),
    }),
])

export const MessageFromApiAdapter = (data: unknown): Result<MessageI, ParsingErrorType> => {
    const result = genericParser(data, MessageIO)
    if (isResultSuccess(result)) {
        return ResultSuccess({
            ...result.result,
            views: result.result.views ?? [],
            isPinned: result.result.isPinned === true,
            content: result.result.content as MessageContentParagraph[],
        })
    }
    return result
}
