export enum PaymentMethodType {
    ACCOUNT_TO_ACCOUNT = "account_to_account",
    BALANCE = "balance",
    BANK_TRANSFER = "bank_transfer",
    BNPL = "bnpl",
    CARD = "card",
    CASH = "cash",
    CHECK = "check",
    DIRECT_DEBIT = "direct_debit",
    GIFT_CARD = "gift_card",
    MOBILE_MONEY = "mobile_money",
    MONEY_ORDER = "money_order",
    WALLET = "wallet",
    TO_APPROVE = "to_approve",
}

export enum PaymentMethodNetwork {
    ACH = "ACH",
    SEPA = "SEPA",
    SWIFT = "SWIFT",
    VARIOUS = "Various",
}

export interface PaymentMethod {
    id: string
    payment_method_type: PaymentMethodType
    name: string
    network: PaymentMethodNetwork | null
}
