import { Alert, Stack, styled } from "@mui/material"
import React from "react"
import { defineMessages, useIntl } from "react-intl"

import { PendingJobItem } from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/PendingJobItem"
import { DocumentJobI } from "~/domains/transactions/invoices/_views/ocr/types"

const messages = defineMessages({
    processingDocumentDiclaimerPart1: {
        id: "transactions.invoices.ocr.processingDocumentDiclaimerPart1",
        defaultMessage: "Your document is being processed, you can continue to use the application.",
    },
    processingDocumentDiclaimerPart2: {
        id: "transactions.invoices.ocr.processingDocumentDiclaimerPart2",
        defaultMessage: "You will get notified once your invoice is ready.",
    },
})

interface Props {
    jobsQueue: DocumentJobI[]
    noDisclaimer?: boolean
}

const QueueContainer = styled(Stack)({
    position: "relative",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    background: "white",
    padding: "var(--spacing-xs) 0",
    fontSize: "var(--font-size-md)",
    backgroundColor: "transparent",
})

export const PendingJobProgressView: React.FC<Props> = ({ jobsQueue, noDisclaimer = false }) => {
    const { formatMessage } = useIntl()
    if (jobsQueue.length === 0) return null

    return (
        <QueueContainer gap="var(--spacing-md)">
            {jobsQueue.map((job) => {
                return <PendingJobItem key={job.jobId} job={job} />
            })}
            {!noDisclaimer && (
                <Alert severity="info">
                    {formatMessage(messages.processingDocumentDiclaimerPart1)}
                    <br />
                    {formatMessage(messages.processingDocumentDiclaimerPart2)}
                </Alert>
            )}
        </QueueContainer>
    )
}
