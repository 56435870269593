import React, { useCallback } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { Button, Loader, Modal, SafeFormattedMessage } from "~/components"
import { CheckFileTypeResult, UploadDocumentBox } from "~/components/UploadDocument/UploadDocument"

import "./ModalsBatchImport.scss"

const messages = defineMessages({
    cancel: { id: "common.cancel", defaultMessage: "Cancel" },
    import: { id: "purchase.requests.modalImportBatch.import", defaultMessage: "Import" },
    loadingMessage: {
        id: "purchase.requests.modalImportBatch.loadingMessage",
        defaultMessage: "Import in progress, it can take up to 20 seconds...",
    },
    formatMessage: {
        id: "purchase.requests.modalImportBatch.formatMessage",
        defaultMessage: "Find more detailed instructions <em>here.</em>",
    },
})

interface Props {
    open: boolean
    close: () => void
    handleFile: (file: File) => void
    checkFileType: (file: File) => CheckFileTypeResult
    handleClickInstructions?: () => void
    loading: boolean
    uploadTip: MessageDescriptor
    title: MessageDescriptor
}

export const ModalBatchImport: React.FC<Props> = ({
    open,
    close,
    handleFile,
    checkFileType,
    handleClickInstructions,
    loading,
    uploadTip,
    title,
}) => {
    const { formatMessage } = useIntl()

    const handleChangeFile = useCallback(
        (file: File) => {
            handleFile(file)
        },
        [handleFile]
    )

    return (
        <Modal open={open} onClose={close} className="size-sm">
            <Modal.Header>
                <h4>{formatMessage(title)}</h4>
            </Modal.Header>
            <Modal.Content>
                {loading ? (
                    <div className="loading-block">
                        <Loader small />
                        <span> {formatMessage(messages.loadingMessage)}</span>
                    </div>
                ) : (
                    <>
                        <UploadDocumentBox
                            handleFile={handleChangeFile}
                            conditionMessage={formatMessage(uploadTip)}
                            checkFileType={checkFileType}
                        />
                        {handleClickInstructions ? (
                            <div className="format-block" onClick={handleClickInstructions}>
                                <SafeFormattedMessage
                                    {...messages.formatMessage}
                                    values={{ em: (msg) => <em>{msg}</em> }}
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </Modal.Content>
            <Modal.Footer>
                <Button type="neutral" onClick={close}>
                    {formatMessage(messages.cancel)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
