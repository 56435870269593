import { MessageDescriptor, defineMessages } from "react-intl"

import {
    WorkflowFilterListOperation,
    WorkflowFilterNumberOperation,
    WorkflowFilterOperation,
    WorkflowFilterReferenceOperation,
    WorkflowFilterStringOperation,
    WorkflowFilterTagsOperation,
    WorkflowFilterUnknownOperation,
} from "~/domains/orchestration/flows-v0/types"

const stringOperationsLabels: Record<WorkflowFilterStringOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterStringOperation.EQUAL]: {
        id: "workflow.operations.EQUAL",
        defaultMessage: "{property} is equal to {value}",
    },
    [WorkflowFilterStringOperation.NOT_EQUAL]: {
        id: "workflow.operations.NOT_EQUAL",
        defaultMessage: "{property} is not equal to {value}",
    },
})

const listOperationsLabels: Record<WorkflowFilterListOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterListOperation.CONTAINS]: {
        id: "workflow.operations.CONTAINS",
        defaultMessage: "{property} contains {value}",
    },
    [WorkflowFilterListOperation.DOES_NOT_CONTAIN]: {
        id: "workflow.operations.DOES_NOT_CONTAIN",
        defaultMessage: "{property} does not contain {value}",
    },
})

const referenceOperationsLabels: Record<WorkflowFilterReferenceOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterReferenceOperation.IS]: {
        id: "workflow.operations.IS",
        defaultMessage: "{property} is {value}",
    },
    [WorkflowFilterReferenceOperation.IS_NOT]: {
        id: "workflow.operations.IS_NOT",
        defaultMessage: "{property} is not {value}",
    },
})

const tagsOperationsLabels: Record<WorkflowFilterTagsOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterTagsOperation.CONTAINS_NONE_OF]: {
        id: "workflow.operations.CONTAINS_NONE_OF",
        defaultMessage: "{property} contains none of {value}",
    },
    [WorkflowFilterTagsOperation.CONTAINS_ANY_OF]: {
        id: "workflow.operations.CONTAINS_ANY_OF",
        defaultMessage: "{property} contains any of {value}",
    },
    [WorkflowFilterTagsOperation.CONTAINS_ALL_OF]: {
        id: "workflow.operations.CONTAINS_ALL_OF",
        defaultMessage: "{property} contains all of {value}",
    },
})

const numberOperationsLabels: Record<WorkflowFilterNumberOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterNumberOperation.EQUAL]: {
        id: "workflow.operations.EQUAL",
        defaultMessage: "{property} is equal to {value}",
    },
    [WorkflowFilterNumberOperation.GREATER_THAN_OR_EQUAL_TO]: {
        id: "workflow.operations.GREATER_OR_EQUAL_THAN",
        defaultMessage: "{property} is greater than or equal to {value}",
    },
    [WorkflowFilterNumberOperation.LESS_THAN_OR_EQUAL_TO]: {
        id: "workflow.operations.LESS_THAN_OR_EQUAL_TO",
        defaultMessage: "{property} is lower than or equal to {value}",
    },
    [WorkflowFilterNumberOperation.LESS_THAN]: {
        id: "workflow.operations.LESS_THAN",
        defaultMessage: "{property} is lower than {value}",
    },
    [WorkflowFilterNumberOperation.GREATER_THAN]: {
        id: "workflow.operations.GREATER_THAN",
        defaultMessage: "{property} is greater than {value}",
    },
    [WorkflowFilterNumberOperation.DOES_NOT_EQUAL]: {
        id: "workflow.operations.IS_NOT",
        defaultMessage: "{property} is not {value}",
    },
})

// const dateOperationsLabels: Record<WorkflowFilterDateOperation, MessageDescriptor> = defineMessages({
//     [WorkflowFilterDateOperation.OLDER_THAN]: {
//         id: "workflow.operations.OLDER_THAN",
//         defaultMessage: "{property} is older than {value}",
//     },
//     [WorkflowFilterDateOperation.WEEKDAY_IS]: {
//         id: "workflow.operations.WEEKDAY_IS",
//         defaultMessage: "{property} weekday is equal to {value}",
//     },
// })

const unknownOperationsLabels: Record<WorkflowFilterUnknownOperation, MessageDescriptor> = defineMessages({
    [WorkflowFilterUnknownOperation.UNKNOWN]: {
        id: "workflow.operations.UNKNOWN",
        defaultMessage: "{property}: {value}",
    },
})

const operationsLabels: Record<WorkflowFilterOperation, MessageDescriptor> = {
    ...stringOperationsLabels,
    ...listOperationsLabels,
    ...referenceOperationsLabels,
    ...tagsOperationsLabels,
    ...numberOperationsLabels,
    // ...dateOperationsLabels,
    ...unknownOperationsLabels,
}
export const getOperationLabel = (operation: WorkflowFilterOperation): MessageDescriptor => operationsLabels[operation]
