export const PARTNERS_ROUTE = "/partners/:view"
export const PARTNERS_ROUTE_DEFAULT = "/partners"
export const PARTNER_DETAILS_ROUTE = "/partners/organization/:organizationId"
export const PARTNER_INVOICES_ROUTE = "/partners/organization/:organizationId/invoices"
export const PARTNER_PURCHASE_ORDERS_ROUTE = "/partners/organization/:organizationId/purchase-orders"
export const PARTNER_ADDRESSES_ROUTE = "/partners/organization/:organizationId/addresses"
export const PARTNER_CONTACTS_ROUTE = "/partners/organization/:organizationId/contacts"
export const PARTNER_PAYMENT_DETAILS_ROUTE = "/partners/organization/:organizationId/payment-details"
export const PARTNER_SENT_FORMS_ROUTE = "/partners/organization/:organizationId/questionnaires"
export const PARTNER_KYB_ROUTE = "/partners/organization/:organizationId/kyb"
