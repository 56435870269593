import { useState } from "react"

import { useVerpApi } from "~/domains/orchestration/verp/api/verpApi"

export const usePushInvoicePennylaneSynchronization = (invoiceId: string) => {
    const verpAi = useVerpApi()
    const [loading, setLoading] = useState<boolean>(false)

    const pushInvoicePennylaneSynchronization = async (): Promise<number | undefined> => {
        setLoading(true)
        try {
            return await verpAi.pushInvoicePennylaneSynchronization(invoiceId)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return { loading, pushInvoicePennylaneSynchronization }
}
