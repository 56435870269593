import * as t from "io-ts"

import { CountryCode, validateCountryCodeOrSetUnknown } from "~/types"
import { Result, ResultError, ResultSuccess, isResultError } from "~/types/Result"
import { ParsingErrorType, genericParser } from "~/utils"

const TransactionVatRateIO = t.type({
    id: t.string,
    label: t.string,
    rate: t.string,
    country: t.string,
})

export type TransactionVatRateI = Omit<Omit<t.TypeOf<typeof TransactionVatRateIO>, "country">, "rate"> & {
    country: CountryCode
    rate: number
}

export const parseTransactionVatRate = (data: unknown): Result<TransactionVatRateI, ParsingErrorType> => {
    const result = genericParser(data, TransactionVatRateIO)
    if (isResultError(result)) return result
    const decodedData = result.result
    const rate = parseFloat(decodedData.rate)
    if (isNaN(rate)) return ResultError<ParsingErrorType>({ data, errorsKey: ["rate"], error: "rate is not a number" })
    return ResultSuccess({
        ...decodedData,
        rate,
        country: validateCountryCodeOrSetUnknown(decodedData.country),
    })
}
