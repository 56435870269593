import { Grid, Slider, Typography } from "@mui/material"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Modal, SafeFormattedMessage } from "~/components"
import { EditAmount } from "~/domains/transactions/invoices/_views/supplier/components"
import { sleep, stopPropagation } from "~/utils"

import { SelectedTagI, TagGroupI } from "../../types"

interface Props {
    tags: SelectedTagI[] | null
    tagGroup?: TagGroupI
    close: () => void
    onSave: (tags: SelectedTagI[]) => void
}

const messages = defineMessages({
    title: {
        id: "tags.TagSelector.ModalSetRatio.title",
        defaultMessage: "Set ratios for tags in {tagGroupName}",
    },
})

const getRatioPerTagId = (acc: Record<string, number>, tag: SelectedTagI): Record<string, number> => {
    acc[tag.tagId] = 100 * (tag.ratio ?? 0)
    return acc
}

export const ModalSetTagsRatio: React.FC<Props> = ({ tags, tagGroup, close, onSave }) => {
    const { formatMessage, formatNumber } = useIntl()
    const [values, setValues] = useState<Record<string, number>>(tags ? tags.reduce(getRatioPerTagId, {}) : {})
    const [inputFocus, setInputFocus] = useState<string | null>(null)
    const ensureTotalIs100 = useRef<number>()
    useEffect(() => {
        if (tags) {
            setValues(tags.reduce(getRatioPerTagId, {}))
        }
    }, [tags])
    const save = useCallback(() => {
        if (tags) {
            onSave(
                tags.map((tag) => ({
                    ...tag,
                    ratio: values[tag.tagId] / 100,
                }))
            )
        }
    }, [onSave, values, tags])
    const handleSliderChange = useCallback((tag: SelectedTagI, newValue: number) => {
        setValues((currentValues) => {
            if (ensureTotalIs100.current) {
                window.cancelAnimationFrame(ensureTotalIs100.current)
            }
            const currentAnimationFrame = window.requestAnimationFrame(async () => {
                await sleep(100)
                if (ensureTotalIs100.current !== currentAnimationFrame) return
                const otherTags = Object.keys(currentValues)
                const totalOtherTags = otherTags.reduce(
                    (total, otherTagId) => (otherTagId === tag.tagId ? total : total + currentValues[otherTagId]),
                    0
                )
                const totalForOtherTags = 100 - newValue
                const result = otherTags.reduce(
                    (acc, otherTag) => {
                        if (otherTag === tag.tagId) {
                            acc[tag.tagId] = newValue
                        } else {
                            if (acc[otherTag] <= 0 && totalForOtherTags > 0) {
                                acc[otherTag] = totalForOtherTags / otherTags.length
                            } else {
                                acc[otherTag] = (acc[otherTag] * totalForOtherTags) / totalOtherTags
                            }
                        }
                        return acc
                    },
                    { ...currentValues }
                )
                setValues(result)
            })
            ensureTotalIs100.current = currentAnimationFrame

            return { ...currentValues, [tag.tagId]: newValue }
        })
    }, [])
    const onInputFocus = useCallback((tag: SelectedTagI) => {
        setInputFocus(tag.tagId)
    }, [])
    const onInputBlur = useCallback((tag: SelectedTagI) => {
        setInputFocus((currentFocus) => (currentFocus === tag.tagId ? null : currentFocus))
    }, [])
    return (
        <Modal open={!!tagGroup} onClose={close} onClick={stopPropagation}>
            <Modal.Header>
                <h4>
                    {tagGroup && <SafeFormattedMessage {...messages.title} values={{ tagGroupName: tagGroup.name }} />}
                </h4>
            </Modal.Header>
            <Modal.Content>
                {tags?.map((tag) => (
                    <div key={tag.tagId}>
                        <Typography>{tag.name}</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={10}>
                                <Slider
                                    value={values[tag.tagId] ?? 0}
                                    onChange={(_event, newValue) => handleSliderChange(tag, newValue as number)}
                                    aria-labelledby="input-slider"
                                    min={0}
                                    max={100}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <EditAmount
                                    value={values[tag.tagId] ?? 0}
                                    size="small"
                                    className="modal-set-tags-ratio-input"
                                    disableHelpers
                                    renderer={(value: number) =>
                                        formatNumber((value ?? 0) / 100, {
                                            style: "percent",
                                            maximumFractionDigits: 0,
                                        })
                                    }
                                    handleUpdate={(value) => {
                                        if (value !== null && !isNaN(value)) {
                                            handleSliderChange(tag, value)
                                        }
                                    }}
                                    onFocus={() => onInputFocus(tag)}
                                    onBlur={() => onInputBlur(tag)}
                                    inputProps={
                                        inputFocus === tag.tagId
                                            ? {
                                                  step: 1,
                                                  min: 0,
                                                  max: 100,
                                                  type: "number",
                                                  "aria-labelledby": "input-slider",
                                              }
                                            : {}
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                ))}
                <div style={{ height: 10 }}></div>
            </Modal.Content>
            <Modal.Footer>
                <Button type="neutral" onClick={close}>
                    {formatMessage(commonMessages.cancel)}
                </Button>
                <Button type="error" buttonType="submit" onClick={save}>
                    {formatMessage(commonMessages.save)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
