import { Grid, List, ListItem, Stack, Tooltip } from "@mui/material"
import { FC, Fragment } from "react"
import { Edit2, Plus, Trash2 } from "react-feather"

import { Button, SafeFormattedMessage } from "~/components"
import { CodeTypography } from "~/components/CodeTypography/CodeTypography"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { messages } from "~/domains/orchestration/flows/messages"

import type { ConditionGroup, Condition as ConditionType } from "./BranchConfiguration"

interface Props {
    group: ConditionGroup
    handleChangeConditions: (group: ConditionGroup) => void
    handleNewCondition: (group: ConditionGroup) => void
    handleEditCondition: (condition: ConditionType) => void
}

export const Condition: FC<Props> = ({ group, handleChangeConditions, handleNewCondition, handleEditCondition }) => {
    const handleDelete = (item: ConditionType) => () => {
        const newConditions = group.conditions.filter((c) => c.id !== item.id)
        handleChangeConditions({
            id: group.id,
            conditions: newConditions,
        })
    }

    const handleAddCondition = () => {
        handleNewCondition(group)
    }

    const handleEdit = (item: ConditionType) => () => handleEditCondition(item)

    const conditionsList = group.conditions.map((item, index) => (
        <Fragment key={item.id}>
            {index !== 0 && (
                <ListItem>
                    <SafeFormattedMessage tagName="h5" {...messages.eventTriggerConfiguration.andConditionOtherLabel} />
                </ListItem>
            )}
            <ListItem>
                <Grid container alignItems="center" spacing={0.5}>
                    <Grid item xs={10}>
                        <Tooltip title={item.condition} placement="left">
                            <CodeTypography noWrap>{item.condition}</CodeTypography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                        <Stack direction="row" gap={0.5}>
                            <IconButton onClick={handleEdit(item)} type="grey-light" size="small">
                                <Edit2 size={12} color="var(--color-grey)" />
                            </IconButton>

                            <IconButton onClick={handleDelete(item)} type="grey-light" size="small">
                                <Trash2 size={12} color="var(--color-grey)" />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            </ListItem>
        </Fragment>
    ))

    return (
        <Stack gap={1} className="flows-eventTriggerConfiguration-andConditions">
            <List>{conditionsList}</List>
            <Stack direction="column" gap={1} className="flows-eventTriggerConfiguration-newConditionSuggestions">
                <Button
                    type="grey-light"
                    onClick={handleAddCondition}
                    className="flows-eventTriggerConfiguration-newCondition"
                >
                    <Plus size={18} />
                    <SafeFormattedMessage {...messages.eventTriggerConfiguration.newConditionLabel} />
                </Button>
            </Stack>
        </Stack>
    )
}
