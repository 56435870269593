import { Box } from "@mui/material"
import { ChangeEvent, FC, FormEvent, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button } from "~/components"
import TrimmedTextField from "~/components/Form/TrimmedTextField"

const messages = defineMessages({
    add: {
        id: "buyer.invoice.involvedPeople.add.",
        defaultMessage: "Add email@your-company.fr",
    },
    invite: {
        id: "buyer.invoice.involvedPeople.invite",
        defaultMessage: "Invite",
    },
})

interface AddPeopleProps {
    addInvolvedPeople: (people: string) => void
}

export const AddPeople: FC<AddPeopleProps> = ({ addInvolvedPeople }) => {
    const { formatMessage } = useIntl()
    const [email, setEmail] = useState<string>("")
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        addInvolvedPeople(email)
        setEmail("")
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="center" gap={1}>
                <TrimmedTextField
                    required={true}
                    id="add-people"
                    name="add-people"
                    type="email"
                    placeholder={formatMessage(messages.add)}
                    value={email}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                />
                <Button onClick={() => addInvolvedPeople(email)} size="small">
                    <>{formatMessage(messages.invite)}</>
                </Button>
            </Box>
        </form>
    )
}
