import { MessageDescriptor, defineMessages } from "react-intl"

import { InvoiceLineErrorType } from "~/domains/transactions/invoices/core"

export const errorMessages: Record<InvoiceLineErrorType, MessageDescriptor> = defineMessages({
    [InvoiceLineErrorType.TotalDoesNotMatchComputedTotal]: {
        id: "invoice.invoiceLines.error.TotalDoesNotMatchComputedTotal",
        defaultMessage: "Total does not match computed total: {computedValueError} (expecting {referenceValue})",
    },
    [InvoiceLineErrorType.UnitPriceAndQuantityDoesNotMatchTotalExcludedTaxes]: {
        id: "invoice.invoiceLines.error.UnitPriceAndQuantityDoesNotMatchTotalExcludedTaxes",
        defaultMessage: "Please correct the total excluding taxes: {referenceValue} (should be {computedValueError})",
    },
    [InvoiceLineErrorType.TotalDoesNotTakeChargeOfDiscount]: {
        id: "invoice.invoiceLines.error.TotalDoesNotTakeChargeOfDiscount",
        defaultMessage:
            "Please correct the total to take the discount into account: {referenceValue} (should be {computedValueError})",
    },
})
