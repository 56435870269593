import { Box } from "@mui/material"
import React from "react"

import { ItemLabel, SafeFormattedMessage } from "~/components"

interface Props {
    requesterName?: string
}

export function RequesterName({ requesterName }: Props) {
    return (
        <Box component="div" className="requester-name">
            <ItemLabel>
                <SafeFormattedMessage id="purchase.requesterName" defaultMessage="Creator" />
            </ItemLabel>
            <span>{requesterName ?? "-"}</span>
        </Box>
    )
}
