import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    SxProps,
    TextField,
    TextFieldVariants,
    Theme,
    createFilterOptions,
} from "@mui/material"
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AllCountries, CountryCode, getCountryName, isValidCountryCode } from "~/types"

const AllCountriesAndUnknown = [CountryCode.UNKNOWN, ...AllCountries]

const messages = defineMessages({
    selectCountry: {
        id: "company.selectCountry",
        defaultMessage: "Select country",
    },
})

const getCountryFlag = (countryCode: CountryCode) =>
    isValidCountryCode(countryCode) ? (
        <img
            loading="lazy"
            width="24"
            src={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.webp`}
            srcSet={`https://flagcdn.com/w80/${countryCode.toLowerCase()}.webp 2x`}
            alt=""
            style={{ borderRadius: "4px" }}
        />
    ) : null

const boxSxProps: SxProps<Theme> = {
    "& > img": { mr: 2, flexShrink: 0 },
}
const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: CountryCode) =>
    option === CountryCode.UNKNOWN ? (
        <Box component="li" sx={boxSxProps} {...props}>
            <SafeFormattedMessage {...messages.selectCountry} />
        </Box>
    ) : (
        <Box component="li" sx={boxSxProps} {...props}>
            {getCountryFlag(option)}
            {getCountryName(option)} ({option})
        </Box>
    )

const filterOptions = createFilterOptions({
    stringify: (option: CountryCode) => (option === CountryCode.UNKNOWN ? "" : `${option} ${getCountryName(option)}`),
})

interface CountrySelectorProps {
    value: CountryCode | null
    onChange: (value: CountryCode | null) => void
    variant?: TextFieldVariants
    label?: string
    required?: boolean
    readonly?: boolean
    disabled?: boolean
    error?: boolean
    helperText?: React.ReactNode
}

export const CountrySelector: React.FC<CountrySelectorProps> = ({
    value,
    onChange,
    variant = "outlined",
    label,
    error,
    helperText,
    disabled = false,
    required = false,
    readonly = false,
}) => {
    const { formatMessage } = useIntl()
    const [inputText, setInputText] = useState<string>(value || "")
    const [renderedValue, setRenderedValue] = useState<CountryCode | null>(value || null)

    const onInputChange = useCallback((_event: SyntheticEvent, value: string) => setInputText(value), [])

    useEffect(() => {
        if (!value) return

        setRenderedValue(value)
    }, [value])

    const handleChange = useCallback(
        (_event: unknown, value: CountryCode | null) => {
            if (!value) return

            setRenderedValue(value)
            onChange(value)
        },
        [onChange]
    )

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => {
            const { value } = params.inputProps
            const isUnknownCountry = value === CountryCode.UNKNOWN

            return (
                <TextField
                    {...params}
                    className="company-autocomplete-country-selector"
                    variant={variant}
                    error={error}
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: isUnknownCountry ? undefined : getCountryFlag(renderedValue as CountryCode),
                        autoComplete: "new-password",
                    }}
                    inputProps={{
                        ...params.inputProps,
                    }}
                    label={isUnknownCountry ? formatMessage(messages.selectCountry) : label}
                    required={required}
                    disabled={disabled}
                />
            )
        },
        [variant, error, helperText, label, required, disabled, renderedValue]
    )
    return (
        <Autocomplete
            className={`${renderedValue === CountryCode.UNKNOWN ? "fullsize" : ""}`}
            value={renderedValue ?? undefined}
            inputValue={inputText}
            onInputChange={onInputChange}
            options={AllCountriesAndUnknown}
            filterOptions={filterOptions}
            disableClearable
            readOnly={readonly}
            disabled={disabled}
            onChange={handleChange}
            autoHighlight
            renderOption={renderOption}
            renderInput={renderInput}
            fullWidth
        />
    )
}
