import { useEffect } from "react"

import { TagsSelectorForLineCells } from "~/domains/tags/components/TagsSelector/TagsSelectorForLineCells"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { TagGroupI, TagObjectType } from "~/domains/tags/types"
import { PurchaseViewType } from "~/domains/transactions/_shared/types"
import { ItemsInvoice } from "~/domains/transactions/components/Items/ItemsInvoice"
import { useTagsForLines } from "~/domains/transactions/components/Items/hooks/useTagsForLines"
import { LinesTabs } from "~/domains/transactions/custom-fields/components/LinesTabs"
import { CustomFieldObjectType } from "~/domains/transactions/custom-fields/types/CustomFieldObjectType"
import { CurrencyCodes, InvoiceI, InvoiceLineI } from "~/types"

interface InvoiceItemsProps {
    invoice: InvoiceI
}

export const InvoiceItems = ({ invoice }: InvoiceItemsProps) => {
    const { tagGroups } = useOrganizationTagGroups(invoice.payerId)
    const {
        selectedTags: tags,
        setSelectedTags: setTags,
        fetchTags,
    } = useTagsForLines({
        lines: invoice.lineItems,
        organizationId: invoice.payerId,
        tagGroups,
        objectType: TagObjectType.INVOICE_LINE,
    })

    useEffect(() => {
        if (!tagGroups?.length) return
        fetchTags()
    }, [tagGroups])

    const renderLineTags = (line: InvoiceLineI, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => (
        <TagsSelectorForLineCells
            objectId={line.id}
            tags={tags}
            setTags={setTags}
            tagGroups={tagGroups ?? []}
            tagGroupId={tagGroupId ?? ""}
            usedTagGroups={usedTagGroups ?? []}
        />
    )

    return (
        <LinesTabs
            items={invoice.lineItems}
            idKey="id"
            contextType={CustomFieldObjectType.INVOICE}
            contextId={invoice.id}
            currency={invoice.tax?.[0]?.currency || CurrencyCodes.EUR}
            organizationId={invoice.payerId}
            renderLineTags={renderLineTags}
        >
            <ItemsInvoice invoice={invoice} mode={PurchaseViewType.VIEW} />
        </LinesTabs>
    )
}
