import { IconButton } from "@mui/material"
import { useRef } from "react"
import { Paperclip } from "react-feather"

import { useCopilotUploadDocument } from "~/domains/copilot/prompt/hooks/useCopilotUploadDocument"

const ACCEPTED_FILE_TYPES = ["application/pdf", "csv/*"]
const ACCEPTED_FILES = ACCEPTED_FILE_TYPES.join(",")

interface CopilotUploadDocumentProps {
    onUploadSuccess: (documentContent: string) => void
}

export const CopilotUploadDocument = ({ onUploadSuccess }: CopilotUploadDocumentProps) => {
    const inputFileRef = useRef<HTMLInputElement>(null)
    const { uploadDocument } = useCopilotUploadDocument()

    const handleClickUploadFile = () => {
        inputFileRef.current?.click()
    }

    const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e?.target?.files) return
        const uploadedFile = e.target.files[0]
        if (!uploadedFile) return

        try {
            const result = await uploadDocument(uploadedFile)
            onUploadSuccess(result)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <IconButton onClick={handleClickUploadFile}>
            <Paperclip />
            <input
                type="file"
                name="file"
                className="upload-document-file"
                accept={ACCEPTED_FILES}
                ref={inputFileRef}
                onChange={handleUploadFile}
            />
        </IconButton>
    )
}
