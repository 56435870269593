import { Stack } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { X } from "react-feather"
import { useLocation } from "react-router-dom"

import { SafeFormattedMessage } from "~/components"
import { LeftChevronIcon } from "~/components/Icons"
import { useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { messages } from "~/domains/orchestration/flows/messages"
import { FlowId, RunId, RunItem } from "~/domains/orchestration/flows/types"

import { IconButton } from "../IconButton"
import { RunExplorerItem } from "./RunExplorerItem"
import { RunExplorerList } from "./RunExplorerList"
import "./RunExplorerSideBar.scss"

interface Props {
    handleClose: () => void
    flowId: FlowId
}

export const RunExplorerSideBar: FC<Props> = ({ handleClose, flowId }) => {
    const location = useLocation()
    const { state } = location
    const { runId = null } = state || {}
    const [currentRunId, setCurrentRunId] = useState<RunId | null>(runId)

    const dispatch = useEditorDispatch()
    const isRunSelected = currentRunId !== null

    const handleClickBack = () => {
        setCurrentRunId(null)
    }

    useEffect(() => {
        if (!isRunSelected) {
            dispatch({ type: "SET_RUN", payload: null })
        }
    }, [dispatch, isRunSelected])

    const handleClickRow = (run: RunItem) => {
        setCurrentRunId(run.id)
    }

    return (
        <div className="flows-run-explorer-sideBar">
            <Stack
                className="flows-run-explorer-sideBar-header"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                {isRunSelected ? (
                    <IconButton type="close" onClick={handleClickBack}>
                        <LeftChevronIcon size={14} color="var(--primary-color)" strokeWidth="3" />
                    </IconButton>
                ) : null}
                <SafeFormattedMessage tagName="h4" {...messages.sideBar.explorerTitle} />
                <IconButton type="close" onClick={handleClose}>
                    <X size={14} />
                </IconButton>
            </Stack>

            {isRunSelected ? (
                <RunExplorerItem runId={currentRunId} />
            ) : (
                <RunExplorerList handleClickRow={handleClickRow} flowId={flowId} />
            )}
        </div>
    )
}
