import { Users } from "react-feather"

import { CreateSurveyConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { CreateSurveyNode } from "~/domains/orchestration/flows/components/nodes"
import { CreateSurveyNode as CreateSurveyNodeType, Event, Node, NodeType } from "~/domains/orchestration/flows/types/"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"

import { createCommonNodeProperties } from "./commonConfig"

const createSurveyNode = (eventTriggerEventType: Event | null): Omit<CreateSurveyNodeType, keyof Node> => ({
    nextNode: null,
    formId: "",
    type: NodeType.CREATE_SURVEY_NODE,
    respondentOrganizationId:
        eventTriggerEventType === Event.PARTNERSHIP_CREATED ? "{{ trigger_event.partnerId }}" : null,
})

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.FORM_ID,
    AllAdvancedFieldsUnion.RESPONDENT_ORGANIZATION_ID,
]

const createSurveyConfig: Configuration<NodeType.CREATE_SURVEY_NODE> = {
    type: NodeType.CREATE_SURVEY_NODE,
    baseSlug: "create_survey",
    Icon: Users,
    Configuration: CreateSurveyConfiguration,
    advancedFields,
    Node: CreateSurveyNode,
    factory: (props: FactoryProps): CreateSurveyNodeType => ({
        ...createSurveyNode(props.eventTriggerEventType),
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { createSurveyConfig, advancedFields as createSurveyAdvancedFields }
