import { FC } from "react"

import { ApprovalBlock } from "~/domains/orchestration/flows-v0/components/ApprovalBlock"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceI } from "~/types"

interface InvoiceApprovalProps {
    invoice: InvoiceI
}

export const InvoiceApproval: FC<InvoiceApprovalProps> = ({ invoice }) => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)

    if (!organizationId) {
        return null
    }

    return (
        <ApprovalBlock organisationId={organizationId} objectId={invoice.id} objectType={ApprovalObjectType.INVOICE} />
    )
}
