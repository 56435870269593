import { Button, Popover, Stack, styled } from "@mui/material"
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { FC, SyntheticEvent, useCallback, useRef, useState } from "react"
import { Clock } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { Editor, Element as SlateElement, Transforms } from "slate"

import { DateComponent } from "~/components/Date"
import { isElementActionPlanItem } from "~/domains/communication/components/InputMessage/ActionPlan/isElementActionPlanItem"
import { ActionPlanCheckListI, MessageActionPlanItem } from "~/domains/communication/types"

const messages = defineMessages({
    setDueDate: {
        id: "communications.actionPlan.setDueDate",
        defaultMessage: "Set due date",
    },
    dueDateEditableAfterPublication: {
        id: "communications.actionPlan.dueDateEditableAfterPublication",
        defaultMessage: "You will be able to set the due date after publication",
    },
})

const DueDateContainer = styled(Stack)(({ theme }) => ({
    whiteSpace: "nowrap",
    fontSize: "var(--font-size-md)",
    fontWeight: "var(--font-weight-lg)",
    fill: theme.palette.grey[900],
}))

const StyledButton = styled(Button)({
    paddingY: 0,
    height: "24px",
    textTransform: "none",
    fontWeight: "var(--font-weight-lg)",
    color: "var(--color-violet-dark)",
})

type BaseProps = {
    element: MessageActionPlanItem
}

type PropsWithMessage = BaseProps & {
    editor?: never
    updateActionPlanItem: (updateData: Partial<ActionPlanCheckListI>) => Promise<void>
}

type PropsWithEditor = BaseProps & {
    editor: Editor
    updateActionPlanItem?: never
}

type Props = PropsWithMessage | PropsWithEditor

export const ActionPlanItemDueDate: FC<Props> = ({ element, editor, updateActionPlanItem }) => {
    const { formatMessage, locale } = useIntl()
    const [dueDate, setDueDate] = useState(() => (element.data.dueDate ? dayjs(element.data.dueDate) : null))
    const [openDueDatePicker, setOpenDueDatePicker] = useState(false)

    const dueDateContainerRef = useRef<HTMLDivElement | null>(null)

    const onDueDateClick = useCallback((event: SyntheticEvent) => {
        event.stopPropagation()
        setOpenDueDatePicker(true)
    }, [])

    const onDueDateChange = useCallback(
        (date: Dayjs | null) => {
            setDueDate(date)
            const newDueDateValue = date ? date.toISOString() : null
            setOpenDueDatePicker(false)
            if (editor) {
                Transforms.setNodes(
                    editor,
                    {
                        ...element,
                        data: {
                            ...element.data,
                            dueDate: newDueDateValue,
                        },
                    },
                    {
                        at: [],
                        mode: "all",
                        match: (n) =>
                            SlateElement.isElement(n) && isElementActionPlanItem(n) && n.data.id === element.data.id,
                    }
                )
            } else {
                updateActionPlanItem({ dueDate: newDueDateValue })
            }
        },
        [element, editor, updateActionPlanItem]
    )
    return (
        <>
            <DueDateContainer
                direction="row"
                gap={0.5}
                alignItems="center"
                ref={dueDateContainerRef}
                onClick={onDueDateClick}
                contentEditable={false}
            >
                {dueDate ? (
                    <>
                        <Clock size={16} />
                        <DateComponent value={dueDate.toISOString()} />
                    </>
                ) : (
                    <StyledButton size="small" contentEditable={false} startIcon={<Clock size={16} />}>
                        {formatMessage(messages.setDueDate)}
                    </StyledButton>
                )}
            </DueDateContainer>
            <Popover
                open={openDueDatePicker}
                anchorEl={dueDateContainerRef.current}
                onClose={() => setOpenDueDatePicker(false)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                    <StaticDatePicker
                        defaultValue={dayjs()}
                        onChange={onDueDateChange}
                        onAccept={() => setOpenDueDatePicker(false)}
                        slotProps={{
                            actionBar: {
                                actions: [],
                            },
                        }}
                    />
                </LocalizationProvider>
            </Popover>
        </>
    )
}
