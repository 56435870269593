import * as t from "io-ts"

import { ResultSuccess, getResultSuccessValue, isResultSuccess } from "~/types/Result"
import { optional } from "~/types/utils"
import { genericParser } from "~/utils"

import { FolderSummaryI, FolderSummaryIO, parseFolderSummary } from "./Folder"

const DocumentVersionIO = t.intersection([
    t.type({
        createdAt: t.string,
        updatedAt: t.string,
        versionNumber: t.number,
        documentId: t.string,
        fileSize: t.number,
        id: t.string,
        isInQuarantine: t.boolean,
        isScanned: t.boolean,
        originFileName: t.string,
    }),
    t.partial({
        pages: optional(t.number),
        scannedResults: optional(t.unknown),
    }),
])

export const DocumentIO = t.intersection([
    t.type({
        id: t.string,
        maxVersions: t.number,
        createdAt: t.string,
        updatedAt: t.string,
    }),
    t.partial({
        latestFileVersion: optional(t.string),
        description: optional(t.string),
        documentType: optional(t.string),
        name: optional(t.string),
    }),
])

const DocumentDetailsIO = t.intersection([
    DocumentIO,
    t.type({
        versions: t.array(DocumentVersionIO),
    }),
])

export const PaginatedDocumentsResultIO = t.type({
    documents: t.array(DocumentIO),
    folders: t.array(FolderSummaryIO),
    page: t.number,
    page_size: t.number,
    total_documents: t.number,
    total_pages: t.number,
})

export type DocumentI = t.TypeOf<typeof DocumentIO>
export type DocumentDetailsI = t.TypeOf<typeof DocumentDetailsIO>
export type DocumentVersionI = t.TypeOf<typeof DocumentVersionIO>
export type PaginatedDocumentsResultI = t.TypeOf<typeof PaginatedDocumentsResultIO> & {
    folders: FolderSummaryI[]
    documents: DocumentI[]
}

export const parseDocument = (data: unknown) => {
    return genericParser<DocumentI>(data, DocumentIO)
}

export const parseDocumentsResults = (data: unknown) => {
    const result = genericParser(data, PaginatedDocumentsResultIO)
    if (isResultSuccess(result)) {
        return ResultSuccess<PaginatedDocumentsResultI>({
            ...result.result,
            documents: result.result.documents.map(parseDocument).filter(isResultSuccess).map(getResultSuccessValue),
            folders: result.result.folders.map(parseFolderSummary).filter(isResultSuccess).map(getResultSuccessValue),
        })
    }
    return result
}

export const parseDocumentDetails = (data: unknown) => {
    return genericParser<DocumentDetailsI>(data, DocumentDetailsIO)
}

export const parseDocumentVersion = (data: unknown) => {
    return genericParser<DocumentVersionI>(data, DocumentVersionIO)
}
