import { styled } from "@mui/material"

import { Button } from "~/components"

const commonStyles = {
    cursor: "pointer",
    borderRadius: "var(--border-radius-sm)",
    border: "1px solid var(--color-border-flows)",
    boxShadow: "none !important",
    padding: "0 !important",
}

const sizes = {
    small: "24px",
    medium: "36px",
}

export const IconButton = styled(Button)(({ size = "medium" }) => ({
    ...commonStyles,
    height: sizes[size],
    width: sizes[size],
    minWidth: sizes[size],
    minHeight: sizes[size],
}))
