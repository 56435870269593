import { Tooltip } from "@mui/material"
import React from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, Loader, ProgressBar } from "~/components"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"
import { useProcessBulkReviewItems } from "~/domains/orchestration/flows-v0/core/hooks/useProcessBulkReviewItems"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"
import { usePurchaseRequestValidationActions } from "~/domains/transactions/purchase-requests/store/hooks"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

const messages = defineMessages({
    validate: {
        id: "purchase.requests.request.validate",
        defaultMessage: "Validate",
    },
    refuse: {
        id: "purchase.requests.request.reject",
        defaultMessage: "Refuse",
    },
    checkDone: {
        id: "purchase.requests.checkDone",
        defaultMessage: "Check done {checkDone}/{totalChecks}",
    },
    waitingForApproval: {
        id: "purchase.requests.waitingForOtherApproval",
        defaultMessage: "Waiting for {peopleNames} approval",
    },
    title: {
        id: "purchase.requests.request.actions.title",
        defaultMessage: "Actions",
    },
})

interface Props {
    purchaseRequest: PurchaseRequestDetails
}

export const SubmittedPRActions: React.FC<Props> = ({ purchaseRequest }) => {
    const { formatMessage } = useIntl()
    const {
        loading,
        withinWorkflow,
        checkDone,
        totalChecks,
        currentUserCanValidate,
        currentUserApproval,
        reviewers,
        approve,
        refuse,
    } = usePurchaseRequestValidationActions(purchaseRequest)
    const approvePermission = purchaseRequest.permissions?.approve
    const canApprove = (approvePermission && typeof approvePermission !== "undefined") ?? false
    const permissionLabel = !canApprove ? formatMessage(permissionMessages.errorNoAccessAdministrator) : ""

    const { processBulkReviewItems } = useProcessBulkReviewItems()

    const handleReview = async (isApprove: boolean) => {
        if (purchaseRequest) {
            await processBulkReviewItems(
                purchaseRequest.lines.map((line) => line.id || ""),
                isApprove,
                ApprovalObjectType.PURCHASE_REQUEST_LINE
            )
        }

        if (isApprove && approve && canApprove) {
            approve()
        } else if (!isApprove && refuse && canApprove) {
            refuse()
        }
    }

    const handleRefuse = () => {
        handleReview(false)
    }

    const handleApprove = () => {
        handleReview(true)
    }

    if (loading) {
        return (
            <div className="actions">
                <Loader small />
            </div>
        )
    }
    return (
        <>
            <h4 className="header-actions">{formatMessage(messages.title)}</h4>
            {withinWorkflow && (
                <ProgressBar values={[{ percentage: checkDone / totalChecks, color: "var(--primary-color)" }]}>
                    {formatMessage(messages.checkDone, {
                        checkDone,
                        totalChecks,
                    })}
                </ProgressBar>
            )}
            {currentUserCanValidate ? (
                <div className="actions">
                    <Tooltip arrow title={permissionLabel}>
                        <span>
                            <Button
                                type="error"
                                className="reject"
                                disabled={!canApprove || currentUserApproval === false}
                                onClick={handleRefuse}
                            >
                                {formatMessage(messages.refuse)}
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip arrow title={permissionLabel}>
                        <span className="flex-1">
                            <Button
                                type="primary"
                                className="validate"
                                disabled={!canApprove || currentUserApproval === true}
                                onClick={handleApprove}
                            >
                                {formatMessage(messages.validate)}
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            ) : (
                withinWorkflow && (
                    <div>
                        {formatMessage(messages.waitingForApproval, {
                            peopleNames: reviewers.map((reviewer) => reviewer.fullName).join(", "),
                        })}
                    </div>
                )
            )}
        </>
    )
}
