import { XYPosition } from "@xyflow/react"

import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import {
    addToBudgetAdvancedFields,
    approvePurchaseOrderAdvancedFields,
    approvePurchaseOrderLineAdvancedFields,
    approvePurchaseRequestAdvancedFields,
    approvePurchaseRequestLineAdvancedFields,
    assignTagAdvancedFields,
    branchAdvancedFields,
    checkAdvancedFields,
    convertPrToPoAdvancedFields,
    createSurveyAdvancedFields,
    eventTriggerAdvancedFields,
    fetchCustomFieldsAdvancedFields,
    fitsToBudgetAdvancedFields,
    ifAdvancedFields,
    invoiceToPurchaseOrderMatchAdvancedFields,
    retractReviewsAdvancedFields,
    sendEmailAdvancedFields,
    setInvoiceLifecycleStatusAdvancedFields,
    setPartnershipFieldAdvancedFields,
    setPaymentMethodDetailsFieldAdvancedFields,
    updateTripletexLedgerAdvancedFields,
} from "~/domains/orchestration/flows/core/nodeConfiguration"

import { AddToBudgetNode, Event, FitsToBudgetNode, FlowNode, NodeType } from "./Flows"

export enum View {
    FLOW = "flow",
    RUN_EXPLORER = "explorer",
}

export type Suggestion = {
    labelId: string
    value: string
    description?: string
    enabled?: boolean
    type: string
    position: "leftHand" | "rightHand" | "operator"
}

export enum PartnershipFieldToUpdate {
    VERIFIED_STATUS = "verifiedStatus",
    PREFERRED_STATUS = "preferredStatus",
    ALLOW_TO_SEND_PO = "allowToSendPO",
    ALLOW_TO_PAY_INVOICE = "allowToPayInvoice",
    COLLABORATION_STATUS = "collaborationStatus",
}

export enum CollaborationStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    REFUSED = "REFUSED",
    IN_REVIEW = "IN_REVIEW",
    CLOTURED = "CLOTURED",
    EXCLUDED = "EXCLUDED",
    DRAFT = "DRAFT",
}

export type BudgetNode = AddToBudgetNode | FitsToBudgetNode

export type FactoryProps = {
    slug: string
    position: XYPosition
    eventTriggerEventType: Event | null
    name: string
}

export type Factory = (props: FactoryProps) => FlowNode

export type CreateNodeProps = FactoryProps & {
    type: NodeType
}

/**
 * Advanced Fields Configuration
 *
 * This section defines the advanced configuration fields available for different node types.
 * Each node type has specific fields that can be configured through the UI.
 */

/**

/**
 * Union type of all possible advanced fields
 */
export enum AllAdvancedFieldsUnion {
    AMOUNT = "amount",
    TRANSACTION_ID = "transactionId",
    TRANSACTION_TYPE = "transactionType",
    CURRENCY = "currency",
    OBJECT_ID = "objectId",
    OBJECT_TYPE = "objectType",
    INVOICE_ID = "invoiceId",
    STATUS_TO_SET = "statusToSet",
    FIELD_TO_UPDATE = "fieldToUpdate",
    VALUE_TO_SET = "valueToSet",
    PARTNERSHIP_ID = "partnershipId",
    AMOUNT_GROSS = "amountGross",
    LEDGER_ID = "ledgerId",
    LEDGER_DATE = "ledgerDate",
    PURCHASE_ORDER_ID = "purchaseOrderId",
    PURCHASE_ORDER_LINE_ID = "purchaseOrderLineId",
    PURCHASE_REQUEST_ID = "purchaseRequestId",
    PURCHASE_REQUEST_LINE_ID = "purchaseRequestLineId",
    PAYMENT_METHOD_DETAILS_ID = "paymentMethodDetailsId",
    FORM_ID = "formId",
    RESPONDENT_ORGANIZATION_ID = "respondentOrganizationId",
}

/**
 * Maps node types to their corresponding advanced fields
 */
export type AdvancedFields = {
    [NodeType.ADD_TO_BUDGET_NODE]: typeof addToBudgetAdvancedFields
    [NodeType.ASSIGN_TAG_NODE]: typeof assignTagAdvancedFields
    [NodeType.CHECK_NODE]: typeof checkAdvancedFields
    [NodeType.FITS_TO_BUDGET_NODE]: typeof fitsToBudgetAdvancedFields
    [NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE]: typeof invoiceToPurchaseOrderMatchAdvancedFields
    [NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE]: typeof setInvoiceLifecycleStatusAdvancedFields
    [NodeType.UPDATE_TRIPLETEX_LEDGER_NODE]: typeof updateTripletexLedgerAdvancedFields
    [NodeType.EVENT_TRIGGER_NODE]: typeof eventTriggerAdvancedFields
    [NodeType.SET_PARTNERSHIP_FIELD_NODE]: typeof setPartnershipFieldAdvancedFields
    [NodeType.IF_NODE]: typeof ifAdvancedFields
    [NodeType.SEND_EMAIL_NODE]: typeof sendEmailAdvancedFields
    [NodeType.APPROVE_PURCHASE_ORDER_NODE]: typeof approvePurchaseOrderAdvancedFields
    [NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE]: typeof approvePurchaseOrderLineAdvancedFields
    [NodeType.CONVERT_PR_TO_PO_NODE]: typeof convertPrToPoAdvancedFields
    [NodeType.APPROVE_PURCHASE_REQUEST_NODE]: typeof approvePurchaseRequestAdvancedFields
    [NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE]: typeof approvePurchaseRequestLineAdvancedFields
    [NodeType.BRANCH_NODE]: typeof branchAdvancedFields
    [NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE]: typeof setPaymentMethodDetailsFieldAdvancedFields
    [NodeType.CREATE_SURVEY_NODE]: typeof createSurveyAdvancedFields
    [NodeType.RETRACT_REVIEWS_NODE]: typeof retractReviewsAdvancedFields
    [NodeType.FETCH_CUSTOM_FIELDS_NODE]: typeof fetchCustomFieldsAdvancedFields
}

/**
 * Helper type to extract advanced fields values for a given node type
 */
export type AdvancedFieldsValues<T> = T extends keyof AdvancedFields ? AdvancedFields[T] : never

/**
 * Node Configuration
 * Defines the structure for configuring different types of flow nodes
 */
export type Configuration<T extends NodeType> = {
    type: T
    baseSlug: string
    Icon: React.FC<any>
    Configuration: React.FC<any>
    Node: React.FC<any>
    factory: Factory
    advancedFields: AdvancedFieldsValues<T>
}

/**
 * Props interface for node configuration components
 */
export interface ConfigurationProps<T extends FlowNode> {
    selectedNode: T
    advancedFields: AdvancedFieldsValues<T>
}
