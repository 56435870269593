import { Grid, TextField, styled } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, CountrySelector, CurrencySelector, Loader, SafeFormattedMessage } from "~/components"
import { useFormValidation } from "~/domains/payment/payment-method-details/hooks"
import { DirectDebitFormState, MandateStatus } from "~/domains/payment/payment-method-details/types"
import { directDebitFormValidationSchema } from "~/domains/payment/payment-method-details/utils/formsValidationSchemas"
import { CountryCode, CurrencyCodes, CurrencyI } from "~/types"

const messages = defineMessages({
    mandateReference: {
        id: "payment.directDebitPaymentMethodForm.mandateReference",
        defaultMessage: "Mandate reference",
    },
    mandateDate: {
        id: "payment.directDebitPaymentMethodForm.mandateDate",
        defaultMessage: "Mandate date",
    },
    accountHolderName: {
        id: "payment.directDebitPaymentMethodForm.accountHolderName",
        defaultMessage: "Account holder name",
    },
    bankAccountNumber: {
        id: "payment.directDebitPaymentMethodForm.bankAccountNumber",
        defaultMessage: "Bank account number",
    },
    bankName: {
        id: "payment.directDebitPaymentMethodForm.bankName",
        defaultMessage: "Bank name",
    },
    currency: {
        id: "payment.directDebitPaymentMethodForm.currency",
        defaultMessage: "Currency",
    },
    country: {
        id: "payment.directDebitPaymentMethodForm.country",
        defaultMessage: "Country",
    },
    saveButton: {
        id: "payment.directDebitPaymentMethodForm.saveButton",
        defaultMessage: "Save",
    },
    cancelButton: {
        id: "payment.directDebitPaymentMethodForm.cancelButton",
        defaultMessage: "Cancel",
    },
})

const Form = styled("form")({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
})

const FormActions = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
})

const initialState: DirectDebitFormState = {
    mandateReference: "",
    mandateDate: null,
    mandateStatus: MandateStatus.PENDING,
    bankAccountNumber: "",
    bankName: "",
    holderName: "",
    currency: CurrencyCodes.EUR,
    country: null,
}

interface PaymentMethodFormDirectDebitProps {
    loading?: boolean
    selectedItem: DirectDebitFormState | null
    onCancel: (resetForm: () => void) => void
    onSubmit: (formData: DirectDebitFormState, resetForm: () => void) => void
}

export const PaymentMethodFormDirectDebit = ({
    selectedItem,
    onSubmit,
    onCancel,
    loading = false,
}: PaymentMethodFormDirectDebitProps) => {
    const [formData, setFormData] = useState<DirectDebitFormState>(selectedItem ?? initialState)
    const { errors, validate } = useFormValidation<DirectDebitFormState>(formData, directDebitFormValidationSchema)
    const { formatMessage, locale } = useIntl()

    const resetForm = () => {
        setFormData(initialState)
    }

    const handleSubmit = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()

        const isValid = validate()

        if (onSubmit && isValid) {
            onSubmit(formData, resetForm)
        }
    }

    const handleCurrencyChange = (currency: CurrencyI | null) => {
        setFormData({
            ...formData,
            currency: currency?.code ?? CurrencyCodes.EUR,
        })
    }

    const handleCountryChange = (countryCode: CountryCode | null) => {
        setFormData({
            ...formData,
            country: countryCode,
        })
    }

    const handleChangeMandateDate = (date: Dayjs | null) => {
        setFormData({
            ...formData,
            mandateDate: date ?? null,
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleCancel = () => {
        onCancel(resetForm)
    }

    return (
        <Form noValidate>
            <TextField
                label={formatMessage(messages.bankAccountNumber)}
                name="bankAccountNumber"
                value={formData.bankAccountNumber}
                disabled={loading}
                required
                error={!!errors.bankAccountNumber}
                helperText={errors.bankAccountNumber}
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />
            <TextField
                label={formatMessage(messages.accountHolderName)}
                name="holderName"
                value={formData.holderName}
                disabled={loading}
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />
            <TextField
                label={formatMessage(messages.bankName)}
                name="bankName"
                value={formData.bankName}
                disabled={loading}
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={formatMessage(messages.mandateReference)}
                        name="mandateReference"
                        value={formData.mandateReference}
                        disabled={loading}
                        required
                        error={!!errors.mandateReference}
                        helperText={errors.mandateReference}
                        fullWidth
                        autoComplete="off"
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                        <DatePicker
                            views={["year", "month", "day"]}
                            label={formatMessage(messages.mandateDate)}
                            minDate={dayjs()}
                            format="YYYY-MM-DD"
                            disabled={loading}
                            value={formData.mandateDate}
                            slots={{ textField: TextField }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    required: true,
                                    error: !!errors.mandateDate,
                                    helperText: errors.mandateDate,
                                },
                            }}
                            onChange={handleChangeMandateDate}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6}>
                    <CountrySelector
                        label={formatMessage(messages.country)}
                        value={formData.country}
                        disabled={loading}
                        onChange={handleCountryChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CurrencySelector
                        editMode
                        label={formatMessage(messages.currency)}
                        value={formData.currency}
                        disabled={loading}
                        onChange={handleCurrencyChange}
                    />
                </Grid>
            </Grid>

            <FormActions>
                <Button type="transparent" onClick={handleCancel}>
                    <SafeFormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="primary" onClick={handleSubmit}>
                    {loading ? <Loader small /> : <SafeFormattedMessage {...messages.saveButton} />}
                </Button>
            </FormActions>
        </Form>
    )
}
