import React, { lazy } from "react"
import { Route } from "react-router-dom"

import { EDIT_INVOICE_BUYER_ROUTE, INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"

const InvoicePage = lazy(() => import("./pages/Invoice").then(({ Invoice }) => ({ default: Invoice })))
const EditInvoicePage = lazy(() => import("./pages/Edit").then(({ EditInvoice }) => ({ default: EditInvoice })))

export default {
    routes: [
        <Route key="invoice_route">
            <Route path={INVOICE_BUYER_ROUTE} element={<InvoicePage />} />
            <Route path={EDIT_INVOICE_BUYER_ROUTE} element={<EditInvoicePage />} />
            <Route path={INVOICE_BUYER_ROUTE + "/*"} element={<InvoicePage />} />
        </Route>,
    ],
}
