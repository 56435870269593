import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { InvoiceId } from "~/types"
import { invoiceActions, selectInvoicesById } from "../invoiceSlice"
import { useEffect } from "react"
import { invoiceApi } from "~/api"
import { store } from "~/store"
import { parseInvoice } from "~/types/InvoiceParsers"

const INITIAL_STATE = { invoice: null, loading: true, error: null }

export const useInvoiceById = (invoiceId: InvoiceId | undefined | null) => {
    const invoicesById = useAppSelector(selectInvoicesById)
    const state = invoiceId ? invoicesById[invoiceId] ?? null : null
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (invoiceId) {
            // we get the state synchronously because this hook can be called by different component at the same time
            // this avoid multiplying the same request
            const currentState = selectInvoicesById(store.getState())[invoiceId]
            if (!currentState || (!currentState.loading && !currentState.invoice && !currentState.error)) {
                dispatch(invoiceActions.fetchInvoiceById(invoiceId))
                invoiceApi
                    .getById(invoiceId)
                    .then(parseInvoice)
                    .then((invoice) => dispatch(invoiceActions.fetchInvoiceByIdSuccess(invoice)))
                    .catch((e) => dispatch(invoiceActions.fetchInvoiceByIdFailed({ invoiceId, error: e.message })))
            }
        }
    }, [invoiceId, dispatch])

    return state ?? INITIAL_STATE
}
