import { InvoiceParty } from "~/domains/transactions/invoices-v1/types/Invoice"
import { CountryCode, ImportInvoiceCompanyInfoI } from "~/types"

export const organizationFromApiAdapter = (organization: InvoiceParty): ImportInvoiceCompanyInfoI => {
    return {
        countryCode: (organization.billingAddress?.country || CountryCode.FR) as CountryCode,
        organizationId: organization.id,
        name: organization.involvement,
        contactName: organization.involvement,
    }
}
