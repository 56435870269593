import { Checkbox, FormControlLabel, Stack, styled } from "@mui/material"
import { FC, PropsWithChildren, ReactNode, SyntheticEvent, useCallback, useState } from "react"
import { Editor, Element as SlateElement, Transforms } from "slate"

import { isElementActionPlanItem } from "~/domains/communication/components/InputMessage/ActionPlan/isElementActionPlanItem"
import { ActionPlanCheckListI, MessageActionPlanItem } from "~/domains/communication/types"

const StyledFormControlLabel = styled(FormControlLabel)({
    width: "100%",
    display: "flex",
    "& > div": {
        flex: "1 1 auto",
    },
})

const CheckListItem = styled("span", { shouldForwardProp: (prop) => prop !== "checked" })<{ checked: boolean }>(
    ({ checked }) => ({
        flex: "1 1 auto",
        textDecoration: checked ? "line-through" : "none",
    })
)

type BaseProps = PropsWithChildren & {
    label: ReactNode
    element: MessageActionPlanItem
}

type PropsWithMessage = BaseProps & {
    editor?: never
    updateActionPlanItem: (updateData: Partial<ActionPlanCheckListI>) => Promise<void>
}
type PropsWithEditor = BaseProps & {
    editor: Editor
    updateActionPlanItem?: never
}

type Props = PropsWithMessage | PropsWithEditor

const stopPropagationAndPreventDefault = (event: SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()
}

export const ActionPlanCheckForm: FC<Props> = ({ label, element, children, editor, updateActionPlanItem }) => {
    const [checked, setChecked] = useState(element.data.checked)
    const onChange = useCallback(
        (_event: SyntheticEvent, checked: boolean) => {
            setChecked(checked)
            if (editor) {
                Transforms.setNodes(
                    editor,
                    {
                        ...element,
                        data: {
                            ...element.data,
                            checked,
                        },
                    },
                    {
                        at: [],
                        mode: "all",
                        match: (n) =>
                            SlateElement.isElement(n) && isElementActionPlanItem(n) && n.data.id === element.data.id,
                    }
                )
            } else {
                updateActionPlanItem({ checked })
            }
        },
        [updateActionPlanItem, editor, element]
    )
    return (
        <StyledFormControlLabel
            onChange={onChange}
            control={<Checkbox checked={checked} />}
            slotProps={{
                typography: {
                    component: "div",
                },
            }}
            label={
                <Stack
                    direction="row"
                    gap={1}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    onClick={editor ? stopPropagationAndPreventDefault : undefined}
                >
                    <CheckListItem checked={checked}>{label}</CheckListItem>
                    {children}
                </Stack>
            }
        />
    )
}
