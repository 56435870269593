import { FC, useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { Card } from "~/components"
import { InvoiceBuyerInfo } from "~/domains/transactions/invoices/components/InvoiceBuyerInfo"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { usePatchDraftInvoice } from "~/store/invoice/hooks"
import { invoiceActions, selectCreatingInvoice } from "~/store/invoice/invoiceSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceI } from "~/types"
import { ImportInvoiceCompanyInfoI, InvoiceUserType } from "~/types"

const messages = defineMessages({
    title: {
        id: "supplier.buyerInfo.title",
        defaultMessage: "Buyer",
    },
    titleAsBuyer: {
        id: "buyer.buyerInfo.titleMe",
        defaultMessage: "Me",
    },
})

interface InvoiceOrganizationBuyerProps {
    invoice: InvoiceI
}

export const InvoiceOrganizationBuyer: FC<InvoiceOrganizationBuyerProps> = ({ invoice }) => {
    const { formatMessage } = useIntl()
    const currentUserOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const user = useAppSelector(selectUser)
    const saveDraftAction = usePatchDraftInvoice(invoice, InvoiceUserType.BUYER, user)

    const dispatch = useDispatch()
    const creatingInvoice = useAppSelector(selectCreatingInvoice)

    const { ocrBuyer } = creatingInvoice ?? {}

    const handleUpdateInvoiceBuyer = useCallback(
        (buyer: Partial<ImportInvoiceCompanyInfoI>) => {
            if (!buyer.organizationId) return

            dispatch(invoiceActions.updatePartialInvoiceBuyer(buyer))
            saveDraftAction()
        },
        [dispatch]
    )

    const { parties } = invoice
    const initiator = Object.values(parties).find((party) => party.initiator)?.id

    return (
        <Card
            title={formatMessage(messages[initiator === currentUserOrganizationId ? "title" : "titleAsBuyer"])}
            expandable
        >
            <InvoiceBuyerInfo
                initiator={InvoiceUserType.BUYER}
                dataLoaded={true}
                buyer={invoice.buyer}
                updateData={handleUpdateInvoiceBuyer}
                ocrBuyerData={ocrBuyer}
            />
        </Card>
    )
}
