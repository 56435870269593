import { styled } from "@mui/material"
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from "@xyflow/react"
import React from "react"
import { X } from "react-feather"

import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"

const DeleteButton = styled("div")<{ labelX: number; labelY: number }>(({ labelX, labelY }) => ({
    position: "absolute",
    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
    // everything inside EdgeLabelRenderer has no pointer events by default
    // if you have an interactive element, set pointer-events: all
    pointerEvents: "all",
}))

export const ButtonEdge = ({
    id,
    target,
    source,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}: EdgeProps) => {
    const { setEdges } = useReactFlow()
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const state = useEditor()
    const currentFlow = state.flow
    const dispatch = useEditorDispatch()

    const handleDelete = () => {
        if (!currentFlow) return

        const node = currentFlow.nodes.find((node) => node.slug === source)
        if (!node) return

        let keyToUpdate = ""

        Object.entries(node).forEach(([key, value]) => {
            if (value === target) {
                keyToUpdate = key
            }
        })

        dispatch({
            type: "UPDATE_NODE",
            payload: {
                ...node,
                [keyToUpdate]: null,
            },
        })

        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: true,
        })

        setEdges((edges) => edges.filter((edge) => edge.id !== id))
    }

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <DeleteButton labelX={labelX} labelY={labelY} className="nodrag nopan">
                    <IconButton type="close" onClick={handleDelete}>
                        <X size={14} />
                    </IconButton>
                </DeleteButton>
            </EdgeLabelRenderer>
        </>
    )
}
