import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material"
import { ReactElement } from "react"

export const TooltipConditional = ({
    children,
    condition,
    type = "default",
    ...tooltipProps
}: { children: ReactElement; condition: boolean; type?: "default" | "primary" | "danger" | "white" } & TooltipProps) =>
    !condition ? (
        children
    ) : type === "default" ? (
        <Tooltip {...tooltipProps}>{children}</Tooltip>
    ) : type === "primary" ? (
        <TooltipPrimary {...tooltipProps}>{children}</TooltipPrimary>
    ) : type === "danger" ? (
        <TooltipDanger {...tooltipProps}>{children}</TooltipDanger>
    ) : (
        <TooltipWhite {...tooltipProps}>{children}</TooltipWhite>
    )

export const TooltipPrimary = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        color: "var(--color-primary)",
        backgroundColor: "var(--color-light-primary)",
        fontSize: "var(--font-size-md)",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "var(--color-light-primary)",
    },
})

export const TooltipDanger = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        color: "var(--color-red)",
        backgroundColor: "var(--color-light-red)",
        fontSize: "var(--font-size-md)",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "var(--color-light-red)",
    },
})

export const TooltipWhite = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "none",
        backgroundColor: "var(--sidebar-color)",
        border: "none",
        boxShadow: "1px 0px 10px rgba(0, 0, 0, 0.25)",
        minWidth: "200px",
        color: "var(--secondary-color)",
        fontSize: "var(--font-size-md)",
        padding: "var(--spacing-md)",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "var(--color-white)",
    },
})
