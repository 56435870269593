import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { RootState } from "~/store"
import { UserI, UserPartialI } from "~/types"

import { UserState as UsersState } from "./usersState"

const initialState: UsersState = {
    loading: false,
    error: undefined,
    users: {},
}

const usersSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {
        fetchUsers(state, _action: PayloadAction<string[]>) {
            state.loading = true
        },
        fetchUsersSuccess(state, action: PayloadAction<UserI[]>) {
            state.loading = false
            state.users = action.payload.reduce((usersRecord, user) => {
                usersRecord[user.id] = user
                return usersRecord
            }, state.users)
        },
        fetchUsersFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
    },
})

export const usersActions = usersSlice.actions

// // Selectors
export const selectUsersStore = (state: RootState) => state.users
export const selectUsers = (state: RootState) => state.users.users
export const selectUser = (state: RootState, userId: string): UserPartialI | undefined => state.users.users[userId]
export const selectUsersLoading = (state: RootState) => state.users.loading

// // Reducer
const usersReducer = usersSlice.reducer
export default usersReducer
