import { Draft, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { toast } from "react-toastify"

import { WorkflowApprovePayload } from "~/domains/orchestration/flows-v0/types"
import { InvoiceI, InvoiceRequestI, InvoiceStatus, InvolvedPeopleI, OrganizationId, UserI, UserId } from "~/types"

import { InvoiceState } from "./invoiceState"

export const buyerActions = {
    fetchInvolvedUsers: (state: Draft<InvoiceState>) => {
        state.involvedUsersLoaded = false
    },
    fetchInvolvedUsersSuccess(state: Draft<InvoiceState>, action: PayloadAction<UserI[]>) {
        state.involvedUsers = action.payload
        state.involvedUsersLoaded = true
    },
    fetchInvolvedUsersFailed(state: Draft<InvoiceState>, action: PayloadAction<string>) {
        state.involvedUsersLoaded = true
        state.error = action.payload
    },

    addInvolvedPersonData(state: Draft<InvoiceState>, action: PayloadAction<InvolvedPeopleI>) {
        if (state.invoice) {
            const personExists =
                state.invoice.involvedPeople.find((person) => person.userId === action.payload.userId) !== undefined
            if (!personExists) {
                state.invoice.involvedPeople.push(action.payload)
                state.userIds.push(action.payload.userId)
            }
        }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addInvolvedPerson(state: Draft<InvoiceState>, _action: PayloadAction<{ userId: string }>) {
        state.loadingInvoice = true
    },
    addInvolvedPersonSuccess(state: Draft<InvoiceState>) {
        state.loadingInvoice = false
    },
    addInvolvedPersonFailed(state: Draft<InvoiceState>) {
        state.loadingInvoice = false
    },
    removeInvolvedPeople(state: Draft<InvoiceState>) {
        state.loadingInvoice = true
    },
    removeInvolvedPeopleSuccess(state: Draft<InvoiceState>, action: PayloadAction<UserId[]>) {
        state.loadingInvoice = false
        action.payload.map((userId) => {
            if (state.invoice) {
                const index = state.invoice.involvedPeople.findIndex((user) => user.userId === userId)
                if (index !== -1) {
                    state.invoice.involvedPeople.splice(index, 1)
                }
            }
        })
    },
    removeInvolvedPeopleFailed(state: Draft<InvoiceState>) {
        state.loadingInvoice = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendRequest(state: Draft<InvoiceState>, _action: PayloadAction<InvoiceRequestI>) {
        state.loading = true
    },
    sendRequestSuccess(state: Draft<InvoiceState>, action: PayloadAction<InvoiceRequestI>) {
        state.loading = false
        state.request = action.payload
        if (state.invoice) {
            state.invoice.status = InvoiceStatus.INFO_REQUESTED
        }
        toast.success("Your request has been sent")
    },
    sendRequestFailed(state: Draft<InvoiceState>, action: PayloadAction<string>) {
        state.loading = false
        state.error = action.payload
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validateInvoice(state: Draft<InvoiceState>, _action: PayloadAction<{ comment: string; tags: string[] }>) {
        state.sendingValidation = true
        state.loading = true
    },
    validateInvoiceSuccess(state: Draft<InvoiceState>) {
        state.loading = false
        state.validationSuccess = true
        if (state.invoice) {
            state.invoice.status = InvoiceStatus.VALIDATED
        }
        toast.success("This invoice has been validated")
    },
    validateInvoiceFailed(state: Draft<InvoiceState>, action: PayloadAction<string>) {
        state.loading = false
        state.error = action.payload
    },
    approveInvoice(
        state: Draft<InvoiceState>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _action: PayloadAction<{ organizationId: OrganizationId } & WorkflowApprovePayload>
    ) {
        state.sendingApproval = true
    },
    approveInvoiceSuccess(state: Draft<InvoiceState>) {
        state.sendingApproval = false
        state.approvalSuccess = true
    },
    approveInvoiceFailed(state: Draft<InvoiceState>) {
        state.sendingApproval = false
    },
    rejectInvoice(
        state: Draft<InvoiceState>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _action: PayloadAction<{ organizationId: OrganizationId } & WorkflowApprovePayload>
    ) {
        state.sendingRejection = true
    },
    rejectInvoiceSuccess(state: Draft<InvoiceState>) {
        state.sendingRejection = false
        state.rejectionSuccess = true
    },
    rejectInvoiceFailed(state: Draft<InvoiceState>) {
        state.sendingRejection = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    schedulePayment(state: Draft<InvoiceState>, _action: PayloadAction<InvoiceI>) {
        state.loading = true
    },
    schedulePaymentSuccess(state: Draft<InvoiceState>, action: PayloadAction<string | null | undefined>) {
        state.loading = false
        if (state.invoice) {
            state.invoice.status = InvoiceStatus.PAYMENT_SCHEDULED
            state.invoice.scheduledPaymentDate = action.payload
        }
        toast.success("Your payment has been scheduled")
    },
    schedulePaymentFailed(state: Draft<InvoiceState>, action: PayloadAction<string>) {
        state.loading = false
        state.error = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setAsPaid(state: Draft<InvoiceState>, _action: PayloadAction<string>) {
        state.loading = true
    },
    setAsPaidSuccess(state: Draft<InvoiceState>) {
        state.loading = false
        if (state.invoice) {
            state.invoice.status = InvoiceStatus.PAID
            state.invoice.paidAt = dayjs().toISOString()
        }
        toast.success("Your payment has been mark as paid")
    },
    setAsPaidFailed(state: Draft<InvoiceState>, action: PayloadAction<string>) {
        state.loading = false
        state.error = action.payload
    },
    setValidated(state: Draft<InvoiceState>) {
        if (state.invoice) {
            state.invoice.status = InvoiceStatus.VALIDATED
        }
    },
}
