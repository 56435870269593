import { Tooltip } from "@mui/material"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"

import { DataTable, DataTableColumn } from "~/components/DataTable"
import { CustomFieldCell } from "~/domains/transactions/custom-fields/components/CustomFieldsTable/CustomFieldCell"
import { customFieldsMessages } from "~/domains/transactions/custom-fields/components/ESGCustomFieldsTable"
import { inputLineConfig, lineCustomFieldsLabelMessages } from "~/domains/transactions/invoices/core"

export interface ItemWithDescriptionAndQuantity {
    description?: string | null
    quantity: number
}

export interface CustomFieldsTableProps<T extends ItemWithDescriptionAndQuantity> {
    items: T[]
    idKey: keyof T
    temporaryIdKey?: keyof T
    contextId?: string
}

export function CustomFieldsTable<T extends ItemWithDescriptionAndQuantity>({
    items,
    idKey,
    temporaryIdKey,
    contextId,
}: CustomFieldsTableProps<T>) {
    const { formatMessage } = useIntl()

    const itemHasId = useCallback(
        (item: T) => !!item[idKey] || (temporaryIdKey && !!item[temporaryIdKey]),
        [idKey, temporaryIdKey]
    )

    const columns: DataTableColumn<T>[] = [
        {
            id: "description",
            key: "description",
            label: formatMessage(customFieldsMessages.description),
            sorter: true,
            renderCell: (line) => (
                <Tooltip title={line.description}>
                    <span className="truncate-text item-description">{line.description}</span>
                </Tooltip>
            ),
        },
        {
            id: "quantity",
            key: "quantity",
            label: formatMessage(customFieldsMessages.quantity),
            sorter: true,
        },
        ...inputLineConfig.map((lineConfig): DataTableColumn<T> => {
            return {
                id: lineConfig.name,
                getValue: () => 0,
                label: formatMessage(lineCustomFieldsLabelMessages[lineConfig.name]),
                renderCell: (line, index) => (
                    <CustomFieldCell lineConfig={lineConfig} contextId={contextId} lineIndex={index} />
                ),
            }
        }),
    ]

    return (
        <div className="items">
            <DataTable hidePagination={true} data={items.filter(itemHasId)} columns={columns} />
        </div>
    )
}
