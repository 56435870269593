import React, { PropsWithChildren, createContext, useEffect, useMemo, useRef, useState } from "react"
import { Socket, io } from "socket.io-client"

const API_OCR_URL = new URL(import.meta.env.VITE_API_OCR_URL)
const WS_ORIGIN = `ws${API_OCR_URL.protocol.endsWith("s:") ? "s" : ""}://${API_OCR_URL.hostname}:${API_OCR_URL.port}`
const WS_PATH = "/ocr/ws"

type OcrSocketIOContextI =
    | {
          ioSocket: Socket
          isConnected: boolean
      }
    | {
          ioSocket: null
          isConnected: false
      }

export const OcrSocketIOContext = createContext<OcrSocketIOContextI>({
    ioSocket: null,
    isConnected: false,
})

export const OcrSocketIOContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const didUnmount = useRef(false)
    const [isConnected, setIsConnected] = useState<boolean>(false)

    const ioSocket = useMemo<Socket>(() => {
        const socket = io(WS_ORIGIN, {
            path: WS_PATH,
            transports: ["websocket"],
        })
        return socket
    }, [])

    useEffect(() => {
        ioSocket.on("connect", () => {
            !didUnmount.current && setIsConnected(true)
        })
        ioSocket.on("disconnect", () => {
            !didUnmount.current && setIsConnected(false)
        })
        ioSocket.connect()
    }, [ioSocket])

    useEffect(() => {
        didUnmount.current = false
        return () => {
            didUnmount.current = true
        }
    }, [])

    return <OcrSocketIOContext.Provider value={{ ioSocket, isConnected }}>{children}</OcrSocketIOContext.Provider>
}
