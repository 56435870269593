import Ucpa from "~/assets/images/ucpa.png"

export const LogoUcpa = ({ iconOnly }: { iconOnly?: boolean }) => {
    return !iconOnly ? (
        <img src={Ucpa} alt="UCPA" height={30} />
    ) : (
        <svg width="29" height="30" viewBox="0 0 117 123" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M85.5003 0V69.7C85.5003 90 79.9003 103.2 58.1003 103.2C36.3003 103.2 30.4003 90 30.4003 69.7V0H0.000326201V77C-0.0996738 110.1 22.8003 122.1 57.6003 122.1C91.4003 122.1 116.3 110.2 116.3 76.9L116.2 0H85.5003Z"
                fill="#E81D00"
            />
        </svg>
    )
}
