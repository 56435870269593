import { Stack, Tooltip } from "@mui/material"
import { FC } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { FLOW_NODE_LIST } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/messages"
import { NodeType } from "~/domains/orchestration/flows/types"

interface Props {
    handleDragStart: (nodeType: NodeType) => (event: React.DragEvent<HTMLDivElement>) => void
    handleClick: (nodeType: NodeType) => void
    showLabels?: boolean
    direction?: "row" | "column"
}

export const NodeList: FC<Props> = ({ handleDragStart, handleClick, showLabels = false, direction = "row" }) => {
    const { formatMessage } = useIntl()

    const handleNodeClick = (nodeType: NodeType) => (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        handleClick(nodeType)
    }

    return (
        <Stack gap={1} direction={direction}>
            {FLOW_NODE_LIST.map(({ type, Icon }) => (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    className="flows-editor-sideBar-item"
                    key={type}
                    onDragStart={handleDragStart(type)}
                    onClick={handleNodeClick(type)}
                    draggable
                >
                    {showLabels ? (
                        <Icon size={18} className="flows-editor-sideBar-item-icon" />
                    ) : (
                        <Tooltip placement="top" title={formatMessage(messages.nodeTitle[type])}>
                            <Icon size={18} className="flows-editor-sideBar-item-icon" />
                        </Tooltip>
                    )}
                    {showLabels && <SafeFormattedMessage tagName="h5" {...messages.nodeTitle[type]} />}
                </Stack>
            ))}
        </Stack>
    )
}
