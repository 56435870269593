import { Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { MouseEvent, useState } from "react"
import { Download } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, Card, Loader } from "~/components"
import { useGenerateXMLFileMutation } from "~/domains/payment/payment/api/paymentApi"
import { XMLVersion } from "~/domains/payment/payment/types"
import { toggleButtonsStyles } from "~/domains/payment/payment/utils/customStyles"

const messages = defineMessages({
    downloadMessage: {
        id: "payment.document.paymentDetails.downloadMessage",
        defaultMessage: "Download the payment file and upload into your bank",
    },
    downloadLabel: {
        id: "payment.document.paymentDetails.downloadLabel",
        defaultMessage: "Download .xml",
    },
    downloadError: {
        id: "payment.document.paymentDetails.downloadError",
        defaultMessage: "Error while downloading the file",
    },
})

interface XMLGeneratorProps {
    paymentId: string
}

export const XMLGenerator = ({ paymentId }: XMLGeneratorProps) => {
    const { formatMessage } = useIntl()
    const [version, setVersion] = useState(XMLVersion.V11)
    const [generateXMLFile, { isLoading }] = useGenerateXMLFileMutation()

    const onXMLVersionChanged = (_: MouseEvent<HTMLElement>, value: XMLVersion) => {
        if (value) {
            setVersion(value)
        }
    }

    const handleFileDownload = async () => {
        try {
            await generateXMLFile({
                payment_ids: [paymentId],
                message_type: version,
            }).unwrap()
        } catch (error) {
            toast.error(formatMessage(messages.downloadError))
        }
    }

    return (
        <Card>
            <Stack spacing={1}>
                <Typography textAlign="center" fontSize={12} color="var(--color-light-grey)">
                    {formatMessage(messages.downloadMessage)}
                </Typography>
                <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" gap={4}>
                    <ToggleButtonGroup
                        color="standard"
                        sx={toggleButtonsStyles}
                        value={version}
                        size="small"
                        exclusive
                        disabled={isLoading}
                        onChange={onXMLVersionChanged}
                    >
                        <ToggleButton value={XMLVersion.V03}>V.03</ToggleButton>
                        <ToggleButton value={XMLVersion.V11}>V.11</ToggleButton>
                    </ToggleButtonGroup>
                    <Button type="title" disabled={isLoading} onClick={handleFileDownload}>
                        {isLoading ? (
                            <Loader small />
                        ) : (
                            <Box component="div">
                                <Download size={16} /> {formatMessage(messages.downloadLabel)}
                            </Box>
                        )}
                    </Button>
                </Grid>
            </Stack>
        </Card>
    )
}
