import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorization } from "~/api/prepareHeaders"
import { bankDetailsFromApiAdapter } from "~/domains/payment/payment-method-details/api/adapters/bankDetailsFromApiAdapter"
import { paymentMethodDetailsFromApiAdapter } from "~/domains/payment/payment-method-details/api/adapters/paymentMethodDetailsFromApiAdapter"
import { paymentMethodDetailsToApiAdapter } from "~/domains/payment/payment-method-details/api/adapters/paymentMethodDetailsToApiAdapter"
import {
    CreatePaymentMethodDetails,
    ManualVerification,
    PaymentMethodDetails,
    PaymentMethodDetailsObject,
    PaymentMethodDetailsScore,
} from "~/domains/payment/payment-method-details/types"
import { PaginatedResponse, PaymentMethodBankTransfer } from "~/domains/payment/types"

const paymentMethodDetailsTags = ["PaymentMethodDetails"]
const manualVerificationTags = ["ManualVerification"]
const tags = [...paymentMethodDetailsTags, ...manualVerificationTags]

const BASE_URL = import.meta.env.VITE_API_PAYMENT_URL_V2

interface PartnerPaymentMethodDetails {
    ownerId: string
    partnerId: string
    showDeleted?: boolean
}

export const paymentMethodDetailsApi = createApi({
    reducerPath: "paymentMethodDetailsApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorization }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getPaymentMethodDetails: builder.query<PaginatedResponse<PaymentMethodDetails>, unknown>({
            query: ({ page, page_size, owner, iban, include_deleted }) => ({
                url: "payment_method_details",
                params: { page: page ?? 1, page_size: page_size ?? 50, owner, iban, include_deleted },
            }),
            providesTags: paymentMethodDetailsTags,
            transformResponse: (response: PaginatedResponse<PaymentMethodDetails>) => {
                return {
                    ...response,
                    data: response.items.map(paymentMethodDetailsFromApiAdapter),
                }
            },
        }),
        getPaymentMethodDetailsItem: builder.query({
            query: (id: string) => ({
                url: `payment_method_details/${id}`,
            }),
            providesTags: paymentMethodDetailsTags,
        }),
        getPartnerPaymentMethodDetails: builder.query<
            PaginatedResponse<PaymentMethodDetails>,
            PartnerPaymentMethodDetails
        >({
            query: ({ ownerId, partnerId, showDeleted }: PartnerPaymentMethodDetails) => ({
                url: `payment_method_details/by_owner_and_partner/${ownerId}/${partnerId}`,
                params: { include_deleted: showDeleted },
            }),
            providesTags: paymentMethodDetailsTags,
            transformResponse: (response: PaginatedResponse<PaymentMethodDetails>) => {
                return {
                    ...response,
                    data: response.items.map(paymentMethodDetailsFromApiAdapter),
                }
            },
        }),
        getPaymentMethodDetailsScore: builder.query<PaymentMethodDetailsScore, string>({
            query: (id: string) => ({
                url: `payment_method_details/${id}/score`,
            }),
            providesTags: paymentMethodDetailsTags,
        }),
        useGetManualVerificationsByIdQuery: builder.query({
            query: (id: string) => ({
                url: `manual_verification/by_payment_method_details/${id}`,
            }),
            providesTags: manualVerificationTags,
        }),
        createPaymentMethodDetails: builder.mutation({
            query: (paymentMethodDetails: CreatePaymentMethodDetails) => ({
                url: "payment_method_details",
                method: "POST",
                body: paymentMethodDetailsToApiAdapter(paymentMethodDetails),
            }),
            invalidatesTags: paymentMethodDetailsTags,
            transformResponse: paymentMethodDetailsFromApiAdapter,
        }),
        createPaymentMethodDetailsObject: builder.mutation({
            query: (paymentMethodDetailsObject: PaymentMethodDetailsObject) => ({
                url: "payment_method_details_object",
                method: "POST",
                body: paymentMethodDetailsObject,
            }),
            invalidatesTags: tags,
        }),
        updatePaymentMethodDetails: builder.mutation({
            query: (paymentMethodDetails: Partial<PaymentMethodDetails>) => ({
                url: `payment_method_details/${paymentMethodDetails.id}`,
                method: "PUT",
                body: paymentMethodDetailsToApiAdapter(paymentMethodDetails),
            }),
            invalidatesTags: paymentMethodDetailsTags,
            transformResponse: paymentMethodDetailsFromApiAdapter,
        }),
        deletePaymentMethodDetails: builder.mutation({
            query: (id: string) => ({
                url: `payment_method_details/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: paymentMethodDetailsTags,
        }),
        requestManualVerification: builder.mutation({
            query: (manualVerification: ManualVerification) => ({
                url: "manual_verification/",
                method: "POST",
                body: manualVerification,
            }),
            invalidatesTags: manualVerificationTags,
        }),
        validateIBANAndExtractBankDetails: builder.mutation<
            { valid: boolean; bankDetails: Partial<PaymentMethodBankTransfer> },
            string
        >({
            query: (iban: string) => ({
                url: "payment_method_details/validate/iban",
                method: "POST",
                body: { iban },
            }),
            transformResponse: (response: any) => ({
                ...response,
                bankDetails: response.bank_details && bankDetailsFromApiAdapter(response.bank_details),
            }),
        }),
    }),
})

export const {
    useGetPaymentMethodDetailsQuery,
    useLazyGetPaymentMethodDetailsQuery,
    useGetPaymentMethodDetailsItemQuery,
    useGetPartnerPaymentMethodDetailsQuery,
    useGetPaymentMethodDetailsScoreQuery,
    useUseGetManualVerificationsByIdQueryQuery,
    useLazyGetPartnerPaymentMethodDetailsQuery,
    useCreatePaymentMethodDetailsMutation,
    useCreatePaymentMethodDetailsObjectMutation,
    useUpdatePaymentMethodDetailsMutation,
    useDeletePaymentMethodDetailsMutation,
    useRequestManualVerificationMutation,
    useValidateIBANAndExtractBankDetailsMutation,
} = paymentMethodDetailsApi
