import React, { ChangeEvent, useState } from "react"
import { Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { invoiceApi } from "~/api"
import { Button, SafeFormattedMessage } from "~/components"
// import { EventI, MessageI } from "~/types"
import UserAvatar from "~/components/Avatar/UserAvatar"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { invoiceActions, selectInvoiceId } from "~/store/invoice/invoiceSlice"

/*interface Props {
    message?: MessageI | EventI
}*/

const messages = defineMessages({
    messageSuccess: {
        id: "communication.newMessage.success",
        defaultMessage: "Your message has been sent !",
    },
    messageLabel: {
        idd: "communication.newMessage.label",
        defaultMessage: "Send a new message",
    },
})

export default function NewMessage(/* {
    // message,
}: Props */) {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const invoiceId = useAppSelector(selectInvoiceId)
    const { formatMessage } = useIntl()

    const [message, setMessage] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleSendMessage = async () => {
        try {
            if (invoiceId && !isLoading && message.length) {
                setIsLoading(true)
                await invoiceApi.addCommunication(invoiceId, message)
                setMessage("")
                dispatch(
                    invoiceActions.addCommunication({
                        userId: user.id ?? "",
                        user,
                        message,
                        timestamp: Date().toString(),
                    })
                )
                setIsLoading(false)
                toast.success(formatMessage(messages.messageSuccess))
            }
        } catch (err) {
            setIsLoading(false)
            console.error(err)
        }
    }

    const handleChangeMessage = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessage(e.target.value)
    }

    return (
        <div className="message new-message">
            <div className="message-avatar">
                <UserAvatar email={user?.email} />
            </div>
            <h5>
                <span>
                    <strong>
                        <SafeFormattedMessage {...messages.messageLabel} />
                    </strong>
                </span>
                {user?.fullName} {user?.email}
            </h5>
            <div className="new-message-field">
                <input
                    type="text"
                    name="new-message"
                    onChange={handleChangeMessage}
                    value={message}
                    readOnly={isLoading}
                />
                <Button onClick={handleSendMessage} disabled={isLoading}>
                    <Send size={16} />
                </Button>
            </div>
        </div>
    )
}
