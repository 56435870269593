import React, { FC, useState } from "react"

import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { ApprovePurchaseOrderLineNode, ConfigurationProps } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const ApprovePurchaseOrderLineConfiguration: FC<ConfigurationProps<ApprovePurchaseOrderLineNode>> = ({
    selectedNode,
    advancedFields,
}) => {
    const [currentNode, setCurrentNode] = useState(selectedNode)

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <AdvancedFields<ApprovePurchaseOrderLineNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
