import { FC } from "react"

import { InvolvedPeople } from "~/domains/transactions/invoices/_views/buyer/components"
import { useAppSelector } from "~/store/hooks"
import { getImportCompanyIdentifier } from "~/store/invoice/core"
import { selectInvoiceUserIds } from "~/store/invoice/invoiceSlice"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { InvoiceI } from "~/types"
import { CountryCode } from "~/types"

interface InvoicePeopleInvolvedProps {
    invoice: InvoiceI
}

export const InvoicePeopleInvolved: FC<InvoicePeopleInvolvedProps> = ({ invoice }) => {
    const userIds = useAppSelector(selectInvoiceUserIds)
    const { users } = useGetAllUsersQuery(userIds)

    const buyerIdentifier = invoice?.buyer ? getImportCompanyIdentifier(invoice.buyer) : null

    const involvedUsers = users.filter((person) =>
        invoice?.involvedPeople.map((person) => person.userId).includes(person.id)
    )

    const hasInvolvedPeople = !!involvedUsers && !!buyerIdentifier

    if (!hasInvolvedPeople) return null

    return (
        <InvolvedPeople
            invoice={invoice}
            involvedPeople={involvedUsers}
            identifier={buyerIdentifier}
            countryCode={invoice.buyer.countryCode ?? CountryCode.UNKNOWN}
        />
    )
}
