import { Stack } from "@mui/material"
import classNames from "classnames"
import { MessageDescriptor } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"
import { selectRowsToApprove } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppSelector } from "~/store/hooks"

const tabs = [
    PaymentMethodType.BANK_TRANSFER,
    PaymentMethodType.DIRECT_DEBIT,
    PaymentMethodType.CARD,
    PaymentMethodType.TO_APPROVE,
]

const messages: Partial<Record<PaymentMethodType, MessageDescriptor>> = {
    [PaymentMethodType.CARD]: {
        id: "payment.paymentMethodTabs.card",
        defaultMessage: "Card",
    },
    [PaymentMethodType.BANK_TRANSFER]: {
        id: "payment.paymentMethodTabs.bankTransfer",
        defaultMessage: "Bank transfer",
    },
    [PaymentMethodType.DIRECT_DEBIT]: {
        id: "payment.paymentMethodTabs.directDebit",
        defaultMessage: "Direct debit",
    },
    [PaymentMethodType.TO_APPROVE]: {
        id: "payment.paymentMethodTabs.toApprove",
        defaultMessage: "To approve",
    },
}

interface PaymentMethodTabsProps {
    currentTab: PaymentMethodType
    onTabChange: (tab: PaymentMethodType) => void
}

export const PaymentMethodTabs = ({ currentTab, onTabChange }: PaymentMethodTabsProps) => {
    const rowsToApprove = useAppSelector(selectRowsToApprove)?.length ?? 0

    const handleTabClick = (tab: PaymentMethodType) => () => {
        onTabChange(tab)
    }

    return (
        <Stack flexDirection="row" marginRight="auto">
            {tabs.map((tab) => (
                <Button
                    key={tab}
                    type="tab"
                    className={classNames({
                        selected: currentTab === tab,
                    })}
                    onClick={handleTabClick(tab)}
                >
                    <SafeFormattedMessage {...messages[tab]} />
                    {tab === PaymentMethodType.TO_APPROVE && <span className="tab-count">{rowsToApprove}</span>}
                </Button>
            ))}
        </Stack>
    )
}
