import TextField, { TextFieldProps } from "@mui/material/TextField"
import React from "react"

export enum ComponentType {
    STYLED = "styled",
    DEFAULT = "default",
}

type TrimmedTextFieldProps = TextFieldProps & {
    size?: "small" | "medium"
}

const TrimmedTextField: React.FC<TrimmedTextFieldProps> = ({ ...props }) => {
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target
        const trimmedValue = value.trim()

        const newBlurEvent = {
            ...event,
            target: { ...event.target, value: trimmedValue },
        }

        props.onBlur?.(newBlurEvent)

        const newChangeEvent = {
            ...event,
            target: { ...event.target, value: trimmedValue },
        }

        props.onChange?.(newChangeEvent)
    }

    return <TextField {...props} onBlur={handleBlur} />
}

export default TrimmedTextField
