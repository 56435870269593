import { styled } from "@mui/material"
import React from "react"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { LightButton } from "~/components/LightButton"

const manualInputMessages = defineMessages({
    editButton: {
        id: "company.autocomplete.EditOrganization",
        defaultMessage: "Edit",
    },
    dontFindIt: {
        id: "transactions.invoices.ocr.OrganizationPicker.dontFindCompany",
        defaultMessage: "You don’t find it? ",
    },
    inputManuallyCTA: {
        id: "transactions.invoices.ocr.OrganizationPicker.manuallyInputCompany",
        defaultMessage: "Input manually a new company +",
    },
    createCompanyManually: {
        id: "transactions.invoices.ocr.OrganizationPicker.createCompanyManuallyTitle",
        defaultMessage: "Create a new company manually",
    },
})

const ManualInputContainer = styled("div")({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "var(--color-light-grey)",
    display: "flex",
    gap: "8px",
    marginTop: "8px",
})

interface Props {
    onClick: () => void
}

export const ManualCompanyInputTrigger: React.FC<Props> = ({ onClick }) => {
    return (
        <ManualInputContainer>
            <SafeFormattedMessage {...manualInputMessages.dontFindIt} />
            <LightButton onClick={onClick}>
                <SafeFormattedMessage {...manualInputMessages.inputManuallyCTA} />
            </LightButton>
        </ManualInputContainer>
    )
}
