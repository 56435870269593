import { FormState, PaymentMethodDetails } from "~/domains/payment/payment-method-details/types"
import { PaymentMethod, PaymentMethodType } from "~/domains/payment/payment-methods/types"

export interface PaymentMethodDetailsState {
    selectedItem: FormState | null
    selectedPaymentMethod: PaymentMethod | null
    activeTab: PaymentMethodType
    showManualValidationModal: boolean
    rowsToApprove: PaymentMethodDetails[]
    selectedOrganizationId: string | null
    showDeleted: boolean
}

export const paymentMethodDetailsState: PaymentMethodDetailsState = {
    selectedItem: null,
    selectedPaymentMethod: null,
    activeTab: PaymentMethodType.BANK_TRANSFER,
    showManualValidationModal: false,
    rowsToApprove: [],
    selectedOrganizationId: null,
    showDeleted: false,
}
