import { DocumentJobI, DocumentJobStatus } from "~/domains/transactions/invoices/_views/ocr/types"

export const EXPECTED_DURATION = 15000

export const getJobProgress = (job: DocumentJobI) => {
    if (job.status !== DocumentJobStatus.PENDING) return 1
    const createdAtTime = new Date(job.createdAt).getTime()
    const expectedCompletedAt = new Date(job.expectedCompletedAt).getTime()
    const duration = new Date().getTime() - createdAtTime
    const expectedTime = expectedCompletedAt - createdAtTime
    return 1 - Math.exp((-1 * duration) / expectedTime)
}
