import { useCallback, useEffect, useMemo } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { userApi } from "~/api"
import { useAppSelector } from "~/store/hooks"
import { selectUsers, usersActions } from "~/store/users/usersSlice"
import { UserI } from "~/types"
import { fetchEntitiesInChunks } from "~/utils/fetchEntitiesInChunks"

const messages = defineMessages({
    error: {
        id: "roles.users.errorFetch",
        defaultMessage: "We can't retrieve the users. Please contact your administrator",
    },
})

export const useFetchUsers = (
    userIds: string[]
): { users: UserI[]; fetchUsers: (userIds: string[]) => Promise<UserI[]> } => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const users = useAppSelector(selectUsers)

    const fetchUsers = useCallback(
        async (userIds: string[]) => {
            const userIdsToFetch = userIds.filter((userId) => !users[userId])
            if (!userIdsToFetch.length) return Object.values(users)

            try {
                dispatch(usersActions.fetchUsers(userIdsToFetch))
                const users = await fetchEntitiesInChunks(userIdsToFetch, userApi.findUsersByIds)

                dispatch(usersActions.fetchUsersSuccess(users))
                return users
            } catch (error) {
                dispatch(usersActions.fetchUsersFailed(formatMessage(messages.error)))
                return []
            }
        },
        [users]
    )

    useEffect(() => {
        fetchUsers(userIds)
    }, [fetchUsers, userIds])

    return useMemo(() => ({ users: Object.values(users), fetchUsers }), [users, fetchUsers])
}
