import { Tooltip } from "@mui/material"
import React, { useState } from "react"
import { Share2 } from "react-feather"
import { useIntl } from "react-intl"

import { Button } from "~/components"
import {
    SpiceDBObjectType,
    sharedObjectPermissionsModalMessages,
} from "~/domains/identity/features/roles-permissions/types/SpiceDB"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { OrganizationId } from "~/types"

import { ShareObjectPermissionsModal } from "./ShareObjectPermissionsModal"

interface Props {
    organizationId: OrganizationId
    objectType: SpiceDBObjectType
    objectId: string
    buyerOrganization: OrganizationSummary | undefined
    supplierOrganization: OrganizationSummary | undefined
}

export const ShareObjectPermissionsButton: React.FC<Props> = ({
    organizationId,
    objectType,
    objectId,
    buyerOrganization,
    supplierOrganization,
}) => {
    const { formatMessage } = useIntl()
    const [shareObjectModalOpen, setShareObjectModalOpen] = useState<boolean>(false)

    const showShareObjectModal = () => setShareObjectModalOpen(true)
    const hideShareObjectModal = () => setShareObjectModalOpen(false)

    return (
        <>
            <Tooltip arrow title={formatMessage(sharedObjectPermissionsModalMessages.share)}>
                <span>
                    <Button type="grey" onClick={showShareObjectModal}>
                        <Share2 size={18} />
                    </Button>
                </span>
            </Tooltip>
            {shareObjectModalOpen && (
                <ShareObjectPermissionsModal
                    open={shareObjectModalOpen}
                    close={hideShareObjectModal}
                    organizationId={organizationId}
                    objectType={objectType}
                    objectId={objectId}
                    buyerOrganization={buyerOrganization}
                    supplierOrganization={supplierOrganization}
                />
            )}
        </>
    )
}
