import { PlusSquare } from "react-feather"

import { ApprovePurchaseOrderLineConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { ApprovePurchaseOrderLineNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    ApprovePurchaseOrderLineNode as ApprovePurchaseOrderLineNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const approvePurchaseOrderLineNode: Omit<ApprovePurchaseOrderLineNodeType, keyof Node> = {
    purchaseOrderLineId: `{{ trigger_event.id }}`,
    purchaseOrderId: `{{ trigger_event.purchaseOrderId }}`,
    nextNode: null,
    type: NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE,
}

const advancedFields: (keyof typeof approvePurchaseOrderLineNode)[] = [
    AllAdvancedFieldsUnion.PURCHASE_ORDER_LINE_ID,
    AllAdvancedFieldsUnion.PURCHASE_ORDER_ID,
]

const approvePurchaseOrderLineConfig: Configuration<NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE> = {
    type: NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE,
    baseSlug: "approve-purchase-order-line",
    Icon: PlusSquare,
    Configuration: ApprovePurchaseOrderLineConfiguration,
    advancedFields,
    Node: ApprovePurchaseOrderLineNode,
    factory: (props: FactoryProps): ApprovePurchaseOrderLineNodeType => ({
        ...approvePurchaseOrderLineNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { approvePurchaseOrderLineConfig, advancedFields as approvePurchaseOrderLineAdvancedFields }
