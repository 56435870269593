import { Alert, Stack, Typography, styled } from "@mui/material"
import React from "react"
import { AlertCircle, CheckCircle, Icon, Loader } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { JobError } from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/JobError"
import { LinkToInvoice } from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/LinkToInvoice"
import { PendingJobProgress } from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/PendingJobProgress"
import { DocumentJobI, DocumentJobStatus } from "~/domains/transactions/invoices/_views/ocr/types"

const messages = defineMessages({
    processing: {
        id: "transactions.invoices.ocr.processing",
        defaultMessage: "Processing…",
    },
})

const FileNameContainer = styled(Typography)({
    flex: "1 1 auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
})

interface Props {
    job: DocumentJobI
}

const IconByStatus: Record<DocumentJobStatus, Icon> = {
    [DocumentJobStatus.PENDING]: Loader,
    [DocumentJobStatus.SUCCESSFUL]: CheckCircle,
    [DocumentJobStatus.FAILED]: AlertCircle,
}

export const PendingJobItem: React.FC<Props> = ({ job }) => {
    const { formatMessage } = useIntl()
    const { jobId, originalFileName, status, invoiceId, error } = job
    const Icon = IconByStatus[status]

    return (
        <Stack key={jobId} gap="var(--spacing-xs)">
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap="var(--spacing-xs)">
                <Icon size={16} />
                <FileNameContainer variant="body2">{originalFileName}</FileNameContainer>
                {invoiceId && <LinkToInvoice invoiceId={invoiceId} fileName={originalFileName} />}
                {error && <JobError error={error} />}
                {status === DocumentJobStatus.PENDING && <Typography>{formatMessage(messages.processing)}</Typography>}
            </Stack>
            {status === DocumentJobStatus.PENDING && <PendingJobProgress job={job} />}
        </Stack>
    )
}
