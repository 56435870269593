import { Box, LinearProgress } from "@mui/material"
import {
    DataGridPremium,
    GridAutosizeOptions,
    GridColDef,
    UncapitalizedGridPremiumSlotsComponent,
    useGridApiRef,
} from "@mui/x-data-grid-premium"
import { skipToken } from "@reduxjs/toolkit/query"
import dayjs from "dayjs"
import React, { useCallback, useLayoutEffect, useState } from "react"
import { FormattedMessage, defineMessages } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { Button, Card } from "~/components"
import { useGetOrganisationCustomFormsQuery } from "~/domains/identity/features/customForms/api/customFormsApi"
import { CUSTOM_FORMS_FORM } from "~/domains/identity/features/customForms/routes"
import { CustomForm, UserSurveyForm } from "~/domains/identity/features/customForms/types/CustomForms"

import { SendFormPartnerSelect } from "./SendFormPartnerSelect"

const messages = defineMessages({
    send: {
        id: "smartForms.buttonSend",
        defaultMessage: "Send",
    },
})

const columnsLabels = defineMessages({
    name: {
        id: "common.name",
        defaultMessage: "Name",
    },
    description: {
        id: "identity.smartForms.list.description",
        defaultMessage: "Description",
    },
    tag: {
        id: "identity.smartForms.list.tag",
        defaultMessage: "Tag",
    },
    creationDate: {
        id: "identity.smartForms.list.creationDate",
        defaultMessage: "Creation date",
    },
})

const autosizeOptions: GridAutosizeOptions = {
    expand: true,
}

interface Props {
    organizationId: string
}

export const CustomFormsList = ({ organizationId }: Props) => {
    const [selectedForm, setSelectedForm] = useState<CustomForm | null>(null)

    const apiRef = useGridApiRef()
    const pageSizeOptions = [12, 24, 36, 48, 60]

    const navigate = useNavigate()

    const slotsOptions: Partial<UncapitalizedGridPremiumSlotsComponent> = {
        loadingOverlay: LinearProgress,
    }

    const { data: organisationCustomForms = [] } = useGetOrganisationCustomFormsQuery(
        organizationId ? { organisationId: organizationId } : skipToken
    )

    const handleSendForm = (form: CustomForm) => {
        setSelectedForm(form)
    }

    const columns: GridColDef[] = [
        {
            field: "name",
            renderHeader: () => <FormattedMessage {...columnsLabels.name} />,
        },
        {
            field: "description",
            renderHeader: () => <FormattedMessage {...columnsLabels.description} />,
        },
        {
            field: "tag",
            renderHeader: () => <FormattedMessage {...columnsLabels.tag} />,
        },
        {
            field: "creationDate",
            renderHeader: () => <FormattedMessage {...columnsLabels.creationDate} />,
            renderCell: (params: { row: CustomForm }) => {
                // TODO: need to make sure API returns date without UTC
                return <span>{dayjs(params.row.createdAt.replace("[UTC]", "")).format("YYYY-MM-DD")}</span>
            },
        },
        {
            field: "sendButton",
            renderHeader: () => <></>,
            renderCell: (params: { row: CustomForm }) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={(event) => {
                        event.stopPropagation()
                        handleSendForm(params.row)
                    }}
                >
                    <FormattedMessage {...messages.send} />
                </Button>
            ),
        },
    ]

    const onRowClick = useCallback(
        (params) => {
            navigate(generatePath(CUSTOM_FORMS_FORM, { formId: (params as UserSurveyForm).id }))
        },
        [organisationCustomForms]
    )

    useLayoutEffect(() => {
        requestAnimationFrame(
            () =>
                typeof apiRef.current?.autosizeColumns === "function" && apiRef.current.autosizeColumns(autosizeOptions)
        )
    })

    const handleSendFormModalClose = () => {
        setSelectedForm(null)
    }

    return (
        <>
            <Box className="main-box">
                <Card>
                    <DataGridPremium
                        apiRef={apiRef}
                        slots={slotsOptions}
                        columns={columns}
                        rows={organisationCustomForms}
                        pagination
                        pageSizeOptions={pageSizeOptions}
                        rowCount={organisationCustomForms.length ?? 0}
                        disableRowSelectionOnClick
                        onRowClick={onRowClick}
                        rowSelection={false}
                    />
                </Card>
            </Box>
            <SendFormPartnerSelect
                open={selectedForm !== null}
                form={selectedForm ?? undefined}
                close={handleSendFormModalClose}
                organizationId={organizationId ?? ""}
            />
        </>
    )
}
