import { Checkbox, ListItemText, MenuItem, SelectProps, TextField } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"

interface CustomFormsInputProps {
    onValueChanged: (value: string | string[]) => void
    description: string
    savedValue?: string | string[]
    isMultiline?: boolean
    isSelect?: boolean
    isNumber?: boolean
    options?: string[]
    isMultichoice?: boolean
    required?: boolean
    disabled?: boolean
    isInvalid?: boolean
}

// Universal input component for custom forms
export const CustomFormsInput: React.FC<CustomFormsInputProps> = ({
    onValueChanged,
    description,
    savedValue = "",
    isMultiline = false,
    isSelect = false,
    isNumber = false,
    options = [],
    isMultichoice = false,
    required = false,
    disabled = false,
    isInvalid = false,
}) => {
    const [value, setValue] = useState<string | string[]>(isMultichoice ? [] : "")
    const [invalid, setInvalid] = useState<boolean>(isInvalid)
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value
            setInvalid(false)
            if (isMultichoice) {
                const selectedValues = Array.isArray(newValue) ? (newValue as string[]).filter(Boolean) : []
                setValue(selectedValues)
                onValueChanged(selectedValues)
            } else {
                setValue(newValue)
                if (isSelect) onValueChanged(newValue)
            }
        },
        [isMultichoice, isSelect]
    )

    // Save answer on blur if it's not a select or multichoice
    // we only want to save onBlur for text fields
    const handleBlur = useCallback(() => {
        if (!isSelect && !isMultichoice) {
            onValueChanged(value)
            setInvalid(false)
        }
    }, [isSelect, isMultichoice, onValueChanged, value])

    const selectProps: SelectProps | undefined = isSelect
        ? {
              multiple: isMultichoice,
              renderValue: (selected) => (isMultichoice ? (selected as string[]).join(", ") : (selected as string)),
          }
        : undefined

    useEffect(() => {
        savedValue && setValue(savedValue)
    }, [savedValue])

    useEffect(() => {
        setInvalid(isInvalid)
    }, [isInvalid])

    return (
        <TextField
            error={invalid}
            label={description}
            placeholder={description}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            disabled={disabled}
            required={required}
            multiline={isMultiline}
            type={isNumber ? "number" : "text"}
            select={isSelect}
            value={value}
            SelectProps={selectProps}
        >
            {isSelect &&
                options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {isMultichoice ? (
                            <>
                                <Checkbox checked={Array.isArray(value) && value.includes(option)} />
                                <ListItemText primary={option} />
                            </>
                        ) : (
                            option
                        )}
                    </MenuItem>
                ))}
        </TextField>
    )
}
