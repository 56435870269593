import { MessageDescriptor, defineMessages } from "react-intl"

import { WorkflowTriggerType } from "~/domains/orchestration/flows-v0/types/Workflow"

const messages: Record<WorkflowTriggerType, MessageDescriptor> = defineMessages({
    [WorkflowTriggerType.InvoiceReceived]: {
        id: "workflow.triggerType.InvoiceReceived",
        defaultMessage: "Invoice received",
    },
    [WorkflowTriggerType.PurchaseRequestReceived]: {
        id: "workflow.triggerType.PurchaseRequestReceived",
        defaultMessage: "Purchase request received",
    },
})

export const getWorkflowTriggerTypeLabel = (triggerType: WorkflowTriggerType) => messages[triggerType]
