import { FolderId } from "./FolderId"

export enum DocumentType {
    CONTRACT = "CONTRACT",
    INVOICE = "INVOICE",
    QUOTE = "QUOTE",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
    PURCHASE_ORDER = "PURCHASE_ORDER",
    DELIVERY_RECEIPT = "DELIVERY_RECEIPT",
    GOODS_RECEIPT = "GOODS_RECEIPT",
    BUSINESS_IDENTITY_PROOF = "BUSINESS_IDENTITY_PROOF",
    BANK_ACCOUNT_DETAILS = "BANK_ACCOUNT_DETAILS",
    ECOVADIS = "ECOVADIS",
    ISO = "ISO",
    DNB = "DNB",
    OTHER = "OTHER",
    PAYMENT_METHOD_DETAILS = "PAYMENT_METHOD_DETAILS",
}

export enum DocumentObjectType {
    INVOICE = "INVOICE",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
    PURCHASE_ORDER = "PURCHASE_ORDER",
    PARTNERSHIP = "PARTNERSHIP",
    OTHER = "OTHER",
    KYB = "KYB",
    PAYMENT = "PAYMENT",
}

export type UploadDocumentDTO = {
    file: File
    name: string
    documentType: DocumentType
    objectType?: DocumentObjectType
    organizationIds: string[]
    objectId?: string
    folderId?: FolderId | null
}
