import React, { forwardRef } from "react"

import { ErrorMessage, Loader } from "~/components"
import { OrganizationId, UserId } from "~/types"

import { useOrganizationTagGroups } from "../../hooks"
import { TagObjectType } from "../../types"
import { TagsSelectorContainer } from "./TagsSelectorContainer"
import { TagsSelectorStateless } from "./TagsSelectorStateless"
import { TagsSelectorWithState } from "./TagsSelectorWithState"

type TagsSelectorStatelessHOCProps = Omit<TagsSelectorStateless, "tagGroups"> & {
    organizationId: OrganizationId
    readonly?: boolean
}

type Props = {
    organizationId: OrganizationId
    readonly?: boolean
} & (
    | {
          objectId: string | string[]
          userId: UserId
          objectType?: TagObjectType
          contextId?: string
          context?: any
          selectedTags?: never
          setSelectedTags?: never
      }
    | (TagsSelectorStatelessHOCProps & {
          objectId?: never
          userId?: never
          objectType?: never
          context?: any
          contextId?: never
      })
)

const TagsSelectorStatelessHOC = forwardRef<HTMLDivElement, TagsSelectorStatelessHOCProps>(
    ({ organizationId, selectedTags, setSelectedTags, readonly }, ref) => {
        const { tagGroups, error } = useOrganizationTagGroups(organizationId)

        if (error) {
            return <ErrorMessage>{error}</ErrorMessage>
        }
        if (!tagGroups) {
            return (
                <TagsSelectorContainer>
                    <Loader small />
                </TagsSelectorContainer>
            )
        }

        return (
            <TagsSelectorStateless
                tagGroups={tagGroups}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                ref={ref}
                readonly={readonly}
                tagsRecommandations={null}
            />
        )
    }
)
TagsSelectorStatelessHOC.displayName = "TagsSelectorStatelessHOC"

export const TagsSelector = forwardRef<HTMLDivElement, Props>(
    (
        { organizationId, objectId, userId, objectType, context, contextId, selectedTags, setSelectedTags, readonly },
        containerRef
    ) => {
        if (objectId === undefined) {
            return (
                <TagsSelectorStatelessHOC
                    organizationId={organizationId}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    ref={containerRef}
                    readonly={readonly}
                    tagsRecommandations={null}
                />
            )
        }
        return (
            <TagsSelectorWithState
                organizationId={organizationId}
                userId={userId}
                objectId={objectId}
                objectType={objectType}
                context={context}
                contextId={contextId}
                ref={containerRef}
                readonly={readonly}
            />
        )
    }
)

TagsSelector.displayName = "TagsSelector"
