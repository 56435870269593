import { createSelector } from "@reduxjs/toolkit"

import { RootState } from "~/store"

const selectRoomsByObjectId = (state: RootState) => state.communication.roomsByObjectId
const selectLoadingRoomsByObjectId = (state: RootState) => state.communication.loadingRoomsByObjectId
const selectErrorsLoadingRoomsByObjectId = (state: RootState) => state.communication.errorsLoadingRoomsByObjectId

export const selectRoomsState = createSelector(
    selectRoomsByObjectId,
    selectLoadingRoomsByObjectId,
    selectErrorsLoadingRoomsByObjectId,
    (roomsByObjectId, loadingRoomsByObjectId, errorsLoadingRoomsByObjectId) => ({
        roomsByObjectId,
        loadingRoomsByObjectId,
        errorsLoadingRoomsByObjectId,
    })
)

const selectMessagesPerRoom = (state: RootState) => state.communication.messagesPerRoom
const selectFetchingMessagesInRoom = (state: RootState) => state.communication.fetchingMessagesInRoom

export const selectMessagesState = createSelector(
    selectMessagesPerRoom,
    selectFetchingMessagesInRoom,
    (messagesPerRoom, fetchingMessagesInRoom) => ({
        messagesPerRoom,
        fetchingMessagesInRoom,
    })
)

export const selectMessageToUpdate = (state: RootState) => state.communication.messageToUpdate
