import { useHasPermissions } from "~/domains/identity/features/roles-permissions/store/hooks"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { AuthorizationName, OrganizationId } from "~/types"

export const useSidebarPermissions = (currentOrganizationId: OrganizationId | undefined) => {
    const { hasPermission: hasBudgetsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.BUDGETS
    )

    const { hasPermission: hasWorkflowsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.ORCHESTRATION,
        ScopeName.WORKFLOWS
    )

    const { hasPermission: hasPOsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )

    const { hasPermission: hasInvoicesPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.INVOICES
    )

    const { hasPermission: hasBookOfRelationsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    return {
        hasBudgetsPermission,
        hasWorkflowsPermission,
        hasPOsPermission,
        hasInvoicesPermission,
        hasBookOfRelationsPermission,
    }
}
