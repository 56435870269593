import { Stack } from "@mui/material"
import classNames from "classnames"
import React, { useCallback, useEffect, useState } from "react"
import {
    BarChart,
    Book,
    ChevronLeft,
    ChevronRight,
    X as CloseMenu,
    Command,
    DollarSign,
    Feather,
    FileText,
    Folder,
    Home,
    Icon,
    LogIn,
    Mail,
    MessageCircle,
    Play,
    Send,
    Settings,
    ShoppingBag,
    User,
    Users,
} from "react-feather"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"
import { Link, generatePath, useLocation, useNavigate } from "react-router-dom"

import { Button, TooltipConditional } from "~/components"
import { SafeFormattedMessage } from "~/components"
import { PlusIcon } from "~/components/Icons"
import { LogoFlowie } from "~/components/OrganizationLogo/LogoFlowie"
import { getSidebarFeatureFlags } from "~/components/Sidebar/getSidebarFeatureFlags"
import { useSidebarPermissions } from "~/components/Sidebar/useSidebarPermissions"
import { CATALOG_V1_ROUTE } from "~/domains/catalog/routes"
import { COMMUNICATION_PAGE } from "~/domains/communication/routes"
import { COPILOT_PROMPT_ROUTE } from "~/domains/copilot/prompt/routes"
import { CUSTOM_FORMS } from "~/domains/identity/features/customForms/routes"
import { DOCUMENTS_ROUTE } from "~/domains/identity/features/documents/routes"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"
import { WORKFLOWS_LIST } from "~/domains/orchestration/flows-v0/routes"
import { FLOWS_LIST } from "~/domains/orchestration/flows/routes"
import { PARTNERS_ROUTE, PARTNERS_ROUTE_DEFAULT } from "~/domains/partners/routes"
import { EMAILS_INBOX } from "~/domains/transactions/emails/routes"
import { OcrPendingJobsQueue } from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue"
import { INVOICES_ROUTE_DEFAULT } from "~/domains/transactions/invoices/routes"
import { INVOICES_ROUTE } from "~/domains/transactions/invoices/routes"
import { PURCHASE_ORDERS_ROUTE, PURCHASE_ORDERS_ROUTE_DEFAULT } from "~/domains/transactions/purchase-orders/routes"
import { PURCHASE_REQUESTS_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import { SelectOrganization } from "~/features/account/components/Organization/SelectOrganization/SelectOrganization"
import { ORGANIZATION_INFO_ROUTE, PROFILE_ROUTE, PROFILE_ROUTE_DEFAULT } from "~/features/account/routes"
import { BUDGETS_LIST } from "~/features/budget/routes"
import { METRICS_ROUTE } from "~/features/metrics/routes"
import { DASHBOARD_ROUTE, HOME_ROUTE, UPLOAD_ROUTE } from "~/routes/routes"
import { authActions, selectIsConnected } from "~/store/auth/authSlice"
import { globalActions, selectDisplayMenu, selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ViewTypeI } from "~/types"

import "./Sidebar.scss"

const ICON_SIZE = 18

enum MenuItem {
    DASHBOARD = "dashboard",
    INBOX = "inbox",
    BUDGETS = "budget",
    METRICS = "metrics",
    CUSTOM_FORMS = "customForms",
    PARTNERS = "partners",
    ACCOUNT = "account",
    LOGIN = "login",
    NEW_UPLOAD = "newUpload",
    INVOICES = "invoices",
    DOCUMENTS = "documents",
    PURCHASE_ORDERS = "purchaseOrders",
    PURCHASE_REQUESTS = "purchaseRequests",
    WORKFLOWS = "workflow",
    FLOWS = "flows",
    COPILOT = "copilot",
    CATALOG = "catalog",
    SETTINGS = "settings",
    COMMUNICATION = "communication",
}

const menuItemLabels: Record<MenuItem, MessageDescriptor> = defineMessages({
    [MenuItem.NEW_UPLOAD]: { id: "navbar.newUpload", defaultMessage: "New upload" },
    [MenuItem.INVOICES]: { id: "navbar.invoices", defaultMessage: "Invoices" },
    [MenuItem.DOCUMENTS]: { id: "navbar.documents", defaultMessage: "Documents" },
    [MenuItem.CUSTOM_FORMS]: { id: "navbar.customForms", defaultMessage: "Smart forms" },
    [MenuItem.PARTNERS]: { id: "navbar.partners", defaultMessage: "Partners network" },
    [MenuItem.BUDGETS]: { id: "navbar.budgets", defaultMessage: "Budgets" },
    [MenuItem.METRICS]: { id: "navbar.metrics", defaultMessage: "Metrics" },
    [MenuItem.PURCHASE_ORDERS]: { id: "navbar.purchases.orders", defaultMessage: "Payments" },
    [MenuItem.PURCHASE_REQUESTS]: { id: "navbar.purchases.requests", defaultMessage: "Payments" },
    [MenuItem.INBOX]: { id: "navbar.inbox", defaultMessage: "Inbox" },
    [MenuItem.DASHBOARD]: { id: "navbar.dashboard", defaultMessage: "Dashboard" },
    [MenuItem.ACCOUNT]: { id: "navbar.account", defaultMessage: "My account" },
    [MenuItem.LOGIN]: { id: "navbar.login", defaultMessage: "Login" },
    [MenuItem.WORKFLOWS]: { id: "navbar.workflow", defaultMessage: "Workflows" },
    [MenuItem.FLOWS]: { id: "navbar.flows", defaultMessage: "Flows (beta)" },
    [MenuItem.COPILOT]: { id: "navbar.copilot", defaultMessage: "Copilot" },
    [MenuItem.CATALOG]: { id: "navbar.catalog", defaultMessage: "Catalog" },
    [MenuItem.SETTINGS]: { id: "navbar.settings", defaultMessage: "Settings" },
    [MenuItem.COMMUNICATION]: { id: "navbar.communication", defaultMessage: "Communication" },
})

export const Sidebar = () => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const [open] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const isConnected = useAppSelector(selectIsConnected)
    const keepOpen = useAppSelector(selectKeepSidebarOpened)
    const displayMenu = useAppSelector(selectDisplayMenu)
    const sidebarClasses = classNames("sidebar", {
        ["open"]: displayMenu,
        ["keep-open"]: keepOpen || displayMenu,
    })
    const dispatch = useAppDispatch()
    const location = useLocation()

    const {
        areFlowsEnabled,
        areWorkflowsDisabled,
        isMetricsEnabled,
        isInvoiceV1Enabled,
        isDocumentsEnabled,
        isCopilotEnabled,
        isCustomFormsEnabled,
        isCatalogV1Enabled,
        isCommunicationPageEnabled,
    } = getSidebarFeatureFlags(currentOrganizationId)

    const {
        hasBudgetsPermission,
        hasWorkflowsPermission,
        hasPOsPermission,
        hasInvoicesPermission,
        hasBookOfRelationsPermission,
    } = useSidebarPermissions(currentOrganizationId)

    const isMobile = screenWidth <= 900
    const isOpen = open || isMobile || keepOpen

    const toggleKeepSidebarOpened = useCallback(() => {
        dispatch(globalActions.toggleKeepSidebarOpened())
    }, [dispatch])

    useEffect(() => {
        const updateDimension = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener("resize", updateDimension)

        return () => {
            window.removeEventListener("resize", updateDimension)
        }
    }, [setScreenWidth])

    const handleDisplayHamburgerMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        dispatch(globalActions.setDisplayMenu())
    }

    const handleLogin = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault()
            dispatch(authActions.openPopupSignIn())
        },
        [dispatch]
    )

    const renderMenuItems = useCallback(
        (route: string, Icon: Icon, message: MenuItem, classes?: string, iconSize = ICON_SIZE) => {
            const active = location.pathname.startsWith(route)

            return (
                <li className={classNames(classes, { active })}>
                    <TooltipConditional
                        condition={!isOpen}
                        type="primary"
                        title={formatMessage(menuItemLabels[message])}
                        placement="right"
                        arrow
                    >
                        <Link to={generatePath(route)}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Icon size={iconSize} />
                                {isOpen && <SafeFormattedMessage {...menuItemLabels[message]} />}
                            </Stack>
                        </Link>
                    </TooltipConditional>
                </li>
            )
        },
        [isOpen, location, formatMessage]
    )

    const renderHomeLink = () => {
        return renderMenuItems(DASHBOARD_ROUTE, Home, MenuItem.DASHBOARD)
    }

    const renderInboxLink = () => {
        const classes = classNames({ disabled: !hasInvoicesPermission })
        const route = hasInvoicesPermission ? generatePath(EMAILS_INBOX) : "#"
        return renderMenuItems(route, Mail, MenuItem.INBOX, classes)
    }

    const renderBudgetsLink = () => {
        const classes = classNames({ disabled: !hasBudgetsPermission })
        const route = hasBudgetsPermission ? BUDGETS_LIST : "#"
        return renderMenuItems(route, DollarSign, MenuItem.BUDGETS, classes)
    }

    // TEMP: note that metrics piggybacks on budgets permissions,
    // whoever has budgets permissions has metrics permissions
    const renderMetricsLink = () => {
        const classes = classNames({ disabled: !hasBudgetsPermission })
        const route = hasBudgetsPermission ? METRICS_ROUTE : "#"
        return renderMenuItems(route, BarChart, MenuItem.METRICS, classes)
    }

    const renderWorkflowsLink = () => {
        const classes = classNames({ disabled: !hasWorkflowsPermission })
        const route = hasWorkflowsPermission ? WORKFLOWS_LIST : "#"
        return renderMenuItems(route, Play, MenuItem.WORKFLOWS, classes)
    }

    const renderFlowsLink = () => {
        const classes = classNames({ disabled: !hasWorkflowsPermission })
        const route = hasWorkflowsPermission ? FLOWS_LIST : "#"
        return renderMenuItems(route, Play, MenuItem.FLOWS, classes)
    }

    const renderPOsLink = () => {
        const classes = classNames({
            active: location.pathname.startsWith(PURCHASE_ORDERS_ROUTE_DEFAULT),
            disabled: !hasPOsPermission,
        })
        const route = hasPOsPermission ? generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.buyer }) : "#"
        return renderMenuItems(route, ShoppingBag, MenuItem.PURCHASE_ORDERS, classes)
    }

    const renderPRsLink = () => {
        const classes = classNames({ disabled: !isConnected || !currentOrganizationId })
        const route = isConnected ? PURCHASE_REQUESTS_ROUTE : "#"

        return renderMenuItems(route, Send, MenuItem.PURCHASE_REQUESTS, classes)
    }

    const renderInvoicesLink = () => {
        const classes = classNames({
            active: location.pathname.startsWith(INVOICES_ROUTE_DEFAULT),
            disabled: !hasInvoicesPermission,
        })
        const route = hasInvoicesPermission ? generatePath(INVOICES_ROUTE, { view: ViewTypeI.buyer }) : "#"
        return renderMenuItems(route, FileText, MenuItem.INVOICES, classes)
    }

    const renderDocumentsLink = () => {
        const route = generatePath(DOCUMENTS_ROUTE)
        return renderMenuItems(route, Folder, MenuItem.DOCUMENTS)
    }

    const renderCustomFormsLink = () => {
        const route = generatePath(CUSTOM_FORMS)
        return renderMenuItems(route, Feather, MenuItem.CUSTOM_FORMS)
    }

    const renderBookOfRelationsLink = () => {
        const classes = classNames({
            active: location.pathname.startsWith(PARTNERS_ROUTE_DEFAULT),
            disabled: !hasBookOfRelationsPermission,
        })
        const route = hasBookOfRelationsPermission ? generatePath(PARTNERS_ROUTE, { view: ViewTypeI.supplier }) : "#"
        return renderMenuItems(route, Users, MenuItem.PARTNERS, classes)
    }

    const renderCopilotLink = () => {
        const route = generatePath(COPILOT_PROMPT_ROUTE)
        return renderMenuItems(route, Command, MenuItem.COPILOT)
    }

    const renderAccountLink = () => {
        const classes = classNames({ active: location.pathname.startsWith(PROFILE_ROUTE_DEFAULT) })
        const route = generatePath(PROFILE_ROUTE)
        return renderMenuItems(route, User, MenuItem.ACCOUNT, classes)
    }

    const renderSettingsLink = () => {
        const route = generatePath(ORGANIZATION_INFO_ROUTE, { organizationId: currentOrganizationId || "" })
        return renderMenuItems(route, Settings, MenuItem.SETTINGS)
    }

    const renderCatalogLink = () => {
        const route = generatePath(CATALOG_V1_ROUTE)
        return renderMenuItems(route, Book, MenuItem.CATALOG)
    }

    const renderCommunicationPageLink = () => {
        const route = generatePath(COMMUNICATION_PAGE)
        return renderMenuItems(route, MessageCircle, MenuItem.COMMUNICATION)
    }

    const handleNewUpload = () => {
        navigate(isInvoiceV1Enabled ? UPLOAD_ROUTE : HOME_ROUTE)
    }

    return (
        <nav className={sidebarClasses}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                {!isConnected ? <LogoFlowie /> : <SelectOrganization hideOrganizationName={!isOpen} />}

                <a href="#" className="close-mobile-sidebar" onClick={handleDisplayHamburgerMenu}>
                    <CloseMenu size={30} />
                </a>

                <div className="expand-sidebar" onClick={toggleKeepSidebarOpened}>
                    {isOpen ? (
                        <ChevronLeft size={16} color="var(--color-light-grey)" />
                    ) : (
                        <ChevronRight size={16} color="var(--color-light-grey)" />
                    )}
                </div>
            </Stack>

            <Stack gap={1} paddingBlock={2} justifyContent="stretch" width="100%">
                <Button
                    onClick={handleNewUpload}
                    sx={{ borderRadius: "var(--border-radius-sm)", width: "100%" }}
                    size="small"
                >
                    <PlusIcon size={ICON_SIZE} color="white" />
                    {isOpen && <SafeFormattedMessage {...menuItemLabels[MenuItem.NEW_UPLOAD]} />}
                </Button>
                <OcrPendingJobsQueue component="li" compact />
            </Stack>

            <hr />

            <ul>
                {renderHomeLink()}

                <TooltipConditional
                    condition={!hasInvoicesPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderInboxLink()}
                </TooltipConditional>

                <hr />

                <TooltipConditional
                    condition={!isConnected}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderPRsLink()}
                </TooltipConditional>

                <TooltipConditional
                    condition={!hasPOsPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderPOsLink()}
                </TooltipConditional>

                <TooltipConditional
                    condition={!hasInvoicesPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderInvoicesLink()}
                </TooltipConditional>

                {isCatalogV1Enabled && renderCatalogLink()}

                {isDocumentsEnabled && renderDocumentsLink()}

                {isCustomFormsEnabled && renderCustomFormsLink()}

                <hr />

                {!areWorkflowsDisabled && (
                    <TooltipConditional
                        condition={!hasWorkflowsPermission}
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                    >
                        {renderWorkflowsLink()}
                    </TooltipConditional>
                )}

                {/* TEMP: New version of Flows are behind a feature flag */}

                {areFlowsEnabled && (
                    <TooltipConditional
                        condition={!hasWorkflowsPermission}
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                    >
                        {renderFlowsLink()}
                    </TooltipConditional>
                )}

                <TooltipConditional
                    condition={!hasBookOfRelationsPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderBookOfRelationsLink()}
                </TooltipConditional>

                <TooltipConditional
                    condition={!hasBudgetsPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    {renderBudgetsLink()}
                </TooltipConditional>

                {isMetricsEnabled && hasBudgetsPermission && renderMetricsLink()}
                {isCommunicationPageEnabled && renderCommunicationPageLink()}

                {isCopilotEnabled && (
                    <>
                        <hr />
                        {renderCopilotLink()}
                    </>
                )}
            </ul>

            <ul className="nav-profile">
                {renderSettingsLink()}
                {isConnected && renderAccountLink()}
            </ul>

            {!isConnected && (
                <ul>
                    <li>
                        <TooltipConditional
                            condition={!isOpen}
                            type="primary"
                            title={formatMessage(menuItemLabels.login)}
                            placement="right"
                            arrow
                        >
                            <a href="#" onClick={handleLogin}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <LogIn size={ICON_SIZE} />
                                    {isOpen && <SafeFormattedMessage {...menuItemLabels.login} />}
                                </Stack>
                            </a>
                        </TooltipConditional>
                    </li>
                </ul>
            )}
        </nav>
    )
}
