import { Stack, Typography } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import { Loader } from "~/components"
import { useGetPaymentMethodDetailsItemQuery } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import {
    buildExpirationDate,
    buildMaskedCardNumber,
} from "~/domains/payment/payment-method-details/utils/getGridColumnsByActiveTab"
import { useGetPaymentMethodQuery } from "~/domains/payment/payment-methods/api/paymentMethodsApi"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"

const messages = defineMessages({
    iban: {
        id: "payment.partyPaymentMethodDetails.iban",
        defaultMessage: "Iban",
    },
    bicSwift: {
        id: "payment.partyPaymentMethodDetails.bicSwift",
        defaultMessage: "Bic / Swift",
    },
    bankAccountNumber: {
        id: "payment.partyPaymentMethodDetails.accountNumber",
        defaultMessage: "Bank account number",
    },
    bankName: {
        id: "payment.partyPaymentMethodDetails.bankName",
        defaultMessage: "Bank name",
    },
    currency: {
        id: "payment.partyPaymentMethodDetails.currency",
        defaultMessage: "Currency",
    },
    cardNumber: {
        id: "payment.partyPaymentMethodDetails.cardNumber",
        defaultMessage: "Card number",
    },
    expirationDate: {
        id: "payment.partyPaymentMethodDetails.expirationDate",
        defaultMessage: "Expiration date",
    },
    cardBrand: {
        id: "payment.partyPaymentMethodDetails.cardBrand",
        defaultMessage: "Card brand",
    },
    accountNumber: {
        id: "payment.partyPaymentMethodDetails.accountNumber",
        defaultMessage: "Account number",
    },
    mandateReference: {
        id: "payment.partyPaymentMethodDetails.mandateReference",
        defaultMessage: "Mandate reference",
    },
    mandateDate: {
        id: "payment.partyPaymentMethodDetails.mandateDate",
        defaultMessage: "Mandate date",
    },
})

interface PartyPaymentMethodDetailsProps {
    paymentMethodDetailsId?: string | null
    paymentMethodId?: string | null
}

interface DataItem {
    label: string
    value: string
}

export const PartyPaymentMethodDetails = ({
    paymentMethodDetailsId,
    paymentMethodId,
}: PartyPaymentMethodDetailsProps) => {
    const {
        data: paymentMethodDetails,
        isLoading: isLoadingPaymentMethodDetails,
        isFetching,
    } = useGetPaymentMethodDetailsItemQuery(paymentMethodDetailsId ?? "", {
        skip: !paymentMethodDetailsId,
    })
    const { data: paymentMethod, isLoading: isLoadingPaymentMethod } = useGetPaymentMethodQuery(paymentMethodId ?? "", {
        skip: !paymentMethodId,
    })
    const { formatMessage, formatDate } = useIntl()
    const isLoading = isLoadingPaymentMethodDetails || isLoadingPaymentMethod || isFetching

    const dataValues: DataItem[] = []

    if (paymentMethodDetails) {
        const {
            payment_method_type,
            iban,
            bic_swift,
            bank_account_number,
            bank_name,
            currency,
            card_first_6_digits,
            card_last_4_digits,
            card_expiration_month,
            card_expiration_year,
            card_brand,
            mandate_reference,
            mandate_date,
        } = paymentMethodDetails

        if (payment_method_type === PaymentMethodType.BANK_TRANSFER) {
            const bankTransferItems = [
                ...(iban
                    ? [
                          {
                              label: formatMessage(messages.iban),
                              value: iban || "-",
                          },
                      ]
                    : []),
                ...(bic_swift
                    ? [
                          {
                              label: formatMessage(messages.bicSwift),
                              value: bic_swift || "-",
                          },
                      ]
                    : []),
                ...(bank_account_number
                    ? [
                          {
                              label: formatMessage(messages.bankAccountNumber),
                              value: bank_account_number ?? "-",
                          },
                      ]
                    : []),
                {
                    label: formatMessage(messages.bankName),
                    value: bank_name || "-",
                },
                {
                    label: formatMessage(messages.currency),
                    value: currency || "-",
                },
            ]
            dataValues.push(...bankTransferItems)
        }

        if (payment_method_type === PaymentMethodType.CARD) {
            dataValues.push(
                {
                    label: formatMessage(messages.cardNumber),
                    value: buildMaskedCardNumber([card_first_6_digits, card_last_4_digits]) || "-",
                },
                {
                    label: formatMessage(messages.expirationDate),
                    value: buildExpirationDate([card_expiration_month, card_expiration_year]) || "-",
                },
                {
                    label: formatMessage(messages.cardBrand),
                    value: card_brand || "-",
                },
                {
                    label: formatMessage(messages.currency),
                    value: currency || "-",
                }
            )
        }

        if (payment_method_type === PaymentMethodType.DIRECT_DEBIT) {
            dataValues.push(
                {
                    label: formatMessage(messages.accountNumber),
                    value: bank_account_number || "-",
                },
                {
                    label: formatMessage(messages.mandateReference),
                    value: mandate_reference || "-",
                },
                {
                    label: formatMessage(messages.mandateDate),
                    value: formatDate(mandate_date) || "-",
                },
                {
                    label: formatMessage(messages.currency),
                    value: currency || "-",
                }
            )
        }
    } else if (paymentMethod) {
        const { name } = paymentMethod

        dataValues.push({
            label: "",
            value: name,
        })
    }

    if (isLoading) {
        return <Loader small />
    }

    if (!paymentMethod && !paymentMethodDetails) {
        return null
    }

    return (
        <Stack columnGap={5} rowGap={2} display="flex" flexDirection="row" flexWrap="wrap">
            {dataValues.map(({ label, value }: DataItem) => (
                <Stack key={label} spacing={1}>
                    {label && (
                        <Typography component="div" fontSize={12} color="var(--color-light-grey)">
                            {label}
                        </Typography>
                    )}
                    <Typography component="div" fontSize={14} color="var(--color-grey)">
                        {value}
                    </Typography>
                </Stack>
            ))}
        </Stack>
    )
}
