import { Grid, TextField, styled } from "@mui/material"
import { useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, CountrySelector, CurrencySelector, Loader, SafeFormattedMessage } from "~/components"
import { useValidateIBANAndExtractBankDetailsMutation } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { useFormValidation } from "~/domains/payment/payment-method-details/hooks"
import { BankTransferFormState } from "~/domains/payment/payment-method-details/types"
import { bankTransferFormValidationSchema } from "~/domains/payment/payment-method-details/utils/formsValidationSchemas"
import { CountryCode, CurrencyCodes, CurrencyI } from "~/types"

const messages = defineMessages({
    iban: {
        id: "payment.bankTransferPaymentMethodForm.iban",
        defaultMessage: "IBAN",
    },
    bicSwift: {
        id: "payment.bankTransferPaymentMethodForm.bicSwift",
        defaultMessage: "BIC / SWIFT",
    },
    bankName: {
        id: "payment.bankTransferPaymentMethodForm.bankName",
        defaultMessage: "Bank name",
    },
    bankKey: {
        id: "payment.bankTransferPaymentMethodForm.bankKey",
        defaultMessage: "Bank key",
    },
    sortCode: {
        id: "payment.bankTransferPaymentMethodForm.sortCode",
        defaultMessage: "Sort code",
    },
    accountHolderName: {
        id: "payment.bankTransferPaymentMethodForm.accountHolderName",
        defaultMessage: "Account holder name",
    },
    bankAccountNumber: {
        id: "payment.bankTransferPaymentMethodForm.bankAccountNumber",
        defaultMessage: "Bank account number",
    },
    currency: {
        id: "payment.bankTransferPaymentMethodForm.currency",
        defaultMessage: "Currency",
    },
    country: {
        id: "payment.bankTransferPaymentMethodForm.country",
        defaultMessage: "Country",
    },
    saveButton: {
        id: "payment.bankTransferPaymentMethodForm.saveButton",
        defaultMessage: "Save",
    },
    cancelButton: {
        id: "payment.bankTransferPaymentMethodForm.cancelButton",
        defaultMessage: "Cancel",
    },
    kid: {
        id: "payment.paymentMethodDetailsList.kid",
        defaultMessage: "KID",
    },
})

const Form = styled("form")({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
})

const FormActions = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
})

const initialState: BankTransferFormState = {
    bankAccountNumber: "",
    bankName: "",
    bankKey: "",
    sortCode: "",
    iban: "",
    bicSwift: "",
    holderName: "",
    currency: CurrencyCodes.EUR,
    country: null,
    kid: "",
}

interface PaymentMethodFormBankTransferProps {
    loading?: boolean
    selectedItem: BankTransferFormState | null
    onCancel: (resetForm: () => void) => void
    onSubmit: (formData: BankTransferFormState, resetForm: () => void) => void
}

export const PaymentMethodFormBankTransfer = ({
    selectedItem,
    onSubmit,
    onCancel,
    loading = false,
}: PaymentMethodFormBankTransferProps) => {
    const [formData, setFormData] = useState<BankTransferFormState>(selectedItem ?? initialState)
    const { errors, validate } = useFormValidation<BankTransferFormState>(formData, bankTransferFormValidationSchema)
    const { formatMessage } = useIntl()

    const [validateIBANAndExtractBankDetails] = useValidateIBANAndExtractBankDetailsMutation()

    useEffect(() => {
        if (!selectedItem) return

        setFormData(selectedItem)
    }, [selectedItem])

    const resetForm = () => {
        setFormData(initialState)
    }

    const handleSubmit = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()

        const isValid = validate()

        if (onSubmit && isValid) {
            onSubmit(formData, resetForm)
        }
    }

    const handleCurrencyChange = (currency: CurrencyI | null) => {
        setFormData({
            ...formData,
            currency: currency?.code ?? CurrencyCodes.EUR,
        })
    }

    const handleCountryChange = (countryCode: CountryCode | null) => {
        setFormData({
            ...formData,
            country: countryCode,
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        if (name === "iban" && value) {
            const result = await validateIBANAndExtractBankDetails(value)

            if ("data" in result && result.data?.valid) {
                const { bankKey, sortCode, bankName, countryCode, bicSwift } = result.data.bankDetails
                setFormData({
                    ...formData,
                    ...(bankKey ? { bankKey } : {}),
                    ...(sortCode ? { sortCode } : {}),
                    ...(bankName ? { bankName } : {}),
                    ...(countryCode ? { country: countryCode } : {}),
                    ...(bicSwift ? { bicSwift } : {}),
                })
            }

            if ("error" in result) {
                console.error("Error validating IBAN and extracting bank details", result.error)
            }
        }
    }

    const handleCancel = () => {
        onCancel(resetForm)
    }

    return (
        <Form noValidate>
            <TextField
                label={formatMessage(messages.accountHolderName)}
                name="holderName"
                value={formData.holderName}
                disabled={loading}
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />
            <TextField
                label={formatMessage(messages.iban)}
                name="iban"
                value={formData.iban}
                disabled={loading}
                error={!!errors.iban}
                helperText={errors.iban}
                fullWidth
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <TextField
                label={formatMessage(messages.bankAccountNumber)}
                name="bankAccountNumber"
                value={formData.bankAccountNumber}
                disabled={loading}
                error={!!errors.bankAccountNumber}
                helperText={errors.bankAccountNumber}
                fullWidth
                autoComplete="off"
                onChange={handleChange}
            />

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={formatMessage(messages.bicSwift)}
                        name="bicSwift"
                        value={formData.bicSwift}
                        disabled={loading}
                        error={!!errors.bicSwift}
                        helperText={errors.bicSwift}
                        fullWidth
                        autoComplete="off"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={formatMessage(messages.sortCode)}
                        name="sortCode"
                        value={formData.sortCode}
                        disabled={loading}
                        fullWidth
                        autoComplete="off"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={formatMessage(messages.bankName)}
                        name="bankName"
                        value={formData.bankName}
                        disabled={loading}
                        fullWidth
                        autoComplete="off"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={formatMessage(messages.bankKey)}
                        name="bankKey"
                        value={formData.bankKey}
                        disabled={loading}
                        fullWidth
                        autoComplete="off"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CountrySelector
                        label={formatMessage(messages.country)}
                        value={formData.country}
                        disabled={loading}
                        onChange={handleCountryChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CurrencySelector
                        editMode
                        label={formatMessage(messages.currency)}
                        value={formData.currency}
                        disabled={loading}
                        onChange={handleCurrencyChange}
                    />
                </Grid>
                {formData.country === CountryCode.NO && (
                    <Grid item xs={12}>
                        <TextField
                            label={formatMessage(messages.kid)}
                            name="kid"
                            value={formData.kid}
                            disabled={loading}
                            error={!!errors.kid}
                            helperText={errors.kid}
                            fullWidth
                            autoComplete="off"
                            onChange={handleChange}
                        />
                    </Grid>
                )}
            </Grid>

            <FormActions>
                <Button type="transparent" onClick={handleCancel}>
                    <SafeFormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="primary" onClick={handleSubmit}>
                    {loading ? <Loader small /> : <SafeFormattedMessage {...messages.saveButton} />}
                </Button>
            </FormActions>
        </Form>
    )
}
