import { useCallback, useEffect, useRef, useState } from "react"

import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId, ViewTypeI } from "~/types"
import { isResultSuccess } from "~/types/Result"

import { SubscriptionEventI, SubscriptionEventType, SubscriptionType } from "../../common/subscriptions"
import { useSubscribeToUpdate } from "../../common/subscriptions/hooks"
import { useDocumentRelationsApi } from "../../document-relations/documentRelationsApi"
import { documentRelationsActions } from "../../document-relations/store/documentRelationsSlice"
import { useFetchPurchaseOrder } from "../store/hooks"
import { purchaseOrdersActions, selectPurchaseOrder } from "../store/purchaseOrdersSlice"

export const usePurchaseOrder = (
    purchaseOrderId: string | undefined,
    organizationId: OrganizationId | undefined,
    fetchBudget = true
) => {
    const purchaseOrder = useAppSelector(selectPurchaseOrder)
    const [changedOrganization, setChangedOrganization] = useState<boolean>(false)
    const lastOrganizationId = useRef<string | null>(null)

    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organizationId)
    const dispatch = useAppDispatch()

    const doFetch = useCallback(
        (doDispatch: boolean) => {
            if (purchaseOrderId && organizationId) {
                fetchPurchaseOrder(purchaseOrderId, fetchBudget).then((data) => {
                    if (data && doDispatch) {
                        dispatch(
                            purchaseOrdersActions.setViewType(
                                organizationId === data.buyerId ? ViewTypeI.buyer : ViewTypeI.supplier
                            )
                        )
                    }
                })
            }
        },
        [purchaseOrderId, organizationId, fetchBudget]
    )

    useEffect(() => {
        if (purchaseOrderId && lastOrganizationId && organizationId && organizationId !== lastOrganizationId.current) {
            lastOrganizationId.current = organizationId
            setChangedOrganization(true)
            doFetch(true)
        }
    }, [organizationId, purchaseOrderId])

    const documentRelationsApi = useDocumentRelationsApi()

    const fetchPurchaseOrderRelations = useCallback(
        async (purchaseOrderId: string) => {
            if (organizationId) {
                const result = await documentRelationsApi.getPurchaseOrderRelations(organizationId, purchaseOrderId)

                if (isResultSuccess(result)) {
                    dispatch(
                        documentRelationsActions.fetchPurchaseOrderRelationsSuccess({
                            purchaseOrderId,
                            purchaseOrderRelations: result.result,
                        })
                    )
                }
            }
        },
        [dispatch, organizationId, documentRelationsApi]
    )

    const onPurchaseOrderEvent = useCallback(
        (event: SubscriptionEventI) => {
            if (event.type === SubscriptionEventType.DOCUMENT_RELATION) {
                fetchPurchaseOrderRelations(event.objectId)
            } else {
                doFetch(false)
            }
        },
        [doFetch, fetchPurchaseOrderRelations]
    )

    useSubscribeToUpdate(SubscriptionType.PURCHASE_ORDER, purchaseOrderId, onPurchaseOrderEvent)

    return { purchaseOrder, fetchPurchaseOrder, changedOrganization }
}
