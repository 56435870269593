import { Grid, Stack } from "@mui/material"
import React, { ChangeEvent, lazy, useCallback, useEffect, useMemo, useState } from "react"
import { CheckCircle } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { Button, Card, HeaderH1, Loader } from "~/components"
import { ModalConfirm } from "~/components/ModalConfirm/ModalConfirm"
import { Documents, DraftDocumentI } from "~/components/UploadDocument/Documents"
import { CommunicationRoom } from "~/domains/communication/components/CommunicationRoom"
import { DocumentObjectType } from "~/domains/identity/features/documents/types/UploadDocumentDto"
import { SavePayloadType } from "~/domains/identity/features/organizations/components/ModalOrganizationSelectorDetails/types"
import { ApprovalBlock } from "~/domains/orchestration/flows-v0/components/ApprovalBlock"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"
import usePartnerBrandname from "~/domains/partners/store/hooks/usePartnerBrandname"
import { TagsSelector } from "~/domains/tags/components/TagsSelector"
import {
    TagsSelectorForLineCells,
    TagsSelectorWithStateForLineCells,
} from "~/domains/tags/components/TagsSelector/TagsSelectorForLineCells"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { tagsActions } from "~/domains/tags/store/tagsSlice"
import { TagGroupI, TagObjectType } from "~/domains/tags/types"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import "~/domains/transactions/assets/css/Purchases.scss"
import { withSocketIOProvider } from "~/domains/transactions/common/subscriptions/components/SocketIOContext"
import { Description } from "~/domains/transactions/components/Description/Description"
import { useTagsForLines } from "~/domains/transactions/components/Items/hooks/useTagsForLines"
import { StepsPurchaseRequest } from "~/domains/transactions/components/Steps/StepsPurchaseRequest"
import { LinesTabs } from "~/domains/transactions/custom-fields/components/LinesTabs"
import { CustomFieldObjectType } from "~/domains/transactions/custom-fields/types/CustomFieldObjectType"
import { organizationAddressToAddress } from "~/domains/transactions/purchase-orders/pages/PurchaseOrder"
import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api"
import { ActionsColumn } from "~/domains/transactions/purchase-requests/components/Actions/ActionsColumn"
import { ActionsHeader } from "~/domains/transactions/purchase-requests/components/Actions/ActionsHeader"
import { ActionsHeaderCreate } from "~/domains/transactions/purchase-requests/components/Actions/ActionsHeaderCreate"
import { Delete } from "~/domains/transactions/purchase-requests/components/Actions/Delete"
import { PurchaseRequestLeftColumn } from "~/domains/transactions/purchase-requests/components/PurchaseRequestLeftColumn/PurchaseRequestLeftColumn"
import { TagsForCreatingPurchaseRequest } from "~/domains/transactions/purchase-requests/components/TagsForCreatingPurchaseRequest"
import { PURCHASE_REQUESTS_ROUTE, PURCHASE_REQUEST_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import {
    usePurchaseRequestValidationActions,
    useUpdatePurchaseRequest,
    useUpdatePurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/store/hooks"
import { useCreateDraftPurchaseRequest } from "~/domains/transactions/purchase-requests/store/hooks/useCreateDraftPurchaseRequest"
import { usePurchaseRequest } from "~/domains/transactions/purchase-requests/store/hooks/usePurchaseRequest"
import {
    purchaseRequestsActions,
    selectPurchaseRequestsError,
    selectPurchaseRequestsLoading,
    selectShouldUpdatePR,
} from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import {
    PurchaseRequestDetails,
    PurchaseRequestLine,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { useTitle } from "~/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { organizationActions, selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationI, OrganizationId, ViewTypeI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"
import useQueryParams from "~/utils/hooks/useQueryParams"

const Items = lazy(() => import("../../components/Items/ItemsPR").then(({ ItemsPR }) => ({ default: ItemsPR })))
const Organizations = lazy(() =>
    import("../../components/Organizations/Organizations").then(({ Organizations }) => ({ default: Organizations }))
)

const messages = defineMessages({
    htmlTitle: { id: "purchase.requests.request.htmlTitle", defaultMessage: "Purchase request" },
    titleEdit: { id: "purchase.requests.request.titleEdit", defaultMessage: "Edit PR" },
    titleNew: { id: "purchase.requests.request.titleNew", defaultMessage: "New PR" },
    titleView: { id: "purchase.requests.request.titleView", defaultMessage: "PR" },
    confirm: {
        id: "purchase.requests.request.modalConfirm.title",
        defaultMessage: "Would you send the purchase request for approval?",
    },
    submitForApproval: {
        id: "purchase.requests.request.askForSubmit",
        defaultMessage: "Would you like to submit this purchase request for approval?",
    },
    saveAsDraft: { id: "purchase.requests.request.modalConfirm.saveDraft", defaultMessage: "Save it as draft" },
    saveAndSend: { id: "purchase.requests.request.modalConfirm.send", defaultMessage: "Send for approval" },
    errorChangedOrganization: {
        id: "purchase.requests.request.errorChangedOrganization",
        defaultMessage: "You have changed the organization. This organization does not have this purchase request.",
    },
    tagsTitle: { id: "purchase.requests.request.tagsTitle", defaultMessage: "Tags" },
})

interface Props extends JSX.IntrinsicAttributes {
    edit?: boolean
    newPR?: boolean
}

interface ViewProps extends Props {
    organization: OrganizationI
    purchaseRequest: PurchaseRequestDetails
}

/* const getPRSideFromSupplierId = (purchaseRequestSupplier: OrganizationId, currentOrgId: OrganizationId): ViewTypeI =>
    purchaseRequestSupplier === currentOrgId ? ViewTypeI.supplier : ViewTypeI.buyer */

const editablePurchaseRequestStatuses: Partial<Record<PurchaseRequestStatus, boolean>> = {
    [PurchaseRequestStatus.DRAFT]: true,
    [PurchaseRequestStatus.SUBMITTED]: true,
}

export const PurchaseRequestView: React.FC<ViewProps> = ({ edit, newPR, organization, purchaseRequest: PR }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const loading = useAppSelector(selectPurchaseRequestsLoading)
    const { updatePR, loading: updateLoading } = useUpdatePurchaseRequest(organization.id, PR.id)
    const shouldUpdatePR = useAppSelector(selectShouldUpdatePR)
    const navigate = useNavigate()
    /* const view = getPRSideFromSupplierId(PR.supplierId, organization.id) */
    const brandName = usePartnerBrandname(PR.supplierId, PR?.supplierName)

    const { currentUserCanValidate, approve, refuse } = usePurchaseRequestValidationActions(PR)

    const [draftDocuments, setDraftDocuments] = useState<DraftDocumentI[]>([])

    const pageMode =
        edit || newPR || PR.status === PurchaseRequestStatus.DRAFT ? PurchaseViewType.EDIT : PurchaseViewType.VIEW

    const title = formatMessage(
        messages[`title${newPR ? "New" : pageMode === PurchaseViewType.EDIT ? "Edit" : "View"}`]
    )

    const [showModalSubmit, setShowModalSubmit] = useState<boolean>(false)
    const { updatePRStatus } = useUpdatePurchaseRequestStatus(PR.organizationId, PR.id)

    const { tagGroups } = useOrganizationTagGroups(organization?.id)
    const {
        selectedTags: tags,
        setSelectedTags: setTags,
        fetchTags,
    } = useTagsForLines({
        lines: PR.lines,
        organizationId: organization?.id,
        tagGroups,
        objectType: TagObjectType.PURCHASE_REQUEST_LINE,
    })

    useEffect(() => {
        return () => {
            dispatch(purchaseRequestsActions.resetData())
            dispatch(tagsActions.resetPendingTags())
        }
    }, [])

    useEffect(() => {
        if (shouldUpdatePR && !updateLoading) {
            updatePR().then(() => {
                dispatch(purchaseRequestsActions.setShouldUpdatePR(false))
            })
        }
    }, [updatePR, shouldUpdatePR, updateLoading])

    useEffect(() => {
        if (tagGroups && tagGroups.length) {
            fetchTags()
        }
    }, [tagGroups])

    const handleSubmit = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault()
            await updatePRStatus(PurchaseRequestStatus.SUBMITTED)
            window.setTimeout(() => {
                navigate(generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId: PR.id }))
                setShowModalSubmit(false)
            }, 500)
        },
        [updatePRStatus]
    )

    const handleChangeDescription = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            dispatch(
                purchaseRequestsActions.updateData({
                    field: "description",
                    value: e.target.value,
                })
            )
        },
        [dispatch]
    )

    const updateAddresses = useCallback((payload: SavePayloadType) => {
        if (payload.sendingAddress) {
            dispatch(
                purchaseRequestsActions.updateData({
                    field: "shippingAddress",
                    value: organizationAddressToAddress(payload.sendingAddress),
                })
            )
            dispatch(purchaseRequestsActions.setShouldUpdatePR(true))
        }
    }, [])

    const buyerOrganization = useMemo(
        () => ({
            id: organization.id,
            name: organization.name,
            type: ViewTypeI.buyer,
        }),
        [organization]
    )

    const supplierOrganization = useMemo(
        () => ({
            id: PR.supplierId ?? null,
            name: brandName,
            type: ViewTypeI.supplier,
        }),
        [PR]
    )

    const otherOrganizations = useMemo(() => {
        // we do not display a shared room on purchase request view
        const otherOrganizations: OrganizationId[] = []
        return otherOrganizations
    }, [organization, PR.organizationId, PR.supplierId, PR.status])

    const renderLineTags = useCallback(
        (line: PurchaseRequestLine, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => {
            if (!user || !organization) return null

            if (line.id) {
                return (
                    <TagsSelectorForLineCells
                        objectId={line.id}
                        tags={tags}
                        setTags={setTags}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            if (line.temporaryId) {
                return (
                    <TagsSelectorWithStateForLineCells
                        temporaryId={line.temporaryId}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            return null
        },
        [user, organization, tags, setTags, PR]
    )

    const canDelete = PR.id && !PR.purchaseOrder

    const handleDelete = useCallback(async () => {
        try {
            await purchaseRequestApi.delete(PR.organizationId, PR.id)
            navigate(PURCHASE_REQUESTS_ROUTE)
        } catch (e) {
            console.error(e)
        }
    }, [purchaseRequestApi.delete, PR.organizationId, PR.id, navigate])

    const tagSelectorContext = useMemo(
        () => ({
            page: "purchase-request",
            purchaseRequest: PR,
            organization,
            user,
        }),
        [PR, organization, user]
    )

    if (!organization) {
        return null
    }

    return (
        <div className="purchase-page">
            <HeaderH1 title={title + (!newPR ? "#" + PR.number : "")} backLink={PURCHASE_REQUESTS_ROUTE}>
                {newPR ? (
                    <ActionsHeaderCreate
                        organizationId={organization.id}
                        draftDocuments={draftDocuments}
                        setDraftDocuments={setDraftDocuments}
                    />
                ) : (
                    <ActionsHeader
                        id={PR.id}
                        status={PR.status}
                        organizationId={PR.organizationId}
                        mode={pageMode}
                        isConvertedToPO={!!PR.purchaseOrder}
                        buyerOrganization={{ id: PR.organizationId, name: PR.requesterName as string }}
                        supplierOrganization={{ id: PR.supplierId, name: PR.supplierName as string }}
                        permissions={PR.permissions}
                    />
                )}
            </HeaderH1>
            <div className="main-box">
                <StepsPurchaseRequest PR={PR} />
                <Grid container className="purchase-request" spacing={0} gap={2}>
                    <Grid item xs className="column">
                        <PurchaseRequestLeftColumn
                            PR={PR}
                            newPR={newPR}
                            pageMode={pageMode}
                            organization={organization}
                            updatePR={updatePR}
                        />
                    </Grid>
                    <Grid item md={6} sm={8} xs={12} gap={3}>
                        <Organizations
                            to={supplierOrganization}
                            from={buyerOrganization}
                            updateAddresses={updateAddresses}
                            shippingAddress={PR.shippingAddress}
                            readOnly={!editablePurchaseRequestStatuses[PR.status]}
                            showOrganizationSelector={
                                !PR.supplierId ||
                                (pageMode === PurchaseViewType.EDIT && !(PR.purchaseOrder && PR.purchaseOrder.id))
                            }
                            objectType={DocumentObjectType.PURCHASE_REQUEST}
                        />
                        <Description
                            description={PR.description}
                            pageMode={pageMode}
                            handleChange={handleChangeDescription}
                        />
                        {PR.status === PurchaseRequestStatus.SUBMITTED && PR.id && (
                            <ApprovalBlock
                                organisationId={PR.organizationId}
                                objectId={PR.id}
                                objectType={ApprovalObjectType.PURCHASE_REQUEST}
                                onReviewed={(isApproved) => {
                                    // also call the old approval services when approval is done
                                    if (currentUserCanValidate) {
                                        if (isApproved) {
                                            approve()
                                        } else {
                                            refuse()
                                        }
                                    }
                                }}
                                className="mt-12"
                                displayType="inline"
                            />
                        )}

                        <LinesTabs
                            items={PR.lines}
                            idKey="id"
                            temporaryIdKey="temporaryId"
                            contextType={CustomFieldObjectType.PURCHASE_REQUEST}
                            contextId={PR.id}
                            currency={PR.currency}
                            organizationId={organization.id}
                            renderLineTags={renderLineTags}
                        >
                            <Items
                                mode={pageMode}
                                id={PR.id}
                                organizationId={PR.organizationId}
                                lines={PR.lines}
                                totalAmount={PR.totalAmount ?? 0}
                                totalAmountExcludingTax={PR.totalAmountExcludingTax}
                                currency={PR.currency}
                                status={PR.status}
                                permissions={PR.permissions}
                            />
                        </LinesTabs>
                    </Grid>
                    {organization && (
                        <Grid xs>
                            <Stack gap={2}>
                                {pageMode === PurchaseViewType.VIEW && !PR.purchaseOrder ? (
                                    <ActionsColumn PR={PR} />
                                ) : null}
                                {canDelete ? (
                                    <Delete hasPermission={PR.permissions?.delete} handleDelete={handleDelete} />
                                ) : null}
                                {!newPR ? (
                                    <>
                                        <Card title={formatMessage(messages.tagsTitle)} expandable>
                                            <TagsSelector
                                                organizationId={organization.id}
                                                objectId={PR.id}
                                                userId={user.id}
                                                objectType={TagObjectType.PURCHASE_REQUEST}
                                                context={tagSelectorContext}
                                            />
                                        </Card>
                                        <Documents
                                            objectId={PR.id}
                                            objectType={DocumentObjectType.PURCHASE_REQUEST}
                                            organizationId={organization.id}
                                        />
                                    </>
                                ) : (
                                    <Card title={formatMessage(messages.tagsTitle)} expandable>
                                        <TagsForCreatingPurchaseRequest organizationId={organization.id} />
                                    </Card>
                                )}
                                <CommunicationRoom
                                    organizationId={organization.id}
                                    objectId={PR.id}
                                    objectType={SharedObjectType.PurchaseRequest}
                                    otherOrganizations={otherOrganizations}
                                />
                            </Stack>
                        </Grid>
                    )}
                    {loading ? (
                        <div className="loader-wrapper">
                            <Loader fullscreen={true} />
                        </div>
                    ) : null}
                    <ModalConfirm
                        message={formatMessage(messages.submitForApproval)}
                        icon={
                            <div className="icon-container green">
                                <CheckCircle size={30} />
                            </div>
                        }
                        button1={
                            <Button type="neutral" onClick={() => setShowModalSubmit(false)}>
                                {formatMessage(messages.saveAsDraft)}
                            </Button>
                        }
                        button2={
                            <Button type="primary" onClick={handleSubmit}>
                                {formatMessage(messages.saveAndSend)}
                            </Button>
                        }
                        open={showModalSubmit}
                    />
                </Grid>
            </div>
        </div>
    )
}

export const PurchaseRequest = withSocketIOProvider<Props>(({ edit }: Props) => {
    const navigate = useNavigate()
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const { purchaseRequestId } = useParams()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)

    const organization = useAppSelector(selectCurrentOrganization)
    const user = useAppSelector(selectUser)
    const { s, o: sharedOrganizationId, u: sharedUserId } = useQueryParams()
    const isFromSharedEmail = !!(s && s === "t")

    const purchaseRequestOrganizationId =
        isFromSharedEmail && sharedOrganizationId ? sharedOrganizationId : organization?.id
    const { purchaseRequest: PR, changedOrganization } = usePurchaseRequest(
        purchaseRequestOrganizationId,
        purchaseRequestId
    )
    const error = useAppSelector(selectPurchaseRequestsError)

    const isFromSharedObject = useMemo(
        () => isFromSharedEmail && sharedOrganizationId && sharedUserId && user.id === sharedUserId,
        [isFromSharedEmail, sharedOrganizationId, sharedUserId, user.id]
    )

    useEffect(() => {
        if (isFromSharedObject && purchaseRequestId) {
            const organizationAlreadyExists = !!user.organizations.find((org) => org.id === sharedOrganizationId)
            if (organizationAlreadyExists) {
                dispatch(organizationActions.setCurrentOrganization(sharedOrganizationId))
                navigate(generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId }))
            }
        }
    }, [user, isFromSharedObject, sharedOrganizationId, purchaseRequestId])

    useEffect(() => {
        if (error) {
            toast.error(changedOrganization ? formatMessage(messages.errorChangedOrganization) : error)
            navigate(PURCHASE_REQUESTS_ROUTE)
        }
    }, [error, changedOrganization])

    if (!organization) {
        return null
    }
    return <PurchaseRequestView edit={edit ?? false} organization={organization} purchaseRequest={PR} />
})

export function PurchaseRequestNew() {
    const { formatMessage } = useIntl()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)
    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectCurrentOrganization)

    useEffect(() => {
        dispatch(purchaseRequestsActions.resetData())
        dispatch(tagsActions.resetPendingTags())

        return () => {
            dispatch(purchaseRequestsActions.resetData())
            dispatch(tagsActions.resetPendingTags())
        }
    }, [])

    const purchaseRequest = useCreateDraftPurchaseRequest(organization?.id)

    if (!organization || !purchaseRequest) {
        return null
    }
    return (
        <PurchaseRequestView edit={true} newPR={true} organization={organization} purchaseRequest={purchaseRequest} />
    )
}
