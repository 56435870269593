import { Grid } from "@mui/material"
import { FC, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { eventsMock } from "~/domains/transactions/invoices-v1/api/mocks/eventsMock"
import { Actions, ModalRequest } from "~/domains/transactions/invoices/_views/buyer/components"
import { useSegment } from "~/hooks"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { invoiceActions, selectInvoiceUserIds } from "~/store/invoice/invoiceSlice"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { useFetchInvoiceWorkflows } from "~/store/workflow/hooks/useFetchInvoiceWorkflows"
import { InvoiceI } from "~/types"
import { InvoiceRequestI } from "~/types"

interface InvoiceActionsProps {
    invoice: InvoiceI
}

export const InvoiceActions: FC<InvoiceActionsProps> = ({ invoice }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const { segmentTrack } = useSegment()

    const dispatch = useAppDispatch()
    const userIds = useAppSelector(selectInvoiceUserIds)

    const { users } = useGetAllUsersQuery(userIds)
    const { fetchInvoiceChecks } = useFetchInvoiceWorkflows(invoice.payerId, invoice.id)

    const [displayModal, setDisplayModal] = useState(false)
    const [, setDisplayModalValidation] = useState(false)
    const [modalConfirmRemoveApprovalVisible, setModalConfirmRemoveApprovalVisible] = useState(false)
    const [, setModalConfirmUnmarkAsPaidVisible] = useState(false)

    const involvedUsers = users.filter((person) =>
        invoice?.involvedPeople.map((person) => person.userId).includes(person.id)
    )

    const handleDownloadPDF = () => {
        if (invoice?.fileLinks?.pdfLink) {
            window.location.href = invoice.fileLinks.pdfLink
        }
    }

    const preValidation = () => setDisplayModalValidation(true)

    const handleRemoveApproval = () => setModalConfirmRemoveApprovalVisible(true)

    const hideConfirmRemoveApproval = () => setModalConfirmRemoveApprovalVisible(false)

    const handleUnmarkAsPaid = () => setModalConfirmUnmarkAsPaidVisible(true)

    const handleSendRequest = async (request: InvoiceRequestI) => {
        segmentTrack("Request Sent", {
            invoice_id: invoice.id,
        })
        dispatch(
            invoiceActions.sendRequest({
                invoiceId: invoice.id,
                subject: request.subject,
                body: request.body,
                userId: request.userId,
            })
        )
    }

    return (
        <Grid item className="actions">
            <Actions
                organizationId={invoice.payerId}
                invoice={invoice}
                status={invoice.status}
                handleDownloadPDF={handleDownloadPDF}
                handleValidate={preValidation}
                handleRemoveApproval={handleRemoveApproval}
                handleUnmarkAsPaid={handleUnmarkAsPaid}
                setDisplayModal={setDisplayModal}
                className="actions-header"
                events={eventsMock}
            />
            <ModalRequest
                displayModal={displayModal}
                setDisplayModal={setDisplayModal}
                onConfirm={handleSendRequest}
                invoiceId={invoice.id}
                involvedPeople={involvedUsers}
            />
        </Grid>
    )
}
