import { Avatar, CircularProgress, Tooltip } from "@mui/material"
import React from "react"
import { X } from "react-feather"
import { useIntl } from "react-intl"
import CsvDownloadButton from "react-json-to-csv"

import { Button, ButtonType } from "~/components"
import "~/components/BulkActions/BulkActions.scss"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"

export type BulkActionButton = {
    label: string | React.ReactNode
    icon: React.ReactNode
    type?: ButtonType
    hasPermission?: boolean
} & (
    | {
          isCsvDownloadButton?: never
          list?: never
          onClick: (selected: string[]) => void
      }
    | {
          isCsvDownloadButton: boolean
          list: unknown[]
          onClick?: never
      }
)

type Props = {
    loading?: boolean
    selected: string[]
    buttons: BulkActionButton[]
    selectedMessage: string
    onClose: () => void
}

const renderButton = (button: BulkActionButton, selected: string[], loading?: boolean): JSX.Element => {
    const { label, icon, type, hasPermission, onClick } = button
    return (
        <Button
            disabled={loading || typeof hasPermission === "undefined" ? false : !hasPermission}
            type={type ?? "neutral"}
            onClick={() => (onClick ? onClick(selected) : null)}
        >
            <>
                <div className="button-text">{label}</div>
                {icon}
            </>
        </Button>
    )
}

export const BulkActions: React.FC<Props> = ({ selected, buttons, onClose, selectedMessage, loading = false }) => {
    const { formatMessage } = useIntl()
    return (
        <div className="bulk-actions">
            <div className="bulk-actions-count">
                {loading ? (
                    <CircularProgress color="inherit" size={32} />
                ) : (
                    <span>{`${selected.length} ${selectedMessage}`}</span>
                )}
            </div>
            <ul>
                {buttons.map((button, key) => (
                    <li key={key}>
                        {button.isCsvDownloadButton && button.list ? (
                            <CsvDownloadButton
                                data={button.list}
                                filename="Flowie-export.csv"
                                className="flowie-button type-neutral"
                            >
                                <>
                                    <div className="button-text">{button.label}</div>
                                    {button.icon}
                                </>
                            </CsvDownloadButton>
                        ) : (
                            <>
                                {button.hasPermission || typeof button.hasPermission === "undefined" ? (
                                    renderButton(button, selected, loading)
                                ) : (
                                    <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                                        <div>{renderButton(button, selected)}</div>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </li>
                ))}
            </ul>
            <Avatar className="close-button" sx={{ width: 24, height: 24 }} onClick={onClose}>
                <X size={18} />
            </Avatar>
        </div>
    )
}
