import { Edit2 } from "react-feather"

import { SetPaymentMethodDetailsFieldConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { SetPaymentMethodDetailsFieldNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    Node,
    NodeType,
    SetPaymentMethodDetailsFieldNode as SetPaymentMethodDetailsFieldNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const setPaymentMethodDetailsFieldNode: Omit<SetPaymentMethodDetailsFieldNodeType, keyof Node> = {
    paymentMethodDetailsId: `{{ trigger_event.id }}`,
    fieldToUpdate: ``,
    valueToSet: ``,
    nextNode: null,
    type: NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE,
}

const advancedFields: (keyof typeof setPaymentMethodDetailsFieldNode)[] = [
    AllAdvancedFieldsUnion.FIELD_TO_UPDATE,
    AllAdvancedFieldsUnion.VALUE_TO_SET,
    AllAdvancedFieldsUnion.PAYMENT_METHOD_DETAILS_ID,
]

const setPaymentMethodDetailsFieldConfig: Configuration<NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE> = {
    type: NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE,
    baseSlug: "set-payment-method-details-field",
    Icon: Edit2,
    Configuration: SetPaymentMethodDetailsFieldConfiguration,
    advancedFields,
    Node: SetPaymentMethodDetailsFieldNode,
    factory: (props: FactoryProps): SetPaymentMethodDetailsFieldNodeType => ({
        ...setPaymentMethodDetailsFieldNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { setPaymentMethodDetailsFieldConfig, advancedFields as setPaymentMethodDetailsFieldAdvancedFields }
