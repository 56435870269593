import React, { ChangeEvent, useEffect } from "react"
import { defineMessages, useIntl } from "react-intl"

import { DatatableFilters, DatatableFiltersActions } from "~/components"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

import { purchaseOrdersActions, selectPurchaseOrderFilter } from "../store/purchaseOrdersSlice"

const messages = defineMessages({
    primaryButtonLabel: {
        id: "purchase.orders.primaryButtonLabel",
        defaultMessage: "Import in batch",
    },
    newPO: {
        id: "purchase.orders.newPO",
        defaultMessage: "Create a purchase order",
    },
})

interface Props {
    actions: DatatableFiltersActions[]
}

export const Filters: React.FC<Props> = ({ actions }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const filter = useAppSelector(selectPurchaseOrderFilter)

    const handleChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(purchaseOrdersActions.setFilter(e.target.value))
    }

    useEffect(() => {
        return () => {
            dispatch(purchaseOrdersActions.setFilter(""))
        }
    }, [])

    return (
        <>
            <DatatableFilters
                onChangeFilter={handleChangeFilter}
                valueFilter={filter}
                labelButton={formatMessage(messages.newPO)}
                actionsMenu={true}
                actions={actions}
            />
        </>
    )
}
