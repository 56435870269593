import * as Sentry from "@sentry/browser"
import dayjs from "dayjs"
import React, { FC, useEffect, useMemo } from "react"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import { DataTable } from "~/components/DataTable"
import { useGetRunsQuery } from "~/domains/orchestration/flows/api/runsApi"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks/"
import { messages } from "~/domains/orchestration/flows/messages"
import { FlowId, RunItem } from "~/domains/orchestration/flows/types"
import { OrderDirection } from "~/types"

interface Props {
    handleClickRow: (run: RunItem) => void
    flowId?: FlowId
}

export const RunExplorerList: FC<Props> = ({ handleClickRow, flowId }) => {
    const { formatMessage } = useIntl()

    const organizationId = useOrganizationId()

    const { data, error, isLoading, isError, refetch } = useGetRunsQuery(
        { flowId, limit: 100, offset: 0 },
        {
            refetchOnMountOrArgChange: true,
        }
    )

    const columns = useMemo(
        () => [
            {
                id: "status",
                getValue: (run: RunItem) => run.status,
                label: formatMessage(messages.runsTable.status),
                sorter: true,
            },
            {
                id: "startedAt",
                getValue: (run: RunItem) => dayjs(run.startedAt).format("L LT"),
                label: formatMessage(messages.runsTable.startedAt),
                sorter: true,
            },
            {
                id: "finishedAt",
                getValue: (run: RunItem) => (run.finishedAt ? dayjs(run.finishedAt).format("L LT") : null),
                label: formatMessage(messages.runsTable.finishedAt),
                sorter: true,
            },
        ],
        [formatMessage]
    )

    useEffect(() => {
        if (organizationId) {
            refetch()
        }
    }, [organizationId, refetch])

    if (isLoading) {
        return <Loader fullscreen />
    }
    if (isError) {
        toast.error(formatMessage(messages.error.loadingRuns))
        Sentry.captureException(error)
        return null
    }
    if (!data) {
        return null
    }

    return (
        <DataTable
            data={data.runs}
            defaultOrderBy="id"
            defaultOrderDirection={OrderDirection.ASC}
            columns={columns}
            handleClickRow={handleClickRow}
        />
    )
}
