import {
    CollaborationStatus,
    ObjectType,
    PartnershipFieldToUpdate,
    TransactionType,
} from "~/domains/orchestration/flows/types"

export const OBJECT_TYPES = Object.values(ObjectType)

export const TRANSACTION_TYPES = Object.values(TransactionType)

export const PARTNERSHIP_FIELD_TO_UPDATE = Object.values(PartnershipFieldToUpdate)

export const COLLABORATION_STATUSES = Object.values(CollaborationStatus)
