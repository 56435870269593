import { useEffect } from "react"

import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isResultSuccess } from "~/types/Result"

import { useDocumentRelationsApi } from "../documentRelationsApi"
import { documentRelationsActions, selectPurchaseOrderRelationsState } from "../store/documentRelationsSlice"

export const usePurchaseOrderRelations = (organizationId: OrganizationId, purchaseOrderId: string) => {
    const { loading, error, purchaseOrderRelations } = useAppSelector(selectPurchaseOrderRelationsState)

    const documentRelationsApi = useDocumentRelationsApi()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(documentRelationsActions.fetchPurchaseOrderRelations({ purchaseOrderId }))
        documentRelationsApi.getPurchaseOrderRelations(organizationId, purchaseOrderId).then((result) => {
            if (isResultSuccess(result)) {
                dispatch(
                    documentRelationsActions.fetchPurchaseOrderRelationsSuccess({
                        purchaseOrderId,
                        purchaseOrderRelations: result.result,
                    })
                )
            } else {
                if (result.error.partialResults) {
                    dispatch(
                        documentRelationsActions.fetchPurchaseOrderRelationsSuccess({
                            purchaseOrderId,
                            purchaseOrderRelations: result.error.partialResults,
                        })
                    )
                }
                dispatch(
                    documentRelationsActions.fetchPurchaseOrderRelationsFailure({
                        purchaseOrderId,
                        error: result.error,
                    })
                )
            }
        })
    }, [organizationId, purchaseOrderId, documentRelationsApi])

    return { purchaseOrderRelations, loading, error }
}
