import { useEffect, useState } from "react"
import { defineMessages } from "react-intl"

import { useGetObjectPermissionsQuery } from "~/domains/identity/features/roles-permissions/api/spiceDbApi"
import { SpiceDBObjectType, SpiceDBPermissionsLiteI } from "~/domains/identity/features/roles-permissions/types/SpiceDB"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers, useFetchOrganizationTeams } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    error: {
        id: "share.object.permissions.errorFetch",
        defaultMessage: "We can't retrieve the permissions. Please contact your administrator",
    },
})

interface SpiceDBStorePermissionsReturn {
    loading: boolean
    sharedPermissions?: SpiceDBPermissionsLiteI[]
}

export const useFetchObjectPermissions = (
    objectId: string,
    objectType: SpiceDBObjectType
): SpiceDBStorePermissionsReturn => {
    const [sharedPermissionsWithNames, setSharedPermissionsWithNames] = useState<SpiceDBPermissionsLiteI[]>([])
    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(currentOrganization)
    const { teams } = useFetchOrganizationTeams(currentOrganization?.id, true)
    const { data: sharedPermissions, isLoading: loading } = useGetObjectPermissionsQuery({ objectId, objectType })

    useEffect(() => {
        if (currentOrganization?.id && sharedPermissions && (members.length || teams.length)) {
            const userMap = new Map(members.map((user) => [user.userId, user.user]))
            const teamMap = new Map(teams.map((team) => [team.teamId, team]))

            const sharedPermissionsWithNames = sharedPermissions.map((sharedObject) => {
                const { objectId } = sharedObject
                const user = userMap.get(objectId)
                const team = teamMap.get(objectId)

                return {
                    ...sharedObject,
                    name: user ? user.fullName : team ? team.name : "",
                    email: user?.email ?? undefined,
                    membersNumber: team?.members.length ?? undefined,
                }
            })
            setSharedPermissionsWithNames(sharedPermissionsWithNames)
        }
    }, [currentOrganization, sharedPermissions, members, teams])

    return { sharedPermissions: sharedPermissionsWithNames, loading }
}
