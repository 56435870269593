import React, { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { PurchaseViewType } from "~/domains/transactions/_shared/types"
import "~/domains/transactions/assets/css/Purchases.scss"
import { ModalAddToBudget } from "~/domains/transactions/components/ModalAddToBudget/ModalAddToBudget"
import { TotalAmount } from "~/domains/transactions/components/TotalAmount/TotalAmount"
import { TransactionInBudgets } from "~/domains/transactions/components/TransactionInBudgets"
import { useFetchPurchaseRequest } from "~/domains/transactions/purchase-requests//store/hooks"
import { PurchaseOrderLink } from "~/domains/transactions/purchase-requests/components/PurchaseOrderLink/PurchaseOrderLink"
import { PurchaseRequestInfos } from "~/domains/transactions/purchase-requests/components/PurchaseRequestInfos/PurchaseRequestInfos"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { AddTransactionPayloadI, TransactionType } from "~/features/budget/types"
import { useFetchBudgetsData } from "~/store/budget/hooks"
import { useAppDispatch } from "~/store/hooks"

const messages = defineMessages({
    taxLabel: { id: "purchase.requests.request.taxLabel", defaultMessage: "Tax excl." },
    purchaseOrder: { id: "purchase.requests.request.purchaseOrder", defaultMessage: "Purchase order" },
})

interface Props {
    PR: PurchaseRequestDetails
    newPR: boolean | undefined
    pageMode: PurchaseViewType
    organization: { id: string }
    updatePR: (showToast?: boolean) => Promise<PurchaseRequestDetails>
    isNewApprovalRequired?: boolean
}

export const PurchaseRequestLeftColumn: React.FC<Props> = ({ PR, newPR, pageMode, organization, updatePR }) => {
    const [modalAddToBudgetVisible, setModalAddToBudgetVisible] = useState<boolean>(false)

    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()

    const { fetchPurchaseRequest } = useFetchPurchaseRequest(organization.id)

    const { budgetsData } = useFetchBudgetsData(organization.id, false)
    const transactionPayload: AddTransactionPayloadI = {
        transactionRefId: PR.id,
        transactionStatus: PR.status,
        transactionType: TransactionType.PURCHASE_REQUEST,
        supplierOrgId: PR.supplierId,
        buyerOrgId: organization.id,
        description: PR.description,
        amount: PR.totalAmount ?? 0,
        amountWithoutTaxes: PR.totalAmountExcludingTax,
        amountRemainingToPay: 0,
        expectedDeliveryDate: PR.expectedDeliveryDate,
        partialAmount: 0,
        partialRate: 0,
    }

    const showModalAddToBudget = useCallback(() => setModalAddToBudgetVisible(true), [])
    const hideModalAddToBudget = useCallback(() => setModalAddToBudgetVisible(false), [])

    const handleSuccess = useCallback(async () => {
        fetchPurchaseRequest(PR.id)
    }, [dispatch, PR])

    return (
        <>
            <TotalAmount
                amount={PR.totalAmountExcludingTax}
                currency={PR.currency}
                taxLabel={formatMessage(messages.taxLabel)}
            />
            <PurchaseRequestInfos
                PR={PR}
                newPR={newPR}
                updatePR={updatePR}
                pageMode={pageMode}
                organizationId={PR.organizationId}
            />
            {PR.purchaseOrder && (
                <PurchaseOrderLink purchaseOrder={PR.purchaseOrder} label={formatMessage(messages.purchaseOrder)} />
            )}
            {PR.id && <TransactionInBudgets transaction={PR} showModalAddToBudget={showModalAddToBudget} withGauge />}

            <ModalAddToBudget
                open={modalAddToBudgetVisible}
                close={hideModalAddToBudget}
                organizationId={organization.id}
                budgets={budgetsData}
                transaction={PR}
                transactionPayload={transactionPayload}
                handleSuccess={handleSuccess}
            />
        </>
    )
}
