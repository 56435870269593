export interface FakeProgressOptions {
    initialProgress?: number
    timeConstant?: number
    progress?: number
    autoStart?: boolean
}

export class FakeProgress {
    autoStart: boolean
    progress: number
    initialProgress: number
    timeConstant: number
    _running: boolean
    _intervalFrequency: number
    _time: number
    _intervalId: number | null
    _callbacks: ((progress: number) => void)[]

    constructor(options?: FakeProgressOptions) {
        this.autoStart = options?.autoStart === true
        this.initialProgress = options?.initialProgress || 0
        this.progress = this.initialProgress
        this.timeConstant = options?.timeConstant || 1000
        this._running = false
        this._intervalFrequency = 100
        this._intervalId = null
        this._time = 0
        this._callbacks = []
        if (this.autoStart) {
            this.start()
        }
    }

    addListener(callback: (progress: number) => void) {
        this._callbacks.push(callback)
    }

    removeListener(callback: (progress: number) => void) {
        this._callbacks = this._callbacks.filter((c) => c !== callback)
    }

    start() {
        this._time = -1 * (Math.log(1 - this.progress) * this.timeConstant)
        this._intervalId = window.setInterval(this._onInterval.bind(this), this._intervalFrequency)
    }

    stop() {
        if (this._intervalId) {
            window.clearInterval(this._intervalId)
            this._intervalId = null
        }
    }

    _onInterval() {
        this._time += this._intervalFrequency
        this.setProgress(1 - Math.exp((-1 * this._time) / this.timeConstant))
    }

    setProgress(progress: number) {
        this.progress = progress
        this._callbacks.forEach((c) => c(progress))
    }
}
