import { Catalog, CatalogProduct, CatalogProductPrice, CatalogProductVariant } from "~/domains/catalog/types/Catalog"

export const catalogFromApiAdapters = (catalog: Catalog): Catalog => {
    return {
        id: catalog.id,
        organizationId: catalog.organizationId,
        supplierId: catalog.supplierId,
        name: catalog.name,
        nameTranslations: catalog.nameTranslations,
        description: catalog.description,
        descriptionTranslations: catalog.descriptionTranslations,
        catalogType: catalog.catalogType,
        isActive: catalog.isActive,
        accessType: catalog.accessType,
        sharedWith: catalog.sharedWith,
        createdAt: catalog.createdAt,
        updatedAt: catalog.updatedAt,
    }
}

export const productFromApiAdapters = (product: CatalogProduct): CatalogProduct => {
    return {
        id: product.id,
        catalogId: product.catalogId,
        defaultVariantId: product.defaultVariantId,
        organizationId: product.organizationId,
        sku: product.sku,
        label: product.label,
        labelTranslations: product.labelTranslations,
        description: product.description,
        descriptionTranslations: product.descriptionTranslations,
        status: product.status,
        unitQuantity: product.unitQuantity,
        brand: product.brand,
        defaultPrice: priceFromApiAdapters(product.defaultPrice),
        createdAt: product.createdAt,
        updatedAt: product.updatedAt,
    }
}

export const productVariantFromApiAdapters = (productVariant: CatalogProductVariant): CatalogProductVariant => {
    return {
        id: productVariant.id,
        productId: productVariant.productId,
        sku: productVariant.sku,
        label: productVariant.label,
        labelTranslations: productVariant.labelTranslations,
        description: productVariant.description,
        descriptionTranslations: productVariant.descriptionTranslations,
        defaultPrice: priceFromApiAdapters(productVariant.defaultPrice),
        defaultPriceId: productVariant.defaultPriceId,
        features: productVariant.features,
        createdAt: productVariant.createdAt,
        updatedAt: productVariant.updatedAt,
    }
}

export const priceFromApiAdapters = (price: CatalogProductPrice): CatalogProductPrice => {
    return !price
        ? {
              id: "8282308b-8233-45a5-a650-c7fc7c7b2dde",
              variantId: "8282308b-8233-45a5-a650-c7fc7c7b2dde",
              amount: "99",
              currency: "EUR",
              countryCode: "FR",
              maxOrderThreshold: 100,
              minOrderThreshold: 0,
              incotermType: "",
              expirationDate: "",
              effectiveDate: "",
              eligibleOrganizations: [],
              createdAt: "2024-11-15T13:48:41.510742",
              updatedAt: "2024-11-15T13:48:41.510756",
          }
        : {
              id: price.id,
              variantId: price.variantId,
              amount: price.amount,
              currency: price.currency,
              countryCode: price.countryCode,
              minOrderThreshold: price.minOrderThreshold,
              maxOrderThreshold: price.maxOrderThreshold,
              incotermType: price.incotermType,
              effectiveDate: price.effectiveDate,
              expirationDate: price.expirationDate,
              eligibleOrganizations: price.eligibleOrganizations,
              createdAt: price.createdAt,
              updatedAt: price.updatedAt,
          }
}
