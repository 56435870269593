import { Grid, Tooltip } from "@mui/material"
import { FC } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { formatCustomFields } from "~/domains/transactions/invoices-v1/helpers/formatCustomFields"
import { InvoiceFieldLevel } from "~/domains/transactions/invoices-v1/types/Invoice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceI } from "~/types"

const messages = defineMessages({
    title: { id: "invoice.additionalFields.title", defaultMessage: "Additional fields" },
})

interface InvoiceCustomFieldsProps {
    invoice: InvoiceI
}

export const InvoiceCustomFields: FC<InvoiceCustomFieldsProps> = ({ invoice }) => {
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectCurrentOrganizationId)

    if (!organizationId) return null

    const party = invoice.parties?.[organizationId]

    if (!party) return null

    const templateDocumentCustomFields = party?.template?.customFields.filter(
        ({ level }) => level === InvoiceFieldLevel.Document
    )
    const customFieldsFormatted = formatCustomFields(invoice.parties[organizationId], InvoiceFieldLevel.Document)

    return (
        <Card title={formatMessage(messages.title)} expandable>
            <Grid container spacing={2}>
                {(templateDocumentCustomFields || []).map(({ id, description, name }) => (
                    <Grid key={id} item xs={12} sm={6}>
                        <Tooltip title={description} arrow>
                            <div>
                                <ItemLabel>{name}</ItemLabel>
                                <p>{customFieldsFormatted[id]?.toString() || "-"}</p>
                            </div>
                        </Tooltip>
                    </Grid>
                ))}
            </Grid>
        </Card>
    )
}
