import React, { lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"

import {
    PARTNERS_ROUTE,
    PARTNER_ADDRESSES_ROUTE,
    PARTNER_CONTACTS_ROUTE,
    PARTNER_DETAILS_ROUTE,
    PARTNER_INVOICES_ROUTE,
    PARTNER_KYB_ROUTE,
    PARTNER_PAYMENT_DETAILS_ROUTE,
    PARTNER_PURCHASE_ORDERS_ROUTE,
    PARTNER_SENT_FORMS_ROUTE,
} from "./routes"

const BookOfRelations = lazy(() =>
    import("./pages/BookOfRelations").then(({ BookOfRelations }) => ({ default: BookOfRelations }))
)
const PartnerOrganizationDetails = lazy(() =>
    import("./pages/PartnerOrganizationDetails").then(({ PartnerOrganizationDetails }) => ({
        default: PartnerOrganizationDetails,
    }))
)
const PartnerInvoices = lazy(() =>
    import("./pages/PartnerInvoicesPage").then(({ PartnerInvoices }) => ({ default: PartnerInvoices }))
)

const PartnerPurchaseOrders = lazy(() =>
    import("./pages/PartnerPurchaseOrdersPage").then(({ PartnerPurchaseOrders }) => ({
        default: PartnerPurchaseOrders,
    }))
)

const PartnerAddressesPage = lazy(() =>
    import("./pages/PartnerAddressesPage").then(({ PartnerAddressesPage }) => ({
        default: PartnerAddressesPage,
    }))
)

const PartnerContactsPage = lazy(() =>
    import("./pages/PartnerContactsPage").then(({ PartnerContactsPage }) => ({ default: PartnerContactsPage }))
)

const PartnerPaymentDetailsPage = lazy(() =>
    import("./pages/PartnerPaymentDetailsPage").then(({ PartnerPaymentDetailsPage }) => ({
        default: PartnerPaymentDetailsPage,
    }))
)

const PartnerKYBPage = lazy(() =>
    import("./pages/PartnerKYBPage").then(({ PartnerKYBPage }) => ({
        default: PartnerKYBPage,
    }))
)

const PartnerSentFormsPage = lazy(() =>
    import("./pages/PartnerSentFormsPage").then(({ PartnerSentFormsPage }) => ({
        default: PartnerSentFormsPage,
    }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="partners_route">
            <Route path={PARTNERS_ROUTE} element={<BookOfRelations />} />
            <Route path={PARTNER_DETAILS_ROUTE} element={<PartnerOrganizationDetails />} />
            <Route path={PARTNER_INVOICES_ROUTE} element={<PartnerInvoices />} />
            <Route path={PARTNER_PURCHASE_ORDERS_ROUTE} element={<PartnerPurchaseOrders />} />
            <Route path={PARTNER_ADDRESSES_ROUTE} element={<PartnerAddressesPage />} />
            <Route path={PARTNER_CONTACTS_ROUTE} element={<PartnerContactsPage />} />
            <Route path={PARTNER_PAYMENT_DETAILS_ROUTE} element={<PartnerPaymentDetailsPage />} />
            <Route path={PARTNER_SENT_FORMS_ROUTE} element={<PartnerSentFormsPage />} />
            <Route path={PARTNER_KYB_ROUTE} element={<PartnerKYBPage />} />
        </Route>,
    ],
}
