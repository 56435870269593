import { Grid, Stack, Typography } from "@mui/material"
import React from "react"

import { BackLink } from "./BackLink"
import Header from "./Header"

interface Props extends React.PropsWithChildren {
    title: string
    icon?: React.ReactNode
    backLink?: string | (() => void)
    className?: string
    isShared?: boolean
    mainAction?: React.ReactNode
}

export const HeaderH1 = ({ title, icon, backLink, children, className, isShared, mainAction }: Props) => {
    return (
        <>
            <Header noHeaderSpacerLeft={true} className={className}>
                <Grid container justifyContent="space-between" alignItems="flex-end" className="header-top">
                    <Grid item className={"title " + (backLink ? "back-link" : "wrap-icon")}>
                        {backLink && !isShared ? (
                            <BackLink urlOrFunction={backLink} />
                        ) : icon ? (
                            <figure className="icon">{icon}</figure>
                        ) : null}
                        <Stack direction="row" alignItems="center" gap={2}>
                            <Typography className="wrap-title" variant="h1">
                                {title}
                            </Typography>
                            {mainAction}
                        </Stack>
                    </Grid>
                    <Grid item>{children}</Grid>
                </Grid>
            </Header>
        </>
    )
}
