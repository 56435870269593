import { MenuItem, Stack, Switch, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, Fragment, useState } from "react"
import { Plus, Trash2 } from "react-feather"
import { useIntl } from "react-intl"
import { v4 as uuid } from "uuid"

import { Button, SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { adaptEventTypeToObjectType, adaptEventTypeToTransactionType } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/messages"
import { ConfigurationProps, Event, EventTriggerNode, isEvent } from "~/domains/orchestration/flows/types"

import type { ConditionGroup, Condition as ConditionType } from "./BranchConfiguration"
import { Condition } from "./Condition"
import { ConditionBuilder } from "./ConditionBuilder"
import { ConfigurationNode } from "./ConfigurationNode"
import "./EventTriggerConfiguration.scss"

const EVENTS = Object.values(Event)

export const EventTriggerConfiguration: FC<ConfigurationProps<EventTriggerNode>> = ({
    selectedNode,
    advancedFields,
}) => {
    const { formatMessage } = useIntl()
    const state = useEditor()
    const currentFlow = state.flow
    const dispatch = useEditorDispatch()
    const initialConditionGroups: ConditionGroup[] = selectedNode.filter.conditions.map((andConditions) => ({
        id: uuid(),
        conditions: andConditions.map((condition) => ({
            id: uuid(),
            condition,
        })),
    }))

    const resetConditionBuilder = () => {
        setShowConditionBuilder(false)
        setCurrentCondition(null)
        setCurrentGroup(null)
    }

    const hasConditions = initialConditionGroups.length > 0
    const [enableConditions, setEnableConditions] = useState(hasConditions)

    // Handle the conditions
    const [conditions, setConditions] = useState(initialConditionGroups)

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const [showConditionBuilder, setShowConditionBuilder] = useState(false)

    const [currentCondition, setCurrentCondition] = useState<ConditionType | null>(null)
    const [currentGroup, setCurrentGroup] = useState<ConditionGroup | null>(null)

    const updateConditions = (conditions: ConditionGroup[]) => {
        setConditions(conditions)
        setCurrentNode({
            ...currentNode,
            filter: {
                ...currentNode.filter,
                conditions: conditions.map((g) => g.conditions.map((c) => c.condition)),
            },
            metadata: {
                ...currentNode.metadata,
            },
        })
    }

    const handleChangeCondition = (conditionGroup: ConditionGroup) => {
        const newConditions = conditions.map((g) =>
            g.id === conditionGroup.id
                ? {
                      id: uuid(),
                      conditions: conditionGroup.conditions,
                  }
                : g
        )

        updateConditions(newConditions)
    }

    const handleAddCondition = () => {
        const newConditions = [...conditions]
        newConditions.push({
            id: uuid(),
            conditions: [],
        })
        updateConditions(newConditions)
    }

    const handleDelete = (conditionGroup: ConditionGroup) => () => {
        const newConditions = conditions.filter((g) => g.id !== conditionGroup.id)
        updateConditions(newConditions)
    }

    const handleChangeEvent = (e: ChangeEvent<HTMLInputElement>): void => {
        const event = e.target.value
        if (!isEvent(event)) return

        setCurrentNode({
            ...currentNode,
            event,
        })

        const transactionType = adaptEventTypeToTransactionType(event)
        const objectType = adaptEventTypeToObjectType(event)

        // Update transactionType and objectType for all nodes in the flow
        if (currentFlow?.nodes) {
            const updatedNodes = currentFlow.nodes.map((node) => {
                const updatedNode = { ...node }
                if ("objectType" in updatedNode) {
                    updatedNode.objectType = objectType
                }
                if ("transactionType" in updatedNode) {
                    updatedNode.transactionType = transactionType
                }
                if ("respondentOrganizationId" in updatedNode) {
                    updatedNode.respondentOrganizationId =
                        event === Event.PARTNERSHIP_CREATED ? "{{ trigger_event.partnerId }}" : null
                }
                return updatedNode
            })

            dispatch({
                type: "SET_FLOW",
                payload: {
                    ...currentFlow,
                    nodes: updatedNodes,
                },
            })
        }
    }

    const handleNewCondition = (group: ConditionGroup) => {
        setShowConditionBuilder(true)
        setCurrentGroup(group)
    }

    const handleEditCondition = (condition: ConditionType) => {
        setShowConditionBuilder(true)
        setCurrentCondition(condition)
    }

    const handleSaveNewCondition = (condition: ConditionType) => {
        setShowConditionBuilder(false)
        if (currentGroup) {
            handleChangeCondition({
                ...currentGroup,
                conditions: [...currentGroup.conditions, condition],
            })
        }
    }

    const handleEnableConditions = () => {
        if (enableConditions) {
            // reset the conditions
            updateConditions([])
        }
        setEnableConditions(!enableConditions)
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    if (showConditionBuilder) {
        return (
            <ConditionBuilder
                condition={currentCondition}
                handleCancel={resetConditionBuilder}
                handleSave={handleSaveNewCondition}
            />
        )
    }

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="h5" {...messages.eventTriggerConfiguration.eventTitle} />

                <TextField
                    select
                    required
                    label={formatMessage(messages.eventTriggerConfiguration.eventSelectLabel)}
                    value={currentNode.event ?? ""}
                    onChange={handleChangeEvent}
                    fullWidth
                >
                    {EVENTS.map((event) => (
                        <MenuItem key={event} value={event}>
                            {formatMessage(messages.event[event])}
                        </MenuItem>
                    ))}
                </TextField>
            </Stack>
            <Stack gap={1} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="span" {...messages.eventTriggerConfiguration.enableConditionsLabel} />
                <Switch checked={enableConditions} onChange={handleEnableConditions} />
            </Stack>
            {enableConditions && (
                <>
                    <Stack gap={1} className={configurationNodeItemClassName}>
                        <SafeFormattedMessage tagName="h5" {...messages.eventTriggerConfiguration.conditionsTitle} />

                        {conditions.map((g, index) => (
                            <Fragment key={g.id}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    className="flows-eventTriggerConfiguration-orTitle"
                                >
                                    <SafeFormattedMessage
                                        tagName="span"
                                        {...(index === 0
                                            ? messages.eventTriggerConfiguration.orConditionFirstLabel
                                            : messages.eventTriggerConfiguration.orConditionOtherLabel)}
                                    />

                                    <Trash2 size={18} color="var(--color-grey)" onClick={handleDelete(g)} />
                                </Stack>
                                <Condition
                                    group={g}
                                    handleChangeConditions={handleChangeCondition}
                                    handleNewCondition={handleNewCondition}
                                    handleEditCondition={handleEditCondition}
                                />
                            </Fragment>
                        ))}
                    </Stack>
                    <Button
                        type="primary-light"
                        onClick={handleAddCondition}
                        className="flows-eventTriggerConfiguration-newConditionGroup"
                    >
                        <Plus size={18} />
                        <SafeFormattedMessage {...messages.eventTriggerConfiguration.newConditionGroupLabel} />
                    </Button>
                </>
            )}
            <AdvancedFields<EventTriggerNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
