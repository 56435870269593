import * as t from "io-ts"

import { Result, ResultSuccess, isResultSuccess } from "~/types/Result"
import { ParsingErrorType, genericParser } from "~/utils"

import { CountryCode, validateCountryCodeOrSetUnknown } from "./CountryCode"
import { WhitePagesAddressIO, WhitePagesIdTypes } from "./WhitePages"
import { optional } from "./utils"

export const EstablishmentIO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        active: t.boolean,
        isHeadOffice: t.boolean,
        postalCode: t.string,
        city: t.string,
        country: t.string,
    }),
    t.partial({
        addressLine1: optional(t.string),
        addressLine2: optional(t.string),
    }),
])

export const CompanyRegistrationNumberIO = t.intersection([
    t.type({
        registrationType: t.string,
        registrationNumber: t.string,
    }),
    t.partial({
        isPreferred: optional(t.boolean),
    }),
])

export const CompanyIO = t.intersection([
    t.type({
        id: t.string,
        idType: t.string,
        name: t.string,
        taxId: t.string,
        countryCode: t.string,
        registrationNumbers: t.array(CompanyRegistrationNumberIO),
    }),
    t.partial({
        primaryAddress: optional(WhitePagesAddressIO),
        establishments: t.array(EstablishmentIO),
    }),
])

export type EstablishmentI = t.TypeOf<typeof EstablishmentIO>
export type CompanyRegistrationNumberI = t.TypeOf<typeof CompanyRegistrationNumberIO>
export type CompanyI = t.TypeOf<typeof CompanyIO> & {
    countryCode: CountryCode
    idType: WhitePagesIdTypes
}

export const parseCompany = (data: unknown): Result<CompanyI, ParsingErrorType> => {
    const result = genericParser(data, CompanyIO)
    if (isResultSuccess(result)) {
        return ResultSuccess({
            ...result.result,
            idType: result.result.idType as WhitePagesIdTypes,
            countryCode: validateCountryCodeOrSetUnknown(result.result.countryCode),
        })
    }
    return result
}
