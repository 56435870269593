import React, { PropsWithChildren, ReactNode, memo, useCallback, useState } from "react"
import { AlertTriangle } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, ButtonType, Modal } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import "~/components/Modal/Modal.scss"

import "./ConfirmModal.scss"

interface Props extends PropsWithChildren {
    open: boolean
    close: (success?: boolean) => void
    onConfirm: () => Promise<boolean | void> | (boolean | void)
    title: ReactNode
    confirmButtonType?: ButtonType
    confirmButtonText?: ReactNode
}

const messages = defineMessages({
    deleteFailed: {
        id: "common.confirmModal.actionFailed",
        defaultMessage: "An unexpected error occurred.",
    },
    cancel: {
        id: "common.confirmModal.cancel",
        defaultMessage: "Cancel",
    },
    confirm: {
        id: "common.confirmModal.confirm",
        defaultMessage: "Confirm",
    },
})

const renderTitle = (title: ReactNode, confirmButtonType: ButtonType) => {
    let titleIcon: ReactNode | null
    switch (confirmButtonType) {
        case "warning":
            titleIcon = <AlertTriangle color="var(--color-yellow)" />
            break
        default:
            titleIcon = null
            break
    }
    return (
        <h4>
            {titleIcon}
            <span>{title}</span>
        </h4>
    )
}

export const ConfirmModal = memo<Props>(
    ({ open, close, onConfirm, title, confirmButtonType = "error", confirmButtonText, children }: Props) => {
        const { formatMessage } = useIntl()
        const onClose = useCallback(() => {
            close()
        }, [close])
        const [error, setError] = useState<string>()
        const confirmDelete = useCallback(async () => {
            try {
                const result = await onConfirm()
                if (result) {
                    close(true)
                } else {
                    setError(formatMessage(messages.deleteFailed))
                }
            } catch (e) {
                setError(`${e}`)
            }
        }, [onConfirm, close])

        return (
            <Modal open={open} onClose={onClose} className={`confirm-modal confirm-modal-${confirmButtonType}`}>
                <Modal.Header>{renderTitle(title, confirmButtonType)}</Modal.Header>
                <Modal.Content className="confirm-modal">
                    {children}
                    <ErrorMessage>{error}</ErrorMessage>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="neutral" onClick={onClose}>
                        {formatMessage(messages.cancel)}
                    </Button>
                    <Button type={confirmButtonType} buttonType="submit" onClick={confirmDelete}>
                        {confirmButtonText ?? formatMessage(messages.confirm)}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    },
    (prevProps, nextProps) =>
        prevProps.children === nextProps.children &&
        prevProps.title === nextProps.title &&
        prevProps.open === nextProps.open &&
        prevProps.close === nextProps.close &&
        prevProps.confirmButtonType === nextProps.confirmButtonType &&
        prevProps.onConfirm === nextProps.onConfirm
)

ConfirmModal.displayName = "ConfirmModal"
