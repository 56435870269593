import { FormGroup, MenuItem, Select, SelectChangeEvent, Typography, capitalize } from "@mui/material"
import * as Sentry from "@sentry/browser"
import { useCallback, useEffect, useMemo, useState } from "react"

import { Button, ButtonType } from "~/components"
import { usePatchUpdateInvoiceMutation } from "~/domains/transactions/invoices-v1/api/invoiceApiV1"
import { InvoiceLifeCycleItem, InvoiceParty, LifecycleGroup } from "~/domains/transactions/invoices-v1/types/Invoice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { invoiceActions, selectInvoice } from "~/store/invoice/invoiceSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const LifecycleGroupColor: Record<LifecycleGroup, string> = {
    COMPLETED_ERROR: "error",
    COMPLETED_OK: "success",
    DRAFT: "grey",
    IN_PROGRESS: "grey",
}

const findLifecycleGroupColor = (lifecycle: InvoiceLifeCycleItem[], status: string) => {
    const group = lifecycle.find((lc) => lc?.status === status)?.group
    return LifecycleGroupColor[group || "DRAFT"]
}

export const InvoiceLifeCycleNextButton = () => {
    const dispatch = useAppDispatch()
    const invoice = useAppSelector(selectInvoice)
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()

    const party = invoice?.parties?.[organizationId || ""]
    const [adjacencyLifecycles, setAdjacencyLifecycles] = useState<{ name: string; color: string }[] | null>(null)

    const lifecycle = useMemo(() => {
        const defaultLifecycle = [party?.template?.lifecycle?.default]
        const otherLifecycles = party?.template?.lifecycle?.other ?? []
        return [...defaultLifecycle, ...otherLifecycles].filter(Boolean) as InvoiceLifeCycleItem[]
    }, [party?.template?.lifecycle?.default, party?.template?.lifecycle?.other])

    useEffect(() => {
        const currentLifecycle = (lifecycle || []).find((lc) => lc?.status === party?.currentLifecycle)

        if (!lifecycle || !currentLifecycle) return

        const adjacencyLifecycles = [
            { name: currentLifecycle?.status || "", color: LifecycleGroupColor[currentLifecycle.group] },
            ...(currentLifecycle?.adjacency || []).map((adjacency) => {
                return {
                    name: adjacency,
                    color: findLifecycleGroupColor(lifecycle, adjacency),
                }
            }),
        ]

        setAdjacencyLifecycles(adjacencyLifecycles)
    }, [party?.currentLifecycle])

    const handleUpdateLifecycle = useCallback(
        async (e: SelectChangeEvent<string>) => {
            e.preventDefault()
            const nextLifecycle = e.target.value
            if (!organizationId || !invoice || !adjacencyLifecycles) return

            const parties = { [organizationId]: { ...party, currentLifecycle: nextLifecycle } } as Record<
                string,
                InvoiceParty
            >

            try {
                await patchUpdateInvoice({ id: invoice?.id, parties })
                dispatch(invoiceActions.updatePartialInvoice({ id: invoice.id, parties }))
            } catch (error) {
                Sentry.captureException(error)
            }
        },
        [organizationId, adjacencyLifecycles]
    )

    if (!adjacencyLifecycles?.length) return null

    return (
        <>
            {adjacencyLifecycles.length === 1 ? (
                <Button type={`${adjacencyLifecycles[0].color}-light` as ButtonType} readOnly>
                    {capitalize(adjacencyLifecycles[0].name)}
                </Button>
            ) : (
                <FormGroup color="grey">
                    <Select
                        value={adjacencyLifecycles[0].name}
                        onChange={handleUpdateLifecycle}
                        size="small"
                        color="secondary"
                    >
                        {adjacencyLifecycles.map(({ name, color }) => (
                            <MenuItem key={name} value={name}>
                                <Typography color={`${color}.main`}>{capitalize(name)}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormGroup>
            )}
        </>
    )
}
