import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId } from "~/api/prepareHeaders"
import { adaptFlowFromApi, adaptFlowsFromApi } from "~/domains/orchestration/flows/api/adapters/fromApiAdapters"
import { adaptFlowToApi, adaptNewFlowToApi } from "~/domains/orchestration/flows/api/adapters/toApiAdapters"
import type {
    ApiFlow,
    ApiFlows,
    CreateFlowQuery,
    Flow,
    Flows,
    GetFlowQuery,
    UpdateFlowMutation,
} from "~/domains/orchestration/flows/types"

const BASE_URL = import.meta.env.VITE_API_FLOWS_URL

export const flowsApi = createApi({
    reducerPath: "flowsApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorizationAndOrganizationId }),
    // tags are associated with the endpoint, and are used to invalidate the cache
    tagTypes: ["Flows", "Flow"],
    endpoints: (builder) => ({
        getFlow: builder.query<Flow, GetFlowQuery>({
            query: ({ flowId, params }: GetFlowQuery) => ({
                url: `flows/${flowId}`,
                params,
            }),
            providesTags: ["Flow"],
            transformResponse: (response: ApiFlow) => adaptFlowFromApi(response),
        }),
        updateFlow: builder.mutation<null, UpdateFlowMutation>({
            query: ({ flowId, body }) => ({
                url: `flows/${flowId}`,
                method: "PUT",
                body: adaptFlowToApi(body),
            }),
            invalidatesTags: ["Flows", "Flow"],
        }),
        getFlows: builder.query<Flows, boolean>({
            query: (archived: boolean) => `flows?archived=${archived}`,
            providesTags: ["Flows"],
            transformResponse: (response: ApiFlows) => adaptFlowsFromApi(response),
        }),
        createFlow: builder.mutation<ApiFlows, CreateFlowQuery>({
            query: ({ body }) => ({
                url: `flows`,
                method: "POST",
                body: adaptNewFlowToApi(body),
            }),
            invalidatesTags: ["Flows"],
        }),
    }),
})

export const {
    useGetFlowsQuery,
    useLazyGetFlowsQuery,
    useLazyGetFlowQuery,
    useCreateFlowMutation,
    useGetFlowQuery,
    useUpdateFlowMutation,
} = flowsApi
