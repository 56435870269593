import { useEffect } from "react"
import { Feather } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { useParams, useSearchParams } from "react-router-dom"

import { HeaderH1 } from "~/components"
import { FormViewer } from "~/domains/identity/features/customForms/components/FormViewer"
import { globalActions, selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    title: {
        id: "smartForms.formViewer.title",
        defaultMessage: "Form",
    },
})

export const CustomFormViewer = () => {
    const { formToken } = useParams<{ formToken: string }>()
    const { formatMessage } = useIntl()
    const [searchParams] = useSearchParams()
    const viewMode = searchParams.get("view")

    const keepSidebarOpened = useAppSelector(selectKeepSidebarOpened)
    const dispatch = useAppDispatch()

    useEffect(() => {
        // collapse sidebar for the form viewer
        if (keepSidebarOpened) {
            dispatch(globalActions.toggleKeepSidebarOpened())
        }
    }, [dispatch])

    return (
        <>
            <HeaderH1 title={formatMessage(messages.title)} icon={<Feather />} />
            {formToken && <FormViewer formToken={formToken} viewMode={viewMode} />}
        </>
    )
}
