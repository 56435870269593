import { selectPendingCustomFieldObjects } from "~/domains/transactions/custom-fields/store"
import { CreateCustomFieldObjectPayload } from "~/domains/transactions/custom-fields/types/CustomFieldValue"
import { useAppSelector } from "~/store/hooks"

export const usePendingCustomFieldsByFieldNames = (fieldNames: string[]): CreateCustomFieldObjectPayload[] => {
    const pendingCustomFieldObject = useAppSelector(selectPendingCustomFieldObjects)
    if (!pendingCustomFieldObject || !fieldNames) {
        return []
    }
    return fieldNames.map((fieldName) => {
        return pendingCustomFieldObject[fieldName]
    })
}
