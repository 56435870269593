import { SharedObject } from "~/types/SharedObjects"

export const adaptSharedObjectFromApi = (object: SharedObject): SharedObject => ({
    id: object.id,
    organizationId: object.organizationId,
    objectId: object.objectId,
    objectType: object.objectType,
    userId: object.userId,
    userOrganizationId: object.userOrganizationId,
    end: object.end,
    version: object.version,
})

export const adaptSharedObjectsFromApi = (objects: SharedObject[]): SharedObject[] =>
    objects.map((object) => adaptSharedObjectFromApi(object))
