import { generatePath, useNavigate } from "react-router-dom"

import { DocumentPayment } from "~/domains/payment"
import { INVOICE_EDIT_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { InvoiceI } from "~/types"

interface InvoicePaymentProps {
    invoice: InvoiceI
}

export const InvoicePayment = ({ invoice: { id } }: InvoicePaymentProps) => {
    const navigate = useNavigate()

    const handlePaymentUpdate = (invoiceId: string): void => {
        if (invoiceId) {
            navigate(generatePath(INVOICE_EDIT_ROUTE, { invoiceId }))
        }
    }

    if (!id) {
        return null
    }

    return <DocumentPayment documentId={id} documentPaid={false} onPaymentUpdate={handlePaymentUpdate} />
}
