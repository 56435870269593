import * as Sentry from "@sentry/browser"
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl"

interface SafeFormattedMessageProps
    extends Omit<React.ComponentProps<typeof FormattedMessage>, "id" | "defaultMessage"> {
    id?: string
    defaultMessage?: MessageDescriptor["defaultMessage"] | string
}

export const SafeFormattedMessage = ({ id, defaultMessage, ...props }: SafeFormattedMessageProps) => {
    const { locale } = useIntl()

    if (!id) {
        console.error("Missing message id", {
            locale,
            defaultMessage,
        })
        Sentry.captureException("Missing message id", {
            extra: {
                locale,
                defaultMessage,
            },
        })
        return <span>{typeof defaultMessage === "string" ? defaultMessage : "MISSING_MESSAGE"}</span>
    }

    return <FormattedMessage id={id} defaultMessage={defaultMessage} {...props} />
}
