import { InvoiceFieldLevel, InvoiceLineItemParty } from "~/domains/transactions/invoices-v1/types/Invoice"
import { InvoiceParty } from "~/domains/transactions/invoices-v1/types/Invoice"

export const formatCustomFields = (party: InvoiceParty, level: InvoiceFieldLevel, lineParty?: InvoiceLineItemParty) => {
    const customFieldsTemplate = (party?.template?.customFields || []).filter(({ level: l }) => l === level)

    const customFields = {}
    customFieldsTemplate?.forEach((customField) => {
        const customFieldValue =
            level === InvoiceFieldLevel.Line
                ? lineParty?.customFields?.[customField.id]
                : party?.customFields?.[customField.id]

        customFields[customField.id] = customFieldValue || ""
    })

    return customFields
}
