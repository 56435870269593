import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { messages } from "~/domains/orchestration/flows/messages"
import { ConfigurationProps, SendEmailNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const SendEmailConfiguration: FC<ConfigurationProps<SendEmailNode>> = ({ selectedNode, advancedFields }) => {
    const { formatMessage } = useIntl()
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode({
            ...currentNode,
            subject: e.target.value,
        })
    }

    const handleChangeBody = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode({
            ...currentNode,
            body: e.target.value,
        })
    }

    const handleChangeRecipients = (e: ChangeEvent<HTMLInputElement>) => {
        // Split by commas and remove whitespace
        const recipients = e.target.value
            .split(",")
            .map((email) => email.trim())
            .filter(Boolean)
        setCurrentNode({
            ...currentNode,
            recipientAddresses: recipients,
        })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="h5" {...messages.sendEmailConfiguration.title} />

                <TextField
                    label={formatMessage(messages.sendEmailConfiguration.subjectLabel)}
                    value={currentNode.subject}
                    size="small"
                    onChange={handleChangeSubject}
                />

                <TextField
                    label={formatMessage(messages.sendEmailConfiguration.toLabel)}
                    value={currentNode.recipientAddresses.join(", ")}
                    size="small"
                    onChange={handleChangeRecipients}
                    helperText={formatMessage(messages.sendEmailConfiguration.toHelper)}
                />

                <TextField
                    label={formatMessage(messages.sendEmailConfiguration.bodyLabel)}
                    value={currentNode.body}
                    multiline
                    rows={10}
                    onChange={handleChangeBody}
                />
            </Stack>

            <AdvancedFields<SendEmailNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
