import { Box, Grid, IconButton, Stack } from "@mui/material"
import React, { Fragment, useEffect, useState } from "react"
import { Trash2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { ItemLabel } from "~/components/ItemLabel/ItemLabel"
import { ProgressBar } from "~/components/ProgressBar/ProgressBar"
import { SubscriptionType } from "~/domains/transactions/common/subscriptions"
import {
    useDeleteInvoiceRelation,
    useInvoiceRelationsDifferences,
    useSubscribeToRelation,
} from "~/domains/transactions/document-relations/hooks"
import { InvoiceRelationI } from "~/domains/transactions/document-relations/types/InvoiceRelation"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { OrganizationId } from "~/types"

import { PurchaseOrderLink } from "./PurchaseOrderLink"

const messages = defineMessages({
    filled: {
        id: "common.kpis.filled",
        defaultMessage: "Filled",
    },
    filledAndRemaining: {
        id: "common.kpis.filledAndRemaining",
        defaultMessage: "Filled: {percentage}% - Remaining: {amount}",
    },
    filledAndExceeded: {
        id: "common.kpis.filledAndExceeded",
        defaultMessage: "Filled: {percentage}% - Exceeded: {amount}",
    },
})

interface Props {
    organizationId: OrganizationId
    invoiceId: string
    invoiceRelation: InvoiceRelationI
}

export const InvoiceRelation: React.FC<Props> = ({ organizationId, invoiceId, invoiceRelation }) => {
    const { formatNumber, formatMessage } = useIntl()
    const deleteDocumentRelation = useDeleteInvoiceRelation(organizationId, invoiceRelation.relationId)
    const { invoiceRelationsDifferences } = useInvoiceRelationsDifferences(organizationId, invoiceId)
    useSubscribeToRelation(invoiceRelation.relationId, SubscriptionType.INVOICE)

    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrders | null>(null)

    useEffect(() => {
        ;(async () => {
            setPurchaseOrder(null)
            const PO = await purchaseOrdersApi.findById(organizationId, invoiceRelation.purchaseOrderId)
            setPurchaseOrder(PO)
        })()
    }, [organizationId, invoiceRelation.purchaseOrderId])

    const differences = invoiceRelationsDifferences?.relations.find(
        ({ relationId }) => relationId === invoiceRelation.relationId
    )

    const hasKPIs = Object.keys(differences?.invoiceKpis?.totalAmount || {}).length > 0

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" gap={1}>
                <PurchaseOrderLink invoiceRelation={invoiceRelation} purchaseOrder={purchaseOrder} />
                <div>
                    <IconButton onClick={deleteDocumentRelation}>
                        <Trash2 color="var(--color-light-grey)" size={16} />
                    </IconButton>
                </div>
            </Grid>
            {hasKPIs && (
                <Box>
                    {Object.entries(differences?.invoiceKpis?.totalAmount || {}).map(
                        ([currency, { fillRate, remaining }]) => {
                            const percentage = +fillRate
                            const value = (percentage * 100).toString()

                            return (
                                <Stack key={currency} paddingTop={1}>
                                    <ProgressBar
                                        values={[
                                            {
                                                value,
                                                percentage,
                                                label: formatMessage(messages.filled),
                                                color: +remaining > 0 ? "var(--color-primary)" : "var(--color-red)",
                                            },
                                        ]}
                                    />
                                    <ItemLabel>
                                        {`${formatMessage(
                                            messages[+remaining > 0 ? "filledAndRemaining" : "filledAndExceeded"],
                                            {
                                                percentage: value,
                                                amount:
                                                    formatNumber(+remaining > 0 ? +remaining : -remaining, {
                                                        style: "currency",
                                                        currency,
                                                    }) ?? "-",
                                            }
                                        )}`}
                                    </ItemLabel>
                                </Stack>
                            )
                        }
                    )}
                </Box>
            )}
        </>
    )
}
