import * as Sentry from "@sentry/react"
import React, { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, Loader, Modal, Size } from "~/components"
import "~/components/ShareObjectModal/ShareObjectModal.scss"
import { useCheckPurchaseRequestPermissionsQuery } from "~/domains/identity/features/roles-permissions/api/permissionsApi"
import { useShareObjectPermissionsMutation } from "~/domains/identity/features/roles-permissions/api/spiceDbApi"
import { useFetchObjectPermissions } from "~/domains/identity/features/roles-permissions/store/hooks"
import {
    SpiceDBAuthorizationName,
    SpiceDBObjectType,
    SpiceDBPermissionsLiteI,
    sharedObjectPermissionsMessages,
    sharedObjectPermissionsModalMessages,
} from "~/domains/identity/features/roles-permissions/types/SpiceDB"
import { getCurrentUserPermissions } from "~/domains/identity/features/roles-permissions/utils/spiceDB"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { AuthorizationName, OrganizationId } from "~/types"

import { ObjectsList } from "./ObjectsList"
import { OnlyListView } from "./OnlyListView"
import { PermissionsSelect } from "./PermissionsSelect"
import { SearchObjects } from "./SearchObjects"

interface Props {
    open: boolean
    close: () => void
    organizationId: OrganizationId
    objectType: SpiceDBObjectType
    objectId: string
    buyerOrganization: OrganizationSummary | undefined
    supplierOrganization: OrganizationSummary | undefined
}

export const ShareObjectPermissionsModal: React.FC<Props> = ({ open, close, objectType, objectId, organizationId }) => {
    const { formatMessage } = useIntl()
    const [searchPermissions, setSearchPermissions] = useState<SpiceDBAuthorizationName[]>([])
    const [selectedObjects, setSelectedObjects] = useState<SpiceDBPermissionsLiteI[]>([])
    const [showOnlyList, setShowOnlyList] = useState<boolean>(false)

    const currentUser = useAppSelector(selectUser)
    const { sharedPermissions, loading } = useFetchObjectPermissions(objectId, objectType)
    const [shareObjectPermissions, { isLoading: shareObjectPermissionsLoading }] = useShareObjectPermissionsMutation()
    const { data: purchaseRequestsPermissions, isLoading: loadingPermissions } =
        useCheckPurchaseRequestPermissionsQuery({
            organizationId,
            authorizations: [AuthorizationName.READ, AuthorizationName.CREATE],
        })

    const objectTypeTranslation = useMemo(() => sharedObjectPermissionsMessages[objectType], [objectType])

    const currentUserPermissions = useMemo(() => {
        return sharedPermissions && purchaseRequestsPermissions
            ? getCurrentUserPermissions(currentUser.id, purchaseRequestsPermissions, sharedPermissions)
            : []
    }, [sharedPermissions, currentUser.id, purchaseRequestsPermissions])

    const handleChangePermission = async (permissions: SpiceDBAuthorizationName[]) => {
        setSearchPermissions(permissions)
    }

    const handleInvite = useCallback(async () => {
        if (!selectedObjects.length || !searchPermissions.length) return

        try {
            await shareObjectPermissions({
                objectId,
                objectType,
                body: {
                    objects: selectedObjects,
                    authorizations: searchPermissions,
                    isFirstTimeShare: true,
                    creatorOrganizationId: organizationId,
                    recepientOrganizationId: organizationId,
                },
            })

            toast.success(
                formatMessage(sharedObjectPermissionsModalMessages.shareMultiPermissionsSuccess, {
                    count: searchPermissions.length,
                })
            )
            setSelectedObjects([])
            setSearchPermissions([])
        } catch (error) {
            toast.error(formatMessage(sharedObjectPermissionsModalMessages.sharePermissionsError))
            Sentry.captureException(error, {
                extra: {
                    objectId,
                    objectType,
                    organizationId,
                },
            })
        }
    }, [
        selectedObjects,
        searchPermissions,
        organizationId,
        objectId,
        objectType,
        formatMessage,
        shareObjectPermissions,
    ])

    return (
        <>
            <Modal open={open} onClose={close} size={Size.MD} className="modal-shared-object-permissions">
                {!showOnlyList && (
                    <Modal.Header>
                        <h4>
                            {formatMessage(sharedObjectPermissionsModalMessages.title, {
                                objectType: objectTypeTranslation ? formatMessage(objectTypeTranslation) : "",
                            })}
                        </h4>
                    </Modal.Header>
                )}
                <Modal.Content>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="modal-shared-body">
                            {showOnlyList ? (
                                <OnlyListView
                                    objectType={objectType}
                                    objectId={objectId}
                                    setShowOnlyList={setShowOnlyList}
                                    sharedPermissions={sharedPermissions}
                                />
                            ) : (
                                <>
                                    <div className="shared-objects-search">
                                        <div className="shared-objects-search-container">
                                            <SearchObjects
                                                objectType={objectType}
                                                objectId={objectId}
                                                selectedObjects={selectedObjects}
                                                setSelectedObjects={setSelectedObjects}
                                            />
                                            <div className="shared-objects-search-select-container">
                                                <PermissionsSelect
                                                    currentUserPermissions={currentUserPermissions}
                                                    objectPermissions={searchPermissions}
                                                    objectType={objectType}
                                                    handleChangePermission={handleChangePermission}
                                                    showRemoveAll={false}
                                                />
                                            </div>
                                        </div>
                                        <Button
                                            type="primary"
                                            onClick={handleInvite}
                                            disabled={shareObjectPermissionsLoading || loadingPermissions}
                                        >
                                            <span>{formatMessage(sharedObjectPermissionsModalMessages.share)}</span>
                                        </Button>
                                    </div>
                                    <div className="shared-objects-text">
                                        {formatMessage(sharedObjectPermissionsModalMessages.sharedObjectsText, {
                                            objectType: objectTypeTranslation
                                                ? formatMessage(objectTypeTranslation)
                                                : "",
                                        })}
                                    </div>
                                    <div className="shared-objects">
                                        <ObjectsList
                                            objectId={objectId}
                                            objectType={objectType}
                                            sharedPermissions={sharedPermissions}
                                            setShowOnlyList={setShowOnlyList}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </Modal.Content>
                <Modal.Footer>
                    <Button type="neutral" onClick={close}>
                        {formatMessage(sharedObjectPermissionsModalMessages.close)}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
