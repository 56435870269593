import { defineMessages } from "react-intl"

import { FulfillmentStatus } from "~/domains/transactions/_shared/types"
import { SupplierStatus } from "~/domains/transactions/custom-fields/components/CompanyCustomFields/CompanyCustomFieldsConfig"
import {
    PurchaseOrderApprovalStatus,
    PurchaseOrderProgressStatus,
    PurchaseOrderStatus,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import {
    LineStatus,
    PurchaseRequestConversionStatus,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { AttachmentStatus, AttachmentStatusType, ReceivedEmailStatusType } from "~/features/ocr/types"
import { SaveStatus } from "~/hooks"
import { ChorusExternalStatus, InvoiceStatus, PaymentStatus } from "~/types"

const messages = defineMessages({
    APPROVED: {
        id: "common.status.approved",
        defaultMessage: "Approved",
    },
    PENDING: {
        id: "common.status.pending",
        defaultMessage: "Pending",
    },
    REJECTED: {
        id: "common.status.rejected",
        defaultMessage: "Rejected",
    },
    ACTIVE: {
        id: "common.status.active",
        defaultMessage: "Active",
    },
    INACTIVE: {
        id: "common.status.inactive",
        defaultMessage: "Inactive",
    },
    BLOCKLISTED: {
        id: "common.status.blocklisted",
        defaultMessage: "Blocklisted",
    },
    PROCESSED: {
        id: "common.status.processed",
        defaultMessage: "Processed",
    },
    NOTHING_TO_PROCESS: {
        id: "common.status.nothingToProcess",
        defaultMessage: "Nothing to Process",
    },
    FAILED: {
        id: "common.status.failed",
        defaultMessage: "Failed",
    },
    UNKNOWN: {
        id: "common.status.unknown",
        defaultMessage: "Unknown",
    },
    NO_ATTACHMENT: {
        id: "common.status.noAttachment",
        defaultMessage: "No Attachment",
    },
    SUCCESSFUL: {
        id: "common.status.successful",
        defaultMessage: "Successful",
    },
    UNCHANGED: {
        id: "common.status.unchanged",
        defaultMessage: "Unchanged",
    },
    SAVING: {
        id: "common.status.saving",
        defaultMessage: "Saving",
    },
    SAVED: {
        id: "common.status.saved",
        defaultMessage: "Saved",
    },
    INITIATED: {
        id: "common.status.initiated",
        defaultMessage: "Initiated",
    },
    VALIDATED: {
        id: "common.status.validated",
        defaultMessage: "Validated",
    },
    SCHEDULED: {
        id: "common.status.scheduled",
        defaultMessage: "Scheduled",
    },
    EXECUTED: {
        id: "common.status.executed",
        defaultMessage: "Executed",
    },
    CANCELLED: {
        id: "common.status.cancelled",
        defaultMessage: "Cancelled",
    },
    CONVERTED: {
        id: "common.status.converted",
        defaultMessage: "Converted",
    },
    IN_RECU: {
        id: "common.status.inRecu",
        defaultMessage: "Received",
    },
    NOT_PROCESSABLE: {
        id: "common.status.notProcessable",
        defaultMessage: "Not Processable",
    },
    DRAFT: {
        id: "common.status.draft",
        defaultMessage: "Draft",
    },
    SUBMITTED: {
        id: "common.status.submitted",
        defaultMessage: "Submitted",
    },
    OPEN: {
        id: "common.status.open",
        defaultMessage: "Open",
    },
    CLOSED: {
        id: "common.status.closed",
        defaultMessage: "Closed",
    },
    DUPLICATES: {
        id: "common.status.duplicates",
        defaultMessage: "Duplicates",
    },
    CONFIRMED: {
        id: "common.status.confirmed",
        defaultMessage: "Confirmed",
    },
    INFO_REQUESTED: {
        id: "common.status.infoRequested",
        defaultMessage: "Info Requested",
    },
    PAYMENT_SCHEDULED: {
        id: "common.status.paymentScheduled",
        defaultMessage: "Payment Scheduled",
    },
    MARKED_AS_PAID: {
        id: "common.status.markedAsPaid",
        defaultMessage: "Marked as Paid",
    },
    PAID: {
        id: "common.status.paid",
        defaultMessage: "Paid",
    },
    DRAFT_supplier: {
        id: "common.status.draftSupplier",
        defaultMessage: "Draft (Supplier)",
    },
    CONFIRMED_supplier: {
        id: "common.status.confirmedSupplier",
        defaultMessage: "Confirmed (Supplier)",
    },
    INFO_REQUESTED_supplier: {
        id: "common.status.infoRequestedSupplier",
        defaultMessage: "Info Requested (Supplier)",
    },
    VALIDATED_supplier: {
        id: "common.status.validatedSupplier",
        defaultMessage: "Validated (Supplier)",
    },
    PAYMENT_SCHEDULED_supplier: {
        id: "common.status.paymentScheduledSupplier",
        defaultMessage: "Payment Scheduled (Supplier)",
    },
    MARKED_AS_PAID_supplier: {
        id: "common.status.markedAsPaidSupplier",
        defaultMessage: "Marked as Paid (Supplier)",
    },
    PAID_supplier: {
        id: "common.status.paidSupplier",
        defaultMessage: "Paid (Supplier)",
    },
    REJECTED_supplier: {
        id: "common.status.rejectedSupplier",
        defaultMessage: "Rejected (Supplier)",
    },
    INTERNALLY_APPROVED: {
        id: "common.status.internallyApproved",
        defaultMessage: "Internally Approved",
    },
    SHARED: {
        id: "common.status.shared",
        defaultMessage: "Shared",
    },
    MUTUALLY_ACCEPTED: {
        id: "common.status.mutuallyAccepted",
        defaultMessage: "Mutually Accepted",
    },
    IN_PREPARATION: {
        id: "common.status.inPreparation",
        defaultMessage: "In Preparation",
    },
    SHIPPED: {
        id: "common.status.shipped",
        defaultMessage: "Shipped",
    },
    RECEIVED: {
        id: "common.status.received",
        defaultMessage: "Received",
    },
    OUT_FOR_DELIVERY: {
        id: "common.status.outForDelivery",
        defaultMessage: "Out for Delivery",
    },
    DELIVERED: {
        id: "common.status.delivered",
        defaultMessage: "Delivered",
    },
    ERROR_STORING: {
        id: "common.status.errorStoring",
        defaultMessage: "Error Storing",
    },
    SUCCESS: {
        id: "common.status.success",
        defaultMessage: "Success",
    },
    SENT: {
        id: "common.status.sent",
        defaultMessage: "Sent",
    },
    IN_TRAITE_SE_CPP: {
        id: "common.status.chorusExternal.inTraiteSeCpp",
        defaultMessage: "Processed SE CPP",
    },
    IN_EN_ATTENTE_TRAIT: {
        id: "common.status.chorusExternal.inEnAttenteTrait",
        defaultMessage: "In En Attente Trait",
    },
    IN_EN_COURS_TRAITE: {
        id: "common.status.chorusExternal.inEnCoursTrait",
        defaultMessage: "In En Cours Trait",
    },
    IN_INCIDENTE: {
        id: "common.status.chorusExternal.inIncidente",
        defaultMessage: "In Incidente",
    },
    IN_REJETE: {
        id: "common.status.chorusExternal.inRejete",
        defaultMessage: "In Rejete",
    },
    IN_EN_ATTENTE_RET: {
        id: "common.status.chorusExternal.inEnAttenteRet",
        defaultMessage: "In En Attente Ret",
    },
    IN_INTEGRE: {
        id: "common.status.chorusExternal.inIntegre",
        defaultMessage: "In Integre",
    },
    IN_DEPOT_PORTAIL_EN_ATTENTE_TRAITEMENT_SE_CPP: {
        id: "common.status.chorusExternal.inDepotPortailEnAttenteTraitementSeCpp",
        defaultMessage: "In Depot Portail En Attente Traitement Se Cpp",
    },
    IN_INTEGRE_PARTIEL: {
        id: "common.status.chorusExternal.inIntegrePartiel",
        defaultMessage: "In Integre Partiel",
    },
    SUSPENDUE: {
        id: "common.status.chorusExternal.suspendue",
        defaultMessage: "Suspendue",
    },
    A_RECYCLER: {
        id: "common.status.chorusExternal.aRecycler",
        defaultMessage: "A Recycler",
    },
    COMPLETEE: {
        id: "common.status.chorusExternal.completee",
        defaultMessage: "Completee",
    },
    COMPTABILISEE: {
        id: "common.status.chorusExternal.comptabilisee",
        defaultMessage: "Comptabilisee",
    },
    MISE_A_DISPOSITION: {
        id: "common.status.chorusExternal.miseADisposition",
        defaultMessage: "Mise A Disposition",
    },
    MISE_A_DISPOSITION_COMPTABLE: {
        id: "common.status.chorusExternal.miseADispositionComptable",
        defaultMessage: "Mise A Disposition Comptable",
    },
    MISE_EN_PAIEMENT: {
        id: "common.status.chorusExternal.miseEnPaiement",
        defaultMessage: "Mise En Paiement",
    },
    REJETEE: {
        id: "common.status.chorusExternal.rejetee",
        defaultMessage: "Rejetee",
    },
    SERVICE_FAIT: {
        id: "common.status.chorusExternal.serviceFait",
        defaultMessage: "Service Fait",
    },
    MANDATEE: {
        id: "common.status.chorusExternal.mandatee",
        defaultMessage: "Mandatee",
    },
    DEPOSEE: {
        id: "common.status.chorusExternal.deposee",
        defaultMessage: "Deposee",
    },
})

export const statusMessages = messages

export const globalStatus: Record<
    | LineStatus
    | InvoiceStatus
    | PurchaseOrderApprovalStatus
    | PurchaseOrderProgressStatus
    | FulfillmentStatus
    | SupplierStatus
    | ReceivedEmailStatusType
    | AttachmentStatusType
    | AttachmentStatus
    | PurchaseOrderStatus
    | PurchaseRequestConversionStatus
    | SaveStatus
    | PaymentStatus
    | PurchaseRequestStatus
    | ChorusExternalStatus,
    { message: { id: string; defaultMessage: string }; color: string }
> = {
    // LineItems
    /* Duplicates:
    - PurchaseOrderApprovalStatus.APPROVED
    - PurchaseRequestStatus.APPROVED
    */
    [LineStatus.APPROVED]: { message: messages.APPROVED, color: "var(--color-green)" },
    /* Duplicates:
    - PurchaseOrderApprovalStatus.PENDING
    - FulfillmentStatus.PENDING
    - ReceivedEmailStatusType.PENDING
    - AttachmentStatusType.PENDING
    */
    [LineStatus.PENDING]: { message: messages.PENDING, color: "var(--color-yellow)" },
    /* Duplicates:
    - InvoiceStatus.REJECTED,
    - PurchaseOrderApprovalStatus.REJECTED
    - PurchaseRequestStatus.REJECTED
    */
    [LineStatus.REJECTED]: { message: messages.REJECTED, color: "var(--color-red)" },

    // Invoice
    /* Duplicates:
    - PurchaseOrderProgressStatus.DRAFT
    - PurchaseOrderStatus.DRAFT
    - PurchaseRequestConversionStatus.DRAFT
    - PurchaseRequestStatus.DRAFT
    */
    [InvoiceStatus.DRAFT]: { message: messages.DRAFT, color: "var(--color-gray)" },
    [InvoiceStatus.DUPLICATES]: { message: messages.DUPLICATES, color: "var(--color-yellow)" },
    [InvoiceStatus.CONFIRMED]: { message: messages.CONFIRMED, color: "var(--color-blue)" },
    [InvoiceStatus.INFO_REQUESTED]: { message: messages.INFO_REQUESTED, color: "var(--color-red)" },

    /* Duplicates:
    - PaymentStatus.VALIDATED
    */
    [InvoiceStatus.VALIDATED]: { message: messages.VALIDATED, color: "var(--color-green)" },
    [InvoiceStatus.PAYMENT_SCHEDULED]: {
        message: messages.PAYMENT_SCHEDULED,
        color: "var(--color-blue)",
    },
    [InvoiceStatus.MARKED_AS_PAID]: { message: messages.MARKED_AS_PAID, color: "var(--color-green)" },
    [InvoiceStatus.PAID]: { message: messages.PAID, color: "var(--color-green)" },
    [InvoiceStatus.DRAFT_supplier]: { message: messages.DRAFT_supplier, color: "var(--color-gray)" },
    [InvoiceStatus.CONFIRMED_supplier]: {
        message: messages.CONFIRMED_supplier,
        color: "var(--color-blue)",
    },
    [InvoiceStatus.INFO_REQUESTED_supplier]: {
        message: messages.INFO_REQUESTED_supplier,
        color: "var(--color-red)",
    },
    [InvoiceStatus.VALIDATED_supplier]: {
        message: messages.VALIDATED_supplier,
        color: "var(--color-green)",
    },
    [InvoiceStatus.PAYMENT_SCHEDULED_supplier]: {
        message: messages.PAYMENT_SCHEDULED_supplier,
        color: "var(--color-blue)",
    },
    [InvoiceStatus.MARKED_AS_PAID_supplier]: {
        message: messages.MARKED_AS_PAID_supplier,
        color: "var(--color-green)",
    },
    [InvoiceStatus.PAID_supplier]: { message: messages.PAID_supplier, color: "var(--color-green)" },
    [InvoiceStatus.REJECTED_supplier]: {
        message: messages.REJECTED_supplier,
        color: "var(--color-red)",
    },

    // PurchaseOrderApprovalStatus
    /* Duplicates:
    - ReceivedEmailStatusType.UNKNOWN
    - AttachmentStatusType.UNKNOWN
    */
    [PurchaseOrderApprovalStatus.UNKNOWN]: {
        message: messages.UNKNOWN,
        color: "var(--color-gray)",
    },
    // ... Add other PurchaseOrderApprovalStatus values ...
    // PurchaseOrderProgressStatus
    /* Duplicates:
    - PurchaseOrderStatus.SUBMITTED
    - PurchaseRequestConversionStatus.SUBMITTED
    - PurchaseRequestStatus.SUBMITTED
    */
    [PurchaseOrderProgressStatus.SUBMITTED]: {
        message: messages.SUBMITTED,
        color: "var(--color-yellow)",
    },
    [PurchaseOrderProgressStatus.INTERNALLY_APPROVED]: {
        message: messages.INTERNALLY_APPROVED,
        color: "var(--color-blue)",
    },
    [PurchaseOrderProgressStatus.SHARED]: {
        message: messages.SHARED,
        color: "var(--color-blue)",
    },
    [PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED]: {
        message: messages.MUTUALLY_ACCEPTED,
        color: "var(--color-green)",
    },
    /* Duplicates:
    - FulfillmentStatus.IN_PREPARATION
    */
    [PurchaseOrderProgressStatus.IN_PREPARATION]: {
        message: messages.IN_PREPARATION,
        color: "var(--color-yellow)",
    },
    [PurchaseOrderProgressStatus.SHIPPED]: {
        message: messages.SHIPPED,
        color: "var(--color-blue)",
    },
    [PurchaseOrderProgressStatus.RECEIVED]: {
        message: messages.RECEIVED,
        color: "var(--color-green)",
    },

    /* Duplicates:
    - PurchaseOrderStatus.CLOSED
    */
    [PurchaseOrderProgressStatus.CLOSED]: {
        message: messages.CLOSED,
        color: "var(--color-gray)",
    },

    // FulfillmentStatus
    [FulfillmentStatus.OUT_FOR_DELIVERY]: {
        message: messages.OUT_FOR_DELIVERY,
        color: "var(--color-blue)",
    },
    [FulfillmentStatus.DELIVERED]: { message: messages.DELIVERED, color: "var(--color-green)" },

    // SupplierStatus
    [SupplierStatus.ACTIVE]: { message: messages.ACTIVE, color: "var(--color-green)" },
    [SupplierStatus.INACTIVE]: { message: messages.INACTIVE, color: "var(--color-red)" },
    [SupplierStatus.BLOCKLISTED]: { message: messages.BLOCKLISTED, color: "var(--color-orange)" },
    // ... Add other SupplierStatus values ...
    // ReceivedEmailStatusType
    [ReceivedEmailStatusType.PROCESSED]: {
        message: messages.PROCESSED,
        color: "var(--color-green)",
    },
    [ReceivedEmailStatusType.NOTHING_TO_PROCESS]: {
        message: messages.NOTHING_TO_PROCESS,
        color: "var(--color-gray)",
    },

    /* Duplicates:
    - AttachmentStatusType.FAILED
    - AttachmentStatus.FAILED
    - PaymentStatus.FAILED
    */
    [ReceivedEmailStatusType.FAILED]: {
        message: messages.FAILED,
        color: "var(--color-red)",
    },

    // AttachmentStatusType
    [AttachmentStatusType.NOT_PROCESSABLE]: {
        message: messages.NOT_PROCESSABLE,
        color: "var(--color-orange)",
    },
    [AttachmentStatusType.ERROR_STORING]: {
        message: messages.ERROR_STORING,
        color: "var(--color-red)",
    },
    [AttachmentStatusType.SUCCESS]: {
        message: messages.SUCCESS,
        color: "var(--color-green)",
    },

    // AttachmentStatus
    [AttachmentStatus.NO_ATTACHMENT]: { message: messages.NO_ATTACHMENT, color: "var(--color-gray)" },
    [AttachmentStatus.SUCCESSFUL]: { message: messages.SUCCESSFUL, color: "var(--color-green)" },

    // PurchaseOrderStatus
    [PurchaseOrderStatus.OPEN]: { message: messages.OPEN, color: "var(--color-green)" },
    // ... Add other PurchaseOrderStatus values ...
    // PurchaseRequestConversionStatus
    [PurchaseRequestConversionStatus.SENT]: {
        message: messages.SENT,
        color: "var(--color-blue)",
    },
    // ... Add other PurchaseRequestConversionStatus values ...
    // SaveStatus
    [SaveStatus.UNCHANGED]: { message: messages.UNCHANGED, color: "var(--color-gray)" },
    [SaveStatus.SAVING]: { message: messages.SAVING, color: "var(--color-yellow)" },
    [SaveStatus.SAVED]: { message: messages.SAVED, color: "var(--color-green)" },
    // ... Add other SaveStatus values ...
    // PaymentStatus
    [PaymentStatus.INITIATED]: { message: messages.INITIATED, color: "var(--color-blue)" },
    [PaymentStatus.SCHEDULED]: { message: messages.SCHEDULED, color: "var(--color-yellow)" },
    [PaymentStatus.EXECUTED]: { message: messages.EXECUTED, color: "var(--color-green)" },
    [PaymentStatus.CANCELLED]: { message: messages.CANCELLED, color: "var(--color-gray)" },

    // PurchaseRequestStatus
    [PurchaseRequestStatus.CONVERTED]: {
        message: messages.CONVERTED,
        color: "var(--color-green)",
    },

    // ChorusExternalStatus
    [ChorusExternalStatus.IN_RECU]: { message: messages.IN_RECU, color: "var(--color-blue)" },
    [ChorusExternalStatus.IN_TRAITE_SE_CPP]: {
        message: messages.IN_TRAITE_SE_CPP,
        color: "var(--color-green)",
    },
    [ChorusExternalStatus.IN_EN_ATTENTE_TRAIT]: {
        message: messages.IN_EN_ATTENTE_TRAIT,
        color: "var(--color-yellow)",
    },
    [ChorusExternalStatus.IN_EN_COURS_TRAITE]: {
        message: messages.IN_EN_COURS_TRAITE,
        color: "var(--color-blue)",
    },
    [ChorusExternalStatus.IN_INCIDENTE]: {
        message: messages.IN_INCIDENTE,
        color: "var(--color-orange)",
    },
    [ChorusExternalStatus.IN_REJETE]: { message: messages.IN_REJETE, color: "var(--color-red)" },
    [ChorusExternalStatus.IN_EN_ATTENTE_RET]: {
        message: messages.IN_EN_ATTENTE_RET,
        color: "var(--color-yellow)",
    },
    [ChorusExternalStatus.IN_INTEGRE]: {
        message: messages.IN_INTEGRE,
        color: "var(--color-green)",
    },
    [ChorusExternalStatus.IN_DEPOT_PORTAIL_EN_ATTENTE_TRAITEMENT_SE_CPP]: {
        message: messages.IN_DEPOT_PORTAIL_EN_ATTENTE_TRAITEMENT_SE_CPP,
        color: "var(--color-yellow)",
    },
    [ChorusExternalStatus.IN_INTEGRE_PARTIEL]: {
        message: messages.IN_INTEGRE_PARTIEL,
        color: "var(--color-orange)",
    },
    [ChorusExternalStatus.SUSPENDUE]: {
        message: messages.SUSPENDUE,
        color: "var(--color-orange)",
    },
    [ChorusExternalStatus.A_RECYCLER]: {
        message: messages.A_RECYCLER,
        color: "var(--color-orange)",
    },
    [ChorusExternalStatus.COMPLETEE]: {
        message: messages.COMPLETEE,
        color: "var(--color-green)",
    },
    [ChorusExternalStatus.COMPTABILISEE]: {
        message: messages.COMPTABILISEE,
        color: "var(--color-green)",
    },
    [ChorusExternalStatus.MISE_A_DISPOSITION]: {
        message: messages.MISE_A_DISPOSITION,
        color: "var(--color-blue)",
    },
    [ChorusExternalStatus.MISE_A_DISPOSITION_COMPTABLE]: {
        message: messages.MISE_A_DISPOSITION_COMPTABLE,
        color: "var(--color-blue)",
    },
    [ChorusExternalStatus.MISE_EN_PAIEMENT]: {
        message: messages.MISE_EN_PAIEMENT,
        color: "var(--color-green)",
    },
    [ChorusExternalStatus.REJETEE]: { message: messages.REJETEE, color: "var(--color-red)" },
    [ChorusExternalStatus.SERVICE_FAIT]: {
        message: messages.SERVICE_FAIT,
        color: "var(--color-green)",
    },
    [ChorusExternalStatus.MANDATEE]: { message: messages.MANDATEE, color: "var(--color-green)" },
    [ChorusExternalStatus.DEPOSEE]: { message: messages.DEPOSEE, color: "var(--color-blue)" },
    [FulfillmentStatus.CANCELED]: { message: messages.CANCELLED, color: "var(--color-gray)" },
}
