import { Grid, Stack } from "@mui/material"
import { Dayjs } from "dayjs"
import React, { ChangeEvent, lazy, useCallback, useEffect, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { organizationApi } from "~/api"
import { Card, CurrencySelector, HeaderH1, Loader, ShareObjectModal } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import { DraftDocumentI } from "~/components/UploadDocument/Documents"
import { Documents } from "~/components/UploadDocument/Documents/Documents"
import { CommunicationRoom } from "~/domains/communication/components/CommunicationRoom"
import { DocumentObjectType } from "~/domains/identity/features/documents/types"
import { SavePayloadType } from "~/domains/identity/features/organizations/components/ModalOrganizationSelectorDetails/types"
import { useFetchSharedObjects } from "~/domains/identity/features/roles-permissions/store/hooks/useFetchSharedObjects"
import { selectPartnersBrandNames } from "~/domains/partners/store/bookOfRelationsSlice"
import { TagsSelector } from "~/domains/tags/components/TagsSelector"
import {
    TagsSelectorForLineCells,
    TagsSelectorWithStateForLineCells,
} from "~/domains/tags/components/TagsSelector/TagsSelectorForLineCells"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { tagsActions } from "~/domains/tags/store/tagsSlice"
import { TagGroupI, TagObjectType } from "~/domains/tags/types"
import { FulfillmentStatus, PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import "~/domains/transactions/assets/css/Purchases.scss"
import { withSocketIOProvider } from "~/domains/transactions/common/subscriptions/components/SocketIOContext"
import { Dates } from "~/domains/transactions/components/Dates/Dates"
import { Description } from "~/domains/transactions/components/Description/Description"
import { useTagsForLines } from "~/domains/transactions/components/Items/hooks/useTagsForLines"
import { ModalAddToBudget } from "~/domains/transactions/components/ModalAddToBudget/ModalAddToBudget"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { RequesterName } from "~/domains/transactions/components/RequesterName/RequesterName"
import { StepsPurchaseOrder } from "~/domains/transactions/components/Steps/StepsPurchaseOrder"
import { TotalAmount } from "~/domains/transactions/components/TotalAmount/TotalAmount"
import { TransactionInBudgets } from "~/domains/transactions/components/TransactionInBudgets"
import { LinesTabs } from "~/domains/transactions/custom-fields/components/LinesTabs"
import { CustomFieldObjectType } from "~/domains/transactions/custom-fields/types/CustomFieldObjectType"
import { PurchaseOrderDocumentRelations } from "~/domains/transactions/document-relations/components/PurchaseOrderDocumentRelations"
import { useUpdateFulfillmentStatusMutation } from "~/domains/transactions/purchase-orders/api/purchaseOrderApi"
import { ActionsHeader } from "~/domains/transactions/purchase-orders/components/Actions/ActionsHeader"
import { ActionsHeaderCreate } from "~/domains/transactions/purchase-orders/components/Actions/ActionsHeaderCreate"
import { POInfosVeoliaCustomFields } from "~/domains/transactions/purchase-orders/components/POInfosCustomFields/POInfosVeoliaCustomFIelds"
import { PurchaseDocumentCustomFields } from "~/domains/transactions/purchase-orders/components/PurchaseDocumentCustomFields"
import { PurchaseRequestLink } from "~/domains/transactions/purchase-orders/components/PurchaseRequestLink/PurchaseRequestLink"
import { TagsForCreatingPurchaseOrder } from "~/domains/transactions/purchase-orders/components/TagsForCreatingPurchaseOrder"
import { usePurchaseOrder } from "~/domains/transactions/purchase-orders/hooks"
import {
    PURCHASE_ORDERS_ROUTE,
    PURCHASE_ORDER_EDIT_ROUTE,
    PURCHASE_ORDER_ROUTE,
} from "~/domains/transactions/purchase-orders/routes"
import {
    useFetchPurchaseOrder,
    useUpdatePOStatus,
    useUpdatePurchaseOrder,
} from "~/domains/transactions/purchase-orders/store/hooks"
import { useCreateDraftPurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks/useCreateDraftPurchaseOrder"
import {
    purchaseOrdersActions,
    selectPurchaseOrdersError,
    selectPurchaseOrdersLoading,
    selectPurchaseViewType,
} from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import {
    Address,
    PurchaseOrderApprovalStatus,
    PurchaseOrderLine,
    PurchaseOrderProgressStatus,
    PurchaseOrderStatus,
    PurchaseOrders,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { AddTransactionPayloadI, TransactionType } from "~/features/budget/types"
import { useTitle } from "~/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { selectIsConnected } from "~/store/auth/authSlice"
import { useFetchBudgetsData } from "~/store/budget/hooks"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { organizationActions, selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { CurrencyCodes, CurrencyI, OrganizationAddressI, OrganizationI, OrganizationId, ViewTypeI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"
import useQueryParams from "~/utils/hooks/useQueryParams"

const Items = lazy(() =>
    import("~/domains/transactions/components/Items/ItemsPO").then(({ ItemsPO }) => ({ default: ItemsPO }))
)
const Organizations = lazy(() =>
    import("~/domains/transactions/components/Organizations/Organizations").then(({ Organizations }) => ({
        default: Organizations,
    }))
)

const messages = defineMessages({
    htmlTitle: { id: "purchase.orders.order.htmlTitle", defaultMessage: "Purchase order" },
    titleEdit: { id: "purchase.orders.order.titleEdit", defaultMessage: "Edit PO" },
    titleView: { id: "purchase.orders.order.titleView", defaultMessage: "PO" },
    titleNew: { id: "purchase.orders.order.titleNew", defaultMessage: "New PO" },
    items: { id: "purchase.orders.order.items", defaultMessage: "Items" },
    esgCustomFields: { id: "purchase.orders.order.esgCustomFields", defaultMessage: "ESG" },
    taxLabel: { id: "purchase.orders.order.taxLabel", defaultMessage: "Tax excl." },
    purchaseRequest: { id: "purchase.orders.order.purchaseRequest", defaultMessage: "Purchase request" },
    cancel: { id: "purchase.orders.order.cancel", defaultMessage: "Cancel this PO" },
    addToBudget: { id: "purchase.orders.order.addToBudget", defaultMessage: "Add to budget" },
    budgets: { id: "purchase.orders.order.budgets", defaultMessage: "Budgets" },
    errorChangedOrganization: {
        id: "purchase.requests.request.errorChangedOrganization",
        defaultMessage: "You have changed the organization. This organization does not have this purchase request.",
    },
    tagsTitle: { id: "purchase.orders.order.tagsTitle", defaultMessage: "Tags" },
    currency: {
        id: "purchase.requests.modalNewPR.currency",
        defaultMessage: "Currency",
    },
})

interface Props extends JSX.IntrinsicAttributes {
    edit?: boolean
}

interface ViewProps extends Props {
    organization?: OrganizationI
    purchaseOrder: PurchaseOrders
    isFromSharedEmail: boolean
    sharedOrganizationId?: OrganizationId
    sharedUserId?: string
    loading?: boolean
    newPO?: boolean
}

const editablePurchaseOrderStatuses: Partial<Record<PurchaseOrderProgressStatus, boolean>> = {
    [PurchaseOrderProgressStatus.DRAFT]: true,
    [PurchaseOrderProgressStatus.INTERNALLY_APPROVED]: true,
    [PurchaseOrderProgressStatus.IN_PREPARATION]: true,
    [PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED]: true,
    [PurchaseOrderProgressStatus.RECEIVED]: true,
    [PurchaseOrderProgressStatus.SHARED]: true,
    [PurchaseOrderProgressStatus.SHIPPED]: true,
    [PurchaseOrderProgressStatus.SUBMITTED]: true,
}

const purchaseOrderStatusesWithoutCommunication: Partial<Record<PurchaseOrderProgressStatus, boolean>> = {
    [PurchaseOrderProgressStatus.DRAFT]: true,
    [PurchaseOrderProgressStatus.INTERNALLY_APPROVED]: true,
    [PurchaseOrderProgressStatus.IN_PREPARATION]: true,
    [PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED]: true,
    [PurchaseOrderProgressStatus.RECEIVED]: true,
    [PurchaseOrderProgressStatus.SHARED]: true,
    [PurchaseOrderProgressStatus.SHIPPED]: true,
    [PurchaseOrderProgressStatus.SUBMITTED]: true,
}

export const organizationAddressToAddress = (address: OrganizationAddressI): Address => ({
    city: address.city,
    country: address.country,
    street: address.addressLine,
    street2: address.secondaryAddressLine,
    zipCode: address.zipCode,
})

const PurchaseOrderView: React.FC<ViewProps> = ({
    edit,
    organization,
    purchaseOrder,
    isFromSharedEmail,
    sharedOrganizationId,
    sharedUserId,
    loading = false,
    newPO = false,
}) => {
    const { formatMessage } = useIntl()
    const user = useAppSelector(selectUser)
    const isConnected = useAppSelector(selectIsConnected)
    const viewType = useAppSelector(selectPurchaseViewType)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [isShared, setIshared] = useState<boolean>(isFromSharedEmail)
    const [modalAddToBudgetVisible, setModalAddToBudgetVisible] = useState<boolean>(false)
    const [shareObjectModalOpen, setShareObjectModalOpen] = useState<boolean>(false)
    const [draftDocuments, setDraftDocuments] = useState<DraftDocumentI[]>([])
    const [linesNeedApproval, setLinesNeedApproval] = useState<boolean>(false)

    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organization?.id)
    const organizationSharings = useFetchSharedObjects(organization?.id)
    const brandNames = useAppSelector(selectPartnersBrandNames)

    const { tagGroups } = useOrganizationTagGroups(organization?.id)
    const {
        selectedTags: tags,
        setSelectedTags: setTags,
        fetchTags,
    } = useTagsForLines({
        lines: purchaseOrder.lines,
        organizationId: organization?.id,
        tagGroups,
        objectType: TagObjectType.PURCHASE_ORDER_LINE,
    })

    const pageMode = newPO ? PurchaseViewType.ADD : edit ? PurchaseViewType.EDIT : PurchaseViewType.VIEW
    const title = formatMessage(messages[`title${pageMode === PurchaseViewType.EDIT ? "Edit" : "View"}`])

    const { budgetsData } = useFetchBudgetsData(organization?.id, false)
    const transactionPayload: AddTransactionPayloadI = {
        transactionRefId: purchaseOrder.id,
        transactionStatus: purchaseOrder.status,
        transactionType: TransactionType.PURCHASE_ORDER,
        supplierOrgId: purchaseOrder.supplierId,
        buyerOrgId: organization?.id ?? "",
        description: purchaseOrder.description,
        amount: purchaseOrder.totalAmount ?? 0,
        amountWithoutTaxes: purchaseOrder.totalAmountExcludingTax,
        amountRemainingToPay: 0,
        expectedDeliveryDate: purchaseOrder.expectedDeliveryDate,
        partialAmount: 0,
        partialRate: 0,
    }

    const {
        updatePO,
        loading: updateLoading,
        error: updateError,
    } = useUpdatePurchaseOrder(purchaseOrder.id, organization?.id)
    const { updateStatus } = useUpdatePOStatus(purchaseOrder.id)
    const [updateFulfillmentStatus] = useUpdateFulfillmentStatusMutation()

    const getOrganizationName = useCallback(
        (sideId: string, organizationId?: string, sideName?: string): string => {
            if (sideId && organizationId && sideId !== organizationId) {
                return brandNames[sideId] ?? sideName ?? ""
            }
            return sideName ?? ""
        },
        [brandNames]
    )

    const updateAddresses = useCallback(
        async (payload: SavePayloadType) => {
            if (pageMode === PurchaseViewType.ADD) {
                if (payload.organizationAddress) {
                    dispatch(
                        purchaseOrdersActions.updateData({
                            field: "billingAddress",
                            value: organizationAddressToAddress(payload.organizationAddress),
                        })
                    )
                }
                if (payload.sendingAddress) {
                    dispatch(
                        purchaseOrdersActions.updateData({
                            field: "shippingAddress",
                            value: organizationAddressToAddress(payload.sendingAddress),
                        })
                    )
                }
            } else {
                const updatePayload: PurchaseOrders = {
                    ...purchaseOrder,
                    ...(payload.sendingAddress
                        ? { shippingAddress: organizationAddressToAddress(payload.sendingAddress) }
                        : {}),
                    ...(payload.organizationAddress
                        ? { billingAddress: organizationAddressToAddress(payload.organizationAddress) }
                        : {}),
                }
                await updatePO(updatePayload)
            }
        },
        [purchaseOrder, pageMode, updatePO]
    )

    const setDeliveryDate = useCallback((date: Dayjs | null) => {
        if (date) {
            dispatch(
                purchaseOrdersActions.updateData({
                    field: "expectedDeliveryDate",
                    value: date.add(18, "hours").toISOString(),
                })
            )
        }
    }, [])

    const handleChangeDescription = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            purchaseOrdersActions.updateData({
                field: "description",
                value: e.target.value,
            })
        )
    }, [])

    const handleCurrencyChange = useCallback(
        (currency: CurrencyI | null) => {
            if (currency) {
                dispatch(
                    purchaseOrdersActions.updateData({
                        field: "currency",
                        value: currency.code as CurrencyCodes,
                    })
                )
            }
        },
        [dispatch]
    )

    const from: OrganizationSummary = {
        id: purchaseOrder.buyerId,
        name: getOrganizationName(purchaseOrder.buyerId, organization?.id, purchaseOrder.buyerName),
        type: ViewTypeI.buyer,
    }
    const to: OrganizationSummary = {
        id: purchaseOrder.supplierId,
        name: getOrganizationName(purchaseOrder.supplierId, organization?.id, purchaseOrder.supplierName),
        type: ViewTypeI.supplier,
    }

    const tagSelectorContext = useMemo(
        () => ({
            page: "purchase-order",
            viewType,
            purchaseOrder,
            organization,
            user,
        }),
        [purchaseOrder, organization, viewType, user]
    )

    const objectSharings = useMemo(
        () =>
            organizationSharings.filter(
                (share) => share.objectId === purchaseOrder.id && share.objectType === SharedObjectType.PurchaseOrder
            ),
        [organizationSharings, purchaseOrder.id]
    )

    useEffect(() => {
        if (tagGroups && tagGroups.length) {
            fetchTags()
        }
    }, [tagGroups])

    useEffect(() => {
        if (isConnected && isFromSharedEmail && sharedOrganizationId && sharedUserId && user.id === sharedUserId) {
            const organizationAlreadyExists = user.organizations.find((org) => org.id === sharedOrganizationId)
            if (!organizationAlreadyExists) {
                const membershipAlreadyRequested = user.membershipRequests?.find(
                    (org) => org.id === sharedOrganizationId
                )
                if (!membershipAlreadyRequested) {
                    organizationApi.requestMembership(sharedOrganizationId).then(() => {
                        document.location.reload()
                    })
                }
            } else if (purchaseOrder && purchaseOrder.id) {
                dispatch(organizationActions.setCurrentOrganization(sharedOrganizationId))
                setIshared(false)
            }
        }
    }, [isConnected, user, isFromSharedEmail, sharedOrganizationId, purchaseOrder, sharedUserId])

    const showModalAddToBudget = useCallback(() => setModalAddToBudgetVisible(true), [])
    const hideModalAddToBudget = useCallback(() => setModalAddToBudgetVisible(false), [])

    const showShareObjectModal = useCallback(() => setShareObjectModalOpen(true), [setShareObjectModalOpen])
    const hideShareObjectModal = useCallback(() => setShareObjectModalOpen(false), [setShareObjectModalOpen])

    const handleSuccess = useCallback(async () => {
        await fetchPurchaseOrder(purchaseOrder.id, true)
    }, [dispatch, purchaseOrder])

    const updatePurchaseOrderStatus = useCallback(
        async (status: PurchaseOrderStatus) => {
            if (organization) {
                dispatch(purchaseOrdersActions.updatePO())
                await updateStatus(organization.id, status)
                await fetchPurchaseOrder(purchaseOrder.id)
                dispatch(purchaseOrdersActions.updatePOSuccess())
            }
        },
        [organization?.id, purchaseOrder.id, updateStatus, fetchPurchaseOrder]
    )

    const handleMarkAllInPreparation = useCallback(() => {
        if (!organization) return

        const lines = purchaseOrder.lines.map((line) => ({ id: line.id ?? "", quantity: line.quantity ?? 1 }))
        updateFulfillmentStatus({
            organizationId: organization?.id,
            purchaseOrderId: purchaseOrder?.id,
            lines,
            status: FulfillmentStatus.IN_PREPARATION,
        })
    }, [organization, purchaseOrder.lines, updateFulfillmentStatus])

    const handleSend = useCallback(() => {
        if (viewType === ViewTypeI.buyer && objectSharings.length === 0) {
            showShareObjectModal()
        } else if (organization) {
            updatePurchaseOrderStatus(PurchaseOrderStatus.OPEN)
        }
    }, [purchaseOrder, organization, objectSharings, updatePurchaseOrderStatus, showShareObjectModal])

    const handleSave = async () => {
        await updatePO(purchaseOrder)
        navigate(generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: purchaseOrder.id }))
    }

    const handleSubmit = useCallback(async () => {
        await updatePurchaseOrderStatus(PurchaseOrderStatus.SUBMITTED)
    }, [updatePurchaseOrderStatus])

    const handleEdit = useCallback(() => {
        navigate(generatePath(PURCHASE_ORDER_EDIT_ROUTE, { purchaseOrderId: purchaseOrder.id }))
    }, [purchaseOrder, navigate, generatePath])

    const handleSharedObject = useCallback(async () => {
        setTimeout(async () => {
            await updatePurchaseOrderStatus(PurchaseOrderStatus.OPEN)
            setShareObjectModalOpen(false)
        }, 500)
    }, [updatePurchaseOrderStatus])

    /// This handles the new approval service approval requirement for a line.
    /// The line renderer component calls the API to see if the line requires approval and fires this callback in case it does.
    const handleApprovalRequiredForLine = useCallback(() => {
        setLinesNeedApproval(true)
    }, [purchaseOrder])

    const renderLineTags = useCallback(
        (line: PurchaseOrderLine, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => {
            if (!user || !organization) return null

            if (line.id) {
                return (
                    <TagsSelectorForLineCells
                        objectId={line.id}
                        tags={tags}
                        setTags={setTags}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            if (line.temporaryId) {
                return (
                    <TagsSelectorWithStateForLineCells
                        temporaryId={line.temporaryId}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            return null
        },
        [user, organization, tags, setTags, purchaseOrder]
    )

    const otherOrganizations = useMemo(() => {
        const otherOrganizations: OrganizationId[] = []
        if (purchaseOrderStatusesWithoutCommunication[purchaseOrder.status]) return otherOrganizations
        if (purchaseOrder.buyerId && (!organization || purchaseOrder.buyerId !== organization.id)) {
            otherOrganizations.push(purchaseOrder.buyerId)
        }
        if (purchaseOrder.supplierId && (!organization || purchaseOrder.supplierId !== organization.id)) {
            otherOrganizations.push(purchaseOrder.supplierId)
        }
        return otherOrganizations
    }, [organization, purchaseOrder])

    const allLinesApproved = useMemo(
        () =>
            purchaseOrder.lines.every(
                (line) =>
                    line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] ===
                    PurchaseOrderApprovalStatus.APPROVED
            ),
        [purchaseOrder.lines, viewType]
    )

    const isOnApprovalSide = useMemo(() => {
        const isBuyerView = viewType === ViewTypeI.buyer
        const { progress } = purchaseOrder

        return (
            (!isBuyerView && progress === PurchaseOrderProgressStatus.SHARED) ||
            (!isBuyerView && progress === PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED) ||
            (isBuyerView && progress === PurchaseOrderProgressStatus.SUBMITTED) ||
            (isBuyerView && progress === PurchaseOrderProgressStatus.INTERNALLY_APPROVED)
        )
    }, [purchaseOrder.progress, viewType])

    const hasVeoliaCustomFields = useMemo(
        () => isFeatureEnabled(Features.VeoliaCustomFields, organization?.id),
        [organization?.id]
    )

    return (
        <div className="purchase-page">
            <HeaderH1
                title={title + "#" + purchaseOrder.shortId}
                backLink={generatePath(PURCHASE_ORDERS_ROUTE, { viewType })}
                isShared={isShared}
            >
                {!isShared &&
                    organization &&
                    (newPO ? (
                        <ActionsHeaderCreate
                            organizationId={organization.id}
                            draftDocuments={draftDocuments}
                            setDraftDocuments={setDraftDocuments}
                        />
                    ) : (
                        <ActionsHeader
                            PO={purchaseOrder}
                            organization={organization}
                            buyerOrganization={{
                                id: purchaseOrder.buyerId,
                                name: getOrganizationName(
                                    organization.id,
                                    purchaseOrder.buyerId,
                                    purchaseOrder.buyerName
                                ),
                            }}
                            supplierOrganization={{
                                id: purchaseOrder.supplierId,
                                name: getOrganizationName(
                                    organization.id,
                                    purchaseOrder.supplierId,
                                    purchaseOrder.supplierName
                                ),
                            }}
                            handleMarkAllInPreparation={handleMarkAllInPreparation}
                            handleEdit={handleEdit}
                            handleSave={handleSave}
                            handleSend={handleSend}
                            handleSubmit={handleSubmit}
                            mode={pageMode}
                            viewType={viewType}
                            allLinesApproved={allLinesApproved}
                            isOnApprovalSide={isOnApprovalSide}
                            needsNewApproval={linesNeedApproval}
                        />
                    ))}
            </HeaderH1>
            <div className="main-box">
                {updateLoading || loading ? <Loader fullscreen /> : null}
                <StepsPurchaseOrder PO={purchaseOrder} viewType={viewType} />
                <Grid container className="purchase-request" spacing={0} gap={2}>
                    <Grid item xs className="column">
                        <TotalAmount
                            amount={purchaseOrder.totalAmountExcludingTax}
                            currency={purchaseOrder.currency}
                            taxLabel={formatMessage(messages.taxLabel)}
                        />
                        <Card>
                            <Stack spacing={1}>
                                <Dates
                                    creationDate={purchaseOrder.creationDate}
                                    expectedDeliveryDate={purchaseOrder.expectedDeliveryDate}
                                    pageMode={pageMode}
                                    setDeliveryDate={setDeliveryDate}
                                    required={true}
                                />
                                {!newPO && <RequesterName requesterName={purchaseOrder.requesterName} />}
                                {hasVeoliaCustomFields && (
                                    <POInfosVeoliaCustomFields purchaseOrderId={purchaseOrder.id} pageMode={pageMode} />
                                )}
                            </Stack>
                        </Card>

                        <Dates
                            creationDate={purchaseOrder.creationDate}
                            expectedDeliveryDate={purchaseOrder.expectedDeliveryDate}
                            pageMode={pageMode}
                            setDeliveryDate={setDeliveryDate}
                            required={true}
                        />

                        {!newPO && (
                            <Card>
                                <RequesterName requesterName={purchaseOrder.requesterName} />
                            </Card>
                        )}

                        <PurchaseDocumentCustomFields objectId={purchaseOrder.id} pageMode={pageMode} />

                        {!isShared && organization ? (
                            <>
                                <Card>
                                    <CurrencySelector
                                        value={purchaseOrder.currency}
                                        label={formatMessage(messages.currency)}
                                        editMode={!purchaseOrder.id || pageMode === PurchaseViewType.EDIT}
                                        onChange={handleCurrencyChange}
                                    />
                                </Card>
                                {purchaseOrder.buyerId === organization.id &&
                                purchaseOrder.purchaseRequest &&
                                purchaseOrder.purchaseRequest.id ? (
                                    <PurchaseRequestLink
                                        purchaseRequest={purchaseOrder.purchaseRequest}
                                        label={formatMessage(messages.purchaseRequest)}
                                    />
                                ) : null}
                                {purchaseOrder.id && (
                                    <PurchaseOrderDocumentRelations
                                        organizationId={organization.id}
                                        purchaseOrder={purchaseOrder}
                                        viewType={viewType}
                                    />
                                )}
                            </>
                        ) : null}
                    </Grid>
                    <Grid item md={6} sm={8} xs={12} gap={3}>
                        <Organizations
                            from={from}
                            to={to}
                            updateAddresses={updateAddresses}
                            billingAddress={purchaseOrder.billingAddress}
                            shippingAddress={purchaseOrder.shippingAddress}
                            readOnly={isShared || !editablePurchaseOrderStatuses[purchaseOrder.status]}
                            showOrganizationSelector={!purchaseOrder.supplierId || newPO}
                            objectType={DocumentObjectType.PURCHASE_ORDER}
                        />
                        <ErrorMessage>{updateError}</ErrorMessage>
                        <Description
                            description={purchaseOrder.description}
                            pageMode={pageMode}
                            handleChange={handleChangeDescription}
                        />

                        {purchaseOrder ? (
                            <LinesTabs
                                items={purchaseOrder.lines}
                                idKey="id"
                                temporaryIdKey="temporaryId"
                                contextType={CustomFieldObjectType.PURCHASE_ORDER}
                                contextId={purchaseOrder.id}
                                currency={purchaseOrder.currency}
                                organizationId={sharedOrganizationId ?? (organization?.id as OrganizationId)}
                                renderLineTags={renderLineTags}
                            >
                                <Items
                                    id={purchaseOrder.id}
                                    organizationId={sharedOrganizationId ?? (organization?.id as OrganizationId)}
                                    lines={purchaseOrder.lines}
                                    totalAmount={purchaseOrder.totalAmount ?? 0}
                                    totalAmountExcludingTax={purchaseOrder.totalAmountExcludingTax}
                                    currency={purchaseOrder.currency}
                                    status={purchaseOrder.progress}
                                    mode={pageMode}
                                    viewType={viewType}
                                    isShared={isShared}
                                    purchaseOrder={purchaseOrder}
                                    approvalRequired={handleApprovalRequiredForLine}
                                    userId={user.id}
                                />
                            </LinesTabs>
                        ) : null}
                        {purchaseOrder.id ? (
                            <TransactionInBudgets
                                transaction={purchaseOrder}
                                showModalAddToBudget={showModalAddToBudget}
                                withGauge
                            />
                        ) : null}
                    </Grid>
                    {organization && purchaseOrder.id && (
                        <Grid xs>
                            <Stack gap={2}>
                                {!newPO ? (
                                    <>
                                        <Card title={formatMessage(messages.tagsTitle)} expandable>
                                            <TagsSelector
                                                organizationId={organization.id}
                                                objectId={purchaseOrder.id}
                                                userId={user.id}
                                                objectType={TagObjectType.PURCHASE_REQUEST}
                                                context={tagSelectorContext}
                                            />
                                        </Card>
                                        <Documents
                                            objectId={purchaseOrder.id}
                                            objectType={DocumentObjectType.PURCHASE_REQUEST}
                                            organizationId={organization.id}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Card title={formatMessage(messages.tagsTitle)} expandable>
                                            <TagsForCreatingPurchaseOrder organizationId={organization.id} />
                                        </Card>
                                        <Documents
                                            organizationId={organization.id}
                                            objectType={DocumentObjectType.PURCHASE_REQUEST}
                                            draftDocuments={draftDocuments}
                                            setDraftDocuments={setDraftDocuments}
                                        />
                                    </>
                                )}
                                <CommunicationRoom
                                    organizationId={organization.id}
                                    objectId={purchaseOrder.id}
                                    objectType={SharedObjectType.PurchaseOrder}
                                    otherOrganizations={otherOrganizations}
                                />
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </div>
            {!isShared && organization ? (
                <>
                    <ModalAddToBudget
                        open={modalAddToBudgetVisible}
                        close={hideModalAddToBudget}
                        organizationId={organization.id}
                        budgets={budgetsData}
                        transaction={purchaseOrder}
                        transactionPayload={transactionPayload}
                        handleSuccess={handleSuccess}
                    />
                    {shareObjectModalOpen ? (
                        <ShareObjectModal
                            open={shareObjectModalOpen}
                            close={hideShareObjectModal}
                            objectType={SharedObjectType.PurchaseOrder}
                            objectId={purchaseOrder.id}
                            organizationId={organization.id}
                            buyerOrganization={from}
                            supplierOrganization={to}
                            onlyEmail={true}
                            sendShareEmail={false}
                            callback={handleSharedObject}
                        />
                    ) : null}
                </>
            ) : null}
        </div>
    )
}

export const PurchaseOrder = withSocketIOProvider(({ edit }: Props) => {
    const navigate = useNavigate()
    const { formatMessage } = useIntl()
    const { purchaseOrderId } = useParams()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)
    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectCurrentOrganization)
    const loading = useAppSelector(selectPurchaseOrdersLoading)
    const { s, o: sharedOrganizationId, u: sharedUserId } = useQueryParams()
    const isFromSharedEmail = !!(s && s === "t")

    const purchaseOrderOrganizationId =
        isFromSharedEmail && sharedOrganizationId ? sharedOrganizationId : organization?.id
    const {
        purchaseOrder: PO,
        fetchPurchaseOrder,
        changedOrganization,
    } = usePurchaseOrder(purchaseOrderId, purchaseOrderOrganizationId, organization?.id === purchaseOrderOrganizationId)
    const error = useAppSelector(selectPurchaseOrdersError)

    useEffect(() => {
        if (error) {
            changedOrganization ? toast.error(formatMessage(messages.errorChangedOrganization)) : console.error(error)
            navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.buyer }))
        }
    }, [error, changedOrganization])

    useEffect(() => {
        if (isFromSharedEmail && purchaseOrderId && sharedOrganizationId) {
            fetchPurchaseOrder(purchaseOrderId, false).then((data) => {
                if (data) {
                    dispatch(
                        purchaseOrdersActions.setViewType(
                            sharedOrganizationId === data.buyerId ? ViewTypeI.buyer : ViewTypeI.supplier
                        )
                    )
                }
            })
        }
    }, [purchaseOrderId, isFromSharedEmail, sharedOrganizationId])

    useEffect(() => {
        return () => {
            dispatch(purchaseOrdersActions.resetData())
        }
    }, [])

    if (!organization && !sharedOrganizationId) {
        return null
    }

    if (!PO || !PO.id) {
        return <Loader fullscreen={true} />
    }

    return (
        <PurchaseOrderView
            purchaseOrder={PO}
            organization={organization}
            sharedOrganizationId={sharedOrganizationId}
            sharedUserId={sharedUserId}
            edit={edit}
            isFromSharedEmail={isFromSharedEmail}
            loading={loading}
        />
    )
})

export function PurchaseOrderNew() {
    const { formatMessage } = useIntl()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)
    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectCurrentOrganization)
    const sharedEmail = false

    useEffect(() => {
        dispatch(purchaseOrdersActions.resetData())
        dispatch(tagsActions.resetPendingTags())

        return () => {
            dispatch(purchaseOrdersActions.resetData())
            dispatch(tagsActions.resetPendingTags())
        }
    }, [])

    const purchaseOrder = useCreateDraftPurchaseOrder(organization)

    if (!organization || !purchaseOrder) {
        return null
    }
    return (
        <PurchaseOrderView
            edit={true}
            newPO={true}
            isFromSharedEmail={sharedEmail}
            organization={organization}
            purchaseOrder={purchaseOrder}
        />
    )
}
