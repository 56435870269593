import { styled } from "@mui/material"
import { Share2 } from "react-feather"

import { BranchConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { BranchNode } from "~/domains/orchestration/flows/components/nodes"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { BranchNode as BranchNodeType, Node, NodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const branchNode: Omit<BranchNodeType, keyof Node> = {
    branches: [],
    default: "",
    type: NodeType.BRANCH_NODE,
}

const advancedFields: (keyof typeof BranchNode | keyof typeof BranchNode)[] = []

const RotatedShare2 = styled(Share2)({
    transform: "rotate(90deg)",
})

const branchConfig: Configuration<NodeType.BRANCH_NODE> = {
    type: NodeType.BRANCH_NODE,
    baseSlug: "branch",
    Icon: RotatedShare2,
    Configuration: BranchConfiguration,
    advancedFields,
    Node: BranchNode,
    factory: (props: FactoryProps): BranchNodeType => ({
        ...branchNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { branchConfig, advancedFields as branchAdvancedFields }
