import { Box } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/query"
import React from "react"
import { Feather } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { useParams } from "react-router-dom"

import { HeaderH1, Loader, Tabs } from "~/components"
import {
    useGetCustomFormQuery,
    useGetCustomFormSurveysQuery,
} from "~/domains/identity/features/customForms/api/customFormsApi"
import { ResponsesDatagrid } from "~/domains/identity/features/customForms/components/ResponsesDatagrid"
import { CUSTOM_FORMS } from "~/domains/identity/features/customForms/routes"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    insights: {
        id: "smartForms.tabHeader.insights",
        defaultMessage: "Insights",
    },
    responses: {
        id: "smartForms.tabHeader.responses",
        defaultMessage: "Responses",
    },
    responsesHeader: {
        id: "smartForms.header.responses",
        defaultMessage: "Partner responses",
    },
    columnHeaderEmail: {
        id: "common.mail",
        defaultMessage: "Email",
    },
    columnHeaderCreatedDate: {
        id: "common.createdDate",
        defaultMessage: "Created date",
    },
})

export const CustomForm = () => {
    const { formId } = useParams<{ formId: string }>()
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectCurrentOrganizationId)

    const { data: form, isLoading } = useGetCustomFormQuery(
        formId && organizationId ? { organisationId: organizationId, formId: formId } : skipToken
    )

    const { data: surveys } = useGetCustomFormSurveysQuery(
        formId && organizationId ? { organisationId: organizationId, formId: formId } : skipToken
    )

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <HeaderH1 title={form?.name || ""} icon={<Feather />} backLink={CUSTOM_FORMS} />
                    <Box data-testid="box" className="main-box">
                        <Tabs
                            className="tabs-communications"
                            tabs={[
                                // {
                                //     label: formatMessage(messages.insights),
                                //     component: <>Insights</>,
                                // },
                                {
                                    label: formatMessage(messages.responses),
                                    component: (
                                        <>
                                            {organizationId && form && (
                                                <ResponsesDatagrid surveys={surveys || []} formName={form?.name} />
                                            )}
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </Box>
                </>
            )}
        </div>
    )
}
