import { Result, ResultSuccess, isResultError } from "~/types/Result"
import { ParsingErrorType, genericParser } from "~/utils"

import { TagGroupI, TagGroupIO } from "../types/TagGroup"
import { validateAssignmentRule } from "./validateAssignmentRule"

export const parseTagGroup = (tagGroupData: unknown): Result<TagGroupI, ParsingErrorType> => {
    const result = genericParser(tagGroupData, TagGroupIO)
    if (isResultError(result)) {
        return result
    }
    return ResultSuccess({
        ...result.result,
        assignmentRule: validateAssignmentRule(result.result.assignmentRule),
        tags: result.result.tags ?? [],
    })
}
