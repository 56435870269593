import * as Sentry from "@sentry/browser"
import { useCallback } from "react"

import {
    useCreatePaymentMethodDetailsMutation,
    useCreatePaymentMethodDetailsObjectMutation,
    useLazyGetPartnerPaymentMethodDetailsQuery,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { BankTransferPaymentMethod, PaymentMethodDetailsStatus } from "~/domains/payment/payment-method-details/types"
import { useLazyGetPaymentMethodsQuery } from "~/domains/payment/payment-methods/api/paymentMethodsApi"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { ObjectType } from "~/domains/payment/types"

const sanitizeValue = (value: string): string => value.replace(/[\s-]/g, "")

export interface InitializePaymentMethodDetailsResult {
    payeePaymentMethodDetailsId?: string
    paymentMethodId?: string
}

export const useManagePaymentMethodDetails = ({ payerId, payeeId, documentData }) => {
    const [loadPaymentMethods, { isLoading: isLoadingPaymentMethods }] = useLazyGetPaymentMethodsQuery()
    const [loadPartnerPaymentMethodDetails, { isLoading: isLoadingPartnerPaymentMethodDetails }] =
        useLazyGetPartnerPaymentMethodDetailsQuery()
    const [createPaymentMethodDetails, { isLoading: isCreatingPaymentMethodDetails }] =
        useCreatePaymentMethodDetailsMutation()
    const [createPaymentMethodDetailsObject, { isLoading: isCreatingPaymentMethodDetailsObject }] =
        useCreatePaymentMethodDetailsObjectMutation()
    const isLoading =
        isLoadingPaymentMethods ||
        isLoadingPartnerPaymentMethodDetails ||
        isCreatingPaymentMethodDetails ||
        isCreatingPaymentMethodDetailsObject

    const initializePaymentMethodDetails = useCallback(async (): Promise<InitializePaymentMethodDetailsResult> => {
        try {
            const { iban, accountNumber } = documentData
            const paymentMethodsData = await loadPaymentMethods(null).unwrap()
            const paymentMethods = paymentMethodsData?.items ?? []

            if (paymentMethods.length && (iban || accountNumber)) {
                const bankTransferPaymentMethodId = paymentMethods.find(
                    (item) => item.payment_method_type === PaymentMethodType.BANK_TRANSFER
                )?.id

                const paymentMethodDetailsData = await loadPartnerPaymentMethodDetails({
                    ownerId: payeeId,
                    partnerId: payerId,
                }).unwrap()
                const paymentMethodDetails = paymentMethodDetailsData?.items ?? []
                const bankTransferPaymentMethodDetailsId = paymentMethodDetails.find(
                    (item) =>
                        sanitizeValue((item as BankTransferPaymentMethod).iban) === sanitizeValue(iban) ||
                        sanitizeValue((item as BankTransferPaymentMethod).bank_account_number) ===
                            sanitizeValue(accountNumber)
                )?.id

                if (bankTransferPaymentMethodDetailsId) {
                    return {
                        payeePaymentMethodDetailsId: bankTransferPaymentMethodDetailsId,
                        paymentMethodId: bankTransferPaymentMethodId,
                    }
                } else {
                    const newPaymentMethodDetails = await createPaymentMethodDetails({
                        payment_method_id: bankTransferPaymentMethodId,
                        owner: payeeId,
                        payment_method_type: PaymentMethodType.BANK_TRANSFER,
                        status: PaymentMethodDetailsStatus.PENDING,
                        ...(iban && { iban }),
                        ...(accountNumber && { bank_account_number: accountNumber }),
                    }).unwrap()

                    if (newPaymentMethodDetails?.id) {
                        const newPaymentMethodDetailsObject = await createPaymentMethodDetailsObject({
                            payment_method_details_id: newPaymentMethodDetails.id,
                            object_id: payerId,
                            object_type: ObjectType.ORGANIZATION,
                            default: true,
                        }).unwrap()

                        if (newPaymentMethodDetailsObject?.id) {
                            return {
                                payeePaymentMethodDetailsId: newPaymentMethodDetails.id,
                                paymentMethodId: bankTransferPaymentMethodId,
                            }
                        }
                    }
                }

                return {
                    paymentMethodId: bankTransferPaymentMethodId,
                }
            }

            return {}
        } catch (error) {
            Sentry.captureException(error, {
                extra: {
                    documentData,
                    payeeId,
                    payerId,
                },
            })
            return {}
        }
    }, [
        loadPaymentMethods,
        loadPartnerPaymentMethodDetails,
        createPaymentMethodDetails,
        createPaymentMethodDetailsObject,
    ])

    return { initializePaymentMethodDetails, isLoading }
}
