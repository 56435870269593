import React, { lazy } from "react"
import { Route } from "react-router-dom"

import {
    EDIT_INVOICE_SUPPLIER_ROUTE,
    INVOICE_SUPPLIER_ROUTE,
    MAIL_SENT_ROUTE,
} from "~/domains/transactions/invoices/_views/supplier/routes"

const MailSent = lazy(() => import("./pages/MailSent").then(({ MailSent }) => ({ default: MailSent })))
const EditInvoice = lazy(() => import("./pages/Edit").then(({ EditInvoice }) => ({ default: EditInvoice })))
const ProtectedRoutes = lazy(() =>
    import("../../../../../auth/ProtectedRoute").then(({ ProtectedRoutes }) => ({ default: ProtectedRoutes }))
)

export default {
    routes: [
        <Route key="invoice_route">
            <Route path={MAIL_SENT_ROUTE} element={<MailSent />} />
        </Route>,
        <Route key="protected_invoice_route" element={<ProtectedRoutes />}>
            <Route path={INVOICE_SUPPLIER_ROUTE} element={<EditInvoice />} />
        </Route>,
        <Route key="protected_invoice_route" element={<ProtectedRoutes />}>
            <Route path={EDIT_INVOICE_SUPPLIER_ROUTE} element={<EditInvoice />} />
        </Route>,
    ],
}
