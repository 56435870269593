import React from "react"

import { CollapsedObjects } from "~/domains/identity/features/roles-permissions/components/ShareObjectPermissionsModal/CollapsedObjects"
import { ObjectPermissions } from "~/domains/identity/features/roles-permissions/components/ShareObjectPermissionsModal/ObjectPermissions"
import { SpiceDBObjectType, SpiceDBPermissionsLiteI } from "~/domains/identity/features/roles-permissions/types/SpiceDB"

interface Props {
    objectId: string
    objectType: SpiceDBObjectType
    sharedPermissions?: SpiceDBPermissionsLiteI[] | null
    setShowOnlyList: (showOnlyList: boolean) => void
}

const MAX_OBJECTS = 6
export const ObjectsList: React.FC<Props> = ({ objectId, objectType, sharedPermissions, setShowOnlyList }) => {
    return (
        <>
            {sharedPermissions && sharedPermissions.length <= MAX_OBJECTS ? (
                <>
                    {sharedPermissions?.map((object) => (
                        <ObjectPermissions
                            key={object.objectId}
                            objectId={objectId}
                            objectType={objectType}
                            objectPermission={object}
                        />
                    ))}
                </>
            ) : (
                <CollapsedObjects sharedPermissions={sharedPermissions} setShowOnlyList={setShowOnlyList} />
            )}
        </>
    )
}
