import { Zap } from "react-feather"

import { EventTriggerConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { EventTriggerNode } from "~/domains/orchestration/flows/components/nodes"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { EventTriggerNode as EventTriggerNodeType, Node, NodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const eventTriggerNode: Omit<EventTriggerNodeType, keyof Node> = {
    event: null,
    nextNode: null,
    filter: {
        conditions: [],
    },
    type: NodeType.EVENT_TRIGGER_NODE,
}

const advancedFields: (keyof typeof eventTriggerNode)[] = []

const eventTriggerConfig: Configuration<NodeType.EVENT_TRIGGER_NODE> = {
    type: NodeType.EVENT_TRIGGER_NODE,
    baseSlug: "event_trigger",
    Icon: Zap,
    Configuration: EventTriggerConfiguration,
    advancedFields,
    Node: EventTriggerNode,
    factory: (props: FactoryProps): EventTriggerNodeType => ({
        ...eventTriggerNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { eventTriggerConfig, advancedFields as eventTriggerAdvancedFields }
