import "dayjs/locale/fr"
import React, { useEffect } from "react"
import { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useParams } from "react-router-dom"

import { organizationApi } from "~/api"
import { Button, Modal } from "~/components"
import { DocumentObjectType } from "~/domains/identity/features/documents/types"
import { CreateOrganizationContainer } from "~/domains/identity/features/organizations/components/CreateOrganizationContainer/CreateOrganizationContainer"
import { ManualCompanyInputTrigger } from "~/domains/identity/features/organizations/components/CreateOrganizationContainer/ManualInputContainer"
import { OrganizationSelectorWithPartners } from "~/domains/identity/features/organizations/components/OrganizationSelector"
import { useCompanyResultFromCompanyDetails } from "~/domains/transactions/invoices/components/InvoiceSupplierInfo"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { purchaseRequestsActions } from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { CompanyResult } from "~/features/organization/components/CompanyAutocomplete"
import { CompanyAutocompleteType } from "~/features/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { getImportCompanyIdentifierOrEmptyString } from "~/store/invoice/core"
import { selectSuggestedSupplierCompagnies } from "~/store/invoice/invoiceSlice"
import { CountryCode, ImportInvoiceCompanyInfoI, OrganizationI, OrganizationId } from "~/types"

import "./ModalSelectOrganization.scss"

const messages = defineMessages({
    title: {
        id: "purchase.modalSelectOrganization.title",
        defaultMessage: "Select an organization",
    },
    save: {
        id: "purchase.modalSelectOrganization.save",
        defaultMessage: "Save",
    },
    cancel: {
        id: "purchase.modalSelectOrganization.cancel",
        defaultMessage: "Cancel",
    },
    companySelectLabel: {
        id: "purchase.modalSelectOrganization.companySelectLabel",
        defaultMessage: "Organization",
    },
})

interface Props {
    open: boolean
    objectType: DocumentObjectType
    close: () => void
    organizationId: OrganizationId | null
    organizationName?: string
}

export const ModalSelectOrganization: React.FC<Props> = ({
    open,
    close,
    objectType,
    organizationId,
    organizationName,
}) => {
    const { formatMessage } = useIntl()
    const { purchaseRequestId } = useParams()
    const dispatch = useAppDispatch()

    const [manualImport, setManualImport] = useState<boolean>(false)
    const [companyResult, setCompanyResult] = useState<CompanyResult | undefined>()
    const [countryCode, setCountryCode] = useState<CountryCode>(CountryCode.FR)
    const onCountryCodeChange = useCallback((countryCode: CountryCode) => setCountryCode(countryCode), [setCountryCode])

    const [supplier, setSupplier] = useState<ImportInvoiceCompanyInfoI>({
        countryCode: countryCode,
        name: organizationName ?? "",
        contactName: "",
        organizationId: organizationId,
    })
    const suggestedCompanies = useAppSelector(selectSuggestedSupplierCompagnies)
    const initialCompanyResult = useCompanyResultFromCompanyDetails(supplier, suggestedCompanies)

    useEffect(() => {
        if (!initialCompanyResult) return

        setCompanyResult(initialCompanyResult)

        const { type, value } = initialCompanyResult

        const countryCode =
            type === CompanyAutocompleteType.WhitePagesResult
                ? value?.countryCode
                : type === CompanyAutocompleteType.Organization
                  ? value?.registration?.countryCode
                  : null

        if (countryCode) {
            setCountryCode(countryCode)
        }
    }, [initialCompanyResult])

    const handleClose = useCallback(() => {
        resetForm()
        close()
    }, [close])

    const resetForm = useCallback(() => {}, [])

    const selectSupplier = useCallback(async () => {
        let supplierOrganizationId = supplier.organizationId
        if (!supplierOrganizationId) {
            const organizationItem = await organizationApi.createOrganization(
                supplier.name,
                supplier.countryCode,
                getImportCompanyIdentifierOrEmptyString(supplier)
            )
            supplierOrganizationId = organizationItem.id
        }
        if (objectType === DocumentObjectType.PURCHASE_REQUEST) {
            dispatch(purchaseRequestsActions.updateData({ field: "supplierId", value: supplierOrganizationId }))
            dispatch(purchaseRequestsActions.updateData({ field: "supplierName", value: supplier.name }))
            if (purchaseRequestId) {
                dispatch(purchaseRequestsActions.setShouldUpdatePR(true))
            }
        } else if (objectType === DocumentObjectType.PURCHASE_ORDER) {
            dispatch(purchaseOrdersActions.updateData({ field: "supplierId", value: supplierOrganizationId }))
            dispatch(purchaseOrdersActions.updateData({ field: "supplierName", value: supplier.name }))
        }
        close()
    }, [supplier, objectType, dispatch, handleClose])

    const onSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            selectSupplier()
        },
        [selectSupplier]
    )

    const updateData = useCallback(
        (data: Partial<ImportInvoiceCompanyInfoI>) => {
            setSupplier((currentState) => ({ ...currentState, ...data }))
        },
        [setSupplier]
    )

    const onSave = useCallback(() => true, [])

    const onManualImportClick = useCallback(() => setManualImport(true), [])
    const cancelManualImport = useCallback(() => setManualImport(false), [])

    const handleOrganizationCreated = useCallback((newOrganization: OrganizationI) => {
        const newCompanyResult: CompanyResult = {
            type: CompanyAutocompleteType.Organization,
            value: newOrganization,
        }
        setCompanyResult(newCompanyResult)

        const { id, name, registration } = newOrganization
        setSupplier({
            contactName: "",
            organizationId: id,
            countryCode: registration.countryCode,
            name,
        })
        setManualImport(false)

        if (objectType === DocumentObjectType.PURCHASE_REQUEST) {
            dispatch(purchaseRequestsActions.updateData({ field: "supplierId", value: id }))
            dispatch(purchaseRequestsActions.updateData({ field: "supplierName", value: name }))
            if (purchaseRequestId) {
                dispatch(purchaseRequestsActions.setShouldUpdatePR(true))
            }
        } else if (objectType === DocumentObjectType.PURCHASE_ORDER) {
            dispatch(purchaseOrdersActions.updateData({ field: "supplierId", value: id }))
            dispatch(purchaseOrdersActions.updateData({ field: "supplierName", value: name }))
        }
        close()
    }, [])

    return (
        <Modal className="purchase-modal-select-organization" open={open} onClose={() => handleClose()}>
            <Modal.Header>
                <h4>{formatMessage(messages.title)}</h4>
            </Modal.Header>
            {!manualImport ? (
                <form onSubmit={onSubmit}>
                    <Modal.Content>
                        <>
                            <OrganizationSelectorWithPartners
                                label=""
                                company={companyResult}
                                suggestedCompanies={suggestedCompanies}
                                countryCode={countryCode}
                                onCountryCodeChange={onCountryCodeChange}
                                updateData={updateData}
                                onSave={onSave}
                                dataLoaded={true}
                            />
                            <ManualCompanyInputTrigger onClick={onManualImportClick} />
                        </>
                    </Modal.Content>
                    <Modal.Footer>
                        <Button type="neutral" onClick={() => handleClose()}>
                            {formatMessage(messages.cancel)}
                        </Button>
                        <Button type="primary" buttonType="submit">
                            {formatMessage(messages.save)}
                        </Button>
                    </Modal.Footer>
                </form>
            ) : (
                <CreateOrganizationContainer
                    cancelManualImport={cancelManualImport}
                    companyResult={companyResult}
                    onOrganizationCreated={handleOrganizationCreated}
                />
            )}
        </Modal>
    )
}
