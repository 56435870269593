import { FileText } from "react-feather"

import { ApprovePurchaseRequestConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { ApprovePurchaseRequestNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    ApprovePurchaseRequestNode as ApprovePurchaseRequestNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const approvePurchaseRequestNode: Omit<ApprovePurchaseRequestNodeType, keyof Node> = {
    purchaseRequestId: `{{ trigger_event.id }}`,
    nextNode: null,
    type: NodeType.APPROVE_PURCHASE_REQUEST_NODE,
}

const advancedFields: (keyof typeof approvePurchaseRequestNode)[] = [AllAdvancedFieldsUnion.PURCHASE_REQUEST_ID]

const approvePurchaseRequestConfig: Configuration<NodeType.APPROVE_PURCHASE_REQUEST_NODE> = {
    type: NodeType.APPROVE_PURCHASE_REQUEST_NODE,
    baseSlug: "approve-purchase-request",
    Icon: FileText,
    Configuration: ApprovePurchaseRequestConfiguration,
    advancedFields,
    Node: ApprovePurchaseRequestNode,
    factory: (props: FactoryProps): ApprovePurchaseRequestNodeType => ({
        ...approvePurchaseRequestNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { approvePurchaseRequestConfig, advancedFields as approvePurchaseRequestAdvancedFields }
