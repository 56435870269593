import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import React, { ReactNode, useCallback, useState } from "react"
import { ChevronDown, MoreVertical } from "react-feather"
import { useIntl } from "react-intl"

import { Button, ButtonType } from "~/components"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"

import "./ActionsMenu.scss"

export type Actions<T> = {
    label?: ReactNode
    action?: ((dataType: T) => void) | (() => void)
    icon?: React.ReactNode
    render?: React.ReactNode
    disabled?: boolean
}

type Props<T> = {
    actions: Actions<T>[]
    dataType?: T
    labelButton?: string
    disabled?: boolean
    buttonType?: ButtonType
    icon?: React.ReactNode
    isActionIcon?: boolean
    size?: "small" | "medium"
}

const renderButton = (
    handleClick: (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => void,
    disabled?: boolean,
    labelButton?: string,
    buttonType?: ButtonType,
    icon?: React.ReactNode,
    isActionIcon?: boolean,
    size?: "small" | "medium"
) => {
    const defaultIcon = icon ?? <MoreVertical size={18} className="svg-grey" />

    if (labelButton) {
        return (
            <Button
                type={buttonType}
                onClick={handleClick}
                className="menu-actions-icon"
                disabled={disabled}
                size={size}
            >
                {labelButton}
                <ChevronDown size={18} className="svg-white" />
            </Button>
        )
    }

    return isActionIcon ? (
        <IconButton className="menu-actions-icon" disabled={disabled} onClick={handleClick} size={size}>
            {defaultIcon}
        </IconButton>
    ) : (
        <Button type={buttonType} onClick={handleClick} className="menu-actions-icon" disabled={disabled} size={size}>
            {defaultIcon}
        </Button>
    )
}

export const ActionsMenu = <T,>({
    dataType,
    actions,
    labelButton,
    disabled,
    icon,
    buttonType = "grey",
    isActionIcon = true,
    size = "medium",
}: Props<T>) => {
    const { formatMessage } = useIntl()
    const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement & MouseEvent>()
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation()
        setAnchorEl(undefined)
    }

    const handleDataAction = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        actionExec: (dataType: T) => void,
        dataType: T
    ) => {
        event.stopPropagation()
        actionExec(dataType)
        handleClose(event)
    }

    const handleNoDataAction = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, actionExec: () => void) => {
        event.stopPropagation()
        actionExec()
        handleClose(event)
    }

    const handleMenuItemClick = useCallback(
        (event: React.MouseEvent<HTMLLIElement, MouseEvent>, item: Actions<T>) => {
            return dataType
                ? handleDataAction(event, item?.action as () => void, dataType)
                : handleNoDataAction(event, item?.action as () => void)
        },
        [dataType]
    )

    const button = renderButton(handleClick, disabled, labelButton, buttonType, icon, isActionIcon, size)

    if (!actions.length) return null

    return (
        <>
            {disabled ? (
                <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                    <div>{button}</div>
                </Tooltip>
            ) : (
                button
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="menu-actions"
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.16))",
                            mt: 1.5,
                            "&::before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {actions.map((action, key) =>
                    action.render ? (
                        <MenuItem key={key} disabled={action.disabled}>
                            {action.render}
                        </MenuItem>
                    ) : (
                        <MenuItem
                            key={key}
                            onClick={(event) => handleMenuItemClick(event, action)}
                            disabled={action.disabled}
                        >
                            {action.icon}
                            <span className="menu-items-grey">{action.label}</span>
                        </MenuItem>
                    )
                )}
            </Menu>
        </>
    )
}
