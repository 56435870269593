import { CornerDownRight } from "react-feather"

import { FetchCustomFieldsConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { FetchCustomFieldsNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    FetchCustomFieldsNode as FetchCustomFieldsNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const fetchCustomFieldsNode: Omit<FetchCustomFieldsNodeType, keyof Node> = {
    objectId: `{{ trigger_event.id }}`,
    nextNode: null,
    type: NodeType.FETCH_CUSTOM_FIELDS_NODE,
}

const advancedFields: (keyof typeof fetchCustomFieldsNode)[] = [AllAdvancedFieldsUnion.OBJECT_ID]

const fetchCustomFieldsConfig: Configuration<NodeType.FETCH_CUSTOM_FIELDS_NODE> = {
    type: NodeType.FETCH_CUSTOM_FIELDS_NODE,
    baseSlug: "fetch-custom-fields",
    Icon: CornerDownRight,
    Configuration: FetchCustomFieldsConfiguration,
    advancedFields,
    Node: FetchCustomFieldsNode,
    factory: (props: FactoryProps): FetchCustomFieldsNodeType => ({
        ...fetchCustomFieldsNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { fetchCustomFieldsConfig, advancedFields as fetchCustomFieldsAdvancedFields }
