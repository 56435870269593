import { Stack } from "@mui/material"
import React, { useState } from "react"
import { Play } from "react-feather"
import { useIntl } from "react-intl"

import { HeaderH1, SafeFormattedMessage } from "~/components"
import { Filter } from "~/domains/orchestration/flows/components/Filter"
import { List } from "~/domains/orchestration/flows/components/List"
import { usePermission } from "~/domains/orchestration/flows/hooks/usePermission"
import { messages } from "~/domains/orchestration/flows/messages"
import { useTitle } from "~/hooks"

export const FlowsList: React.FC = () => {
    const { formatMessage } = useIntl()
    const pageName = formatMessage(messages.page.title)
    const [filter, setFilter] = useState("")

    const { hasWorkflowCreatePermission, permissionError } = usePermission()

    useTitle(pageName)

    const filterChangeCallback = (filter: string) => {
        setFilter(filter)
    }

    return (
        <>
            <HeaderH1 title={pageName} icon={<Play />} />

            <Stack className="main-box">
                {!permissionError ? (
                    <>
                        <Filter
                            hasCreatePermission={hasWorkflowCreatePermission}
                            filterChangeCallback={filterChangeCallback}
                            filter={filter}
                        />
                        <List filter={filter} />
                    </>
                ) : (
                    <SafeFormattedMessage tagName="p" {...messages.page.noOrganization} />
                )}
            </Stack>
        </>
    )
}
