import { forwardRef } from "react"

import { ItemLabel, Loader } from "~/components"
import { OrganizationId, UserId } from "~/types"

import { useOrganizationTagGroups, useTagsForObject, useTagsRecommandations } from "../../hooks"
import { TagObjectType } from "../../types"
import { TagsSelectorContainer } from "./TagsSelectorContainer"
import { TagsSelectorStateless } from "./TagsSelectorStateless"

interface Props {
    organizationId: OrganizationId
    objectId: string | string[]
    userId: UserId
    objectType?: TagObjectType
    context?: any
    contextId?: string
    readonly?: boolean
}

export const TagsSelectorWithState = forwardRef<HTMLDivElement, Props>(
    ({ organizationId, objectId, userId, objectType, context, contextId, readonly }, containerRef) => {
        const { tagGroups, error } = useOrganizationTagGroups(organizationId)

        const tagsRecommandations = useTagsRecommandations(organizationId, context, readonly)

        const [selectedTags, setSelectedTags] = useTagsForObject(
            organizationId,
            objectId,
            userId,
            tagGroups,
            objectType,
            context,
            contextId
        )

        if (!objectId || objectId.length === 0) {
            return null
        }

        if (error) {
            /* return <ErrorMessage>{error}</ErrorMessage> */
            return <ItemLabel> - </ItemLabel>
        }

        if (!selectedTags || !tagGroups) {
            return (
                <TagsSelectorContainer>
                    <Loader small />
                </TagsSelectorContainer>
            )
        }

        return (
            <TagsSelectorStateless
                setSelectedTags={setSelectedTags}
                selectedTags={selectedTags}
                tagGroups={tagGroups}
                ref={containerRef}
                readonly={readonly}
                tagsRecommandations={tagsRecommandations}
            />
        )
    }
)

TagsSelectorWithState.displayName = "TagsSelectorWithState"
