import { Box, SxProps } from "@mui/material"
import React from "react"

import "./ProgressBar.scss"

interface Props extends React.PropsWithChildren {
    progress: number
    className?: string
    displayPercentage?: boolean
    sx?: SxProps
}

export const ProgressBar: React.FC<Props> = ({ progress, displayPercentage, className, children, sx }) => {
    const percentage = Math.max(Math.min(progress * 100, 100), 0)
    const label = (
        <>
            {children}
            {displayPercentage && <span>&nbsp;{Math.round(percentage)}%</span>}
        </>
    )
    return (
        <Box
            className={`progressbar-container ${percentage === 0 ? "progressbar-zero" : ""} ${className ?? ""}`}
            sx={sx}
        >
            <div className="progressbar" style={{ width: `${percentage}%` }}>
                <div className="progressbar-label">{label}</div>
            </div>
            <div className="progressbar-sublabel">{label}</div>
        </Box>
    )
}
