import { Grid, Typography } from "@mui/material"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import { Tag } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, Modal, SafeFormattedMessage } from "~/components"
import { TagsSelector } from "~/domains/tags/components/TagsSelector"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { TagI } from "~/domains/tags/types"
import "~/domains/transactions/invoices/_views/buyer/assets/css/Invoice.scss"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { DocumentI, OrganizationI } from "~/types"

import "./ModalAddTags.scss"

const messages = defineMessages({
    modalTitle: {
        id: "account.documents.modal.addTags.title",
        defaultMessage: "Add tags to selected invoice{s} ({number})",
    },
    stepAddTags: { id: "account.documents.modal.addTags.stepTags.title", defaultMessage: "Tag {those} invoice{s}" },
    addTags: { id: "account.documents.modal.addTags.button.addTags", defaultMessage: "Add tags" },
    cancel: { id: "common.cancel", defaultMessage: "Cancel" },
})

interface Props {
    organization: OrganizationI
    selected: string[]
    objects: DocumentI[] | PurchaseOrders[]
    displayModal: boolean
    setDisplayModal: Dispatch<SetStateAction<boolean>>
    onConfirm: (tagsToAdd: TagI[], tagsToRemove: TagI[]) => void
}

const isDocumentI = (object: DocumentI | PurchaseOrders): object is DocumentI => {
    return "invoiceId" in object
}
const isPurchaseOrder = (object: DocumentI | PurchaseOrders): object is PurchaseOrders => {
    return "id" in object
}

export function ModalAddTags({ organization, selected, objects, displayModal, setDisplayModal, onConfirm }: Props) {
    const { formatMessage } = useIntl()

    const initialTags = useRef<TagI[]>([])
    const [selectedTags, setSelectedTags] = useState<TagI[]>([])

    const user = useAppSelector(selectUser)

    const { tagGroups } = useOrganizationTagGroups(organization.id)

    useEffect(() => {
        const selectedObjects = objects.filter(
            (object) =>
                (isDocumentI(object) && selected.includes(object.invoiceId)) ||
                (isPurchaseOrder(object) && selected.includes(object.id))
        )
        const commonTagsIds = selectedObjects.reduce(
            (commonTags, object) => {
                return commonTags.filter((tagId) => object.tags?.some((objectTag) => objectTag.tagId === tagId))
            },
            (selectedObjects.length && selectedObjects[0].tags?.map((tag) => tag.tagId)) || []
        )

        const commonTags = tagGroups?.flatMap((tagGroup) =>
            tagGroup.tags.filter((tag) => commonTagsIds.includes(tag.tagId))
        )

        if (commonTags) {
            initialTags.current = commonTags
            setSelectedTags(commonTags)
        }
    }, [tagGroups, objects, selected])

    const handleConfirm = useCallback(() => {
        const tagsToAdd = selectedTags.filter(
            (tag) => !initialTags.current.some((initialTag) => initialTag.tagId === tag.tagId)
        )
        const tagsToRemove = initialTags.current.filter(
            (initialTag) => !selectedTags.some((tag) => tag.tagId === initialTag.tagId)
        )
        onConfirm(tagsToAdd, tagsToRemove)
    }, [onConfirm, selectedTags])

    const handleClose = () => {
        setDisplayModal(false)
    }

    const nbSelected = selected.length

    return (
        <Modal
            open={displayModal}
            onClose={handleClose}
            className="modal-add-tags modal-otp"
            aria-labelledby="modal-add-tags modal-otp"
        >
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    <SafeFormattedMessage
                        {...messages.modalTitle}
                        values={{
                            s: nbSelected > 1 ? "s" : "",
                            number: nbSelected,
                        }}
                    />
                </Typography>
            </Modal.Header>
            <Modal.Content>
                <Grid className="add-comment">
                    <Grid className="OTP-step">
                        <div className="OTP-step-num">
                            <Tag size={14} />
                        </div>
                        <div className="OTP-step-content">
                            <h5>
                                <SafeFormattedMessage
                                    {...messages.stepAddTags}
                                    values={{
                                        s: nbSelected > 1 ? "s" : "",
                                        those: nbSelected > 1 ? "those" : "this",
                                    }}
                                />
                            </h5>
                        </div>
                    </Grid>
                </Grid>
                {organization && user.id ? (
                    <TagsSelector
                        organizationId={organization.id}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        tagsRecommandations={null}
                    />
                ) : null}
            </Modal.Content>
            <Modal.Footer className="vr-footer">
                <Button onClick={handleClose} type="neutral">
                    {formatMessage(messages.cancel)}
                </Button>
                <Button buttonType="submit" onClick={handleConfirm}>
                    {formatMessage(messages.addTags)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
