import { useCallback } from "react"

import { useCommunicationApi } from "~/domains/communication/api"
import {
    isElementActionPlan,
    isElementActionPlanItem,
} from "~/domains/communication/components/InputMessage/ActionPlan"
import { communicationActions } from "~/domains/communication/store/CommunicationSlice"
import { ActionPlanCheckListI, MessageId } from "~/domains/communication/types"
import { useAppDispatch } from "~/store/hooks"
import { isResultSuccess } from "~/types/Result"

export const useUpdateActionPlanItem = (messageId: MessageId | null, checkId: string) => {
    const communicationApi = useCommunicationApi()
    const dispatch = useAppDispatch()
    return useCallback(
        async (updateData: Partial<ActionPlanCheckListI>) => {
            if (!messageId) return
            const messageResponse = await communicationApi.getMessage(messageId)
            if (isResultSuccess(messageResponse)) {
                const message = messageResponse.result
                const newContent = message.content.map((paragraph) =>
                    isElementActionPlan(paragraph)
                        ? {
                              ...paragraph,
                              children: paragraph.children.map((item) =>
                                  isElementActionPlanItem(item) && item.data.id === checkId
                                      ? {
                                            ...item,
                                            data: {
                                                ...item.data,
                                                ...updateData,
                                            },
                                        }
                                      : item
                              ),
                          }
                        : paragraph
                )
                const result = await communicationApi.updateMessage(messageId, { content: newContent })
                if (isResultSuccess(result)) {
                    dispatch(communicationActions.updateMessage(result.result))
                }
            }
        },
        [communicationApi, dispatch, messageId, checkId]
    )
}
