import React from "react"
import { Play } from "react-feather"
import { useIntl } from "react-intl"

import { HeaderH1 } from "~/components"
import { RunInformation } from "~/domains/orchestration/flows/components/RunInformation"
import { messages } from "~/domains/orchestration/flows/messages"
import { useTitle } from "~/hooks"

export const Run: React.FC = () => {
    const { formatMessage } = useIntl()
    const pageName = formatMessage(messages.page.title)

    useTitle(pageName)

    return (
        <>
            <HeaderH1 title={pageName} icon={<Play />} />
            <div className="main-box">
                <RunInformation />
            </div>
        </>
    )
}
