import { AppBar, Container, Toolbar } from "@mui/material"
import classNames from "classnames"
import React, { ReactNode } from "react"

import { UserActions } from "~/components/Header/UserActions"
import { selectIsConnected } from "~/store/auth/authSlice"
import { useAppSelector } from "~/store/hooks"

import "./Header.scss"

interface HeaderProps extends React.PropsWithChildren {
    hideUser?: boolean
    rightChildren?: ReactNode
    noHeaderSpacerLeft?: boolean
    noHeaderSpacerRight?: boolean
    className?: string
}

export function Header({
    children,
    rightChildren,
    className,
    noHeaderSpacerLeft = false,
    noHeaderSpacerRight = false,
}: HeaderProps) {
    const isConnected = useAppSelector(selectIsConnected)
    const classes = classNames("header", className)

    return (
        <>
            <AppBar position="fixed" color="secondary" className={classes}>
                <Container maxWidth={false} sx={{ height: "100%" }}>
                    <Toolbar disableGutters sx={{ height: "100%" }}>
                        {(!isConnected || children || rightChildren) && !noHeaderSpacerLeft && (
                            <div className="header-spacer left" />
                        )}
                        {children}
                        {noHeaderSpacerRight ? null : (
                            <div className="header-spacer right">
                                {rightChildren}
                                <UserActions isConnected={isConnected} />
                            </div>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    )
}

export default Header
