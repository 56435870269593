import { FC, useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { InvoiceSupplierInfo } from "~/domains/transactions/invoices/components/InvoiceSupplierInfo"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { usePatchDraftInvoice } from "~/store/invoice/hooks"
import { invoiceActions, selectCreatingInvoice } from "~/store/invoice/invoiceSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceI } from "~/types"
import { ImportInvoiceCompanyInfoI, InvoiceUserType } from "~/types"

const messages = defineMessages({
    title: {
        id: "supplier.supplierInfo.title",
        defaultMessage: "Supplier",
    },
    titleAsBuyer: {
        id: "buyer.buyerInfo.titleMe",
        defaultMessage: "Me",
    },
})

interface InvoiceOrganizationVendorProps {
    invoice: InvoiceI
}

export const InvoiceOrganizationVendor: FC<InvoiceOrganizationVendorProps> = ({ invoice }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const currentUserOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const creatingInvoice = useAppSelector(selectCreatingInvoice)
    const user = useAppSelector(selectUser)
    const saveDraftAction = usePatchDraftInvoice(invoice, InvoiceUserType.SUPPLIER, user)

    const { ocrSupplier } = creatingInvoice ?? {}

    const handleUpdateInvoiceSupplier = useCallback(
        (supplier: Partial<ImportInvoiceCompanyInfoI>) => {
            if (!supplier.organizationId) return

            dispatch(invoiceActions.updatePartialInvoiceSupplier(supplier))
            saveDraftAction()
        },
        [dispatch]
    )

    const { parties } = invoice
    const initiator = Object.values(parties).find((party) => party.initiator)?.id

    return (
        <Card
            title={formatMessage(messages[initiator === currentUserOrganizationId ? "title" : "titleAsBuyer"])}
            expandable
        >
            <InvoiceSupplierInfo
                initiator={InvoiceUserType.BUYER}
                dataLoaded={true}
                supplier={invoice.supplier}
                updateData={handleUpdateInvoiceSupplier}
                ocrSupplierData={ocrSupplier}
                invoiceV1
            />
        </Card>
    )
}
