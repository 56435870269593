import { Autocomplete, AutocompleteRenderInputParams, Box, TextField, createFilterOptions } from "@mui/material"
import React, { ReactNode, useCallback, useMemo, useState } from "react"
import { defineMessage } from "react-intl"

import { ErrorMessage, SafeFormattedMessage } from "~/components"
import { UserAndOrganization, selectUser } from "~/store/account/accountSlice"
import { useGetReceivedDocuments, useGetSentDocuments } from "~/store/account/hooks"
import { useAppSelector } from "~/store/hooks"
import { DocumentI, OrganizationId, ViewTypeI } from "~/types"
import { isDefined } from "~/utils/isDefined"

const defaultLabel = defineMessage({
    id: "transactions.purchaseOrder.documentRelations.invoicePicker.label",
    defaultMessage: "Link an invoice",
})

const getOptionLabel = (option: DocumentI | string) => {
    if (typeof option === "string") return option
    return `INV #${option.reference}`
}

const getOptionId = (option: DocumentI | string) => {
    if (typeof option === "string") return option
    return option.invoiceId
}

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: DocumentI) => {
    return (
        <Box component="li" {...props}>
            {getOptionLabel(option)}
        </Box>
    )
}

const sortDocumentsByDate = (doc1: DocumentI, doc2: DocumentI): number => {
    if (doc1.updateDate < doc2.updateDate) return 1
    if (doc2.updateDate < doc1.updateDate) return -1
    return 0
}

const areDocumentsEqual = (doc1: DocumentI, doc2: DocumentI) => doc1.invoiceId === doc2.invoiceId

interface InvoicePickerProps {
    organizationId: OrganizationId
    viewType: ViewTypeI
    value: DocumentI | null
    onChange: (purchaseOrder: DocumentI) => void
    label?: ReactNode
    required?: boolean
    blackListIds?: string[]
}

export const InvoicePicker: React.FC<InvoicePickerProps> = ({
    organizationId,
    viewType,
    value,
    onChange,
    blackListIds,
}) => {
    const [inputValue, setInputValue] = useState<string>("")

    const user = useAppSelector(selectUser)
    const userId = user.id
    const userOrOrganization: UserAndOrganization = { userId, organizationId }

    const {
        sentDocuments,
        loading: loadingSentDocuments,
        error: errorSentDocuments,
    } = useGetSentDocuments(userOrOrganization, true)
    const {
        receivedDocuments,
        loading: loadingReceivedDocuments,
        error: errorReceivedDocuments,
    } = useGetReceivedDocuments(userOrOrganization, true)

    const loading = viewType === ViewTypeI.supplier ? loadingSentDocuments : loadingReceivedDocuments
    const documents = viewType === ViewTypeI.supplier ? sentDocuments : receivedDocuments
    const error = viewType === ViewTypeI.supplier ? errorSentDocuments : errorReceivedDocuments

    const filterOptions = useMemo(
        () =>
            createFilterOptions({
                stringify: (option: DocumentI) =>
                    `${option.reference} ${option.description} ${
                        viewType === ViewTypeI.supplier ? option.buyerName : option.supplierName
                    }`,
            }),
        [viewType]
    )

    const sortedDocuments = (documents as DocumentI[])
        .filter((document): boolean => !isDefined(blackListIds) || !blackListIds.includes(document.invoiceId))
        .sort(sortDocumentsByDate)

    const onInputChange = useCallback((_event: React.SyntheticEvent<Element, Event>, value: string) => {
        setInputValue(value)
    }, [])

    const onAucompleteChange = useCallback(
        (_event: React.SyntheticEvent<Element, Event>, value: string | DocumentI | null) => {
            if (value && typeof value === "object") {
                onChange(value)
                setInputValue("")
            }
        },
        [onChange]
    )

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return <TextField {...params} label={<SafeFormattedMessage {...defaultLabel} />} />
    }, [])

    return (
        <>
            <Autocomplete
                inputValue={inputValue}
                onInputChange={onInputChange}
                filterOptions={filterOptions}
                autoHighlight
                loading={loading}
                options={sortedDocuments}
                isOptionEqualToValue={areDocumentsEqual}
                getOptionLabel={getOptionId}
                value={value}
                onChange={onAucompleteChange}
                fullWidth
                renderInput={renderInput}
                renderOption={renderOption}
            />
            <ErrorMessage>{error}</ErrorMessage>
        </>
    )
}
