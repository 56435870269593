import { useHasPermissions } from "~/domains/identity/features/roles-permissions/store/hooks"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { AuthorizationName } from "~/types"

type DashboardPermissions = {
    createPurchaseRequests: boolean
    createPurchaseOrders: boolean
    createPartnership: boolean
    createInvoices: boolean
    payInvoices: boolean
}

export const useDashboardPermissions = (organizationId: string | undefined): DashboardPermissions => {

    const { hasPermission: hasCreatePurchaseRequestsPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_REQUESTS
    )

    const { hasPermission: hasCreatePurchaseOrdersPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )

    const { hasPermission: hasCreatePartnershipPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    const { hasPermission: hasPayInvoicesPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.BANKING,
        ScopeName.PAYMENTS
    )

    const { hasPermission: hasCreateInvoicesPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.TRANSACTION,
        ScopeName.INVOICES
    )

    return {
        createPurchaseRequests: hasCreatePurchaseRequestsPermission,
        createPurchaseOrders: hasCreatePurchaseOrdersPermission,
        createPartnership: hasCreatePartnershipPermission,
        createInvoices: hasCreateInvoicesPermission,
        payInvoices: hasPayInvoicesPermission,
    }
}
