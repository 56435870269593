import { BankTransferPaymentMethod, PaymentMethodBankTransfer } from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { CountryCode } from "~/types"

export const bankDetailsFromApiAdapter = (
    paymentMethodDetails: BankTransferPaymentMethod
): PaymentMethodBankTransfer => {
    return {
        paymentMethodType: PaymentMethodType.BANK_TRANSFER,
        bankAccountNumber: paymentMethodDetails.bank_account_number || "",
        bankName: paymentMethodDetails.bank_name || "",
        iban: paymentMethodDetails.iban || "",
        bicSwift: paymentMethodDetails.bic_swift || "",
        holderName: paymentMethodDetails.holder_name || "",
        accountNumber: paymentMethodDetails.account_number || "",
        bankIdentifier: paymentMethodDetails.bank_identifier || "",
        bankKey: paymentMethodDetails.bank_key || "",
        branchIdentifier: paymentMethodDetails.branch_identifier || "",
        checkDigit: paymentMethodDetails.check_digit || "",
        checkDigits: paymentMethodDetails.check_digits || "",
        countryCode: (paymentMethodDetails.country_code || CountryCode.FR) as CountryCode,
        sepaParticipant: paymentMethodDetails.sepa_participant || false,
        sortCode: paymentMethodDetails.sort_code || "",
    }
}
