import React from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { PurchaseViewType } from "~/domains/transactions/_shared/types"
import { CustomFieldObjectAsTextfield } from "~/domains/transactions/custom-fields/components/CompanyCustomFields/CustomFieldObjectAsTextfield"
import { useFetchCustomFieldsByObjectId } from "~/domains/transactions/custom-fields/hooks"

const messages = defineMessages({
    title: { id: "invoice.additionalFields.title", defaultMessage: "Additional fields" },
})

interface PurchaseOrderCustomFieldsProps {
    objectId?: string
    pageMode: PurchaseViewType
}

export const PurchaseDocumentCustomFields: React.FC<PurchaseOrderCustomFieldsProps> = ({ objectId, pageMode }) => {
    const { formatMessage } = useIntl()
    const { customFields } = useFetchCustomFieldsByObjectId(objectId || "")
    const isReadOnly = pageMode === PurchaseViewType.VIEW
    const isTemporaryId = !objectId

    if (!customFields?.length) return null

    return (
        <Card title={formatMessage(messages.title)} expandable>
            {customFields?.map((cf) => (
                <CustomFieldObjectAsTextfield
                    key={cf.id}
                    customField={cf}
                    label={cf.name}
                    isTemporaryId={isTemporaryId}
                    isReadOnly={isReadOnly}
                />
            ))}
        </Card>
    )
}
