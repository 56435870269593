import { Container } from "@mui/material"
import React, { ReactNode, Suspense, useCallback, useEffect, useState } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

import { useGetPartnersQuery } from "~/api/partnerApiV1"
import { ModalFinalizeAccount, Sidebar } from "~/components/"
import { useFetchPartnersData } from "~/domains/partners/store/hooks"
import { OcrSocketIOContextProvider } from "~/domains/transactions/invoices/_views/ocr/components/OcrSocketIOContext"
import { useSegment } from "~/hooks/useSegment"
import { DASHBOARD_ROUTE } from "~/routes/routes"
import { selectDisplayModalFinalizeAccount, selectUser, selectUserId } from "~/store/account/accountSlice"
import { selectIsConnected } from "~/store/auth/authSlice"
import { globalActions, selectDisplayMenu, selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { UserI } from "~/types"

interface LayoutProps {
    modulesRoutes: ReactNode[]
    modulesNavItems: []
}

const isDevelopment = import.meta.env.VITE_ENV === "development"

function Layout({ modulesRoutes }: LayoutProps) {
    const displayModalFinalizeAccount = useAppSelector(selectDisplayModalFinalizeAccount)
    const sideBarOpened = useAppSelector(selectKeepSidebarOpened)
    const [prevPath, setPrevPath] = useState<null | string>(null)
    const { update } = useIntercom()
    const { segmentIdentify, segmentPage, segmentTrack } = useSegment()
    const isConnected = useAppSelector(selectIsConnected)
    const user = useAppSelector(selectUser)
    const userId = useAppSelector(selectUserId)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const displayMenu = useAppSelector(selectDisplayMenu)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const pathname = location.pathname
    const [page, setPage] = useState<string>(pathname)

    useEffect(() => {
        const newPage = pathname.split("/")[2]
        if (newPage !== page) {
            setPage(newPage)
        }
    }, [pathname, page])

    useEffect(() => {
        localStorage?.removeItem("invoice.store.current")
    }, [])

    useEffect(() => {
        const initTrackingUser = (id: string, user: UserI) => {
            update({
                name: user.fullName,
                userId: id,
                email: user.email,
                company: {
                    companyId: id, // TODO: change when companies will be detached from user entity
                    name: "",
                    //name: user.companyDetails.name ?? "",
                },
            })
            segmentIdentify(id, user)
        }
        if (isConnected && userId && !isDevelopment) {
            initTrackingUser(userId, user)
        }
    }, [isConnected, userId, update, user, segmentIdentify])

    const updateIntercomLocation = useCallback(() => {
        update()
    }, [update])

    useEffect(() => {
        if (!isDevelopment) {
            updateIntercomLocation()
            if (prevPath !== location.pathname) {
                segmentPage(location, prevPath)
                setPrevPath(location.pathname)
            }
        }
    }, [location, prevPath, segmentPage, updateIntercomLocation])

    useEffect(() => {
        if (displayModalFinalizeAccount && !isDevelopment) {
            segmentTrack("User Signed Up", {
                user_id: userId,
                origin_page: location.pathname,
            })
        }
    }, [displayModalFinalizeAccount, location.pathname, segmentTrack, userId])

    useGetPartnersQuery({ organizationId: currentOrganizationId || "" })

    const handleHideMenu = () => {
        dispatch(globalActions.setDisplayMenu())
    }

    return (
        <OcrSocketIOContextProvider>
            <div className={isConnected ? "logged-layout" : ""}>
                <Sidebar />
                <div className={`overlay-menu ${displayMenu ? "" : "hidden"}`} onClick={handleHideMenu} />
                <main className={`${sideBarOpened ? "sidebar-opened" : ""} page-${page}`}>
                    <>
                        <Container maxWidth={false}>
                            <Suspense fallback={<></>}>
                                <Routes>
                                    {modulesRoutes}
                                    <Route path="*" element={<Navigate to={DASHBOARD_ROUTE} replace />} />
                                </Routes>
                            </Suspense>
                        </Container>
                        {!displayModalFinalizeAccount ? null : (
                            <ModalFinalizeAccount displayModal={displayModalFinalizeAccount} />
                        )}
                    </>
                </main>
            </div>
        </OcrSocketIOContextProvider>
    )
}

export default Layout
