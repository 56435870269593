import { Stack, TextField } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { PartnerOrganizationI } from "~/domains/partners/types"
import {
    CompanyData,
    OrganizationPickerFromOCR,
    OrganizationPickerFromOcrResult,
    OrganizationPickerFromOcrResultType,
} from "~/domains/transactions/invoices/_views/ocr/components/OrganizationPickerFromOCR"
import { EstablishmentSelect } from "~/domains/transactions/invoices/_views/supplier/components/EstablishmentSelect"
import { registrationNumberIsPrefered } from "~/features/account/components/Company/FoundOrganization"
import { useSaveBuffer } from "~/hooks"
import { selectIsConnected } from "~/store/auth/authSlice"
import { useAppSelector } from "~/store/hooks"
import { fetchAndDecodeOrganization, useFetchOrganization } from "~/store/organization/hooks"
import {
    CompanyI,
    CountryCode,
    EstablishmentI,
    ImportInvoiceCompanyInfoI,
    InvoiceUserType,
    NO_ORGANIZATION_ID,
    OrganizationI,
    RegistrationNumberType,
    RegistrationNumberTypePerCountryCode,
    ViewTypeI,
    WhitePagesIdTypes,
} from "~/types"

import { useEstablishmentState } from "../../hooks"
import { CompanyAutocomplete, CompanyResult } from "../CompanyAutocomplete"
import { CompanyAutocompleteType } from "../CompanyAutocomplete/CompanyAutocompleteField"
import { UserOrganizationsSelect } from "../UserOrganizationsSelect"
import "./CompanyRegistrationFields.scss"

export enum organizationBlockType {
    SUPPLIER = "SUPPLIER",
    BUYER = "BUYER",
}

export type OtherOrganizationI = {
    id: string
    name: string
}

const messages = defineMessages({
    companyName: {
        id: "supplier.supplierInfo.companyName",
        defaultMessage: "Company Name",
    },
    siren: {
        id: "supplier.supplierInfo.siren",
        defaultMessage: "SIREN",
    },
    siret: {
        id: "supplier.supplierInfo.siret",
        defaultMessage: "SIRET",
    },
    taxNumber: {
        id: "supplier.supplierInfo.taxNumber",
        defaultMessage: "VAT NUMBER",
    },
    registrationNumber: {
        id: "company.registrationNumber",
        defaultMessage: "Registration number",
    },
    or: {
        id: "buyer.import.page.me.or",
        defaultMessage: "or",
    },
})

const countrySpecificRegistrationNumber: Record<RegistrationNumberType, MessageDescriptor> = defineMessages({
    [RegistrationNumberType.FRENCH_SIRET]: {
        id: `company.registrationNumber.FRENCH_SIRET`,
        defaultMessage: "SIRET",
    },
})

export const getRegistrationNumberLabel = (countryCode: CountryCode): MessageDescriptor => {
    const registrationNumberType = RegistrationNumberTypePerCountryCode[countryCode]
    if (registrationNumberType) return countrySpecificRegistrationNumber[registrationNumberType]
    return messages.registrationNumber
}

// Either an organization is selected, or a company, or the fields are filled freely

interface CompanyRegistrationFieldsProps {
    initiator: InvoiceUserType
    currentOrganization: OrganizationI | undefined
    organizations: (OrganizationI | PartnerOrganizationI)[]
    suggestedCompanies: CompanyI[] | undefined
    buyerOrSupplier: ImportInvoiceCompanyInfoI
    ocrCompanyDetails?: ImportInvoiceCompanyInfoI
    updateData: (data: Partial<ImportInvoiceCompanyInfoI>) => void
    disabled?: boolean
    organizationType: organizationBlockType
    isReadOnly?: boolean
}

export const CompanyRegistrationFields: React.FC<CompanyRegistrationFieldsProps> = ({
    initiator,
    currentOrganization,
    buyerOrSupplier,
    ocrCompanyDetails,
    suggestedCompanies,
    updateData,
    organizations,
    disabled,
    organizationType,
    isReadOnly,
}) => {
    const { formatMessage } = useIntl()
    const userIsConnected = useAppSelector(selectIsConnected)
    const [company, setCompany] = useState<CompanyResult>()
    const [companyLoading, setCompanyLoading] = useState<boolean>(false)
    const [searchAnotherOrganization, setSearchAnotherOrganization] = useState<boolean>(false)
    const [selectedEstablishment, setSelectedEstablishment] = useEstablishmentState(
        company?.type === CompanyAutocompleteType.WhitePagesResult ? company.value : undefined,
        undefined
    )
    const [fields, setFields] = useState<ImportInvoiceCompanyInfoI>(buyerOrSupplier)
    const [updatedField, setUpdatedField] = useState<Partial<ImportInvoiceCompanyInfoI>>({})

    /* const isCurrentOrganizationSelected = buyerOrSupplier.organizationId === currentOrganization?.id */
    const { organization: companyOrganization, loading: loadingCompanyOrganization } = useFetchOrganization(
        buyerOrSupplier.organizationId
    )

    const [companyResult, setCompanyResult] = useState<OrganizationPickerFromOcrResult | null>(
        companyOrganization
            ? { type: OrganizationPickerFromOcrResultType.ORGANIZATION, value: companyOrganization }
            : null
    )

    const readOnly = disabled || !!company /* || isCurrentOrganizationSelected */

    const vatNumberIsReadOnly =
        readOnly &&
        !(company && company.type === CompanyAutocompleteType.WhitePagesResult && !company.value.taxId) &&
        !(
            (buyerOrSupplier.organizationId && companyOrganization && !companyOrganization.registration.vatNumber) ||
            loadingCompanyOrganization
        )

    const isCurrentOrganizationBlock = organizationType.toString() === initiator.toString()

    const mustShowUserOrganizationsSelect = userIsConnected && organizations.length && isCurrentOrganizationBlock

    const mustShowCompanyAutocomplete =
        (suggestedCompanies && suggestedCompanies.length === 1) ||
        !userIsConnected ||
        !organizations.length ||
        searchAnotherOrganization ||
        !isCurrentOrganizationBlock

    useEffect(() => {
        // The current user has an organization selected but this organization is not selected
        if (
            !currentOrganization ||
            currentOrganization.id === NO_ORGANIZATION_ID ||
            buyerOrSupplier.organizationId === currentOrganization.id
        )
            return

        // let's check if we should select this organization as the current company (buyer or supplier depending of the context)
        if (
            (!suggestedCompanies || suggestedCompanies.length === 0) &&
            !buyerOrSupplier.taxId &&
            !buyerOrSupplier.registrationNumber
        ) {
            updateData({
                organizationId: currentOrganization.id,
                countryCode: currentOrganization.registration.countryCode,
                name: currentOrganization.name,
                taxId: currentOrganization.registration.vatNumber,
                registrationNumber: currentOrganization.registration.preferredRegistrationNumber?.registrationNumber,
                dunsNumber: currentOrganization.registration.dunsNumber,
            })
        }
    }, [currentOrganization, buyerOrSupplier, suggestedCompanies])

    useEffect(() => {
        setCompanyResult(
            !companyOrganization
                ? null
                : { type: OrganizationPickerFromOcrResultType.ORGANIZATION, value: companyOrganization }
        )
    }, [companyOrganization])

    /* useEffect(() => {
        setSearchAnotherOrganization(currentOrganization?.id !== buyerOrSupplier.organizationId)
    }, [currentOrganization, buyerOrSupplier]) */

    useEffect(() => {
        if (!company) return

        if (company.type === CompanyAutocompleteType.Organization) {
            updateData({
                name: company.value.name,
                organizationId: company.value.id,
                countryCode: company.value.registration.countryCode,
                dunsNumber: company.value.registration.dunsNumber ?? undefined,
                taxId: company.value.registration.vatNumber,
                registrationNumber: company.value.registration.preferredRegistrationNumber?.registrationNumber,
            })
            return
        }
        updateData({
            name: company.value.name,
            organizationId: null,
            countryCode: company.value.countryCode,
            dunsNumber: company.value.idType === WhitePagesIdTypes.DUNS ? company.value.id : null,
            registrationNumber:
                company.value.idType === WhitePagesIdTypes.FRENCH_SIRET
                    ? company.value.id
                    : (company.value.registrationNumbers.find(registrationNumberIsPrefered)?.registrationNumber ??
                      null),
            taxId: company.value.taxId,
        })
    }, [company, updateData])

    /* 
    This is causing re-renders when the user changes the organization :
    See: https://flowie-groupe.slack.com/archives/C05QRERB23Z/p1730726643244729
    useEffect(() => {
        if (currentOrganization && mustShowUserOrganizationsSelect && !isCurrentOrganizationSelected) {
            handleSelectUserOrganization(currentOrganization)
        }
    }, [mustShowUserOrganizationsSelect, isCurrentOrganizationSelected, currentOrganization])
    */

    useEffect(() => {
        if (!selectedEstablishment) return

        updateData({
            registrationNumber: selectedEstablishment.id,
        })
    }, [selectedEstablishment, updateData])

    const registrationNumberLabel = getRegistrationNumberLabel(buyerOrSupplier.countryCode)

    const handleCompanyChange = useCallback(
        (value?: CompanyResult, establishmentToSelect?: EstablishmentI) => {
            setCompany(value)
            setSelectedEstablishment(establishmentToSelect)
        },
        [setCompany, setSelectedEstablishment]
    )

    const save = useCallback(() => {
        updateData(updatedField)
    }, [updatedField])

    const { setHasChanges } = useSaveBuffer(save)

    const handleChange = useCallback(
        (data: Partial<ImportInvoiceCompanyInfoI>) => {
            setUpdatedField(data)
            setFields((prev) => ({ ...prev, ...data }) as ImportInvoiceCompanyInfoI)
            setHasChanges(true)
        },
        [setHasChanges]
    )

    const handleCountryCodeChange = useCallback(
        (data: CountryCode) => {
            setFields((prev) => ({ ...prev, countryCode: data }))
            updateData({ countryCode: data })
        },
        [updateData]
    )

    const handleSelectUserOrganization = useCallback(
        (data: OrganizationI | OtherOrganizationI) => {
            if (data.id === NO_ORGANIZATION_ID) {
                setSearchAnotherOrganization(true)
                setCompany(undefined)
                return
            }

            setSearchAnotherOrganization(false)
            setCompany({
                type: CompanyAutocompleteType.Organization,
                value: data as OrganizationI,
            })
        },
        [currentOrganization, organizations]
    )

    const handleOrganizationPickerChange = useCallback(
        async (result: OrganizationPickerFromOcrResult | null) => {
            setCompanyResult(result)

            if (!result) {
                updateData({
                    organizationId: null,
                    dunsNumber: null,
                    name: "",
                    registrationNumber: "",
                    taxId: "",
                })

                return
            }

            const { type, value } = result

            if (type !== OrganizationPickerFromOcrResultType.ORGANIZATION) {
                updateData({
                    organizationId: null,
                    name: value.name,
                    dunsNumber: value.idType === WhitePagesIdTypes.DUNS ? value.id : null,
                    registrationNumber:
                        value.registrationNumbers.find(registrationNumberIsPrefered)?.registrationNumber,
                    taxId: value.taxId,
                })

                return
            }

            const { dunsNumber, preferredRegistrationNumber } = value.registration
            const organization =
                dunsNumber || preferredRegistrationNumber?.registrationNumber
                    ? value
                    : await fetchAndDecodeOrganization(value.id)

            updateData({
                organizationId: organization.id,
                dunsNumber: organization.registration.dunsNumber,
                name: organization.name,
                registrationNumber: organization.registration.preferredRegistrationNumber?.registrationNumber,
                taxId: organization.registration.vatNumber,
            })
        },
        [updateData]
    )

    const ocrData = useMemo<CompanyData>(
        () => ({
            countryCode: buyerOrSupplier.countryCode,
            name: ocrCompanyDetails?.name,
            registrationNumber: ocrCompanyDetails?.registrationNumber || undefined,
            taxNumber: ocrCompanyDetails?.taxId || undefined,
        }),
        [ocrCompanyDetails, buyerOrSupplier]
    )

    // TODO: this is ugly, sorry
    if (initiator.toString() !== organizationType.toString()) {
        return (
            <OrganizationPickerFromOCR
                ocrData={ocrData}
                options={organizations}
                onChange={handleOrganizationPickerChange}
                setCountryCode={handleCountryCodeChange}
                value={companyResult}
                viewType={organizationType === organizationBlockType.BUYER ? ViewTypeI.buyer : ViewTypeI.supplier}
                disabled={disabled}
            />
        )
    }

    return (
        <>
            {!disabled && !companyLoading && (
                <Stack className="company-registration-fields" gap={1}>
                    {mustShowUserOrganizationsSelect && (
                        <>
                            <UserOrganizationsSelect
                                selectedOrganizationId={buyerOrSupplier.organizationId as string}
                                organizations={organizations}
                                onSelect={handleSelectUserOrganization}
                                searchAnotherOrganization={searchAnotherOrganization}
                                readOnly={isReadOnly}
                            />
                            {searchAnotherOrganization ? (
                                <div className="or-divider">{formatMessage(messages.or)}</div>
                            ) : null}
                        </>
                    )}

                    {mustShowCompanyAutocomplete && (
                        <CompanyAutocomplete
                            company={company}
                            setCompany={handleCompanyChange}
                            organizations={organizations}
                            suggestedCompanies={suggestedCompanies}
                            countryCode={fields.countryCode}
                            setCountryCode={handleCountryCodeChange}
                            setCompanyLoading={setCompanyLoading}
                        />
                    )}

                    <TextField
                        required
                        id="supplier.name"
                        name="supplier.name"
                        className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                        placeholder={formatMessage(messages.companyName)}
                        onChange={(event) => handleChange({ name: event.currentTarget.value })}
                        aria-readonly={readOnly}
                        inputProps={{ readOnly }}
                        value={fields?.name ?? ""}
                        label={!mustShowCompanyAutocomplete ? null : formatMessage(messages.companyName)}
                        fullWidth
                        type={!mustShowCompanyAutocomplete ? "hidden" : "text"}
                    />

                    <TextField
                        id="supplier.registrations.vatNumber"
                        name="supplier.registrations.vatNumber"
                        className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                        label={!mustShowCompanyAutocomplete ? null : formatMessage(messages.taxNumber)}
                        placeholder={formatMessage(messages.taxNumber)}
                        onChange={(event) => handleChange({ taxId: event.currentTarget.value })}
                        aria-readonly={vatNumberIsReadOnly}
                        inputProps={{
                            readOnly: vatNumberIsReadOnly,
                        }}
                        value={fields?.taxId ?? ""}
                        fullWidth
                        type={!mustShowCompanyAutocomplete ? "hidden" : "text"}
                    />

                    {company?.type === CompanyAutocompleteType.WhitePagesResult && company.value.establishments ? (
                        <EstablishmentSelect
                            options={company.value.establishments}
                            value={selectedEstablishment}
                            onChange={setSelectedEstablishment}
                            className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                        />
                    ) : (
                        <TextField
                            required
                            id="supplier.company.registrationNumber"
                            name="supplier.company.registrationNumber"
                            className={!mustShowCompanyAutocomplete ? "input-hidden" : ""}
                            label={!mustShowCompanyAutocomplete ? null : formatMessage(registrationNumberLabel)}
                            placeholder={formatMessage(registrationNumberLabel)}
                            onChange={(event) => handleChange({ registrationNumber: event.currentTarget.value })}
                            aria-readonly={readOnly}
                            inputProps={{ readOnly }}
                            value={fields?.registrationNumber ?? ""}
                            fullWidth
                            type={!mustShowCompanyAutocomplete ? "hidden" : "text"}
                        />
                    )}
                </Stack>
            )}
        </>
    )
}
