import { Autocomplete, Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { FC, useState } from "react"
import { useIntl } from "react-intl"

import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { ConfigurationNode } from "~/domains/orchestration/flows/components/configuration/ConfigurationNode"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/messages"
import { AssignTagNode, ConfigurationProps } from "~/domains/orchestration/flows/types"
import { useOrganizationTagGroups } from "~/domains/tags/hooks/useOrganizationTagGroups"
import { TagI } from "~/domains/tags/types"

export const AssignTagConfiguration: FC<ConfigurationProps<AssignTagNode>> = ({ selectedNode, advancedFields }) => {
    const { formatMessage } = useIntl()
    const organizationId = useOrganizationId()

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const { tagGroups } = useOrganizationTagGroups(organizationId)
    const tags = tagGroups?.flatMap((tagGroup) => tagGroup.tags) || []

    const initialTag = tags.find((tag) => tag.tagId === currentNode.tagId) || null

    const [tag, setTag] = useState<TagI | null>(initialTag)

    const handleChangeTag = (_, value: TagI | null) => {
        if (!value) return

        setTag(value)
        setCurrentNode({
            ...currentNode,
            tagId: value.tagId,
            metadata: {
                ...currentNode.metadata,
                additionalInformation: value.name,
            },
        })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    const tagsLoading = tagGroups === null

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <Autocomplete
                    options={tags}
                    value={tag}
                    loading={tagsLoading}
                    onChange={handleChangeTag}
                    getOptionLabel={(tag: TagI) => tag.name}
                    getOptionKey={(tag: TagI) => tag.tagId}
                    isOptionEqualToValue={(option: TagI, value: TagI) => option.tagId === value.tagId}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.assignTagConfiguration.tagIdLabel)} />
                    )}
                />
            </Stack>

            <AdvancedFields<AssignTagNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
