import {
    FormControlLabel,
    FormLabel,
    Grid,
    LinearProgress,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    capitalize,
} from "@mui/material"
import * as Sentry from "@sentry/browser"
import React, { useCallback, useEffect, useState } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { organizationApi } from "~/api"
import { useGetPartnersQuery } from "~/api/partnerApiV1"
import { useWhitePagesApi } from "~/api/whitePagesApi"
import { Button, Loader, Modal, SafeFormattedMessage } from "~/components"
import { CreateOrganizationContainer as ManualImportContainer } from "~/domains/identity/features/organizations/components/CreateOrganizationContainer/CreateOrganizationContainer"
import { ManualCompanyInputTrigger } from "~/domains/identity/features/organizations/components/CreateOrganizationContainer/ManualInputContainer"
import { ModalContact } from "~/domains/partners/components/PartnerProfile/components"
import { PartnerProfileSecheCustomFields } from "~/domains/partners/components/PartnerProfile/components/PartnerProfileSecheCustomFields"
import { computePartnerProfile, isPartnerProfileContact } from "~/domains/partners/core"
import { usePartnersPermissions } from "~/domains/partners/hooks/usePartnersPermissions"
import { PARTNER_DETAILS_ROUTE } from "~/domains/partners/routes"
import {
    useCreatePartnerProfile,
    useCreatePartnerProfileContact,
    useCreatePartnership,
    useFetchPartnerProfile,
    useUpdatePartnerProfileContact,
} from "~/domains/partners/store/hooks"
import {
    CreatePartnerProfileContactI,
    CreatePartnershipPayload,
    PartnerProfileContactI,
    PartnershipTypeOption,
} from "~/domains/partners/types"
import {
    customFieldsListByOrganizationId,
    secheRequiredContactFields,
} from "~/domains/transactions/custom-fields/components/CompanyCustomFields/CompanyCustomFieldsConfig"
import {
    useHasRequiredSecheCustomFields,
    usePendingCustomFieldsByFieldNames,
} from "~/domains/transactions/custom-fields/hooks"
import { EstablishmentSelect } from "~/domains/transactions/invoices/_views/supplier/components/EstablishmentSelect"
import { useCompanyResultFromCompanyDetails } from "~/domains/transactions/invoices/components/InvoiceSupplierInfo"
import { CompanyAutocomplete, CompanyResult } from "~/features/organization/components/CompanyAutocomplete"
import { CompanyAutocompleteType } from "~/features/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { useEstablishmentState } from "~/features/organization/hooks"
import { getImportCompanyIdentifierOrEmptyString } from "~/store/invoice/core"
import {
    CompanyI,
    CountryCode,
    CreateOrganizationResponseI,
    EstablishmentI,
    ImportInvoiceCompanyInfoI,
    OrganizationI,
    OrganizationId,
} from "~/types"
import { isResultSuccess } from "~/types/Result"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

const NO_SUGGESTED_COMPANIES: CompanyI[] = []

const messages = defineMessages({
    modalTitle: {
        id: "partners.bookofrelations.modal.create.title",
        defaultMessage: "Add a new partner",
    },
    modalCancel: {
        id: "partners.bookofrelations.modal.create.cancel",
        defaultMessage: "Cancel",
    },
    modalCreateAnother: {
        id: "partners.bookofrelations.modal.create.another",
        defaultMessage: "Create & add another",
    },
    modalConfirm: {
        id: "partners.bookofrelations.modal.create.confirm",
        defaultMessage: "Create",
    },
    organization: {
        id: "partners.bookofrelations.modal.create.organization",
        defaultMessage: "Organization",
    },
    searchPartner: {
        id: "partners.bookofrelations.modal.create.searchPartner",
        defaultMessage: "Search for the partner's company",
    },
    preferredStatus: {
        id: "partners.bookofrelations.modal.create.preferredStatus",
        defaultMessage: "Mark as preferred",
    },
    verifiedStatus: {
        id: "partners.bookofrelations.modal.create.verifiedStatus",
        defaultMessage: "Mark as verified",
    },
    createSuccess: {
        id: "partners.bookofrelations.modal.create.success",
        defaultMessage: "The new partner has been added to your Partners network",
    },
    createPartnershipError: {
        id: "partners.bookofrelations.modal.create.partnershipError",
        defaultMessage: "We encountered an error creating the partnership. Please try again.",
    },
    createOrganizationError: {
        id: "partners.bookofrelations.modal.create.organizationError",
        defaultMessage: "We encountered an error creating the organization. Please try again.",
    },
    siretLabel: {
        id: "partners.bookofrelations.modal.create.siretLabel",
        defaultMessage: "Siret",
    },
    addressLabel: {
        id: "partners.bookofrelations.modal.create.addressLabel",
        defaultMessage: "Address",
    },
    contactLabel: {
        id: "customrules.company.seche.modal.create.primaryContactLabel",
        defaultMessage: "Primary contact",
    },
    modalNext: {
        id: "customrules.company.seche.modal.create.next",
        defaultMessage: "Next",
    },
})

export const partnershipTypeMessages: Record<PartnershipTypeOption, MessageDescriptor> = defineMessages({
    [PartnershipTypeOption.BUYER]: {
        id: "partners.bookofrelations.createPartnershipType.buyer",
        defaultMessage: "client",
    },
    [PartnershipTypeOption.SUPPLIER]: {
        id: "partners.bookofrelations.createPartnershipType.supplier",
        defaultMessage: "vendor",
    },
    [PartnershipTypeOption.BOTH]: {
        id: "partners.bookofrelations.createPartnershipType.both",
        defaultMessage: "both Vendor & Client",
    },
})

const createEmptyPartner = (countryCode = CountryCode.FR): ImportInvoiceCompanyInfoI => ({
    countryCode,
    name: "",
    contactName: "",
    organizationId: null,
})

const getCountryCodeFromCompanyResult = (type: CompanyAutocompleteType, value: any): CountryCode | null => {
    switch (type) {
        case CompanyAutocompleteType.WhitePagesResult:
            return value?.countryCode || null
        case CompanyAutocompleteType.Organization:
            return value?.registration?.countryCode || null
        default:
            return null
    }
}

interface CreatePartnershipModalProps {
    organizationId: OrganizationId
    open: boolean
    close: () => void
    displayContactModal?: () => void
}

export const ModalCreatePartnership: React.FC<CreatePartnershipModalProps> = ({ organizationId, open, close }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [companyLoading, setCompanyLoading] = useState(false)
    const [establishementLoading, setEstablishementLoading] = useState(false)
    const [createPartnershipType, setPartnershipType] = useState(PartnershipTypeOption.SUPPLIER)
    const [verifiedStatus, setVerifiedStatus] = useState(false)
    const [preferredStatus, setPreferredStatus] = useState(false)
    const [countryCode, setCountryCode] = useState(CountryCode.FR)
    const [manualImport, setManualImport] = useState(false)

    const [companyResult, setCompanyResult] = useState<CompanyResult | undefined>()
    const [selectedEstablishment, setSelectedEstablishment] = useEstablishmentState(
        companyResult?.type === CompanyAutocompleteType.WhitePagesResult ? companyResult.value : undefined
    )
    const [contactModalVisible, setContactModalVisible] = useState(false)
    const onCountryCodeChange = useCallback((countryCode: CountryCode) => setCountryCode(countryCode), [setCountryCode])
    const [partnerOrganization, setPartnerOrganization] = useState(createEmptyPartner())
    const [partnerId, setPartnerId] = useState<OrganizationId | undefined>(undefined)

    const initialCompanyResult = useCompanyResultFromCompanyDetails(partnerOrganization, NO_SUGGESTED_COMPANIES)
    const { createPartnerships } = useCreatePartnership(organizationId)
    const { refetch: refetchPartners } = useGetPartnersQuery({ organizationId, withMetrics: true })

    const partnersPermissions = usePartnersPermissions(organizationId)

    // Feature flags Custom fields and rules
    const hasSecheCustomFields = isFeatureEnabled(Features.SecheCustomFields, organizationId)
    const hasSecheCustomRules = isFeatureEnabled(Features.SecheCustomRules, organizationId)

    const customFieldsList = customFieldsListByOrganizationId[organizationId ?? ""]
    const pendingCustomFields = usePendingCustomFieldsByFieldNames(customFieldsList)
    const hasRequiredCustomFields = useHasRequiredSecheCustomFields(pendingCustomFields)

    const saveDisabled = !partnerOrganization.registrationNumber || (hasSecheCustomFields && !hasRequiredCustomFields)

    const wp = useWhitePagesApi()

    const onEstablishmentSelectionChange = useCallback(
        async (establishment: EstablishmentI | undefined) => {
            setSelectedEstablishment(establishment)
            if (!establishment) {
                return updateData({
                    registrationNumber: undefined,
                })
            }
            setEstablishementLoading(true)
            try {
                const results = await wp.searchDnBCompanyFromRegistrationNumber(establishment.id, countryCode)
                if (isResultSuccess(results) && results.result.length > 0) {
                    updateData({
                        registrationNumber: results.result[0].id,
                    })
                }
            } finally {
                setEstablishementLoading(false)
            }
        },
        [wp, countryCode]
    )

    // Organization related functions
    useEffect(() => {
        if (!initialCompanyResult) return
        setCompanyResult(initialCompanyResult)
        const { type, value } = initialCompanyResult
        const countryCode = getCountryCodeFromCompanyResult(type, value)

        if (countryCode) {
            setCountryCode(countryCode)
        }
    }, [initialCompanyResult])

    const onCompanyChange = useCallback(
        (company: CompanyResult | undefined, establishmentsToSelect?: EstablishmentI) => {
            onEstablishmentSelectionChange(establishmentsToSelect)
            if (!company) return updateData(createEmptyPartner())
            if (company.type === CompanyAutocompleteType.Organization) {
                updateData({
                    organizationId: company.value.id,
                    countryCode: company.value.registration.countryCode,
                    name: company.value.name,
                    registrationNumber:
                        company.value.registration.dunsNumber ??
                        company.value.registration.preferredRegistrationNumber?.registrationNumber,
                })
            } else if (company.type === CompanyAutocompleteType.WhitePagesResult) {
                const registrationNumber = establishmentsToSelect
                    ? establishmentsToSelect.id
                    : company.value.establishments && company.value.establishments.length > 0
                      ? undefined
                      : company.value.id
                updateData({
                    organizationId: undefined,
                    countryCode: company.value.countryCode,
                    name: company.value.name,
                    registrationNumber,
                    establishments: company.value.establishments,
                })
                if (!establishmentsToSelect && company.value.establishments?.length === 1) {
                    onEstablishmentSelectionChange(company.value.establishments[0])
                }
            }
        },
        []
    )
    const onManualImportClick = () => setManualImport(true)
    const cancelManualImport = () => setManualImport(false)

    const createNewOrganization = async (
        partner: ImportInvoiceCompanyInfoI
    ): Promise<CreateOrganizationResponseI | null> => {
        try {
            const organizationItem = await organizationApi.createOrganization(
                partner.name,
                partner.countryCode,
                getImportCompanyIdentifierOrEmptyString(partner)
            )
            return organizationItem
        } catch (error) {
            toast.error(formatMessage(messages.createOrganizationError))
            Sentry.captureException(error, {
                extra: { companyResult, partner, organizationId },
            })
            return null
        }
    }

    // Partnership related functions
    const handleCreatePartnership = async (partnerId: OrganizationId | undefined) => {
        if (!partnerId) {
            toast.error(formatMessage(messages.createOrganizationError))
            return
        }
        const payload: CreatePartnershipPayload = {
            partnerId,
            partnershipType: createPartnershipType,
            verifiedStatus,
            preferredStatus,
        }

        try {
            await createPartnerships(payload)
            return partnerId
        } catch (error) {
            toast.error(formatMessage(messages.createPartnershipError))
            Sentry.captureException(error, {
                extra: {
                    partner: partnerOrganization,
                    organizationId,
                },
            })
        } finally {
            setLoading(false)
        }
    }

    const handleNextSteps = (createdPartnerId: OrganizationId | undefined, createAnother = false) => {
        if (hasSecheCustomRules && createdPartnerId) {
            setPartnerId(createdPartnerId)
            handleClose()
            showContactModal()
        } else if (!createAnother && createdPartnerId) {
            toast.success(formatMessage(messages.createSuccess))
            handleClose()
            partnersPermissions.create &&
                navigate(generatePath(PARTNER_DETAILS_ROUTE, { organizationId: createdPartnerId }))
        } else {
            toast.success(formatMessage(messages.createSuccess))
            clearComponent()
            refetchPartners()
        }
    }

    const handleOrganizationAndPartnership = useCallback(
        async (createAnother = false) => {
            setLoading(true)
            const createdPartnerId = await handleCreatePartnership(
                partnerOrganization.organizationId || (await createNewOrganization(partnerOrganization))?.id
            )
            handleNextSteps(createdPartnerId, createAnother)
        },
        [
            partnerOrganization,
            createPartnershipType,
            verifiedStatus,
            preferredStatus,
            createPartnerships,
            handleNextSteps,
            createNewOrganization,
        ]
    )

    const handleManualOrganizationCreated = useCallback((newOrganization: OrganizationI) => {
        const newCompanyResult: CompanyResult = {
            type: CompanyAutocompleteType.Organization,
            value: newOrganization,
        }
        setCompanyResult(newCompanyResult)
        const newPartner = {
            organizationId: newOrganization.id,
            countryCode: newOrganization.registration?.countryCode,
            name: newOrganization.name,
            registrationNumber: newOrganization.registration?.preferredRegistrationNumber?.registrationNumber,
        }
        updateData(newPartner)
        setManualImport(false)
    }, [])

    const handlePartnershipTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPartnershipType((event.target as HTMLInputElement).value as PartnershipTypeOption)
    }

    const onPreferredStatusChange = () => setPreferredStatus((prev) => !prev)

    const onVerifiedStatusChange = () => setVerifiedStatus((prev) => !prev)

    const updateData = useCallback(
        (data: Partial<ImportInvoiceCompanyInfoI>) => {
            setPartnerOrganization((currentState) => ({ ...currentState, ...data }))
        },
        [setPartnerOrganization]
    )

    const onSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleOrganizationAndPartnership()
        },
        [handleOrganizationAndPartnership]
    )

    const clearComponent = () => {
        setPartnerOrganization(createEmptyPartner())
        setPartnershipType(PartnershipTypeOption.SUPPLIER)
        setVerifiedStatus(false)
        setPreferredStatus(false)
        setContactModalVisible(false)
        setCompanyResult(undefined)
    }

    const handleClose = useCallback(() => {
        clearComponent()
        close()
    }, [close])

    const handleCreateAnother = () => {
        handleOrganizationAndPartnership(true)
    }

    // Feature flags / Contact modal related functions
    const { partnerProfile } = useFetchPartnerProfile(organizationId, partnerId)

    const closeContactModal = () => setContactModalVisible(false)
    const showContactModal = () => setContactModalVisible(true)

    const { createPartnerProfile } = useCreatePartnerProfile()
    const { updatePartnerProfileContact } = useUpdatePartnerProfileContact()
    const { createPartnerProfileContact } = useCreatePartnerProfileContact()

    const handleSave = useCallback(
        async (contact: CreatePartnerProfileContactI | PartnerProfileContactI) => {
            if (!partnerProfile) {
                const computedProfile = computePartnerProfile(
                    partnerProfile,
                    { contacts: [contact] },
                    organizationId,
                    partnerId
                )
                await createPartnerProfile(computedProfile)
            } else if (isPartnerProfileContact(contact)) {
                await updatePartnerProfileContact(partnerProfile.initiatorId, partnerProfile.partnerId, contact)
            } else {
                await createPartnerProfileContact(partnerProfile.initiatorId, partnerProfile.partnerId, contact)
            }
            toast.success(formatMessage(messages.createSuccess))
            refetchPartners()
            handleClose()
            if (partnerId) {
                navigate(generatePath(PARTNER_DETAILS_ROUTE, { organizationId: partnerId }))
            }
        },
        [partnerProfile, organizationId, partnerId]
    )

    return (
        <>
            {contactModalVisible && hasSecheCustomRules ? (
                <ModalContact
                    save={handleSave}
                    open={contactModalVisible}
                    close={closeContactModal}
                    requiredFields={secheRequiredContactFields}
                    label={formatMessage(messages.contactLabel)}
                />
            ) : (
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-request" className="modal-request">
                    <Modal.Header>
                        <h4>
                            <SafeFormattedMessage {...messages.modalTitle} />
                        </h4>
                    </Modal.Header>
                    {!manualImport ? (
                        <form onSubmit={onSubmit}>
                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                    <Modal.Content>
                                        <Stack gap={2}>
                                            <CompanyAutocomplete
                                                company={companyResult}
                                                setCompany={onCompanyChange}
                                                countryCode={countryCode}
                                                setCountryCode={onCountryCodeChange}
                                                setCompanyLoading={setCompanyLoading}
                                                organizations={[]}
                                                suggestedCompanies={[]}
                                            />
                                            {companyLoading && <LinearProgress color="primary" />}
                                            {partnerOrganization.establishments &&
                                            partnerOrganization.establishments.length > 1 ? (
                                                <Stack>
                                                    <EstablishmentSelect
                                                        options={partnerOrganization.establishments}
                                                        value={selectedEstablishment}
                                                        onChange={onEstablishmentSelectionChange}
                                                    />
                                                    {establishementLoading && <LinearProgress color="primary" />}
                                                </Stack>
                                            ) : (
                                                selectedEstablishment && (
                                                    <TextField
                                                        value={selectedEstablishment.id}
                                                        label={formatMessage(messages.siretLabel)}
                                                        disabled
                                                        aria-readonly
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                )
                                            )}
                                            <ManualCompanyInputTrigger onClick={onManualImportClick} />
                                            <hr />
                                            <FormLabel id="radio-label">
                                                {hasSecheCustomRules ? null : (
                                                    <RadioGroup
                                                        aria-labelledby="partnershipType-radio-group"
                                                        name="partnership.partnershipType"
                                                        value={createPartnershipType}
                                                        onChange={handlePartnershipTypeChange}
                                                    >
                                                        <Grid container alignItems="center" gap={1}>
                                                            <FormControlLabel
                                                                value={PartnershipTypeOption.SUPPLIER}
                                                                control={<Radio />}
                                                                label={capitalize(
                                                                    formatMessage(
                                                                        partnershipTypeMessages[
                                                                            PartnershipTypeOption.SUPPLIER
                                                                        ]
                                                                    )
                                                                )}
                                                            />
                                                            <FormControlLabel
                                                                value={PartnershipTypeOption.BUYER}
                                                                control={<Radio />}
                                                                label={capitalize(
                                                                    formatMessage(
                                                                        partnershipTypeMessages[
                                                                            PartnershipTypeOption.BUYER
                                                                        ]
                                                                    )
                                                                )}
                                                            />
                                                            <FormControlLabel
                                                                value={PartnershipTypeOption.BOTH}
                                                                control={<Radio />}
                                                                label={capitalize(
                                                                    formatMessage(
                                                                        partnershipTypeMessages[
                                                                            PartnershipTypeOption.BOTH
                                                                        ]
                                                                    )
                                                                )}
                                                            />
                                                        </Grid>
                                                    </RadioGroup>
                                                )}
                                            </FormLabel>
                                            {hasSecheCustomFields && (
                                                <Stack>
                                                    <PartnerProfileSecheCustomFields
                                                        partnershipId={partnerOrganization?.organizationId ?? ""}
                                                        currentOrganizationId={organizationId}
                                                        readOnly={false}
                                                    />
                                                </Stack>
                                            )}
                                            <Grid container alignItems="center" gap={2}>
                                                <Grid container alignItems="center" gap={1}>
                                                    <FormLabel>
                                                        {capitalize(formatMessage(messages.preferredStatus))}
                                                    </FormLabel>
                                                    <Switch
                                                        checked={preferredStatus}
                                                        onChange={onPreferredStatusChange}
                                                        color="primary"
                                                    />
                                                </Grid>
                                                <Grid container alignItems="center" gap={1}>
                                                    <FormLabel>
                                                        {capitalize(formatMessage(messages.verifiedStatus))}
                                                    </FormLabel>
                                                    <Switch
                                                        checked={verifiedStatus}
                                                        onChange={onVerifiedStatusChange}
                                                        color="primary"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Modal.Content>
                                    <Modal.Footer>
                                        <Button onClick={handleClose} rightIcon="close" type="neutral">
                                            <SafeFormattedMessage {...messages.modalCancel} />
                                        </Button>
                                        {hasSecheCustomRules ? (
                                            <Button buttonType="submit" disabled={saveDisabled}>
                                                <SafeFormattedMessage {...messages.modalNext} />
                                            </Button>
                                        ) : (
                                            <>
                                                <Button
                                                    onClick={handleCreateAnother}
                                                    type="primary-light"
                                                    disabled={saveDisabled}
                                                >
                                                    <SafeFormattedMessage {...messages.modalCreateAnother} />
                                                </Button>
                                                <Button buttonType="submit" disabled={saveDisabled}>
                                                    <SafeFormattedMessage {...messages.modalConfirm} />
                                                </Button>
                                            </>
                                        )}
                                    </Modal.Footer>
                                </>
                            )}
                        </form>
                    ) : (
                        <ManualImportContainer
                            cancelManualImport={cancelManualImport}
                            companyResult={companyResult}
                            onOrganizationCreated={handleManualOrganizationCreated}
                        />
                    )}
                </Modal>
            )}
        </>
    )
}
