import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material"

import { ViewTypeI } from "~/types"

interface ViewSwitcherTabProps {
    view: ViewTypeI | string
    labelSupplier: string
    labelBuyer: string
    actionSupplier: () => void
    actionBuyer: () => void
}

export function ViewSwitcherTab({
    view,
    labelSupplier,
    labelBuyer,
    actionSupplier,
    actionBuyer,
}: ViewSwitcherTabProps) {
    return (
        <Stack direction="row" justifyContent="center" spacing={1} width="100%" paddingBottom={3}>
            <ToggleButtonGroup value={view} exclusive color="primary">
                <ToggleButton value="buyer" onClick={actionBuyer}>
                    {labelBuyer}
                </ToggleButton>
                <ToggleButton value="supplier" onClick={actionSupplier}>
                    {labelSupplier}
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    )
}
