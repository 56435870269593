export const toggleButtonsStyles = {
    "&.MuiToggleButtonGroup-root": {
        "> .MuiToggleButton-root": {
            border: "1px solid var(--separator-color)",
            backgroundColor: "var(--color-white)",
            textTransform: "none",
            fontSize: "var(--font-size-md)",
            fontWeight: "var(--font-weight-bold)",
            "&:not(.Mui-selected):hover": {
                backgroundColor: "var(--primary-color-light)",
            },
            "&.Mui-selected": {
                backgroundColor: "var(--primary-color)",
                color: "var(--color-white)",
            },
        },
    },
}
