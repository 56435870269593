import { useCallback, useEffect } from "react"

import { useCommunicationApi } from "~/domains/communication/api"
import { useSocketIOContext } from "~/domains/transactions/common/subscriptions/components/SocketIOContext"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { getResultSuccessValue, isResultSuccess } from "~/types/Result"

import { sortMessagesByDate } from "../core"
import { communicationActions } from "../store/CommunicationSlice"
import { selectMessagesState } from "../store/selectors"
import { RoomId } from "../types"
import { useMessagesWithUsers } from "./useMessagesWithUsers"
import { MessageFromApiAdapter } from "~/domains/communication/api/adapters"

export const useRoomMessages = (organizationId: OrganizationId, roomId: RoomId) => {
    const user = useAppSelector(selectUser)
    const { fetchingMessagesInRoom, messagesPerRoom } = useAppSelector(selectMessagesState)
    const communicationApi = useCommunicationApi()
    const dispatch = useAppDispatch()

    const { ioSocket, isConnected } = useSocketIOContext()

    const messagesInRoom = messagesPerRoom[roomId]
    const fetchingInRoom = fetchingMessagesInRoom[roomId]

    const messages = messagesInRoom?.messages
    const messagesWithUsers = useMessagesWithUsers(messages)

    const fetchMoreMessages = useCallback(async () => {
        if (
            !fetchingInRoom &&
            (!messagesInRoom ||
                !messagesInRoom.paginationInfo ||
                messagesInRoom.paginationInfo.total_pages > messagesInRoom.paginationInfo.current_page)
        ) {
            const page = messagesInRoom?.paginationInfo ? messagesInRoom.paginationInfo.current_page + 1 : 1
            dispatch(communicationActions.fetchMessageInRoom({ roomId }))
            const result = await communicationApi.getMessages(organizationId, roomId, page)
            if (isResultSuccess(result)) {
                dispatch(
                    communicationActions.fetchMessageInRoomSuccess({
                        roomId,
                        roomMessages: {
                            paginationInfo: result.result.pagination_info,
                            messages: result.result.messages.sort(sortMessagesByDate),
                        },
                    })
                )
            }
        }
    }, [organizationId, roomId, fetchingInRoom, messagesInRoom])

    useEffect(() => {
        if (!fetchingInRoom && !messagesInRoom) {
            fetchMoreMessages()
        }
    }, [fetchingInRoom, messagesInRoom])

    useEffect(() => {
        if (user && user.id) {
            ioSocket.on(roomId, (data) => {
                const message = MessageFromApiAdapter(data)
                if (isResultSuccess(message)) {
                    dispatch(communicationActions.addMessageInRoom({ roomId, message: message.result }))
                }
            })
            ioSocket.emit(`subscribe/chat`, { roomId, user })
            return () => {
                ioSocket.emit(`unsubscribe/chat`, { roomId, user })
            }
        }
    }, [ioSocket, isConnected, user])

    return { messagesInRoom: messagesWithUsers, loading: fetchingInRoom, fetchMoreMessages }
}
