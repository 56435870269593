import { styled } from "@mui/material"
import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { Button } from "~/components"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { useFlowActions } from "~/domains/orchestration/flows/hooks/useFlowActions"
import { messages } from "~/domains/orchestration/flows/messages"

const Publish = styled(Button)({
    borderRadius: "var(--border-radius-sm)",
    marginLeft: "var(--spacing-sm)",
})

export const PublishButton: FC = () => {
    const dispatch = useEditorDispatch()
    const state = useEditor()
    const flow = state.flow
    const { updateFlow } = useFlowActions()

    const { isFlowPublishable, error } = state

    const handlePublish = async () => {
        if (!flow) return
        const updatedFlow = await updateFlow({ id: flow.id, flow })

        if (!updatedFlow) return

        dispatch({ type: "SET_FLOW", payload: updatedFlow })
        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: false,
        })
    }

    const disablePublish = !isFlowPublishable || error !== null
    return (
        <Publish disabled={disablePublish} onClick={handlePublish}>
            <FormattedMessage {...messages.editor.publish} />
        </Publish>
    )
}
