import { Box, Tooltip } from "@mui/material"
import React, { useEffect, useState } from "react"
import { AlertTriangle, Trash } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, ModalConfirm, SafeFormattedMessage } from "~/components"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"

const messages = defineMessages({
    label: { id: "purchase.requests.request.delete.deleteRequest", defaultMessage: "Delete this PR" },
    cancel: { id: "common.cancel", defaultMessage: "Cancel" },
    confirm: { id: "purchase.requests.request.delete.confirm", defaultMessage: "I want to delete this PR" },
    message: {
        id: "purchase.requests.request.delete.confirmMessage",
        defaultMessage: "Are you sure you want to delete this PR ? This action is not reversible !",
    },
})

interface Props {
    hasPermission?: boolean
    handleDelete: () => void
}

export function Delete({ handleDelete, hasPermission }: Props) {
    const { formatMessage } = useIntl()
    const [deleteRequest, setDeleteRequest] = useState<boolean>(false)
    const [displayModal, setDisplayModal] = useState<boolean>(false)
    const canDelete = (hasPermission && typeof hasPermission !== "undefined") ?? false

    useEffect(() => {
        if (deleteRequest) {
            setDisplayModal(false)
            handleDelete()
        }
    }, [deleteRequest])
    const handleClick = () => {
        setDisplayModal(true)
    }

    return (
        <>
            <Box component="div" className="cancel">
                <Tooltip arrow title={!canDelete ? formatMessage(permissionMessages.errorNoAccessAdministrator) : ""}>
                    <span>
                        <Button type="grey" disabled={!canDelete} {...(canDelete ? { onClick: handleClick } : {})}>
                            <SafeFormattedMessage {...messages.label} /> <Trash size={16} />
                        </Button>
                    </span>
                </Tooltip>
            </Box>
            <ModalConfirm
                message={formatMessage(messages.message)}
                icon={
                    <div className="icon-container red">
                        <AlertTriangle size={20} />
                    </div>
                }
                button1={
                    <Button
                        type="neutral"
                        onClick={() => setDisplayModal(false)}
                        text={formatMessage(messages.cancel)}
                    />
                }
                button2={
                    <Button
                        type="error"
                        onClick={() => setDeleteRequest(true)}
                        text={formatMessage(messages.confirm)}
                    />
                }
                open={displayModal}
            />
        </>
    )
}
