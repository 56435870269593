import { useCallback, useEffect, useMemo } from "react"

import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { isResultSuccess } from "~/types/Result"

import { useDocumentsApi } from "../documentsApi"
import {
    documentsActions,
    selectSelectedDocument,
    selectSelectedDocumentFetchingError,
    selectSelectedDocumentLoading,
} from "../store/documentsSlice"

export const useDocumentById = (documentId: string | undefined) => {
    const document = useAppSelector(selectSelectedDocument)
    const loading = useAppSelector(selectSelectedDocumentLoading)
    const error = useAppSelector(selectSelectedDocumentFetchingError)
    const documentsApi = useDocumentsApi()
    const dispatch = useAppDispatch()

    const fetchDocument = useCallback(async () => {
        if (!documentId) return
        dispatch(documentsActions.fetchingSingleDocument())
        const result = await documentsApi.getDocument(documentId)

        if (isResultSuccess(result)) {
            dispatch(documentsActions.setSelectedDocument(result.result))
        } else {
            dispatch(documentsActions.fetchingSingleDocumentFailure({ error: result.error }))
        }
    }, [documentId, documentsApi, dispatch])

    useEffect(() => {
        fetchDocument()
    }, [fetchDocument])

    return useMemo(() => {
        const isDocumentLoaded = document?.id === documentId

        return {
            loading: isDocumentLoaded ? loading : !!documentId,
            error: isDocumentLoaded ? error : null,
            document: isDocumentLoaded ? document : null,
            refetch: fetchDocument,
        }
    }, [document, loading, error, documentId, fetchDocument])
}
