import { useCallback, useState } from "react"
import { useAppDispatch } from "~/store/hooks"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"
import { CreatePartnerProfileI } from "~/domains/partners/types"
import { partnerApi } from "~/api"
import { commonMessages } from "~/common-messages"
import { bookOfRelationsActions } from "../bookOfRelationsSlice"

export const useCreatePartnerProfile = () => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const createPartnerProfile = useCallback(async (partnerProfile: CreatePartnerProfileI) => {
        try {
            setLoading(true)

            const profile = await partnerApi.createPartnerProfile(partnerProfile)

            dispatch(bookOfRelationsActions.setPartnerProfile(profile))
            setLoading(false)
        } catch (e) {
            setError(`${e}`)
            setLoading(false)
            toast.error(formatMessage(commonMessages.errorContact))
        }
    }, [])

    return {
        createPartnerProfile,
        error,
        loading,
    }
}
