import { useCallback } from "react"

import { useDuplicateTags } from "~/domains/tags/hooks/useDuplicateTags"
import { tagsActions } from "~/domains/tags/store/tagsSlice"
import { TagObjectType } from "~/domains/tags/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { DocumentI, OrganizationId } from "~/types"
import { isResultSuccess } from "~/types/Result"

import { useDocumentRelationsApi } from "../documentRelationsApi"
import { documentRelationsActions } from "../store/documentRelationsSlice"

export const useAddRelationToPurchaseOrder = (organizationId: OrganizationId, purchaseOrderId: string) => {
    const documentRelations = useDocumentRelationsApi()
    const dispatch = useAppDispatch()
    const { duplicateTags } = useDuplicateTags()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    return useCallback(
        async (document: DocumentI) => {
            const result = await documentRelations.addRelationToPurchaseOrder(organizationId, purchaseOrderId, {
                invoiceId: document.invoiceId,
            })

            if (isResultSuccess(result)) {
                dispatch(
                    documentRelationsActions.addPurchaseOrderRelation({
                        relationId: result.result.relationId,
                        invoiceId: document.invoiceId,
                    })
                )
                // copy tags
                if (currentOrganizationId) {
                    const duplicationResult = await duplicateTags(
                        currentOrganizationId,
                        document.invoiceId,
                        purchaseOrderId,
                        TagObjectType.INVOICE
                    )
                    if (duplicationResult) {
                        dispatch(tagsActions.tagsDuplicationSuccess())
                    }
                }
            }
            return result
        },
        [organizationId, purchaseOrderId, dispatch]
    )
}
