import { Box, Stack, Tooltip, Typography } from "@mui/material"
import { CheckCircle, DollarSign, Edit } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, Card, Loader } from "~/components"
import { ActivePayment } from "~/domains/payment/payment-method-details/components"
import { XMLGenerator } from "~/domains/payment/payment/components/DocumentPayment"
import { useManagePayment } from "~/domains/payment/payment/hooks/useManagePayment"
import { PaymentStatus } from "~/domains/payment/payment/types"

const messages = defineMessages({
    updatePayment: {
        id: "payment.document.paymentDetails.updatePayment",
        defaultMessage: "Update payment",
    },
    paymentDetails: {
        id: "payment.document.paymentDetails",
        defaultMessage: "Payment details",
    },
    markAsPaid: {
        id: "payment.document.paymentDetails.markAsPaid",
        defaultMessage: "Mark as paid",
    },
    warningMessage: {
        id: "payment.document.paymentDetails.warningMessage",
        defaultMessage: "Notify your vendor that you have executed the payment",
    },
    paymentExecuted: {
        id: "payment.document.paymentDetails.paymentExecuted",
        defaultMessage: "You've set the invoice as paid",
    },
})

interface DocumentPaymentProps {
    documentPaid: boolean
    documentId: string
    onPaymentUpdate?: (documentId: string) => void
    onMarkAsPaid?: () => void
}

export const DocumentPayment = ({ documentPaid, documentId, onPaymentUpdate, onMarkAsPaid }: DocumentPaymentProps) => {
    const { formatMessage } = useIntl()
    const { payment, isLoading, isUpdatingPayment, editPayment } = useManagePayment({ documentId }, { skip: true })

    const handleUpdatePayment = () => {
        if (onPaymentUpdate) {
            onPaymentUpdate(documentId)
        }
    }

    const handleMarkAsPaid = async () => {
        await editPayment({
            status: PaymentStatus.EXECUTED,
            date_validated: new Date().toISOString(),
            date_scheduled: null,
            value_date_expected: null,
        })
        if (onMarkAsPaid) {
            onMarkAsPaid()
        }
    }

    const updatePaymentButton = (
        <Button size="x-small" type="title" onClick={handleUpdatePayment}>
            <Edit size={16} />
            {formatMessage(messages.updatePayment)}
        </Button>
    )

    if (isLoading) {
        return (
            <Card title={formatMessage(messages.paymentDetails)}>
                <Loader />
            </Card>
        )
    }

    if (!payment?.id) {
        return <Card>{updatePaymentButton}</Card>
    }

    return (
        <Card
            header={
                <Box component="div" display="flex" gap={5} alignItems="center">
                    <Typography variant="h4">{formatMessage(messages.paymentDetails)}</Typography>
                    {!documentPaid && updatePaymentButton}
                </Box>
            }
            expandable
        >
            <Stack spacing={2} flexDirection="column">
                <ActivePayment payment={payment} />
                {documentPaid ? (
                    <Box
                        component="div"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap={2}
                    >
                        <CheckCircle size={48} color="var(--color-green)" />
                        <Typography component="h2" textAlign="center" color="var(--color-light-grey)">
                            {formatMessage(messages.paymentExecuted)}
                        </Typography>
                    </Box>
                ) : (
                    <Stack spacing={2}>
                        {payment.destination_cash_id && <XMLGenerator paymentId={payment.id} />}
                        {payment.status !== PaymentStatus.EXECUTED && (
                            <Stack justifyContent="center" spacing={1}>
                                <Tooltip arrow placement="bottom" title={formatMessage(messages.warningMessage)}>
                                    <Box component="span" display="flex" justifyContent="center" alignItems="center">
                                        <Button type="primary" disabled={isUpdatingPayment} onClick={handleMarkAsPaid}>
                                            <DollarSign size={16} />
                                            {formatMessage(messages.markAsPaid)}
                                        </Button>
                                    </Box>
                                </Tooltip>
                            </Stack>
                        )}
                    </Stack>
                )}
            </Stack>
        </Card>
    )
}
