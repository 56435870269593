import { Box, IconButton, Tooltip, Typography, styled } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { Edit, Eye, EyeOff, Flag } from "react-feather"
import { useIntl } from "react-intl"
import { createEditor } from "slate"
import { Editable, Slate, withReact } from "slate-react"

import UserAvatar from "~/components/Avatar/UserAvatar"
import { DateComponent, toISOStringWithFixedOffset } from "~/components/Date"
import { renderElement } from "~/domains/communication/components/InputMessage/renderElement"
import { renderLeaf } from "~/domains/communication/components/InputMessage/renderLeaf"
import { MessageContextProvider } from "~/domains/communication/components/MessageContext"
import { useTogglePinMessage } from "~/domains/communication/hooks"
import { communicationActions } from "~/domains/communication/store/CommunicationSlice"
import { selectMessageToUpdate } from "~/domains/communication/store/selectors"
import { RoomId } from "~/domains/communication/types"
import { MessageWithUserI } from "~/domains/communication/types/Message"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { UserI } from "~/types"

interface MessageBlockProps {
    isCurrentUser: boolean
}

const MessageContainer = styled("div")({
    marginTop: "8px",
    marginBottom: "8px",
})

export const MessageBlock = styled("div")<MessageBlockProps>(({ isCurrentUser }) => ({
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    gap: "4px",
    flex: 1,
    ...(isCurrentUser && {
        flexDirection: "row-reverse",
        "& .user-avatar": {
            marginLeft: "8px",
            marginRight: "0px",
        },
    }),
}))

const MessageAvatarWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
})

const MessageEditButton = styled("div")<{ isActive: boolean }>(({ isActive }) => ({
    cursor: "pointer",
    "&:hover": {
        color: "var(--primary-color)",
    },
    ...(isActive && {
        color: "var(--primary-color)",
    }),
}))

export const MessageAvatarContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    flex: 1,
})

const MessageContentWrapper = styled("div", {
    shouldForwardProp: (prop) => prop !== "isActive" && prop !== "isCurrentUser",
})<MessageBlockProps & { isActive: boolean }>(({ isCurrentUser, isActive }) => ({
    backgroundColor: "var(--sidebar-color)",
    flex: 1,
    padding: "8px",
    position: "relative",
    border: `1px solid ${isActive ? "var(--primary-color)" : "rgba(0, 0, 0, 0.15)"}`,
    borderRadius: "4px",
    "&::before": {
        content: '""',
        position: "absolute",
        bottom: "0px",
        left: "-7px",
        borderRight: `7px solid ${isActive ? "var(--primary-color)" : "rgba(0, 0, 0, 0.25)"}`,
        borderTop: "5px solid transparent",
        borderBottom: "5px solid transparent",
        zIndex: 0,
    },
    "&::after": {
        content: '""',
        position: "absolute",
        bottom: "0px",
        left: "-6px",
        borderRight: "7px solid var(--color-white)",
        borderTop: "5px solid transparent",
        borderBottom: "5px solid transparent",
        zIndex: 1,
    },
    ...(isCurrentUser && {
        "&::before": {
            content: '""',
            position: "absolute",
            bottom: "0px",
            borderTop: "5px solid transparent",
            borderBottom: "5px solid transparent",
            zIndex: 1,
            right: "-7px",
            borderLeft: `7px solid ${isActive ? "var(--primary-color)" : "rgba(0, 0, 0, 0.25)"}`,
        },
        "&::after": {
            content: '""',
            position: "absolute",
            bottom: "0px",
            borderTop: "5px solid transparent",
            borderBottom: "5px solid transparent",
            zIndex: 1,
            right: "-6px",
            borderLeft: "7px solid var(--color-white)",
        },
    }),
}))

const MessageFooter = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "8px",
})

const MessageViews = styled("div")(({ theme }) => ({
    color: theme.palette.grey[600],
}))

const MessageDate = styled(Typography)({
    color: "var(--color-silver)",
    fontSize: "12px",
    textAlign: "right",
})

const MessageActions = styled("div")<MessageBlockProps>(({ isCurrentUser }) => ({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "8px",
    color: "#637381",
    ...(isCurrentUser && {
        justifyContent: "flex-start",
    }),
}))

interface Props {
    message: MessageWithUserI
    roomId: RoomId
    currentUser: UserI
    displayMessageViewers?: () => void
    withoutFooter?: boolean
}

export const MessageContent: React.FC<Props> = ({
    message,
    roomId,
    currentUser,
    displayMessageViewers,
    withoutFooter,
}) => {
    const [editor] = useState(() => withReact(createEditor()))
    const [resetKey, setResetKey] = useState(0)
    const { locale } = useIntl()
    const dispatch = useAppDispatch()
    const isCurrentUser = message.senderId === currentUser.id
    const isMessageViewed = message.views.some((view) => view.userId !== currentUser.id)
    const messageViewIcon = isMessageViewed ? <Eye size={12} /> : <EyeOff size={12} />
    const togglePinMessage = useTogglePinMessage(message, roomId)
    const messageToUpdate = useAppSelector(selectMessageToUpdate)
    const isMessageToUpdate = messageToUpdate?.id === message.id

    const handleUpdateMessage = useCallback(() => {
        dispatch(communicationActions.setMessageToUpdate(isMessageToUpdate ? null : message))
    }, [dispatch, message, isMessageToUpdate])

    useEffect(() => {
        setResetKey((prev) => prev + 1)
    }, [message])

    return (
        <MessageContextProvider message={message}>
            <MessageContainer>
                <MessageBlock isCurrentUser={isCurrentUser}>
                    <MessageAvatarWrapper>
                        {isCurrentUser && (
                            <MessageEditButton isActive={isMessageToUpdate} onClick={handleUpdateMessage}>
                                <Edit size={16} />
                            </MessageEditButton>
                        )}
                        <Tooltip title={message.user ? message.user.fullName : ""}>
                            <MessageAvatarContainer>
                                <UserAvatar email={message.user?.email} />
                            </MessageAvatarContainer>
                        </Tooltip>
                    </MessageAvatarWrapper>
                    <MessageContentWrapper isActive={isMessageToUpdate} isCurrentUser={isCurrentUser}>
                        <Slate key={resetKey} editor={editor} initialValue={message.content}>
                            <Editable renderElement={renderElement} renderLeaf={renderLeaf} readOnly />
                        </Slate>
                        <MessageFooter>
                            <MessageDate>
                                <DateComponent
                                    value={toISOStringWithFixedOffset(message.createdAt)}
                                    timeLocale={locale}
                                />
                            </MessageDate>
                            <MessageViews>
                                {displayMessageViewers ? (
                                    <IconButton size="small" onClick={displayMessageViewers}>
                                        {messageViewIcon}
                                    </IconButton>
                                ) : (
                                    messageViewIcon
                                )}
                            </MessageViews>
                        </MessageFooter>
                    </MessageContentWrapper>
                </MessageBlock>
                {!withoutFooter && (
                    <MessageActions isCurrentUser={isCurrentUser}>
                        <IconButton size="small" onClick={togglePinMessage}>
                            <Flag
                                size={14}
                                stroke={message.isPinned ? "var(--primary-color)" : "#637381"}
                                fill={message.isPinned ? "var(--primary-color)" : "#637381"}
                            />
                        </IconButton>
                    </MessageActions>
                )}
            </MessageContainer>
        </MessageContextProvider>
    )
}
