import { Avatar, Grid, Stack } from "@mui/material"
import { ChangeEvent, Fragment, KeyboardEvent, useCallback, useEffect, useState } from "react"
import { Command } from "react-feather"
import { defineMessage, useIntl } from "react-intl"
import { Navigate } from "react-router-dom"

import { Card, CenterViewport, HeaderH1, Loader } from "~/components"
import FormattedText from "~/components/FormattedText/FormattedText"
import "~/components/UploadDocument/UploadDocument/UploadDocument.scss"
import { copilotApi } from "~/domains/copilot/prompt/api"
import { CopilotCompletion } from "~/domains/copilot/prompt/components/CopilotCompletion"
import { CopilotPromptBar } from "~/domains/copilot/prompt/components/CopilotPromptBar"
import { Conversation, From, MessageResult } from "~/domains/copilot/prompt/types/Copilot"
import { HOME_ROUTE } from "~/routes/routes"
import { selectUser } from "~/store/account/accountSlice"
import { selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

import "./Prompt.scss"

const title = defineMessage({
    id: "domains.copilot.prompt.pages.Prompt.title",
    defaultMessage: "Flowie Copilot",
})

const FlowieCopilotIcon = () => (
    <Avatar sx={{ bgcolor: "transparent", border: "1px solid var(--color-light-grey)", width: 32, height: 32 }}>
        <Command size={16} color="var(--color-grey)" />
    </Avatar>
)

export function Prompt() {
    const { formatMessage } = useIntl()
    const displayMenu = useAppSelector(selectKeepSidebarOpened)
    const user = useAppSelector(selectUser)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const [message, setMessage] = useState("")
    const [documentContent, setDocumentContent] = useState("")
    const [completion, setCompletion] = useState<MessageResult["completion"]>()
    const [conversation, setConversation] = useState<Conversation[]>([])
    const [threadId, setThreadId] = useState("")
    const [threadLoading, setThreadLoading] = useState(true)
    const [messageLoading, setMessageLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight)
    }, [conversation])

    useEffect(() => {
        if (currentOrganizationId && user?.id && threadId === "") {
            copilotApi.createThread(currentOrganizationId, user.id).then((result) => {
                setThreadLoading(false)
                setThreadId(result.threadId)
            })
        }
    }, [currentOrganizationId, user, threadId])

    const handleChangeMessage = (e: ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value)
    }

    const handleSendMessage = async () => {
        if (!message) return

        setConversation((prev) => [...prev, { from: From.USER, message }])
        setMessage("")
        setMessageLoading(true)

        const result = await copilotApi.createMessage(threadId, user.id, `${message}\n\n${documentContent}`)
        setCompletion(result.completion)
        setConversation((prev) => [...prev, { from: From.COPILOT, message: result.message }])

        setMessageLoading(false)
        setDocumentContent("")
    }

    const handlePressEnter = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.code === "Enter") {
                event.stopPropagation()
                event.preventDefault()
                handleSendMessage()
                return false
            }
        },
        [handleSendMessage]
    )

    const handleUploadSuccess = (documentContent: string) => {
        setDocumentContent(documentContent)
    }

    if (currentOrganizationId && !isFeatureEnabled(Features.ChatBot, currentOrganizationId)) {
        return <Navigate to={HOME_ROUTE} />
    }

    return (
        <div className="copilot-page">
            <HeaderH1 title={formatMessage(title)} icon={<Command />} />
            <Grid container justifyContent="center">
                {threadLoading ? (
                    <CenterViewport>
                        <Loader />
                    </CenterViewport>
                ) : (
                    <>
                        <Stack direction="row" gap={6} className="conversation-container">
                            <Stack gap={4} alignItems="center" className="messages-container">
                                {conversation.map((message, key) => {
                                    return (
                                        <Fragment key={key}>
                                            {message.from === From.USER ? (
                                                <Stack
                                                    direction="row"
                                                    alignSelf="flex-end"
                                                    justifyContent="flex-end"
                                                    gap={2}
                                                    className="message-user"
                                                >
                                                    <Card sx={{ paddingBlock: 1 }}>
                                                        <p>{message.message}</p>
                                                    </Card>
                                                </Stack>
                                            ) : (
                                                <Stack direction="row" gap={2} className="message-flowie">
                                                    <FlowieCopilotIcon />
                                                    <Stack gap={2}>
                                                        {message.message.split("\n").map((message, key) => (
                                                            <p key={key}>
                                                                <FormattedText text={message} />
                                                            </p>
                                                        ))}
                                                    </Stack>
                                                </Stack>
                                            )}
                                        </Fragment>
                                    )
                                })}
                                {messageLoading ? (
                                    <Stack direction="row" alignItems="center" gap={2} className="message-flowie">
                                        <FlowieCopilotIcon />
                                        <Loader small />
                                    </Stack>
                                ) : null}
                            </Stack>
                            <CopilotCompletion completion={completion} />
                        </Stack>

                        <CopilotPromptBar
                            displayMenu={displayMenu}
                            documentContent={documentContent}
                            conversation={conversation}
                            message={message}
                            handleUploadSuccess={handleUploadSuccess}
                            handleChangeMessage={handleChangeMessage}
                            handlePressEnter={handlePressEnter}
                            handleSendMessage={handleSendMessage}
                        />
                    </>
                )}
            </Grid>
        </div>
    )
}
