import React from "react"
import { RenderElementProps, useSlateStatic } from "slate-react"

import { ActionPlanCheckForm } from "~/domains/communication/components/InputMessage/ActionPlan/ActionPlanCheckForm"
import { ActionPlanItemDueDate } from "~/domains/communication/components/InputMessage/ActionPlan/ActionPlanItemDueDate"
import { useContextMessage } from "~/domains/communication/components/MessageContext"
import { useUpdateActionPlanItem } from "~/domains/communication/hooks"
import { MessageActionPlanItem } from "~/domains/communication/types"

type Props = Omit<RenderElementProps, "element"> & { element: MessageActionPlanItem }

export const ActionPlanItemElement: React.FC<Props> = ({ attributes, element, children }) => {
    const message = useContextMessage()
    const { data } = element

    const editor = useSlateStatic()

    const updateActionPlanItem = useUpdateActionPlanItem(message?.id ?? null, data.id)

    const actionPlanCheckFormProps = message
        ? {
              updateActionPlanItem,
          }
        : { editor }

    return (
        <li {...attributes}>
            <ActionPlanCheckForm key={element.data.id} element={element} label={children} {...actionPlanCheckFormProps}>
                <ActionPlanItemDueDate key={element.data.id} element={element} {...actionPlanCheckFormProps} />
            </ActionPlanCheckForm>
        </li>
    )
}
