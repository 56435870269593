import { HandleProps, Position } from "@xyflow/react"
import type { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { Users } from "react-feather"

import { CreateSurveyNode as CreateSurveyNodeType } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

export const CreateSurveyNode: FC<NodeProps<NodeType<CreateSurveyNodeType>>> = ({ data, selected }) => {
    const handles: HandleProps[] = [
        {
            type: "target",
            isConnectableEnd: true,
            position: Position.Top,
        },
        {
            type: "source",
            position: Position.Bottom,
            isConnectableStart: true,
        },
    ]

    const formName = data.metadata?.additionalInformation || ""

    const error = !data.formId

    return (
        <Node
            type={data.type}
            name={data.name}
            information={formName}
            icon={Users}
            handles={handles}
            selected={selected}
            slug={data.slug}
            error={error}
        ></Node>
    )
}
