import { TextField, Typography } from "@mui/material"
import { ChangeEvent, useCallback, useEffect } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, Card, Modal, SafeFormattedMessage } from "~/components"
import TrimmedTextField from "~/components/Form/TrimmedTextField"
import { accountActions, selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectOcrData, selectOcrInvoiceId } from "~/store/ocr/ocrSlice"

const messages = defineMessages({
    modalTitle: {
        id: "login.modalFinalizeAccount.title",
        defaultMessage: "Complete your profile",
    },
    modalSave: {
        id: "login.modalFinalizeAccount.save",
        defaultMessage: "Save",
    },
    companyName: {
        id: "user.userInfo.companyName",
        defaultMessage: "Company Name",
    },
    name: {
        id: "user.userInfo.fullName",
        defaultMessage: "Full Name",
    },
    phone: {
        id: "user.userInfo.phone",
        defaultMessage: "Your Phone",
    },
    email: {
        id: "user.userInfo.email",
        defaultMessage: "Email",
    },
    position: {
        id: "user.userInfo.position",
        defaultMessage: "Your Position",
    },
})

interface Props {
    displayModal: boolean
}

export function ModalFinalizeAccount({ displayModal }: Props) {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const ocrData = useAppSelector(selectOcrData)
    const ocrInvoiceId = useAppSelector(selectOcrInvoiceId)

    useEffect(() => {
        if (ocrInvoiceId) {
            if (ocrData?.supplier?.contactName) {
                dispatch(
                    accountActions.updatePartialUser({
                        fullName: ocrData.supplier.contactName,
                    })
                )
            }
        }
    }, [dispatch, ocrInvoiceId, ocrData])

    const handleClose = useCallback(
        (_event: Event, reason: string) => {
            if (reason === "escapeKeyDown" || reason === "backdropClick") {
                return false
            }
            dispatch(accountActions.displayModal())
        },
        [dispatch]
    )

    const onNameChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            dispatch(
                accountActions.updatePartialUser({
                    fullName: event.currentTarget.value,
                })
            )
        },
        [dispatch]
    )

    const onPhoneNumberChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            dispatch(
                accountActions.updatePartialUser({
                    phoneNumber: event.currentTarget.value,
                })
            )
        },
        [dispatch]
    )

    const handleSaveProfile = useCallback(() => {
        dispatch(accountActions.updateUser())
        if (window.location.href.search(/supplier/) || window.location.href.search(/home/)) {
            dispatch(accountActions.setHasSignedUp(true))
        }
    }, [dispatch])

    return (
        <Modal
            open={displayModal}
            onClose={handleClose}
            aria-labelledby="modal-login"
            className="modal-login"
            disableEscapeKeyDown={true}
        >
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    <SafeFormattedMessage {...messages.modalTitle} />
                </Typography>
            </Modal.Header>
            <Modal.Content>
                <Card>
                    <TrimmedTextField
                        required
                        id="email"
                        name="email"
                        type="email"
                        className="bg-grey mb-12"
                        label={formatMessage(messages.email)}
                        placeholder={formatMessage(messages.email)}
                        value={user?.email ?? ""}
                        inputProps={{
                            readOnly: true,
                        }}
                        aria-readonly={true}
                        fullWidth
                    />
                    <TextField
                        required
                        id="fullName"
                        name="fullName"
                        className="bg-grey mb-12"
                        label={formatMessage(messages.name)}
                        placeholder={formatMessage(messages.name)}
                        onChange={onNameChange}
                        value={user?.fullName ?? ""}
                        fullWidth
                    />
                    <TextField
                        id="phoneNumber"
                        name="phoneNumber"
                        className="bg-grey"
                        label={formatMessage(messages.phone)}
                        placeholder={formatMessage(messages.phone)}
                        onChange={onPhoneNumberChange}
                        value={user?.phoneNumber ?? ""}
                        fullWidth
                        inputProps={{
                            type: "tel",
                        }}
                    />
                </Card>
            </Modal.Content>
            <Modal.Footer>
                <Button buttonType="submit" onClick={handleSaveProfile}>
                    <SafeFormattedMessage {...messages.modalSave} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
