import { FC, useCallback, useState } from "react"

import { Extract } from "~/domains/transactions/invoices/_views/supplier/components"
import { useSaveBuffer } from "~/hooks/useSaveBuffer"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { usePatchDraftInvoice } from "~/store/invoice/hooks/usePatchDraftInvoice"
import { InvoiceI, InvoiceUserType } from "~/types"
import { DocumentWithReference } from "~/types"

interface InvoiceCommonFieldsEditProps {
    invoice: InvoiceI
    dataLoaded: boolean
    updateData: (data: Partial<InvoiceI>) => void
    disabled?: boolean
    updateTotal?: (amount: number) => void
    updateTotalExcludedTaxes?: (amount: number) => void
    updateDueDate?: (date: string) => void
}

export const InvoiceCommonFieldsEdit: FC<InvoiceCommonFieldsEditProps> = ({
    invoice,
    dataLoaded,
    disabled,
    updateData,
    updateTotal,
    updateTotalExcludedTaxes,
    updateDueDate,
}) => {
    const user = useAppSelector(selectUser)
    const saveAsDraftAction = usePatchDraftInvoice(invoice, InvoiceUserType.BUYER, user)
    const [commonFields, setCommonFields] = useState<InvoiceI>(invoice)
    const [updatedField, setUpdatedField] = useState<Partial<InvoiceI>>({})

    const save = useCallback(() => {
        updateData(updatedField)
        saveAsDraftAction(false, updatedField)
    }, [updatedField])

    const { setHasChanges } = useSaveBuffer(save)

    const handleChange = useCallback(
        (data: Partial<InvoiceI | DocumentWithReference>) => {
            setUpdatedField(data)
            setCommonFields((prev) => ({ ...prev, ...data }) as InvoiceI)
            setHasChanges(true)
        },
        [setHasChanges]
    )

    return (
        commonFields && (
            <Extract
                dataLoaded={dataLoaded}
                ocrData={commonFields}
                updateData={handleChange}
                disabled={disabled}
                updateTotal={updateTotal}
                updateTotalExcludedTaxes={updateTotalExcludedTaxes}
                updateDueDate={updateDueDate}
            />
        )
    )
}
