import React, { useEffect } from "react"
import { IntlProvider, MessageFormatElement } from "react-intl"

import { SupportedLanguages, globalActions, selectLang } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

interface LocalesProviderProps extends React.PropsWithChildren {
    messages: Record<string, Record<string, string> | Record<string, MessageFormatElement[]> | undefined>
    locale: string
}

const langStorage = localStorage?.getItem("lang") as SupportedLanguages

export const LocalesProvider = ({ messages, locale, children }: LocalesProviderProps) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (langStorage) {
            dispatch(globalActions.setLang(langStorage))
        } else if (locale) {
            dispatch(globalActions.setLang(locale as SupportedLanguages))
        }
    }, [locale, dispatch])

    const lang = useAppSelector(selectLang)

    return (
        <IntlProvider messages={messages[lang]} locale={lang} defaultLocale={locale}>
            {children}
        </IntlProvider>
    )
}
