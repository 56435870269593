import { Square } from "react-feather"

import { ApprovePurchaseRequestLineConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { ApprovePurchaseRequestLineNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    ApprovePurchaseRequestLineNode as ApprovePurchaseRequestLineNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const approvePurchaseRequestLineNode: Omit<ApprovePurchaseRequestLineNodeType, keyof Node> = {
    purchaseRequestLineId: `{{ trigger_event.id }}`,
    purchaseRequestId: `{{ trigger_event.purchaseRequestId }}`,
    nextNode: null,
    type: NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE,
}

const advancedFields: (keyof typeof approvePurchaseRequestLineNode)[] = [
    AllAdvancedFieldsUnion.PURCHASE_REQUEST_ID,
    AllAdvancedFieldsUnion.PURCHASE_REQUEST_LINE_ID,
]

const approvePurchaseRequestLineConfig: Configuration<NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE> = {
    type: NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE,
    baseSlug: "approve-purchase-request-line",
    Icon: Square,
    Configuration: ApprovePurchaseRequestLineConfiguration,
    advancedFields,
    Node: ApprovePurchaseRequestLineNode,
    factory: (props: FactoryProps): ApprovePurchaseRequestLineNodeType => ({
        ...approvePurchaseRequestLineNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { approvePurchaseRequestLineConfig, advancedFields as approvePurchaseRequestLineAdvancedFields }
