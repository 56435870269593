import "dayjs/locale/fr"
import React, { useEffect } from "react"
import { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { budgetApi } from "~/api/budgetApi"
import { commonMessages } from "~/common-messages"
import { AutocompleteWithTags, Button, Loader, Modal } from "~/components"
import { transactionTypeMessages } from "~/features/budget/core/messages"
import { AddTransactionPayloadI, BudgetDataI, BudgetDataWithMetricsI } from "~/features/budget/types"
import { InvoiceI, OrganizationId, ReceivedDocumentI } from "~/types"

import { PurchaseOrders } from "../../purchase-orders/types/PurchaseOrders"
import { PurchaseRequestDetails } from "../../purchase-requests/types/PurchaseRequests"

const messages = defineMessages({
    title: { id: "transactions.modal.addToBudget.title", defaultMessage: "Add {transactionType} to a budget" },
    none: { id: "transactions.modal.addToBudget.none", defaultMessage: "None" },
    selectBudget: { id: "transactions.modal.addToBudget.selectBudget", defaultMessage: "Select a budget" },
    changesSaved: { id: "transactions.modal.addToBudget.changesSaved", defaultMessage: "Changes has been saved." },
    noChanges: {
        id: "transactions.modal.addToBudget.noChanges",
        defaultMessage: "There is no changes. Please select a new budget.",
    },
})

interface Props {
    organizationId: OrganizationId
    transaction: ReceivedDocumentI | PurchaseRequestDetails | PurchaseOrders | InvoiceI
    budgets: BudgetDataI[]
    transactionPayload: AddTransactionPayloadI
    open: boolean
    close: () => void
    handleSuccess: () => void
}

export const ModalAddToBudget: React.FC<Props> = ({
    organizationId,
    budgets,
    open,
    close,
    transaction,
    transactionPayload,
    handleSuccess,
}) => {
    const { formatMessage } = useIntl()

    const [loading, setLoading] = useState<boolean>(false)
    const [selectedBudgets, setSelectedBudgets] = useState<BudgetDataI[]>([])
    const [selectedOriginalBudgets, setSelectedOriginalBudgets] = useState<BudgetDataI[]>([])

    useEffect(() => {
        if (transaction.budgets) {
            setSelectedBudgets(transaction.budgets.map((b: BudgetDataWithMetricsI) => b.budget))
            setSelectedOriginalBudgets(transaction.budgets.map((b) => b.budget))
        }
    }, [transaction, setSelectedBudgets, setSelectedOriginalBudgets])

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const newBudgets = selectedBudgets.filter((b) => {
                return !selectedOriginalBudgets.find((budget) => budget.id === b.id)
            })
            const removedBudgets = selectedOriginalBudgets.filter((budget) => {
                return !selectedBudgets.find((b) => budget.id === b.id)
            })
            if (newBudgets.length || removedBudgets.length) {
                setLoading(true)
                try {
                    if (newBudgets.length) {
                        await Promise.all(
                            newBudgets.map((budget) => {
                                return budgetApi.addBudgetTransaction(organizationId, budget.id, transactionPayload)
                            })
                        )
                    }
                    if (removedBudgets.length) {
                        await Promise.all(
                            removedBudgets.map((budget) => {
                                const budgetWithMetrics = transaction.budgets?.find((b) => b.budget.id === budget.id)
                                return budgetApi.deleteBudgetTransaction(
                                    organizationId,
                                    budgetWithMetrics?.transaction.id as string
                                )
                            })
                        )
                    }
                    toast.success(formatMessage(messages.changesSaved))
                    close()
                    handleSuccess()
                } catch (error) {
                    toast.error(formatMessage(messages.noChanges))
                } finally {
                    setLoading(false)
                }
            } else {
                toast.error(formatMessage(messages.noChanges))
            }
        },
        [selectedOriginalBudgets, selectedBudgets, organizationId, transactionPayload]
    )

    return (
        <Modal open={open} onClose={() => close()}>
            <Modal.Header>
                <h4>
                    {formatMessage(messages.title, {
                        transactionType: formatMessage(transactionTypeMessages[transactionPayload.transactionType]),
                    })}
                </h4>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Content>
                    {loading ? (
                        <Loader />
                    ) : (
                        <AutocompleteWithTags
                            options={budgets}
                            selectedEntities={selectedBudgets}
                            setSelectedEntities={setSelectedBudgets}
                            numberOfTagsToShow={3}
                            getOptionLabel={(budget) => budget.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            placeholder={formatMessage(messages.selectBudget)}
                            classname="contacts-role-name mb-12"
                        />
                    )}
                </Modal.Content>
                <Modal.Footer>
                    <Button type="transparent" onClick={() => close()}>
                        {formatMessage(commonMessages.cancel)}
                    </Button>
                    <Button type="primary" buttonType="submit" disabled={loading}>
                        {formatMessage(commonMessages.save)}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
