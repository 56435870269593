import { Box, Grid, Stack } from "@mui/material"
import { FC } from "react"
import { defineMessages, useIntl } from "react-intl"

import {
    ItemLabel,
    Loader,
    ProgressBar,
    ProgressBarItem,
    ProgressBarItemDisplay,
    ProgressBarLegend,
} from "~/components"
import { TransactionInBudgetGaugeWithDetails } from "~/domains/transactions/components/TransactionInBudgets/TransactionInBudgetGaugeWithDetails"
import { BudgetDataWithMetricsI } from "~/features/budget/types"
import { useFetchBudgetDetails } from "~/store/budget/hooks"
import { CurrencyCodes } from "~/types"

const messages = defineMessages({
    noBudgetForCurrency: {
        id: "transactions.transactionInBudget.noBudgetForCurrency",
        defaultMessage: "No budget for currency: {currency}",
    },
    budgetLabel: {
        id: "transactions.transactionInBudget.budgetLabel",
        defaultMessage: "Budget",
    },
    budgetTotal: {
        id: "transactions.transactionInBudget.budgetTotal",
        defaultMessage: "Budget total",
    },
})

interface Props {
    budget: BudgetDataWithMetricsI | undefined
    currency: CurrencyCodes
    totalAmountsFormatted: string
    notRejectedTransactionItems: ProgressBarItem[]
    transactionItems: ProgressBarItem[]
    showDetails?: boolean
}

export const TransactionBudgetGaugeByCurrency: FC<Props> = ({
    totalAmountsFormatted,
    budget,
    currency,
    notRejectedTransactionItems,
    transactionItems,
    showDetails = true,
}) => {
    const { formatMessage, formatNumber } = useIntl()

    const { budgetDetails, loading } = useFetchBudgetDetails(budget?.budget.organizationId, budget?.budget.id)

    const budgetAmountFormatted = formatNumber(budget?.budget?.currentAmount ?? 0, {
        style: "currency",
        currency: budget?.budget?.currency ?? "",
        currencyDisplay: "narrowSymbol",
    })
    if (!budgetDetails || !showDetails) {
        const progressBarValues = notRejectedTransactionItems.map(
            (v): ProgressBarItemDisplay => ({
                ...v,
                value: formatNumber(v.value, {
                    style: "currency",
                    currency: currency,
                    currencyDisplay: "narrowSymbol",
                }),
            })
        )
        const legendValues = transactionItems.map(
            (currencyValue): ProgressBarItemDisplay => ({
                ...currencyValue,
                value: formatNumber(currencyValue.value, {
                    style: "currency",
                    currency: currency,
                    currencyDisplay: "narrowSymbol",
                }),
            })
        )
        return (
            <>
                <hr className="m-0" />
                {!budgetAmountFormatted ? (
                    <ItemLabel>{formatMessage(messages.noBudgetForCurrency, { currency })}</ItemLabel>
                ) : (
                    <Stack key={currency} gap={1}>
                        <Grid container justifyContent="space-between" gap={3}>
                            <Box>
                                <ItemLabel>{formatMessage(messages.budgetLabel)}</ItemLabel>
                                <h4>{totalAmountsFormatted[currency] || "0"}</h4>
                            </Box>
                            <Box textAlign="right">
                                <ItemLabel>{formatMessage(messages.budgetTotal)}</ItemLabel>
                                <h4>{budgetAmountFormatted || "0"}</h4>
                            </Box>
                        </Grid>
                        {loading && <Loader small />}
                        <ProgressBar values={progressBarValues} />
                        <ProgressBarLegend values={legendValues} />
                    </Stack>
                )}
            </>
        )
    }

    return (
        <TransactionInBudgetGaugeWithDetails
            budgetDetails={budgetDetails}
            transactionItems={notRejectedTransactionItems}
        />
    )
}
