import { isRejectedWithValue } from "@reduxjs/toolkit"
import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"
import { createIntl } from "react-intl"
import { toast } from "react-toastify"

import { messages } from "~/i18n/messages"

export const errorLoggerMiddleware: Middleware = (middlewareApi: MiddlewareAPI) => (next) => (action) => {
    const locale = middlewareApi.getState()?.global?.lang || "en"
    const intl = createIntl({ locale, messages: messages[locale] })

    if (isRejectedWithValue(action)) {
        if (action.payload?.meta?.errorMessage) {
            toast.error(intl.formatMessage(action.payload.meta.errorMessage))
        }

        if (action.payload?.meta?.catchSentryException) {
            Sentry.captureException(action.type, action.payload)
        }
    }

    return next(action)
}
