import React, { lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "../../../auth/ProtectedRoute"
import { WORKFLOW, WORKFLOWS_LIST } from "./routes"

const Workflow = lazy(() =>
    import("~/domains/orchestration/flows-v0/pages/Workflow").then(({ Workflow }) => ({ default: Workflow }))
)
const WorkflowsListView = lazy(() =>
    import("./pages/WorkflowsListView").then(({ WorkflowsListView }) => ({ default: WorkflowsListView }))
)

export default {
    routes: [
        <Route key="protected_workflow_route" element={<ProtectedRoutes />}>
            <Route path={WORKFLOWS_LIST} element={<WorkflowsListView />} />
            <Route path={WORKFLOW} element={<Workflow />} />
        </Route>,
    ],
}
