import * as Sentry from "@sentry/browser"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import {
    useCreatePaymentTermObjectMutation,
    useDeletePaymentTermObjectMutation,
    useGetPaymentTermObjectsQuery,
    useGetPaymentTermsByObjectQuery,
    useGetPaymentTermsQuery,
    useUpdatePaymentTermObjectMutation,
} from "~/domains/payment/payment-terms/api/paymentTermsApi"
import { ObjectType } from "~/domains/payment/types"

const messages = defineMessages({
    errorMessage: {
        id: "payment.paymentTerms.errorMessage",
        defaultMessage: "An error occurred while processing your request",
    },
})

interface ManagePaymentTerms {
    objectId?: string
    objectType?: ObjectType
}

interface ManagePaymentTermsOptions {
    showList?: boolean
}

export const useManagePaymentTerms = (
    { objectId, objectType }: ManagePaymentTerms,
    { showList = true }: ManagePaymentTermsOptions
) => {
    const { formatMessage } = useIntl()
    const [createPaymentTermObject, { isLoading: isLoadingCreatePaymentTermObject }] =
        useCreatePaymentTermObjectMutation()
    const [updatePaymentTermObject, { isLoading: isLoadingUpdatePaymentTermObject }] =
        useUpdatePaymentTermObjectMutation()
    const [deletePaymentTermObject, { isLoading: isLoadingDeletePaymentTermObject }] =
        useDeletePaymentTermObjectMutation()
    const { data: objectPaymentTermsData, isLoading: isLoadingOrgPaymentTerms } = useGetPaymentTermsByObjectQuery(
        objectId ?? "",
        {
            skip: !objectId,
            refetchOnMountOrArgChange: true,
        }
    )
    const objectPaymentTerms = objectPaymentTermsData?.items ?? []
    const objectPaymentTerm = objectPaymentTerms[0] ?? null

    const { data: paymentTermsData, isLoading: isLoadingPaymentTerms } = useGetPaymentTermsQuery(null, {
        skip: !showList,
    })
    const paymentTerms = paymentTermsData?.items ?? []

    const { data: paymentTermsObjectsData, isLoading: isLoadingPaymentTermsObject } = useGetPaymentTermObjectsQuery(
        { object_id: objectId },
        { refetchOnMountOrArgChange: true, skip: !showList }
    )
    const paymentTermsObjects =
        paymentTermsObjectsData?.items?.filter(({ object_type }) => !object_type || object_type === objectType) ?? []
    const defaultPaymentTermObject = paymentTermsObjects.find((item) => item.is_default) ?? null

    const loading =
        isLoadingPaymentTerms ||
        isLoadingOrgPaymentTerms ||
        isLoadingCreatePaymentTermObject ||
        isLoadingUpdatePaymentTermObject ||
        isLoadingDeletePaymentTermObject ||
        isLoadingPaymentTermsObject

    const deleteObject = async (paymentTermsObjectId: string) => {
        try {
            await deletePaymentTermObject(paymentTermsObjectId).unwrap()
        } catch (error) {
            toast.error(formatMessage(messages.errorMessage))
            Sentry.captureException(error, {
                extra: {
                    objectId,
                },
            })
        }
    }

    return {
        loading,
        objectPaymentTerm,
        paymentTerms,
        defaultPaymentTermObject,
        createPaymentTermObject,
        updatePaymentTermObject,
        deleteObject,
    }
}
