import * as Sentry from "@sentry/browser"
import { AxiosInstance } from "axios"
import { useContext } from "react"

import { OrganizationId } from "~/types"
import {
    Result,
    ResultError,
    ResultSuccess,
    getResultErrorValue,
    getResultSuccessValue,
    isResultError,
    isResultSuccess,
} from "~/types/Result"
import { ParsingErrorType, genericParser } from "~/utils"
import { ApiContext } from "~/utils/apiClient"

import {
    ApiWorkflowCreateResponseI,
    ApiWorkflowCreateResponseIO,
    ApiWorkflowI,
    ApiWorkflowId,
    InvoiceWorkflowCheckI,
    PurchaseRequestWorkflowCheckI,
    WorkflowCreatePayload,
} from "../types/ApiWorkflow"
import {
    parseInvoiceWorkflowCheck,
    parsePurchaseRequestApiWorkflow,
    parsePurchaseRequestWorkflowCheck,
} from "../types/parseApiWorkflow"

const BASE_URL = import.meta.env.VITE_API_WORKFLOWS_URL

export enum FetchErrorType {
    HTTP_REQUEST_ERROR = "HTTP_REQUEST_ERROR",
    PARSING_ERROR = "PARSING_ERROR",
}

type FetchError<T> =
    | {
          type: FetchErrorType.HTTP_REQUEST_ERROR
          code: number
          error: string
      }
    | {
          type: FetchErrorType.PARSING_ERROR
          partialResults: T[]
          errors: ParsingErrorType[]
      }

class WorkflowsApi {
    private static instance: WorkflowsApi
    private constructor(private axiosClient: AxiosInstance) {}

    static getInstance(axiosClient: AxiosInstance) {
        if (!WorkflowsApi.instance) {
            WorkflowsApi.instance = new WorkflowsApi(axiosClient)
        }
        return WorkflowsApi.instance
    }

    private buildUrl(organizationId: OrganizationId, path: string, version = "v1") {
        return `${BASE_URL}${version}/organizations/${organizationId}${path}`
    }

    async getWorkflows(organizationId: OrganizationId): Promise<Result<ApiWorkflowI[], FetchError<ApiWorkflowI>>> {
        const url = this.buildUrl(organizationId, "/workflows")
        try {
            const response = await this.axiosClient.get<unknown>(url)
            if (!Array.isArray(response.data)) {
                const error: FetchError<ApiWorkflowI> = {
                    type: FetchErrorType.PARSING_ERROR,
                    partialResults: [],
                    errors: [
                        {
                            data: response.data,
                            errorsKey: [],
                        },
                    ],
                }
                Sentry.captureException(error, { extra: { url } })
                return ResultError(error)
            }
            const results = response.data.map(parsePurchaseRequestApiWorkflow)
            const successResult = results.filter(isResultSuccess)
            if (successResult.length !== results.length) {
                const errors = results.filter(isResultError)
                return ResultError({
                    type: FetchErrorType.PARSING_ERROR,
                    partialResults: successResult.map(getResultSuccessValue),
                    errors: errors.map(getResultErrorValue),
                })
            }
            return ResultSuccess(successResult.map(getResultSuccessValue))
        } catch (error) {
            Sentry.captureException(error, { extra: { url } })
            return ResultError({ type: FetchErrorType.HTTP_REQUEST_ERROR, code: 0, error: `${error}` })
        }
    }

    async createWorkflow(
        organizationId: OrganizationId,
        payload: WorkflowCreatePayload
    ): Promise<Result<ApiWorkflowCreateResponseI, any>> {
        const url = this.buildUrl(organizationId, "/workflows")
        try {
            const response = await this.axiosClient.post<unknown>(url, payload)
            const result = genericParser<ApiWorkflowCreateResponseI>(response.data, ApiWorkflowCreateResponseIO)
            return result
        } catch (error) {
            Sentry.captureException(error, {
                extra: { url, payload },
            })
            return ResultError({ error })
        }
    }
    async getWorkfrow(
        organizationId: OrganizationId,
        workflowId: ApiWorkflowId
    ): Promise<Result<ApiWorkflowI, ParsingErrorType>> {
        const url = this.buildUrl(organizationId, `/workflows/${workflowId}`)
        const response = await this.axiosClient.get(url)
        return parsePurchaseRequestApiWorkflow(response.data)
    }
    async updateWorkflow(
        organizationId: OrganizationId,
        workflowId: ApiWorkflowId,
        workflowPayload: ApiWorkflowI
    ): Promise<void> {
        const url = this.buildUrl(organizationId, `/workflows/${workflowId}`)
        return await this.axiosClient.put(url, workflowPayload)
    }
    async deleteWorkflow(organizationId: OrganizationId, workflowId: ApiWorkflowId): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/workflows/${workflowId}`)
        const response = await this.axiosClient.delete(url)
        return response.status >= 200 && response.status < 300
    }

    async enableWorkflow(organizationId: OrganizationId, workflowId: ApiWorkflowId): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/workflows/${workflowId}/enable`)
        const response = await this.axiosClient.post(url)
        return response.status >= 200 && response.status < 300
    }

    async disableWorkflow(organizationId: OrganizationId, workflowId: ApiWorkflowId): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/workflows/${workflowId}/disable`)
        const response = await this.axiosClient.post(url)
        return response.status >= 200 && response.status < 300
    }

    async approvePurchaseRequest(organizationId: OrganizationId, purchaseRequestId: string): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/purchase-requests/${purchaseRequestId}/approve`)
        const response = await this.axiosClient.post(url)
        return response.status >= 200 && response.status < 300
    }

    async refusePurchaseRequest(organizationId: OrganizationId, purchaseRequestId: string): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/purchase-requests/${purchaseRequestId}/refuse`)
        const response = await this.axiosClient.post(url)
        return response.status >= 200 && response.status < 300
    }

    async approvePurchaseRequestLine(
        organizationId: OrganizationId,
        purchaseRequestId: string,
        lineId: string
    ): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/purchase-requests/${purchaseRequestId}/lines/${lineId}/approve`)
        const response = await this.axiosClient.post(url)
        return response.status >= 200 && response.status < 300
    }

    async refusePurchaseRequestLine(
        organizationId: OrganizationId,
        purchaseRequestId: string,
        lineId: string
    ): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/purchase-requests/${purchaseRequestId}/lines/${lineId}/refuse`)
        const response = await this.axiosClient.post(url)
        return response.status >= 200 && response.status < 300
    }

    async getPurchaseRequestChecks(
        organizationId: OrganizationId,
        purchaseRequestId: string
    ): Promise<Result<PurchaseRequestWorkflowCheckI, ParsingErrorType | FetchError<PurchaseRequestWorkflowCheckI>>> {
        const url = this.buildUrl(organizationId, `/purchase-requests/${purchaseRequestId}/checks`, "v2")
        const response = await this.axiosClient.get(url)
        if (response.status >= 200 && response.status < 300) {
            return parsePurchaseRequestWorkflowCheck(response.data)
        } else {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: response.status,
                error: response.statusText,
            })
        }
    }

    async getInvoiceChecks(
        organizationId: OrganizationId,
        invoiceId: string
    ): Promise<Result<InvoiceWorkflowCheckI, ParsingErrorType | FetchError<InvoiceWorkflowCheckI>>> {
        const url = this.buildUrl(organizationId, `/invoices/${invoiceId}/checks`)
        const response = await this.axiosClient.get(url)
        if (response.status >= 200 && response.status < 300) {
            return parseInvoiceWorkflowCheck(response.data)
        } else {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: response.status,
                error: response.statusText,
            })
        }
    }

    async approveInvoice(organizationId: OrganizationId, invoiceId: string): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/invoices/${invoiceId}/approve`)
        const response = await this.axiosClient.post(url)
        return response.status >= 200 && response.status < 300
    }

    async refuseInvoice(organizationId: OrganizationId, invoiceId: string): Promise<boolean> {
        const url = this.buildUrl(organizationId, `/invoices/${invoiceId}/refuse`)
        const response = await this.axiosClient.post(url)
        return response.status >= 200 && response.status < 300
    }
}

export const useWorkflowsApi = () => {
    const { axiosClient } = useContext(ApiContext)
    return WorkflowsApi.getInstance(axiosClient)
}
