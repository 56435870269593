import { useEffect } from "react"

import { MessageViewFromApiAdapter } from "~/domains/communication/api/adapters/MessageViewFromApiAdapter"
import { useSocketIOContext } from "~/domains/transactions/common/subscriptions/components/SocketIOContext"
import { useAppDispatch } from "~/store/hooks"
import { UserI } from "~/types"
import { isResultSuccess } from "~/types/Result"

import { communicationActions } from "../store/CommunicationSlice"
import { MessageId } from "../types"

export const useSubscribeOnMessageUpdates = (user: UserI, messageId: MessageId) => {
    const { ioSocket } = useSocketIOContext()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (user && user.id) {
            ioSocket.on(messageId, (data) => {
                const result = MessageViewFromApiAdapter(data)
                if (isResultSuccess(result)) {
                    dispatch(communicationActions.addMessageView(result.result))
                }
            })
            ioSocket.emit(`subscribe/messages`, { messageId, user })
            return () => {
                ioSocket.emit(`unsubscribe/messages`, { messageId, user })
            }
        }
    }, [ioSocket, user, messageId, dispatch])
}
