import { useContext } from "react"

import { OcrSocketIOContext } from "~/domains/transactions/invoices/_views/ocr/components/OcrSocketIOContext/OcrSocketIOContextProvider"

export const useOcrSocketIOContext = () => {
    const { ioSocket, isConnected } = useContext(OcrSocketIOContext)
    if (!ioSocket) {
        throw new Error(`Cannot use useSocketIOContext outside SocketIOContext: ioSocket is null`)
    }
    return { ioSocket, isConnected }
}
