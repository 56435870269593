import { Grid, Stack } from "@mui/material"
import { Dayjs } from "dayjs"
import React, { useCallback, useMemo } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { CurrencySelector } from "~/components/CurrencySelector"
import { PurchaseViewType } from "~/domains/transactions/_shared/types"
import { Dates } from "~/domains/transactions/components/Dates/Dates"
import { RequesterName } from "~/domains/transactions/components/RequesterName/RequesterName"
import { purchaseRequestsActions } from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { useAppDispatch } from "~/store/hooks"
import { CurrencyI, OrganizationId } from "~/types"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

import { PRInfosVeoliaCustomFields } from "../PRInfosCustomFields/PRInfosVeoliaCustomFields"

const messages = defineMessages({
    title: {
        id: "supplier.extract.title",
        defaultMessage: "Informations",
    },
    currency: {
        id: "purchase.requests.modalNewPR.currency",
        defaultMessage: "Currency",
    },
})

interface Props {
    PR: PurchaseRequestDetails
    newPR: boolean | undefined
    updatePR: (showToast?: boolean) => Promise<PurchaseRequestDetails>
    pageMode: PurchaseViewType
    organizationId: OrganizationId
}

export const PurchaseRequestInfos: React.FC<Props> = ({ PR, newPR, updatePR, pageMode, organizationId }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()

    const hasVeoliaCustomFields = useMemo(
        () => isFeatureEnabled(Features.VeoliaCustomFields, organizationId),
        [organizationId]
    )

    const setDeliveryDate = useCallback(
        (date: Dayjs | null) => {
            if (date) {
                dispatch(
                    purchaseRequestsActions.updateData({
                        field: "expectedDeliveryDate",
                        value: date.set("hours", 18).toISOString(),
                    })
                )
                if (pageMode !== PurchaseViewType.EDIT) {
                    dispatch(purchaseRequestsActions.setShouldUpdatePR(true))
                }
            }
        },
        [dispatch, pageMode, updatePR]
    )

    const handleCurrencyChange = useCallback(
        (currency: CurrencyI | null) => {
            if (currency) {
                dispatch(
                    purchaseRequestsActions.updateData({
                        field: "currency",
                        value: currency.code,
                    })
                )
            }
        },
        [dispatch]
    )

    return (
        <>
            <Card title={formatMessage(messages.title)}>
                <Stack spacing={1}>
                    <Dates
                        creationDate={PR.creationDate}
                        expectedDeliveryDate={PR.expectedDeliveryDate}
                        pageMode={pageMode}
                        setDeliveryDate={setDeliveryDate}
                    />
                    <Grid container direction="column" gap={2}>
                        {!newPR && <RequesterName requesterName={PR.requesterName ?? ""} />}
                        {hasVeoliaCustomFields && (
                            <PRInfosVeoliaCustomFields purchaseRequestId={PR.id} pageMode={pageMode} />
                        )}
                    </Grid>
                </Stack>
            </Card>
            <Card>
                <CurrencySelector
                    value={PR.currency}
                    label={formatMessage(messages.currency)}
                    editMode={pageMode === PurchaseViewType.EDIT}
                    onChange={handleCurrencyChange}
                />
            </Card>
        </>
    )
}
