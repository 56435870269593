import { Tag } from "react-feather"

import { AssignTagConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { AssignTagNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEventTypeToObjectType } from "~/domains/orchestration/flows/core"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { AssignTagNode as AssignTagNodeType, Event, Node, NodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const assignTagNode = (eventTriggerEventType: Event | null): Omit<AssignTagNodeType, keyof Node> => ({
    objectId: `{{ trigger_event.id }}`,
    objectType: eventTriggerEventType ? adaptEventTypeToObjectType(eventTriggerEventType) : null,
    tagId: ``,
    nextNode: null,
    type: NodeType.ASSIGN_TAG_NODE,
})

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.OBJECT_ID, AllAdvancedFieldsUnion.OBJECT_TYPE]

const assignTagConfig: Configuration<NodeType.ASSIGN_TAG_NODE> = {
    type: NodeType.ASSIGN_TAG_NODE,
    baseSlug: "assign_tag",
    Icon: Tag,
    Configuration: AssignTagConfiguration,
    advancedFields,
    Node: AssignTagNode,
    factory: (props: FactoryProps): AssignTagNodeType => ({
        ...assignTagNode(props.eventTriggerEventType),
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { assignTagConfig, advancedFields as assignTagAdvancedFields }
