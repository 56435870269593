import { Stack } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Briefcase, Check, ChevronDown, ChevronUp, Clock, User, X } from "react-feather"
import { useDispatch } from "react-redux"

import { ItemLabel, Loader, ProgressBar } from "~/components"
import {
    ApprovalCheckI,
    ApprovalReviewerI,
    ApprovalReviewerType,
} from "~/domains/orchestration/flows-v0/types/Approval"
import { useAppSelector } from "~/store/hooks"
import { selectUsersLoading, usersActions } from "~/store/users/usersSlice"
import { OrganizationTeamI, UserI } from "~/types"

type Props = {
    check: ApprovalCheckI
    users: UserI[]
    teams: OrganizationTeamI[]
    isOpen?: boolean
}

export const ApprovalCheck = ({ check, users, teams, isOpen = false }: Props) => {
    const [open, setOpen] = useState(isOpen)

    const usersLoading = useAppSelector(selectUsersLoading)
    const dispatch = useDispatch()

    useEffect(() => {
        const userIds = check.reviewers.map((reviewer) => reviewer.userId || "")
        dispatch(usersActions.fetchUsers(userIds))
    }, [check, dispatch])

    const approvalProgress = useMemo(() => {
        return check.review.approvers.length / check.passThreshold
    }, [check.review.approvers.length, check.passThreshold])

    // Determine the color of the progress bar based on refusers
    const progressBarColor = check.review.refusers.length > 0 ? "var(--color-yellow)" : "var(--color-green)"

    // Helper function to get the review status icon
    const getReviewStatusIcon = useCallback(
        (userId) => {
            if (check.review.approvers.some((review) => review.userId === userId)) {
                return <Check data-testid="check-icon" color="var(--color-green)" size={14} />
            }
            if (check.review.refusers.some((review) => review.userId === userId)) {
                return <X data-testid="x-icon" color="var(--color-red)" size={14} />
            }
            return <Clock data-testid="clock-icon" color="var(--color-light-grey)" size={14} />
        },
        [check.review.approvers, check.review.refusers]
    )

    // Helper function to render a reviewer based on type
    const renderReviewer = (reviewer: ApprovalReviewerI) => {
        const isUser = reviewer.type === ApprovalReviewerType.USER
        const entity = isUser
            ? users.find((user) => user.id === reviewer.userId)
            : teams.find((team) => team.teamId === reviewer.teamId)

        if (!entity) return null

        const IconComponent = isUser ? User : Briefcase
        const name = "fullName" in entity ? entity.fullName : "name" in entity ? entity.name : ""
        const id = "id" in entity ? entity.id : "teamId" in entity ? entity.teamId : ""

        const statusIcon = isUser ? (
            getReviewStatusIcon(id)
        ) : (
            <Clock data-testid="clock-icon" color="var(--color-light-grey)" size={14} />
        )

        return (
            <Stack direction="row" alignItems="center" gap={1} key={id}>
                <IconComponent size={14} color="var(--color-light-grey)" />
                <ItemLabel className="m-0">{name}</ItemLabel>
                {statusIcon}
            </Stack>
        )
    }

    return (
        <>
            {usersLoading ? (
                <Loader small />
            ) : (
                <>
                    <ItemLabel>
                        {check.name} {check.review.approvers.length}/{check.passThreshold}
                    </ItemLabel>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <ProgressBar
                            values={[{ percentage: approvalProgress, label: check.name, color: progressBarColor }]}
                            className="w-full"
                        />
                        {open ? (
                            <ChevronUp
                                data-testid="chevron-up"
                                color="var(--color-light-grey)"
                                size={14}
                                onClick={() => setOpen(false)}
                            />
                        ) : (
                            <ChevronDown
                                data-testid="chevron-down"
                                color="var(--color-light-grey)"
                                size={14}
                                onClick={() => setOpen(true)}
                            />
                        )}
                    </Stack>
                    {open && <div>{check.reviewers.map((reviewer) => renderReviewer(reviewer))}</div>}
                </>
            )}
        </>
    )
}
