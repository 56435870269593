import { Stack, Typography, styled } from "@mui/material"
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from "@xyflow/react"
import React from "react"
import { X } from "react-feather"

import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { isBranchNode } from "~/domains/orchestration/flows/types/Flows"

const EdgeLabel = styled("div")<{ labelX: number; labelY: number }>(({ labelX, labelY }) => ({
    position: "absolute",
    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
    // everything inside EdgeLabelRenderer has no pointer events by default
    // if you have an interactive element, set pointer-events: all
    pointerEvents: "all",
}))

const Label = styled(Typography)({
    backgroundColor: "var(--color-white)",
    padding: "var(--spacing-xs)",
    borderRadius: "var(--border-radius-sm)",
})

export const ButtonEdgeWithLabel = ({
    id,
    target,
    source,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}: EdgeProps) => {
    const { setEdges } = useReactFlow()
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const state = useEditor()
    const currentFlow = state.flow
    const dispatch = useEditorDispatch()
    const node = currentFlow?.nodes.find((node) => node.slug === source)

    if (!node || !isBranchNode(node)) return null

    const edgeLabel = node.branches.find((branch) => branch.nextNode === target)?.name || ""

    const handleDelete = () => {
        const branchIndex = node.branches.findIndex((branch) => branch.nextNode === target)

        if (branchIndex === -1) return

        const newBranches = [...node.branches]
        newBranches[branchIndex] = {
            ...newBranches[branchIndex],
            nextNode: null,
        }

        dispatch({
            type: "UPDATE_NODE",
            payload: {
                ...node,
                branches: newBranches,
            },
        })

        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: true,
        })

        setEdges((edges) => edges.filter((edge) => edge.id !== id))
    }

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <EdgeLabel labelX={labelX} labelY={labelY} className="nodrag nopan">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                        <IconButton type="close" onClick={handleDelete}>
                            <X size={14} />
                        </IconButton>
                        <Label variant="body2">{edgeLabel}</Label>
                    </Stack>
                </EdgeLabel>
            </EdgeLabelRenderer>
        </>
    )
}
