import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { parseTagRecommandationsResult } from "~/domains/tags/types/TagRecommandation"
import { OrganizationId } from "~/types"

const BASE_URL = import.meta.env.VITE_API_TRANSACTION_V2_TAGS_URL

export const tagsApiV1 = createApi({
    reducerPath: "tagsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getTagsSuggestions: builder.query<any, { organizationId: OrganizationId; objectContext: unknown }>({
            query: ({ organizationId, objectContext }) => ({
                url: `/organizations/${organizationId}/objects/tags/auto`,
                method: "POST",
                body: objectContext,
            }),
            transformResponse: (response: unknown) => parseTagRecommandationsResult(response),
        }),
    }),
})

export const { useGetTagsSuggestionsQuery } = tagsApiV1
