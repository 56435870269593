import { useCallback, useState } from "react"

import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isResultError, isResultSuccess } from "~/types/Result"
import { FetchError } from "~/utils/apiClient/errors"

import { useDocumentsApi } from "../documentsApi"
import { documentsActions } from "../store/documentsSlice"
import { DocumentI, DocumentType, FolderId } from "../types"

export const useUploadDocument = (organizationId: OrganizationId, parentId: FolderId | null) => {
    const documentsApi = useDocumentsApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<FetchError<DocumentI> | null>(null)
    const dispatch = useAppDispatch()

    const uploadDocument = useCallback(
        async (file: File, documentType: DocumentType, objectId?: string) => {
            setLoading(true)
            setError(null)
            const result = await documentsApi.uploadDocument({
                organizationIds: [organizationId],
                file,
                name: file.name,
                documentType,
                folderId: parentId,
                ...(objectId && { objectId }),
            })
            setLoading(false)
            if (isResultError(result)) {
                setError(result.error)
            }
            if (isResultSuccess(result)) {
                dispatch(documentsActions.insertDocument({ organizationId, document: result.result }))
            }
            return result
        },
        [documentsApi, organizationId, parentId, dispatch]
    )

    return { uploadDocument, loading, error }
}
