import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId } from "~/api/prepareHeaders"
import {
    CheckPurchaseRequestPermissionsParams,
    PermissionId,
} from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { OrganizationId, SpiceDBPermissionsResult } from "~/types"

const BASE_URL = import.meta.env.VITE_API_ROLES_PERMISSIONS_URL

export const permissionsApi = createApi({
    reducerPath: "permissionsApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorizationAndOrganizationId }),
    endpoints: (builder) => ({
        getPermissions: builder.query<PermissionId[], OrganizationId>({
            query: (organizationId: OrganizationId) => ({
                url: `organizations/${organizationId}/permissions`,
            }),
        }),
        checkPurchaseRequestPermissions: builder.query<SpiceDBPermissionsResult, CheckPurchaseRequestPermissionsParams>(
            {
                query: ({ organizationId, authorizations }) => ({
                    url: `organizations/${organizationId}/permissions/purchase-requests`,
                    method: "POST",
                    body: { authorizations },
                }),
            }
        ),
    }),
})

export const { useGetPermissionsQuery, useCheckPurchaseRequestPermissionsQuery } = permissionsApi
