import { RotateCw } from "react-feather"

import { SetInvoiceLifecycleStatusConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { SetInvoiceLifecycleStatusNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    Node,
    NodeType,
    SetInvoiceLifecycleStatusNode as SetInvoiceLifecycleStatusNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const setInvoiceLifecycleStatusNode: Omit<SetInvoiceLifecycleStatusNodeType, keyof Node> = {
    invoiceId: `{{ trigger_event.id }}`,
    statusToSet: ``,
    nextNode: null,
    type: NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE,
}

const advancedFields: (keyof typeof setInvoiceLifecycleStatusNode)[] = [
    AllAdvancedFieldsUnion.INVOICE_ID,
    AllAdvancedFieldsUnion.STATUS_TO_SET,
]

const setInvoiceLifecycleStatusConfig: Configuration<NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE> = {
    type: NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE,
    baseSlug: "set-invoice-lifecycle-status",
    Icon: RotateCw,
    Configuration: SetInvoiceLifecycleStatusConfiguration,
    advancedFields,
    Node: SetInvoiceLifecycleStatusNode,
    factory: (props: FactoryProps): SetInvoiceLifecycleStatusNodeType => ({
        ...setInvoiceLifecycleStatusNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { setInvoiceLifecycleStatusConfig, advancedFields as setInvoiceLifecycleStatusAdvancedFields }
