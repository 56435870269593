import * as t from "io-ts"

import { MessageFromApiAdapter } from "~/domains/communication/api/adapters/MessageFromApiAdapter"
import { CommunicationChannelI } from "~/domains/communication/types/CommunicationChannel"
import { OrganizationId } from "~/types"
import { Result, ResultSuccess, getResultSuccessValue, isResultError, isResultSuccess } from "~/types/Result"
import { ParsingErrorType, genericParser } from "~/utils"

const CommunicationChannelsIO = t.type({
    partner_id: t.string,
    creation: t.string,
    last_update: t.string,
    list_message: t.array(t.unknown),
})

export const CommunicationChannelsFromApiAdapter = (data: unknown): Result<CommunicationChannelI, ParsingErrorType> => {
    const result = genericParser(data, CommunicationChannelsIO)
    if (isResultError(result)) return result
    const apiCommunicationChannel = result.result
    return ResultSuccess<CommunicationChannelI>({
        parterId: apiCommunicationChannel.partner_id as OrganizationId,
        createdAt: apiCommunicationChannel.creation,
        lastUpdateAt: apiCommunicationChannel.last_update,
        messages: apiCommunicationChannel.list_message
            .map(MessageFromApiAdapter)
            .filter(isResultSuccess)
            .map(getResultSuccessValue),
        actionPlans: [],
    })
}
