import { Stack, styled } from "@mui/material"
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from "react"
import { Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { BasePoint, BaseRange, Editor, Range, Transforms, createEditor } from "slate"
import { Editable, Slate, withReact } from "slate-react"
import slugify from "slugify"
import { v4 } from "uuid"

import { Button } from "~/components"
import { isElementActionPlan, withActionPlan } from "~/domains/communication/components/InputMessage/ActionPlan"
import { MessageTemplateSelector } from "~/domains/communication/components/InputMessage/MessageTemplateSelector"
import { selectMessageToUpdate } from "~/domains/communication/store/selectors"
import { RoomInvolvedOrganizationI } from "~/domains/communication/types"
import { MentionTypes, MessageContentType, MessageWithUserI } from "~/domains/communication/types/Message"
import { MessageTemplateI } from "~/domains/communication/types/MessageTemplate"
import { CustomElement } from "~/domains/communication/types/Slate"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationTeams, useFetchOrganizations } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { OrganizationI, OrganizationTeamI, UserI } from "~/types"

import {
    HoveringMentions,
    UserOrTeamWithOrganizationContext,
    UserWithOrganizationsContext,
    insertMention,
    isElementMention,
    withMentions,
} from "./Mentions"
import { renderElement } from "./renderElement"
import { renderLeaf } from "./renderLeaf"

const messages = defineMessages({
    placeholder: {
        id: "communication.newMessage.placeholder",
        defaultMessage: "type your message",
    },
})

const InputMessageContainer = styled("div")({
    display: "flex",
    gap: "8px",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: "8px 0 16px",
    "div[role=textbox]": {
        flex: 1,
        border: "1px solid var(--color-light-silver)",
        backgroundColor: "var(--sidebar-color)",
        padding: "8px 8px",
        minHeight: "32px",
        borderRadius: "4px",
        boxSize: "content-box",
        color: "var(--secondary-color)",
        "&:focus-visible": {
            outline: 0,
        },
    },
})

const StyledButton = styled(Button)({
    width: "34px",
    height: "34px",
    padding: "3px",
    flexBasis: "34px",
    margin: "1px 0",
})

const initialContent: CustomElement = {
    type: MessageContentType.Paragraph,
    children: [{ text: "" }],
}
const GLOBAL_INITIAL_VALUE: CustomElement[] = [initialContent]

const isEmpty = (value: CustomElement[]): boolean => {
    return (
        value.length === 1 &&
        (!value[0].children || (value[0].children.length === 1 && value[0].children[0].text === ""))
    )
}

const noFilter = () => true

const getUsersFilter = (filter: string) => {
    if (!filter || !filter.length) {
        return noFilter
    }
    const filterWords = slugify(filter.toLowerCase()).split("-")

    return (user: UserI) => {
        const userWords = slugify(
            `${user.fullName?.toLowerCase()} ${user.givenName?.toLowerCase()} ${user.familyName?.toLowerCase()} ${user.email.toLowerCase()}`
        ).split("-")
        return filterWords.every((filterWord) => userWords.some((userWord) => userWord.indexOf(filterWord) >= 0))
    }
}

const getTeamsFilter = (filter: string) => {
    if (!filter || !filter.length) {
        return noFilter
    }
    const filterWords = slugify(filter.toLowerCase()).split("-")

    return (team: OrganizationTeamI) => {
        const teamWords = slugify(`${team.name.toLowerCase()} ${team.description?.toLowerCase()}`).split("-")
        return filterWords.every((filterWord) => teamWords.some((teamWord) => teamWord.indexOf(filterWord) >= 0))
    }
}

const getBeforeMatch = (editor: Editor, start: BasePoint): BaseRange | undefined => {
    const wordBefore = Editor.before(editor, start, { unit: "word" })
    const before = wordBefore && Editor.before(editor, wordBefore)
    const beforeRange = before && Editor.range(editor, before, start)
    const beforeText = beforeRange && Editor.string(editor, beforeRange)
    if (!beforeText) {
        const characterBefore = Editor.before(editor, start, { unit: "character" })
        const beforeCharacter = characterBefore && Editor.before(editor, characterBefore)
        const beforeCharacterRange = beforeCharacter && Editor.range(editor, beforeCharacter, start)
        return beforeCharacterRange
    }
    return beforeRange
}

const getTarget = (editor: Editor, start: BasePoint): [BaseRange, string] | [null, null] => {
    const beforeRange = getBeforeMatch(editor, start)
    const beforeText = beforeRange && Editor.string(editor, beforeRange)
    const beforeMatch = beforeText && slugify(beforeText).match(/^@(\w+)$/)
    const after = Editor.after(editor, start)
    const afterRange = Editor.range(editor, start, after)
    const afterText = Editor.string(editor, afterRange)
    const afterMatch = afterText.match(/^(\s|$)/)

    if (beforeMatch && afterMatch) {
        return [beforeRange, beforeMatch[1]]
    }
    return [null, null]
}

const isMemberOfOrganization = (user: UserI, org: OrganizationI | undefined, taggedCompanyId: string | null): boolean =>
    !!org && (!taggedCompanyId || taggedCompanyId === org.id) && org.members.some((member) => member.userId === user.id)

export const isUserWithOrganizationsContext = (
    user: UserOrTeamWithOrganizationContext
): user is UserWithOrganizationsContext => {
    return "id" in user
}

export const isTeamWithOrganizationContext = (team: UserOrTeamWithOrganizationContext): team is OrganizationTeamI => {
    return "teamId" in team
}

interface Props {
    onSendMessage: (message: CustomElement[], messageToUpdate: MessageWithUserI | null) => void
    involvedOrganization: RoomInvolvedOrganizationI[]
}

export const InputMessage: React.FC<Props> = ({ onSendMessage, involvedOrganization }) => {
    const { formatMessage } = useIntl()
    const [editor, setEditor] = useState(() => withActionPlan(withMentions(withReact(createEditor()))))
    const [resetKey, setResetKey] = useState(0)
    const [target, setTarget] = useState<Range | null>(null)
    const [index, setIndex] = useState(0)
    const [search, setSearch] = useState("")
    const [taggedCompanyId, setTaggedCompanyId] = useState<string | null>(null)
    const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplateI | null>(null)

    const messageToUpdate = useAppSelector(selectMessageToUpdate)
    const initialValue = messageToUpdate?.content || GLOBAL_INITIAL_VALUE
    const [message, setMessage] = useState<CustomElement[]>(initialValue)

    useEffect(() => {
        setResetKey((prev) => prev + 1)
    }, [messageToUpdate])

    const onSelectTemplate = useCallback((template: MessageTemplateI | null) => {
        setSelectedTemplate(template)
        if (template) {
            Transforms.insertNodes(
                editor,
                template.content.map((p) =>
                    isElementActionPlan(p)
                        ? {
                              ...p,
                              children: p.children.map((child) => ({ ...child, data: { ...child.data, id: v4() } })),
                          }
                        : p
                ),
                { at: [0] }
            )
            setSelectedTemplate(null)
        }
    }, [])

    const organization = useAppSelector(selectCurrentOrganization)
    const organizationMembers = useMemo(
        () => organization?.members.map((member) => member.userId) ?? [],
        [organization]
    )

    const { teams } = useFetchOrganizationTeams(organization?.id ?? "", true)

    const otherOrganizationsIds = useMemo(
        () =>
            involvedOrganization
                .map((involvedOrganization) => involvedOrganization.organizationId)
                .filter((organizationId) => organizationId !== organization?.id),
        [involvedOrganization, organization]
    )

    const { organizations: otherOrganizations } = useFetchOrganizations(otherOrganizationsIds)
    const otherOrganizationsMembers = useMemo(
        () =>
            Array.from(
                new Set(
                    otherOrganizations.flatMap((organization) => organization.members).map((member) => member.userId)
                )
            ),
        [otherOrganizations]
    )
    const allInvolvedUsers = useMemo(
        () => [...organizationMembers, ...otherOrganizationsMembers],
        [organizationMembers, otherOrganizationsMembers]
    )
    const { users } = useGetAllUsersQuery(allInvolvedUsers)

    const filteredUsers = useMemo<UserWithOrganizationsContext[]>(() => {
        const deduplicatedUsers = Array.from(new Set(users))
        if (search.trim().length === 0 || !users || users.length === 0)
            return deduplicatedUsers as UserWithOrganizationsContext[]

        const userFilter = getUsersFilter(search)
        return users.reduce<UserWithOrganizationsContext[]>((acc, user) => {
            if (!userFilter(user)) {
                return acc
            }
            const memberOfOrganizations = new Set<OrganizationI>()
            if (organization && isMemberOfOrganization(user, organization, taggedCompanyId)) {
                memberOfOrganizations.add(organization)
            }
            otherOrganizations.forEach((otherOrganization) => {
                if (isMemberOfOrganization(user, otherOrganization, taggedCompanyId)) {
                    memberOfOrganizations.add(otherOrganization)
                }
            })
            acc.push({
                ...user,
                memberOfOrganizations: Array.from(memberOfOrganizations),
            })
            return acc
        }, [])
    }, [users, search, taggedCompanyId])

    const filteredTeams = useMemo<OrganizationTeamI[]>(() => {
        if (search.trim().length === 0 || !teams || teams.length === 0) {
            return teams as OrganizationTeamI[]
        }
        const teamFilter = getTeamsFilter(search)
        return teams.filter(teamFilter)
    }, [teams, search, organization])

    const filteredOptions = useMemo(() => [...filteredTeams, ...filteredUsers], [filteredTeams, filteredUsers])

    useEffect(() => {
        if (message.length > 0) {
            const mentions = message.map((m) => m.children.filter(isElementMention).length).filter((r) => r > 0)
            if (mentions.length === 0) {
                setTaggedCompanyId(null)
            }
        }
    }, [message])

    const handleSendMessage = useCallback(() => {
        onSendMessage(message, messageToUpdate)
        setMessage(GLOBAL_INITIAL_VALUE)
        setResetKey((prev) => prev + 1)
        setEditor(withActionPlan(withMentions(withReact(createEditor()))))
        setTaggedCompanyId(null)
    }, [editor, message, messageToUpdate])

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (target && filteredOptions && filteredOptions.length > 0) {
                if (event.key === "ArrowUp") {
                    event.preventDefault()
                    const prevIndex = index >= filteredOptions.length - 1 ? 0 : index + 1
                    setIndex(prevIndex)
                } else if (event.key === "ArrowDown") {
                    event.preventDefault()
                    const nextIndex = index <= 0 ? filteredOptions.length - 1 : index - 1
                    setIndex(nextIndex)
                } else if (event.key === "Tab" || event.key === "Enter") {
                    event.preventDefault()
                    Transforms.select(editor, target)
                    const option = filteredOptions[index]
                    const isUser = isUserWithOrganizationsContext(option)
                    if (isUser) {
                        const { id, fullName, memberOfOrganizations } = option
                        const organizationId = memberOfOrganizations[0]?.id
                        insertMention(editor, {
                            type: MentionTypes.USER,
                            value: id,
                            label: fullName ?? "",
                            organizationId: organizationId ?? "",
                        })
                        setTaggedCompanyId(organizationId ?? null)
                    } else {
                        const { teamId, name, organizationId } = option
                        insertMention(editor, {
                            type: MentionTypes.TEAM,
                            value: teamId,
                            label: name,
                            organizationId,
                        })
                        setTaggedCompanyId(organizationId)
                    }
                    setTarget(null)
                } else if (event.key === "Escape") {
                    setTarget(null)
                }
            } else if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault()
                handleSendMessage()
            }
        },
        [handleSendMessage, filteredOptions, target, index]
    )

    const onEditorChange = useCallback(
        (value: CustomElement[]) => {
            const isAstChange = editor.operations.some((op) => "set_selection" !== op.type)
            if (isAstChange) {
                setMessage(value)
                const { selection } = editor

                if (selection && Range.isCollapsed(selection)) {
                    const [start] = Range.edges(selection)

                    const [beforeRange, beforeMatch] = getTarget(editor, start)

                    if (beforeMatch) {
                        setTarget(beforeRange)
                        setSearch(beforeMatch)
                        setIndex(0)
                        return
                    }
                }

                setTarget(null)
            }
        },
        [editor, setMessage]
    )

    return (
        <Stack>
            {organization && (
                <MessageTemplateSelector
                    organizationId={organization.id}
                    value={selectedTemplate}
                    onChange={onSelectTemplate}
                />
            )}
            <InputMessageContainer>
                <Slate key={resetKey} editor={editor} initialValue={initialValue} onChange={onEditorChange}>
                    <HoveringMentions
                        options={filteredOptions}
                        editor={editor}
                        target={target}
                        setTarget={setTarget}
                        setTaggedCompanyId={setTaggedCompanyId}
                        highlightedOption={filteredOptions ? filteredOptions[index] : null}
                    />
                    <Editable
                        key={resetKey}
                        onKeyDown={onKeyDown}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder={formatMessage(messages.placeholder)}
                    />
                </Slate>
                <StyledButton onClick={handleSendMessage} disabled={isEmpty(message)}>
                    <Send size={16} />
                </StyledButton>
            </InputMessageContainer>
        </Stack>
    )
}
