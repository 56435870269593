import { FormLabel, MenuItem, Stack, Switch, TextField } from "@mui/material"
import cls from "classnames"
import { ChangeEvent, FC, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { COLLABORATION_STATUSES, PARTNERSHIP_FIELD_TO_UPDATE } from "~/domains/orchestration/flows/const"
import { adaptExpressionToText, adaptTextToExpression } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/messages"
import {
    ConfigurationProps,
    PartnershipFieldToUpdate,
    SetPartnershipFieldNode,
} from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const SetPartnershipConfiguration: FC<ConfigurationProps<SetPartnershipFieldNode>> = ({
    selectedNode,
    advancedFields,
}) => {
    const { formatMessage } = useIntl()

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleChangeFieldToUpdate = (e: ChangeEvent<HTMLInputElement>) => {
        const fieldToUpdate = e.target.value as PartnershipFieldToUpdate
        const valueToSetReset = fieldToUpdate === PartnershipFieldToUpdate.COLLABORATION_STATUS ? "" : "false"

        setCurrentNode({ ...currentNode, fieldToUpdate, valueToSet: valueToSetReset })
    }

    const handleChangeValueToUpdate = (e: ChangeEvent<HTMLInputElement>) => {
        const valueToSet = adaptTextToExpression(e.target.value)

        setCurrentNode({ ...currentNode, valueToSet })
    }

    const handleChangeValueToSet = () => {
        const valueToSet = `${currentNode.valueToSet === "false"}`
        setCurrentNode({ ...currentNode, valueToSet })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    // Check if the field to update is part of the FIELDS_TO_UPDATE
    const isKnownFieldToUpdate = PARTNERSHIP_FIELD_TO_UPDATE.includes(
        currentNode.fieldToUpdate as PartnershipFieldToUpdate
    )

    const adaptedValueToSet = adaptExpressionToText(currentNode.valueToSet)

    const fieldIsCollaborationStatus = currentNode.fieldToUpdate === PartnershipFieldToUpdate.COLLABORATION_STATUS

    const switchLabel = isKnownFieldToUpdate
        ? formatMessage(messages.partnershipFieldToUpdate[currentNode.fieldToUpdate])
        : ""

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="h5" {...messages.setPartnershipFieldConfiguration.fieldTitle} />
                <TextField
                    select
                    required
                    label={formatMessage(messages.setPartnershipFieldConfiguration.fieldLabel)}
                    value={isKnownFieldToUpdate ? currentNode.fieldToUpdate : ""}
                    onChange={handleChangeFieldToUpdate}
                    fullWidth
                >
                    {PARTNERSHIP_FIELD_TO_UPDATE.map((field) => (
                        <MenuItem key={field} value={field}>
                            {formatMessage(messages.partnershipFieldToUpdate[field])}
                        </MenuItem>
                    ))}
                </TextField>

                {fieldIsCollaborationStatus ? (
                    <TextField
                        select
                        required
                        label={formatMessage(messages.setPartnershipFieldConfiguration.valueLabel)}
                        value={adaptedValueToSet}
                        onChange={handleChangeValueToUpdate}
                        fullWidth
                    >
                        {COLLABORATION_STATUSES.map((status) => (
                            <MenuItem key={status} value={status}>
                                {formatMessage(messages.collaborationStatus[status])}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <>
                        <SafeFormattedMessage tagName="h5" {...messages.setPartnershipFieldConfiguration.valueLabel} />
                        <Stack direction="row" justifyContent="space-between" gap={2}>
                            <FormLabel id="toggle-label">{switchLabel}</FormLabel>
                            <Switch
                                checked={currentNode.valueToSet === "true"}
                                onChange={handleChangeValueToSet}
                                color="primary"
                                value={currentNode.valueToSet || "false"}
                            />
                        </Stack>
                    </>
                )}
            </Stack>

            <AdvancedFields<SetPartnershipFieldNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
