import { styled } from "@mui/material"
import { FC } from "react"

import { InvoiceDuplicateWarning as InvoiceDuplicateWarningDeprecated } from "~/domains/transactions/invoices/components/InvoiceDuplicateWarning/InvoiceDuplicateWarning"
import { InvoiceI } from "~/types"
import { InvoiceUserType } from "~/types"

const InvoiceDuplicateWarningContainer = styled("div")(() => ({
    width: "100%",
    display: "flex",
    "> div": {
        flex: 1,
    },
}))

interface InvoiceDuplicateWarningProps {
    invoice?: InvoiceI
}

export const InvoiceDuplicateWarning: FC<InvoiceDuplicateWarningProps> = () => {
    /* const possibleDuplicates = usePollInvoiceForDuplicates(invoice) */
    const possibleDuplicates = []

    if (!possibleDuplicates?.length) return null

    return (
        <InvoiceDuplicateWarningContainer>
            <InvoiceDuplicateWarningDeprecated duplicates={possibleDuplicates} initiator={InvoiceUserType.BUYER} />
        </InvoiceDuplicateWarningContainer>
    )
}
