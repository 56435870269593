import { Editor, Point, Range, Element as SlateElement, Transforms } from "slate"
import { v4 } from "uuid"

import { MessageActionPlan, MessageActionPlanItem, MessageContentType } from "~/domains/communication/types"

export const withActionPlan = (editor: Editor) => {
    const { insertSoftBreak, deleteBackward, insertText } = editor

    editor.insertText = (text) => {
        const { selection } = editor
        if (text.endsWith("a") && selection && Range.isCollapsed(selection)) {
            const { anchor } = selection
            const block = Editor.above(editor, {
                match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
            })
            const path = block ? block[1] : []
            const start = Editor.start(editor, path)
            const range = { anchor, focus: start }
            const beforeText = Editor.string(editor, range) + text
            if (beforeText === "/a") {
                Transforms.select(editor, range)

                if (!Range.isCollapsed(range)) {
                    Transforms.delete(editor)
                }

                const newActionPlan: MessageActionPlan = {
                    type: MessageContentType.ActionPlan,
                    children: [],
                }
                const newActionPlanItem: MessageActionPlanItem = {
                    type: MessageContentType.ActionPlanItem,
                    data: {
                        id: v4(),
                        checked: false,
                        dueDate: null,
                    },
                    children: [{ text: "" }],
                }
                Transforms.setNodes<SlateElement>(editor, newActionPlanItem, {
                    match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
                })

                Transforms.wrapNodes(editor, newActionPlan, {
                    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === newActionPlanItem.type,
                })

                return
            }
        }

        insertText(text)
    }

    editor.insertSoftBreak = () => {
        insertSoftBreak()
        const newActionPlanItem: MessageActionPlanItem = {
            type: MessageContentType.ActionPlanItem,
            data: {
                id: v4(),
                checked: false,
                dueDate: null,
            },
            children: [{ text: "" }],
        }
        Transforms.setNodes<SlateElement>(editor, newActionPlanItem, {
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === newActionPlanItem.type,
        })
    }

    editor.deleteBackward = (...args) => {
        const { selection } = editor

        if (selection && Range.isCollapsed(selection)) {
            const [match] = Editor.nodes(editor, {
                match: (n) =>
                    !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === MessageContentType.ActionPlan,
            })
            const [matchItem] = Editor.nodes(editor, {
                match: (n) =>
                    !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === MessageContentType.ActionPlanItem,
            })

            if (match && matchItem) {
                const [, path] = match
                const start = Editor.start(editor, path)
                const [, path2] = matchItem
                const start2 = Editor.start(editor, path2)

                if (Point.equals(selection.anchor, start2)) {
                    const newProperties: Partial<SlateElement> = {
                        type: "paragraph",
                    }
                    Transforms.setNodes(editor, newProperties)
                    Transforms.unwrapNodes(editor, {
                        match: (n) =>
                            !Editor.isEditor(n) &&
                            SlateElement.isElement(n) &&
                            n.type === MessageContentType.ActionPlan,
                        split: true,
                    })
                    return
                }
            }
        }
        deleteBackward(...args)
    }

    return editor
}
