import { FC } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import AutoCheck from "~/domains/transactions/invoices/_views/buyer/components/AutoCheck/AutoCheck"
import { InvoiceI } from "~/types"

import "./InvoiceCopilot.scss"

const messages = defineMessages({
    title: { id: "buyer.invoice.page.check.title", defaultMessage: "Auto-check" },
})

interface InvoiceCopilotProps {
    invoice: InvoiceI
}

export const InvoiceCopilot: FC<InvoiceCopilotProps> = ({ invoice }) => {
    const { formatMessage } = useIntl()
    return (
        <Card title={formatMessage(messages.title)} expandable>
            <AutoCheck invoice={invoice} invoiceV1={true} />
        </Card>
    )
}
