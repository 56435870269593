import { Stack, TextField } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { enUS, frFR } from "@mui/x-date-pickers/locales"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/fr"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card, SkeletonInput } from "~/components"
import { EditAmount } from "~/domains/transactions/invoices/_views/supplier/components/EditAmount"
import { OCRInputTypes } from "~/domains/transactions/invoices/components/OcrExtract/extract"
import { useOcrExtract } from "~/domains/transactions/invoices/components/OcrExtract/useOcrExtract"
import { getOcrInvoiceCurrency } from "~/domains/transactions/invoices/core"
import {
    DocumentWithDueDate,
    DocumentWithPrice,
    DocumentWithPurchaseOrderNumber,
    DocumentWithReference,
    DocumentWithVersion,
} from "~/types/"

const FR_LOCALE = "fr"

const messages = defineMessages({
    title: {
        id: "supplier.extract.title",
        defaultMessage: "Informations",
    },
    reference: {
        id: "supplier.extract.reference",
        defaultMessage: "Document ref.",
    },
    purchaseOrderNumber: {
        id: "supplier.extract.purchaseOrderNumber",
        defaultMessage: "Purchase order number",
    },
    deadLine: {
        id: "supplier.extract.deadline",
        defaultMessage: "Payment deadline",
    },
    issueDate: {
        id: "buyer.invoice.page.issueDate",
        defaultMessage: "issue date (MM/DD/YYYY)",
    },
    dueDate: {
        id: "buyer.invoice.page.dueDate",
        defaultMessage: "due date (MM/DD/YYYY)",
    },
    totalNoTax: {
        id: "supplier.extract.totalNoTax",
        defaultMessage: "Total (taxes excl.)",
    },
    totalDiscount: {
        id: "supplier.extract.totalDiscount",
        defaultMessage: "Total discount",
    },
    total: {
        id: "supplier.extract.total",
        defaultMessage: "Total (taxes incl.)",
    },
    editLines: {
        id: "supplier.extract.editLine",
        defaultMessage: "View invoice items",
    },
})

interface ExtractProps {
    ocrData: DocumentWithPurchaseOrderNumber &
        DocumentWithReference &
        DocumentWithDueDate &
        DocumentWithPrice &
        DocumentWithVersion
    updateData: (
        data: Partial<DocumentWithPurchaseOrderNumber & DocumentWithReference & DocumentWithDueDate & DocumentWithPrice>
    ) => void
    updateTotal?: (amount: number) => void
    updateTotalExcludedTaxes?: (amount: number) => void
    updateDueDate?: (date: string) => void
    dataLoaded: boolean
    disabled?: boolean
}

export function Extract({ ocrData, updateData, dataLoaded, disabled }: ExtractProps) {
    const [issueDateOpen, setIssueDateOpen] = useState<boolean>(false)
    const [dueDateOpen, setDueDateOpen] = useState<boolean>(false)
    const { formatMessage, locale } = useIntl()
    const { toggleShowShapes } = useOcrExtract()
    const currency = getOcrInvoiceCurrency(ocrData)
    const textLocale =
        locale === FR_LOCALE
            ? frFR.components.MuiLocalizationProvider.defaultProps.localeText
            : enUS.components.MuiLocalizationProvider.defaultProps.localeText

    useEffect(() => {
        if (ocrData && !ocrData.total && ocrData.totalExcludedTaxes) {
            updateData({ total: ocrData.totalExcludedTaxes })
        }
    }, [ocrData])

    const onReferenceChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateData({
                reference: event.currentTarget.value,
            })
        },
        [updateData]
    )

    const onPurchaseOrderNumberChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateData({
                purchaseOrderNumber: event.currentTarget.value,
            })
        },
        [updateData]
    )

    const handleUpdateIssueDate = useCallback(
        (newDate: Dayjs | null) => {
            if (newDate) {
                updateData({ issueDate: newDate?.format("YYYY-MM-DD") ?? null })
            }
        },
        [updateData]
    )

    const handleUpdateDueDate = useCallback(
        (newDate: Dayjs | null) => {
            if (newDate) {
                updateData({ dueDate: newDate?.format("YYYY-MM-DD") ?? null })
            }
        },
        [updateData]
    )

    const handleUpdateAmountExcludingTaxes = useCallback(
        (newAmount: number | null) => {
            if (newAmount !== null) {
                updateData({ totalExcludedTaxes: newAmount })
            }
        },
        [updateData]
    )

    const handleUpdateAmountTotalDiscount = useCallback(
        (newAmount: number | null) => {
            if (newAmount !== null) {
                updateData({ totalDiscount: newAmount })
            }
        },
        [updateData]
    )

    const handleUpdateAmountIncludingTaxes = useCallback(
        (newAmount: number | null) => {
            if (newAmount !== null) {
                updateData({ total: newAmount })
            }
        },
        [updateData]
    )

    if (!ocrData) {
        return null
    }
    return (
        <Card title={formatMessage(messages.title)} expandable>
            <Stack spacing={1}>
                {!dataLoaded ? (
                    <>
                        <SkeletonInput />
                        <SkeletonInput />
                        <SkeletonInput />
                        <SkeletonInput />
                        <SkeletonInput />
                        <SkeletonInput />
                        <SkeletonInput />
                    </>
                ) : (
                    <>
                        <TextField
                            required
                            id="reference"
                            name="reference"
                            label={formatMessage(messages.reference)}
                            placeholder={formatMessage(messages.reference)}
                            onChange={onReferenceChange}
                            value={ocrData?.reference ?? ""}
                            disabled={disabled}
                            onFocus={() => toggleShowShapes("reference", OCRInputTypes.TEXT)}
                        />

                        <TextField
                            id="purchaseOrderNumber"
                            name="purchaseOrderNumber"
                            label={formatMessage(messages.purchaseOrderNumber)}
                            placeholder={formatMessage(messages.purchaseOrderNumber)}
                            onChange={onPurchaseOrderNumberChange}
                            value={ocrData?.purchaseOrderNumber ?? ""}
                            fullWidth
                            disabled={disabled}
                            onFocus={() => toggleShowShapes("purchaseOrderNumber", OCRInputTypes.TEXT)}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale} localeText={textLocale}>
                            <DatePicker
                                open={issueDateOpen}
                                onOpen={() => setIssueDateOpen(true)}
                                onClose={() => setIssueDateOpen(false)}
                                label={formatMessage(messages.issueDate)}
                                value={ocrData.issueDate ? dayjs(ocrData.issueDate) : null}
                                onChange={(newValue) => {
                                    handleUpdateIssueDate(newValue)
                                }}
                                disabled={disabled}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        onClick: () => setIssueDateOpen(true),
                                    },
                                }}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale} localeText={textLocale}>
                            <DatePicker
                                open={dueDateOpen}
                                onOpen={() => setDueDateOpen(true)}
                                onClose={() => setDueDateOpen(false)}
                                label={formatMessage(messages.dueDate)}
                                value={ocrData.dueDate ? dayjs(ocrData.dueDate) : null}
                                onChange={(newValue) => {
                                    handleUpdateDueDate(newValue)
                                }}
                                disabled={disabled}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        onClick: () => setDueDateOpen(true),
                                    },
                                }}
                            />
                        </LocalizationProvider>
                        <EditAmount
                            value={ocrData.totalExcludedTaxes ?? 0}
                            id="totalExcludedTaxes"
                            placeholder={formatMessage(messages.totalNoTax)}
                            handleUpdate={handleUpdateAmountExcludingTaxes}
                            disabled={disabled}
                            currency={currency}
                        />

                        <EditAmount
                            value={ocrData.totalDiscount ?? 0}
                            id="totalDiscount"
                            placeholder={formatMessage(messages.totalDiscount)}
                            handleUpdate={handleUpdateAmountTotalDiscount}
                            disabled={disabled}
                            currency={currency}
                        />

                        <EditAmount
                            value={ocrData.total ?? 0}
                            id="total"
                            placeholder={formatMessage(messages.total)}
                            handleUpdate={handleUpdateAmountIncludingTaxes}
                            disabled={disabled}
                            currency={currency}
                        />
                    </>
                )}
            </Stack>
        </Card>
    )
}
