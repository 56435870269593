import { Box, ClickAwayListener, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { FC, useState } from "react"
import { Copy, Edit, MoreHorizontal, Trash } from "react-feather"
import { generatePath, useNavigate } from "react-router-dom"

import { SafeFormattedMessage } from "~/components"
import { useFlowActions } from "~/domains/orchestration/flows/hooks/useFlowActions"
import { messages } from "~/domains/orchestration/flows/messages"
import { EDITOR } from "~/domains/orchestration/flows/routes"
import { FlowItem, View } from "~/domains/orchestration/flows/types"

import { IconButton } from "./IconButton"

interface Props {
    flow: FlowItem
}

export const FlowCardHeader: FC<Props> = ({ flow }) => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const { copyFlow, archiveFlow } = useFlowActions()

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleCopy = async () => {
        await copyFlow({ id: flow.id, version: flow.version, name: flow.name })
        handleClose()
    }

    const handleEdit = () => {
        const path = generatePath(EDITOR, {
            flowId: flow.id,
        })
        navigate(path, { state: { view: View.FLOW } })
    }

    const handleArchive = async () => {
        await archiveFlow({ id: flow.id, version: flow.version })
    }

    const handleClick = () => {
        const path = generatePath(EDITOR, {
            flowId: flow.id,
        })
        navigate(path, { state: { view: View.FLOW } })
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box width="100%" className="cursor-pointer">
                <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} onClick={handleClick}>
                    <Typography variant="subtitle1">{flow.name}</Typography>

                    <IconButton type="close" onClick={handleOpen}>
                        <MoreHorizontal size={18} />
                    </IconButton>
                </Stack>
                <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                    <MenuItem onClick={handleEdit}>
                        <Edit size={18} />
                        <SafeFormattedMessage {...messages.common.edit} />
                    </MenuItem>
                    <MenuItem onClick={handleCopy}>
                        <Copy size={18} />
                        <SafeFormattedMessage {...messages.common.copy} />
                    </MenuItem>
                    <MenuItem onClick={handleArchive}>
                        <Trash size={18} />
                        <SafeFormattedMessage {...messages.common.archive} />
                    </MenuItem>
                </Menu>
            </Box>
        </ClickAwayListener>
    )
}
