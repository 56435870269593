import { Grid, Tooltip } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Check, Edit2, Save, Send, Truck, UserCheck, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { ActionsMenu, Button, SafeFormattedMessage, ShareButton } from "~/components"
import { useHasPermissions } from "~/domains/identity/features/roles-permissions/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { useProcessBulkReviewItems } from "~/domains/orchestration/flows-v0/core/hooks/useProcessBulkReviewItems"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"
import { useFetchPartnership } from "~/domains/partners/store/hooks"
import useSupplierAllowed from "~/domains/partners/store/hooks/useSupplierAllowed"
import { FulfillmentStatus, PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { useUpdateFulfillmentStatusMutation } from "~/domains/transactions/purchase-orders/api/purchaseOrderApi"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import DownloadPOPdf from "~/domains/transactions/purchase-orders/components/DownloadPOPdf/DownloadPOPdf"
import { allLinesRejected } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { PURCHASE_ORDERS_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import {
    useFetchPurchaseOrder,
    useUpdatePurchaseOrderApprovalStatus,
} from "~/domains/transactions/purchase-orders/store/hooks"
import {
    PurchaseOrderApprovalStatus,
    PurchaseOrderProgressStatus,
    PurchaseOrderStatus,
    PurchaseOrderStatusOrder,
    PurchaseOrders,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { AuthorizationName, OrganizationI, ViewTypeI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"

import { Delete } from "./Delete"
import { MarkAllInPreparation } from "./MarkAllInPreparation"

const messages = defineMessages({
    edit: { id: "purchase.orders.actionsHeader.edit", defaultMessage: "Edit" },
    submit: { id: "purchase.orders.actionsHeader.submit", defaultMessage: "Submit" },
    send: {
        id: "purchase.orders.order.action.send",
        defaultMessage: "Send",
    },
    approve: {
        id: "purchase.orders.order.action.approve",
        defaultMessage: "Mark all as approved",
    },
    reject: {
        id: "purchase.orders.order.action.reject",
        defaultMessage: "Mark all as rejected",
    },
    markAllInPreparation: {
        id: "purchase.orders.actionsHeader.markAllInPreparation",
        defaultMessage: "Mark all as in preparation",
    },
    inTransit: {
        id: "purchase.orders.order.action.inTransit",
        defaultMessage: "Mark all as in transit",
    },
    delivered: {
        id: "purchase.orders.order.action.delivered",
        defaultMessage: "Mark all as delivered",
    },
    received: {
        id: "purchase.orders.order.action.received",
        defaultMessage: "Mark all as received",
    },
    supplierNotAllowed: {
        id: "purchase.requests.request.supplierNotAllowed",
        defaultMessage: "This supplier is not allowed to receive POs",
    },
})

interface PropsPOActions {
    PO: PurchaseOrders
    organization: OrganizationI
    buyerOrganization: OrganizationSummary
    supplierOrganization: OrganizationSummary
    handleMarkAllInPreparation: () => void
    handleEdit: () => void
    handleSave: () => void
    handleSend: () => void
    handleSubmit: () => void
    mode: PurchaseViewType
    viewType: ViewTypeI
    allLinesApproved: boolean
    isOnApprovalSide: boolean
    needsNewApproval: boolean
}

export function ActionsHeader({
    PO,
    organization,
    buyerOrganization,
    supplierOrganization,
    handleMarkAllInPreparation,
    handleEdit,
    handleSave,
    handleSend,
    handleSubmit,
    mode,
    viewType,
    allLinesApproved,
    isOnApprovalSide,
    needsNewApproval,
}: PropsPOActions) {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const { updatePOApprovalStatus } = useUpdatePurchaseOrderApprovalStatus(PO.id)
    const [updateFulfillmentStatus] = useUpdateFulfillmentStatusMutation()

    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organization.id)
    const { partnershipData, loading: partnershipDataLoading } = useFetchPartnership(organization.id, PO.supplierId)

    const isSupplier = viewType === ViewTypeI.supplier
    const isBuyer = viewType === ViewTypeI.buyer
    const [isSupplierAllowed, setSupplierAllowed] = useState(false)

    const areAllLinesRejected = useMemo(() => allLinesRejected(PO.lines, viewType), [PO.lines, viewType])

    const { hasPermission: hasPOEditPermission } = useHasPermissions(
        organization.id ?? "",
        AuthorizationName.UPDATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )

    const { processBulkReviewItems } = useProcessBulkReviewItems()

    const handleApprovalStatus = useCallback(
        (status: PurchaseOrderApprovalStatus) => async (e: React.MouseEvent) => {
            e.preventDefault()
            const lineIds = PO.lines.map((line) => line.id ?? "")
            // if the PO needs approval with the new approval service:
            if (needsNewApproval) {
                await processBulkReviewItems(
                    lineIds,
                    status === PurchaseOrderApprovalStatus.APPROVED,
                    ApprovalObjectType.PURCHASE_ORDER_LINE
                )
            }
            await updatePOApprovalStatus(organization.id, lineIds, status)
            await fetchPurchaseOrder(PO.id)
        },
        [PO, organization.id, updatePOApprovalStatus, fetchPurchaseOrder, needsNewApproval, processBulkReviewItems]
    )

    const handleFulfillmentStatus = useCallback(
        (status: FulfillmentStatus) => async (e: React.MouseEvent) => {
            e.preventDefault()
            const lines = PO.lines.map((line) => ({ id: line.id ?? "", quantity: line.quantity ?? 1 }))

            await updateFulfillmentStatus({
                organizationId: organization.id,
                purchaseOrderId: PO.id,
                lines,
                status,
            })
            await fetchPurchaseOrder(PO.id)
        },
        [PO.id, organization.id, updateFulfillmentStatus, fetchPurchaseOrder]
    )

    const handleDelete = useCallback(async () => {
        try {
            await purchaseOrdersApi.delete(organization.id, PO.id)
            navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType }))
        } catch (e) {
            console.error(e)
        }
    }, [organization.id, PO.id, navigate, viewType])

    const supplierAllowed = useSupplierAllowed(partnershipData, partnershipDataLoading)

    useEffect(() => {
        setSupplierAllowed(supplierAllowed)

        return () => {
            setSupplierAllowed(false)
        }
    }, [partnershipData, partnershipDataLoading, supplierAllowed])

    const actions = useMemo(
        () => [
            ...(hasPOEditPermission
                ? [{ label: formatMessage(messages.edit), action: handleEdit, icon: <Edit2 size={16} /> }]
                : []),
            {
                render: (
                    <ShareButton
                        objectId={PO.id}
                        objectType={SharedObjectType.PurchaseOrder}
                        organizationId={organization.id}
                        buyerOrganization={buyerOrganization}
                        supplierOrganization={supplierOrganization}
                    />
                ),
            },
            { render: <DownloadPOPdf purchaseOrder={PO} /> },
            ...(PO.status === PurchaseOrderStatus.DRAFT || PO.status === PurchaseOrderStatus.SUBMITTED
                ? [{ render: <Delete handleDelete={handleDelete} key="delete" /> }]
                : []),
        ],
        [
            PO,
            organization,
            buyerOrganization,
            supplierOrganization,
            hasPOEditPermission,
            formatMessage,
            handleEdit,
            handleDelete,
        ]
    )

    const renderSendButton = () => {
        if (!(isOnApprovalSide && allLinesApproved && isBuyer && PO.status === PurchaseOrderStatus.SUBMITTED)) {
            return null
        }
        const tooltipTitle = !isSupplierAllowed ? formatMessage(messages.supplierNotAllowed) : ""

        return (
            <Tooltip arrow title={tooltipTitle}>
                <div>
                    <Button
                        type="grey"
                        onClick={isSupplierAllowed ? handleSend : undefined}
                        disabled={!isSupplierAllowed}
                    >
                        <SafeFormattedMessage {...messages.send} />
                        <Send size={18} />
                    </Button>
                </div>
            </Tooltip>
        )
    }

    const renderActionButtons = () => {
        if (mode === PurchaseViewType.VIEW) {
            return (
                <>
                    <ActionsMenu isActionIcon={false} actions={actions} buttonType="default" />
                    {PO.progress === PurchaseOrderProgressStatus.DRAFT && (
                        <Button type="grey" onClick={handleSubmit}>
                            {formatMessage(messages.submit)}
                            <Check size={18} />
                        </Button>
                    )}
                    {isOnApprovalSide && !areAllLinesRejected && (
                        <Button type="error-light" onClick={handleApprovalStatus(PurchaseOrderApprovalStatus.REJECTED)}>
                            {formatMessage(messages.reject)}
                            <X size={18} />
                        </Button>
                    )}
                    {isOnApprovalSide && !allLinesApproved && (
                        <Button type="grey" onClick={handleApprovalStatus(PurchaseOrderApprovalStatus.APPROVED)}>
                            {formatMessage(messages.approve)}
                            <Check size={18} />
                        </Button>
                    )}
                    {renderSendButton()}
                    {isSupplier && PO.progress === PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED && (
                        <>
                            <MarkAllInPreparation handleMarkInPreparation={handleMarkAllInPreparation} />
                            <Box size={18} />
                        </>
                    )}
                    {isSupplier && PO.progress === PurchaseOrderProgressStatus.IN_PREPARATION && (
                        <Button type="grey" onClick={handleFulfillmentStatus(FulfillmentStatus.OUT_FOR_DELIVERY)}>
                            {formatMessage(messages.inTransit)}
                            <Truck size={18} />
                        </Button>
                    )}
                    {(isBuyer &&
                        PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                            PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHARED) &&
                        PurchaseOrderStatusOrder.indexOf(PO.progress) <
                            PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.RECEIVED)) ||
                    (isSupplier && PO.progress === PurchaseOrderProgressStatus.SHIPPED) ? (
                        <Button type="grey" onClick={handleFulfillmentStatus(FulfillmentStatus.DELIVERED)}>
                            {formatMessage(isBuyer ? messages.received : messages.delivered)}
                            <UserCheck size={18} />
                        </Button>
                    ) : null}
                </>
            )
        }
        return (
            <Tooltip arrow title={formatMessage(commonMessages.save)}>
                <span>
                    <Button type="grey" onClick={handleSave}>
                        <Save size={18} />
                    </Button>
                </span>
            </Tooltip>
        )
    }

    return (
        <Grid item className="actions">
            {renderActionButtons()}
        </Grid>
    )
}
