import { AutocompleteRenderInputParams } from "@mui/material"
import React from "react"
import { defineMessages, useIntl } from "react-intl"

import { Chip } from "~/components/Chip"
import TrimmedTextField from "~/components/Form/TrimmedTextField"
import { PartnerProfileContactI } from "~/domains/partners/types"

const messages = defineMessages({
    selectPlaceholder: {
        id: "partners.bookofrelations.selectContacts.placeholder",
        defaultMessage: "Add a contact",
    },
})

const inputPropsStyle = {
    margin: 0,
    padding: "8px 16px",
}

const getContactLabel = (contact: PartnerProfileContactI | string | null) => {
    if (!contact) {
        return ""
    }
    if (typeof contact === "string") {
        return contact
    }
    const email = contact.email
        ? `${contact.email}${contact.lastName || contact.firstName ? " - " : ""}`
        : `${contact.phoneNumber || ""}`
    const displayLabel = `${email || ""} ${contact.lastName || ""} ${contact.firstName || ""}`.trim()
    return displayLabel.length > 35 ? `${displayLabel.slice(0, 35)}...` : displayLabel.trim()
}

interface RenderInputProps {
    params: AutocompleteRenderInputParams
    selectedContact: PartnerProfileContactI | null
    handleRemove: () => void
}

export const RenderInputComponent = React.memo(({ params, selectedContact, handleRemove }: RenderInputProps) => {
    const { formatMessage } = useIntl()
    const { inputProps } = params
    const displayChip = selectedContact?.email && (selectedContact?.email === inputProps.value || !inputProps.value)

    const customInputProps = {
        ...params.InputProps,
        startAdornment: displayChip ? (
            <React.Fragment>
                <Chip
                    borderLess={true}
                    variant="primary"
                    removable
                    onRemove={() => {
                        handleRemove()
                    }}
                    sx={{ borderRadius: 16 }}
                >
                    {getContactLabel(selectedContact)}
                </Chip>
                {params.InputProps.startAdornment}
            </React.Fragment>
        ) : null,
        endAdornment: null,
        style: inputPropsStyle,
    }

    return (
        <TrimmedTextField
            {...params}
            inputProps={{
                ...inputProps,
                value: !displayChip ? inputProps.value : "",
            }}
            InputProps={customInputProps}
            placeholder={!selectedContact ? formatMessage(messages.selectPlaceholder) : ""}
        />
    )
})
RenderInputComponent.displayName = "RenderInputComponent"
