export const checkOneFileType = (file: File, acceptedFileTypes: string, errorMessage: string) => {
    const fileTypes = acceptedFileTypes.split(";")

    if (fileTypes.includes(file.type)) {
        return { result: true, error: null }
    }

    return { result: false, error: errorMessage }
}

export const checkMultiFileTypes = (file: File, acceptedTypes: string[], errorMessage: string) => {
    if (acceptedTypes.includes(file.type)) {
        return { result: true, error: null }
    }

    return { result: false, error: errorMessage }
}
