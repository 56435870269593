import { Box, Modal as MuiModal, ModalProps as MuiModalProps } from "@mui/material"
import classnames from "classnames"
import { ReactNode } from "react"

import { Size } from "~/components"

import "./Modal.scss"

type OMuiModalProps = Omit<MuiModalProps, "children">

interface ModalProps extends OMuiModalProps {
    children: ReactNode
    size?: Size
    height?: Size
    className?: string
}

export const Modal = ({ className, children, size = Size.MD, height, ...muiProps }: ModalProps) => {
    const classes = classnames("flowie-modal", className, {
        [`size-${size}`]: size,
        [`height-${height}`]: height,
    })

    return (
        <MuiModal className={classes} {...muiProps}>
            <Box>{children}</Box>
        </MuiModal>
    )
}

interface subModalComponentProps {
    children: ReactNode
    className?: string
}

const Header = ({ children, className }: subModalComponentProps) => (
    <div className={classnames("flowie-modal-header", className)}>{children}</div>
)
Modal.Header = Header

const Content = ({ children, className }: subModalComponentProps) => (
    <div className={classnames("flowie-modal-content", className)}>{children}</div>
)
Modal.Content = Content

const Footer = ({ children, className }: subModalComponentProps) => (
    <div className={classnames("flowie-modal-footer", className)}>{children}</div>
)
Modal.Footer = Footer
