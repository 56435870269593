import { Box, Typography } from "@mui/material"
import React from "react"
import { defineMessages } from "react-intl"

import { Button, Modal, SafeFormattedMessage } from "~/components"
import { authActions } from "~/store/auth/authSlice"
import { useAppDispatch } from "~/store/hooks"

import "./ModalReconnect.scss"

const messages = defineMessages({
    modalTitle: {
        id: "modal.login.title",
        defaultMessage: "Your session has expired",
    },
    messageSignIn: {
        id: "modal.login.messageSignIn",
        defaultMessage: "You need to be logged in if you perform this action",
    },
    buttonSignIn: {
        id: "modal.login.buttonSignIn",
        defaultMessage: "Sign In again",
    },
    cancelLogin: {
        id: "modal.login.cancel",
        defaultMessage: "Do not Sign In",
    },
})

export function ModalReconnect() {
    const dispatch = useAppDispatch()

    const handleClose = (event, reason) => {
        if (reason === "escapeKeyDown" || reason === "backdropClick") {
            return false
        }
        dispatch(authActions.logout())
    }

    const handleLogin = () => {
        dispatch(authActions.openPopupSignIn())
    }

    const handleCancelLogin = () => {
        dispatch(authActions.logout())
    }

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-reconnect"
            className="modal-reconnect"
            disableEscapeKeyDown={true}
        >
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    <SafeFormattedMessage {...messages.modalTitle} />
                </Typography>
            </Modal.Header>
            <Modal.Content>
                <Box className="modal-reconnect-content">
                    <p>
                        <SafeFormattedMessage {...messages.messageSignIn} />
                    </p>
                    <Button type="tertiary" onClick={handleLogin}>
                        <SafeFormattedMessage {...messages.buttonSignIn} />
                    </Button>
                </Box>
            </Modal.Content>
            <Modal.Footer>
                <Button onClick={handleCancelLogin} type="secondary">
                    <SafeFormattedMessage {...messages.cancelLogin} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
