import { Link } from "@mui/material"
import React from "react"
import { Link as RouterLink, generatePath } from "react-router-dom"

import { INVOICE_EDIT_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { useFetchInvoice, useInvoiceById } from "~/store/invoice/hooks"
import { InvoiceId } from "~/types"

interface Props {
    invoiceId: InvoiceId
    fileName: string
}

export const LinkToInvoice: React.FC<Props> = ({ invoiceId, fileName }) => {
    const { invoice } = useInvoiceById(invoiceId)

    const invoiceLinkName = invoice?.reference?.trim().length
        ? invoice.reference
        : fileName?.length
          ? fileName
          : `#${invoiceId.split("-", 1)[0]}`

    const shortenLinkName = invoiceLinkName.length > 18 ? invoiceLinkName.slice(0, 15) + "..." : invoiceLinkName

    return (
        <Link component={RouterLink} to={generatePath(INVOICE_EDIT_ROUTE, { invoiceId })}>
            {shortenLinkName}
        </Link>
    )
}
