import * as Sentry from "@sentry/react"

import { chunkArray } from "~/utils/arrays"

const FETCH_ENTITIES_CHUNK_SIZE = 20

// Function to fetch entities in chunks with Promise.allSettled
export const fetchEntitiesInChunks = async <T>(
    ids: string[],
    fetchFunction: (ids: string[]) => Promise<T[]>,
    chunkSize: number = FETCH_ENTITIES_CHUNK_SIZE
): Promise<T[]> => {
    const chunks = chunkArray(ids, chunkSize)
    const results = await Promise.allSettled(chunks.map((chunk) => fetchFunction(chunk)))

    const { successes, failures } = results.reduce<{ successes: T[]; failures: unknown[] }>(
        (acc, result) => {
            if (result.status === "fulfilled") {
                acc.successes.push(...result.value)
            } else {
                acc.failures.push(result.reason)
            }
            return acc
        },
        { successes: [], failures: [] }
    )

    if (failures.length > 0) {
        Sentry.captureException("Failed to fetch entities in chunks", { extra: { chunks, failures } })
    }

    return successes
}
