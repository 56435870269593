import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { FormState, PaymentMethodDetails } from "~/domains/payment/payment-method-details/types"
import { PaymentMethod, PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { RootState } from "~/store"

import { paymentMethodDetailsState } from "./paymentMethodDetailsState"

const initialState = paymentMethodDetailsState

const paymentMethodDetailsSlice = createSlice({
    name: "paymentMethodDetails",
    initialState,
    reducers: {
        setSelectedItem(state, action: PayloadAction<FormState | null>) {
            state.selectedItem = action.payload
        },
        setSelectedPaymentMethod(state, action: PayloadAction<PaymentMethod | null>) {
            state.selectedPaymentMethod = action.payload
        },
        setActiveTab(state, action: PayloadAction<PaymentMethodType>) {
            state.activeTab = action.payload
        },
        setShowManualValidationModal(state, action: PayloadAction<boolean>) {
            state.showManualValidationModal = action.payload
        },
        setRows(state, action: PayloadAction<PaymentMethodDetails[]>) {
            state.rowsToApprove = action.payload
        },
        removeRowById(state, action: PayloadAction<string>) {
            state.rowsToApprove = state.rowsToApprove.filter((row) => row.id !== action.payload)
        },
        addRowToApprove(state, action: PayloadAction<PaymentMethodDetails>) {
            state.rowsToApprove.push(action.payload)
        },
        setSelectedOrganizationId(state, action: PayloadAction<string | null>) {
            state.selectedOrganizationId = action.payload
        },
        setShowDeleted(state, action: PayloadAction<boolean>) {
            state.showDeleted = action.payload
        },
        reset() {
            return initialState
        },
    },
})

export const paymentMethodDetailsActions = paymentMethodDetailsSlice.actions

export const selectSelectedItem = (state: RootState) => state.paymentMethodDetails.selectedItem
export const selectSelectedPaymentMethod = (state: RootState) => state.paymentMethodDetails.selectedPaymentMethod
export const selectActiveTab = (state: RootState) => state.paymentMethodDetails.activeTab
export const selectShowManualValidationModal = (state: RootState) =>
    state.paymentMethodDetails.showManualValidationModal
export const selectRowsToApprove = (state: RootState) => state.paymentMethodDetails.rowsToApprove
export const selectSelectedOrganizationId = (state: RootState) => state.paymentMethodDetails.selectedOrganizationId
export const selectShowDeletedPaymentMethodDetails = (state: RootState) => state.paymentMethodDetails.showDeleted

const paymentMethodDetails = paymentMethodDetailsSlice.reducer
export default paymentMethodDetails
