import React, { ReactNode, useCallback, useRef, useState } from "react"
import { Upload } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { SafeFormattedMessage } from "~/components"

import IconUpload from "../images/upload.svg"
import "./UploadDocument.scss"

const messages = defineMessages({
    headline: { id: "documents.modal.uploadFile.headline", defaultMessage: "Drag or <em>upload</em> " },
    defaultConditions: {
        id: "documents.modal.uploadFile.defaultConditions",
        defaultMessage: "Formats: all file types accepted. 2MB limit",
    },
    errorUpload: {
        id: "documents.modal.uploadFile.errorUploadData",
        defaultMessage: "An error occurred while uploading the document: {error}",
    },
})

export enum boxSize {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
}

export interface CheckFileTypeResult {
    result: boolean
    error: string | null
}

type UpdoadSigleFileProps = {
    handleFile: (file: File) => void
    handleFiles?: never
    multiple?: false
}

type UploadMultipleFileProps = {
    handleFiles: (files: File[]) => void
    handleFile?: never
    multiple: true
}

type UploadFileBoxProps = {
    conditionMessage?: string
    bottomMessage?: ReactNode
    accept?: string
    checkFileType?: (file: File) => CheckFileTypeResult
    size?: boxSize
} & (UpdoadSigleFileProps | UploadMultipleFileProps)

export const UploadDocumentBox = ({
    handleFile,
    handleFiles,
    conditionMessage,
    bottomMessage,
    accept,
    checkFileType,
    size = boxSize.SMALL,
    multiple,
    ...props
}: UploadFileBoxProps) => {
    const [dragActive, setDragActive] = useState<boolean>(false)
    const inputFileRef = useRef<HTMLInputElement>(null)
    const { formatMessage } = useIntl()

    const triggerInput = () => {
        inputFileRef.current?.click()
    }

    const handleFileList = useCallback(
        (fileList: FileList) => {
            if (multiple) {
                const files = Array.from(fileList)

                if (checkFileType) {
                    const errors = files.map(checkFileType).filter((result) => !result.result)

                    if (errors.length === 0) {
                        errors.forEach((error) =>
                            toast.error(formatMessage(messages.errorUpload, { error: error.error }))
                        )
                    }
                }
                const validFiles = checkFileType ? files.filter((file) => checkFileType(file).result) : files
                handleFiles(validFiles)
            } else {
                const file = fileList[0]

                if (checkFileType) {
                    const { result, error } = checkFileType(file)

                    if (result) {
                        handleFile(file)
                    } else {
                        toast.error(formatMessage(messages.errorUpload, { error }))
                    }
                } else {
                    handleFile(file)
                }
            }
        },
        [multiple, handleFile, handleFiles, checkFileType, formatMessage]
    )

    const handleClickUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files) {
            handleFileList(e.target.files)
        }
    }

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileList(e.dataTransfer.files)
        }
    }

    return (
        <div className="upload-document-container" {...props}>
            <div
                className={`upload-document ${size}`}
                onClick={triggerInput}
                onDragEnter={(e) => handleDrag(e)}
                role="button"
                tabIndex={0}
                aria-label="upload a file"
            >
                <div className="upload-document-block">
                    {size === boxSize.LARGE ? <img alt="Upload" src={IconUpload} /> : <Upload size={24} />}
                    <div className="upload-document-text">
                        <SafeFormattedMessage {...messages.headline} values={{ em: (msg) => <em>{msg}</em> }} />
                    </div>
                    <div className="upload-document-conditions">
                        {conditionMessage ? conditionMessage : <SafeFormattedMessage {...messages.defaultConditions} />}
                    </div>
                </div>
                <input
                    type="file"
                    name="file"
                    className="upload-document-file"
                    ref={inputFileRef}
                    accept={accept}
                    onChange={handleClickUpload}
                    multiple={multiple}
                />
                {dragActive && (
                    <div
                        className="upload-document-drag"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                    />
                )}
            </div>
            {bottomMessage ? <div className="upload-document-tip">{bottomMessage}</div> : null}
        </div>
    )
}
