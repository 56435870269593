import { Box, LinearProgress, styled, tooltipClasses } from "@mui/material"
import { FC, Fragment, useCallback, useMemo } from "react"

import { TooltipWhite } from "~/components/Tooltip/Tooltip"
import { updateCumulativePercentage } from "~/features/budget/core/budgetGauge"

const ProgressBarContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
})

const TitleContainer = styled(Box)({
    position: "relative",
    width: "100%",
})

const GaugeContainer = styled(Box)({
    position: "relative",
    width: "100%",
})

const ProgressBarGauge = styled(LinearProgress)<{ itemcolor: string; itemindex: number; height: number }>(
    ({ itemcolor, itemindex, height }) => ({
        position: itemindex === 0 ? "relative" : "absolute",
        top: 0,
        height: `${height}px`,
        width: "100%",
        borderRadius: "10px",
        backgroundColor: itemindex === 0 ? "var(--color-border-workflow)" : "transparent",
        "& .MuiLinearProgress-bar": {
            backgroundColor: itemcolor,
            borderRadius: "10px",
        },
    })
)

const ProgressBarLabel = styled("div")({
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "var(--color-light-grey)",
})

const CustomTooltip = styled(TooltipWhite)({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "300px",
        fontSize: "var(--font-size-sm)",
    },
})

export interface ProgressBarItemDisplay {
    label?: string
    value?: string
    amount?: number
    percentage: number
    color: string
}

export interface ProgressBarItem extends Omit<ProgressBarItemDisplay, "value"> {
    value: number
}

export enum TitlePlacement {
    TOP = "top",
    BOTTOM = "bottom",
}

const PROGRESSBAR_DEFAULT_HEIGHT = 8

interface ProgressBarProps extends React.PropsWithChildren {
    values: ProgressBarItemDisplay[]
    height?: number
    title?: string | React.ReactNode
    titlePlacement?: TitlePlacement
    className?: string
    tooltip?: React.ReactNode
}

export const ProgressBar: FC<ProgressBarProps> = ({
    values,
    children,
    title,
    height = PROGRESSBAR_DEFAULT_HEIGHT,
    titlePlacement = TitlePlacement.TOP,
    className,
    tooltip,
}) => {
    const updatedValues = useMemo(() => updateCumulativePercentage(values).reverse(), [values])

    const renderGauge = useCallback(() => {
        return updatedValues.map((p, index) => {
            const value = Math.min(100, p.percentage * 100)

            return (
                <Fragment key={`${p.label}_${index}`}>
                    {children ? <ProgressBarLabel>{children}</ProgressBarLabel> : null}
                    <ProgressBarGauge
                        variant="determinate"
                        value={value}
                        itemcolor={p.color}
                        itemindex={index}
                        height={height}
                    />
                </Fragment>
            )
        })
    }, [updatedValues, children])

    return (
        <ProgressBarContainer className={className}>
            {title && titlePlacement === TitlePlacement.TOP && <TitleContainer>{title}</TitleContainer>}
            {tooltip ? (
                <CustomTooltip title={tooltip} placement="top" arrow>
                    <GaugeContainer>{renderGauge()}</GaugeContainer>
                </CustomTooltip>
            ) : (
                <GaugeContainer>{renderGauge()}</GaugeContainer>
            )}
            {title && titlePlacement === TitlePlacement.BOTTOM && <TitleContainer>{title}</TitleContainer>}
        </ProgressBarContainer>
    )
}
