export enum CurrencyCodes {
    AED = "AED",
    AFN = "AFN",
    ALL = "ALL",
    AMD = "AMD",
    ANG = "ANG",
    AOA = "AOA",
    ARS = "ARS",
    AUD = "AUD",
    AWG = "AWG",
    AZN = "AZN",
    BAM = "BAM",
    BBD = "BBD",
    BDT = "BDT",
    BGN = "BGN",
    BHD = "BHD",
    BIF = "BIF",
    BMD = "BMD",
    BND = "BND",
    BOB = "BOB",
    BRL = "BRL",
    BSD = "BSD",
    BTN = "BTN",
    BWP = "BWP",
    BYN = "BYN",
    BZD = "BZD",
    CAD = "CAD",
    CDF = "CDF",
    CHF = "CHF",
    CLP = "CLP",
    CNY = "CNY",
    COP = "COP",
    CRC = "CRC",
    CUP = "CUP",
    CVE = "CVE",
    CZK = "CZK",
    DJF = "DJF",
    DKK = "DKK",
    DOP = "DOP",
    DZD = "DZD",
    EGP = "EGP",
    ERN = "ERN",
    ETB = "ETB",
    EUR = "EUR",
    FJD = "FJD",
    FKP = "FKP",
    FOK = "FOK",
    GBP = "GBP",
    GEL = "GEL",
    GGP = "GGP",
    GHS = "GHS",
    GIP = "GIP",
    GMD = "GMD",
    GNF = "GNF",
    GTQ = "GTQ",
    GYD = "GYD",
    HKD = "HKD",
    HNL = "HNL",
    HRK = "HRK",
    HTG = "HTG",
    HUF = "HUF",
    IDR = "IDR",
    ILS = "ILS",
    IMP = "IMP",
    INR = "INR",
    IQD = "IQD",
    IRR = "IRR",
    ISK = "ISK",
    JEP = "JEP",
    JMD = "JMD",
    JOD = "JOD",
    JPY = "JPY",
    KES = "KES",
    KGS = "KGS",
    KHR = "KHR",
    KID = "KID",
    KMF = "KMF",
    KRW = "KRW",
    KWD = "KWD",
    KYD = "KYD",
    KZT = "KZT",
    LAK = "LAK",
    LBP = "LBP",
    LKR = "LKR",
    LRD = "LRD",
    LSL = "LSL",
    LYD = "LYD",
    MAD = "MAD",
    MDL = "MDL",
    MGA = "MGA",
    MKD = "MKD",
    MMK = "MMK",
    MNT = "MNT",
    MOP = "MOP",
    MRU = "MRU",
    MUR = "MUR",
    MVR = "MVR",
    MWK = "MWK",
    MXN = "MXN",
    MYR = "MYR",
    MZN = "MZN",
    NAD = "NAD",
    NGN = "NGN",
    NIO = "NIO",
    NOK = "NOK",
    NPR = "NPR",
    NZD = "NZD",
    OMR = "OMR",
    PAB = "PAB",
    PEN = "PEN",
    PGK = "PGK",
    PHP = "PHP",
    PKR = "PKR",
    PLN = "PLN",
    PYG = "PYG",
    QAR = "QAR",
    RON = "RON",
    RSD = "RSD",
    RUB = "RUB",
    RWF = "RWF",
    SAR = "SAR",
    SBD = "SBD",
    SCR = "SCR",
    SDG = "SDG",
    SEK = "SEK",
    SGD = "SGD",
    SHP = "SHP",
    SLL = "SLL",
    SOS = "SOS",
    SRD = "SRD",
    SSP = "SSP",
    STN = "STN",
    SVC = "SVC",
    SYP = "SYP",
    SZL = "SZL",
    THB = "THB",
    TJS = "TJS",
    TMT = "TMT",
    TND = "TND",
    TOP = "TOP",
    TRY = "TRY",
    TTD = "TTD",
    TVD = "TVD",
    TWD = "TWD",
    TZS = "TZS",
    UAH = "UAH",
    UGX = "UGX",
    USD = "USD",
    UYU = "UYU",
    UZS = "UZS",
    VES = "VES",
    VND = "VND",
    VUV = "VUV",
    WST = "WST",
    XAF = "XAF",
    XCD = "XCD",
    XOF = "XOF",
    XPF = "XPF",
    YER = "YER",
    ZAR = "ZAR",
    ZMW = "ZMW",
    ZWL = "ZWL",
}

export interface CurrencyI {
    code: CurrencyCodes
    name: string
}

export const Currencies: CurrencyI[] = [
    { code: CurrencyCodes.AED, name: "United Arab Emirates Dirham" },
    { code: CurrencyCodes.AFN, name: "Afghan Afghani" },
    { code: CurrencyCodes.ALL, name: "Albanian Lek" },
    { code: CurrencyCodes.AMD, name: "Armenian Dram" },
    { code: CurrencyCodes.ANG, name: "Netherlands Antillean Guilder" },
    { code: CurrencyCodes.AOA, name: "Angolan Kwanza" },
    { code: CurrencyCodes.ARS, name: "Argentine Peso" },
    { code: CurrencyCodes.AUD, name: "Australian Dollar" },
    { code: CurrencyCodes.AWG, name: "Aruban Florin" },
    { code: CurrencyCodes.AZN, name: "Azerbaijani Manat" },
    { code: CurrencyCodes.BAM, name: "Bosnia-Herzegovina Convertible Mark" },
    { code: CurrencyCodes.BBD, name: "Barbadian Dollar" },
    { code: CurrencyCodes.BDT, name: "Bangladeshi Taka" },
    { code: CurrencyCodes.BGN, name: "Bulgarian Lev" },
    { code: CurrencyCodes.BHD, name: "Bahraini Dinar" },
    { code: CurrencyCodes.BIF, name: "Burundian Franc" },
    { code: CurrencyCodes.BMD, name: "Bermudian Dollar" },
    { code: CurrencyCodes.BND, name: "Brunei Dollar" },
    { code: CurrencyCodes.BOB, name: "Bolivian Boliviano" },
    { code: CurrencyCodes.BRL, name: "Brazilian Real" },
    { code: CurrencyCodes.BSD, name: "Bahamian Dollar" },
    { code: CurrencyCodes.BTN, name: "Bhutanese Ngultrum" },
    { code: CurrencyCodes.BWP, name: "Botswana Pula" },
    { code: CurrencyCodes.BYN, name: "Belarusian Ruble" },
    { code: CurrencyCodes.BZD, name: "Belize Dollar" },
    { code: CurrencyCodes.CAD, name: "Canadian Dollar" },
    { code: CurrencyCodes.CDF, name: "Congolese Franc" },
    { code: CurrencyCodes.CHF, name: "Swiss Franc" },
    { code: CurrencyCodes.CLP, name: "Chilean Peso" },
    { code: CurrencyCodes.CNY, name: "Chinese Yuan" },
    { code: CurrencyCodes.COP, name: "Colombian Peso" },
    { code: CurrencyCodes.CRC, name: "Costa Rican Colón" },
    { code: CurrencyCodes.CUP, name: "Cuban Peso" },
    { code: CurrencyCodes.CVE, name: "Cape Verdean Escudo" },
    { code: CurrencyCodes.CZK, name: "Czech Koruna" },
    { code: CurrencyCodes.DJF, name: "Djiboutian Franc" },
    { code: CurrencyCodes.DKK, name: "Danish Krone" },
    { code: CurrencyCodes.DOP, name: "Dominican Peso" },
    { code: CurrencyCodes.DZD, name: "Algerian Dinar" },
    { code: CurrencyCodes.EGP, name: "Egyptian Pound" },
    { code: CurrencyCodes.ERN, name: "Eritrean Nakfa" },
    { code: CurrencyCodes.ETB, name: "Ethiopian Birr" },
    { code: CurrencyCodes.EUR, name: "Euro" },
    { code: CurrencyCodes.FJD, name: "Fijian Dollar" },
    { code: CurrencyCodes.FKP, name: "Falkland Islands Pound" },
    { code: CurrencyCodes.FOK, name: "Faroese Króna" },
    { code: CurrencyCodes.GBP, name: "British Pound" },
    { code: CurrencyCodes.GEL, name: "Georgian Lari" },
    { code: CurrencyCodes.GGP, name: "Guernsey Pound" },
    { code: CurrencyCodes.GHS, name: "Ghanaian Cedi" },
    { code: CurrencyCodes.GIP, name: "Gibraltar Pound" },
    { code: CurrencyCodes.GMD, name: "Gambian Dalasi" },
    { code: CurrencyCodes.GNF, name: "Guinean Franc" },
    { code: CurrencyCodes.GTQ, name: "Guatemalan Quetzal" },
    { code: CurrencyCodes.GYD, name: "Guyanese Dollar" },
    { code: CurrencyCodes.HKD, name: "Hong Kong Dollar" },
    { code: CurrencyCodes.HNL, name: "Honduran Lempira" },
    { code: CurrencyCodes.HRK, name: "Croatian Kuna" },
    { code: CurrencyCodes.HTG, name: "Haitian Gourde" },
    { code: CurrencyCodes.HUF, name: "Hungarian Forint" },
    { code: CurrencyCodes.IDR, name: "Indonesian Rupiah" },
    { code: CurrencyCodes.ILS, name: "Israeli New Shekel" },
    { code: CurrencyCodes.IMP, name: "Isle of Man Pound" },
    { code: CurrencyCodes.INR, name: "Indian Rupee" },
    { code: CurrencyCodes.IQD, name: "Iraqi Dinar" },
    { code: CurrencyCodes.IRR, name: "Iranian Rial" },
    { code: CurrencyCodes.ISK, name: "Icelandic Króna" },
    { code: CurrencyCodes.JEP, name: "Jersey Pound" },
    { code: CurrencyCodes.JMD, name: "Jamaican Dollar" },
    { code: CurrencyCodes.JOD, name: "Jordanian Dinar" },
    { code: CurrencyCodes.JPY, name: "Japanese Yen" },
    { code: CurrencyCodes.KES, name: "Kenyan Shilling" },
    { code: CurrencyCodes.KGS, name: "Kyrgyzstani Som" },
    { code: CurrencyCodes.KHR, name: "Cambodian Riel" },
    { code: CurrencyCodes.KID, name: "Kiribati Dollar" },
    { code: CurrencyCodes.KMF, name: "Comorian Franc" },
    { code: CurrencyCodes.KRW, name: "South Korean Won" },
    { code: CurrencyCodes.KWD, name: "Kuwaiti Dinar" },
    { code: CurrencyCodes.KYD, name: "Cayman Islands Dollar" },
    { code: CurrencyCodes.KZT, name: "Kazakhstani Tenge" },
    { code: CurrencyCodes.LAK, name: "Lao Kip" },
    { code: CurrencyCodes.LBP, name: "Lebanese Pound" },
    { code: CurrencyCodes.LKR, name: "Sri Lankan Rupee" },
    { code: CurrencyCodes.LRD, name: "Liberian Dollar" },
    { code: CurrencyCodes.LSL, name: "Lesotho Loti" },
    { code: CurrencyCodes.LYD, name: "Libyan Dinar" },
    { code: CurrencyCodes.MAD, name: "Moroccan Dirham" },
    { code: CurrencyCodes.MDL, name: "Moldovan Leu" },
    { code: CurrencyCodes.MGA, name: "Malagasy Ariary" },
    { code: CurrencyCodes.MKD, name: "Macedonian Denar" },
    { code: CurrencyCodes.MMK, name: "Myanmar Kyat" },
    { code: CurrencyCodes.MNT, name: "Mongolian Tögrög" },
    { code: CurrencyCodes.MOP, name: "Macanese Pataca" },
    { code: CurrencyCodes.MRU, name: "Mauritanian Ouguiya" },
    { code: CurrencyCodes.MUR, name: "Mauritian Rupee" },
    { code: CurrencyCodes.MVR, name: "Maldivian Rufiyaa" },
    { code: CurrencyCodes.MWK, name: "Malawian Kwacha" },
    { code: CurrencyCodes.MXN, name: "Mexican Peso" },
    { code: CurrencyCodes.MYR, name: "Malaysian Ringgit" },
    { code: CurrencyCodes.MZN, name: "Mozambican Metical" },
    { code: CurrencyCodes.NAD, name: "Namibian Dollar" },
    { code: CurrencyCodes.NGN, name: "Nigerian Naira" },
    { code: CurrencyCodes.NIO, name: "Nicaraguan Córdoba" },
    { code: CurrencyCodes.NOK, name: "Norwegian Krone" },
    { code: CurrencyCodes.NPR, name: "Nepalese Rupee" },
    { code: CurrencyCodes.NZD, name: "New Zealand Dollar" },
    { code: CurrencyCodes.OMR, name: "Omani Rial" },
    { code: CurrencyCodes.PAB, name: "Panamanian Balboa" },
    { code: CurrencyCodes.PEN, name: "Peruvian Sol" },
    { code: CurrencyCodes.PGK, name: "Papua New Guinean Kina" },
    { code: CurrencyCodes.PHP, name: "Philippine Peso" },
    { code: CurrencyCodes.PKR, name: "Pakistani Rupee" },
    { code: CurrencyCodes.PLN, name: "Polish Złoty" },
    { code: CurrencyCodes.PYG, name: "Paraguayan Guaraní" },
    { code: CurrencyCodes.QAR, name: "Qatari Riyal" },
    { code: CurrencyCodes.RON, name: "Romanian Leu" },
    { code: CurrencyCodes.RSD, name: "Serbian Dinar" },
    { code: CurrencyCodes.RUB, name: "Russian Ruble" },
    { code: CurrencyCodes.RWF, name: "Rwandan Franc" },
    { code: CurrencyCodes.SAR, name: "Saudi Riyal" },
    { code: CurrencyCodes.SBD, name: "Solomon Islands Dollar" },
    { code: CurrencyCodes.SCR, name: "Seychellois Rupee" },
    { code: CurrencyCodes.SDG, name: "Sudanese Pound" },
    { code: CurrencyCodes.SEK, name: "Swedish Krona" },
    { code: CurrencyCodes.SGD, name: "Singapore Dollar" },
    { code: CurrencyCodes.SHP, name: "Saint Helena Pound" },
    { code: CurrencyCodes.SLL, name: "Sierra Leonean Leone" },
    { code: CurrencyCodes.SOS, name: "Somali Shilling" },
    { code: CurrencyCodes.SRD, name: "Surinamese Dollar" },
    { code: CurrencyCodes.SSP, name: "South Sudanese Pound" },
    { code: CurrencyCodes.STN, name: "São Tomé and Príncipe Dobra" },
    { code: CurrencyCodes.SVC, name: "Salvadoran Colón" },
    { code: CurrencyCodes.SYP, name: "Syrian Pound" },
    { code: CurrencyCodes.SZL, name: "Eswatini Lilangeni" },
    { code: CurrencyCodes.THB, name: "Thai Baht" },
    { code: CurrencyCodes.TJS, name: "Tajikistani Somoni" },
    { code: CurrencyCodes.TMT, name: "Turkmenistani Manat" },
    { code: CurrencyCodes.TND, name: "Tunisian Dinar" },
    { code: CurrencyCodes.TOP, name: "Tongan Paʻanga" },
    { code: CurrencyCodes.TRY, name: "Turkish Lira" },
    { code: CurrencyCodes.TTD, name: "Trinidad and Tobago Dollar" },
    { code: CurrencyCodes.TVD, name: "Tuvaluan Dollar" },
    { code: CurrencyCodes.TWD, name: "New Taiwan Dollar" },
    { code: CurrencyCodes.TZS, name: "Tanzanian Shilling" },
    { code: CurrencyCodes.UAH, name: "Ukrainian Hryvnia" },
    { code: CurrencyCodes.UGX, name: "Ugandan Shilling" },
    { code: CurrencyCodes.USD, name: "United States Dollar" },
    { code: CurrencyCodes.UYU, name: "Uruguayan Peso" },
    { code: CurrencyCodes.UZS, name: "Uzbekistani Som" },
    { code: CurrencyCodes.VES, name: "Venezuelan Bolívar Soberano" },
    { code: CurrencyCodes.VND, name: "Vietnamese Đồng" },
    { code: CurrencyCodes.VUV, name: "Vanuatu Vatu" },
    { code: CurrencyCodes.WST, name: "Samoan Tālā" },
    { code: CurrencyCodes.XAF, name: "Central African CFA Franc" },
    { code: CurrencyCodes.XCD, name: "East Caribbean Dollar" },
    { code: CurrencyCodes.XOF, name: "West African CFA Franc" },
    { code: CurrencyCodes.XPF, name: "CFP Franc" },
    { code: CurrencyCodes.YER, name: "Yemeni Rial" },
    { code: CurrencyCodes.ZAR, name: "South African Rand" },
    { code: CurrencyCodes.ZMW, name: "Zambian Kwacha" },
    { code: CurrencyCodes.ZWL, name: "Zimbabwean Dollar" },
]

const ValidCurrencyCodes: Set<string> = new Set(Object.values(CurrencyCodes))

export const isCurrencyCode = (currencyCodeStr: string): currencyCodeStr is CurrencyCodes =>
    ValidCurrencyCodes.has(currencyCodeStr)

export const validateCurrencyCode = (
    currencyCodeStr: string,
    defaultValue: CurrencyCodes | null = null
): CurrencyCodes | null => (isCurrencyCode(currencyCodeStr) ? currencyCodeStr : defaultValue)
