import styled from "@emotion/styled"
import {
    Background,
    BackgroundVariant,
    type Connection,
    Panel,
    ReactFlow,
    addEdge,
    useEdgesState,
    useNodesState,
} from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { Menu } from "react-feather"

import { Button } from "~/components"
import { ButtonEdge } from "~/domains/orchestration/flows/components/ButtonEdge"
import { ButtonEdgeWithLabel } from "~/domains/orchestration/flows/components/ButtonEdgeWithLabel"
import { EdgeWithLabel } from "~/domains/orchestration/flows/components/EdgeWithLabel"
import { useEditor } from "~/domains/orchestration/flows/context/editorContext"
import { FLOW_NODE_TYPES, adaptFlowToEdges, adaptFlowToNodes } from "~/domains/orchestration/flows/core"
import {
    type Flow,
    isAddToBudgetNode,
    isApprovePurchaseOrderLineNode,
    isApprovePurchaseOrderNode,
    isApprovePurchaseRequestLineNode,
    isApprovePurchaseRequestNode,
    isAssignTagNode,
    isBranchNode,
    isConvertPrToPoNode,
    isCreateSurveyNode,
    isEventTriggerNode,
    isFetchCustomFieldsNode,
    isRetractReviewsNode,
    isSendEmailNode,
    isSetInvoiceLifecycleStatusNode,
    isSetPartnershipFieldNode,
    isSetPaymentMethodDetailsFieldNode,
    isUpdateTripletexLedgerNode,
} from "~/domains/orchestration/flows/types"

import { RunExplorerSideBar } from "./RunExplorerSideBar"

interface Props {
    flow: Flow
}

const Wrapper = styled.div<{ $hideSideBar: boolean }>`
    width: ${({ $hideSideBar }) => ($hideSideBar ? "100vw" : "50vw")};
    height: calc(100vh - 100px);
`

const StyledError = styled(Panel)`
    color: var(--color-red);
    padding: var(--spacing-md);
    background-color: var(--sidebar-color);
    border-radius: var(--border-radius-sm);
`

const MenuButton = styled(Button)`
    position: fixed;
    top: 100px;
    right: var(--spacing-xl);
`

export const RunExplorerPanel: FC<Props> = ({ flow }) => {
    const reactFlowWrapper = useRef(null)
    const [hideSideBar, setHideSideBar] = useState(false)

    const state = useEditor()

    const { error } = state

    const [nodes, setNodes, onNodesChange] = useNodesState(adaptFlowToNodes(flow))
    const [edges, setEdges, onEdgesChange] = useEdgesState(
        adaptFlowToEdges(flow, {
            readOnly: true,
        })
    )
    const edgeTypes = useMemo(
        () => ({
            button: ButtonEdge,
            buttonWithLabel: ButtonEdgeWithLabel,
            withLabel: EdgeWithLabel,
        }),
        []
    )

    const onConnect = (params: Connection) => {
        const nodes = [...flow.nodes]
        const sourceNodeIndex = nodes.findIndex((node) => node.slug === params.source)
        if (sourceNodeIndex === -1) return

        let sourceNode = nodes[sourceNodeIndex]

        // TODO: Create a function to handle this

        const isDefaultBranchHandle =
            isBranchNode(sourceNode) && params.sourceHandle === `${nodes[sourceNodeIndex].slug}-default`

        if (
            isEventTriggerNode(sourceNode) ||
            isSetPartnershipFieldNode(sourceNode) ||
            isSendEmailNode(sourceNode) ||
            isAddToBudgetNode(sourceNode) ||
            isSetInvoiceLifecycleStatusNode(sourceNode) ||
            isAssignTagNode(sourceNode) ||
            isUpdateTripletexLedgerNode(sourceNode) ||
            isApprovePurchaseOrderNode(sourceNode) ||
            isApprovePurchaseOrderLineNode(sourceNode) ||
            isConvertPrToPoNode(sourceNode) ||
            isApprovePurchaseRequestNode(sourceNode) ||
            isApprovePurchaseRequestLineNode(sourceNode) ||
            isSetPaymentMethodDetailsFieldNode(sourceNode) ||
            isCreateSurveyNode(sourceNode) ||
            isRetractReviewsNode(sourceNode) ||
            isFetchCustomFieldsNode(sourceNode)
        ) {
            sourceNode = { ...sourceNode, nextNode: params.target }
        } else if (isBranchNode(sourceNode)) {
            const handleIndex = parseInt(params.sourceHandle || "0")

            sourceNode = {
                ...sourceNode,
                default: isDefaultBranchHandle ? params.target : sourceNode.default,
                branches: sourceNode.branches.map((branch, index) => ({
                    ...branch,
                    nextNode: handleIndex === index ? params.target : branch.nextNode,
                })),
            }
        } else {
            sourceNode = params.sourceHandle?.includes("success")
                ? { ...sourceNode, nextIfSuccess: params.target }
                : { ...sourceNode, nextIfFailure: params.target }
        }

        setEdges((eds) => addEdge(params, eds))
    }
    useEffect(() => {
        setNodes(adaptFlowToNodes(flow))
        setEdges(
            adaptFlowToEdges(flow, {
                readOnly: true,
            })
        )
    }, [flow])

    const handleClose = () => {
        setHideSideBar(true)
    }

    const handleOpen = () => {
        setHideSideBar(false)
    }

    const hasError = error !== null && error.trim().length > 0

    return (
        <>
            <Wrapper ref={reactFlowWrapper} $hideSideBar={hideSideBar}>
                <ReactFlow
                    key={flow.id}
                    nodes={nodes}
                    edges={edges}
                    edgeTypes={edgeTypes}
                    nodeTypes={FLOW_NODE_TYPES}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    snapToGrid
                    snapGrid={[50, 50]}
                    fitView
                    proOptions={{
                        hideAttribution: true,
                    }}
                >
                    {hasError && <StyledError position="top-center">{error}</StyledError>}
                    <Panel position="top-right">
                        {hideSideBar ? (
                            <MenuButton type="primary" onClick={handleOpen}>
                                <Menu size={18} />
                            </MenuButton>
                        ) : (
                            <Panel position="top-right">
                                <RunExplorerSideBar handleClose={handleClose} flowId={flow.id} />
                            </Panel>
                        )}
                    </Panel>

                    <Background color="var(--primary-color)" variant={BackgroundVariant.Dots} />
                </ReactFlow>
            </Wrapper>
        </>
    )
}
