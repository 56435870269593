import { useEffect, useState } from "react"

import { useCommunicationApi } from "~/domains/communication/api"
import { MessageTemplateI } from "~/domains/communication/types/MessageTemplate"
import { OrganizationId } from "~/types"
import { pipeResult } from "~/types/Result"

export const useMessageTemplates = (organizationId: OrganizationId | undefined) => {
    const communicationApi = useCommunicationApi()
    const [templates, setTemplates] = useState<MessageTemplateI[] | null>(null)

    useEffect(() => {
        if (!organizationId) return
        communicationApi.getTemplates(organizationId).then((result) => {
            pipeResult(result, setTemplates)
        })
    }, [organizationId, communicationApi])

    return templates
}
