import { Alert, Box, styled } from "@mui/material"
import React, { PropsWithChildren } from "react"
import { AlertTriangle } from "react-feather"
import { MessageDescriptor } from "react-intl"

import { SafeFormattedMessage } from "~/components"

interface Props extends PropsWithChildren {
    autoCorrectionMessage?: MessageDescriptor
}

export const CustomAlert = styled(Alert, {
    shouldForwardProp: (prop) => prop !== "autoCorrectionMessage",
})<{ autoCorrectionMessage?: MessageDescriptor | undefined }>(({ autoCorrectionMessage }) => ({
    backgroundColor: "var(--color-yellow-100)",
    cursor: autoCorrectionMessage ? "pointer" : "default",
}))

export const WarningAlert: React.FC<Props> = ({ autoCorrectionMessage, children }) => {
    return (
        <CustomAlert severity="warning" icon={<AlertTriangle />} autoCorrectionMessage={autoCorrectionMessage}>
            <span>{children}</span>
            {autoCorrectionMessage ? (
                <Box sx={{ color: "var(--primary-color)" }}>
                    <SafeFormattedMessage {...autoCorrectionMessage} />
                </Box>
            ) : null}
        </CustomAlert>
    )
}
